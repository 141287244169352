import { FormattedMessage } from 'react-intl';

const steps = [
  { name: <FormattedMessage id="firstCategoryStep" />, id: 1 },
  { name: <FormattedMessage id="secondCategoryStep" />, id: 2 },
  { name: <FormattedMessage id="thirdCategoryStep" />, id: 3 },
  { name: <FormattedMessage id="fourthCategoryStep" />, id: 4 },
];

interface AddCategoryStepperProps {
  activeStep: number;
}

export const AddCategoryStepper = ({ activeStep }: AddCategoryStepperProps) => {
  return (
    <div className="w-full">
      <div className="mt-2 mb-3" aria-hidden="true">
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div
            className="h-2 rounded-full bg-indigo-600"
            style={{
              width: (+activeStep / steps.length) * 100 + '%',
            }}
          />
        </div>
        <div className="mt-6 hidden grid-cols-3 text-sm font-medium text-gray-600 sm:grid">
          {steps.map((item) => (
            <div
              className={`${
                activeStep === item.id ? 'text-indigo-600 block' : 'hidden'
              } ltr:text-left rtl:text-right`}
              key={'create-category-step-' + item.id}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
