import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { defaultToastProps, selectLanguages } from 'appConstants';
import { Languages, SetStateType, TranslateFiled } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { checkFieldNotEmpty } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { createCategory, editCategory } from 'store/category/actions';

import {
  AddCategoryButton,
  AddCategorySelectLang,
  AddCategoryStepper,
  AddCategoryTextField,
} from '../UI';

interface AddCategoryModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  name?: TranslateFiled;
  descriptionOld?: Record<string, never> | TranslateFiled;
  id?: string;
  edit?: boolean;
  activeGlobalMenu?: string;
  globalMenuId: string;
}

export const AddCategoryModal = ({
  open,
  setOpen,
  name,
  descriptionOld,
  id,
  edit = false,
  activeGlobalMenu,
  globalMenuId,
}: AddCategoryModalProps) => {
  const dispatch = useAppDispatch();
  const [categoryName, setCategoryName] = useState('');
  const account = useAppSelector((state) => state.account.account);
  const [selected, setSelected] = useState(
    selectLanguages.find((elem) => elem.value === account?.locale),
  );
  const [selectedDescription, setSelectedDescription] = useState(
    selectLanguages.find((elem) => elem.value === account?.locale),
  );
  const [description, setDescription] = useState('');

  const [addCategoryTranslate, setAddCategoryTranslate] = useState<
    object | Record<string, never> | Record<Languages, string>
  >({});
  const [descriptionTranslate, setDescriptionTranslate] = useState<
    object | Record<string, never> | Record<Languages, string>
  >({});
  const [loadingTranslateLabmda, setLoadingTranslateLabmda] = useState(false);
  const [withoutTranslationsName, setWithoutTranslationsName] = useState(false);
  const [withoutTranslationsDescription, setWithoutTranslationsDescription] =
    useState(false);

  const [activeStep, setActiveStep] = useState(1);

  const resetFields = () => {
    setTimeout(() => {
      setCategoryName('');
      setSelected(selectLanguages.find((lang) => lang.value === account?.locale));
      setSelectedDescription(
        selectLanguages.find((lang) => lang.value === account?.locale),
      );
      setActiveStep(1);
      setAddCategoryTranslate({});
      setDescriptionTranslate({});
      setDescription('');
      setWithoutTranslationsName(false);
      setWithoutTranslationsDescription(false);
    }, 250);
    setOpen(false);
  };

  useEffect(() => {
    if (edit) {
      if (name && name.translations && name.source_lang) {
        setCategoryName(name.translations[name.source_lang]);
      }
      if (descriptionOld?.translations && descriptionOld?.source_lang) {
        setDescription(descriptionOld?.translations[descriptionOld?.source_lang] || '');
      } else {
        setDescription('');
      }
      setSelected(selectLanguages.find((lang) => lang.value === name?.source_lang));
      if (descriptionOld?.source_lang) {
        setSelectedDescription(
          selectLanguages.find((lang) => lang.value === descriptionOld?.source_lang),
        );
      } else {
        setSelectedDescription(
          selectLanguages.find((lang) => lang.value === name?.source_lang),
        );
      }
      if (name?.translations) {
        setAddCategoryTranslate(name?.translations);
      } else {
        setAddCategoryTranslate({});
      }

      if (descriptionOld?.translations) {
        setDescriptionTranslate(descriptionOld?.translations || {});
      } else {
        setDescriptionTranslate({});
      }

      setWithoutTranslationsName(name?.withoutTranslate || false);
      setWithoutTranslationsDescription(descriptionOld?.withoutTranslate || false);
    }
    if (account && !edit) {
      setSelected(selectLanguages.find((lang) => lang.value === account.locale));
      setSelectedDescription(
        selectLanguages.find((lang) => lang.value === account.locale),
      );
    }
  }, [edit, id, open]);

  const handleCreateCategory = () => {
    if (!categoryName.trim()) {
      toast('Category name required', {
        ...defaultToastProps,
        type: 'error',
      });
      return;
    } else if (
      !Object.keys(addCategoryTranslate).length ||
      !checkFieldNotEmpty(addCategoryTranslate)
    ) {
      toast('Category name translation required', {
        ...defaultToastProps,
        type: 'error',
      });
      return;
    } else if (
      description.trim() &&
      (!Object.keys(descriptionTranslate).length ||
        !checkFieldNotEmpty(descriptionTranslate))
    ) {
      toast('Category description translate required', {
        ...defaultToastProps,
        type: 'error',
      });
      return;
    }
    if (edit) {
      let flagChange = false;
      if (JSON.stringify(name?.translations) !== JSON.stringify(addCategoryTranslate)) {
        flagChange = true;
        dispatch(
          editCategory({
            categoryId: id,
            field: 'name',
            value: {
              source_lang: selected?.value,
              translations: addCategoryTranslate,
              withoutTranslate: withoutTranslationsName,
            },
            resetFields,
            globalCategoryId: globalMenuId,
          }),
        );
      }
      if (
        JSON.stringify(descriptionOld?.translations || {}) !==
        JSON.stringify(descriptionTranslate)
      ) {
        flagChange = true;
        dispatch(
          editCategory({
            categoryId: id,
            field: 'description',
            value: description.trim()
              ? {
                  source_lang: selectedDescription?.value,
                  translations: descriptionTranslate,
                  withoutTranslate: withoutTranslationsDescription,
                }
              : {},
            resetFields,
            globalCategoryId: globalMenuId,
          }),
        );
      }
      if (!flagChange) {
        setOpen(false);
        setTimeout(() => {
          resetFields();
        }, 250);
      }
    } else {
      dispatch(
        createCategory({
          name: {
            source_lang: selected?.value,
            translations: addCategoryTranslate,
            withoutTranslate: withoutTranslationsName,
          },
          description: description.trim()
            ? {
                source_lang: selectedDescription?.value,
                translations: descriptionTranslate,
                withoutTranslate: withoutTranslationsDescription,
              }
            : {},
          menu_id: activeGlobalMenu,
          setOpen: setOpen,
          resetFields,
          globalMenuId,
        }),
      );
    }
  };

  return (
    <AppModal open={open} size="sm:max-w-3xl">
      <div className="flex flex-col">
        <div className="flex w-full items-center justify-between mb-3">
          <h1>
            {edit ? (
              <FormattedMessage id="editCategory" />
            ) : (
              <FormattedMessage id="createCategory" />
            )}
          </h1>
          <XMarkIcon
            onClick={() => {
              if (!loadingTranslateLabmda) {
                setOpen(false);
                setTimeout(() => {
                  resetFields();
                }, 250);
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <AddCategoryStepper activeStep={activeStep} />
        <div className="w-full">
          {activeStep === 3 && (
            <div className="rounded-md bg-blue-50 p-4 mb-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ltr:ml-3 rtl:mr-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-blue-700">
                    <FormattedMessage id="descriptionCategory" />
                  </p>
                </div>
              </div>
            </div>
          )}
          {
            <>
              <AddCategorySelectLang
                activeStep={activeStep}
                loadingTranslateLabmda={loadingTranslateLabmda}
                selected={selected}
                setSelected={setSelected}
                selectedDescription={selectedDescription}
                setSelectedDescription={setSelectedDescription}
              />
              <AddCategoryTextField
                activeStep={activeStep}
                name={activeStep === 1 || activeStep === 2 ? categoryName : description}
                loading={loadingTranslateLabmda}
                setName={
                  activeStep === 1 || activeStep === 2 ? setCategoryName : setDescription
                }
                setTranslate={
                  activeStep === 1 || activeStep === 2
                    ? setAddCategoryTranslate
                    : setDescriptionTranslate
                }
                translate={
                  activeStep === 1 || activeStep === 2
                    ? addCategoryTranslate
                    : descriptionTranslate
                }
                mode={activeStep === 1 || activeStep === 2 ? 'name' : 'description'}
                withoutTranslations={
                  activeStep === 1 || activeStep === 2
                    ? withoutTranslationsName
                    : withoutTranslationsDescription
                }
                setWithoutTranslations={
                  activeStep === 1 || activeStep === 2
                    ? setWithoutTranslationsName
                    : setWithoutTranslationsDescription
                }
              />
            </>
          }
        </div>
        <div className="w-full flex justify-end mt-4">
          <div className="flex items-center space-x-4 rtl:space-x-reverse">
            {activeStep !== 1 && (
              <button
                disabled={loadingTranslateLabmda}
                onClick={() => {
                  if (activeStep === 3 && withoutTranslationsName) {
                    setActiveStep(1);
                    return;
                  }
                  setActiveStep((prev) => prev - 1);
                }}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="previous" />
              </button>
            )}
            <AddCategoryButton
              edit={edit}
              activeStep={activeStep}
              categoryName={categoryName}
              addCategoryTranslate={addCategoryTranslate}
              selected={selected}
              setAddCategoryTranslate={setAddCategoryTranslate}
              setLoadingTranslateLabmda={setLoadingTranslateLabmda}
              setActiveStep={setActiveStep}
              description={description}
              descriptionTranslate={descriptionTranslate}
              setDescriptionTranslate={setDescriptionTranslate}
              handleCreateCategory={handleCreateCategory}
              loadingTranslateLabmda={loadingTranslateLabmda}
              selectedDescription={selectedDescription}
              descriptionOld={descriptionOld}
              setOpen={setOpen}
              resetFields={resetFields}
              name={name}
              withoutTranslationsName={withoutTranslationsName}
              withoutTranslationsDescription={withoutTranslationsDescription}
            />
          </div>
        </div>
      </div>
    </AppModal>
  );
};
