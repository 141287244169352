import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { selectLanguages } from 'appConstants';
import { Languages, SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { changeMainLang } from 'store/account/actions';

interface ChangeMainLangModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  oldMainLang: Languages;
}

export const ChangeMainLangModal = ({
  open,
  setOpen,
  oldMainLang,
}: ChangeMainLangModalProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.account.subLoading);
  const [mainLang, setMainLang] = useState(oldMainLang);
  const [discardClick, setDiscardClick] = useState(0);

  useEffect(() => {
    setMainLang(oldMainLang);
  }, [oldMainLang]);

  const handleOldPasswordChange = () => {
    setDiscardClick(0);
    dispatch(
      changeMainLang({
        setOpen,
        newMainLang: mainLang,
      }),
    );
  };

  return (
    <AppModal open={open} size="sm:max-w-md">
      <div className="flex flex-col">
        <div className="flex items-center justify-between mb-3 w-full">
          <h1>
            <FormattedMessage id="mainLanguage" />{' '}
          </h1>
          <XMarkIcon
            onClick={() => {
              if (JSON.stringify(mainLang) === JSON.stringify(oldMainLang)) {
                setMainLang(oldMainLang);
                setDiscardClick(0);
                setOpen(false);
              } else {
                if (discardClick === 0) {
                  setDiscardClick(1);
                } else {
                  setMainLang(oldMainLang);
                  setDiscardClick(0);
                  setOpen(false);
                }
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="mb-4">
          <Listbox value={mainLang} onChange={setMainLang}>
            {({ open }) => (
              <>
                <div className="relative mt-1">
                  <Listbox.Button
                    as="div"
                    data-testId="cypress-locale-list-btn"
                    className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm md:focus:border-indigo-500 md:focus:outline-none md:focus:ring-1 md:focus:ring-indigo-500 sm:text-sm"
                  >
                    <span className="block truncate">
                      {selectLanguages.find((item) => item.value === mainLang)?.label}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full scrollbar overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none sm:text-sm">
                      {selectLanguages.map((item) => (
                        <Listbox.Option
                          data-testId={'cypress-locale-' + item.value}
                          key={'update-currency-' + item.value}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-white bg-indigo-600' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-8 pr-4',
                            )
                          }
                          value={item.value}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate',
                                )}
                              >
                                {item.label}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>

        <div className="w-full flex items-center justify-end">
          {JSON.stringify(mainLang) === JSON.stringify(oldMainLang) ? (
            <>
              <button
                type="button"
                disabled={loading}
                onClick={() => {
                  setMainLang(oldMainLang);
                  setDiscardClick(0);
                  setOpen(false);
                }}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="cancel" />
              </button>
            </>
          ) : (
            <div className="flex items-center">
              <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
                {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
              </div>
              <div className="flex items-center justify-end w-full sm:w-auto">
                <button
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    if (discardClick === 0) {
                      setDiscardClick(1);
                    } else {
                      setMainLang(oldMainLang);
                      setDiscardClick(0);
                      setOpen(false);
                    }
                  }}
                  className="ltr:mr-3 rtl:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  <FormattedMessage id="discardChanges" />
                </button>
                <button
                  disabled={loading}
                  onClick={handleOldPasswordChange}
                  type="button"
                  data-testId="cypress-locale-save"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  {loading ? <Spinner /> : <FormattedMessage id="save" />}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </AppModal>
  );
};
