import { useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ChangeMainAttrModal } from '../ChangeModals';

export const PhoneField = () => {
  const cognitoUser = useAppSelector((state) => state.account.cognitoUser);
  const [editPhone, setEditPhone] = useState(false);

  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
      <dt className="text-sm font-medium text-gray-500">
        <FormattedMessage id="phone" />
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow truncate">{cognitoUser?.attributes?.phone_number}</div>
      </dd>
      <ChangeMainAttrModal open={editPhone} setOpen={setEditPhone} />
    </div>
  );
};
