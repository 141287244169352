import { XMarkIcon } from '@heroicons/react/24/outline';
import { ISelections, SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { deleteOption, deleteSelection } from 'store/options/actions';

interface OptionDeleteModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  mode?: string;
  id?: string;
  activeSelection?: ISelections;
}

export const OptionDeleteModal = ({
  open,
  setOpen,
  mode = '',
  id,
  activeSelection,
}: OptionDeleteModalProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const loading = useAppSelector((state) => state.options.subLoading);

  return (
    <AppModal size="sm:max-w-xl" open={open}>
      <div className="w-full flex flex-col">
        <div className="flex w-full items-center justify-between mb-3">
          <h1>
            <FormattedMessage id={'delete' + mode[0].toUpperCase() + mode.slice(1)} />
          </h1>
          <XMarkIcon
            onClick={() => {
              setOpen(false);
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="mb-3">
          <p className="text-gray-500 rtl:text-right">
            <FormattedMessage
              id={'delete' + mode[0].toUpperCase() + mode.slice(1) + 'Info'}
            />
          </p>
        </div>

        <div className="flex items-center justify-end w-full">
          <button
            type="button"
            onClick={() => {
              setOpen(false);
            }}
            className="ltr:mr-3 rtl:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <FormattedMessage id="cancel" />
          </button>
          <button
            onClick={() => {
              if (mode === 'selection') {
                dispatch(
                  deleteSelection({
                    id: activeSelection?.id,
                    setOpen,
                    optionId: id,
                  }),
                );
              } else {
                dispatch(
                  deleteOption({
                    id,
                    setOpen,
                    history,
                  }),
                );
              }
            }}
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            {loading ? <Spinner color="bg-white" /> : <FormattedMessage id="delete" />}
          </button>
        </div>
      </div>
    </AppModal>
  );
};
