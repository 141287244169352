import { AppModal } from '@Common/AppModal';
import { CropDropdown } from '@Common/CropDropdown';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { useAppSelector } from 'hooks/store';
import { FormattedMessage } from 'react-intl';

interface ChangeLogoModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
}

export const ChangeLogoModal = ({ open, setOpen }: ChangeLogoModalProps) => {
  const loadingImages = useAppSelector((state) => state.image.loading);

  return (
    <AppModal open={open} size="sm:max-w-3xl">
      <div className="flex flex-col">
        <div className="flex items-center justify-between w-full">
          <h1>
            <FormattedMessage id="logo" />
          </h1>
          <XMarkIcon
            onClick={() => {
              if (!loadingImages) {
                setOpen(false);
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <CropDropdown setOpen={setOpen} profileMode={true} logoMode />
      </div>
    </AppModal>
  );
};
