import { FormattedMessage } from 'react-intl';

interface AddItemTitleProps {
  activeStep: number;
}

export const AddItemTitle = ({ activeStep }: AddItemTitleProps) => {
  const titleData = [
    <FormattedMessage key={'add-item-title-name'} id="name" />,
    <FormattedMessage key={'add-item-title-description'} id="description" />,
    <FormattedMessage key={'add-item-title-mainInfo'} id="mainInfo" />,
    <FormattedMessage key={'add-item-title-availability'} id="availability" />,
    <FormattedMessage key={'add-item-title-options'} id="options" />,
    <FormattedMessage key={'add-item-title-suggestions'} id="suggestions" />,
    <FormattedMessage key={'add-item-title-photo'} id="photo" />,
  ];
  return <h1 className="mb-2 text-lg text-gray-500">{titleData[activeStep]}</h1>;
};
