import { Dialog } from '@headlessui/react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { FormattedMessage } from 'react-intl';
import { enableMenu } from 'store/menu/actions';

interface GlobalMenusEnableModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  mode: number;
  setEnableGlobalMenus: SetStateType<boolean>;
  setActiveGlobalMenu: SetStateType<string>;
}

export const GlobalMenusEnableModal = ({
  open,
  setOpen,
  mode,
  setEnableGlobalMenus,
  setActiveGlobalMenu,
}: GlobalMenusEnableModalProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.menu.subLoading);

  const resetFields = () => {
    setOpen(false);
    setTimeout(() => {
      if (mode === 1) {
        setEnableGlobalMenus(true);
      } else {
        setEnableGlobalMenus(false);
      }
    }, 250);
  };

  const confirmHandler = () => {
    dispatch(
      enableMenu({
        mode,
        resetFields,
        setActiveGlobalMenu,
      }),
    );
  };

  return (
    <AppModal open={open}>
      <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
        <button
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-gray-500"
          onClick={() => {
            setOpen(false);
            if (mode === 1) {
              setEnableGlobalMenus(false);
            } else {
              setEnableGlobalMenus(true);
            }
          }}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div
          className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
            mode === 1 ? 'bg-primary/10' : 'bg-red-100'
          } sm:mx-0 sm:h-10 sm:w-10`}
        >
          <ExclamationTriangleIcon
            className={`h-6 w-6 ${mode === 1 ? 'text-primary' : 'text-red-600'}`}
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            <FormattedMessage
              id={mode === 1 ? 'enableGlobalMenus' : 'disableGlobalMenus'}
            />
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              <FormattedMessage
                id={mode === 1 ? 'enableGlobalMenusText' : 'disableGlobalMenusText'}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className={`inline-flex w-full justify-center rounded-md ${
            mode === 1 ? 'bg-primary hover:bg-primary/70' : 'bg-red-500 hover:bg-red-500'
          } px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto`}
          onClick={confirmHandler}
        >
          {loading ? <Spinner color="bg-white" /> : <FormattedMessage id="confirm" />}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {
            setOpen(false);
            if (mode === 1) {
              setEnableGlobalMenus(false);
            } else {
              setEnableGlobalMenus(true);
            }
          }}
        >
          <FormattedMessage id="cancel" />
        </button>
      </div>
    </AppModal>
  );
};
