import { XMarkIcon } from '@heroicons/react/24/outline';
import { defaultToastProps } from 'appConstants';
import { AvaliblePassword, SetStateType } from 'commonTypes';
import { AppInput } from 'components/Common/AppInput';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { RegisterAvailablePassword } from 'components/RegisterComponent/UI/RegisterAvailablePassword';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { changePassword } from 'store/account/actions';

interface ChangePasswordModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
}

export const ChangePasswordModal = ({ open, setOpen }: ChangePasswordModalProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const loading = useAppSelector((state) => state.account.subLoading);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [avaliblePassword, setAvaliblePassword] = useState<AvaliblePassword>({
    length: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    special: false,
  });
  const [discardClick, setDiscardClick] = useState(0);

  const resetFields = () => {
    setTimeout(() => {
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setAvaliblePassword({
        length: false,
        upperCase: false,
        lowerCase: false,
        number: false,
        special: false,
      });
    }, 200);
  };

  useEffect(() => {
    const newAvaliblePassword = { ...avaliblePassword };
    if (newPassword.length >= 8) {
      newAvaliblePassword.length = true;
    } else {
      newAvaliblePassword.length = false;
    }

    if (newPassword.match(/[A-Z]/)) {
      newAvaliblePassword.upperCase = true;
    } else {
      newAvaliblePassword.upperCase = false;
    }

    if (newPassword.match(/[a-z]/)) {
      newAvaliblePassword.lowerCase = true;
    } else {
      newAvaliblePassword.lowerCase = false;
    }

    if (newPassword.match(/[0-9]/)) {
      newAvaliblePassword.number = true;
    } else {
      newAvaliblePassword.number = false;
    }

    if (newPassword.match(/[!@#$%^&*(),_.?":{}|<>-]/)) {
      newAvaliblePassword.special = true;
    } else {
      newAvaliblePassword.special = false;
    }

    setAvaliblePassword(newAvaliblePassword);
  }, [newPassword]);

  useEffect(() => {
    return () => {
      resetFields();
    };
  }, []);

  const handleOldPasswordChange = () => {
    setDiscardClick(0);
    if (newPassword !== confirmPassword) {
      toast('Password mismatch', {
        ...defaultToastProps,
        type: 'error',
      });
    } else if (newPassword.trim() === '') {
      toast('Password cannot be empty', {
        ...defaultToastProps,
        type: 'error',
      });
    } else if (Object.values(avaliblePassword).includes(false)) {
      toast('The password is not strong enough', {
        ...defaultToastProps,
        type: 'error',
      });
    } else {
      dispatch(
        changePassword({
          oldPassword,
          newPassword,
          setOpen,
          history,
        }),
      );
    }
  };

  return (
    <AppModal open={open} size="sm:max-w-md">
      <div className="flex flex-col">
        <div className="flex items-center justify-between w-full mb-3">
          <h1>
            <FormattedMessage id="changePassword" />{' '}
          </h1>
          <XMarkIcon
            onClick={() => {
              if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setDiscardClick(0);
                setOpen(false);
              } else {
                if (discardClick === 0) {
                  setDiscardClick(1);
                } else {
                  setOldPassword('');
                  setNewPassword('');
                  setConfirmPassword('');
                  setDiscardClick(0);
                  setOpen(false);
                }
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="mb-4">
          <AppInput
            label="oldPassword"
            id="oldPassword"
            name="oldPassword"
            type="text"
            placeholder="Old Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <AppInput
            label="newPassword"
            id="newPassword"
            name="newPassword"
            type="text"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <RegisterAvailablePassword avaliblePassword={avaliblePassword} />
        </div>
        <div className="mb-4">
          <AppInput
            label="repeatPassword"
            id="repeatPassword"
            name="repeatPassword"
            type="text"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div className="w-full flex items-center justify-end">
          {oldPassword === '' || newPassword === '' || confirmPassword === '' ? (
            <>
              <button
                type="button"
                disabled={loading}
                onClick={() => {
                  setOldPassword('');
                  setNewPassword('');
                  setConfirmPassword('');
                  setOpen(false);
                }}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="cancel" />
              </button>
            </>
          ) : (
            <div className="flex items-center">
              <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
                {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
              </div>
              <div className="flex items-center justify-end w-full sm:w-auto">
                <button
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    if (discardClick === 0) {
                      setDiscardClick(1);
                    } else {
                      setOldPassword('');
                      setNewPassword('');
                      setConfirmPassword('');
                      setDiscardClick(0);
                      setOpen(false);
                    }
                  }}
                  className="ltr:mr-3 rtl:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  <FormattedMessage id="discardChanges" />
                </button>
                <button
                  disabled={loading}
                  onClick={handleOldPasswordChange}
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  {loading ? <Spinner /> : <FormattedMessage id="save" />}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </AppModal>
  );
};
