import { XMarkIcon } from '@heroicons/react/24/outline';
import { defaultToastProps, selectLanguages } from 'appConstants';
import { Languages, SelectedLanguage, SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { changeTranslatedLang } from 'store/account/actions';

interface ChangeTranslatedLangModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  oldTranslatedLang: Languages[];
}

export const ChangeTranslatedLangModal = ({
  open,
  setOpen,
  oldTranslatedLang,
}: ChangeTranslatedLangModalProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.account.subLoading);
  const [translatedLang, setTranslatedLang] = useState(
    selectLanguages.filter((item) => oldTranslatedLang?.includes(item.value)),
  );
  const [discardClick, setDiscardClick] = useState(0);

  useEffect(() => {
    setTranslatedLang(
      selectLanguages.filter((item) => oldTranslatedLang?.includes(item.value)),
    );
  }, [oldTranslatedLang]);

  const handleOldPasswordChange = () => {
    setDiscardClick(0);
    if (translatedLang.length === 0) {
      toast('Please select at least one language', {
        ...defaultToastProps,
        type: 'error',
      });
      return;
    } else {
      dispatch(
        changeTranslatedLang({
          setOpen,
          newTranslatedLang: translatedLang,
        }),
      );
    }
  };

  return (
    <AppModal open={open} size="sm:max-w-xl">
      <div className="flex flex-col">
        <div className="flex items-center justify-between mb-3 w-full">
          <h1>
            <FormattedMessage id="translatedLanguages" />{' '}
          </h1>
          <XMarkIcon
            onClick={() => {
              if (discardClick === 0) {
                setDiscardClick(1);
              } else {
                setTranslatedLang(
                  selectLanguages.filter((item) =>
                    oldTranslatedLang.includes(item.value),
                  ),
                );
                setDiscardClick(0);
                setOpen(false);
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="mb-4">
          <Select
            options={selectLanguages}
            isMulti
            value={translatedLang}
            onChange={(v) => {
              if (v.length !== 0 && v.length <= 5) {
                setTranslatedLang(v as SelectedLanguage[]);
              }
            }}
          />
        </div>

        <div className="w-full flex items-center justify-end">
          <div className="text-sm text-red-500 ltr:mr-2 rtl:ml-2">
            {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
          </div>
          <button
            type="button"
            disabled={loading}
            onClick={() => {
              if (discardClick === 0) {
                setDiscardClick(1);
              } else {
                setTranslatedLang(
                  selectLanguages.filter((item) =>
                    oldTranslatedLang?.includes(item.value),
                  ),
                );
                setDiscardClick(0);
                setOpen(false);
              }
            }}
            className="ltr:mr-3 rtl:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="discardChanges" />
          </button>
          <button
            disabled={loading}
            onClick={handleOldPasswordChange}
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            {loading ? <Spinner /> : <FormattedMessage id="save" />}
          </button>
        </div>
      </div>
    </AppModal>
  );
};
