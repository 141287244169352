import { allergensFieldIcons, oldLanguages, weekDaysObj } from 'appConstants';
import { PriceElement } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { LabelsPreview } from 'components/Common/LabelsPreview';
import { returnNumberPrice } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  ChangeAllergensModal,
  ChangeAvailableModal,
  ChangeCategoryContent,
  ChangeItemPriceModal,
  ChangeLabelsModal,
  ChangeQuantityModal,
} from '../ChangeModals';
import { EditItemTextField, ItemTableRow } from '../UI';

interface MultiPriceContentProps {
  multiplePriceValue: PriceElement[];
}

const MultiPriceContent = ({ multiplePriceValue }: MultiPriceContentProps) => {
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const account = useAppSelector((state) => state.account.account);

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Name
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Value
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
          >
            Default
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {multiplePriceValue.map((priceValue) => (
          <tr key={'multiple-price-' + priceValue.id}>
            <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-900">
              <div className="relative rounded-md shadow-sm sm:text-sm">
                {priceValue?.name}
              </div>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <FormattedNumber
                style={'currency'}
                value={+priceValue.value}
                currency={account?.currency}
                maximumFractionDigits={intCurrency ? 0 : 2}
              />
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <div className="w-full flex items-center justify-center">
                <div
                  className={`w-4 focus:ring-indigo-500 h-4 rounded-full flex items-center justify-center ${
                    priceValue.is_default ? 'bg-primary' : 'border border-gray-300'
                  }`}
                >
                  <div className="w-1.5 h-1.5 rounded-full bg-white" />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

interface FirstItemTabProps {
  activeGlobalMenu: string;
}

interface RouteParams {
  id: string;
}

export const FirstItemTab = ({ activeGlobalMenu }: FirstItemTabProps) => {
  const { id } = useParams<RouteParams>();
  const account = useAppSelector((state) => state.account.account);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const locale = useAppSelector((state) => state.locale.lang);
  const active = useAppSelector((state) => state.item.activeItem);
  const activeItem = useAppSelector((state) => state.item.activeItem);
  const categoryListObj = useAppSelector(
    (state) => state.category.activeCategory.categoryListObj,
  );
  const beutifyMenu = useAppSelector((state) => state.category.beutifyMenu);

  const [openCategoryChange, setOpenCategoryChange] = useState(false);
  const [openPriceChange, setOpenPriceChange] = useState(false);
  const [openAllergensChange, setOpenAllergensChange] = useState(false);
  const [openQuantityChange, setOpenQuantityChange] = useState(false);
  const [showEditTextModal, setShowEditTextModal] = useState(false);
  const [showAvailableModal, setShowAvailableModal] = useState(false);
  const [showEditDescriptionModal, setShowEditDescriptionModal] = useState(false);
  const [showEditLabelsModal, setShowEditLabelsModal] = useState(false);

  const priceContent = () => {
    if (active && active.price?.type === 'single') {
      if (returnNumberPrice(active.price) === -1) {
        return <FormattedMessage id="free" />;
      } else {
        return (
          <FormattedNumber
            style={'currency'}
            value={returnNumberPrice(active.price)}
            currency={account?.currency}
            maximumFractionDigits={intCurrency ? 0 : 2}
          />
        );
      }
    } else if (active) {
      return <MultiPriceContent multiplePriceValue={active.price?.prices} />;
    } else {
      return null;
    }
  };

  if (!active || !beutifyMenu) {
    return null;
  } else {
    return (
      <div className="w-full flex flex-col">
        <dl className="divide-y divide-gray-200">
          {/* Display & change name % description */}
          <>
            <ItemTableRow
              label="name"
              value={
                active?.name?.translations
                  ? active?.name?.translations[oldLanguages[locale]]
                  : ''
              }
              setShowModal={setShowEditDescriptionModal}
              setShowEditModal={setShowEditTextModal}
            />
            <ItemTableRow
              label="description"
              value={
                active?.description?.translations ? (
                  active?.description?.translations[oldLanguages[locale]]
                ) : (
                  <FormattedMessage id="noDescription" />
                )
              }
              setShowModal={setShowEditDescriptionModal}
              setShowEditModal={setShowEditTextModal}
            />
            <EditItemTextField
              open={showEditTextModal}
              setOpen={setShowEditTextModal}
              showEditDescriptionModal={showEditDescriptionModal}
              globalCategoryId={activeGlobalMenu}
            />
          </>

          <>
            <ItemTableRow
              label="quantity"
              value={
                active?.quantity?.amount ? (
                  active?.quantity.value + ' ' + active?.quantity.amount
                ) : (
                  <FormattedMessage id="no" />
                )
              }
              setShowEditModal={setOpenQuantityChange}
            />

            <ChangeQuantityModal
              open={openQuantityChange}
              setOpen={setOpenQuantityChange}
              globalCategoryId={activeGlobalMenu}
            />
          </>

          {/* Display & change category */}
          <>
            <ItemTableRow
              label="category"
              value={(() => {
                const categoryID = active?.category_id;
                const menuID = beutifyMenu[active?.id]?.menuId;
                if (categoryID && menuID) {
                  const categoryTranslate =
                    categoryListObj[menuID][categoryID]?.name?.translations;
                  if (categoryTranslate) {
                    return categoryTranslate[oldLanguages[locale]];
                  } else {
                    return '';
                  }
                } else {
                  return '';
                }
              })()}
              setShowEditModal={setOpenCategoryChange}
            />
            <AppModal open={openCategoryChange} size="sm:max-w-md">
              <ChangeCategoryContent
                id={activeItem?.category_id || ''}
                setOpenCategoryChange={setOpenCategoryChange}
                itemId={id}
                globalCategoryId={beutifyMenu[active?.id]?.menuId}
              />
            </AppModal>
          </>

          {/* Display & change price */}
          <>
            <ItemTableRow
              label="price"
              value={priceContent()}
              setShowEditModal={setOpenPriceChange}
            />
            <ChangeItemPriceModal
              setOpenPriceChange={setOpenPriceChange}
              openPriceChange={openPriceChange}
              globalCategoryId={activeGlobalMenu}
            />
          </>

          {/* Display & change allergens */}
          <>
            <ItemTableRow
              label="allergens"
              value={
                Object.keys(active?.allergens).filter(
                  (item) => active?.allergens[item] !== 0,
                ).length === 0 ? (
                  <FormattedMessage id="no" />
                ) : (
                  <div className="flex w-full items-center space-x-3 rtl:space-x-reverse">
                    {Object.keys(active?.allergens).map(
                      (allergen, index) =>
                        active?.allergens[allergen] !== 0 && (
                          <div
                            key={'allergens-icon-item-' + index}
                            id={'allergens-icon-item-id' + index}
                            data-tip
                            data-for={allergen}
                          >
                            {
                              allergensFieldIcons[
                                (active?.allergens[allergen] === 1
                                  ? allergen
                                  : 'no' + allergen) as keyof typeof allergensFieldIcons
                              ]?.icon
                            }
                          </div>
                        ),
                    )}
                  </div>
                )
              }
              setShowEditModal={setOpenAllergensChange}
            />
            <ChangeAllergensModal
              openAllergensChange={openAllergensChange}
              setOpenAllergensChange={setOpenAllergensChange}
              globalCategoryId={activeGlobalMenu}
            />
          </>

          {/* Display & change price */}
          <>
            <ItemTableRow
              label="availability"
              value={
                <div className="scrollbar overflow-auto flex">
                  {active?.availability?.days?.length === 7 && (
                    <div className="ltr:mr-2 rtl:ml-2 whitespace-nowrap">
                      <FormattedMessage id="everyDay" />,
                    </div>
                  )}
                  {active?.availability?.days?.length !== 7 &&
                    active?.availability?.days?.map((item, index) => {
                      return (
                        <div
                          key={'available-days-' + index}
                          className="flex ltr:mr-2 rtl:ml-2 whitespace-nowrap"
                        >
                          <FormattedMessage
                            id={weekDaysObj[item as keyof typeof weekDaysObj]}
                          />
                          ,
                        </div>
                      );
                    })}
                  {active?.availability?.time?.length === 1 &&
                    active?.availability?.time[0]?.split('-')[0] ===
                      active?.availability?.time[0]?.split('-')[1] && (
                      <div className="whitespace-nowrap">
                        <FormattedMessage id="everyTime" />
                      </div>
                    )}
                  {(active?.availability?.time?.length !== 1 ||
                    active?.availability?.time[0]?.split('-')[0] !==
                      active?.availability?.time[0]?.split('-')[1]) &&
                    active?.availability?.time?.map((item, index) => {
                      return (
                        <div
                          key={'available-days-' + index}
                          className="flex ltr:mr-2 rtl:ml-2 whitespace-nowrap"
                        >
                          {item.slice(0, 2)}:{item.slice(2, 4)} - {item.slice(5, 7)}:
                          {item.slice(7, 9)}
                          {index !== active?.availability?.time?.length - 1 && ','}
                        </div>
                      );
                    })}
                </div>
              }
              setShowEditModal={setShowAvailableModal}
            />
            <ChangeAvailableModal
              open={showAvailableModal}
              setOpen={setShowAvailableModal}
              availability={activeItem?.availability}
              globalCategoryId={activeGlobalMenu}
            />
          </>

          {/* Display & change labels */}
          <>
            <ItemTableRow
              label="labels"
              value={<LabelsPreview dish={active} />}
              setShowEditModal={setShowEditLabelsModal}
            />
            <ChangeLabelsModal
              openLabelsChange={showEditLabelsModal}
              setOpenLabelsChange={setShowEditLabelsModal}
              globalCategoryId={activeGlobalMenu}
            />
          </>
        </dl>
      </div>
    );
  }
};
