import { XCircleIcon } from '@heroicons/react/20/solid';
import { weekDays } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { EDIT_ITEM_FIELD } from 'store/item/actionsTypes';

interface CheckAvailabilityFieldProps {
  itemCreated: boolean;
  days: number[];
  setDays: SetStateType<number[]>;
  time: {
    timeStart: string;
    timeEnd: string;
    id: number;
  }[];
  setTime: SetStateType<
    {
      timeStart: string;
      timeEnd: string;
      id: number;
    }[]
  >;
  everyTime: boolean;
  everyDay: boolean;
  setEverytime: SetStateType<boolean>;
  setEveryday: SetStateType<boolean>;
}

export const CheckAvailabilityField = ({
  itemCreated,
  days,
  setDays,
  time,
  setTime,
  everyTime,
  everyDay,
  setEverytime,
  setEveryday,
}: CheckAvailabilityFieldProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (everyTime) {
      setTime([{ timeStart: '00:00', timeEnd: '00:00', id: 0 }]);
    }
    if (everyDay) {
      setDays([1, 2, 3, 4, 5, 6, 7]);
    }
  }, [everyTime, everyDay]);

  useEffect(() => {
    if (!itemCreated) {
      dispatch({
        type: EDIT_ITEM_FIELD,
        payload: {
          field: 'availability',
          value: {
            days: days,
          },
        },
      });
      dispatch({
        type: EDIT_ITEM_FIELD,
        payload: {
          field: 'availability',
          value: {
            time: time,
          },
        },
      });
    }
  }, [days, time]);

  return (
    <div className="mb-5">
      <div className="w-full flex items-start md:items-center flex-col">
        <div className="flex items-center mb-4">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="allDays"
                name="allDays"
                type="checkbox"
                checked={everyDay}
                onChange={(e) => setEveryday(e.target.checked)}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-2 text-sm leading-6">
              <label htmlFor="allDays" className="font-medium text-gray-900 select-none">
                <FormattedMessage id="everyDay" />
              </label>
            </div>
          </div>
          <div className="relative flex items-start ml-5">
            <div className="flex h-6 items-center">
              <input
                id="allDay"
                name="allDay"
                type="checkbox"
                checked={everyTime}
                onChange={(e) => setEverytime(e.target.checked)}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-2 text-sm leading-6">
              <label htmlFor="allDay" className="font-medium text-gray-900 select-none">
                <FormattedMessage id="everyTime" />
              </label>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            everyDay ? 'opacity-60' : '',
            'overflow-auto md:overflow-hidden flex items-center scrollbar max-w-full pb-1',
          )}
        >
          {weekDays.map((item, index) => (
            <div
              role="button"
              tabIndex={0}
              key={'week-days-button-' + index}
              onClick={() => {
                if (!itemCreated && !everyDay) {
                  setDays((prev) => {
                    if (prev.includes(item.id) && prev.length > 1) {
                      return prev.filter((elem) => elem !== item.id);
                    } else if (!prev.includes(item.id)) {
                      return [...prev, item.id];
                    } else {
                      return prev;
                    }
                  });
                }
              }}
              className={classNames(
                'flex items-center relative justify-center flex-shrink-0 w-[60px] h-[60px] border rounded-full font-medium text-center cursor-pointer rtl:ml-3 ltr:mr-3 rtl:las:ml-0 ltr:last:mr-0 mt-2 md:mt-0',
                days.includes(item.id)
                  ? 'border-primary bg-primary bg-opacity-10 text-primary'
                  : 'border-gray-300 bg-white text-gray-500',
              )}
            >
              {item.short}
            </div>
          ))}
        </div>
        <div
          className={classNames(
            everyTime ? 'opacity-60' : '',
            'flex flex-col max-h-60 overflow-auto scrollbar mt-4',
          )}
        >
          {time.map((item, index) => (
            <div
              className="flex items-center mt-2.5 flex-wrap sm:flex-nowrap"
              key={'time-period-' + index}
            >
              <div className="border rounded-lg border-gray-300 relative rtl:ml-3 ltr:mr-3">
                <input
                  type="time"
                  name="start-time"
                  id={'start-time' + item.id}
                  placeholder="00:00"
                  disabled={itemCreated || everyTime}
                  value={item.timeStart}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                      setTime((prev) => {
                        const newState = [...prev];
                        newState.forEach((elem) => {
                          if (elem.id === item.id) {
                            elem.timeStart = value;
                          }
                        });
                        return newState;
                      });
                    }
                  }}
                  className="border-none rounded-lg"
                />
                <div className="text-gray-400 text-xs absolute -top-[9px] bg-white rtl:right-2 ltr:left-2 px-[2px] whitespace-nowrap">
                  {/* <FormattedMessage id="startTime" /> */}
                  start time
                </div>
              </div>
              <div
                className={`border rounded-lg border-gray-300 relative rtl:ml-6 ltr:mr-6 `}
              >
                <input
                  type="time"
                  name="end-time"
                  id={'end-time' + item.id}
                  placeholder="00:00"
                  disabled={itemCreated || everyTime}
                  value={item.timeEnd}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                      setTime((prev) => {
                        const newState = [...prev];
                        newState.forEach((elem) => {
                          if (elem.id === item.id) {
                            elem.timeEnd = value;
                          }
                        });
                        return newState;
                      });
                    }
                  }}
                  className="border-none rounded-lg"
                />
                <div className="text-gray-400 text-xs absolute -top-[9px] bg-white rtl:right-2 ltr:left-2 px-[2px] whitespace-nowrap">
                  {/* <FormattedMessage id="endTime" /> */}
                  end time
                </div>
              </div>
              {index === 0 && (
                <button
                  disabled={itemCreated || everyTime}
                  onClick={() => {
                    setTime((prev) => [
                      ...prev,
                      {
                        timeStart: '00:00',
                        timeEnd: '00:00',
                        id: prev[prev.length - 1].id + 1,
                      },
                    ]);
                  }}
                  className="text-gray-500 whitespace-nowrap rounded-full self-stretch px-4 py-2.5 mt-3 sm:mt-0 sm:py-0 bg-gray-100 md:hover:bg-gray-200 md:hover:text-gray-600"
                >
                  +<FormattedMessage id="addTimePeriod" />
                </button>
              )}
              {index !== 0 && (
                <button
                  disabled={itemCreated || everyTime}
                  onClick={() => {
                    setTime((prev) => {
                      return prev.filter((elem) => elem.id !== item.id);
                    });
                  }}
                >
                  <XCircleIcon className="h-6 w-6 text-gray-400 md:hover:text-gray-500" />
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
