import { ModalChangeLanguage } from '@Common/ModalChangeLanguage';
import { Disclosure } from '@headlessui/react';
import { SelectedLanguage, SetStateType } from 'commonTypes';

import { AddSelectionField } from './AddSelectionField';

interface AddOptionThirdStepProps {
  loadingTranslate: boolean;
  selectedSelectionsLang: SelectedLanguage | undefined;
  setSelectedSelectionLang: SetStateType<SelectedLanguage | undefined>;
  selections: any;
  multiChoice: boolean;
  setSelections: any;
  selectionCount: number;
  toppins: boolean;
}

export const AddOptionThirdStep = ({
  loadingTranslate,
  selectedSelectionsLang,
  setSelectedSelectionLang,
  selections,
  multiChoice,
  setSelections,
  selectionCount,
  toppins,
}: AddOptionThirdStepProps) => {
  return (
    <div className="mb-3">
      <ModalChangeLanguage
        disabled={loadingTranslate}
        selected={selectedSelectionsLang}
        setSelected={setSelectedSelectionLang}
      />
      {Object.keys(selections).map((item, i) => {
        return (
          <Disclosure as="div" key={'add-selection-name-' + item}>
            {({ open }) => (
              <AddSelectionField
                disabled={loadingTranslate}
                id={item}
                multiChoice={multiChoice}
                // @ts-ignore
                selection={selections[item]}
                selections={selections}
                setSelections={setSelections}
                testId={'selection-' + i}
                open={open}
                selectionCount={selectionCount}
                toppins={toppins}
              />
            )}
          </Disclosure>
        );
      })}
      <div className="w-full flex items-center mt-4">
        <div className="w-1/2 h-px bg-gray-100" />
        <button
          onClick={() => {
            setSelections((prev: any) => {
              const lastId = +Object.keys(prev)[Object.keys(prev).length - 1];
              return {
                ...prev,
                [lastId + 1]: {
                  id: lastId + 1,
                  name: '',
                  nameTranslate: {},
                  withoutTranslate: false,
                  price: 0,
                  default_value: false,
                },
              };
            });
          }}
          className="p-1 w-11 h-11 rounded-full bg-gray-100 text-gray-500 text-2xl hover:bg-gray-200 transition-all"
        >
          +
        </button>
        <div className="w-1/2 h-px bg-gray-100" />
      </div>
    </div>
  );
};
