import { QuantityState, SetStateType } from 'commonTypes';
import { positiveNumberInput } from 'helpers/validation';
import { FormattedMessage } from 'react-intl';

interface QuantityFieldProps {
  quantity: QuantityState;
  setQuantity: SetStateType<QuantityState>;
}

export const QuantityField = ({ quantity, setQuantity }: QuantityFieldProps) => {
  return (
    <dl className="divide-y divide-gray-200">
      <div className="py-2 sm:grid sm:grid-cols-[150px_1fr] sm:gap-4 sm:py-3 items-center">
        <dt className="text-sm font-medium text-gray-500 truncate">
          <FormattedMessage id="quantity" />
        </dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-1 sm:mt-0 overflow-auto scrollbar">
          <div className="flex-grow">
            <div className="flex items-center space-x-4">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-2 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left text-sm font-semibold text-gray-900"
                    >
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  <tr>
                    <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900">
                      <div className="relative rounded-md shadow-sm">
                        <input
                          type="text"
                          id={'quantity-name'}
                          className="block w-full rounded-md border-gray-300 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm"
                          placeholder="Quantity name"
                          value={quantity.amount}
                          onChange={(e) => {
                            setQuantity((prev) => {
                              return {
                                ...prev,
                                amount: e.target.value,
                              };
                            });
                          }}
                        />
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                      <div className="relative rounded-md shadow-sm">
                        <input
                          type="text"
                          id={'quantity-value'}
                          className="block w-full rounded-md border-gray-300 rtl:pl-12 ltr:pr-12 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm"
                          placeholder={'Quantity value'}
                          value={quantity.value}
                          onChange={(e) => {
                            setQuantity((prev) => {
                              return {
                                ...prev,
                                value: positiveNumberInput(e),
                              };
                            });
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </dd>
      </div>
    </dl>
  );
};
