import { SetStateType } from 'commonTypes';

interface RegisterTextInputProps {
  label: string;
  value: string;
  setValue: SetStateType<string>;
  id: string;
  name: string;
  required?: boolean;
  autoComplete?: string;
  type?: string;
  placeholder?: string;
  className?: string;
}

export const RegisterTextInput = ({
  label,
  value,
  setValue,
  id,
  name,
  required = false,
  autoComplete = '',
  type = 'text',
  placeholder = '',
  className = '',
}: RegisterTextInputProps) => {
  return (
    <div className={className}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <input
          id={id}
          name={name}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete}
          required={required}
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm md:focus:border-primary md:focus:outline-none md:focus:ring-primary sm:text-sm"
        />
      </div>
    </div>
  );
};
