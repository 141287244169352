import { FormattedMessage } from 'react-intl';

interface AddOptionSteperProps {
  step: number;
}

export const AddOptionSteper = ({ step }: AddOptionSteperProps) => {
  return (
    <>
      <div className="mb-3">
        <div className="mt-3" aria-hidden="true">
          <div className="overflow-hidden rounded-full bg-gray-200">
            <div
              className="h-2 rounded-full bg-indigo-600"
              style={{ width: (step / 5) * 100 + '%' }}
            />
          </div>
        </div>
      </div>

      {step === 0 && (
        <p className="text-base rtl:text-right text-gray-400 font-medium mb-3">
          <FormattedMessage id="fillOptionName" />
        </p>
      )}
      {step === 1 && (
        <p className="text-base rtl:text-right text-gray-400 font-medium mb-3">
          <FormattedMessage id="checkOptionTranslate" />
        </p>
      )}
      {step === 2 && (
        <p className="text-base rtl:text-right text-gray-400 font-medium mb-3">
          <FormattedMessage id="fillMainInfo" />
        </p>
      )}
      {step === 3 && (
        <p className="text-base rtl:text-right text-gray-400 font-medium mb-3">
          <FormattedMessage id="fillSelections" />
        </p>
      )}
      {step === 4 && (
        <p className="text-base rtl:text-right text-gray-400 font-medium mb-3">
          <FormattedMessage id="checkSelectionsTranslate" />
        </p>
      )}
      {step === 5 && (
        <p className="text-base rtl:text-right text-gray-400 font-medium mb-3">
          <FormattedMessage id="preview" />
        </p>
      )}
    </>
  );
};
