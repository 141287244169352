import {
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from '@heroicons/react/24/outline';
import { ReactComponent as RotateIcon } from 'assets/static/rotateIcon.svg';
import { SetStateType } from 'commonTypes';
import { canvasPreview, useDebounceEffect } from 'helpers/hooks';
import { useAppSelector } from 'hooks/store';
import { useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { useDispatch } from 'react-redux';
import { STOP_FETCH_IMAGES } from 'store/images/actionTypes';

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

interface CropImageProps {
  scale: number;
  setScale: SetStateType<number>;
  imgSrc: string;
  rotate: number;
  setRotate: SetStateType<number>;
  crop: any;
  setCrop: SetStateType<any>;
  completedCrop: {
    unit: string;
    width: number;
    height: number;
    x: number;
    y: number;
  };
  setCompletedCrop: SetStateType<{
    unit: string;
    width: number;
    height: number;
    x: number;
    y: number;
  }>;
  logoMode?: boolean;
}

export const CropImage = ({
  scale,
  setScale,
  imgSrc,
  rotate,
  setRotate,
  crop,
  setCrop,
  completedCrop,
  setCompletedCrop,
  logoMode = false,
}: CropImageProps) => {
  const dispatch = useDispatch();
  const imgRef = useRef(null);
  const aspect = 4 / 3;
  const loadingImages = useAppSelector((state) => state.image.loading);

  function onImageLoad(e: any) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      if (completedCrop.unit === '%') {
        setCrop(centerAspectCrop(width, height, aspect));
      }
      dispatch({
        type: STOP_FETCH_IMAGES,
      });
    }
  }

  useDebounceEffect(
    async () => {
      const previewCanvasRef = document.getElementById(
        'previewCanvasRef',
      ) as HTMLCanvasElement;
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  return (
    <div className="App">
      {/*<div className='w-full flex items-center mt-2 mb-3'>
        <div className='text-gray-400'>{photo?.name}</div>
        <XMarkIcon
          onClick={() => {
            setPhoto(null)
            setImgSrc('')
            setCrop(null)
          }}
          className='w-5 h-5 ltr:ml-2 rtl:mr-2 text-gray-400 md:hover:text-gray-500 cursor-pointer'
        />
      </div>*/}
      <div className="w-full flex items-center justify-between mt-2">
        <div className="flex items-center">
          <button
            disabled={loadingImages}
            type="button"
            className="cursor-pointer md:hover:bg-gray-100 bg-gray-50 border border-gray-300 p-1 rounded-lg mr-3"
            onClick={() => setRotate((prev) => prev - 90)}
          >
            <RotateIcon className="h-5 w-5 -scale-x-100" />
          </button>
          <button
            type="button"
            disabled={loadingImages}
            className="cursor-pointer md:hover:bg-gray-100 bg-gray-50 border border-gray-300 rounded-lg p-1"
            onClick={() => setRotate((prev) => prev + 90)}
          >
            <RotateIcon className="h-5 w-5" />
          </button>
        </div>

        <div className="flex items-center">
          <button
            className={`cursor-pointer md:hover:bg-gray-100 bg-gray-50 p-1 border border-gray-300 rounded-lg mr-3 ${
              +scale <= 0.6 ? 'opacity-50' : ''
            }`}
            type="button"
            disabled={loadingImages}
            onClick={() =>
              setScale((prev) => {
                if (prev > 0.6) {
                  return prev - 0.1;
                } else {
                  return prev;
                }
              })
            }
          >
            <MagnifyingGlassMinusIcon className="h-6 w-6 text-primary" />
          </button>
          <button
            className={`cursor-pointer md:hover:bg-gray-100 bg-gray-50  border border-gray-300 rounded-lg p-1 ${
              +scale >= 1.6 ? 'opacity-50' : ''
            }`}
            type="button"
            disabled={loadingImages}
            onClick={() =>
              setScale((prev) => {
                if (prev < 1.6) {
                  return prev + 0.1;
                } else {
                  return prev;
                }
              })
            }
          >
            <MagnifyingGlassPlusIcon className="h-6 w-6 text-primary" />
          </button>
        </div>
      </div>

      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          keepSelection={true}
          disabled={loadingImages}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={logoMode ? undefined : aspect}
          className="max-h-[530px] mt-2"
          minWidth={70}
          ruleOfThirds
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{
              transform: `scale(${scale}) rotate(${rotate}deg)`,
            }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {!!completedCrop && (
        <div className="hidden">
          <div>
            <canvas
              id="previewCanvasRef"
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
