import { AppModal } from '@Common/AppModal';
import { Spinner } from '@Common/Spinner';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { QuantityState, SetStateType } from 'commonTypes';
import { positiveNumberInput } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { editItemField } from 'store/item/actions';

interface ChangeQuantityModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  globalCategoryId: string;
}

interface RouteParams {
  id: string;
}

export const ChangeQuantityModal = ({
  open,
  setOpen,
  globalCategoryId,
}: ChangeQuantityModalProps) => {
  const { id } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.item.subLoading);
  const menu = useAppSelector((state) => state.menu.menu);
  const activeItem = useAppSelector((state) => state.item.activeItem);
  const [quantity, setQuantity] = useState<QuantityState>({
    value: '',
    amount: '',
  });
  const [discardClick, setDiscardClick] = useState(0);

  useEffect(() => {
    if (menu.length > 0) {
      if (activeItem?.quantity && activeItem.quantity.amount) {
        // @ts-ignore
        setQuantity(activeItem.quantity);
      }
    }
  }, [menu, activeItem]);

  const resetFields = () => {
    setTimeout(() => {}, 250);
  };
  return (
    <AppModal open={open} size="sm:max-w-xl">
      <div className="flex flex-col">
        <div className="flex w-full items-center justify-between mb-3">
          <h1>
            <FormattedMessage id="quantity" />
          </h1>
          <XMarkIcon
            onClick={() => {
              if (discardClick === 0) {
                setDiscardClick(1);
              } else if (discardClick === 1) {
                setDiscardClick(0);
                setOpen(false);
                resetFields();
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-1 sm:mt-0 overflow-auto scrollbar">
          <div className="flex-grow">
            <div className="flex items-center space-x-4">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-2 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left text-sm font-semibold text-gray-900"
                    >
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  <tr>
                    <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900">
                      <div className="relative rounded-md shadow-sm">
                        <input
                          type="text"
                          id={'quantity-name'}
                          className="block w-full rounded-md border-gray-300 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm"
                          placeholder="Quantity name"
                          value={quantity.amount}
                          onChange={(e) => {
                            setQuantity((prev) => {
                              return {
                                ...prev,
                                amount: e.target.value,
                              };
                            });
                          }}
                        />
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                      <div className="relative rounded-md shadow-sm">
                        <input
                          type="text"
                          id={'quantity-value'}
                          className="block w-full rounded-md border-gray-300 rtl:pl-12 ltr:pr-12 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm"
                          placeholder={'Quantity value'}
                          value={quantity.value}
                          onChange={(e) => {
                            setQuantity((prev) => {
                              return {
                                ...prev,
                                value: positiveNumberInput(e),
                              };
                            });
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </dd>

        <div className="flex w-full justify-end mt-3">
          <div className="flex items-center space-x-3">
            <div className="flex items-center">
              <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
                {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
              </div>
              <div className="flex items-center justify-end w-full sm:w-auto">
                <button
                  onClick={() => {
                    if (discardClick === 0) {
                      setDiscardClick(1);
                    } else if (discardClick === 1) {
                      setDiscardClick(0);
                      setOpen(false);
                      resetFields();
                    }
                  }}
                  type="button"
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  <FormattedMessage id="discardChanges" />
                </button>
                <button
                  onClick={() => {
                    const result: QuantityState = {
                      amount: quantity.amount || '',
                      value: quantity.value || '',
                    };
                    setDiscardClick(0);
                    dispatch(
                      editItemField({
                        id: id,
                        value: result,
                        field: 'quantity',
                        setOpen: setOpen,
                        globalCategoryId,
                      }),
                    );
                  }}
                  type="button"
                  className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  {loading ? (
                    <Spinner color="bg-white" />
                  ) : (
                    <FormattedMessage id="save" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppModal>
  );
};
