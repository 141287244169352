import { SetStateType } from 'commonTypes';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { convertImage } from 'store/images/actions';

import { Spinner } from '../../Common/Spinner';

interface DropezoneItemProps {
  setPhoto: SetStateType<any>;
  resetCrop: () => void;
  setName: SetStateType<string>;
  setSize: SetStateType<number>;
}

export const DropezoneItem = ({
  setPhoto,
  resetCrop,
  setName,
  setSize,
}: DropezoneItemProps) => {
  const dispatch = useAppDispatch();
  const loadingImages = useAppSelector((state) => state.image.loading);

  useEffect(() => {
    resetCrop();
  }, []);

  return (
    <div className="mb-5">
      <Dropzone
        noClick={true}
        disabled={loadingImages}
        multiple={false}
        onDrop={(acceptedFiles) => {
          if (acceptedFiles) {
            setName(acceptedFiles[0].name);
            setSize(acceptedFiles[0].size);
          }
          dispatch(
            convertImage({
              photo: acceptedFiles[0],
              setPhoto: setPhoto,
            }),
          );
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className="mt-3 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6"
          >
            <div className="space-y-1 text-center">
              {loadingImages ? (
                <div className="py-24">
                  <Spinner color="bg-primary" />
                </div>
              ) : (
                <>
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600 rtl:flex-row-reverse">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 md:hover:text-indigo-500"
                    >
                      <span>Upload a file</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        {...getInputProps()}
                        accept="image/*,.heic,.heif"
                        onChange={(e) => {
                          if (e.target.files) {
                            setName(e.target.files[0].name);
                            setSize(e.target.files[0].size);
                          }
                          dispatch(
                            convertImage({
                              // @ts-ignore
                              photo: e.target.files[0],
                              setPhoto: setPhoto,
                            }),
                          );
                        }}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">PNG, JPG, WEBP up to 5MB</p>
                </>
              )}
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
};
