import { SetStateType } from 'commonTypes';

interface ForgotPasswordInputProps {
  email: string;
  setEmail: SetStateType<string>;
}

export const ForgotPasswordInput = ({ email, setEmail }: ForgotPasswordInputProps) => {
  return (
    <div>
      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
        Email address
      </label>
      <div className="mt-1">
        <input
          id="email"
          name="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="off"
          required
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm md:focus:border-indigo-500 md:focus:outline-none md:focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  );
};
