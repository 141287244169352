import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { SetStateType } from 'commonTypes';
import { useState } from 'react';

interface RegisterPasswordInputProps {
  value: string;
  setValue: SetStateType<string>;
  id: string;
  name: string;
  label: string;
}

export const RegisterPasswordInput = ({
  value,
  setValue,
  id,
  name,
  label,
}: RegisterPasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div className="mt-1 relative">
        <input
          id={id}
          value={value}
          onInput={(e) => {
            const target = e.target as HTMLInputElement;
            setValue(target.value);
          }}
          name={name}
          type={showPassword ? 'text' : 'password'}
          required
          className="block w-full appearance-none rounded-md border border-gray-300 pl-3 pr-8 py-2 placeholder-gray-400 shadow-sm md:focus:border-indigo-500 md:focus:outline-none md:focus:ring-indigo-500 sm:text-sm"
        />
        <div
          role="button"
          tabIndex={0}
          className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
          onClick={() => {
            setShowPassword((prev) => !prev);
          }}
        >
          {showPassword ? (
            <EyeSlashIcon className="h-5 w-5 text-gray-400" />
          ) : (
            <EyeIcon className="h-5 w-5 text-gray-400" />
          )}
        </div>
      </div>
    </>
  );
};
