import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { MENU_PANEL_URL } from 'configure';
import { useDebounce } from 'helpers/hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { checkAvailabilityLink, updateQRCode } from 'store/qrcode/action';

import { AppModal } from '../Common/AppModal';
import { Spinner } from '../Common/Spinner';

interface QRCodeModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  linkEx?: string;
  id?: null | string;
}

export const QRCodeModal = ({
  open,
  setOpen,
  linkEx = '',
  id = null,
}: QRCodeModalProps) => {
  const dispatch = useAppDispatch();
  const [link, setLink] = useState(linkEx ? linkEx : '');
  const [avalibleLink, setAvalibleLink] = useState<null | boolean>(null);
  const [discardClick, setDiscardClick] = useState(0);
  const loading = useAppSelector((state) => state.qr.localLoading);

  const debouncedSearchTerm = useDebounce(link, 300);

  useEffect(() => {
    if (debouncedSearchTerm && open) {
      dispatch(
        checkAvailabilityLink({
          link: debouncedSearchTerm,
          setAvalibleLink,
        }),
      );
    } else {
      setAvalibleLink(null);
    }
  }, [debouncedSearchTerm, open]);

  return (
    <AppModal size="sm:max-w-xl" open={open}>
      <div className="flex items-center w-full justify-between">
        <h1>
          <FormattedMessage id="linkText" />
        </h1>
        <XMarkIcon
          onClick={() => {
            if (!link || link === linkEx) {
              setOpen(false);
              if (linkEx) {
                setLink(linkEx);
              } else {
                setLink('');
              }
            } else {
              if (discardClick === 0) {
                setDiscardClick(1);
              } else {
                if (linkEx) {
                  setLink(linkEx);
                } else {
                  setLink('');
                }
                setDiscardClick(0);
                setOpen(false);
              }
            }
          }}
          className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
        />
      </div>

      <div className="rounded-md bg-blue-50 p-4 mt-3">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ltr:ml-3 rtl:mr-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              <FormattedMessage id="avaLink" />
            </p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="mt-1 flex items-center relative">
          <input
            id="link"
            name="dm-link"
            value={link}
            onInput={(e) => {
              const target = e.target as HTMLInputElement;
              setLink(target.value.toLowerCase().replace(/[^a-z0-9._-]/gi, ''));
            }}
            type="text"
            required
            data-testId="cypress-domain-input"
            maxLength={26}
            placeholder="your-link"
            className={`block w-full rounded-md ltr:pr-10 rtl:pl-10 ${
              avalibleLink === null || link === linkEx
                ? 'text-gray-900 border-gray-300 placeholder-gray-300 md:focus:border-primary md:focus:ring-primary'
                : avalibleLink
                ? 'text-green-900 border-green-300 placeholder-green-300 md:focus:border-green-500 md:focus:ring-green-500'
                : 'text-red-900 border-red-300 placeholder-red-300 md:focus:border-red-500 md:focus:ring-red-500'
            }  md:focus:outline-none  sm:text-sm`}
          />
          <div className="pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-3 rtl:pl-3">
            {avalibleLink === null || link === linkEx ? (
              <></>
            ) : avalibleLink ? (
              <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
            ) : (
              <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            )}
          </div>
        </div>
        {(avalibleLink || avalibleLink === null || link === linkEx) && (
          <p className="mt-2 text-xs text-primary truncate">
            {MENU_PANEL_URL}/{link ? link : '[your domain name]'}
          </p>
        )}
        {!avalibleLink && avalibleLink !== null && link !== linkEx && (
          <p className="mt-2 text-xs text-red-500 truncate">
            Account with this link already exist
          </p>
        )}
      </div>
      <div className="md:hidden text-sm text-red-500 mt-2">
        {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
      </div>
      <div className="w-full flex justify-end mt-3">
        {!link || link === linkEx ? (
          <button
            type="button"
            onClick={() => {
              setOpen(false);
              if (linkEx) {
                setLink(linkEx);
              } else {
                setLink('');
              }
            }}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="cancel" />
          </button>
        ) : (
          <div className="flex items-center flex-nowrap">
            <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2 hidden md:block">
              {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
            </div>
            <div className="flex items-center justify-end w-full sm:w-auto">
              <button
                type="button"
                onClick={() => {
                  if (discardClick === 0) {
                    setDiscardClick(1);
                  } else {
                    if (linkEx) {
                      setLink(linkEx);
                    } else {
                      setLink('');
                    }
                    setDiscardClick(0);
                    setOpen(false);
                  }
                }}
                className="ltr:mr-3 rtl:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="discardChanges" />
              </button>
              <button
                type="button"
                disabled={!avalibleLink}
                onClick={() => {
                  if (avalibleLink && link) {
                    if (linkEx) {
                      dispatch(
                        updateQRCode({
                          link,
                          setOpen,
                          setDiscardClick,
                          id,
                        }),
                      );
                    }
                  }
                }}
                data-testId="cypress-domain-save"
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                {loading ? <Spinner color="bg-white" /> : <FormattedMessage id="save" />}
              </button>
            </div>
          </div>
        )}
      </div>
    </AppModal>
  );
};
