import { defaultToastProps } from 'appConstants';
import Amplify from 'aws-amplify';
import axios from 'axios';
import { SetStateType } from 'commonTypes';
import { toast } from 'react-toastify';

enum stageEnum {
  local = 'local',
  dev = 'development',
  stage = 'staging',
  prod = 'production',
}

const BACKEND_CORE_VERSION = '/api/v1';
const BACKEND_CORE_PORT = '8080';

const IMAGE_HANDLER_VERSION = '/api/v1';
const IMAGE_HANDLER_PORT = '5000';

const MENU_PANEL_URL_LOCAL = 'http://localhost:3001';

export let BASE_URL = '';
export let IMG_SERVICE_BASE_URL = '';
export let CLOUDFRONT_BASE_URL = '';
export let TRANSLATOR_BASE_URL = '';
export let MENU_PANEL_URL = '';
export let GPT_LAMBDA_LINK = '';

export const configureAws =
  ({ setLoading }: { setLoading: SetStateType<boolean> }) =>
  async () => {
    try {
      setLoading(true);

      let envStage = process.env.REACT_APP_STAGE?.toLowerCase() || '';

      if (!process.env.REACT_APP_STAGE) {
        throw new Error('Check env variables');
      }

      if (!Object.values(stageEnum).includes(envStage as stageEnum)) {
        throw new Error('Unsupported stage: ' + envStage);
      }

      if (envStage === stageEnum.local) {
        envStage = stageEnum.dev;
      }

      const req = await axios.post(
        process.env.REACT_APP_API + '/v1/parameters',
        {
          parameters: [
            `/general/aws-region`,
            `/verifier/${envStage}`,
            `/storages/blobs/${envStage}`,
            `/api/backend-core/${envStage}`,
            `/api/image-processing-core/${envStage}`,
            `/cloudfront/${envStage}`,
            `/amplify/menu-panel/${envStage}`,
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );

      const parameters = await req.data;

      const region = parameters[`/general/aws-region`];

      const verifierParameters = JSON.parse(parameters[`/verifier/${envStage}`]).cognito;

      const storagesParameters = JSON.parse(parameters[`/storages/blobs/${envStage}`]);

      if (process.env.REACT_APP_STAGE.toLowerCase() === stageEnum.local) {
        BASE_URL = 'http://localhost:' + BACKEND_CORE_PORT + BACKEND_CORE_VERSION;
        IMG_SERVICE_BASE_URL =
          'http://localhost:' + IMAGE_HANDLER_PORT + IMAGE_HANDLER_VERSION;
        MENU_PANEL_URL = MENU_PANEL_URL_LOCAL;
      } else {
        BASE_URL =
          JSON.parse(parameters[`/api/backend-core/${envStage}`]).domain +
          BACKEND_CORE_VERSION;
        IMG_SERVICE_BASE_URL =
          JSON.parse(parameters[`/api/image-processing-core/${envStage}`]).domain +
          IMAGE_HANDLER_VERSION;
        MENU_PANEL_URL = JSON.parse(
          parameters[`/amplify/menu-panel/${envStage}`],
        ).endpoint;
      }

      CLOUDFRONT_BASE_URL = JSON.parse(parameters[`/cloudfront/${envStage}`])[
        'image-handler'
      ].endpoint;

      Amplify.configure({
        Auth: {
          mandatorySignIn: true,
          region: region || '',
          userPoolId: verifierParameters['user-pool-id'],
          userPoolWebClientId: verifierParameters['application-client-id'],
          identityPoolId: verifierParameters['identity-pool-id'],
        },
        Storage: {
          AWSS3: {
            bucket: storagesParameters['primary_images'],
            region: region || '',
          },
        },
      });
      setLoading(false);
    } catch (e) {
      if (typeof e === 'string') {
        toast(e, {
          ...defaultToastProps,
          type: 'error',
        });
      } else if (e instanceof Error) {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      }
    }
  };

export const configureAddAws =
  ({ setLoading }: { setLoading: SetStateType<boolean> }) =>
  async () => {
    setLoading(true);

    let envStage = process.env.REACT_APP_STAGE?.toLowerCase() || '';

    if (!process.env.REACT_APP_STAGE) {
      throw new Error('Check env variables');
    }

    if (!Object.values(stageEnum).includes(envStage as stageEnum)) {
      throw new Error('Unsupported stage: ' + envStage);
    }

    if (envStage === stageEnum.local) {
      envStage = stageEnum.dev;
    }

    try {
      const req = await axios.post(
        process.env.REACT_APP_API + '/v1/parameters',
        {
          parameters: [
            `/translator-service/${envStage}`,
            `/text-generation-service/${envStage}`,
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );

      const parameters = await req.data;

      TRANSLATOR_BASE_URL = JSON.parse(
        parameters[`/translator-service/${envStage}`],
      ).endpoint;

      GPT_LAMBDA_LINK = JSON.parse(
        parameters[`/text-generation-service/${envStage}`],
      ).endpoint;
    } catch (e) {
      if (typeof e === 'string') {
        toast(e, {
          ...defaultToastProps,
          type: 'error',
        });
      } else if (e instanceof Error) {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };
