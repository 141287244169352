import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { selectLanguages } from 'appConstants';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Fragment, useEffect, useState } from 'react';
import { setLocale } from 'store/locales/actions';

export const LanguageDropdown = () => {
  const dispatch = useAppDispatch();
  const locale = useAppSelector((state) => state.locale.lang);
  const [selected, setSelected] = useState(
    selectLanguages.find(
      (item) =>
        item.oldCode ===
        (localStorage.getItem('locale') ? localStorage.getItem('locale') : locale),
    ),
  );
  const account = useAppSelector((state) => state.account.account);

  useEffect(() => {
    if (!localStorage.getItem('locale') && account) {
      setSelected(selectLanguages.find((item) => item.value === account?.locale));
    }
  }, [account]);

  useEffect(() => {
    if (selected?.oldCode) {
      dispatch(
        setLocale({
          locale: selected?.oldCode,
        }),
      );
    }
  }, [selected]);

  if (!account) {
    return null;
  }

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700 sr-only">
            Assigned to
          </Listbox.Label>
          <div className="relative mt-1 w-36">
            <Listbox.Button
              as="div"
              className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm md:focus:border-indigo-500 md:focus:outline-none md:focus:ring-1 md:focus:ring-indigo-500 sm:text-sm"
            >
              <span className="block truncate">{selected?.label}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none sm:text-sm">
                {account.languages.map((lang) => {
                  const langObj = selectLanguages.find((l) => l.value === lang);
                  return (
                    <Listbox.Option
                      key={lang}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-indigo-600' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                        )
                      }
                      value={langObj}
                      onClick={() => {
                        localStorage.setItem('locale', langObj?.oldCode + '');
                      }}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate text-left',
                            )}
                          >
                            {langObj?.label}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
