import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { SetStateType } from 'commonTypes';
import { MENU_PANEL_URL } from 'configure';
import { useDebounce } from 'helpers/hooks';
import { phoneMask } from 'helpers/validation';
import { useAppDispatch } from 'hooks/store';
import { useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { checkAvailabilityLink } from 'store/qrcode/action';

import { RegisterPhoneCodeSelect } from './RegisterPhoneCodeSelect';

interface RegisterPhoneInputProps {
  phoneCode:
    | {
        code: string;
        name: string;
      }
    | undefined;
  setPhoneCode: SetStateType<
    | {
        code: string;
        name: string;
      }
    | undefined
  >;
  phoneNumber: string;
  setPhoneNumber: SetStateType<string>;

  link: string;
  setLink: SetStateType<string>;
  avalibleLink: boolean | null;
  setAvalibleLink: SetStateType<boolean | null>;
}

export const RegisterPhoneInput = ({
  phoneCode,
  setPhoneCode,
  phoneNumber,
  setPhoneNumber,

  link,
  setLink,
  avalibleLink,
  setAvalibleLink,
}: RegisterPhoneInputProps) => {
  const dispatch = useAppDispatch();

  const debouncedSearchTerm = useDebounce(link, 300);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(
        checkAvailabilityLink({
          link: debouncedSearchTerm,
          setAvalibleLink,
        }),
      );
    } else {
      setAvalibleLink(null);
    }
  }, [debouncedSearchTerm]);

  return (
    <div className="flex w-full items-start flex-wrap sm:flex-nowrap justify-between space-y-6 sm:space-x-8 sm:space-y-0">
      <div className="w-full sm:w-1/2">
        <label htmlFor="phone-number" className="block text-sm font-medium text-gray-700">
          Phone Number
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 flex items-center">
            <label htmlFor="country" className="sr-only">
              Country
            </label>
            <RegisterPhoneCodeSelect phoneCode={phoneCode} setPhoneCode={setPhoneCode} />
          </div>
          <input
            type="text"
            value={phoneNumber}
            //   onChange={(e) => setPhoneNumber(e.target.value)}
            onInput={(e) => {
              phoneMask(e, setPhoneNumber);
            }}
            required
            name="phone-number"
            id="phone-number"
            autoComplete="tel-national"
            className="block w-full rounded-md border-gray-300 pl-28 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm"
            placeholder="Phone Number"
          />
        </div>
      </div>
      <div className="w-full sm:w-1/2">
        <label htmlFor="link" className="text-sm font-medium text-gray-700 flex">
          <div>Link</div>
          <InformationCircleIcon
            data-tooltip-id="domain"
            className="h-5 w-5 text-gray-500 ml-2 cursor-pointer hover:text-gray-400"
            aria-hidden="true"
          />
          <Tooltip place="top" id="domain">
            <div className="max-w-[300px]">
              You can access your menu via this link. It can be changed later. The link
              can only contain Latin letters, numbers, and the symbols dot (.), hyphen
              (-), underscore (_)
            </div>
          </Tooltip>
        </label>
        <div className="mt-1 flex items-center relative">
          <input
            id="link"
            name="dm-link"
            value={link}
            onInput={(e) => {
              const target = e.target as HTMLInputElement;
              setLink(target.value.toLowerCase().replace(/[^a-z0-9._-]/gi, ''));
            }}
            type="text"
            required
            placeholder="demo-restaurant"
            className={`block w-full rounded-md pr-10 ${
              avalibleLink === null
                ? 'text-gray-900 border-gray-300 placeholder-gray-300 focus:border-primary focus:ring-primary'
                : avalibleLink
                ? 'text-green-900 border-green-300 placeholder-green-300 focus:border-green-500 focus:ring-green-500'
                : 'text-red-900 border-red-300 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
            }  focus:outline-none  sm:text-sm`}
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {avalibleLink === null ? (
              <></>
            ) : avalibleLink ? (
              <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
            ) : (
              <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            )}
          </div>
        </div>
        {(avalibleLink || avalibleLink === null) && (
          <p className="mt-2 text-xs text-primary truncate">
            {MENU_PANEL_URL}/{link ? link : '[your domain name]'}
          </p>
        )}
        {!avalibleLink && avalibleLink !== null && (
          <p className="mt-2 text-xs text-red-500 truncate">
            Account with this link already exist
          </p>
        )}
      </div>
    </div>
  );
};
