import { Switch } from '@headlessui/react';
import { oldLanguages } from 'appConstants';
import { IOptions, SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { editOption } from 'store/options/actions';

import { OptionSelectionModal } from '../Modals';
import { ChangeToppingsModal } from '../Modals/ChangeToppingsModal';
import { Toggle } from '../UI';

interface OptionHeaderProps {
  active: IOptions;
  id: string;
  setIsMulti: SetStateType<boolean>;
}

export const OptionHeader = ({ active, id, setIsMulti }: OptionHeaderProps) => {
  const dispatch = useAppDispatch();
  const [editNameModal, setOpenEditModal] = useState(false);
  const [editToppingsModal, setOpenEditToppingsModal] = useState(false);
  const locale = useAppSelector((state) => state.locale.lang);

  return (
    <div className="flex flex-col mb-2 w-full">
      <div className="flex flex-wrap items-center mb-2">
        <div className="mr-4">
          <Toggle
            mode="multi"
            id={id}
            setIsMulti={setIsMulti}
            defaultCheck={active?.multi_select}
            active={active}
            hasDefault={!!active?.selections?.find((item) => item.default_value)}
          />
        </div>

        <Switch.Group as="div" className="flex items-center">
          <Switch.Label
            htmlFor="selection-free"
            className="flex items-center cursor-pointer ltr:mr-2 rtl:ml-2 text-gray-500 select-none"
          >
            <FormattedMessage id="topingsOptionShort" />
          </Switch.Label>

          <Switch
            id="selection-free"
            checked={active.free_option_data?.free_options || false}
            onChange={(v: boolean) => {
              const filteredSelections = active.selections.filter(
                (selection) => selection.status === 1,
              );
              if (
                v &&
                filteredSelections.length < (active.free_option_data?.amount || 2)
              ) {
                setOpenEditToppingsModal(true);
                return;
              }
              if (!active?.multi_select && v) {
                dispatch(
                  editOption({
                    data: {
                      multi_select: true,
                      free_option_data: {
                        free_options: v,
                        amount: active.free_option_data?.amount || 2,
                      },
                    },
                    id,
                    setIsMulti,
                  }),
                );
              } else {
                dispatch(
                  editOption({
                    data: {
                      free_option_data: {
                        free_options: v,
                        amount: active.free_option_data?.amount || 2,
                      },
                    },
                    id,
                  }),
                );
              }
            }}
            className={classNames(
              active.free_option_data?.free_options ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                active.free_option_data?.free_options ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              )}
            />
          </Switch>
        </Switch.Group>
      </div>
      <div className="min-w-full max-w-full overflow-auto scrollbar border-b">
        <table className="min-w-full max-w-full divide-y divide-gray-300 overflow-auto scrollbar">
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="min-w-full max-w-full overflow-auto scrollbar">
              <td className="whitespace-nowrap py-4 ltr:pr-3 rtl:pl-3 text-sm text-gray-500">
                <FormattedMessage id="numberOfSelectionForFree" />
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                {active?.free_option_data?.amount || 2}
              </td>
              <td className="relative whitespace-nowrap py-4 ltr:pl-3 rtl:pr-3 text-right text-sm font-medium">
                <button
                  className="text-indigo-600 hover:text-indigo-900"
                  onClick={() => {
                    setOpenEditToppingsModal(true);
                  }}
                >
                  <FormattedMessage id="edit" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="min-w-full max-w-full overflow-auto scrollbar">
        <table className="min-w-full max-w-full divide-y divide-gray-300 overflow-auto scrollbar">
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="min-w-full max-w-full overflow-auto scrollbar">
              <td className="whitespace-nowrap py-4 ltr:pr-3 rtl:pl-3 text-sm text-gray-500">
                <FormattedMessage id="optionName" />
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                {active?.name?.translations
                  ? active?.name?.translations[oldLanguages[locale]]
                  : ''}
              </td>
              <td className="relative whitespace-nowrap py-4 ltr:pl-3 rtl:pr-3 text-right text-sm font-medium">
                <button
                  className="text-indigo-600 hover:text-indigo-900"
                  onClick={() => {
                    setOpenEditModal(true);
                  }}
                >
                  <FormattedMessage id="edit" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <OptionSelectionModal
        open={editNameModal}
        setOpen={setOpenEditModal}
        id={id}
        optionEdit={true}
        active={active}
      />

      <ChangeToppingsModal
        active={active}
        open={editToppingsModal}
        setOpen={setOpenEditToppingsModal}
        id={id}
      />
    </div>
  );
};
