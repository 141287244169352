import { defaultToastProps } from 'appConstants';
import { ISelections, SelectedLanguage, SetStateType } from 'commonTypes';
import { Spinner } from 'components/Common/Spinner';
import { fetchLambdaTranslate } from 'helpers/translate';
import { checkFieldNotEmpty } from 'helpers/validation';
import { useAppDispatch } from 'hooks/store';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { addSelection, editOption, editSelection } from 'store/options/actions';

interface OptionBottomModalButtonProps {
  translateActiveTab: number;
  setTranslateActiveTab: SetStateType<number>;
  translateLoading: boolean;
  setTranslateLoading: SetStateType<boolean>;
  itemName: string;
  setTranslate: SetStateType<any>;
  selected: SelectedLanguage | undefined;
  loadingAccount: boolean;
  translation: any;
  setOpen: SetStateType<boolean>;
  optionEdit: boolean;
  active: ISelections | undefined;
  oldTranslate: any;
  selectionPrice: number;
  addModal: boolean;
  id: string;
  resetFields: () => void;
  oldPrice: number;
  withoutTranslation: boolean;
}

export const OptionBottomModalButton = ({
  translateActiveTab,
  setTranslateActiveTab,
  translateLoading,
  setTranslateLoading,
  itemName,
  setTranslate,
  selected,
  loadingAccount,
  translation,
  setOpen,
  optionEdit,
  active,
  oldTranslate,
  selectionPrice,
  addModal,
  id,
  resetFields,
  oldPrice,
  withoutTranslation,
}: OptionBottomModalButtonProps) => {
  const dispatch = useAppDispatch();

  return (
    <div className="w-full flex justify-end mt-4">
      <div className="flex items-center space-x-4 rtl:space-x-reverse">
        {translateActiveTab !== 0 && (
          <button
            disabled={translateLoading}
            onClick={() => {
              setTranslateActiveTab(0);
            }}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="previous" />
          </button>
        )}

        <button
          disabled={translateLoading}
          onClick={() => {
            if (translateActiveTab === 0) {
              if (withoutTranslation) {
                if (itemName.trim()) {
                  if (
                    optionEdit &&
                    id &&
                    JSON.stringify(translation) !== JSON.stringify(oldTranslate) &&
                    checkFieldNotEmpty(translation)
                  ) {
                    dispatch(
                      editOption({
                        setOpen,
                        resetFields,
                        data: {
                          name: {
                            source_lang: selected?.value,
                            translations: translation,
                            withoutTranslate: withoutTranslation,
                          },
                        },
                        id,
                      }),
                    );
                  } else if (!optionEdit && addModal && checkFieldNotEmpty(translation)) {
                    dispatch(
                      addSelection({
                        setOpen,
                        resetFields,
                        optionId: id,
                        newSelection: {
                          name: {
                            source_lang: selected?.value,
                            translations: translation,
                            withoutTranslate: withoutTranslation,
                          },
                          price: selectionPrice,
                        },
                      }),
                    );
                  } else if (!optionEdit && !addModal) {
                    if (
                      (JSON.stringify(translation) !== JSON.stringify(oldTranslate) &&
                        checkFieldNotEmpty(translation)) ||
                      +oldPrice !== +selectionPrice
                    ) {
                      dispatch(
                        editSelection({
                          setOpen,
                          resetFields,
                          optionId: id,
                          selectionId: active?.id,
                          data: {
                            name: {
                              source_lang: selected?.value,
                              translations: translation,
                              withoutTranslate: withoutTranslation,
                            },
                            price: +selectionPrice,
                          },
                        }),
                      );
                    } else if (!checkFieldNotEmpty(translation)) {
                      toast('Transtations required', {
                        ...defaultToastProps,
                        type: 'error',
                      });
                    } else {
                      setOpen(false);
                      setTimeout(() => {
                        resetFields();
                      }, 200);
                    }
                  } else {
                    setOpen(false);
                    setTimeout(() => {
                      resetFields();
                    }, 200);
                  }
                } else {
                  toast('Name required', {
                    ...defaultToastProps,
                    type: 'error',
                  });
                }
              } else {
                if (
                  itemName === translation[selected?.value as keyof typeof translation]
                ) {
                  setTranslateActiveTab((prev) => prev + 1);
                } else {
                  if (itemName.trim()) {
                    setTranslate({});
                    dispatch(
                      fetchLambdaTranslate({
                        setLoading: setTranslateLoading,
                        setData: setTranslate,
                        text: itemName,
                        // @ts-ignore
                        source_lang: selected?.value,
                        setTranslateActiveTab,
                      }),
                    );
                  } else {
                    toast('Name required', {
                      ...defaultToastProps,
                      type: 'error',
                    });
                  }
                }
              }
            } else {
              if (
                optionEdit &&
                id &&
                JSON.stringify(translation) !== JSON.stringify(oldTranslate) &&
                checkFieldNotEmpty(translation)
              ) {
                dispatch(
                  editOption({
                    setOpen,
                    resetFields,
                    data: {
                      name: {
                        source_lang: selected?.value,
                        translations: translation,
                        withoutTranslate: withoutTranslation,
                      },
                    },
                    id,
                  }),
                );
              } else if (!optionEdit && addModal && checkFieldNotEmpty(translation)) {
                dispatch(
                  addSelection({
                    setOpen,
                    resetFields,
                    optionId: id,
                    newSelection: {
                      name: {
                        source_lang: selected?.value,
                        translations: translation,
                        withoutTranslate: withoutTranslation,
                      },
                      price: selectionPrice,
                    },
                  }),
                );
              } else if (!optionEdit && !addModal) {
                if (
                  (JSON.stringify(translation) !== JSON.stringify(oldTranslate) &&
                    checkFieldNotEmpty(translation)) ||
                  +oldPrice !== +selectionPrice
                ) {
                  dispatch(
                    editSelection({
                      setOpen,
                      resetFields,
                      optionId: id,
                      selectionId: active?.id,
                      data: {
                        name: {
                          source_lang: selected?.value,
                          translations: translation,
                          withoutTranslate: withoutTranslation,
                        },
                        price: +selectionPrice,
                      },
                    }),
                  );
                } else if (!checkFieldNotEmpty(translation)) {
                  toast('Transtations required', {
                    ...defaultToastProps,
                    type: 'error',
                  });
                } else {
                  setOpen(false);
                  setTimeout(() => {
                    resetFields();
                  }, 200);
                }
              } else if (!checkFieldNotEmpty(translation)) {
                toast('Transtations required', {
                  ...defaultToastProps,
                  type: 'error',
                });
              } else {
                setOpen(false);
                setTimeout(() => {
                  resetFields();
                }, 200);
              }
            }
          }}
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
        >
          {loadingAccount || translateLoading ? (
            <Spinner color="bg-white" />
          ) : translateActiveTab === 0 && !withoutTranslation ? (
            <FormattedMessage id="next" />
          ) : (
            <FormattedMessage id="save" />
          )}
        </button>
      </div>
    </div>
  );
};
