import { SetStateType } from 'commonTypes';
import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-tooltip';

interface AllergensChooseCoverProps {
  name: string;
  iconNoVis: ReactNode;
  iconVis: ReactNode;
  iconNoAllergen: ReactNode;
  allergen: string;
  allergens:
    | {
        [key: string]: number;
      }
    | undefined;
  setAllergens: SetStateType<
    | {
        [key: string]: number;
      }
    | undefined
  >;
  disabled?: boolean;
  isMobile: boolean;
}

export const AllergensChooseCover = ({
  name,
  iconNoVis,
  iconVis,
  iconNoAllergen,
  allergen,
  allergens,
  setAllergens,
  disabled = false,
  isMobile,
}: AllergensChooseCoverProps) => {
  const [clickAllergenCount, setClickAllergenCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setClickAllergenCount(() => {
      if (allergen.toLowerCase() === name.toLowerCase()) {
        setAllergens({
          ...allergens,
          [name.toLowerCase()]: 1,
        });
        return 1;
      } else if (allergen.toLowerCase() === 'no' + name.toLowerCase()) {
        setAllergens({
          ...allergens,
          [name.toLowerCase()]: 2,
        });
        return 2;
      } else {
        setAllergens({
          ...allergens,
          [name.toLowerCase()]: 0,
        });
        return 0;
      }
    });
  }, [allergen]);

  const setOpenMobileTooltip = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (target.closest('.tooltip-' + name)) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      // @ts-ignore
      window.addEventListener('click', setOpenMobileTooltip);
      return () => {
        // @ts-ignore
        window.removeEventListener('click', setOpenMobileTooltip);
      };
    }
  }, [isMobile]);

  const tooltipContent = () => {
    if (isMobile) {
      return <FormattedMessage id={name.toLowerCase()} />;
    }
    if (clickAllergenCount === 0) {
      return (
        <FormattedMessage
          id="notSelectAllergens"
          values={{
            name: <FormattedMessage id={name.toLowerCase()} />,
          }}
        />
      );
    } else if (clickAllergenCount === 1) {
      return (
        <FormattedMessage
          id="haveAllergens"
          values={{
            name: <FormattedMessage id={name.toLowerCase()} />,
          }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="noAllergens"
          values={{
            name: <FormattedMessage id={name.toLowerCase()} />,
          }}
        />
      );
    }
  };

  return (
    <>
      <button
        data-tooltip-id={name}
        data-testId={'cypress-allergens-' + name.toLowerCase()}
        data-some-relevant-attr={clickAllergenCount}
        className={`bg-[transparent] tooltip-${name}`}
        onClick={() => {
          if (!disabled) {
            if (clickAllergenCount === 2) {
              setClickAllergenCount(0);
              setAllergens({
                ...allergens,
                [name.toLowerCase()]: 0,
              });
            } else {
              if (clickAllergenCount === 0) {
                setAllergens({
                  ...allergens,
                  [name.toLowerCase()]: 1,
                });
              } else {
                setAllergens({
                  ...allergens,
                  [name.toLowerCase()]: 2,
                });
              }
              setClickAllergenCount(clickAllergenCount + 1);
            }
          }
        }}
      >
        {clickAllergenCount === 0
          ? iconNoVis
          : clickAllergenCount === 1
          ? iconVis
          : iconNoAllergen}
      </button>
      {isMobile ? (
        <Tooltip isOpen={isOpen} place="top" id={name}>
          {tooltipContent()}
        </Tooltip>
      ) : (
        <Tooltip place="top" id={name}>
          {tooltipContent()}
        </Tooltip>
      )}
    </>
  );
};
