import { OldLanguages } from 'commonTypes';

import { RESET_LOCALE, SET_LOCALE } from './actionsTypes';
import { ResetLocaleAction, SetLocaleAction } from './types';

export const setLocale = ({ locale }: { locale: OldLanguages }): SetLocaleAction => {
  return {
    type: SET_LOCALE,
    payload: locale,
  };
};

export const resetLocale = (): ResetLocaleAction => {
  return {
    type: RESET_LOCALE,
  };
};
