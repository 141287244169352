import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { SelectedLanguage, SetStateType } from 'commonTypes';
import { ModalChangeLanguage } from 'components/Common/ModalChangeLanguage';
import { FormattedMessage } from 'react-intl';

interface AddCategorySelectLangProps {
  activeStep: number;
  loadingTranslateLabmda: boolean;
  selected: SelectedLanguage | undefined;
  setSelected: SetStateType<SelectedLanguage | undefined>;
  selectedDescription: SelectedLanguage | undefined;
  setSelectedDescription: SetStateType<SelectedLanguage | undefined>;
}

export const AddCategorySelectLang = ({
  activeStep,
  loadingTranslateLabmda,
  selected,
  setSelected,
  selectedDescription,
  setSelectedDescription,
}: AddCategorySelectLangProps) => {
  return (
    <div className="flex items-center justify-between w-full mt-3 md:mt-0">
      {activeStep === 1 && (
        <ModalChangeLanguage
          disabled={loadingTranslateLabmda}
          selected={selected}
          setSelected={setSelected}
        />
      )}
      {activeStep === 3 && (
        <ModalChangeLanguage
          disabled={loadingTranslateLabmda}
          selected={selectedDescription}
          setSelected={setSelectedDescription}
        />
      )}
    </div>
  );
};
