import { XMarkIcon } from '@heroicons/react/24/outline';
import { defaultToastProps, selectLanguages } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { ChatGPTModal } from 'components/AddItemComponents/ChatGPT/ChatGPTModal';
import { AppModal } from 'components/Common/AppModal';
import { ModalChangeLanguage } from 'components/Common/ModalChangeLanguage';
import { Spinner } from 'components/Common/Spinner';
import { createNameWithoutTransalte } from 'helpers/menu';
import { fetchLambdaTranslate } from 'helpers/translate';
import { checkFieldNotEmpty, sortedTranslateObj } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { editItemField } from 'store/item/actions';

const steps = [
  { name: <FormattedMessage id="firstCategoryStep" />, id: 1 },
  { name: <FormattedMessage id="secondCategoryStep" />, id: 2 },
];

interface EditItemTextFieldProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  showEditDescriptionModal: boolean;
  globalCategoryId: string;
}

interface RouteParams {
  id: string;
}

export const EditItemTextField = ({
  open,
  setOpen,
  showEditDescriptionModal,
  globalCategoryId,
}: EditItemTextFieldProps) => {
  const { id } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const activeItem = useAppSelector((state) => state.item.activeItem);
  const [translateActiveTab, setTranslateActiveTab] = useState(0);
  const [itemName, setItemName] = useState('');
  const account = useAppSelector((state) => state.account.account);
  const [selected, setSelected] = useState(
    selectLanguages.find((elem) => elem.value === account?.locale),
  );

  const loading = useAppSelector((state) => state.item.loading);

  const [translate, setTranslate] = useState<any>({});
  const [translateLoading, setTranslateLoading] = useState(false);

  const [withoutTranslate, setWithoutTranslate] = useState(false);

  const [openChatGPTModal, setOpenChatGPTModal] = useState(false);

  const inputRef = useRef(null);

  const setHeight = (ref: any) => {
    const scrollHeight = ref.scrollHeight;
    ref.style.height = scrollHeight + 'px';
  };

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current && open) {
        setHeight(inputRef.current);
      }
    }, 10);
  }, [itemName, open]);

  useEffect(() => {
    if (showEditDescriptionModal) {
      if (itemName.trim() === '') {
        setTranslate({});
      }
    }
  }, [itemName]);

  useEffect(() => {
    if (
      activeItem &&
      activeItem?.name &&
      activeItem?.name?.source_lang &&
      !showEditDescriptionModal &&
      activeItem?.name?.translations
    ) {
      setItemName(activeItem?.name?.translations[activeItem?.name?.source_lang]);
      setTranslate(activeItem?.name?.translations);
      setWithoutTranslate(!!activeItem?.name?.withoutTranslate);
    } else if (
      activeItem &&
      activeItem?.description &&
      activeItem?.description?.source_lang &&
      showEditDescriptionModal &&
      activeItem?.description?.translations
    ) {
      setItemName(
        activeItem?.description?.translations[activeItem?.description?.source_lang],
      );
      setTranslate(activeItem?.description?.translations);
      setWithoutTranslate(!!activeItem?.description?.withoutTranslate);
    } else if (
      activeItem &&
      activeItem?.description &&
      !activeItem?.description?.source_lang &&
      showEditDescriptionModal
    ) {
      setItemName('');
      setTranslate({});
      setWithoutTranslate(false);
    }
  }, [id, open]);

  useEffect(() => {
    if (account) {
      setSelected(selectLanguages.find((lang) => lang.value === account.locale));
    }
    if (activeItem && activeItem?.name?.source_lang && !showEditDescriptionModal) {
      setSelected(
        selectLanguages.find((lang) => lang.value === activeItem?.name?.source_lang),
      );
    }
    if (activeItem && activeItem?.description?.source_lang && showEditDescriptionModal) {
      setSelected(
        selectLanguages.find(
          (lang) => lang.value === activeItem?.description?.source_lang,
        ),
      );
    }
  }, [account, open]);

  const handleCreateItem = () => {
    if (!itemName) {
      toast(`${showEditDescriptionModal ? 'Description' : 'Item name'} required`, {
        ...defaultToastProps,
        type: 'error',
      });
      return;
    } else if (!Object.keys(translate).length || !checkFieldNotEmpty(translate)) {
      toast(
        `${showEditDescriptionModal ? 'Description' : 'Item name'} translation required`,
        {
          ...defaultToastProps,
          type: 'error',
        },
      );
      return;
    } else {
      if (
        (!showEditDescriptionModal &&
          JSON.stringify(activeItem?.name?.translations) !== JSON.stringify(translate)) ||
        (showEditDescriptionModal &&
          JSON.stringify(activeItem?.description?.translations) !==
            JSON.stringify(translate))
      ) {
        dispatch(
          editItemField({
            id: id,
            field: showEditDescriptionModal ? 'description' : 'name',
            value: {
              source_lang: selected?.value,
              translations: translate,
              withoutTranslate: !!withoutTranslate,
            },
            setOpen: setOpen,
            globalCategoryId,
          }),
        );
      }
    }
  };

  return (
    <AppModal open={open} size="sm:max-w-3xl">
      <ChatGPTModal
        setParentSelected={setSelected}
        setParentName={setItemName}
        open={openChatGPTModal}
        setOpen={setOpenChatGPTModal}
      />
      <div className="flex flex-col">
        <div className="flex w-full items-center justify-between mb-3">
          <h1>
            <FormattedMessage id="editItem" />
          </h1>
          <XMarkIcon
            onClick={() => {
              setTranslateActiveTab(0);
              setItemName('');
              setTranslate({});
              setSelected(selectLanguages.find((lang) => lang.value === account?.locale));
              setOpen(false);
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="w-full">
          <div className="mt-2 mb-3" aria-hidden="true">
            <div className="overflow-hidden rounded-full bg-gray-200">
              <div
                className="h-2 rounded-full bg-indigo-600"
                style={{
                  width: ((+translateActiveTab + 1) / steps.length) * 100 + '%',
                }}
              />
            </div>
            <div className="mt-6 hidden grid-cols-2 text-sm font-medium text-gray-600 sm:grid">
              {steps.map((item) => (
                <div
                  className={`${
                    +translateActiveTab + 1 === item.id ? 'text-indigo-600' : ''
                  } ${
                    item.id === 1
                      ? 'ltr:text-left rtl:text-right'
                      : 'ltr:text-right rtl:text-left'
                  }`}
                  key={'edit-text-item-' + item.id}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full">
          <>
            <div className="flex items-center justify-between w-full">
              {translateActiveTab === 0 && (
                <ModalChangeLanguage selected={selected} setSelected={setSelected} />
              )}
            </div>
            {translateActiveTab === 0 && (
              <div className="mt-4 flex flex-col w-full justify-between items-end">
                <div className="w-full">
                  <div className="mt-0">
                    <textarea
                      ref={inputRef}
                      name="name"
                      id="item-name"
                      value={itemName}
                      onChange={(e) => {
                        setItemName(e.target.value);
                        if (withoutTranslate) {
                          setTranslate(createNameWithoutTransalte(e.target.value));
                        } else {
                          setTranslate({});
                        }
                      }}
                      className="block overflow-hidden resize-none  w-full box-border scrollbar rounded-md border-gray-300 sm:text-sm"
                      placeholder={`${
                        showEditDescriptionModal ? 'Description' : 'Item name'
                      } `}
                    />
                  </div>
                </div>
                <div className="relative flex items-start mt-2">
                  <div className="flex h-6 items-center">
                    <input
                      id="without-translate"
                      aria-describedby="without-translate"
                      name="without-translate"
                      type="checkbox"
                      checked={withoutTranslate}
                      onChange={(e) => {
                        setWithoutTranslate(e.target.checked);
                        if (e.target.checked) {
                          if (itemName.trim()) {
                            setTranslate(createNameWithoutTransalte(itemName));
                          }
                        } else {
                          setTranslate({});
                        }
                      }}
                      disabled={loading}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="without-translate"
                      className="font-medium text-gray-900 select-none"
                    >
                      <FormattedMessage id="withoutTranslate" />
                    </label>
                  </div>
                </div>
                {showEditDescriptionModal && (
                  <button
                    onClick={() => setOpenChatGPTModal(true)}
                    className="w-full mt-3 py-3 flex items-center justify-center rounded-lg bg-[#75ac9d] md:hover:bg-[#67998b] transition-all duration-300"
                  >
                    <span className="text-base text-white font-medium mr-2">
                      Or Ask ChatGPT
                    </span>

                    <svg width="24px" height="24px" viewBox="140 140 520 520">
                      <path
                        d="m617.24 354a126.36 126.36 0 0 0 -10.86-103.79 127.8 127.8 0 0 0 -137.65-61.32 126.36 126.36 0 0 0 -95.31-42.49 127.81 127.81 0 0 0 -121.92 88.49 126.4 126.4 0 0 0 -84.5 61.3 127.82 127.82 0 0 0 15.72 149.86 126.36 126.36 0 0 0 10.86 103.79 127.81 127.81 0 0 0 137.65 61.32 126.36 126.36 0 0 0 95.31 42.49 127.81 127.81 0 0 0 121.96-88.54 126.4 126.4 0 0 0 84.5-61.3 127.82 127.82 0 0 0 -15.76-149.81zm-190.66 266.49a94.79 94.79 0 0 1 -60.85-22c.77-.42 2.12-1.16 3-1.7l101-58.34a16.42 16.42 0 0 0 8.3-14.37v-142.39l42.69 24.65a1.52 1.52 0 0 1 .83 1.17v117.92a95.18 95.18 0 0 1 -94.97 95.06zm-204.24-87.23a94.74 94.74 0 0 1 -11.34-63.7c.75.45 2.06 1.25 3 1.79l101 58.34a16.44 16.44 0 0 0 16.59 0l123.31-71.2v49.3a1.53 1.53 0 0 1 -.61 1.31l-102.1 58.95a95.16 95.16 0 0 1 -129.85-34.79zm-26.57-220.49a94.71 94.71 0 0 1 49.48-41.68c0 .87-.05 2.41-.05 3.48v116.68a16.41 16.41 0 0 0 8.29 14.36l123.31 71.19-42.69 24.65a1.53 1.53 0 0 1 -1.44.13l-102.11-59a95.16 95.16 0 0 1 -34.79-129.81zm350.74 81.62-123.31-71.2 42.69-24.64a1.53 1.53 0 0 1 1.44-.13l102.11 58.95a95.08 95.08 0 0 1 -14.69 171.55c0-.88 0-2.42 0-3.49v-116.68a16.4 16.4 0 0 0 -8.24-14.36zm42.49-63.95c-.75-.46-2.06-1.25-3-1.79l-101-58.34a16.46 16.46 0 0 0 -16.59 0l-123.31 71.2v-49.3a1.53 1.53 0 0 1 .61-1.31l102.1-58.9a95.07 95.07 0 0 1 141.19 98.44zm-267.11 87.87-42.7-24.65a1.52 1.52 0 0 1 -.83-1.17v-117.92a95.07 95.07 0 0 1 155.9-73c-.77.42-2.11 1.16-3 1.7l-101 58.34a16.41 16.41 0 0 0 -8.3 14.36zm23.19-50 54.92-31.72 54.92 31.7v63.42l-54.92 31.7-54.92-31.7z"
                        fill="#fff"
                      ></path>
                    </svg>
                  </button>
                )}
              </div>
            )}
            {translateActiveTab === 1 && (
              <div className="mt-4 flex w-full flex-col space-y-4 max-h-96 overflow-auto scrollbar">
                {!translateLoading &&
                  Object.keys(
                    sortedTranslateObj({ translate, langs: account?.languages || [] }),
                  ).map((item) => {
                    return (
                      <div
                        key={'edit-translate-' + item}
                        className="grid grid-cols-[1fr_4fr] items-center space-x-3 rtl:space-x-reverse w-full ltr:pr-2 rtl:pl-2"
                      >
                        <h2 className="rtl:text-right">{item.toUpperCase()}</h2>
                        <div>
                          <TextareaAutosize
                            minRows={2}
                            className="w-full scrollbar rounded-md border-gray-300 sm:text-sm"
                            placeholder={`${
                              showEditDescriptionModal ? 'Description' : 'Item name'
                            } `}
                            value={translate[item]}
                            onChange={(e) => {
                              setTranslate((prev: any) => {
                                return {
                                  ...prev,
                                  [item]: e.target.value,
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </>
        </div>
        <div className="w-full flex justify-end mt-4">
          <div className="flex items-center rtl:space-x-reverse space-x-4">
            {translateActiveTab !== 0 && (
              <button
                disabled={translateLoading}
                onClick={() => {
                  setTranslateActiveTab(0);
                }}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="previous" />
              </button>
            )}

            <button
              disabled={translateLoading}
              onClick={() => {
                if (withoutTranslate) {
                  if (itemName.trim()) {
                    handleCreateItem();
                  } else {
                    toast(
                      `${
                        showEditDescriptionModal ? 'Description' : 'Item name'
                      } required`,
                      {
                        ...defaultToastProps,
                        type: 'error',
                      },
                    );
                  }
                } else if (translateActiveTab === 0) {
                  if (showEditDescriptionModal) {
                    if (itemName.trim()) {
                      if (
                        selected?.value === activeItem?.description?.source_lang &&
                        itemName ===
                          // @ts-ignore
                          translate[
                            // @ts-ignore
                            selected?.value
                          ]
                      ) {
                        setTranslateActiveTab(1);
                      } else {
                        if (itemName.trim()) {
                          setTranslate({});
                          dispatch(
                            fetchLambdaTranslate({
                              setLoading: setTranslateLoading,
                              setData: setTranslate,
                              text: itemName.trim(),
                              // @ts-ignore
                              source_lang: selected?.value,
                              setTranslateActiveTab,
                            }),
                          );
                        } else {
                          toast(
                            `${
                              showEditDescriptionModal ? 'Description' : 'Item name'
                            } required`,
                            {
                              ...defaultToastProps,
                              type: 'error',
                            },
                          );
                        }
                      }
                    } else {
                      dispatch(
                        editItemField({
                          id: id,
                          field: 'description',
                          value: {},
                          setOpen: setOpen,
                          globalCategoryId,
                        }),
                      );
                    }
                  } else {
                    if (
                      // @ts-ignore
                      itemName === translate[activeItem?.name?.source_lang] &&
                      selected?.value === activeItem?.name?.source_lang
                    ) {
                      setTranslateActiveTab(1);
                    } else {
                      if (itemName.trim()) {
                        setTranslate({});
                        dispatch(
                          fetchLambdaTranslate({
                            setLoading: setTranslateLoading,
                            setData: setTranslate,
                            text: itemName,
                            // @ts-ignore
                            source_lang: selected?.value,
                            setTranslateActiveTab,
                          }),
                        );
                      } else {
                        toast(
                          `${
                            showEditDescriptionModal ? 'Description' : 'Item name'
                          } required`,
                          {
                            ...defaultToastProps,
                            type: 'error',
                          },
                        );
                      }
                    }
                  }
                } else {
                  if (
                    (!showEditDescriptionModal &&
                      JSON.stringify(activeItem?.name?.translations) !==
                        JSON.stringify(translate)) ||
                    (showEditDescriptionModal &&
                      JSON.stringify(activeItem?.description?.translations) !==
                        JSON.stringify(translate))
                  ) {
                    handleCreateItem();
                  } else {
                    setTranslateActiveTab(0);
                    setItemName('');
                    setTranslate({});
                    setSelected(
                      selectLanguages.find((lang) => lang.value === account?.locale),
                    );
                    setOpen(false);
                  }
                }
              }}
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
            >
              {loading || translateLoading ? (
                <Spinner color="bg-white" />
              ) : translateActiveTab === 0 && !withoutTranslate ? (
                <FormattedMessage id="next" />
              ) : (
                <FormattedMessage id="save" />
              )}
            </button>
          </div>
        </div>
      </div>
    </AppModal>
  );
};
