import { Bars4Icon } from '@heroicons/react/24/outline';
import { oldLanguages } from 'appConstants';
import { IOptions } from 'commonTypes';
import { useAppSelector } from 'hooks/store';

interface OptionRowBlockProps {
  option: IOptions;
}

export const OptionRowBlock = ({ option }: OptionRowBlockProps) => {
  const locale = useAppSelector((state) => state.locale.lang);

  return (
    <div className="flex items-center justify-between py-2 px-3 cursor-pointer bg-white md:hover:bg-gray-200 rounded-lg border">
      <div className="flex items-center">
        <p className="text-sm font-medium text-gray-900 select-none truncate">
          {option?.name?.translations
            ? option?.name?.translations[oldLanguages[locale]]
            : ''}
        </p>
        <div
          className={`h-2 w-2 ltr:ml-3 rtl:mr-3 rounded-full ${
            option?.status === 1 ? 'bg-green-500' : 'bg-gray-500'
          }`}
        />
      </div>

      <Bars4Icon
        className="h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
        aria-hidden="true"
      />
    </div>
  );
};
