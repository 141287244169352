import { SetStateType } from 'commonTypes';
import { CropDropdown } from 'components/Common/CropDropdown';

interface PhotoFieldProps {
  setActiveStep: SetStateType<number>;
  globalCategoryId: string;
}

export const PhotoField = ({ setActiveStep, globalCategoryId }: PhotoFieldProps) => {
  return (
    <CropDropdown
      setActiveStep={setActiveStep}
      addItemMode={true}
      globalCategoryId={globalCategoryId}
    />
  );
};
