import { XMarkIcon } from '@heroicons/react/24/outline';
import { selectLanguages } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { ModalChangeLanguage } from 'components/Common/ModalChangeLanguage';
import { createNameWithoutTransalte } from 'helpers/menu';
import {
  positiveNumberInput,
  positiveNumberValue,
  sortedTranslateObj,
} from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';

import { OptionBottomModalButton } from './OptionBottomModalButton';

interface OptionSelectionModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  id?: string;
  optionEdit?: boolean;
  addModal?: boolean;
  active?: any;
}

export const OptionSelectionModal = ({
  open,
  setOpen,
  id,
  optionEdit = false,
  addModal = false,
  active,
}: OptionSelectionModalProps) => {
  const [translateActiveTab, setTranslateActiveTab] = useState(0);
  const account = useAppSelector((state) => state.account.account);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const loadingAccount = useAppSelector((state) => state.account.loading);
  const [selected, setSelected] = useState(
    selectLanguages.find((elem) => elem.value === 'en'),
  );
  const [selectionName, setSelectionName] = useState('');
  const [translate, setTranslate] = useState<any>({});
  const [translateLoading, setTranslateLoading] = useState(false);
  const [selectionPrice, setSelectionPrice] = useState(0);
  const [withoutTranslation, setWithoutTranslation] = useState(false);

  const steps = [
    { name: <FormattedMessage id="fillTheInformation" />, id: 1 },
    { name: <FormattedMessage id="checkTranslation" />, id: 2 },
  ];

  useEffect(() => {
    if (account) {
      setSelected(selectLanguages.find((lang) => lang.value === account?.locale));
    }
    if (active && active?.name?.translations && active?.name?.source_lang) {
      setSelectionName(active?.name?.translations[active?.name?.source_lang]);
      setSelected(
        selectLanguages.find((lang) => lang.value === active?.name?.source_lang),
      );
      setTranslate(active?.name?.translations);
      setSelectionPrice(active?.price || 0);
      setWithoutTranslation(!!active?.name?.withoutTranslate);
    }
  }, [account, open, active]);

  const resetFields = () => {
    setTranslate({});
    setTranslateActiveTab(0);
    setTranslateLoading(false);
    setSelectionPrice(0);
    setSelectionName('');
    setWithoutTranslation(false);
  };

  return (
    <AppModal size="sm:max-w-2xl" open={open}>
      <div className="flex flex-col w-full">
        <div className="flex w-full items-center justify-between mb-3">
          <h1>
            <FormattedMessage
              id={
                addModal
                  ? optionEdit
                    ? 'addOption'
                    : 'addSelection'
                  : optionEdit
                  ? 'editOption'
                  : 'editSelection'
              }
            />
          </h1>
          <XMarkIcon
            onClick={() => {
              if (!loadingAccount && !translateLoading) {
                setOpen(false);
                setTimeout(() => {
                  resetFields();
                }, 200);
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="w-full">
          <div className="mt-2 mb-3" aria-hidden="true">
            <div className="overflow-hidden rounded-full bg-gray-200">
              <div
                className="h-2 rounded-full bg-indigo-600"
                style={{
                  width: ((+translateActiveTab + 1) / steps.length) * 100 + '%',
                }}
              />
            </div>
            <div className="mt-6 hidden grid-cols-2 text-sm font-medium text-gray-600 sm:grid">
              {steps.map((item) => (
                <div
                  className={`${
                    +translateActiveTab + 1 === item.id ? 'text-indigo-600' : ''
                  } ${
                    item.id === 1
                      ? 'ltr:text-left rtl:text-right'
                      : 'ltr:text-right rtl:text-left'
                  }`}
                  key={'edit text-item-' + item.id}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="w-full">
          <>
            {translateActiveTab === 0 && !optionEdit && (
              <div className="flex flex-col w-full">
                <div className="flex items-center mb-2">
                  <div className="w-1/2 h-px bg-gray-300" />
                  <p className="text-sm text-gray-500 whitespace-nowrap px-2">
                    <FormattedMessage id="price" />
                  </p>
                  <div className="w-1/2 h-px bg-gray-300" />
                </div>

                <div className="flex items-center justify-between w-full mb-3 flex-wrap">
                  <div>
                    <label
                      htmlFor="price"
                      className="block text-sm rtl:text-right font-medium text-gray-700"
                    >
                      <FormattedMessage id="price" />
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="price"
                        value={positiveNumberValue('' + selectionPrice, intCurrency)}
                        onChange={(e) =>
                          // @ts-ignore
                          setSelectionPrice(positiveNumberInput(e, intCurrency))
                        }
                        id="price"
                        className="block w-full rounded-md border-gray-300 ltr:pr-12 rtl:pl-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder={intCurrency ? '0' : '0.00'}
                        aria-describedby="price-currency"
                      />
                      <div className="pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-3 rtl:pl-3">
                        <span className="text-gray-500 sm:text-sm" id="price-currency">
                          {account?.currency?.toUpperCase()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex items-center mb-2">
                  <div className="w-1/2 h-px bg-gray-300" />
                  <p className="text-sm text-gray-500 whitespace-nowrap px-2">
                    <FormattedMessage id="selectionName" />
                  </p>
                  <div className="w-1/2 h-px bg-gray-300" />
                </div>
              </div>
            )}
            <div className="flex items-center justify-between w-full">
              {translateActiveTab === 0 && (
                <div className="flex items-center justify-between w-full flex-wrap">
                  <ModalChangeLanguage
                    selected={selected}
                    setSelected={setSelected}
                    disabled={loadingAccount || translateLoading}
                  />
                </div>
              )}
            </div>
            {translateActiveTab === 0 && (
              <>
                <div className="mt-4 flex w-full justify-between items-end">
                  <div className="w-full">
                    <div className="mt-0">
                      <TextareaAutosize
                        minRows={2}
                        name="name"
                        id="selection-name"
                        value={selectionName}
                        onChange={(e) => {
                          setSelectionName(e.target.value);
                          if (withoutTranslation) {
                            setTranslate(createNameWithoutTransalte(e.target.value));
                          } else {
                            setTranslate({});
                          }
                        }}
                        className="w-full scrollbar rounded-md border-gray-300 sm:text-sm"
                        placeholder={`${optionEdit ? 'Option' : 'Selection'} name`}
                      />
                    </div>
                  </div>
                </div>
                <div className="relative flex items-start justify-end mb-4">
                  <div className="flex h-6 items-center">
                    <input
                      id="without-translate"
                      aria-describedby="without-translate"
                      name="without-translate"
                      type="checkbox"
                      checked={withoutTranslation}
                      onChange={(e) => {
                        if (setWithoutTranslation) {
                          setWithoutTranslation(e.target.checked);
                        }
                        if (e.target.checked) {
                          if (selectionName.trim()) {
                            setTranslate(
                              createNameWithoutTransalte(selectionName.trim()),
                            );
                          }
                        } else {
                          setTranslate({});
                        }
                      }}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="without-translate"
                      className="font-medium text-gray-900 select-none"
                    >
                      <FormattedMessage id="withoutTranslate" />
                    </label>
                  </div>
                </div>
              </>
            )}
            {translateActiveTab === 1 && (
              <div className="mt-4 flex w-full flex-col space-y-4 max-h-96 overflow-auto scrollbar">
                {!translateLoading &&
                  Object.keys(
                    sortedTranslateObj({ translate, langs: account?.languages || [] }),
                  ).map((item) => {
                    return (
                      <div
                        key={'edit-translate-selection-' + item}
                        className="grid grid-cols-[1fr_4fr] items-center space-x-3 rtl:space-x-reverse w-full ltr:pr-2 rtl:pl-2"
                      >
                        <h2 className="rtl:text-right">{item.toUpperCase()}</h2>
                        <div>
                          <TextareaAutosize
                            minRows={2}
                            className="w-full scrollbar rounded-md border-gray-300 sm:text-sm"
                            placeholder={`${optionEdit ? 'Option' : 'Selection'} name`}
                            value={translate[item]}
                            onChange={(e) => {
                              setTranslate((prev: any) => {
                                return {
                                  ...prev,
                                  [item]: e.target.value,
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </>
        </div>

        <OptionBottomModalButton
          translateActiveTab={translateActiveTab}
          setTranslateActiveTab={setTranslateActiveTab}
          translateLoading={translateLoading}
          setTranslateLoading={setTranslateLoading}
          itemName={selectionName}
          setTranslate={setTranslate}
          selected={selected}
          optionEdit={optionEdit}
          active={active}
          oldPrice={active?.price || 0}
          oldTranslate={active?.name?.translations}
          loadingAccount={loadingAccount}
          translation={translate}
          selectionPrice={selectionPrice}
          addModal={addModal}
          id={id || ''}
          setOpen={setOpen}
          resetFields={resetFields}
          withoutTranslation={withoutTranslation}
        />
      </div>
    </AppModal>
  );
};
