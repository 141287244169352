import { FreeCheckBox } from '@Common/FreeCheckBox';
import { PriceElement, SetStateType } from 'commonTypes';
import { MultiCheckBox } from 'components/Common/MultiCheckBox';
import { MultiPriceRow } from 'components/Common/MultiPriceRow';
import { classNames, positiveNumberInput } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

interface AddPriceBlockProps {
  itemCreated: boolean;
  priceValue: PriceElement;
  setPriceValue: SetStateType<PriceElement>;
  multiple: boolean;
  setMultiple: SetStateType<boolean>;
  multiplePriceValue: PriceElement[];
  setMultiplePriceValue: SetStateType<PriceElement[]>;
  free: boolean;
  setFree: SetStateType<boolean>;
}

export const AddPriceBlock = ({
  itemCreated,
  priceValue,
  setPriceValue,
  multiple,
  setMultiple,
  multiplePriceValue,
  setMultiplePriceValue,
  free,
  setFree,
}: AddPriceBlockProps) => {
  const account = useAppSelector((state) => state.account.account);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);

  useEffect(() => {
    if (free) {
      setPriceValue((prev) => ({ ...prev, value: '-1' }));
    } else {
      setPriceValue((prev) => ({ ...prev, value: '0' }));
    }
  }, [free]);

  return (
    <dl className="divide-y divide-gray-200">
      <div className="py-2 sm:grid sm:grid-cols-[150px_1fr] sm:gap-4 sm:py-3 items-center">
        <dt className="text-sm font-medium text-gray-500 truncate">
          <FormattedMessage id="price" />
        </dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-1 sm:mt-0">
          <div className="flex-grow">
            <div className="flex items-center space-x-4">
              <MultiCheckBox
                multiple={multiple}
                setMultiple={setMultiple}
                disabled={itemCreated || free}
              />
              <FreeCheckBox
                free={free}
                setFree={setFree}
                disabled={itemCreated || multiple}
              />
            </div>

            {!multiple && (
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  name="price"
                  id="price"
                  disabled={itemCreated || free}
                  className={classNames(
                    'block w-full rounded-md border-gray-300 rtl:pl-12 ltr:pr-12 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm',
                    free && 'opacity-70',
                  )}
                  placeholder={intCurrency ? '0' : '0.00'}
                  aria-describedby="price-currency"
                  value={priceValue.value === '-1' ? '0' : priceValue.value}
                  onChange={(e) => {
                    if (!itemCreated) {
                      setPriceValue((prev) => {
                        const newVal = { ...prev };
                        newVal.value = positiveNumberInput(e, intCurrency);
                        return newVal;
                      });
                    }
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 rtl:left-0 ltr:right-0 flex items-center rtl:pl-3 ltr:pr-3">
                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                    {account?.currency}
                  </span>
                </div>
              </div>
            )}
            {multiple && (
              <MultiPriceRow
                multiplePriceValue={multiplePriceValue}
                setMultiplePriceValue={setMultiplePriceValue}
                disabled={itemCreated}
              />
            )}
          </div>
        </dd>
      </div>
    </dl>
  );
};
