import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { ApiThemeState } from 'store/themes/types';

import { ConfirmChangeActiveThemeModal } from '../Modals';

interface ActiveThemeSelectProps {
  activeTheme: ApiThemeState | undefined;
  setActiveTheme: SetStateType<ApiThemeState | undefined>;
  changeActive?: boolean;
  setActiveChooseTheme?: SetStateType<ApiThemeState | undefined>;
  activeChooseTheme?: ApiThemeState | undefined;
  testId?: string;
}

export const ActiveThemeSelect = ({
  activeTheme,
  setActiveTheme,
  changeActive = false,
  setActiveChooseTheme,
  activeChooseTheme,
  testId = '',
}: ActiveThemeSelectProps) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const themesList = useAppSelector((state) => state.themes.themeList);
  const [nextActiveTheme, setNextActiveTheme] = useState<string>('');

  useEffect(() => {
    if (!activeTheme && themesList.length > 0) {
      setActiveTheme(themesList.find((theme) => theme.status === 1));
    }
  }, [themesList]);

  return (
    <>
      <ConfirmChangeActiveThemeModal
        open={openConfirm}
        setOpen={setOpenConfirm}
        nextActiveTheme={nextActiveTheme}
        setActiveTheme={setActiveTheme}
        setActiveChooseTheme={setActiveChooseTheme}
        activeChooseTheme={activeChooseTheme}
      />
      <Listbox
        as="div"
        value={activeTheme}
        onChange={(theme: ApiThemeState) => {
          if (changeActive && theme.id !== activeTheme?.id) {
            setNextActiveTheme(theme.id);
            setOpenConfirm(true);
          } else if (theme.id !== activeTheme?.id) {
            setActiveTheme(theme);
          }
        }}
      >
        <div className="relative">
          <Listbox.Button
            data-testId={testId}
            className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            <span className="block truncate">{activeTheme?.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          {themesList.length > 0 && (
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg border border-black border-opacity-5 focus:outline-none sm:text-sm">
              {themesList.map((theme) => (
                <Listbox.Option
                  data-testId={'cypress-active-theme-' + theme.name.toLowerCase()}
                  key={'theme-' + theme.id}
                  value={theme}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames('truncate', selected && 'font-semibold')}
                      >
                        {theme.name}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          )}
        </div>
      </Listbox>
    </>
  );
};
