import { Bars4Icon } from '@heroicons/react/24/outline';
import { oldLanguages } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { Categories } from 'store/menu/types';

interface CategoryButtonProps {
  category: Categories | undefined;
  active: boolean;
  setActiveCategory: SetStateType<string>;
  activeGlobalMenu: string;
  noClick?: boolean;
}

export const CategoryButton = ({
  category,
  active,
  setActiveCategory,
  activeGlobalMenu,
  noClick = false,
}: CategoryButtonProps) => {
  const account = useAppSelector((state) => state.account.account);
  const locale = useAppSelector((state) => state.locale.lang);

  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames(
        active
          ? 'bg-primary text-white md:hover:bg-white md:hover:text-primary'
          : 'text-primary bg-white md:hover:bg-primary md:hover:text-white',
        'cursor-pointer w-full relative rounded text-sm text-center py-1 transition border border-primary',
      )}
      onClick={() => {
        if (!noClick && category?.id) {
          setActiveCategory(category?.id);
          localStorage.setItem(
            'activeCategory-' + activeGlobalMenu + '-' + account?.id,
            category?.id,
          );
        }
      }}
    >
      {category?.status !== 4 ? (
        <div className="absolute top-0 ltr:left-1 rtl:right-1 h-full">
          <Bars4Icon className="h-full w-6" />
        </div>
      ) : null}

      <div className="flex w-full items-center justify-center">
        <div className="max-w-[80%]">
          {category?.name?.translations &&
            category?.name?.translations[oldLanguages[locale]]}
        </div>
      </div>

      {category?.status === 1 && (
        <div className="h-2.5 w-2.5 rounded-full bg-green-500 absolute ltr:right-1.5 rtl:left-1.5 bottom-1/2 translate-y-2/4" />
      )}
      {category?.status === 2 && (
        <div className="h-2.5 w-2.5 rounded-full bg-red-500 absolute ltr:right-1.5 rtl:left-1.5 bottom-1/2 translate-y-2/4" />
      )}
      {category?.status === 3 && (
        <div className="h-2.5 w-2.5 rounded-full bg-gray-500 absolute ltr:right-1.5 rtl:left-1.5 bottom-1/2 translate-y-2/4" />
      )}
    </div>
  );
};
