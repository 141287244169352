import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';

interface RegisterAvailablePasswordProps {
  avaliblePassword: {
    length: boolean;
    upperCase: boolean;
    lowerCase: boolean;
    number: boolean;
    special: boolean;
  };
}

export const RegisterAvailablePassword = ({
  avaliblePassword,
}: RegisterAvailablePasswordProps) => {
  return (
    <div className="mt-2">
      <span className="text-sm mb-1">The password must</span>
      <ul>
        <li className="flex items-center mb-2">
          {avaliblePassword.length ? (
            <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          )}
          <span className="ml-2 text-sm text-gray-500">
            be at least 8 characters long
          </span>
        </li>
        <li className="flex items-center mb-2">
          {avaliblePassword.number ? (
            <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          )}
          <span className="ml-2 text-sm text-gray-500">contain at least 1 number</span>
        </li>
        <li className="flex items-center mb-2">
          {avaliblePassword.special ? (
            <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          )}
          <span className="ml-2 text-sm text-gray-500">
            contain at least 1 special character
          </span>
        </li>
        <li className="flex items-center mb-2">
          {avaliblePassword.upperCase ? (
            <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          )}
          <span className="ml-2 text-sm text-gray-500">
            contain at least 1 uppercase letter
          </span>
        </li>
        <li className="flex items-center mb-2">
          {avaliblePassword.lowerCase ? (
            <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          )}
          <span className="ml-2 text-sm text-gray-500">
            contain at least 1 lowercase letter
          </span>
        </li>
      </ul>
    </div>
  );
};
