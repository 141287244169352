import { Crumbs } from 'commonTypes';
import { Spinner } from 'components/Common/Spinner';
import {
  FirstProfileTab,
  ImageRestaurant,
  ProfileTabs,
} from 'components/ProfileComponents';
import { AccountTab } from 'components/ProfileComponents/RootPageModule';
import { useAppSelector } from 'hooks/store';
import { Layout } from 'pages/Layout/Layout';
import { useEffect } from 'react';
import { useState } from 'react';

export const Profile = () => {
  const account = useAppSelector((state) => state.account.account);
  const cognitoUser = useAppSelector((state) => state.account.cognitoUser);
  const loadingAccount = useAppSelector((state) => state.account.loading);
  const [activeTab, setActiveTab] = useState(
    +(localStorage.getItem('activeTabAccount') || 0),
  );
  const notification = useAppSelector((state) => state.account.notification);

  useEffect(() => {
    if (notification === 'reviews' || notification === 'changeDefaultPhoto') {
      setActiveTab(0);
    }
  }, [notification]);

  useEffect(() => {
    if (!localStorage.getItem('activeTabAccount')) {
      localStorage.setItem('activeTabAccount', '0');
    }
  }, []);

  const crumbs: Crumbs[] = [{ name: 'Profile', href: '/profile', current: true }];

  return (
    <Layout crumbs={crumbs}>
      {loadingAccount || !account || !cognitoUser ? (
        <div className="flex justify-center items-start h-screen">
          <Spinner color="bg-primary" />
        </div>
      ) : (
        <div
          className="flex w-full flex-wrap justify-between ipad:flex-nowrap items-start p-4 bg-white rounded-lg"
          style={{
            minHeight: 'calc(100vh - 64px - 36px - 44px - 56px)',
          }}
        >
          <ImageRestaurant />

          <div className="w-full ipad:w-7/12 flex flex-grow flex-col space-y-4 ipad:ltr:ml-7 ipad:rtl:mr-7">
            <ProfileTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            {+activeTab === 1 && <FirstProfileTab />}
            {+activeTab === 0 && <AccountTab />}
          </div>
        </div>
      )}
    </Layout>
  );
};
