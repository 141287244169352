import { AxiosInstance } from 'axios';
import { Languages } from 'commonTypes';
import { TRANSLATOR_BASE_URL } from 'configure';

export const translate = (
  ApiClient: (
    publicRequest: boolean,
    baseUrl: string,
    idToken: boolean,
  ) => Promise<AxiosInstance>,
) => {
  return {
    fetchTranslate: async (body: { source_language: Languages; text: string }) =>
      (await ApiClient(false, TRANSLATOR_BASE_URL, true)).post<Record<Languages, string>>(
        `/`,
        body,
      ),
  };
};
