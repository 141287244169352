import { Menu, Switch, Transition } from '@headlessui/react';
import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  EllipsisVerticalIcon,
  HeartIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { Bars4Icon, PlusIcon as PlusIconOutline } from '@heroicons/react/24/outline';
import { oldLanguages } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { Loading } from 'components/Common/Loading';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { switchSimpleCategory } from 'store/category/actions';
import { Categories, Items } from 'store/menu/types';

import {
  AddCategoryModal,
  ChangeCategoryMenuModal,
  ChangeCategoryStatusModal,
  ChangeItemsOrderModal,
  DeleteCategoryModal,
} from '../Modals';

interface ActiveItemHeaderProps {
  activeCategory: Categories;
  setActiveCategory: SetStateType<string>;
  categoryList: Categories[];
  itemsList: Items[];
  setItemsList: SetStateType<Items[]>;
  activeGlobalMenu: string;
}

export const ActiveItemHeader = ({
  activeCategory,
  setActiveCategory,
  categoryList,
  itemsList,
  setItemsList,
  activeGlobalMenu,
}: ActiveItemHeaderProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.category.subLoading);
  const account = useAppSelector((state) => state.account.account);
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [showChangeItemsOrder, setShowChangeItemsOrder] = useState(false);
  const [showChangeCategoryMenu, setShowChangeCategoryMenu] = useState(false);
  const [enabledSimpleCategory, setEnabledSimpleCategory] = useState(false);
  const locale = useAppSelector((state) => state.locale.lang);

  useEffect(() => {
    if (activeCategory) {
      setEnabledSimpleCategory(activeCategory?.is_simple);
    }
  }, [activeCategory]);

  return (
    <div className="relative border-b border-gray-200 pb-4 mb-4 md:mb-0">
      <div className="sm:flex sm:items-start sm:justify-between">
        <div className="sm:w-0 sm:flex-1 mb-0 md:mb-2">
          <div className="flex w-full items-center justify-between">
            <h1 id="message-heading" className="text-lg font-medium text-gray-900">
              {activeCategory?.name?.translations &&
                activeCategory?.name?.translations[oldLanguages[locale]]}
            </h1>
            {activeCategory?.status !== 4 && (
              <Menu
                as="div"
                className="relative ltr:ml-3 rtl:mr-3 inline-block ltr:text-left rtl:text-right md:hidden"
              >
                <div>
                  <Menu.Button className="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 md:hover:text-gray-600 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute ltr:right-0 rtl:left-0 z-10 mt-2 w-56 ltr:origin-top-right rtl:origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) =>
                          activeCategory?.status !== 4 ? (
                            <div
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                setOpenEditCategory(true);
                              }}
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'group flex items-center px-4 py-2 text-sm cursor-pointer',
                              )}
                            >
                              <PencilSquareIcon
                                className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              <span>
                                <FormattedMessage id="edit" />
                              </span>
                            </div>
                          ) : (
                            <></>
                          )
                        }
                      </Menu.Item>
                      {(activeCategory?.items?.length || 0) > 1 && (
                        <Menu.Item>
                          {({ active }) =>
                            activeCategory?.status !== 4 ? (
                              <div
                                role="button"
                                tabIndex={0}
                                onClick={() => {
                                  setShowChangeItemsOrder(true);
                                }}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'group flex items-center px-4 py-2 text-sm cursor-pointer',
                                )}
                              >
                                <Bars4Icon
                                  className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                                <span>
                                  <FormattedMessage id="changeItemsOrder" />
                                </span>
                              </div>
                            ) : (
                              <></>
                            )
                          }
                        </Menu.Item>
                      )}
                      {activeCategory?.status !== 4 && account?.with_global_menus && (
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                setShowChangeCategoryMenu(true);
                              }}
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'group flex items-center px-4 py-2 text-sm cursor-pointer',
                              )}
                            >
                              <ArrowRightCircleIcon
                                className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              <span className="truncate">
                                <FormattedMessage id="move" />
                              </span>
                            </div>
                          )}
                        </Menu.Item>
                      )}

                      <Menu.Item>
                        {({ active }) => (
                          <div
                            role="button"
                            tabIndex={0}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm cursor-pointer',
                            )}
                            onClick={() => {
                              setShowChangeStatus(true);
                            }}
                          >
                            {activeCategory?.status === 3 && (
                              <HeartIcon
                                className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                            )}
                            {activeCategory?.status === 1 && (
                              <ArchiveBoxIcon
                                className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                            )}
                            {activeCategory?.status === 3 && (
                              <FormattedMessage id="active" />
                            )}
                            {activeCategory?.status === 1 && (
                              <FormattedMessage id="archived" />
                            )}
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) =>
                          activeCategory?.status !== 4 ? (
                            <div
                              role="button"
                              tabIndex={0}
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'group flex items-center px-4 py-2 text-sm cursor-pointer',
                              )}
                              onClick={() => setOpenDeleteCategory(true)}
                            >
                              <TrashIcon
                                className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              <span>
                                <FormattedMessage id="delete" />
                              </span>
                            </div>
                          ) : (
                            <></>
                          )
                        }
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
          </div>

          {activeCategory?.status !== 4 &&
            !!activeCategory?.description?.translations && (
              <p className="mt-1 truncate text-sm text-gray-500">
                <FormattedMessage id="description" />:{' '}
                {activeCategory?.description?.translations[oldLanguages[locale]] || (
                  <FormattedMessage id="no" />
                )}
              </p>
            )}
        </div>

        {activeCategory?.status !== 4 && (
          <Switch.Group as="div" className="flex items-center">
            <Switch.Label as="span" className="mr-3 text-sm">
              <span className="text-gray-500">
                <FormattedMessage id="simpleCategory" />
              </span>
            </Switch.Label>
            <Switch
              checked={enabledSimpleCategory}
              onChange={(value) => {
                dispatch(
                  switchSimpleCategory({
                    categoryId: activeCategory?.id,
                    value,
                    globalMenuId: activeGlobalMenu,
                    resetFields: () => {
                      setEnabledSimpleCategory(value);
                    },
                  }),
                );
              }}
              className={classNames(
                enabledSimpleCategory ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  enabledSimpleCategory ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              >
                {loading && <Loading />}
              </span>
            </Switch>
          </Switch.Group>
        )}

        <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ltr:ml-6 sm:rtl:mr-6 sm:flex-shrink-0 sm:justify-start mb-3 md:mb-0">
          {activeCategory?.status !== 4 && activeCategory?.status === 1 ? (
            <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
              <FormattedMessage id="active" />
            </span>
          ) : activeCategory?.status !== 4 && activeCategory?.status === 2 ? (
            <span className="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-sm font-medium text-red-800">
              <FormattedMessage id="deleted" />
            </span>
          ) : activeCategory?.status !== 4 && activeCategory?.status === 3 ? (
            <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800">
              <FormattedMessage id="archived" />
            </span>
          ) : (
            <></>
          )}
        </div>

        {activeCategory?.status !== 4 && (
          <Menu
            as="div"
            className="relative ltr:ml-3 rtl:mr-3 ltr:text-left rtl:text-right hidden md:inline-block"
          >
            <div>
              <Menu.Button className="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 md:hover:text-gray-600 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute ltr:right-0 rtl:left-0 z-10 mt-2 w-56 rtl:origin-top-left ltr:origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) =>
                      activeCategory?.status !== 4 ? (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            setOpenEditCategory(true);
                          }}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm cursor-pointer',
                          )}
                        >
                          <PencilSquareIcon
                            className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          <span>
                            <FormattedMessage id="edit" />
                          </span>
                        </div>
                      ) : (
                        <></>
                      )
                    }
                  </Menu.Item>
                  {(activeCategory?.items?.length || 0) > 1 && (
                    <Menu.Item>
                      {({ active }) =>
                        activeCategory?.status !== 4 ? (
                          <div
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              setShowChangeItemsOrder(true);
                            }}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm cursor-pointer',
                            )}
                          >
                            <Bars4Icon
                              className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            <span>
                              <FormattedMessage id="changeItemsOrder" />
                            </span>
                          </div>
                        ) : (
                          <></>
                        )
                      }
                    </Menu.Item>
                  )}

                  {activeCategory?.status !== 4 && account?.with_global_menus && (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            setShowChangeCategoryMenu(true);
                          }}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm cursor-pointer',
                          )}
                        >
                          <ArrowRightCircleIcon
                            className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          <span className="truncate">
                            <FormattedMessage id="move" />
                          </span>
                        </div>
                      )}
                    </Menu.Item>
                  )}

                  <Menu.Item>
                    {({ active }) => (
                      <div
                        role="button"
                        tabIndex={0}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm cursor-pointer',
                        )}
                        onClick={() => {
                          setShowChangeStatus(true);
                        }}
                      >
                        {activeCategory?.status === 3 && (
                          <HeartIcon
                            className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        )}
                        {activeCategory?.status === 1 && (
                          <ArchiveBoxIcon
                            className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        )}
                        {activeCategory?.status === 3 && <FormattedMessage id="active" />}
                        {activeCategory?.status === 1 && (
                          <FormattedMessage id="archived" />
                        )}
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) =>
                      activeCategory?.status !== 4 ? (
                        <div
                          role="button"
                          tabIndex={0}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm cursor-pointer',
                          )}
                          onClick={() => setOpenDeleteCategory(true)}
                        >
                          <TrashIcon
                            className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          <span>
                            <FormattedMessage id="delete" />
                          </span>
                        </div>
                      ) : (
                        <></>
                      )
                    }
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
      {activeCategory?.name && (
        <AddCategoryModal
          open={openEditCategory}
          setOpen={setOpenEditCategory}
          name={activeCategory?.name}
          descriptionOld={activeCategory?.description}
          id={activeCategory?.id}
          edit={true}
          globalMenuId={activeGlobalMenu}
        />
      )}

      <DeleteCategoryModal
        open={openDeleteCategory}
        setOpen={setOpenDeleteCategory}
        category={activeCategory?.id}
        setActiveCategory={setActiveCategory}
        categoryList={categoryList}
        globalMenuId={activeGlobalMenu}
      />

      <ChangeCategoryStatusModal
        open={showChangeStatus}
        setOpen={setShowChangeStatus}
        status={activeCategory?.status}
        id={activeCategory?.id}
        globalMenuId={activeGlobalMenu}
      />

      <ChangeCategoryMenuModal
        open={showChangeCategoryMenu}
        setOpen={setShowChangeCategoryMenu}
        activeGlobalMenu={activeGlobalMenu}
        activeCategoryId={activeCategory?.id}
        categoryList={categoryList}
        setActiveCategory={setActiveCategory}
      />

      <ChangeItemsOrderModal
        open={showChangeItemsOrder}
        setOpen={setShowChangeItemsOrder}
        itemsList={itemsList}
        setItemsList={setItemsList}
        categoryId={activeCategory?.id}
      />

      {(activeCategory?.items?.length || 0) > 0 && (
        <Link
          to="/add-item"
          className="absolute right-1/2 translate-x-2/4 text-center px-12 w-auto py-1.5 group rounded-2xl bg-white border border-gray-200 flex items-center md:hover:bg-primary md:hover:text-white flex-nowrap"
        >
          <PlusIconOutline
            className="h-4 w-4 text-primary md:group-hover:text-white"
            aria-hidden="true"
          />
          <span className="ltr:ml-2 rtl:mr-2 text-sm text-primary md:group-hover:text-white w-aouto whitespace-nowrap">
            <FormattedMessage id="additem" />
          </span>
        </Link>
      )}
    </div>
  );
};
