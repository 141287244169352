import { SetStateType } from 'commonTypes';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface ItemTableRowProps {
  label: string;
  value: string | number | ReactNode | null;
  setShowModal?: SetStateType<boolean>;
  setShowEditModal: SetStateType<boolean>;
}

export const ItemTableRow = ({
  label,
  value,
  setShowModal,
  setShowEditModal,
}: ItemTableRowProps) => {
  return (
    <div className="py-4 sm:grid sm:grid-cols-[120px_1fr_minmax(50px,_100px)] sm:gap-4 sm:py-5 items-center">
      <dt className="text-sm font-medium text-gray-500 truncate">
        <FormattedMessage id={label} />
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 items-center">
        <div className="flex-grow scrollbar overflow-x-auto">{value}</div>
        <span className="ml-4 flex-shrink-0">
          <button
            onClick={() => {
              if (label === 'name' && setShowModal) {
                setShowModal(false);
              } else if (label === 'description' && setShowModal) {
                setShowModal(true);
              }
              setShowEditModal(true);
            }}
            className="rounded-md bg-white truncate font-medium text-indigo-600 md:hover:text-indigo-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="edit" />
          </button>
        </span>
      </dd>
    </div>
  );
};
