//eslint-ignore

export const Milk = ({ width = 32, height = 32, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 232 232"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_417_64)">
      <path
        d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
        fill="#499FFC"
      />
      <path
        d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
        fill="#6BA7FF"
      />
      <path
        d="M229.98 116.591L146.794 33.4049H114.078H81.3628V42.7521L90.71 52.0993L90.6155 77.9601L72.0156 192.932L108.925 229.841C110.936 229.946 112.962 230 115 230C177.981 230 229.127 179.369 229.98 116.591Z"
        fill="#2681FF"
      />
      <path
        d="M144.818 81.1922C140.062 76.1259 137.447 69.5127 137.447 62.5618V52.0997C137.447 46.947 141.641 42.7525 146.794 42.7525L137.446 38.0787H114.078H90.71L81.3628 42.7525C86.5155 42.7525 90.71 46.947 90.71 52.0997V62.6257C90.71 71.4346 85.9268 78.3854 81.9151 82.6623C75.53 89.4668 72.0156 98.3576 72.0156 107.691V108.495L84.2835 117.842H114.078H142.315L156.141 108.495V107.627C156.141 98.2392 152.225 89.0973 144.818 81.1922Z"
        fill="#EEF4FF"
      />
      <path
        d="M114.078 164.578H81.3628L72.0156 173.925V192.932H114.078H156.141V173.925L146.794 164.578H114.078Z"
        fill="#EEF4FF"
      />
      <path
        d="M156.141 108.495V107.627C156.141 98.2392 152.225 89.0973 144.817 81.1922C140.061 76.1259 137.446 69.5127 137.446 62.5618V52.0997C137.446 46.947 141.641 42.7525 146.793 42.7525L137.446 38.0787H114.078V117.842H142.315L156.141 108.495Z"
        fill="#D9E6FC"
      />
      <path
        d="M156.141 173.925L146.793 164.578H114.078V192.932H156.141V173.925Z"
        fill="#D9E6FC"
      />
      <path
        d="M128.572 108.495H114.078H109.216L72.0156 145.884V173.925H114.078H156.141V127.189L128.572 108.495Z"
        fill="#FCF5F5"
      />
      <path
        d="M156.141 127.189L128.572 108.495H114.078V173.925H156.141V127.189Z"
        fill="#EBE1DC"
      />
      <path
        d="M100.057 129.125C100.057 126.112 101.23 123.278 103.362 121.147C106.795 117.714 108.77 113.265 109.216 108.495H72.0156V145.884H83.2982C92.5405 145.884 100.057 138.367 100.057 129.125Z"
        fill="#674D55"
      />
      <path
        d="M128.572 108.495C130.744 119.148 140.183 127.189 151.467 127.189H156.141V108.495H128.572Z"
        fill="#52374C"
      />
      <path
        d="M123.425 155.231C115.694 155.231 109.404 148.942 109.404 141.21C109.404 133.478 115.694 127.189 123.425 127.189C131.157 127.189 137.446 133.478 137.446 141.21C137.446 148.942 131.157 155.231 123.425 155.231Z"
        fill="#52374C"
      />
      <path
        d="M114.078 33.4049H81.3628V42.7521H114.078H146.794V33.4049H114.078Z"
        fill="#D9E6FC"
      />
      <path d="M114.078 33.4049H146.793V42.7521H114.078V33.4049Z" fill="#BED9FD" />
    </g>
    <defs>
      <clipPath id="clip0_417_64">
        <rect width="230" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
