import { FormattedMessage } from 'react-intl';

interface OptionAddButtonProps {
  onClick: () => void;
}

export const OptionAddButton = ({ onClick }: OptionAddButtonProps) => {
  return (
    <button
      onClick={onClick}
      type="button"
      data-testId="cypress-add-option-btn"
      className="inline-flex whitespace-nowrap items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      + <FormattedMessage id="addOption" />
    </button>
  );
};
