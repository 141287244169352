import { LabelsPreview } from '@Common/LabelsPreview';
import { oldLanguages } from 'appConstants';
import { ReactComponent as NoPhoto } from 'assets/static/no_photo.svg';
import { SetStateType } from 'commonTypes';
import { PreloadComponent } from 'components/ImagePreload/PreloadComponent/PreloadComponent';
import { classNames, returnNumberPrice } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Items } from 'store/menu/types';

import { CategoryDropdownMenu } from './CategoryDropdownMenu';

interface MenuListItemProps {
  product: Items;
  setActiveItem: SetStateType<string | null>;
  setOpenCategoryChange: SetStateType<boolean>;
  setShowChangeStatusModal: SetStateType<boolean>;
  setOpenDeleteModal: SetStateType<boolean>;
}

export const MenuListItem = ({
  product,
  setActiveItem,
  setOpenCategoryChange,
  setShowChangeStatusModal,
  setOpenDeleteModal,
}: MenuListItemProps) => {
  const account = useAppSelector((state) => state.account.account);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const defaultPhoto = useAppSelector((state) => state.account.account?.default_photo);
  const history = useHistory();
  const locale = useAppSelector((state) => state.locale.lang);

  const checkProductLabels = () => {
    const labels = product.labels;
    if (labels) {
      return Object.keys(labels).filter((label) => labels[label]).length !== 0;
    } else {
      return false;
    }
  };

  const returnItemPhoto = (product: Items) => {
    if (product?.photo?.medium) {
      return (
        <PreloadComponent
          baseSrc={product?.photo?.medium}
          minimizedSrc={product?.photo?.preloader}
          className="h-full w-full object-cover object-center md:group-hover/item:opacity-75"
        />
      );
    } else if (defaultPhoto?.links?.medium) {
      return (
        <PreloadComponent
          baseSrc={defaultPhoto?.links?.medium}
          minimizedSrc={defaultPhoto?.links?.preloader}
          className="h-full w-full object-cover object-center md:group-hover/item:opacity-75"
        />
      );
    } else {
      return <NoPhoto className="p-9 md:group-hover/item:opacity-75" />;
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={(e) => {
        const target = e.target as HTMLInputElement;
        if (!target.closest('.item-action-menu')) {
          history.push(`/item/${product.id}`);
        }
      }}
      className={classNames(
        'relative group/item cursor-pointer rounded-lg flex flex-col',
      )}
    >
      <div className="relative">
        <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-w-7 xl:aspect-h-6">
          {returnItemPhoto(product)}
        </div>
        <div className="flex flex-wrap items-center absolute bottom-[5px] rtl:right-[5px] ltr:left-[5px] justify-end">
          {product?.popular && (
            <span className="ltr:mr-[5px] rtl:ml-[5px] mt-[5px] inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
              <FormattedMessage id="popular" />
            </span>
          )}
          {product?.chef_advice && (
            <span className="ltr:mr-[5px] rtl:ml-[5px] mt-[5px] inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
              <FormattedMessage id="chefAdvice" />
            </span>
          )}
          {product?.new && (
            <span className="ltr:mr-[5px] rtl:ml-[5px] mt-[5px] inline-flex items-center rounded-full bg-orange-100 px-2.5 py-0.5 text-xs font-medium text-orange-800">
              <FormattedMessage id="new" />
            </span>
          )}
        </div>
      </div>

      <CategoryDropdownMenu
        setActiveItem={setActiveItem}
        product={product}
        setOpenCategoryChange={setOpenCategoryChange}
        setShowChangeStatusModal={setShowChangeStatusModal}
        setOpenDeleteModal={setOpenDeleteModal}
      />
      <div className={classNames('mt-4 flex w-full items-center justify-between')}>
        <h3 className="text-sm text-gray-700 truncate ltr:mr-3 rtl:ml-3">
          {product?.name?.translations &&
            product?.name?.translations[oldLanguages[locale]]}
        </h3>
        {product?.status === 1 && (
          <span className="inline-flex whitespace-nowrap items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
            <FormattedMessage id="active" />
          </span>
        )}
        {product?.status === 2 && (
          <span className="inline-flex whitespace-nowrap items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
            <FormattedMessage id="soldout" />
          </span>
        )}
        {product?.status === 3 && (
          <span className="inline-flex whitespace-nowrap items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
            <FormattedMessage id="archived" />
          </span>
        )}
      </div>

      <p className={classNames('mt-1 text-base font-medium text-gray-900 truncate')}>
        {returnNumberPrice(product.price) === -1 ? (
          <FormattedMessage id="free" />
        ) : (
          <FormattedNumber
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency={account?.currency}
            value={returnNumberPrice(product.price)}
            maximumFractionDigits={intCurrency ? 0 : 2}
          />
        )}
      </p>

      {checkProductLabels() && (
        <div className={classNames('mt-auto justify-self-end mb-2')}>
          <div className="flex items-center justify-between">
            <div className="text-base text-gray-700 mr-2">
              <FormattedMessage id="labels" />:
            </div>
            <div className="flex items-center gap-2 overflow-auto scrollbar">
              <LabelsPreview dish={product} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
