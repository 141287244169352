import { MENU_PANEL_URL } from 'configure';
import { useAppSelector } from 'hooks/store';
import { CSSProperties, ReactEventHandler } from 'react';

interface IframeSectionProps {
  className: string;
  onLoad: ReactEventHandler<HTMLIFrameElement>;
  style?: CSSProperties | Record<string, never>;
}

export const IframeSection = ({ className, onLoad, style = {} }: IframeSectionProps) => {
  const QRCode = useAppSelector((state) => state.qr.data);

  return (
    <iframe
      onLoad={onLoad}
      id="menu-preview-frame"
      title="Digital Menu Preview"
      style={{
        height: '100%',
        width: '100%',
        minWidth: '330px',
        overflowX: 'hidden',
        ...style,
      }}
      className={className}
      src={MENU_PANEL_URL + '/' + QRCode?.link + '/?cookies=false'}
    ></iframe>
  );
};
