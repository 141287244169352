import { XMarkIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { CropDropdown } from 'components/Common/CropDropdown';
import { useAppSelector } from 'hooks/store';
import { FormattedMessage } from 'react-intl';

interface ChangePhotoItemCropProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  globalCategoryId: string;
}

export const ChangePhotoItemCrop = ({
  open,
  setOpen,
  globalCategoryId,
}: ChangePhotoItemCropProps) => {
  const loadingImages = useAppSelector((state) => state.image.loading);

  return (
    <AppModal open={open} size="sm:max-w-3xl">
      <div className="flex flex-col">
        <div className="flex items-center justify-between w-full">
          <h1>
            <FormattedMessage id="photo" />
          </h1>
          <XMarkIcon
            onClick={() => {
              if (!loadingImages) {
                setOpen(false);
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <CropDropdown globalCategoryId={globalCategoryId} setOpen={setOpen} />
      </div>
    </AppModal>
  );
};
