import { API } from 'api';
import { defaultToastProps, phoneCodesObj } from 'appConstants';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { setAccountInfo } from 'store/account/actions';
import { RESET_ACCOUNT } from 'store/account/actionsTypes';
import { ChangeMainAttrBody } from 'store/account/types';
import { AppThunk } from 'store/globalTypes';
import { RESET_MENU } from 'store/menu/actionsTypes';
import { RESET_QR_CODE } from 'store/qrcode/actionsTypes';
import { RESET_THEME } from 'store/themes/actionsTypes';

import {
  RESET_AUTH,
  SET_ERROR,
  SET_REGISTER_USER,
  START_LOADING,
  STOP_LOADING,
  SUCCESS_LOGIN,
} from './actionsTypes';
import {
  AuthAction,
  ChangeAttrAwsProps,
  ChangeMainAttrProps,
  CreateUserBody,
  LoginGoogleProps,
  RegisterGoogleUserProps,
  ResendCodeAttrProps,
  ResentdRecoveryPasswordCodeProps,
  ResetPasswordProps,
} from './types';

export const login: AppThunk =
  (email: string, password: string, history: any) =>
  async (dispatch: Dispatch<AuthAction>) => {
    dispatch({ type: START_LOADING });
    try {
      const res = await Auth.signIn(email, password);

      if (res?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        localStorage.removeItem('locale');
        localStorage.removeItem('user');

        dispatch({ type: STOP_LOADING });
        history.push('/register');
      } else {
        localStorage.removeItem('locale');

        dispatch({ type: SUCCESS_LOGIN, payload: true });
        history.push('/menu');
      }
    } catch (error) {
      if (typeof error === 'string') {
        dispatch({ type: SET_ERROR, payload: error });
      } else if (error instanceof Error) {
        dispatch({ type: SET_ERROR, payload: error.message });
      }
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const logout: AppThunk =
  (history: any, global = false) =>
  async (dispatch) => {
    dispatch({ type: START_LOADING });
    try {
      history.push('/login');
      await Auth.signOut({
        global,
      });

      localStorage.removeItem('user');
      localStorage.removeItem('activeTabAccount');
      localStorage.removeItem('locale');
      localStorage.removeItem('desktopSidebarOpen');

      dispatch({ type: SUCCESS_LOGIN, payload: false });
      dispatch({ type: SUCCESS_LOGIN, payload: false });
      dispatch({ type: SET_REGISTER_USER, payload: null });
      dispatch({ type: RESET_MENU });
      dispatch({ type: RESET_ACCOUNT });
      dispatch({ type: RESET_AUTH });
      dispatch({ type: RESET_QR_CODE });
      dispatch({ type: RESET_THEME });
    } catch (error) {
      if (typeof error === 'string') {
        dispatch({ type: SET_ERROR, payload: error });
      } else if (error instanceof Error) {
        dispatch({ type: SET_ERROR, payload: error.message });
      }
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

// loginGoogle not in use yet
export const loginGoogle: AppThunk =
  ({
    setNeedCreateUser,
    setFirstName,
    setLastName,
    setPhoneCode,
    setPhoneNumber,
  }: LoginGoogleProps) =>
  async (dispatch: Dispatch<AuthAction>) => {
    dispatch({ type: START_LOADING });
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userAtt = await Auth.userAttributes(user);
      const allAttNeeded = [
        'email',
        'family_name',
        'given_name',
        'locale',
        'phone_number',
        'zoneinfo',
        'sub',
      ];

      userAtt.forEach((att) => {
        if (allAttNeeded.includes(att.Name)) {
          allAttNeeded.splice(
            allAttNeeded.findIndex((item) => item === att.Name),
            1,
          );
          if (att.Name === 'family_name') {
            setLastName(att.Value);
          }
          if (att.Name === 'given_name') {
            setFirstName(att.Value);
          }
          if (att.Name === 'phone_number') {
            const phone = att.Value;
            let countryCode;
            let i = 1;
            while (!countryCode && i < phone.length) {
              countryCode =
                phoneCodesObj[phone.slice(0, i) as keyof typeof phoneCodesObj];
              i++;
            }
            if (countryCode) {
              setPhoneCode(countryCode);
              setPhoneNumber(phone.replace(countryCode.code, ''));
            }
          }
        }
      });

      setNeedCreateUser(true);
    } catch (error) {
      if (typeof error === 'string') {
        dispatch({ type: SET_ERROR, payload: error });
      } else if (error instanceof Error) {
        dispatch({ type: SET_ERROR, payload: error.message });
      }
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

// registerGoogleUser not in use yet
export const registerGoogleUser: AppThunk =
  ({
    firstName,
    lastName,
    phone,
    locale,
    zoneinfo,
    setRegisterStep,
    userDB,
  }: RegisterGoogleUserProps) =>
  async (dispatch) => {
    dispatch({ type: START_LOADING });
    try {
      const user = await Auth.currentAuthenticatedUser();
      dispatch(
        changeAttrAws({
          firstName,
          lastName,
          phone,
          locale,
          zoneinfo,
        }),
      );

      const newDataBaseUser: CreateUserBody = {
        name: userDB.restaurantName,
        email: user.attributes.email,
        currency: userDB.currency,
        preview: {},
        locale: userDB.mainLanguage,
        languages: userDB.transactionLanguages.map((item: any) => item.value),
        link: userDB.link,
      };

      const { data } = await API.v1.account.createAccount(newDataBaseUser);
      dispatch({
        type: SET_REGISTER_USER,
        payload: {
          ...userDB,
          isAuth: true,
          id: data.id,
        },
      });
      setRegisterStep(2);
    } catch (error) {
      if (typeof error === 'string') {
        dispatch({ type: SET_ERROR, payload: error });
      } else if (error instanceof Error) {
        dispatch({ type: SET_ERROR, payload: error.message });
      }
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const changeAttrAws: AppThunk =
  ({ firstName, lastName, phone, locale, zoneinfo }: ChangeAttrAwsProps) =>
  async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        family_name: lastName,
        given_name: firstName,
        phone_number: phone,
        locale: locale,
        zoneinfo: zoneinfo,
        email_verified: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

export const newRegister: AppThunk =
  (user: any, step: number) => async (dispatch: Dispatch<AuthAction>) => {
    dispatch({ type: START_LOADING });
    try {
      if (step === 1) {
        const awsUser = await Auth.signIn(user?.email, user?.oldPassword);
        if (awsUser?.challengeName !== 'NEW_PASSWORD_REQUIRED') {
          await Auth.signOut();
          throw new Error('User already exists');
        }
        user.setRegisterStep(2);
        localStorage.setItem('user', JSON.stringify({ ...user, activeStep: 2 }));
        dispatch({
          type: SET_REGISTER_USER,
          payload: { ...user, activeStep: 2 },
        });
      } else if (step === 2) {
        const req = await Auth.signIn(user?.email, user?.oldPassword);
        await Auth.completeNewPassword(req, user?.oldPassword, {
          family_name: user.lastName,
          given_name: user.firstName,
          phone_number: user.phoneCode.code + user.phoneNumber.match(/\d+/g).join(''),
          locale: user.mainLanguage,
          zoneinfo: user.country,
        });

        localStorage.setItem(
          'user',
          JSON.stringify({ ...user, activeStep: 2, isAuth: true }),
        );

        const newDataBaseUser: CreateUserBody = {
          name: user.restaurantName,
          email: user.email,
          currency: user.currency,
          preview: {},
          locale: user.mainLanguage,
          languages: user.transactionLanguages.map((item: any) => item.value),
          link: user.link,
        };

        const { data } = await API.v1.account.createAccount(newDataBaseUser);
        dispatch({
          type: SET_REGISTER_USER,
          payload: {
            ...user,
            isAuth: true,
            activeStep: 3,
            id: data.id,
          },
        });
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...user,
            isAuth: true,
            activeStep: 3,
            id: data.id,
          }),
        );
        user.setRegisterStep(3);
      }
    } catch (error) {
      if (typeof error === 'string') {
        dispatch({ type: SET_ERROR, payload: error });
      } else if (error instanceof Error) {
        dispatch({ type: SET_ERROR, payload: error.message });
      }
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const setExistingUser = (user: any) => (dispatch: Dispatch<AuthAction>) => {
  dispatch({ type: SET_REGISTER_USER, payload: user });
};

export const resendCodeForMainAttr: AppThunk =
  ({ step, mode, setLoading, setStartTimer, email, phone }: ResendCodeAttrProps) =>
  async () => {
    setLoading(true);
    try {
      if (+step === 2) {
        await Auth.verifyCurrentUserAttribute(mode);
        setStartTimer(true);
        toast('Code has been sent successfully', {
          ...defaultToastProps,
          type: 'success',
        });
      } else if (+step === 4) {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
          [mode]: mode === 'email' ? email : phone,
        });
        setStartTimer(true);
        toast('Code has been sent successfully', {
          ...defaultToastProps,
          type: 'success',
        });
      }
    } catch (error) {
      if (typeof error === 'string') {
        toast(error, {
          ...defaultToastProps,
          type: 'error',
        });
      } else if (error instanceof Error) {
        toast(error.message, {
          ...defaultToastProps,
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };

export const changeMainAttr: AppThunk =
  ({
    step,
    setStep,
    mode,
    code = '',
    setCode,
    setLoading,
    setCodeSended,
    setStartTimer,
    email,
    phone,
    setOpen,
    resetFields,
  }: ChangeMainAttrProps) =>
  async (dispatch) => {
    setLoading(true);
    try {
      if (+step === 1) {
        await Auth.verifyCurrentUserAttribute(mode);
        setStartTimer(true);
        toast('Code has been sent successfully', {
          ...defaultToastProps,
          type: 'success',
        });
        setCodeSended(true);
        setStep(2);
      } else if (+step === 2) {
        await Auth.verifyCurrentUserAttributeSubmit(mode, code);
        setCodeSended(false);
        setCode('');
        setStep(3);
      } else if (+step === 3) {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
          [mode]: mode === 'email' ? email : phone,
        });
        setStartTimer(true);
        toast('Code has been sent successfully', {
          ...defaultToastProps,
          type: 'success',
        });
        setCodeSended(true);
        setStep(4);
      } else if (+step === 4) {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.verifyUserAttributeSubmit(user, mode, code);

        if (mode === 'email') {
          const body: ChangeMainAttrBody = {
            [mode]: mode === 'email' ? email : phone,
          };

          await API.v1.account.changeAccountAttribute(body);

          setOpen(false);
          setTimeout(() => {
            resetFields();
          }, 300);
          dispatch(setAccountInfo());
        } else {
          setOpen(false);
          setTimeout(() => {
            resetFields();
          }, 300);
          dispatch(setAccountInfo());
        }
      }
    } catch (error) {
      if (typeof error === 'string') {
        toast(error, {
          ...defaultToastProps,
          type: 'error',
        });
      } else if (error instanceof Error) {
        toast(error.message, {
          ...defaultToastProps,
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };

export const resetPassword: AppThunk =
  ({
    email,
    setStartTimer,
    setCodeSended,
    codeSended,
    password,
    code,
    history,
  }: ResetPasswordProps) =>
  async (dispatch) => {
    try {
      if (!codeSended) {
        dispatch({ type: START_LOADING });
        await Auth.forgotPassword(email);
        toast('Code has been sent successfully', {
          ...defaultToastProps,
          type: 'success',
        });
        setStartTimer(true);
        setCodeSended(true);
      } else {
        dispatch({ type: START_LOADING });
        await Auth.forgotPasswordSubmit(email, code, password);
        toast('Password changed successfully', {
          ...defaultToastProps,
          type: 'success',
        });
        setTimeout(() => {
          dispatch(login(email, password, history));
        }, 500);
      }
    } catch (error) {
      if (typeof error === 'string') {
        dispatch({ type: SET_ERROR, payload: error });
      } else if (error instanceof Error) {
        dispatch({ type: SET_ERROR, payload: error.message });
      }
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const resentdRecoveryPasswordCode =
  ({ email, setStartTimer }: ResentdRecoveryPasswordCodeProps) =>
  async (dispatch: Dispatch<AuthAction>) => {
    try {
      dispatch({ type: START_LOADING });
      await Auth.forgotPassword(email);
      toast('Code has been sent successfully.', {
        ...defaultToastProps,
        type: 'success',
      });
      setStartTimer(true);
    } catch (error) {
      if (typeof error === 'string') {
        dispatch({ type: SET_ERROR, payload: error });
      } else if (error instanceof Error) {
        dispatch({ type: SET_ERROR, payload: error.message });
      }
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };
