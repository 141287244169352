import { ChangeEvent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { classNames } from '../../helpers/validation';

interface AppInputProps {
  label: string;
  id: string;
  name: string;
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  icon?: null | ReactNode;
  withLabel?: boolean;
  className?: string;
  testId?: string;
}

export const AppInput = ({
  label,
  id,
  name,
  type,
  placeholder,
  value,
  onChange,
  icon = null,
  withLabel = true,
  className = '',
  testId = '',
}: AppInputProps) => {
  return (
    <div className={className}>
      <label
        htmlFor={id}
        className={classNames(
          withLabel ? 'block text-sm font-medium text-gray-700' : 'sr-only',
        )}
      >
        <FormattedMessage id={label} />
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        {!!icon && (
          <div className="pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3">
            {icon}
          </div>
        )}
        <input
          type={type}
          name={name}
          value={value}
          data-testId={testId}
          onChange={onChange}
          id={id}
          className={classNames(
            !!icon && 'ltr:pl-10 rtl:pr-10',
            'block w-full rounded-md border-gray-300 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm',
          )}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
