import { roundedOption } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { FormattedMessage } from 'react-intl';

export interface RoundedOption {
  id: number;
  label: string;
  value: 'standard' | 'up' | 'down';
}

interface RoundedSelectProps {
  selected: RoundedOption;
  setSelected: SetStateType<RoundedOption>;
}

export const RoundedSelect = ({ selected, setSelected }: RoundedSelectProps) => {
  return (
    <div className="flex items-center space-x-3">
      {roundedOption.map((option) => {
        return (
          <button
            key={'rounding-option-' + option.id}
            className={classNames(
              'h-10 flex items-center space-x-2 px-3 rounded-lg transition-all cursor-pointer relative text-sm text-center border border-primary',
              option.id === selected.id
                ? 'bg-primary text-white md:hover:bg-white md:hover:text-primary'
                : 'text-primary bg-white md:hover:bg-primary md:hover:text-white',
            )}
            onClick={() => setSelected(option)}
          >
            <FormattedMessage id={option.label} />
          </button>
        );
      })}
    </div>
  );
};
