import { Languages, SetStateType } from 'commonTypes';
import { createNameWithoutTransalte } from 'helpers/menu';
import { sortedTranslateObj } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { FormattedMessage } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';

interface AddCategoryTextFieldProps {
  activeStep: number;
  name: string;
  loading: boolean;
  setName: SetStateType<string>;
  setTranslate: SetStateType<object | Record<Languages, string> | Record<string, never>>;
  translate: object | Record<Languages, string> | Record<string, never>;
  mode?: 'name' | 'description';
  withoutTranslations: boolean;
  setWithoutTranslations: SetStateType<boolean>;
}

export const AddCategoryTextField = ({
  activeStep,
  name,
  loading,
  setName,
  setTranslate,
  translate,
  mode = 'name',
  withoutTranslations,
  setWithoutTranslations,
}: AddCategoryTextFieldProps) => {
  const account = useAppSelector((state) => state.account.account);

  return (
    <>
      {(activeStep === 1 || activeStep === 3) && (
        <>
          <div className="mt-4 flex w-full justify-between items-end">
            <div className="w-full">
              <div className="mt-0">
                <TextareaAutosize
                  minRows={2}
                  name={mode}
                  id={'category-' + mode}
                  value={name}
                  data-testId="cypress-add-category-name-input"
                  disabled={loading}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (withoutTranslations) {
                      setTranslate(createNameWithoutTransalte(e.target.value || ''));
                    } else {
                      setTranslate({});
                    }
                  }}
                  className="w-full scrollbar rounded-md border-gray-300 sm:text-sm"
                  placeholder={'Write category ' + mode + '...'}
                />
              </div>
            </div>
          </div>
          <div className="relative flex items-start justify-end mb-4">
            <div className="flex h-6 items-center">
              <input
                id="without-translate"
                aria-describedby="without-translate"
                name="without-translate"
                type="checkbox"
                data-testId="cypress-add-category-translate"
                checked={withoutTranslations}
                onChange={(e) => {
                  if (setWithoutTranslations) {
                    setWithoutTranslations(e.target.checked);
                  }
                  if (e.target.checked) {
                    if (name.trim()) {
                      setTranslate(createNameWithoutTransalte(name));
                    }
                  } else {
                    setTranslate({});
                  }
                }}
                disabled={loading}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label
                htmlFor="without-translate"
                className="font-medium text-gray-900 select-none"
              >
                <FormattedMessage id="withoutTranslate" />
              </label>
            </div>
          </div>
        </>
      )}
      {(activeStep === 2 || activeStep === 4) && (
        <div className="mt-4 flex w-full flex-col space-y-4 max-h-96 overflow-auto scrollbar">
          {Object.keys(translate).length > 0 &&
            !loading &&
            Object.keys(
              sortedTranslateObj({
                // @ts-ignore
                translate: translate,
                langs: account?.languages || [],
              }),
            ).map((item, index) => {
              return (
                <div
                  key={'edit-translate-' + mode + '-' + index}
                  className="grid grid-cols-[1fr_4fr] items-center space-x-3 rtl:space-x-reverse w-full ltr:pr-2 rtl:pl-2"
                >
                  <h2 className="rtl:text-right">{item.toUpperCase()}</h2>
                  <div>
                    <TextareaAutosize
                      minRows={2}
                      className="w-full scrollbar rounded-md border-gray-300 sm:text-sm"
                      placeholder={'Category ' + mode + '...'}
                      disabled={loading}
                      // @ts-ignore
                      value={translate[item]}
                      onChange={(e) => {
                        setTranslate((prev) => {
                          return {
                            ...prev,
                            [item]: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};
