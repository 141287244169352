import {
  SET_GPT_ERROR,
  START_GPT_REQUEST,
  STOP_GPT_REQUEST,
  UPDATE_CHAT_GPT_CUSTOM_PROMPT,
  UPDATE_CHAT_GPT_PROMPT,
  UPDATE_CHAT_GPT_RESULT,
} from './actionTypes';
import { GPTAction, GPTStore } from './types';

const initState: GPTStore = {
  chatGPT: {
    listPrompts: [
      {
        id: 1,
        defaultId: 1,
        text: 'Describe the nutrients and calories of the dish',
      },
      {
        id: 2,
        defaultId: 2,
        text: 'What is the cultural or regional inspiration behind the dish?',
      },
      {
        id: 3,
        defaultId: 3,
        text: 'What are the main ingredients and flavors of the dish?',
      },
      {
        id: 4,
        defaultId: 4,
        text: 'What are the contrasting flavors or textures in the dish?',
      },
    ],
    customPrompt: '',
    gptResult: '',
    loading: false,
    error: null,
  },
};

export const GPTReducer = (state: GPTStore = initState, action: GPTAction) => {
  switch (action.type) {
    case UPDATE_CHAT_GPT_PROMPT: {
      return {
        ...state,
        chatGPT: {
          ...state.chatGPT,
          listPrompts: action.payload,
        },
      };
    }
    case UPDATE_CHAT_GPT_CUSTOM_PROMPT: {
      return {
        ...state,
        chatGPT: {
          ...state.chatGPT,
          customPrompt: action.payload,
        },
      };
    }
    case UPDATE_CHAT_GPT_RESULT: {
      return {
        ...state,
        chatGPT: {
          ...state.chatGPT,
          gptResult: action.payload,
        },
      };
    }
    case START_GPT_REQUEST: {
      return {
        ...state,
        chatGPT: {
          ...state.chatGPT,
          error: null,
          loading: true,
        },
      };
    }
    case STOP_GPT_REQUEST: {
      return {
        ...state,
        chatGPT: {
          ...state.chatGPT,
          loading: false,
        },
      };
    }
    case SET_GPT_ERROR: {
      return {
        ...state,
        chatGPT: {
          ...state.chatGPT,
          error: action.payload,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};
