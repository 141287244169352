import { ReactComponent as Logo } from 'assets/logos/digital-menu.logo-03.svg';
import { Link } from 'react-router-dom';

interface LoginHeaderFormProps {
  label: string;
  linkLabel: string;
  to: string;
  onClick: () => void;
}

export const LoginHeaderForm = ({
  label,
  linkLabel,
  to,
  onClick,
}: LoginHeaderFormProps) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <Logo className="mx-auto h-12 w-auto" />
      <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
        {label}
      </h2>
      <p className="mt-2 text-center text-sm text-gray-600">
        Or {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <Link
          to={to}
          className="font-medium text-indigo-600 md:hover:text-indigo-500"
          onClick={onClick}
        >
          {linkLabel}
        </Link>
      </p>
    </div>
  );
};
