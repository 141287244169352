import { Menu, Transition } from '@headlessui/react';
import {
  ArchiveBoxIcon,
  EllipsisVerticalIcon,
  HeartIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { oldLanguages } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MenuItemState } from 'store/menu/types';

import {
  AddGlobalMenuModal,
  ChangeMenuStatusModal,
  DeleteGlobalMenuModal,
} from '../Modals';

interface ActiveGlobalMenuHeaderProps {
  activeGlobalMenu: string;
  setActiveGlobalMenu: SetStateType<string>;
}

export const ActiveGlobalMenuHeader = ({
  activeGlobalMenu,
  setActiveGlobalMenu,
}: ActiveGlobalMenuHeaderProps) => {
  const [openDeleteGlobalMenu, setOpenDeleteGlobalMenu] = useState(false);
  const [openEditGlobalMenu, setOpenEditGlobalMenu] = useState(false);
  const [openStatusGlobalMenu, setOpenStatusGlobalMenu] = useState(false);
  const menus = useAppSelector((state) => state.menu.menu);
  const [activeGlobalMenuObj, setActiveGlobalMenuObj] = useState<MenuItemState>();
  const locale = useAppSelector((state) => state.locale.lang);

  useEffect(() => {
    if (menus?.length > 0) {
      setActiveGlobalMenuObj(() => {
        return menus?.find((menu) => menu?.id === activeGlobalMenu);
      });
    }
  }, [activeGlobalMenu, menus]);

  return (
    <div className="bg-white rounded-lg shadow p-4 mb-4 flex w-full items-center justify-between">
      <DeleteGlobalMenuModal
        open={openDeleteGlobalMenu}
        setOpen={setOpenDeleteGlobalMenu}
        globalMenuId={activeGlobalMenu}
        setActiveGlobalMenu={setActiveGlobalMenu}
      />
      <AddGlobalMenuModal
        open={openEditGlobalMenu}
        setOpen={setOpenEditGlobalMenu}
        globalMenu={activeGlobalMenuObj}
        edit
      />
      <ChangeMenuStatusModal
        open={openStatusGlobalMenu}
        setOpen={setOpenStatusGlobalMenu}
        globalMenuId={activeGlobalMenu}
        status={activeGlobalMenuObj?.status || -1}
      />
      <div className="text-base font-medium text-gray-900">
        {activeGlobalMenuObj?.name?.translations &&
        activeGlobalMenuObj?.name?.translations[oldLanguages[locale]]
          ? activeGlobalMenuObj?.name?.translations[oldLanguages[locale]]
          : null}
      </div>

      {activeGlobalMenuObj?.status !== 3 && (
        <Menu
          as="div"
          className="relative ltr:ml-3 rtl:mr-3 ltr:text-left rtl:text-right inline-block"
        >
          <div>
            <Menu.Button className="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 md:hover:text-gray-600 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500">
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute ltr:right-0 rtl:left-0 z-10 mt-2 w-56 rtl:origin-top-left ltr:origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      role="button"
                      tabIndex={0}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm cursor-pointer',
                      )}
                      onClick={() => {
                        setOpenEditGlobalMenu(true);
                      }}
                    >
                      <PencilSquareIcon
                        className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <span>
                        <FormattedMessage id="edit" />
                      </span>
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setOpenStatusGlobalMenu(true);
                      }}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm cursor-pointer',
                      )}
                    >
                      {activeGlobalMenuObj?.status === 1 ? (
                        <>
                          <ArchiveBoxIcon
                            className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          <FormattedMessage id="archived" />
                        </>
                      ) : (
                        <>
                          <HeartIcon
                            className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          <FormattedMessage id="active" />
                        </>
                      )}
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      role="button"
                      tabIndex={0}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm cursor-pointer',
                      )}
                      onClick={() => {
                        setOpenDeleteGlobalMenu(true);
                      }}
                    >
                      <TrashIcon
                        className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <span>
                        <FormattedMessage id="delete" />
                      </span>
                    </div>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
};
