import { ReactComponent as NoPhoto } from 'assets/static/no_photo.svg';
import { PreloadComponent } from 'components/ImagePreload/PreloadComponent/PreloadComponent';
import { useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ChangePhotoProfileCrop } from '../ChangeModals';

export const ImageRestaurant = () => {
  const account = useAppSelector((state) => state.account.account);
  const [openImageRestaurant, setOpenImageRestaurant] = useState(false);

  return (
    <div className="w-full ipad:max-w-[520px] ipad:m-5/12 flex flex-col space-y-4 mb-3">
      <div className="relative">
        {account?.preview?.links?.full ? (
          <PreloadComponent
            baseSrc={account?.preview.links.full}
            minimizedSrc={account?.preview.links.preloader}
            className="w-full h-[260px] md:h-[400px] object-cover object-center rounded-lg"
          />
        ) : (
          <NoPhoto className="w-full h-[260px] md:h-[400px] object-cover object-center rounded-lg p-9" />
        )}
        {account?.logo?.links?.preloader && (
          <PreloadComponent
            baseSrc={account?.logo?.links.full}
            minimizedSrc={account?.logo?.links.preloader}
            className="h-[60px] md:h-[60px] object-cover object-center absolute top-4 left-4"
            style={{
              backgroundColor: 'transparent',
            }}
          />
        )}
      </div>

      <button
        onClick={() => setOpenImageRestaurant(true)}
        className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-6 py-3 text-base font-medium text-indigo-700 md:hover:bg-indigo-200 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2 text-center justify-center"
      >
        <FormattedMessage id="updatePhoto" />
      </button>
      <ChangePhotoProfileCrop
        open={openImageRestaurant}
        setOpen={setOpenImageRestaurant}
      />
    </div>
  );
};
