import { SET_IMAGES_ITEM, START_FETCH_IMAGES, STOP_FETCH_IMAGES } from './actionTypes';
import { FetchImagesAction, ItemImageStore } from './types';

const initialState: ItemImageStore = {
  loading: false,
  itemImages: [],
};

export const imageReducer = (
  state: ItemImageStore = initialState,
  action: FetchImagesAction,
) => {
  switch (action.type) {
    case START_FETCH_IMAGES:
      return {
        ...state,
        loading: true,
      };
    case STOP_FETCH_IMAGES:
      return {
        ...state,
        loading: false,
      };
    case SET_IMAGES_ITEM:
      return {
        ...state,
        itemImages: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
