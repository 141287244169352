import { AppModal } from '@Common/AppModal';
import { Spinner } from '@Common/Spinner';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { FormattedMessage } from 'react-intl';
import { updateActiveTheme } from 'store/themes/actions';
import { ApiThemeState } from 'store/themes/types';

interface ConfirmChangeActiveThemeModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  nextActiveTheme: string;
  setActiveTheme: SetStateType<ApiThemeState | undefined>;
  setActiveChooseTheme?: SetStateType<ApiThemeState | undefined>;
  activeChooseTheme?: ApiThemeState | undefined;
}

export const ConfirmChangeActiveThemeModal = ({
  open,
  setOpen,
  nextActiveTheme,
  setActiveTheme,
  setActiveChooseTheme,
  activeChooseTheme,
}: ConfirmChangeActiveThemeModalProps) => {
  const dispatch = useAppDispatch();
  const themeList = useAppSelector((state) => state.themes.themeList);
  const themeSubLoading = useAppSelector((state) => state.themes.subLoading);

  const close = () => {
    if (!themeSubLoading) {
      setOpen(false);
    }
  };

  return (
    <AppModal open={open}>
      <div className="flex w-full items-center justify-between mb-3">
        <h1>
          <FormattedMessage id="changeCurrentTheme" />
        </h1>
        <XMarkIcon
          onClick={close}
          className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
        />
      </div>

      <div className="text-base text-gray-500">
        <FormattedMessage
          id="changeThemeMessage"
          values={{
            nameOld: (
              <strong>
                {themeList.find((theme) => theme.status === 1)?.name.toLocaleUpperCase()}
              </strong>
            ),
            nameNew: (
              <strong>
                {themeList
                  .find((theme) => theme.id === nextActiveTheme)
                  ?.name.toLocaleUpperCase()}
              </strong>
            ),
          }}
        />
      </div>

      <div className="flex w-full justify-end items-center mt-4">
        <button
          type="button"
          onClick={close}
          disabled={themeSubLoading}
          className="rounded-md mr-2 bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <FormattedMessage id="cancel" />
        </button>

        <button
          type="button"
          disabled={themeSubLoading}
          onClick={() => {
            dispatch(
              updateActiveTheme(
                nextActiveTheme,
                setOpen,
                setActiveTheme,
                setActiveChooseTheme,
                activeChooseTheme,
              ),
            );
          }}
          data-testId="cypress-confirm-active-theme"
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {themeSubLoading ? (
            <Spinner color="bg-white" />
          ) : (
            <FormattedMessage id="confirm" />
          )}
        </button>
      </div>
    </AppModal>
  );
};
