import { AppModal } from '@Common/AppModal';
import { CropDropdown } from '@Common/CropDropdown';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { RESET_NOTIFICATION } from 'store/account/actionsTypes';

interface ChangeDefaultPhotoModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
}

export const ChangeDefaultPhotoModal = ({
  open,
  setOpen,
}: ChangeDefaultPhotoModalProps) => {
  const dispatch = useAppDispatch();
  const loadingImages = useAppSelector((state) => state.image.loading);

  useEffect(() => {
    dispatch({
      type: RESET_NOTIFICATION,
    });
  }, []);

  return (
    <AppModal open={open} size="sm:max-w-3xl">
      <div className="flex flex-col">
        <div className="flex items-center justify-between w-full">
          <h1>
            <FormattedMessage id="defaultItemPhoto" />
          </h1>
          <XMarkIcon
            onClick={() => {
              if (!loadingImages) {
                setOpen(false);
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <CropDropdown setOpen={setOpen} profileMode={true} defaultPhotoMode />
      </div>
    </AppModal>
  );
};
