export const Spinner = ({ color = 'bg-white', width = 'w-3', heigth = 'h-3' }) => {
  return (
    <div className={`flex items-center justify-center py-1`}>
      <div className="flex space-x-2 rtl:space-x-reverse animate-pulse">
        <div className={`${width} ${heigth} ${color} rounded-full`}></div>
        <div className={`${width} ${heigth} ${color} rounded-full`}></div>
        <div className={`${width} ${heigth} ${color} rounded-full`}></div>
      </div>
    </div>
  );
};
