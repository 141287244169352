import {
  FETCH_CATEGORY,
  START_CATEGORY_SUB_LOADING,
  STOP_CATEGORY_SUB_LOADING,
  STOP_FETCH_CATEGORY,
  SUCCESS_FETCH_CATEGORY,
} from './actionTypes';
import { CategoryAction, CategoryStoreState } from './types';

const initialState: CategoryStoreState = {
  loading: false,
  subLoading: false,
  activeCategory: {
    categoryList: {},
    categoryListObj: {},
  },
  beutifyMenu: null,
};

export const categoryReducer = (
  state: CategoryStoreState = initialState,
  action: CategoryAction,
) => {
  switch (action.type) {
    case FETCH_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case STOP_FETCH_CATEGORY:
      return {
        ...state,
        loading: false,
      };
    case START_CATEGORY_SUB_LOADING:
      return {
        ...state,
        subLoading: true,
      };
    case STOP_CATEGORY_SUB_LOADING:
      return {
        ...state,
        subLoading: false,
      };
    case SUCCESS_FETCH_CATEGORY:
      return {
        ...state,
        loading: false,
        subLoading: false,
        activeCategory: {
          categoryList: {
            ...state.activeCategory.categoryList,
            ...action.payload.categoryList,
          },
          categoryListObj: {
            ...state.activeCategory.categoryListObj,
            ...action.payload.categoryListObj,
          },
        },
        beutifyMenu: {
          ...state.beutifyMenu,
          ...action.payload.beutifyMenu,
        },
      };
    default:
      return state;
  }
};
