//eslint-ignore

export const Sulfites = ({ width = 32, height = 32, className = '' }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 230 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_417_386)">
        <path
          d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
          fill="#DD455B"
        />
        <path
          d="M230 115C230 114.851 229.995 114.703 229.995 114.554L148.671 33.2307L81.7791 42.7872C81.9308 42.7872 91.3351 52.3099 91.3351 52.3432V90.8851L55.2485 167.026C55.2485 167.026 59.4268 190.754 59.2769 190.603C59.3395 190.668 76.9833 208.313 97.3169 228.647C103.081 229.536 108.987 230 115 230C178.513 230 230 178.513 230 115Z"
          fill="#B2004F"
        />
        <path
          d="M175.202 167.026L139.115 90.8851L129.559 81.3291H115.225H100.891L91.3352 90.8851L55.2486 167.026C53.8488 169.821 53.1116 172.952 53.1116 176.078C53.1116 187.239 62.1914 196.319 73.3525 196.319H115.225H157.098C168.259 196.319 177.339 187.239 177.339 176.078C177.339 172.951 176.601 169.821 175.202 167.026Z"
          fill="#BBEB7A"
        />
        <path
          d="M177.339 176.078C177.339 172.951 176.601 169.821 175.202 167.026L139.115 90.8851L129.559 81.3291H115.225V196.319H157.098C168.259 196.319 177.339 187.239 177.339 176.078Z"
          fill="#A1D45D"
        />
        <path
          d="M139.115 38.009H115.225H91.3353L81.7793 42.7868C87.0472 42.7868 91.3353 47.0749 91.3353 52.3428V90.8852H115.225H139.115V52.3428C139.115 47.0749 143.403 42.7868 148.671 42.7868L139.115 38.009Z"
          fill="#EEF4FF"
        />
        <path
          d="M139.115 52.3428C139.115 47.0749 143.403 42.7868 148.671 42.7868L139.115 38.009H115.225V90.8852H139.115V52.3428Z"
          fill="#D9E6FC"
        />
        <path
          d="M115.225 33.2312H81.7793V42.7872H115.225H148.671V33.2312H115.225Z"
          fill="#D9E6FC"
        />
        <path d="M115.225 33.2312H148.671V42.7872H115.225V33.2312Z" fill="#BED9FD" />
        <path
          d="M120.003 147.717C125.564 145.745 129.559 140.434 129.559 134.205C129.559 126.301 123.129 119.871 115.225 119.871C107.321 119.871 100.891 126.301 100.891 134.205C100.891 140.434 104.886 145.745 110.447 147.717V150.363L106.054 152.56C103.475 150.073 99.9707 148.539 96.1134 148.539C88.2092 148.539 81.7795 154.968 81.7795 162.872C81.7795 170.777 88.2092 177.206 96.1134 177.206C104.018 177.206 110.447 170.777 110.447 162.872C110.447 162.273 110.406 161.684 110.334 161.104L115.225 158.66L120.003 150.363V147.717Z"
          fill="#F2D049"
        />
        <path
          d="M134.337 148.539C130.479 148.539 126.975 150.073 124.396 152.561L120.003 150.364V147.718C125.564 145.746 129.559 140.435 129.559 134.206C129.559 126.301 123.129 119.872 115.225 119.872V143.761V148.539V158.659L120.116 161.105C120.044 161.685 120.003 162.274 120.003 162.873C120.003 170.777 126.433 177.207 134.337 177.207C142.241 177.207 148.671 170.777 148.671 162.873C148.671 154.969 142.241 148.539 134.337 148.539Z"
          fill="#E58E40"
        />
      </g>
      <defs>
        <clipPath id="clip0_417_386">
          <rect width="230" height="230" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
