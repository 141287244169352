import { selectLanguages } from 'appConstants';
import { useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ChangeTranslatedLangModal } from '../ChangeModals';

export const TranslateLanguagesField = () => {
  const account = useAppSelector((state) => state.account.account);
  const [showChangeTranslatedLangModal, setShowChangeTranslatedLangModal] =
    useState(false);

  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
      <ChangeTranslatedLangModal
        open={showChangeTranslatedLangModal}
        setOpen={setShowChangeTranslatedLangModal}
        oldTranslatedLang={account?.translatedLanguages || []}
      />
      <dt className="text-sm font-medium text-gray-500">
        <FormattedMessage id="translatedLanguages" />
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow flex items-center overflow-auto scrollbar">
          {account?.translatedLanguages?.map((item, index) => {
            return (
              <div key={'translatedLanguage-' + index} className="mr-2">
                {selectLanguages.find((lang) => lang.value === item)?.label}
                {index !== (account?.translatedLanguages?.length || 0) - 1 && ','}
              </div>
            );
          })}
        </div>
        <span className="ltr:ml-4 rtl:mr-4 flex-shrink-0">
          <button
            onClick={() => {
              setShowChangeTranslatedLangModal(true);
            }}
            className="rounded-md bg-white font-medium text-indigo-600 md:hover:text-indigo-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="edit" />
          </button>
        </span>
      </dd>
    </div>
  );
};
