import { PhotoIcon, ViewColumnsIcon } from '@heroicons/react/20/solid';
import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

const items = [
  {
    title: <FormattedMessage id="createCategory" />,
    description: <FormattedMessage id="subCreateCategory" />,
    icon: PhotoIcon,
    background: 'bg-green-500',
    id: 'createCategory',
  },
  {
    title: <FormattedMessage id="createItem" />,
    description: <FormattedMessage id="subCreateItem" />,
    icon: ViewColumnsIcon,
    background: 'bg-blue-500',
    id: 'createItem',
  },
];

interface EmptyActiveCategoryBlockProps {
  setIsOpen: SetStateType<boolean>;
}

export const EmptyActiveCategoryBlock = ({
  setIsOpen,
}: EmptyActiveCategoryBlockProps) => {
  const history = useHistory();
  return (
    <div>
      <div className="flex items-center justify-center">
        <ul className="mt-8 w-full max-w-2xl grid grid-cols-1 gap-6 py-6 sm:grid-cols-2">
          {items.map((item, itemIdx) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
              key={itemIdx}
              className="flow-root"
              onClick={() => {
                if (item.id === 'createCategory') {
                  setIsOpen(true);
                } else if (item.id === 'createItem') {
                  history.push('/add-item');
                }
              }}
            >
              <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 md:hover:bg-gray-50">
                <div
                  className={classNames(
                    item.background,
                    'flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg',
                  )}
                >
                  <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    <button className="md:focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <span>{item.title}</span>
                      <span aria-hidden="true"> &rarr;</span>
                    </button>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
