// @ts-nocheck
import { Component } from 'react';

export class GlobalErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  /** Update state so the next render will show the fallback UI. */
  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  /** You can render any custom fallback UI */
  render() {
    const { children } = this.props;
    const { error, hasError } = this.state;

    if (hasError) {
      return (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <p className="text-3xl font-semibold text-indigo-600">Error</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              The following error was encountered:
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-600">{error.message}</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <button
                onClick={() => (window.location.href = '/')}
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Go back home
              </button>
              <button
                onClick={() => {
                  this.setState({ hasError: false, error: null });
                }}
                className="text-sm font-semibold text-gray-900"
              >
                Refresh page
              </button>
            </div>
          </div>
        </main>
      );
    }

    return children;
  }
}
