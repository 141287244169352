import { Switch } from '@headlessui/react';
import { SetStateType } from 'commonTypes';
import { reorder } from 'helpers/menu';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { changeOrderEntity } from 'store/account/actions';
import { MenuItemState } from 'store/menu/types';

import { GlobalMenusEnableModal } from '../Modals';
import { AddGlobalMenusButton, GlobalMenuButton } from '../UI';

interface GlobalMenusSectionProps {
  enableGlobalMenus: boolean;
  setEnableGlobalMenus: SetStateType<boolean>;
  setActiveGlobalMenu: SetStateType<string>;
  activeGlobalMenu: string;
}

export const GlobalMenusSection = ({
  enableGlobalMenus,
  setEnableGlobalMenus,
  setActiveGlobalMenu,
  activeGlobalMenu,
}: GlobalMenusSectionProps) => {
  const dispatch = useAppDispatch();
  const [items, setItems] = useState<MenuItemState[]>([]);
  const [openGlobalMenusEnable, setOpenGlobalMenusEnable] = useState(false);
  const [enableMode, setEnableMode] = useState(1);
  const menuGlobalList = useAppSelector((state) => state.menu.menu);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const itemsNew = reorder<MenuItemState>(
      items,
      result.source.index,
      result.destination.index,
    );

    const newOrder: Record<number, string> = {};
    itemsNew.forEach((item, index) => {
      newOrder[index] = item.id;
    });

    dispatch(
      changeOrderEntity({
        newOrder,
        entity: 'menu',
        newMenuList: itemsNew,
      }),
    );

    setItems(itemsNew);
  };

  useEffect(() => {
    if (menuGlobalList?.length > 0) {
      setItems(menuGlobalList);
    }
  }, [menuGlobalList]);

  useEffect(() => {
    if (enableGlobalMenus) {
      setEnableMode(2);
    } else {
      setEnableMode(1);
    }
  }, [enableGlobalMenus]);

  return (
    <div className="w-full bg-white rounded-lg shadow p-4 mb-4 flex flex-wrap sm:flex-nowrap">
      <GlobalMenusEnableModal
        open={openGlobalMenusEnable}
        setOpen={setOpenGlobalMenusEnable}
        mode={enableMode}
        setEnableGlobalMenus={setEnableGlobalMenus}
        setActiveGlobalMenu={setActiveGlobalMenu}
      />
      <Switch.Group as="div" className="flex items-center h-10 mr-5">
        <Switch.Label as="span" className="mr-3 text-sm">
          <span className="text-gray-500 whitespace-nowrap">
            <FormattedMessage id="globalMenus" />
          </span>
        </Switch.Label>
        <Switch
          checked={enableGlobalMenus}
          onChange={(v: boolean) => {
            if (v) {
              setEnableMode(1);
            } else {
              setEnableMode(2);
            }
            setOpenGlobalMenusEnable(true);
          }}
          className={classNames(
            enableGlobalMenus ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              enableGlobalMenus ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
      </Switch.Group>

      {enableGlobalMenus && (
        <div className="flex items-center space-x-3 overflow-auto scrollbar pb-1">
          <AddGlobalMenusButton />
          <GlobalMenuButton
            menu={items.find((item) => item.status === 3)}
            setActiveGlobalMenu={setActiveGlobalMenu}
            activeGlobalMenu={activeGlobalMenu}
          />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-global-menus" direction="horizontal">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  className="flex items-center space-x-3"
                  ref={provided.innerRef}
                >
                  {items.map((menu, i) => {
                    if (menu?.status === 3) return null;
                    return (
                      <Draggable
                        key={'global-menu-button-' + menu?.id}
                        draggableId={'global-menu-button-' + menu?.id}
                        isDragDisabled={i === 0}
                        index={i}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <GlobalMenuButton
                              menu={menu}
                              setActiveGlobalMenu={setActiveGlobalMenu}
                              activeGlobalMenu={activeGlobalMenu}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  );
};
