import { combineReducers } from 'redux';

import { accountReducer } from './account/reducer';
import { authReducer } from './auth/reducer';
import { categoryReducer } from './category/reducer';
import { GPTReducer } from './gpt/reducer';
import { imageReducer } from './images/reducer';
import { addItemReducer } from './item/reducer';
import { localesReducer } from './locales/reducer';
import { menuReducer } from './menu/reducer';
import { optionsReducer } from './options/reducer';
import { qrcodeReducer } from './qrcode/reducer';
import { themesReducer } from './themes/reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  menu: menuReducer,
  account: accountReducer,
  item: addItemReducer,
  qr: qrcodeReducer,
  options: optionsReducer,
  locale: localesReducer,
  category: categoryReducer,
  gpt: GPTReducer,
  image: imageReducer,
  themes: themesReducer,
});
