export const ADD_ITEM_REQUEST = 'ADD_ITEM_REQUEST';
export const ADD_ITEM_SUCCESS = 'ADD_ITEM_SUCCESS';
export const STOP_ADD_ITEM = 'STOP_ADD_ITEM';

export const EDIT_ITEM_FIELD = 'EDIT_ITEM_FIELD';

export const RESET_ITEM = 'RESET_ITEM';

export const START_ACTIVE_REQUEST = 'START_ACTIVE_REQUEST';
export const STOP_ACTIVE_REQUEST = 'STOP_ACTIVE_REQUEST';
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';
export const RESET_ACTIVE_ITEM = 'RESET_ACTIVE_ITEM';

export const START_SUB_ITEM_LOADING = 'START_SUB_ITEM_LOADING';
export const STOP_SUB_ITEM_LOADING = 'STOP_SUB_ITEM_LOADING';
