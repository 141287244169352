import { Dialog } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { ReactNode, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { deleteMenu } from 'store/menu/actions';

interface DeleteGlobalMenuModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  globalMenuId: string;
  setActiveGlobalMenu: SetStateType<string>;
}

export const DeleteGlobalMenuModal = ({
  open,
  setOpen,
  globalMenuId,
  setActiveGlobalMenu,
}: DeleteGlobalMenuModalProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.menu.subLoading);
  const cancelButtonRef = useRef(null);
  const checkField = useRef(null);
  const [check, setCheck] = useState<'move' | 'delete'>('move');

  const resetField = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(
      deleteMenu({
        globalMenuId,
        mode: check,
        setActiveGlobalMenu,
        resetField,
      }),
    );
  };

  const deleteMethods: {
    id: 'move' | 'delete';
    title: ReactNode;
  }[] = [
    {
      id: 'move',
      title: <FormattedMessage id="moveItemsToDefaultGlobalMenu" />,
    },
    { id: 'delete', title: <FormattedMessage id="delete" /> },
  ];

  return (
    <AppModal open={open} size="sm:max-w-3xl">
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 ltr:sm:ml-4 rtl:sm:mr-4 ltr:sm:text-left rtl:sm:text-right">
          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
            <FormattedMessage id="deleteGlobalMenu" />?
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              <FormattedMessage id="messageDeleteGlobalMenu" />
            </p>
          </div>
          <div className="mt-4">
            <div className="text-base font-medium text-gray-900">
              <FormattedMessage id="whatDoYouWantToDoGlobalMenu" />
            </div>
            <fieldset className="mt-2" ref={checkField}>
              <legend className="sr-only">Notification method</legend>
              <div className="space-y-4">
                {deleteMethods.map((method) => (
                  <div
                    key={'global-menu-delete-mode-' + method.id}
                    className="flex items-center"
                  >
                    <input
                      id={method.id}
                      name="notification-method"
                      type="radio"
                      onChange={() => setCheck(method.id)}
                      defaultChecked={method.id === 'move'}
                      className="h-4 w-4 border-gray-300 text-indigo-600 md:focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={method.id}
                      className="ltr:ml-3 rtl:mr-3 block text-sm font-medium text-gray-700"
                    >
                      {method.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          disabled={loading}
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-red-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-red-500 md:focus:ring-offset-2 sm:w-auto sm:text-sm"
          onClick={handleDelete}
        >
          {loading ? <Spinner color="bg-white" /> : <FormattedMessage id="delete" />}
        </button>
        <button
          disabled={loading}
          type="button"
          className="ltr:mr-3 mt-3 sm:mt-0 rtl:ml-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
          ref={cancelButtonRef}
        >
          <FormattedMessage id="cancel" />
        </button>
      </div>
    </AppModal>
  );
};
