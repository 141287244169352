import { Auth } from 'aws-amplify';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { BASE_URL } from 'configure';

import {
  account,
  category,
  gpt,
  images,
  item,
  menu,
  options,
  publicRequest,
  qrcode,
  suggestions,
  theme,
  translate,
} from './routes';

const getAccessToken = async () =>
  (await Auth.currentSession()).getAccessToken().getJwtToken();

const getIdToken = async () => (await Auth.currentSession()).getIdToken().getJwtToken();

const ApiClient = async (
  publicRequest = false,
  baseUrl = BASE_URL,
  idToken = false,
  methodName = '',
): Promise<AxiosInstance> => {
  const config: AxiosRequestConfig = {
    baseURL: baseUrl,
    headers: publicRequest
      ? {}
      : {
          Authorization: `Bearer ${
            idToken ? await getIdToken() : await getAccessToken()
          }`,
        },
  };

  const a = axios.create(config);

  a.interceptors.response.use(
    (response) => response,
    (error: AxiosError<Record<string, any>>) => {
      console.log(methodName, error);

      if (error.request.responseType === 'blob') {
        throw new Error(
          'Supported image extension: "JPEG, PNG, WEBP, HEIC, HEIF"  Please try one more time.',
        );
      } else if (typeof error.response?.data?.detail === 'string') {
        throw new Error(error.response?.data?.detail || '');
      } else {
        throw new Error(error.message);
      }
    },
  );

  return a;
};

export const v1 = {
  qrcode: qrcode(ApiClient),
  publicRequest: publicRequest(ApiClient),
  translate: translate(ApiClient),
  account: account(ApiClient),
  menu: menu(ApiClient),
  category: category(ApiClient),
  images: images(ApiClient),
  item: item(ApiClient),
  suggestions: suggestions(ApiClient),
  gpt: gpt(ApiClient),
  options: options(ApiClient),
  theme: theme(ApiClient),
};
