import { SetStateType } from 'commonTypes';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { resentdRecoveryPasswordCode, resetPassword } from 'store/auth/actions';

interface ForgotPasswordCodeProps {
  codeSended: boolean;
  code: string;
  setCode: SetStateType<string>;
  startTimer: boolean;
  email: string;
  setCodeSended: SetStateType<boolean>;
  setStartTimer: SetStateType<boolean>;
  counter: number;
}

export const ForgotPasswordCode = ({
  codeSended,
  code,
  setCode,
  startTimer,
  email,
  setCodeSended,
  setStartTimer,
  counter,
}: ForgotPasswordCodeProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.auth.loading);

  return (
    <>
      {codeSended && (
        <label htmlFor="code" className="block text-sm font-medium text-gray-700">
          Code
        </label>
      )}
      <div className="flex items-center w-full mt-1">
        <div className="flex flex-grow items-stretch">
          <input
            type="text"
            name="code"
            value={code}
            disabled={loading}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            id="code"
            className={`${
              codeSended ? 'w-full' : 'w-0 px-0'
            } block transition-all duration-300 ease-in-out rounded-l-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
            placeholder="Your conformation code"
          />
        </div>
        <button
          type="button"
          disabled={loading || (codeSended && startTimer)}
          onClick={() => {
            if (!codeSended && email.trim()) {
              dispatch(
                resetPassword({
                  email,
                  setStartTimer,
                  setCodeSended,
                  codeSended,
                }),
              );
            } else if (codeSended && email.trim() && !startTimer) {
              dispatch(
                resentdRecoveryPasswordCode({
                  email,
                  setStartTimer,
                }),
              );
            }
          }}
          className={`${
            codeSended ? 'w-32 rounded-r-md' : 'w-full rounded-md'
          } transition-all disabled:opacity-50 disabled:hover:bg-indigo-600 duration-300 truncate whitespace-nowrap flex items-center justify-center text-center ease-in-out bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
        >
          {loading ? (
            <Spinner color="bg-white" />
          ) : codeSended ? (
            startTimer ? (
              counter + ' s'
            ) : (
              'Resend code'
            )
          ) : (
            'Send code'
          )}
        </button>
      </div>
    </>
  );
};
