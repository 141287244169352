import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { oldLanguages } from 'appConstants';
import { IOptions, SetStateType, TranslateFiled } from 'commonTypes';
import { useAppSelector } from 'hooks/store';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

interface OptionItemProps {
  name: TranslateFiled;
  localChoose?: string[];
  setLocalChoose?: SetStateType<string[]>;
  itemPage?: boolean;
  activeOption?: any;
  option: IOptions;
  id: string;
}

export const OptionItem = ({
  name,
  localChoose,
  setLocalChoose,
  itemPage = false,
  activeOption,
  option,
  id,
}: OptionItemProps) => {
  const history = useHistory();
  const locale = useAppSelector((state) => state.locale.lang);

  return (
    <li>
      <div
        role="button"
        data-testId="cypress-option-item"
        tabIndex={0}
        className={`block ${
          localChoose?.includes(id) ? 'bg-indigo-50 md:hover:bg-indigo-100' : ''
        } ${activeOption ? 'lg:bg-gray-100' : ''} cursor-pointer md:hover:bg-indigo-100`}
        onClick={(e) => {
          const target = e.target as HTMLElement;
          if (!itemPage) {
            history.push('/options/' + id);
          }
          if (target.closest('.edit-option-button') || !itemPage) return;
          if (localChoose && setLocalChoose) {
            if (localChoose.includes(id)) {
              setLocalChoose((prev) => {
                const newChoose = [...prev];
                newChoose.splice(localChoose.indexOf(id), 1);
                return newChoose;
              });
            } else {
              setLocalChoose((prev) => {
                const newChoose = [...prev, id];
                return newChoose;
              });
            }
          }
        }}
      >
        <div
          className={`flex items-center  ${
            itemPage ? 'px-2 py-2 sm:px-3' : 'px-4 py-4 sm:px-6'
          }`}
        >
          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="truncate w-full">
              <div className="flex text-sm items-center justify-between">
                <p className="truncate font-medium text-indigo-600 cursor-text">
                  {name?.translations ? name?.translations[oldLanguages[locale]] : ''}
                </p>

                {itemPage && (
                  <button
                    className="text-indigo-600 test-sm truncate md:hover:text-indigo-500 edit-option-button"
                    onClick={() => {
                      history.push('/options/' + id);
                    }}
                  >
                    <FormattedMessage id="edit" />
                  </button>
                )}
              </div>
              <div className="mt-2 flex">
                <div className="flex items-center text-sm text-gray-500 flex-wrap">
                  <p className="ltr:mr-2 rtl:ml-2">
                    Option in {option?.items?.length} items
                  </p>
                </div>
              </div>
            </div>
          </div>
          {!itemPage && (
            <div className="ltr:ml-5 rtl:mr-5 flex-shrink-0 rtl:rotate-180">
              <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          )}
        </div>
      </div>
    </li>
  );
};
