import { oldLanguages } from 'appConstants';
import { ReactComponent as NoPhoto } from 'assets/static/no_photo.svg';
import { PreloadComponent } from 'components/ImagePreload/PreloadComponent/PreloadComponent';
import { returnNumberPrice } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Items } from 'store/menu/types';

interface ItemsInOptionProps {
  item: Items;
}

export const ItemsInOption = ({ item }: ItemsInOptionProps) => {
  const account = useAppSelector((state) => state.account.account);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const defaultPhoto = useAppSelector((state) => state.account.account?.default_photo);
  const history = useHistory();
  const locale = useAppSelector((state) => state.locale.lang);

  const returnItemPhoto = (product: Items) => {
    if (product?.photo?.medium) {
      return (
        <PreloadComponent
          baseSrc={product?.photo?.medium}
          minimizedSrc={product?.photo?.preloader}
          className="h-full w-full object-cover object-center md:group-hover:opacity-75"
        />
      );
    } else if (defaultPhoto?.links?.medium) {
      return (
        <PreloadComponent
          baseSrc={defaultPhoto?.links?.medium}
          minimizedSrc={defaultPhoto?.links?.preloader}
          className="h-full w-full object-cover object-center md:group-hover:opacity-75"
        />
      );
    } else {
      return <NoPhoto className="p-9 md:group-hover:opacity-75" />;
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className="p-2 rounded-lg flex flex-col cursor-pointer group"
      onClick={() => {
        history.push('/item/' + item?.id);
      }}
    >
      <div className="aspect-w-1 aspect-h-1 w-full md:group-hover:opacity-75 overflow-hidden rounded-lg bg-white xl:aspect-w-7 xl:aspect-h-6">
        {returnItemPhoto(item)}
      </div>
      <div className="w-full flex items-center justify-between mt-2">
        <div className="truncate text-sm">
          {item?.name?.translations ? item?.name?.translations[oldLanguages[locale]] : ''}
        </div>
        {item?.status === 1 && (
          <span className="inline-flex whitespace-nowrap items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
            <FormattedMessage id="active" />
          </span>
        )}
        {item?.status === 2 && (
          <span className="inline-flex whitespace-nowrap items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
            <FormattedMessage id="soldout" />
          </span>
        )}
        {item?.status === 3 && (
          <span className="inline-flex whitespace-nowrap items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
            <FormattedMessage id="archived" />
          </span>
        )}
      </div>
      <div className="w-full truncate text-base mt-2">
        {returnNumberPrice(item?.price) === -1 ? (
          <FormattedMessage id="free" />
        ) : (
          <FormattedNumber
            currency={account?.currency}
            style="currency"
            value={returnNumberPrice(item?.price)}
            maximumFractionDigits={intCurrency ? 0 : 2}
          />
        )}
      </div>
    </div>
  );
};
