import { FreeCheckBox } from '@Common/FreeCheckBox';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { defaultToastProps } from 'appConstants';
import { IPrice, PriceElement, SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { MultiCheckBox } from 'components/Common/MultiCheckBox';
import { MultiPriceRow } from 'components/Common/MultiPriceRow';
import { Spinner } from 'components/Common/Spinner';
import { classNames, positiveNumberInput, validatePrice } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { editItemField } from 'store/item/actions';

interface ChangeItemPriceModalProps {
  openPriceChange: boolean;
  setOpenPriceChange: SetStateType<boolean>;
  globalCategoryId: string;
}

interface RouteParams {
  id: string;
}

export const ChangeItemPriceModal = ({
  openPriceChange,
  setOpenPriceChange,
  globalCategoryId,
}: ChangeItemPriceModalProps) => {
  const { id } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.account);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const beutifyMenu = useAppSelector((state) => state.category.beutifyMenu);
  const loadingImages = useAppSelector((state) => state.item.subLoading);
  const [discardClick, setDiscardClick] = useState(0);
  const [priceValue, setPriceValue] = useState<PriceElement>({
    name: null,
    value: '',
    is_default: null,
    id: 0,
  });
  const [multiple, setMultiple] = useState(false);
  const [free, setFree] = useState(false);
  const [multiplePriceValue, setMultiplePriceValue] = useState<PriceElement[]>([]);
  const menu = useAppSelector((state) => state.menu.menu);
  const activeItem = useAppSelector((state) => state.item.activeItem);

  useEffect(() => {
    if (free) {
      setPriceValue((prev) => ({ ...prev, value: '-1' }));
    } else {
      setPriceValue((prev) => ({ ...prev, value: '0' }));
    }
  }, [free]);

  useEffect(() => {
    if (menu.length > 0) {
      if (activeItem?.price?.type === 'single') {
        setPriceValue(activeItem?.price?.prices[0]);
        if (activeItem?.price?.prices[0].value === '-1') {
          setFree(true);
        } else {
          setFree(false);
        }
        setMultiplePriceValue([
          {
            name: '',
            value: '',
            is_default: true,
            id: 0,
          },
          {
            name: '',
            value: '',
            is_default: false,
            id: 1,
          },
        ]);
        setMultiple(false);
      } else {
        setMultiplePriceValue(activeItem?.price?.prices || []);
        setPriceValue({
          name: null,
          value: '',
          is_default: null,
          id: 0,
        });
        setMultiple(true);
        setFree(false);
      }
    }
  }, [menu, activeItem]);

  const resetFields = () => {
    if (beutifyMenu) {
      setTimeout(() => {
        if (beutifyMenu[id]?.price?.type === 'single') {
          setPriceValue(beutifyMenu[id]?.price?.prices[0]);
          if (activeItem?.price?.prices[0].value === '-1') {
            setFree(true);
          } else {
            setFree(false);
          }
          setMultiplePriceValue([
            {
              name: '',
              value: '',
              is_default: true,
              id: 0,
            },
            {
              name: '',
              value: '',
              is_default: false,
              id: 1,
            },
          ]);
          setMultiple(false);
        } else {
          setMultiplePriceValue(beutifyMenu[id]?.price?.prices);
          setPriceValue({
            name: null,
            value: '',
            is_default: null,
            id: 0,
          });
          setMultiple(true);
          setFree(false);
        }
      }, 250);
    }
  };

  return (
    <AppModal open={openPriceChange} size="sm:max-w-xl">
      <div className="flex flex-col">
        <div className="flex w-full items-center justify-between mb-3">
          <h1>
            <FormattedMessage id="price" />
          </h1>
          <XMarkIcon
            onClick={() => {
              if (discardClick === 0) {
                setDiscardClick(1);
              } else if (discardClick === 1) {
                setDiscardClick(0);
                setOpenPriceChange(false);
                resetFields();
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="flex-grow">
          <div className="flex items-center space-x-4">
            <MultiCheckBox
              multiple={multiple}
              setMultiple={setMultiple}
              disabled={loadingImages || free}
            />
            <FreeCheckBox
              free={free}
              setFree={setFree}
              disabled={loadingImages || multiple}
            />
          </div>
          {!multiple && (
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                type="text"
                name="price"
                id="price"
                disabled={loadingImages}
                className={classNames(
                  'block w-full rounded-md border-gray-300 rtl:pl-12 ltr:pr-12 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm',
                  free && 'opacity-70',
                )}
                placeholder={intCurrency ? '0' : '0.00'}
                aria-describedby="price-currency"
                value={priceValue?.value === '-1' ? '0' : priceValue?.value}
                onChange={(e) => {
                  if (!loadingImages) {
                    setPriceValue((prev) => {
                      const newVal = { ...prev };
                      newVal.value = positiveNumberInput(e, intCurrency);
                      return newVal;
                    });
                  }
                }}
              />
              <div className="pointer-events-none absolute inset-y-0 rtl:left-0 ltr:right-0 flex items-center rtl:pl-3 ltr:pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  {account?.currency}
                </span>
              </div>
            </div>
          )}
          {multiple && (
            <MultiPriceRow
              multiplePriceValue={multiplePriceValue}
              setMultiplePriceValue={setMultiplePriceValue}
              disabled={loadingImages}
            />
          )}
        </div>

        <div className="flex w-full justify-end mt-3">
          <div className="flex items-center space-x-3">
            <div className="flex items-center">
              <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
                {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
              </div>
              <div className="flex items-center justify-end w-full sm:w-auto">
                <button
                  onClick={() => {
                    if (discardClick === 0) {
                      setDiscardClick(1);
                    } else if (discardClick === 1) {
                      setDiscardClick(0);
                      setOpenPriceChange(false);
                      resetFields();
                    }
                  }}
                  type="button"
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  <FormattedMessage id="discardChanges" />
                </button>
                <button
                  onClick={() => {
                    const resultPrice: IPrice = {
                      type: multiple ? 'multiple' : 'single',
                      prices: multiple ? multiplePriceValue : [priceValue],
                    };
                    if (!validatePrice(resultPrice)) {
                      toast("Price name and value can't be 0 or empty", {
                        ...defaultToastProps,
                        type: 'error',
                      });
                      return;
                    } else {
                      setDiscardClick(0);
                      dispatch(
                        editItemField({
                          id: id,
                          value: resultPrice,
                          field: 'price',
                          setOpen: setOpenPriceChange,
                          globalCategoryId,
                        }),
                      );
                    }
                  }}
                  type="button"
                  className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  {loadingImages ? (
                    <Spinner color="bg-white" />
                  ) : (
                    <FormattedMessage id="save" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppModal>
  );
};
