import { SetStateType } from 'commonTypes';
import { ReactNode } from 'react';

interface LoginInputProps {
  label: string;
  id: string;
  name: string;
  type: string;
  value: string;
  setValue: SetStateType<string>;
  autoComplete: string;
  iconLeft?: ReactNode | null;
}

export const LoginInput = ({
  label,
  id,
  name,
  type,
  value,
  setValue,
  autoComplete,
  iconLeft = null,
}: LoginInputProps) => {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative">
        <input
          id={id}
          name={name}
          type={type}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          autoComplete={autoComplete}
          required
          className={`block w-full appearance-none rounded-md border border-gray-300 ${
            iconLeft ? 'pl-3 pr-8' : 'px-3'
          } py-2 placeholder-gray-400 shadow-sm md:focus:border-indigo-500 md:focus:outline-none md:focus:ring-indigo-500 sm:text-sm`}
        />
        {iconLeft}
      </div>
    </div>
  );
};
