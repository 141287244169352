import { AxiosInstance } from 'axios';
import { QRCodeValue } from 'store/qrcode/types';

export const qrcode = (ApiClient: () => Promise<AxiosInstance>) => {
  return {
    getQRCode: async () => (await ApiClient()).get<QRCodeValue>(`/qrcode`),

    updateQRCode: async (id: string, body: { link: string }) =>
      (await ApiClient()).put<QRCodeValue>(`/qrcode/${id}`, body),
  };
};
