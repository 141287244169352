import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import {
  ChangeCategoryContent,
  ChangeStatusItemModal,
  DeleteItemModal,
} from 'components/ItemComponents';
import { useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Categories, Items } from 'store/menu/types';

import { EmptyActiveCategoryBlock, EmptyItemsBlock } from '../UI';
import { MenuListItem } from '../UI/MenuListItem';

interface ActiveCategoryListProps {
  activeCategory: string;
  categoryListObj: {
    [key: string]: Categories;
  };
  emptyAccount: boolean;
  setIsOpen: SetStateType<boolean>;
  itemsList: Items[];
  globalMenuId: string;
}

export const ActiveCategoryList = ({
  activeCategory,
  categoryListObj,
  emptyAccount,
  setIsOpen,
  itemsList,
  globalMenuId,
}: ActiveCategoryListProps) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [activeItem, setActiveItem] = useState<null | string>(null);

  const [openCategoryChange, setOpenCategoryChange] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const beutifyMenu = useAppSelector((state) => state.category.beutifyMenu);

  return (
    <div className="bg-white">
      <div className="max-w-2xl py-4 px-0 sm:pb-4 sm:pt-8 sm:px-0 lg:max-w-7xl 2xl:max-w-full lg:px-0">
        <h2 className="sr-only">Products</h2>
        {categoryListObj[activeCategory]?.status === 4 && (
          <div className="rounded-md bg-yellow-50 p-4 mb-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ltr:ml-3 rtl:mr-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  <FormattedMessage id="attention" />
                </h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    <FormattedMessage id="emptyAccountText" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeCategory &&
          !!categoryListObj &&
          !!categoryListObj[activeCategory]?.items?.length && (
            <div className="w-full grid grid-cols-2 gap-y-10 gap-x-6 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 largeDesktop:grid-cols-6 xl:gap-x-8">
              {itemsList?.map((product) => (
                <MenuListItem
                  setActiveItem={setActiveItem}
                  setOpenCategoryChange={setOpenCategoryChange}
                  setShowChangeStatusModal={setShowChangeStatusModal}
                  setOpenDeleteModal={setOpenDeleteModal}
                  product={product}
                  key={'item-card-' + product.id}
                />
              ))}
            </div>
          )}
        {activeCategory &&
          !!categoryListObj &&
          !categoryListObj[activeCategory]?.items?.length &&
          !emptyAccount && <EmptyItemsBlock />}

        {activeCategory &&
          !!categoryListObj &&
          !categoryListObj[activeCategory]?.items?.length &&
          emptyAccount && <EmptyActiveCategoryBlock setIsOpen={setIsOpen} />}
      </div>

      <DeleteItemModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        item={activeItem || ''}
        globalMenuId={globalMenuId}
        categoryId={activeCategory}
      />

      {beutifyMenu && activeItem && (
        <ChangeStatusItemModal
          open={showChangeStatusModal}
          setOpen={setShowChangeStatusModal}
          itemId={activeItem}
          item={beutifyMenu[activeItem]}
          globalCategoryId={globalMenuId}
          menuPage={true}
        />
      )}

      {beutifyMenu && activeItem && (
        <AppModal open={openCategoryChange} size="sm:max-w-md">
          <ChangeCategoryContent
            id={beutifyMenu[activeItem]?.categoryId}
            setOpenCategoryChange={setOpenCategoryChange}
            itemId={activeItem}
            globalCategoryId={globalMenuId}
            menuPage={true}
          />
        </AppModal>
      )}
    </div>
  );
};
