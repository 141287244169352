import { translateGpt } from 'appConstants';
import { SelectedLanguage } from 'commonTypes';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { removePrompt } from 'store/gpt/actions';
import { Prompt as PromptType } from 'store/gpt/types';

interface PromptProps {
  prompt: PromptType;
  selected: SelectedLanguage | undefined;
}

export const Prompt = ({ prompt, selected }: PromptProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.gpt.chatGPT.loading);

  const trueText = () => {
    if (!prompt.defaultId) {
      return prompt.text;
    } else {
      // @ts-ignore
      return translateGpt.prompts[prompt.defaultId][selected.value];
    }
  };

  return (
    <span className="inline-flex bg-indigo-50 rounded-full px-2.5 py-1.5 text-sm font-semibold items-center gap-x-0.5 text-indigo-600 mb-2 mr-2">
      {trueText()}
      <button
        onClick={() => {
          dispatch(
            removePrompt({
              id: prompt.id,
            }),
          );
        }}
        disabled={loading}
        type="button"
        className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
      >
        <span className="sr-only">Remove</span>
        <svg
          viewBox="0 0 14 14"
          className="h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75"
        >
          <path d="M4 4l6 6m0-6l-6 6" />
        </svg>
        <span className="absolute -inset-1" />
      </button>
    </span>
  );
};
