import { XMarkIcon } from '@heroicons/react/24/outline';
import { ReactComponent as BoltFood } from 'assets/static/boltFood.svg';
import { ReactComponent as UberEats } from 'assets/static/uberEats.svg';
import { SetStateType } from 'commonTypes';
import { AppInput } from 'components/Common/AppInput';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { changeDeliveryLinks } from 'store/account/actions';
import { RESET_NOTIFICATION } from 'store/account/actionsTypes';

interface ChangeDeliveryModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
}

export const ChangeDeliveryModal = ({ open, setOpen }: ChangeDeliveryModalProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.account.subLoading);
  const account = useAppSelector((state) => state.account.account);

  const [discardClick, setDiscardClick] = useState(0);

  const [newUberEats, setNewUberEats] = useState('');
  const [newBoltFood, setNewBoltFood] = useState('');

  useEffect(() => {
    if (account) {
      if (account.delivery_uber) {
        setNewUberEats(account.delivery_uber);
      } else {
        setNewUberEats('');
      }
      if (account.delivery_bolt) {
        setNewBoltFood(account.delivery_bolt);
      } else {
        setNewBoltFood('');
      }
    }
  }, [open]);

  const resetFields = () => {
    setTimeout(() => {
      setDiscardClick(0);
      setNewUberEats('');
      setNewBoltFood('');
      dispatch({
        type: RESET_NOTIFICATION,
      });
    }, 300);
  };

  const onHandleClick = () => {
    setDiscardClick(0);
    //TODO Валидация ссылки

    dispatch(
      changeDeliveryLinks({
        setOpen,
        data: {
          delivery_uber: newUberEats,
          delivery_bolt: newBoltFood,
        },
        resetFields,
      }),
    );
  };

  return (
    <AppModal open={open} size="sm:max-w-md">
      <div className="flex flex-col">
        <div className="flex items-center justify-between w-full mb-3">
          <h1>
            <FormattedMessage id="changeDeliverySrvicesTitle" />
          </h1>
          <XMarkIcon
            onClick={() => {
              setOpen(false);
              resetFields();
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="flex items-center justify-between mb-3">
          <UberEats className="h-7 w-7 mr-3" />
          <AppInput
            label="uberEats"
            withLabel={false}
            id="uberEats"
            name="uberEatsLinks"
            type="text"
            placeholder="Your Uber Eats link"
            className="w-full"
            testId="cypress-uberEats-input"
            value={newUberEats}
            onChange={(e) => {
              setNewUberEats(e.target.value);
            }}
          />
        </div>

        <div className="flex items-center justify-between mb-3">
          <BoltFood className="h-7 w-7 mr-3" />
          <AppInput
            label="boltFood"
            withLabel={false}
            id="boltFood"
            name="boltFoodLinks"
            type="text"
            placeholder="Your Bolt Food link"
            className="w-full"
            value={newBoltFood}
            onChange={(e) => {
              setNewBoltFood(e.target.value);
            }}
            testId="cypress-boltFood-input"
          />
        </div>

        <div className="w-full flex items-center justify-end">
          {(!account?.delivery_uber &&
            !account?.delivery_bolt &&
            newBoltFood === '' &&
            newUberEats === '') ||
          (newBoltFood === account?.delivery_bolt &&
            newUberEats === account?.delivery_uber) ? (
            <>
              <button
                type="button"
                disabled={loading}
                onClick={() => {
                  setOpen(false);
                  resetFields();
                }}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="cancel" />
              </button>
            </>
          ) : (
            <div className="flex items-center">
              <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
                {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
              </div>
              <div className="flex items-center justify-end w-full sm:w-auto">
                <button
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    if (discardClick === 0) {
                      setDiscardClick(1);
                    } else {
                      setOpen(false);
                      resetFields();
                    }
                  }}
                  className="ltr:mr-3 rtl:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  <FormattedMessage id="discardChanges" />
                </button>
                <button
                  disabled={loading}
                  onClick={onHandleClick}
                  type="button"
                  data-testId="cypress-delivery-save"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  {loading ? <Spinner /> : <FormattedMessage id="save" />}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </AppModal>
  );
};
