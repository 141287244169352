import { ReactComponent as BoltFood } from 'assets/static/boltFood.svg';
import { ReactComponent as UberEats } from 'assets/static/uberEats.svg';
import { useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ChangeDeliveryModal } from '../ChangeModals';

export const DeliveryServicesField = () => {
  const account = useAppSelector((state) => state.account.account);
  const [showChangeDeliveryModal, setShowChangeDeliveryModal] = useState(false);

  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 delivery-link items-center">
      <ChangeDeliveryModal
        open={showChangeDeliveryModal}
        setOpen={setShowChangeDeliveryModal}
      />
      <dt className="text-sm font-medium text-gray-500">
        <FormattedMessage id="deliveryServices" />
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow truncate">
          <div className="flex items-center">
            {account?.delivery_bolt && (
              <a
                href={account?.delivery_bolt}
                target="_blank"
                className="cursor-pointer p-1 rounded-lg border border-gray-300 mr-3 md:hover:bg-gray-100"
                rel="noreferrer"
              >
                <BoltFood className="h-6 w-6" />
              </a>
            )}
            {account?.delivery_uber && (
              <a
                href={account?.delivery_uber}
                target="_blank"
                className="cursor-pointer p-1 rounded-lg border border-gray-300 md:hover:bg-gray-100"
                rel="noreferrer"
              >
                <UberEats className="h-6 w-6" />
              </a>
            )}
            {!account?.delivery_bolt && !account?.delivery_uber && 'No links'}
          </div>
        </div>
        <span className="ltr:ml-4 rtl:mr-4 flex-shrink-0">
          <button
            onClick={() => {
              setShowChangeDeliveryModal(true);
            }}
            data-testId="cypress-change-delivery"
            className="rounded-md bg-white font-medium text-indigo-600 md:hover:text-indigo-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="edit" />
          </button>
        </span>
      </dd>
    </div>
  );
};
