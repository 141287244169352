import { ModalChangeLanguage } from '@Common/ModalChangeLanguage';
import { Switch } from '@headlessui/react';
import { SelectedLanguage, SetStateType } from 'commonTypes';
import { createNameWithoutTransalte } from 'helpers/menu';
import { classNames } from 'helpers/validation';
import { FormattedMessage } from 'react-intl';
import ReactTextareaAutosize from 'react-textarea-autosize';

import { ToppingsOptions } from './ToppingsOptions';

interface AddOptionFirstStepProps {
  loadingTranslate: boolean;
  selected: SelectedLanguage | undefined;
  setSelected: SetStateType<SelectedLanguage | undefined>;
  optionName: string;
  setOptionName: SetStateType<string>;
  withoutTransalteOption: boolean;
  setOptionNameTranslate: any;
  setWithoutTransalteOption: SetStateType<boolean>;
  multiChoice: boolean;
  setMultiChoice: SetStateType<boolean>;
  toppings: boolean;
  setToppings: SetStateType<boolean>;
  setSelectionCount: SetStateType<number>;
  selectionCount: number;
}

export const AddOptionFirstStep = ({
  loadingTranslate,
  selected,
  setSelected,
  optionName,
  setOptionName,
  withoutTransalteOption,
  setOptionNameTranslate,
  setWithoutTransalteOption,
  multiChoice,
  setMultiChoice,
  toppings,
  setToppings,
  setSelectionCount,
  selectionCount,
}: AddOptionFirstStepProps) => {
  return (
    <div className="w-full flex flex-col">
      <ModalChangeLanguage
        disabled={loadingTranslate}
        selected={selected}
        setSelected={setSelected}
      />

      <div className="mt-4 flex flex-col w-full justify-between items-end">
        <div className="w-full">
          <div className="mt-1 ">
            <ReactTextareaAutosize
              data-testId="cypress-option-name-input"
              minRows={2}
              disabled={loadingTranslate}
              name="item-name"
              id="item-name"
              className="w-full scrollbar rounded-md border-gray-300 sm:text-sm"
              placeholder={`Option name`}
              value={optionName}
              onChange={(e) => {
                setOptionName(e.target.value);
                if (withoutTransalteOption) {
                  setOptionNameTranslate(createNameWithoutTransalte(e.target.value));
                } else {
                  setOptionNameTranslate({});
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className="relative flex items-start justify-end mb-3 border-b pb-3">
        <div className="flex h-6 items-center">
          <input
            id="without-translate"
            data-testId="cypress-option-name-translate"
            aria-describedby="without-translate"
            name="without-translate"
            type="checkbox"
            checked={withoutTransalteOption}
            onChange={(e) => {
              if (setWithoutTransalteOption) {
                setWithoutTransalteOption(e.target.checked);
              }
              if (e.target.checked) {
                if (optionName.trim()) {
                  setOptionNameTranslate(createNameWithoutTransalte(optionName));
                }
              } else {
                setOptionNameTranslate({});
              }
            }}
            disabled={loadingTranslate}
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label
            htmlFor="without-translate"
            className="font-medium text-gray-900 select-none"
          >
            <FormattedMessage id="withoutTranslate" />
          </label>
        </div>
      </div>

      <Switch.Group
        as="div"
        className="flex items-center justify-between flex-wrap mb-3 pb-3 border-b"
      >
        <Switch.Label
          htmlFor="option-multi"
          className="font-medium text-gray-700 ltr:mr-2 rtl:ml-2 text-sm"
        >
          <FormattedMessage id="multi" />
        </Switch.Label>

        <Switch
          id="option-multi"
          checked={multiChoice}
          onChange={(v: boolean) => {
            if (!v) {
              setToppings(false);
            }
            setMultiChoice(v);
          }}
          className={classNames(
            multiChoice ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              multiChoice ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
      </Switch.Group>

      <ToppingsOptions
        selectionCount={selectionCount}
        setSelectionCount={setSelectionCount}
        enabled={toppings}
        setEnabled={setToppings}
        multi={multiChoice}
        setMultiChoice={setMultiChoice}
      />
    </div>
  );
};
