import { Menu, Transition } from '@headlessui/react';
import {
  Bars4Icon,
  EllipsisVerticalIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { IOptions } from 'commonTypes';
import { AddOptionModal } from 'components/Common/AddOptionModal';
import { ChangeOptionsModal } from 'components/Common/ChangeOptionsModal';
import { ChoosenOption } from 'components/Common/ChoosenOption';
import { Spinner } from 'components/Common/Spinner';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { getOptionsApi } from 'store/options/actions';

import { ChangeOptionsOrderModal } from '../ChangeModals';
import { EmptyTab } from '../UI';

interface OptionsTabProps {
  optionsItem: IOptions[];
}

interface RouteParams {
  id: string;
}

export const OptionsTab = ({ optionsItem }: OptionsTabProps) => {
  const { id } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showAddOptionModal, setShowAddOptionModal] = useState(false);
  const options = useAppSelector((state) => state.options.optionsApi);
  const optionsLoading = useAppSelector((state) => state.options.loading);
  const optionItemRelationLoading = useAppSelector((state) => state.options.subLoading);
  const [localChoose, setLocalChoose] = useState(optionsItem?.map((item) => item.id));
  const [showChangeOrderModal, setShowChangeOrderModal] = useState(false);
  const [localOptionsList, setLocalOptionsList] = useState(optionsItem || []);

  useEffect(() => {
    if (!options) {
      dispatch(getOptionsApi());
    }
    return () => {
      setLocalOptionsList([]);
    };
  }, []);

  useEffect(() => {
    setLocalOptionsList(optionsItem);
  }, [optionsItem]);

  if (optionsLoading || optionItemRelationLoading) {
    return <Spinner color="bg-primary" />;
  }

  return (
    <div>
      {optionsItem?.length > 0 ? (
        <>
          <div className="w-full flex items-center justify-between mb-3">
            <button
              className="py-2 px-4 rounded-lg whitespace-nowrap bg-white md:hover:bg-indigo-50 text-indigo-400 text-sm"
              onClick={() => setShowAddOptionModal(true)}
            >
              + <FormattedMessage id="addOption" />
            </button>

            <Menu as="div" className="relative ml-3 inline-block text-left">
              <div>
                <Menu.Button className="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 md:hover:text-gray-600 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute ltr:right-0 rtl:left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => setShowOptionsModal(true)}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm cursor-pointer',
                          )}
                        >
                          <PencilSquareIcon
                            className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          <span className="truncate">
                            <FormattedMessage id="edit" />
                          </span>
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="flex items-start flex-wrap w-full gap-x-3 gap-y-3 xl:gap-x-4">
            {optionsItem?.map((option) => {
              return <ChoosenOption key={'item-option-' + option?.id} option={option} />;
            })}
          </div>
        </>
      ) : (
        <EmptyTab
          setOpen={setShowAddOptionModal}
          setExistOpen={setShowOptionsModal}
          mode="options"
        />
      )}

      <ChangeOptionsModal
        open={showOptionsModal}
        localChoose={localChoose}
        setLocalChoose={setLocalChoose}
        setOpen={setShowOptionsModal}
        itemOptions={optionsItem?.map((item) => item.id)}
        itemId={id}
      />
      <AddOptionModal
        open={showAddOptionModal}
        setOpen={setShowAddOptionModal}
        setLocalChoose={setLocalChoose}
        localChoose={localChoose}
        idItem={id}
      />
      <ChangeOptionsOrderModal
        open={showChangeOrderModal}
        setOpen={setShowChangeOrderModal}
        optionsList={localOptionsList}
        setOptionsList={setLocalOptionsList}
      />
    </div>
  );
};
