import { Listbox, Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { selectLanguages } from 'appConstants';
import { SelectedLanguage, SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

interface ModalChangeLanguageProps {
  selected: SelectedLanguage | undefined;
  setSelected: SetStateType<SelectedLanguage | undefined>;
  disabled?: boolean;
}

export const ModalChangeLanguage = ({
  selected,
  setSelected,
  disabled = false,
}: ModalChangeLanguageProps) => {
  const account = useAppSelector((state) => state.account.account);

  return (
    <div className="flex items-center flex-wrap space-x-1 space-y-1">
      <Listbox value={selected} onChange={setSelected} disabled={disabled}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700 sr-only">
              Assigned to
            </Listbox.Label>
            <div className="relative w-36">
              <Listbox.Button
                as="div"
                className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm md:focus:border-indigo-500 md:focus:outline-none md:focus:ring-1 md:focus:ring-indigo-500 sm:text-sm"
              >
                <span className="block truncate">{selected?.label}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none sm:text-sm">
                  {account?.languages.map((lang) => {
                    const langObj = selectLanguages.find((elem) => elem.value === lang);
                    return (
                      <Listbox.Option
                        key={'lang-change-modal-' + langObj?.value}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          )
                        }
                        value={langObj}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate',
                              )}
                            >
                              {langObj?.label}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <div className="flex items-center">
        <InformationCircleIcon
          data-tip
          data-for="translate-info-source"
          className="h-5 w-5 text-primary"
          aria-hidden="true"
        />
        <div className=" text-sm ltr:ml-1 rtl:mr-1 text-primary">
          <FormattedMessage id="sourceLangText" />
        </div>
      </div>
    </div>
  );
};
