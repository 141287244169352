import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { changeMainAttr, resendCodeForMainAttr } from 'store/auth/actions';

const confirmMethod = [
  { label: 'Email', id: 'email-confirm-method', value: 1 },
  // { label: 'Phone', id: 'phone-confirm-method', value: 2 },
];

interface ConfirmOldAttrProps {
  codeSended: boolean;
  loading: boolean;
  confirmMethodChoose: number;
  setConfirmMethodChoose: SetStateType<number>;
  code: string;
  setCode: SetStateType<string>;
  setActiveStep: SetStateType<number>;
  activeStep: number;
  setLoading: SetStateType<boolean>;
  setCodeSended: SetStateType<boolean>;
}

export const ConfirmOldAttr = ({
  codeSended,
  loading,
  confirmMethodChoose,
  setConfirmMethodChoose,
  code,
  setCode,
  setActiveStep,
  activeStep,
  setLoading,
  setCodeSended,
}: ConfirmOldAttrProps) => {
  const dispatch = useAppDispatch();
  const cognitoUser = useAppSelector((state) => state.account.cognitoUser);
  const [startTimer, setStartTimer] = useState(false);
  const [timer, setTimer] = useState<undefined | NodeJS.Timeout>(undefined);

  const [counter, setCounter] = useState(60);

  useEffect(() => {
    if (startTimer && !timer) {
      setTimer(
        setInterval(() => {
          setCounter((time) => time - 1);
        }, 1000),
      );
    } else {
      clearInterval(timer);
      setTimer(undefined);
      setCounter(60);
    }
  }, [startTimer]);

  useEffect(() => {
    if (counter <= 0) {
      setStartTimer(false);
    }
  }, [counter]);

  return (
    <div className="flex flex-col mb-3">
      {/* <div className="rounded-md bg-blue-50 p-4 mb-3">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              <FormattedMessage id="changeMainAttrText" />
            </p>
          </div>
        </div>
      </div> */}

      <div className="space-y-4 mb-3">
        {confirmMethod.map((method) => (
          <div key={'confirm-method-radio' + method.value} className="flex items-center">
            {/* <input
              name="confirm-method"
              id={method.id}
              disabled={codeSended || loading}
              type="radio"
              defaultChecked={method.value === +confirmMethodChoose}
              onChange={() => {
                if (method.value === 1) {
                  setConfirmMethodChoose(1);
                } else {
                  setConfirmMethodChoose(2);
                }
              }}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
            /> */}
            <div className="block text-sm font-medium leading-6 text-gray-900">
              {method.label} (
              {method.value === 1
                ? cognitoUser?.attributes?.email
                : cognitoUser?.attributes?.phone_number}
              )
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-center w-full">
        <div className="flex flex-grow items-stretch">
          <label htmlFor="code" className="sr-only">
            <FormattedMessage id="code" />
          </label>
          <input
            type="text"
            name="code"
            value={code}
            disabled={loading}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            id="code"
            className={`${
              codeSended ? 'w-full' : 'w-0 px-0'
            } block transition-all duration-300 ease-in-out rounded-l-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
            placeholder="Your conformation code"
          />
        </div>
        <button
          type="button"
          disabled={loading || (codeSended && startTimer)}
          onClick={() => {
            if (!codeSended) {
              dispatch(
                changeMainAttr({
                  step: activeStep,
                  setStep: setActiveStep,
                  setLoading,
                  setCode,
                  codeSended,
                  setCodeSended,
                  mode: confirmMethodChoose === 1 ? 'email' : 'phone_number',
                  setStartTimer,
                }),
              );
            } else if (!startTimer) {
              dispatch(
                resendCodeForMainAttr({
                  step: activeStep,
                  setLoading,
                  mode: confirmMethodChoose === 1 ? 'email' : 'phone_number',
                  setStartTimer,
                }),
              );
            }
          }}
          className={`${
            codeSended ? 'w-32 rounded-r-md' : 'w-full rounded-md'
          } transition-all disabled:opacity-50 disabled:hover:bg-indigo-600 duration-300 truncate whitespace-nowrap flex items-center justify-center text-center ease-in-out bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
        >
          {loading ? (
            <Spinner color="bg-white" />
          ) : codeSended ? (
            startTimer ? (
              counter + ' s'
            ) : (
              <FormattedMessage id="resendCode" />
            )
          ) : (
            <FormattedMessage id="sendCode" />
          )}
        </button>
      </div>
    </div>
  );
};
