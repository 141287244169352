import { XMarkIcon } from '@heroicons/react/24/outline';
import { IOptions, SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { getItemStyleOrder, reorder } from 'helpers/menu';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';

import { OptionRowBlock } from '../UI';

interface ChangeOptionsOrderModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  optionsList: IOptions[];
  setOptionsList: SetStateType<IOptions[]>;
}

export const ChangeOptionsOrderModal = ({
  open,
  setOpen,
  optionsList,
  setOptionsList,
}: ChangeOptionsOrderModalProps) => {
  const [options, setOptions] = useState(optionsList);

  useEffect(() => {
    setOptions(optionsList);
  }, [optionsList]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const itemsNew = reorder(options, result.source.index, result.destination.index);

    const newOrder: Record<number, string> = {};
    itemsNew.forEach((item, index) => {
      newOrder[index] = item.id;
    });
    setOptions(itemsNew);
    setOptionsList(itemsNew);
  };
  return (
    <AppModal open={open} size="sm:max-w-xl">
      <DragDropContext onDragEnd={onDragEnd}>
        <div>
          <form className="w-full mb-2 flex items-center justify-between">
            <h1>
              <FormattedMessage id="options" />
            </h1>

            <XMarkIcon
              onClick={() => {
                setOpen(false);
              }}
              className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
            />
          </form>
          <div className="text-gray-400 w-full text-left text-sm mb-4 sub-text-dnd">
            <FormattedMessage id="changeItemsOrderText" />
          </div>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                className="w-full relative item-list"
                ref={provided.innerRef}
              >
                {options.map((item, i) => {
                  return (
                    <Draggable
                      key={'options-order-button-' + item?.id}
                      draggableId={item?.id}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyleOrder(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                          className="mb-3"
                        >
                          <OptionRowBlock key={item?.id} option={item} />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <div className="w-full flex justify-end">
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
        >
          <FormattedMessage id="close" />
        </button>
      </div>
    </AppModal>
  );
};
