import { CheckIcon } from '@heroicons/react/20/solid';
import { classNames } from 'helpers/validation';
import { FormattedMessage } from 'react-intl';

const steps = [
  {
    name: <FormattedMessage id="name" />,
    description: <FormattedMessage id="nameSub" />,
    id: 0,
  },
  {
    name: <FormattedMessage id="description" />,
    description: <FormattedMessage id="descriptionSub" />,
    id: 1,
  },
  {
    name: <FormattedMessage id="mainInfo" />,
    description: <FormattedMessage id="mainInfoSub" />,
    id: 2,
  },
  {
    name: <FormattedMessage id="availability" />,
    description: <FormattedMessage id="availabilitySub" />,
    id: 3,
  },
  {
    name: <FormattedMessage id="options" />,
    description: <FormattedMessage id="optionsSub" />,
    id: 4,
  },
  {
    name: <FormattedMessage id="suggestions" />,
    description: <FormattedMessage id="suggestionsSub" />,
    id: 5,
  },
  {
    name: <FormattedMessage id="photo" />,
    description: <FormattedMessage id="photoSub" />,
    id: 6,
  },
];

interface CreateItemSteperProps {
  activeStep: number;
}

export const CreateItemSteper = ({ activeStep }: CreateItemSteperProps) => {
  return (
    <nav aria-label="Progress">
      <ol className="overflow-hidden">
        {steps.map((step, stepIdx) => (
          <li
            key={'create-item-step-' + step.id}
            className={classNames(
              stepIdx !== steps.length - 1 ? 'pb-10' : '',
              'relative',
            )}
          >
            {+step.id < +activeStep ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute top-4 rtl:right-4 ltr:left-4 rtl:-mr-px ltr:-ml-px mt-0.5 h-full w-0.5 bg-indigo-600"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="group relative flex items-start cursor-pointer">
                  <span className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 ">
                      <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                    </span>
                  </span>
                  <span className="ltr:ml-4 rtl:mr-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </div>
              </>
            ) : +step.id === +activeStep ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute top-4 rtl:right-4 ltr:left-4 rtl:-mr-px ltr:-ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <div
                  className="group relative flex items-start cursor-pointer"
                  aria-current="step"
                >
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                    </span>
                  </span>
                  <span className="ltr:ml-4 rtl:mr-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-indigo-600">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </div>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute top-4 rtl:right-4 ltr:left-4 rtl:-mr-px ltr:-ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="group relative flex items-start cursor-pointer">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white ">
                      {/*<span className='h-2.5 w-2.5 rounded-full bg-transparent md:group-hover:bg-gray-300' />*/}
                    </span>
                  </span>
                  <span className="ltr:ml-4 rtl:mr-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-gray-500">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};
