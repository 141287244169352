import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  RESET_ACCOUNT,
  RESET_NOTIFICATION,
  SET_ACCOUNT_INFO,
  SET_NOTIFICATION,
  START_CHANGE_FLAG_WITH_ORDER,
  START_CHANGE_PASSWORD,
  START_SET_ACCOUNT_INFO,
  START_SUB_LOADING,
  STOP_CHANGE_FLAG_WITH_ORDER,
  STOP_SET_ACCOUNT_INFO,
  STOP_SUB_LOADING,
  UPDATE_COGNITO_USER,
} from './actionsTypes';
import { AccountAction, AccountReducerState } from './types';

const initialState: AccountReducerState = {
  account: null,
  loading: false,
  error: null,
  subLoading: false,
  cognitoUser: null,
  notification: null,
};

export const accountReducer = (
  state: AccountReducerState = initialState,
  action: AccountAction,
) => {
  switch (action.type) {
    case START_SET_ACCOUNT_INFO:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SET_ACCOUNT_INFO:
      return {
        ...state,
        account: {
          ...state.account,
          ...action.payload.account,
        },
        cognitoUser: action.payload.cognitoUser,
        loading: false,
        error: null,
      };
    case STOP_SET_ACCOUNT_INFO:
      return {
        ...state,
        loading: false,
      };
    case START_CHANGE_PASSWORD:
    case START_CHANGE_FLAG_WITH_ORDER:
    case START_SUB_LOADING:
      return {
        ...state,
        subLoading: true,
        error: null,
      };
    case CHANGE_PASSWORD:
    case STOP_CHANGE_FLAG_WITH_ORDER:
    case STOP_SUB_LOADING:
      return {
        ...state,
        subLoading: false,
        error: null,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        subLoading: false,
        error: action.payload,
      };
    case RESET_ACCOUNT:
      return {
        ...state,
        account: null,
        loading: false,
        error: null,
        subLoading: false,
        cognitoUser: null,
      };
    case UPDATE_COGNITO_USER:
      return {
        ...state,
        cognitoUser: action.payload,
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case RESET_NOTIFICATION:
      return {
        ...state,
        notification: null,
      };
    default:
      return state;
  }
};
