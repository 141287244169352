import { API } from 'api';
import { defaultToastProps } from 'appConstants';
import { IOptions } from 'commonTypes';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { AppThunk } from 'store/globalTypes';

import { store } from '..';
import { setActiveItem } from '../item/actions';
import {
  RESET_API_OPTIONS,
  SET_API_OPTIONS,
  START_OPTIONS_REQUEST,
  START_SUB_OPTIONS_REQUEST,
  STOP_OPTIONS_REQUEST,
  STOP_SUB_OPTIONS_REQUEST,
} from './actionsTypes';
import {
  AddOptionProps,
  AddSelectionBody,
  AddSelectionProps,
  ChangeStatusOptionBody,
  ChangeStatusOptionProps,
  DeleteOptionAction,
  DeleteSelectionProps,
  EditDefaultSelectionProps,
  EditOptionProps,
  EditSelectionProps,
  OptionsAction,
  StructOptions,
  updateItemOptionsRelationsProps,
} from './types';

export const resetOptionsApi = () => {
  return {
    type: RESET_API_OPTIONS,
  };
};

export const updateReducerOptions = (data: IOptions[]) => {
  const structOptions: StructOptions = {};
  data.forEach((item) => (structOptions[item.id] = item));
  return {
    type: SET_API_OPTIONS,
    payload: { options: data, struct: structOptions },
  };
};

export const getOptionsApi: AppThunk =
  (needLoad = true) =>
  async (dispatch: Dispatch<OptionsAction>) => {
    if (needLoad) {
      dispatch({
        type: START_OPTIONS_REQUEST,
      });
    }

    API.v1.options
      .getOptions()
      .then(({ data }) => {
        // @ts-ignore
        dispatch(updateReducerOptions(data));
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_OPTIONS_REQUEST,
        });
      });
  };

export const deleteOption: AppThunk =
  ({ id, setOpen, history }: DeleteOptionAction) =>
  async (dispatch: Dispatch<OptionsAction>) => {
    dispatch({
      type: START_SUB_OPTIONS_REQUEST,
    });
    const deletedId = id;
    API.v1.options
      .deleteOptions(id)
      .then(() => {
        const oldOptions = store.getState().options.optionsApi || [];
        const newOptions = oldOptions?.filter((option) => option.id !== deletedId);

        // @ts-ignore
        dispatch(updateReducerOptions(newOptions));

        setOpen(false);
        history.push('/options');

        toast('Option successfully deleted', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_SUB_OPTIONS_REQUEST,
        });
      });
  };

export const addOption: AppThunk =
  ({
    option,
    setOpen,
    resetFields,
    localChoose,
    setLocalChoose,
    idItem,
  }: AddOptionProps) =>
  async (dispatch: Dispatch<OptionsAction>) => {
    dispatch({
      type: START_SUB_OPTIONS_REQUEST,
    });

    API.v1.options
      .createOption(option)
      .then(({ data: newOption }) => {
        const oldOptions = store.getState().options.optionsApi || [];
        const newOptions = [...oldOptions, newOption];

        // @ts-ignore
        dispatch(updateReducerOptions(newOptions));

        if (setLocalChoose) {
          setLocalChoose((prev) => {
            const newOptions = [...prev, newOption.id];
            return newOptions;
          });
        }
        if (setLocalChoose && localChoose && idItem) {
          dispatch(
            // @ts-ignore
            updateItemOptionsRelations({
              entityId: idItem,
              data: [...localChoose, newOption.id],
              setOpen,
            }),
          );
        }
        if (setOpen) {
          setOpen(false);
        }
        if (resetFields) {
          setTimeout(() => {
            resetFields();
          }, 200);
        }

        toast('Option successfully added', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_SUB_OPTIONS_REQUEST,
        });
      });
  };

export const editOption: AppThunk =
  ({ id, data, setOpen, resetFields, setIsMulti, setLoading }: EditOptionProps) =>
  async (dispatch: Dispatch<OptionsAction>) => {
    if (
      Object.keys(data)[0] !== 'multi_select' &&
      Object.keys(data)[0] !== 'free_option_data'
    ) {
      dispatch({
        type: START_OPTIONS_REQUEST,
      });
    }
    if (setLoading) setLoading(true);

    API.v1.options
      .editOption(id, data)
      .then(({ data: newOption }) => {
        if (setIsMulti) setIsMulti((prev) => !prev);
        const oldOptions = store.getState().options.optionsApi || [];
        const newOptions = oldOptions.map((option) => {
          if (option?.id === id) {
            return {
              ...newOption,
              selections: option.selections,
              items: option.items,
            };
          } else {
            return option;
          }
        });

        // @ts-ignore
        dispatch(updateReducerOptions(newOptions));

        if (setOpen) {
          setOpen(false);
        }
        if (resetFields) {
          setTimeout(() => {
            resetFields();
          }, 200);
        }

        toast('Option successfully update', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        if (setLoading) setLoading(false);
        dispatch({
          type: STOP_OPTIONS_REQUEST,
        });
      });
  };

export const addSelection: AppThunk =
  ({ optionId, newSelection, setOpen, resetFields }: AddSelectionProps) =>
  async (dispatch: Dispatch<OptionsAction>) => {
    dispatch({
      type: START_OPTIONS_REQUEST,
    });

    const body: AddSelectionBody = {
      option_id: optionId,
      default_value: false,
      price: +newSelection.price,
      name: newSelection.name,
    };

    API.v1.options
      .createSelection(body)
      .then(() => {
        //FIXME: doesn't work from back
        // const oldOptions = store.getState().options.optionsApi || [];
        // const newOptions = oldOptions.map((option) => {
        //   if (option.id === newSelection.option_id) {
        //     return {
        //       ...option,
        //       selections: [...option.selections, newSelection],
        //     };
        //   } else {
        //     return option;
        //   }
        // });

        // // @ts-ignore
        // dispatch(updateReducerOptions(newOptions));

        // @ts-ignore
        dispatch(getOptionsApi());
        if (setOpen) {
          setOpen(false);
        }
        if (resetFields) {
          setTimeout(() => {
            resetFields();
          }, 200);
        }
        toast('Selection successfully added', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_OPTIONS_REQUEST,
        });
      });
  };

export const editSelection: AppThunk =
  ({ selectionId, data, setOpen, resetFields }: EditSelectionProps) =>
  async (dispatch: Dispatch<OptionsAction>) => {
    dispatch({
      type: START_SUB_OPTIONS_REQUEST,
    });

    API.v1.options
      .editSelection(selectionId, data)
      .then(({ data: newSelection }) => {
        const oldOptions = store.getState().options.optionsApi || [];
        const newOptions = oldOptions.map((option) => {
          if (option.id === newSelection.option_id) {
            return {
              ...option,
              selections: option.selections.map((selection) => {
                if (selection.id === selectionId) {
                  return newSelection;
                } else {
                  return selection;
                }
              }),
            };
          } else {
            return option;
          }
        });

        // @ts-ignore
        dispatch(updateReducerOptions(newOptions));

        if (setOpen) {
          setOpen(false);
        }
        if (resetFields) {
          setTimeout(() => {
            resetFields();
          }, 200);
        }

        toast('Selection successfully update', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_SUB_OPTIONS_REQUEST,
        });
      });
  };

export const editDefaultSelection: AppThunk =
  ({ data, optionId, selectionId, setWasClicked }: EditDefaultSelectionProps) =>
  async (dispatch: Dispatch<OptionsAction>) => {
    dispatch({
      type: START_SUB_OPTIONS_REQUEST,
    });

    API.v1.options
      .editDefaultSelection(data)
      .then(() => {
        const oldOptions = store.getState().options.optionsApi;
        const newOptions = oldOptions?.map((option) => {
          if (option?.id === optionId) {
            const newSelection = option?.selections?.map((selection) => {
              if (selection?.id === selectionId) {
                return {
                  ...selection,
                  default_value: true,
                };
              } else {
                return {
                  ...selection,
                  default_value: false,
                };
              }
            });
            const newOption = {
              ...option,
              selections: newSelection,
            };
            return newOption;
          } else {
            return option;
          }
        });

        // @ts-ignore
        dispatch(updateReducerOptions(newOptions));

        if (setWasClicked) setWasClicked(true);
        toast('Selection successfully update', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_SUB_OPTIONS_REQUEST,
        });
      });
  };

export const deleteSelection: AppThunk =
  ({ id, setOpen, optionId }: DeleteSelectionProps) =>
  async (dispatch: Dispatch<OptionsAction>) => {
    dispatch({
      type: START_SUB_OPTIONS_REQUEST,
    });

    API.v1.options
      .deleteSelection(id)
      .then(() => {
        const oldOptions = store.getState().options.optionsApi || [];
        const newOptions = oldOptions.map((option) => {
          if (option.id === optionId) {
            return {
              ...option,
              selections: option.selections.filter((selection) => selection.id !== id),
            };
          } else {
            return option;
          }
        });

        // @ts-ignore
        dispatch(updateReducerOptions(newOptions));

        setOpen(false);
        toast('Selection successfully deleted', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_SUB_OPTIONS_REQUEST,
        });
      });
  };

export const updateItemOptionsRelations: AppThunk =
  ({ entityId, data, setOpen }: updateItemOptionsRelationsProps) =>
  async (dispatch: Dispatch<OptionsAction>) => {
    dispatch({
      type: START_SUB_OPTIONS_REQUEST,
    });

    const body: any = {
      entity_type: 'item',
      relations: data,
    };

    API.v1.options
      .updateItemOptionsRelations(entityId, body)
      .then(() => {
        if (setOpen) setOpen(false);

        // FIXME: maybe unnecessary fetch
        dispatch(
          // @ts-ignore
          setActiveItem({
            id: entityId,
          }),
        );
        // @ts-ignore
        dispatch(getOptionsApi());

        toast('Option successfully updated', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_SUB_OPTIONS_REQUEST,
        });
      });
  };

export const changeStatusOption: AppThunk =
  ({ newStatus, mode, id, setEnabled, optionId, setOpen }: ChangeStatusOptionProps) =>
  async (dispatch: Dispatch<OptionsAction>) => {
    dispatch({
      type: START_SUB_OPTIONS_REQUEST,
    });

    const body: ChangeStatusOptionBody = {
      status: newStatus,
    };

    API.v1.options
      .changeStatusOption(mode, id, body)
      .then(({ data }) => {
        if (mode === 'selection') {
          const oldOptions = store.getState().options.optionsApi || [];
          const newOptions = oldOptions?.map((option) => {
            if (option?.id === optionId) {
              const newSelection = option?.selections?.map((selection) => {
                if (selection?.id === id) {
                  return {
                    ...selection,
                    status: newStatus,
                  };
                } else {
                  return selection;
                }
              });
              const newOption = {
                ...option,
                selections: newSelection,
              };
              return newOption;
            } else {
              return option;
            }
          });

          // @ts-ignore
          dispatch(updateReducerOptions(newOptions));

          if (setOpen) setOpen(false);
        } else if (mode === 'option') {
          const oldOptions = store.getState().options.optionsApi || [];
          const newOptions = oldOptions?.map((option) => {
            if (option?.id === id) {
              return {
                ...option,
                status: data.status,
              };
            } else {
              return option;
            }
          });

          // @ts-ignore
          dispatch(updateReducerOptions(newOptions));
        }

        if (setEnabled) {
          setEnabled(newStatus === 1 ? true : false);
        }

        toast(mode[0].toUpperCase() + mode.substring(1) + ' successfully updated', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_SUB_OPTIONS_REQUEST,
        });
      });
  };
