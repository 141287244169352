export const CHANGE_CATEGORIES_ORDER = 'CHANGE_CATEGORIES_ORDER';

export const SET_ACCOUNT_INFO = 'SET_ACCOUNT_INFO';
export const START_SET_ACCOUNT_INFO = 'START_SET_ACCOUNT_INFO';
export const STOP_SET_ACCOUNT_INFO = 'STOP_SET_ACCOUNT_INFO';

export const START_CHANGE_PASSWORD = 'START_CHANGE_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const START_SUB_LOADING = 'START_SUB_LOADING';
export const STOP_SUB_LOADING = 'STOP_SUB_LOADING';

export const RESET_ACCOUNT = 'RESET_ACCOUNT';

export const START_CHANGE_FLAG_WITH_ORDER = 'CHANGE_FLAG_WITH_ORDER';
export const STOP_CHANGE_FLAG_WITH_ORDER = 'STOP_CHANGE_FLAG_WITH_ORDER';

export const UPDATE_COGNITO_USER = 'UPDATE_COGNITO_USER';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
