import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { oldLanguages } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { Fragment } from 'react';

interface CategoryDropdownProps {
  categoryList: any;
  setChangeCategyValue: SetStateType<string>;
  changeCategyValue: string;
  className?: string;
  disabled?: boolean;
  globalMode?: boolean;
}

export const CategoryDropdown = ({
  categoryList,
  setChangeCategyValue,
  changeCategyValue,
  className = '',
  disabled = false,
  globalMode = false,
}: CategoryDropdownProps) => {
  const locale = useAppSelector((state) => state.locale.lang);

  return (
    <Listbox
      value={changeCategyValue}
      disabled={disabled}
      onChange={setChangeCategyValue}
    >
      {({ open }) => (
        <>
          <div className={`relative mt-1 ${className}`}>
            <Listbox.Button
              as="div"
              className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm md:focus:border-indigo-500 md:focus:outline-none md:focus:ring-1 md:focus:ring-indigo-500 sm:text-sm"
            >
              <span className="flex items-center">
                <span
                  className={classNames(
                    categoryList.find((item: any) => item.id === changeCategyValue)
                      ?.status === 1
                      ? 'bg-green-500'
                      : categoryList.find((item: any) => item.id === changeCategyValue)
                          ?.status === (globalMode ? 2 : 3)
                      ? 'bg-gray-500'
                      : '',
                    'inline-block h-2 w-2 flex-shrink-0 rounded-full',
                  )}
                />
                <span className="ltr:ml-3 rtl:mr-3 block truncate">
                  {
                    // @ts-ignore
                    categoryList.find((item) => item.id === changeCategyValue)?.name
                      ?.translations[oldLanguages[locale]]
                  }
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="scrollbar absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none sm:text-sm">
                {categoryList.map((item: any) => (
                  <Listbox.Option
                    key={'choose-category-' + item.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      )
                    }
                    value={item.id}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              item.status === 1
                                ? 'bg-green-500'
                                : item.status === (globalMode ? 2 : 3)
                                ? 'bg-gray-500'
                                : '',
                              'inline-block h-2 w-2 flex-shrink-0 rounded-full rtl:mr-2',
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ltr:ml-3 rtl:mr-3 block truncate',
                            )}
                          >
                            {item.name?.translations
                              ? item.name?.translations[oldLanguages[locale]]
                              : ''}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
