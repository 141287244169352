import { useAppSelector } from 'hooks/store';
import { FormattedNumber } from 'react-intl';

interface AddOptionLastStepProps {
  optionName: string;
  selections: any;
  multiChoice: boolean;
}

export const AddOptionLastStep = ({
  optionName,
  selections,
  multiChoice,
}: AddOptionLastStepProps) => {
  const account = useAppSelector((state) => state.account.account);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);

  return (
    <div className="mb-3">
      <div className="flex flex-col mb-2 px-1 max-w-xs">
        <p className="text-lg rtl:text-right font-medium text-gray-700 mb-3">
          {optionName}
        </p>

        {Object.keys(selections).map((item) => {
          return (
            <div
              key={'selection-translate-preview-' + item}
              className="items-center grid grid-cols-[1fr_4fr] space-x-3 rtl:space-x-reverse max-w-sm w-full mb-2"
            >
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    checked={multiChoice ? false : selections[item]?.default_value}
                    type={multiChoice ? 'checkbox' : 'radio'}
                    disabled={true}
                    className={`h-4 w-4 ${
                      multiChoice ? 'rounded' : ' rounded-full'
                    } border-gray-300 text-indigo-600 focus:ring-indigo-500`}
                  />
                </div>
                <div className="ltr:ml-3 rtl:mr-3 text-sm">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700 truncate"
                  >
                    {selections[item].name}
                  </label>
                </div>
              </div>

              <div className="flex items-center text-sm text-gray-500 font-medium">
                {selections[item].price !== 0 && (
                  <div>
                    +{/*eslint-disable-next-line react/style-prop-object*/}
                    <FormattedNumber
                      currency={account?.currency}
                      maximumFractionDigits={intCurrency ? 0 : 2}
                      style="currency"
                      value={selections[item].price}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
