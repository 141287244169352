import { InformationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { weekDays } from 'appConstants';
import { Availability, SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { editItemField } from 'store/item/actions';

interface ChangeAvailableModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  availability: Availability | undefined;
  globalCategoryId: string;
}

interface RouteParams {
  id: string;
}

export const ChangeAvailableModal = ({
  open,
  setOpen,
  availability,
  globalCategoryId,
}: ChangeAvailableModalProps) => {
  const { id } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const loadingImages = useAppSelector((state) => state.item.subLoading);
  const [discardClick, setDiscardClick] = useState(0);

  const [availabilityLocal, setAvailabilityLocal] = useState(availability?.days);
  const [timeLocal, setTimeLocal] = useState(() =>
    availability?.time?.map((elem, index) => {
      const timeStart = elem.split('-')[0];
      const timeEnd = elem.split('-')[1];
      return {
        timeStart: timeStart.slice(0, 2) + ':' + timeStart.slice(2, 4),
        timeEnd: timeEnd.slice(0, 2) + ':' + timeEnd.slice(2, 4),
        id: index,
      };
    }),
  );
  const [everyTime, setEverytime] = useState(true);
  const [everyDay, setEveryday] = useState(true);

  useEffect(() => {
    if (everyTime) {
      setTimeLocal([{ timeStart: '00:00', timeEnd: '00:00', id: 0 }]);
    }
    if (everyDay) {
      setAvailabilityLocal([1, 2, 3, 4, 5, 6, 7]);
    }
  }, [everyTime, everyDay]);

  useEffect(() => {
    if (availabilityLocal?.length === 7) {
      setEveryday(true);
    } else {
      setEveryday(false);
    }

    if (timeLocal?.length === 1 && timeLocal[0].timeStart === timeLocal[0].timeEnd) {
      setEverytime(true);
    } else {
      setEverytime(false);
    }
  }, []);

  const checkAvailabilityChange = () => {
    const newTime = timeLocal?.map((elem) => {
      return elem.timeStart.replace(':', '') + '-' + elem.timeEnd.replace(':', '');
    });
    return (
      JSON.stringify(availabilityLocal) !== JSON.stringify(availability?.days) ||
      JSON.stringify(newTime) !== JSON.stringify(availability?.time)
    );
  };

  useEffect(() => {
    setAvailabilityLocal(availability?.days);
    setTimeLocal(() =>
      availability?.time?.map((elem, index) => {
        const timeStart = elem.split('-')[0];
        const timeEnd = elem.split('-')[1];
        return {
          timeStart: timeStart.slice(0, 2) + ':' + timeStart.slice(2, 4),
          timeEnd: timeEnd.slice(0, 2) + ':' + timeEnd.slice(2, 4),
          id: index,
        };
      }),
    );
  }, [open]);

  return (
    <AppModal size="sm:max-w-xl" open={open}>
      <div className="flex flex-col">
        <div className="flex w-full items-center justify-between mb-3">
          <h1>
            <FormattedMessage id="availability" />
          </h1>
          <XMarkIcon
            onClick={() => {
              if (
                JSON.stringify(availabilityLocal) === JSON.stringify(availability?.days)
              ) {
                setOpen(false);
                setDiscardClick(0);
              } else {
                if (discardClick === 0) {
                  setDiscardClick(1);
                } else if (discardClick === 1) {
                  setDiscardClick(0);
                  setAvailabilityLocal(availability?.days);
                  setOpen(false);
                }
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div>
          <div className="py-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ltr:ml-3 rtl:text-right rtl:mr-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">
                  <FormattedMessage id="timeDaysSub" />
                </p>
              </div>
            </div>
          </div>
          <div className="w-full flex items-start md:items-center flex-col">
            <div className="flex items-center mb-4">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="allDays"
                    name="allDays"
                    type="checkbox"
                    checked={everyDay}
                    onChange={(e) => setEveryday(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-2 text-sm leading-6">
                  <label
                    htmlFor="allDays"
                    className="font-medium text-gray-900 select-none"
                  >
                    <FormattedMessage id="everyDay" />
                  </label>
                </div>
              </div>
              <div className="relative flex items-start ml-5">
                <div className="flex h-6 items-center">
                  <input
                    id="allDay"
                    name="allDay"
                    type="checkbox"
                    checked={everyTime}
                    onChange={(e) => setEverytime(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-2 text-sm leading-6">
                  <label
                    htmlFor="allDay"
                    className="font-medium text-gray-900 select-none"
                  >
                    <FormattedMessage id="everyTime" />
                  </label>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                everyDay ? 'opacity-60' : '',
                'overflow-auto flex items-center scrollbar max-w-full pb-1',
              )}
            >
              {weekDays.map((item, index) => (
                <div
                  role="button"
                  tabIndex={0}
                  key={'week-days-button-' + index}
                  onClick={() => {
                    setAvailabilityLocal((prev) => {
                      if (prev && prev.includes(item.id) && prev.length > 1) {
                        return prev.filter((elem) => elem !== item.id);
                      } else if (prev && !prev.includes(item.id)) {
                        return [...prev, item.id];
                      } else {
                        return prev;
                      }
                    });
                  }}
                  className={classNames(
                    'flex items-center relative justify-center flex-shrink-0 w-[60px] h-[60px] border rounded-full font-medium text-center cursor-pointer ltr:mr-3 rtl:ml-3 rtl:last:ml-0 ltr:last:mr-0 mt-2 md:mt-0',
                    availabilityLocal?.includes(item.id)
                      ? 'border-primary bg-primary bg-opacity-10 text-primary'
                      : 'border-gray-300 bg-white text-gray-500',
                  )}
                >
                  {item.short}
                </div>
              ))}
            </div>
            <div
              className={classNames(
                everyTime ? 'opacity-60' : '',
                'flex flex-col max-w-full max-h-96 overflow-auto scrollbar mt-4',
              )}
            >
              {timeLocal?.map((item, index) => (
                <div
                  className="flex max-w-full items-center first:mt-0 mt-4 flex-nowrap pt-2"
                  key={'time-period-' + index}
                >
                  <div className="border rounded-lg border-gray-300 relative ltr:mr-3 rtl:ml-3">
                    <input
                      type="time"
                      name="start-time"
                      id={'start-time' + item.id}
                      disabled={everyTime}
                      placeholder="00:00"
                      value={item.timeStart}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                          setTimeLocal((prev) => {
                            if (prev) {
                              const newState = [...prev];
                              newState.forEach((elem) => {
                                if (elem.id === item.id) {
                                  elem.timeStart = value;
                                }
                              });
                              return newState;
                            } else {
                              return prev;
                            }
                          });
                        }
                      }}
                      className="border-none rounded-lg"
                    />
                    <div className="text-gray-400 text-xs absolute -top-[9px] bg-white ltr:left-2 rtl:right-2 px-[2px] whitespace-nowrap">
                      {/* <FormattedMessage id="startTime" /> */}
                      start time
                    </div>
                  </div>
                  <div
                    className={`border rounded-lg border-gray-300 relative ltr:mr-6 rtl:ml-6 `}
                  >
                    <input
                      type="time"
                      name="end-time"
                      id={'end-time' + item.id}
                      disabled={everyTime}
                      placeholder="00:00"
                      value={item.timeEnd}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value) {
                          setTimeLocal((prev) => {
                            if (prev) {
                              const newState = [...prev];
                              newState.forEach((elem) => {
                                if (elem.id === item.id) {
                                  elem.timeEnd = value;
                                }
                              });
                              return newState;
                            } else {
                              return prev;
                            }
                          });
                        }
                      }}
                      className="border-none rounded-lg"
                    />
                    <div className="text-gray-400 text-xs absolute -top-[9px] bg-white ltr:left-2 rtl:right-2 px-[2px] whitespace-nowrap">
                      {/* <FormattedMessage id="endTime" /> */}
                      end time
                    </div>
                  </div>
                  {index === 0 && (
                    <button
                      disabled={everyTime}
                      onClick={() => {
                        setTimeLocal((prev) => {
                          if (prev) {
                            return [
                              ...prev,
                              {
                                timeStart: '00:00',
                                timeEnd: '00:00',
                                id: prev[prev.length - 1].id + 1,
                              },
                            ];
                          } else {
                            return prev;
                          }
                        });
                      }}
                      className="text-gray-500 whitespace-nowrap rounded-full self-stretch px-4 py-2.5 sm:mt-0 sm:py-0 bg-gray-100 md:hover:bg-gray-200 md:hover:text-gray-600"
                    >
                      +<FormattedMessage id="addTimePeriod" />
                    </button>
                  )}
                  {index !== 0 && (
                    <button
                      disabled={everyTime}
                      onClick={() => {
                        setTimeLocal((prev) => {
                          return prev?.filter((elem) => elem.id !== item.id);
                        });
                      }}
                    >
                      <XCircleIcon className="h-6 w-6 text-gray-400 md:hover:text-gray-500" />
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex w-full justify-end mt-3">
          <div className="flex items-center space-x-3 rtl:space-x-reverse">
            {checkAvailabilityChange() ? (
              <div className="flex items-center">
                <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
                  {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
                </div>
                <div className="flex items-center justify-end w-full sm:w-auto">
                  <button
                    onClick={() => {
                      if (discardClick === 0) {
                        setDiscardClick(1);
                      } else if (discardClick === 1) {
                        setDiscardClick(0);
                        setAvailabilityLocal(availability?.days);
                        setOpen(false);
                      }
                    }}
                    type="button"
                    className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                  >
                    <FormattedMessage id="discardChanges" />
                  </button>
                  <button
                    onClick={() => {
                      setDiscardClick(0);
                      dispatch(
                        editItemField({
                          id: id,
                          value: {
                            days: availabilityLocal,
                            time: timeLocal?.map((item) => {
                              return (
                                item.timeStart.split(':').join('') +
                                '-' +
                                item.timeEnd.split(':').join('')
                              );
                            }),
                          },
                          field: 'availability',
                          setOpen: setOpen,
                          globalCategoryId,
                        }),
                      );
                    }}
                    type="button"
                    className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                  >
                    {loadingImages ? (
                      <Spinner color="bg-white" />
                    ) : (
                      <FormattedMessage id="save" />
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <button
                onClick={() => {
                  setOpen(false);
                  setDiscardClick(0);
                }}
                type="button"
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="cancel" />
              </button>
            )}
          </div>
        </div>
      </div>
    </AppModal>
  );
};
