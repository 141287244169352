import { API } from 'api';
import { validatePrice } from 'helpers/validation';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { SUCCESS_FETCH_CATEGORY } from 'store/category/actionTypes';
import { AppThunk } from 'store/globalTypes';
import { SET_IMAGES_ITEM } from 'store/images/actionTypes';
import { getNewValueByCategoryList, getRestaurantMenu } from 'store/menu/actions';

import { allergens, defaultToastProps } from '../../appConstants';
import { store } from '..';
import {
  ADD_ITEM_REQUEST,
  ADD_ITEM_SUCCESS,
  EDIT_ITEM_FIELD,
  SET_ACTIVE_ITEM,
  START_ACTIVE_REQUEST,
  START_SUB_ITEM_LOADING,
  STOP_ACTIVE_REQUEST,
  STOP_ADD_ITEM,
  STOP_SUB_ITEM_LOADING,
} from './actionsTypes';
import {
  ActiveItemAction,
  AddItemProps,
  DeleteItemProps,
  EditItemProps,
  EditSuggestionsProps,
  EditSwithItemFieldProps,
} from './types';

export const setActiveItem: AppThunk =
  ({ id }: { id: string }) =>
  async (dispatch: Dispatch<ActiveItemAction>) => {
    dispatch({
      type: START_ACTIVE_REQUEST,
    });
    API.v1.item
      .getItemById(id)
      .then(({ data }) => {
        dispatch({
          type: SET_ACTIVE_ITEM,
          payload: data,
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
        dispatch({
          type: STOP_ACTIVE_REQUEST,
        });
      });
  };

export const addItem: AppThunk =
  ({ setActiveStep, setItemCreated, setShouldImageUpload, globalMenuId }: AddItemProps) =>
  async (dispatch) => {
    dispatch({
      type: ADD_ITEM_REQUEST,
    });
    try {
      const createItem = store.getState().item.addedItem;

      if (
        !createItem.name ||
        !createItem.name.source_lang ||
        !createItem.name.translations
      ) {
        toast('Please fill in the name field', {
          ...defaultToastProps,
          type: 'warning',
        });
        return;
      }
      if (!validatePrice(createItem.price)) {
        toast('Please fill in the price field', {
          ...defaultToastProps,
          type: 'warning',
        });
        return;
      }
      if (createItem.allergens) {
        Object.keys(allergens).forEach((key) => {
          createItem.allergens[key] = createItem.allergens[key]
            ? createItem.allergens[key]
            : allergens[key as keyof typeof allergens];
        });
      }

      const newAvailability = {
        days: createItem.availability.days,
        time: createItem.availability.time.map((time: any) => {
          return (
            time.timeStart.split(':').join('') + '-' + time.timeEnd.split(':').join('')
          );
        }),
      };

      const newDescription =
        Object.keys(createItem?.description?.translations)?.length > 0
          ? createItem.description
          : {};

      const newItem = {
        name: createItem.name,
        description: newDescription,
        price: createItem.price,
        category_id: createItem.category,
        availability: newAvailability,
        allergens: createItem.allergens ? createItem.allergens : allergens, //
        suggestions: createItem.suggestions.length > 0 ? createItem.suggestions : [],
        ststus: 1,
        options: createItem.options.length > 0 ? createItem.options : [],
        labels: createItem?.labels,
        quantity: createItem.quantity || {},
      };

      const { data: item } = await API.v1.item.createItem(newItem);
      const oldCategoryList =
        store.getState().category?.activeCategory.categoryList[globalMenuId] || [];

      const newCategoryList = oldCategoryList.map((category) => {
        if (category?.id === item?.category_id) {
          return {
            ...category,
            items: [...(category.items || []), item],
          };
        } else {
          return category;
        }
      });
      const [newCategoryListObj, beutifyMenu] = getNewValueByCategoryList({
        newCategoryList,
      });
      dispatch({
        type: SUCCESS_FETCH_CATEGORY,
        payload: {
          categoryList: {
            [globalMenuId]: newCategoryList,
          },
          categoryListObj: {
            [globalMenuId]: newCategoryListObj,
          },
          beutifyMenu,
        },
      });

      dispatch({
        type: EDIT_ITEM_FIELD,
        payload: {
          field: 'id',
          value: item.id,
        },
      });
      setActiveStep(6);
      toast('Item added', {
        ...defaultToastProps,
        type: 'success',
      });
      setItemCreated(true);
      if (setShouldImageUpload) setShouldImageUpload(true);
      dispatch({
        type: ADD_ITEM_SUCCESS,
      });
    } catch (error) {
      if (error instanceof Error) {
        toast(error.message, {
          ...defaultToastProps,
          type: 'error',
        });
      } else if (typeof error === 'string') {
        toast(error, {
          ...defaultToastProps,
          type: 'error',
        });
      }
      dispatch({
        type: STOP_ADD_ITEM,
      });
    }
  };

export const editSwithItemField: AppThunk =
  ({ id, data, setEnabled, setLoading }: EditSwithItemFieldProps) =>
  async () => {
    setLoading(true);
    API.v1.item
      .changeItemAttribute(id, data)
      .then(() => {
        setEnabled((prev) => !prev);
        toast('Item successfully updated', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

export const editSuggestions: AppThunk =
  ({ suggestions, item, setOpen }: EditSuggestionsProps) =>
  async (dispatch) => {
    //dispatch({ type: START_FETCH_IMAGES });
    dispatch({
      type: START_SUB_ITEM_LOADING,
    });
    API.v1.suggestions
      .changeSuggestions(item.id, suggestions)
      .then(() => {
        dispatch(getRestaurantMenu());
        dispatch(
          setActiveItem({
            id: item.id,
          }),
        );
        dispatch({
          type: SET_IMAGES_ITEM,
          payload: [],
        });
        toast('Suggestions successfully changed', {
          ...defaultToastProps,
          type: 'success',
        });
        if (setOpen) setOpen(false);
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_SUB_ITEM_LOADING,
        });
      });
  };

export const deleteItem: AppThunk =
  ({ itemId, setOpen, history, globalMenuId, categoryId }: DeleteItemProps) =>
  async (dispatch) => {
    //dispatch({ type: START_FETCH_IMAGES });
    dispatch({ type: START_SUB_ITEM_LOADING });

    API.v1.item
      .deleteItem(itemId)
      .then(() => {
        dispatch({
          type: SET_IMAGES_ITEM,
          payload: [],
        });
        const oldCategoryList =
          store.getState().category?.activeCategory.categoryList[globalMenuId] || [];
        const newCategoryList = oldCategoryList.map((category) => {
          if (category?.id === categoryId) {
            return {
              ...category,
              items: category.items?.filter((dish) => dish?.id !== itemId),
            };
          } else {
            return category;
          }
        });
        const [newCategoryListObj, beutifyMenu] = getNewValueByCategoryList({
          newCategoryList,
        });

        dispatch({
          type: SUCCESS_FETCH_CATEGORY,
          payload: {
            categoryList: {
              [globalMenuId]: newCategoryList,
            },
            categoryListObj: {
              [globalMenuId]: newCategoryListObj,
            },
            beutifyMenu,
          },
        });
        toast('Item successfully deleted', {
          ...defaultToastProps,
          type: 'success',
        });
        setOpen(false);
        history.push('/menu');
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_SUB_ITEM_LOADING,
        });
      });
  };

export const editItemField: AppThunk =
  ({
    value,
    field,
    id,
    setOpen,
    globalCategoryId,
    oldGlobalCategoryId = '',
    oldCategoryId,
    menuPage = false,
  }: EditItemProps) =>
  async (dispatch) => {
    dispatch({ type: START_SUB_ITEM_LOADING });
    const body = {
      [field]: value,
    };

    API.v1.item
      .changeItemAttribute(id, body)
      .then(({ data: newItem }) => {
        const updateCategoryList =
          store.getState().category?.activeCategory.categoryList[globalCategoryId] || [];

        let newCategoryList = updateCategoryList;

        if (field === 'category_id') {
          const categoryListObj =
            store.getState().category.activeCategory.categoryListObj;
          const categoryObjArray = Object.values(categoryListObj);
          const oldProductMenuObj = categoryObjArray.find(
            (category) => oldCategoryId in category,
          );
          const newProductMenuObj = categoryObjArray.find(
            (category) => value in category,
          );
          let oldProductCategory;
          if (oldProductMenuObj) {
            oldProductCategory = oldProductMenuObj[oldCategoryId];
          }

          if (oldProductCategory && globalCategoryId !== oldGlobalCategoryId) {
            const oldMenuList =
              store.getState().category?.activeCategory.categoryList[
                oldProductCategory.menu_id
              ] || [];
            let newMenuList = oldMenuList;
            newMenuList = oldMenuList.map((category) => {
              if (category?.id === oldCategoryId) {
                return {
                  ...category,
                  items: category.items?.filter((dish) => dish?.id !== id),
                };
              } else if (category?.id === newItem?.category_id) {
                return {
                  ...category,
                  items: [...(category.items || []), newItem],
                };
              } else {
                return category;
              }
            });

            const [newCategoryListObj, beutifyMenu] = getNewValueByCategoryList({
              newCategoryList: newMenuList,
            });

            dispatch({
              type: SUCCESS_FETCH_CATEGORY,
              payload: {
                categoryList: {
                  [oldGlobalCategoryId]: newMenuList,
                },
                categoryListObj: {
                  [oldGlobalCategoryId]: newCategoryListObj,
                },
                beutifyMenu,
              },
            });
          }

          let newProductCategory;
          if (newProductMenuObj) {
            newProductCategory = newProductMenuObj[value];
          }

          if (newProductCategory) {
            const newMenuList =
              store.getState().category?.activeCategory.categoryList[
                newProductCategory.menu_id
              ] || [];

            newCategoryList = newMenuList.map((category) => {
              if (category?.id === oldCategoryId) {
                return {
                  ...category,
                  items: category.items?.filter((dish) => dish?.id !== id),
                };
              } else if (category?.id === value) {
                return {
                  ...category,
                  items: [...(category?.items || []), newItem],
                };
              } else {
                return category;
              }
            });
          }
        } else {
          newCategoryList = updateCategoryList.map((category) => {
            if (category?.id === newItem?.category_id) {
              return {
                ...category,
                items: category?.items?.map((item) => {
                  if (item?.id === newItem?.id) {
                    return newItem;
                  } else {
                    return item;
                  }
                }),
              };
            } else {
              return category;
            }
          });
        }

        const [newCategoryListObj, beutifyMenu] = getNewValueByCategoryList({
          newCategoryList,
        });

        dispatch({
          type: SUCCESS_FETCH_CATEGORY,
          payload: {
            categoryList: {
              [globalCategoryId]: newCategoryList,
            },
            categoryListObj: {
              [globalCategoryId]: newCategoryListObj,
            },
            beutifyMenu,
          },
        });

        if (menuPage) {
          dispatch({
            type: SET_ACTIVE_ITEM,
            payload: newItem,
          });
        } else {
          dispatch(
            setActiveItem({
              id: id,
            }),
          );
        }

        setOpen(false);
        dispatch({
          type: SET_IMAGES_ITEM,
          payload: [],
        });
        toast('Item successfully updated', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_SUB_ITEM_LOADING,
        });
      });
  };
