import { Crumbs } from 'commonTypes';
import { Spinner } from 'components/Common/Spinner';
import {
  ActiveCategoryList,
  ActiveGlobalMenuHeader,
  ActiveItemHeader,
  AddCategoryModal,
  ChangeCategoryOrder,
  GlobalMenusSection,
  MenuSideBar,
  MenuTabs,
} from 'components/MenuComponents';
import { checkLocalActiveCategory, checkLocalActiveMenu } from 'helpers/menu';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Layout } from 'pages/Layout/Layout';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { setActiveCategoryRedux } from 'store/category/actions';
import { getRestaurantMenu } from 'store/menu/actions';
import { Items } from 'store/menu/types';

const crumbs: Crumbs[] = [{ name: 'Menu', href: '/menu', current: true }];

export const Menu = () => {
  const dispatch = useAppDispatch();
  const menu = useAppSelector((state) => state.menu.menu);
  const loading = useAppSelector((state) => state.menu.loading);
  const loadingAccount = useAppSelector((state) => state.account.loading);
  const account = useAppSelector((state) => state.account.account);
  const categoryList = useAppSelector(
    (state) => state.category.activeCategory.categoryList,
  );
  const categoryListObj = useAppSelector(
    (state) => state.category.activeCategory.categoryListObj,
  );
  const loadingCategory = useAppSelector((state) => state.category.loading);
  const [activeGlobalMenu, setActiveGlobalMenu] = useState('');
  const [activeCategory, setActiveCategory] = useState('');
  const [isOpenAddCategory, setIsOpenAddCategory] = useState(false);
  const [isOpenOrder, setIsOpenOrder] = useState(false);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [itemsList, setItemsList] = useState<Items[]>([]);
  const [tabs, setTabs] = useState(categoryList[activeGlobalMenu]);
  const [enableGlobalMenus, setEnableGlobalMenus] = useState(false);

  useEffect(() => {
    if (activeGlobalMenu && !!categoryListObj[activeGlobalMenu]) {
      setItemsList(categoryListObj[activeGlobalMenu][activeCategory]?.items || []);
    }
  }, [activeCategory, categoryListObj, activeGlobalMenu, categoryList]);

  useEffect(() => {
    setTabs(categoryList[activeGlobalMenu]);
  }, [activeGlobalMenu, categoryList]);

  useEffect(() => {
    if (account) {
      setEnableGlobalMenus(account?.with_global_menus);
    }
  }, [account]);

  useEffect(() => {
    if (!activeGlobalMenu && !!account?.id && !!menu[0]?.id) {
      const localeGlobalMenu = checkLocalActiveMenu({ menu, account });
      setActiveGlobalMenu(localeGlobalMenu);
      localStorage.setItem('activeGlobalMenu-' + account?.id, localeGlobalMenu);
      dispatch(
        setActiveCategoryRedux({
          globalCategoryId: localeGlobalMenu,
          backGround: true,
        }),
      );
    }
  }, [menu, account]);

  useEffect(() => {
    if (activeGlobalMenu && !!categoryList[activeGlobalMenu]?.length && !!account?.id) {
      const localeActiveCategory = checkLocalActiveCategory({
        activeGlobalMenu,
        account,
        categoryList,
      });
      setActiveCategory(localeActiveCategory);
      localStorage.setItem(
        'activeCategory-' + activeGlobalMenu + '-' + account?.id,
        localeActiveCategory,
      );
    }
  }, [activeGlobalMenu, categoryList, account]);

  useEffect(() => {
    if (!menu?.length) {
      dispatch(getRestaurantMenu());
    }
  }, []);

  return (
    <Layout crumbs={crumbs}>
      {loading || loadingAccount ? (
        <Spinner color="bg-primary" />
      ) : (
        <>
          <GlobalMenusSection
            enableGlobalMenus={enableGlobalMenus}
            setEnableGlobalMenus={setEnableGlobalMenus}
            setActiveGlobalMenu={setActiveGlobalMenu}
            activeGlobalMenu={activeGlobalMenu}
          />
          <div className="w-full flex items-start justify-between flex-wrap ipad:flex-nowrap">
            <div className="w-full ipad:w-2/6 ipad:max-w-[372px] ipad:min-w-[300px]">
              {enableGlobalMenus && (
                <ActiveGlobalMenuHeader
                  activeGlobalMenu={activeGlobalMenu}
                  setActiveGlobalMenu={setActiveGlobalMenu}
                />
              )}
              <div className="hidden ipad:block bg-white rounded-lg shadow p-4 mb-4">
                <MenuSideBar
                  setActiveCategory={setActiveCategory}
                  activeCategory={activeCategory}
                  isOpen={isOpenSidebar}
                  setIsOpen={setIsOpenSidebar}
                  activeGlobalMenu={activeGlobalMenu}
                />
              </div>
            </div>
            {loadingCategory || !categoryListObj[activeGlobalMenu] ? (
              <div className="w-full flex items-center justify-center py-10">
                <Spinner color="bg-primary" />
              </div>
            ) : (
              <>
                <div className="flex ipad:hidden w-full bg-white rounded-lg shadow p-4 mb-4 flex-col">
                  <button
                    onClick={() => setIsOpenAddCategory(true)}
                    type="button"
                    data-testId="cypress-add-category-btn-mobile"
                    className="mb-3 inline-flex justify-center items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 md:hover:bg-indigo-200 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                  >
                    <FormattedMessage id="addCategory" />
                  </button>

                  <button
                    type="button"
                    onClick={() => setIsOpenOrder(true)}
                    className="mb-3 inline-flex justify-center items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 md:hover:bg-indigo-200 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                  >
                    <FormattedMessage id="changeOrder" />
                  </button>
                  {!!tabs?.length && (
                    <div className="w-full overflow-auto scrollbar ">
                      <MenuTabs
                        activeCategory={activeCategory}
                        setActiveCategory={setActiveCategory}
                        activeGlobalMenu={activeGlobalMenu}
                        tabs={tabs}
                      />
                    </div>
                  )}
                </div>
                <div className="w-full bg-white rounded-lg shadow p-4 ipad:ltr:ml-4 ipad:rtl:mr-4">
                  <ActiveItemHeader
                    activeCategory={categoryListObj[activeGlobalMenu][activeCategory]}
                    setActiveCategory={setActiveCategory}
                    categoryList={categoryList[activeGlobalMenu]}
                    itemsList={itemsList}
                    setItemsList={setItemsList}
                    activeGlobalMenu={activeGlobalMenu}
                  />
                  <ActiveCategoryList
                    activeCategory={activeCategory}
                    categoryListObj={categoryListObj[activeGlobalMenu]}
                    setIsOpen={setIsOpenSidebar}
                    itemsList={itemsList}
                    emptyAccount={
                      categoryList[activeGlobalMenu]?.length === 1 &&
                      categoryListObj[activeGlobalMenu][activeCategory]?.items?.length ===
                        0
                    }
                    globalMenuId={activeGlobalMenu}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}

      <AddCategoryModal
        open={isOpenAddCategory}
        setOpen={setIsOpenAddCategory}
        activeGlobalMenu={activeGlobalMenu}
        globalMenuId={activeGlobalMenu}
      />

      <ChangeCategoryOrder
        isOpen={isOpenOrder}
        setIsOpen={setIsOpenOrder}
        activeCategory={activeCategory}
        categoryList={categoryList[activeGlobalMenu]}
        setActiveCategory={setActiveCategory}
        setTabs={setTabs}
      />
    </Layout>
  );
};
