import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { translateGpt } from 'appConstants';
import { SelectedLanguage, SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { addPrompt, changeCustomPrompt } from 'store/gpt/actions';

import { GPTSelectLang } from './GPTSelectLang';
import { Prompt } from './Prompt';

interface FirstStepGPTProps {
  selected: SelectedLanguage | undefined;
  setSelected: SetStateType<SelectedLanguage | undefined>;
  maxLength: number;
  setMaxLength: SetStateType<number>;
  avalibleLength: number;
  currentLength: number;
}

export const FirstStepGPT = ({
  selected,
  setSelected,
  maxLength,
  setMaxLength,
  avalibleLength,
  currentLength,
}: FirstStepGPTProps) => {
  const dispatch = useAppDispatch();
  const listPrompts = useAppSelector((state) => state.gpt.chatGPT.listPrompts);
  const customPrompt = useAppSelector((state) => state.gpt.chatGPT.customPrompt);

  const [showCustomPromptField, setShowCustomPromptField] = useState(false);
  const loading = useAppSelector((state) => state.gpt.chatGPT.loading);
  const itemFields = useAppSelector((state) => state.item.addedItem);
  const activeItem = useAppSelector((state) => state.item.activeItem);

  return (
    <>
      <div className="rounded-md bg-blue-50 p-4 mb-2">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              {
                translateGpt.promptExplonetion[
                  selected?.value as keyof typeof translateGpt.promptExplonetion
                ]
              }
            </p>
          </div>
        </div>
      </div>

      <div className="rounded-md bg-blue-50 p-4 mb-3">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              {
                translateGpt.langRecommend[
                  selected?.value as keyof typeof translateGpt.langRecommend
                ]
              }
            </p>
          </div>
        </div>
      </div>

      <div className="mb-3">
        <GPTSelectLang selected={selected} setSelected={setSelected} />
      </div>

      <div className="mb-4">
        <h2 className="text-lg mb-2">
          {
            translateGpt.listPrompts[
              selected?.value as keyof typeof translateGpt.listPrompts
            ]
          }
        </h2>
        <div className="flex flex-wrap items-center">
          {listPrompts.length > 0 ? (
            listPrompts.map((prompt) => (
              <Prompt selected={selected} prompt={prompt} key={'prompt-' + prompt.id} />
            ))
          ) : (
            <span className="text-gray-500">
              {
                translateGpt.noPrompts[
                  selected?.value as keyof typeof translateGpt.noPrompts
                ]
              }
            </span>
          )}
        </div>
      </div>

      <div className="mb-2">
        {showCustomPromptField ? (
          <div>
            <label htmlFor="custom-prompt" className="sr-only">
              Custom prompt
            </label>
            <div>
              <TextareaAutosize
                minRows={2}
                disabled={loading}
                value={customPrompt}
                onChange={(e) => {
                  dispatch(
                    changeCustomPrompt({
                      value: e.target.value,
                    }),
                  );
                }}
                name="custom-prompt"
                id="custom-prompt"
                className={classNames(
                  'w-full resize-none scrollbar rounded-md  sm:text-sm',
                  currentLength + customPrompt.length + 2 > avalibleLength
                    ? 'border-2 border-red-600 focus:border-red-600 focus:!ring-0 focus:!outline-none'
                    : 'border-2 border-gray-300 focus:border-primary focus:!ring-0 focus:!outline-none',
                )}
                placeholder={
                  translateGpt.addCustomPrompt[
                    selected?.value as keyof typeof translateGpt.addCustomPrompt
                  ] + '...'
                }
              />
              <div className="flex items-center">
                <div
                  className={classNames(
                    'text-sm',
                    currentLength + customPrompt.length + 2 > avalibleLength
                      ? 'text-red-600'
                      : 'text-gray-600',
                  )}
                >
                  Available number of characters:{' '}
                  {currentLength + customPrompt.length + 2}/{avalibleLength}
                </div>
              </div>
            </div>
            <div className="mt-2 flex justify-end">
              <button
                disabled={loading}
                type="submit"
                onClick={() => {
                  setShowCustomPromptField(false);
                }}
                className="rounded-md mr-2 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                {translateGpt.cancel[selected?.value as keyof typeof translateGpt.add]}
              </button>
              <button
                disabled={loading}
                type="submit"
                onClick={() => {
                  if (customPrompt.trim()) {
                    dispatch(
                      addPrompt({
                        text: customPrompt,
                      }),
                    );
                    setShowCustomPromptField(false);
                    dispatch(
                      changeCustomPrompt({
                        value: '',
                      }),
                    );
                  }
                }}
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {translateGpt.add[selected?.value as keyof typeof translateGpt.add]}
              </button>
            </div>
          </div>
        ) : (
          <div className="h-[128px]">
            <button
              disabled={loading}
              type="button"
              onClick={() => {
                setShowCustomPromptField(true);
              }}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              +{' '}
              {
                translateGpt.addCustomPrompt[
                  selected?.value as keyof typeof translateGpt.addCustomPrompt
                ]
              }
            </button>
          </div>
        )}
      </div>

      <div className="mb-2">
        <label htmlFor="max-length-range" className="mb-1 inline-block">
          {translateGpt.maxLength[selected?.value as keyof typeof translateGpt.maxLength]}
        </label>
        <input
          type="range"
          min="60"
          max="250"
          value={maxLength}
          onChange={(e) => setMaxLength(+e.target.value)}
          step="10"
          className="transparent h-1.5 w-full cursor-pointer appearance-none rounded-lg border-transparent bg-neutral-200"
          id="max-length-range"
        />
        <div className="md:flex justify-between mt-2 text-xs text-gray-600 hidden">
          <span className="w-4 text-center">60</span>
          <span className="w-4 text-center">70</span>
          <span className="w-4 text-center">80</span>
          <span className="w-4 text-right">90</span>
          <span className="w-4 text-right">100</span>
          <span className="w-4 text-right">110</span>
          <span className="w-4 text-right">120</span>
          <span className="w-4 text-right">130</span>
          <span className="w-4 text-right">140</span>
          <span className="w-4 text-right">150</span>
          <span className="w-4 text-right">160</span>
          <span className="w-4 text-right">170</span>
          <span className="w-4 text-right">180</span>
          <span className="w-4 text-right">190</span>
          <span className="w-4 text-right">200</span>
          <span className="w-4 text-right">210</span>
          <span className="w-4 text-right">220</span>
          <span className="w-4 text-right">230</span>
          <span className="w-4 text-right">240</span>
          <span className="w-4 text-right">250</span>
        </div>
      </div>

      <div className="mb-3">
        <div className="flex items-center mb-2">
          <h2 className="text-lg mr-2">Final Prompt</h2>
          <div
            className={classNames(
              'text-sm',
              currentLength > avalibleLength ? 'text-red-600' : 'text-gray-600',
            )}
          >
            Available number of characters: {currentLength}/{avalibleLength}
          </div>
        </div>
        <div
          className={classNames(
            'w-full text-center py-3 px-4 rounded-lg bg-gray-100',
            currentLength > avalibleLength ? 'border-2 border-red-600' : 'border-none',
          )}
        >
          {translateGpt.finalPrompt[
            selected?.value as keyof typeof translateGpt.finalPrompt
          ] +
            ' ' +
            (itemFields.name?.translations
              ? itemFields.name?.translations[
                  selected?.value as keyof typeof itemFields.name.translations
                ]
              : // @ts-ignore
                activeItem.name?.translations[selected.value]) +
            '. '}
          {listPrompts.length > 0 &&
            listPrompts.map((prompt) => {
              if (!prompt.defaultId) {
                return prompt.text + '. ';
              } else {
                // @ts-ignore
                return translateGpt.prompts[prompt.defaultId][selected.value] + '. ';
              }
            })}{' '}
          {translateGpt.inShort1[selected?.value as keyof typeof translateGpt.inShort1] +
            maxLength +
            translateGpt.inShort2[selected?.value as keyof typeof translateGpt.inShort2]}
        </div>
      </div>
    </>
  );
};
