import {
  ERROR_REQUEST_QR_CODE,
  ERROR_REQUEST_QR_CODE_LOCAL,
  RESET_QR_CODE,
  START_REQUEST_QR_CODE,
  START_REQUEST_QR_CODE_LOCAL,
  SUCCESS_REQUEST_QR_CODE,
  SUCCESS_REQUEST_QR_CODE_LOCAL,
} from './actionsTypes';
import { QRCodeAction, QRCodeReducerState } from './types';

const initialState: QRCodeReducerState = {
  loading: false,
  localLoading: false,
  error: null,
  data: null,
};

export const qrcodeReducer = (
  state: QRCodeReducerState = initialState,
  action: QRCodeAction,
) => {
  switch (action.type) {
    case START_REQUEST_QR_CODE:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case SUCCESS_REQUEST_QR_CODE:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      };
    case ERROR_REQUEST_QR_CODE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case START_REQUEST_QR_CODE_LOCAL:
      return {
        ...state,
        localLoading: true,
        error: null,
      };
    case SUCCESS_REQUEST_QR_CODE_LOCAL:
      return {
        ...state,
        localLoading: false,
        error: null,
        data: action.payload,
      };
    case ERROR_REQUEST_QR_CODE_LOCAL:
      return {
        ...state,
        localLoading: false,
        error: action.payload,
      };
    case RESET_QR_CODE:
      return initialState;
    default:
      return state;
  }
};
