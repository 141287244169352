import { Menu, Transition } from '@headlessui/react';
import {
  ArchiveBoxIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import { oldLanguages } from 'appConstants';
import { classNames } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { Fragment } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ChangeStatusItemModal } from '../ChangeModals';
import { DeleteItemModal } from './DeleteItemModal';

interface ItemHeaderProps {
  activeGlobalMenu: string;
}

interface RouteParams {
  id: string;
}

export const ItemHeader = ({ activeGlobalMenu }: ItemHeaderProps) => {
  const { id } = useParams<RouteParams>();
  const locale = useAppSelector((state) => state.locale.lang);
  const activeItem = useAppSelector((state) => state.item.activeItem);

  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <div className="w-full flex items-center justify-between ">
      <DeleteItemModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        item={activeItem?.id || ''}
        globalMenuId={activeGlobalMenu}
        categoryId={activeItem?.category_id || ''}
      />

      {activeItem && (
        <ChangeStatusItemModal
          open={showChangeStatusModal}
          setOpen={setShowChangeStatusModal}
          itemId={id}
          item={activeItem}
          globalCategoryId={activeGlobalMenu}
        />
      )}

      <h1 className="text-lg">
        {activeItem?.name?.translations
          ? activeItem?.name?.translations[oldLanguages[locale]]
          : ''}
      </h1>
      <div className="flex items-center space-x-3 rtl:space-x-reverse relative">
        {activeItem?.status === 1 && (
          <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
            <FormattedMessage id="active" />
          </span>
        )}
        {activeItem?.status === 2 && (
          <span className="inline-flex items-center rounded-full bg-yellow-100 px-3 py-0.5 text-sm font-medium text-yellow-800">
            <FormattedMessage id="soldout" />
          </span>
        )}
        {activeItem?.status === 3 && (
          <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800">
            <FormattedMessage id="archived" />
          </span>
        )}
        <Menu
          as="div"
          className="relative ltr:ml-3 rtl:mr-3 inline-block ltr:text-left rtl:text-right"
        >
          <div>
            <Menu.Button
              data-testId="cypress-item-menu-btn"
              className="flex items-center rounded-full bg-white p-2 text-black md:hover:text-gray-600 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500"
            >
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute ltr:right-0 rtl:left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setShowChangeStatusModal(true);
                      }}
                      data-testId="cypress-change-status-item"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm cursor-pointer',
                      )}
                    >
                      <ArchiveBoxIcon
                        className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <span>
                        <FormattedMessage id="changeStatus" />
                      </span>
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setOpenDeleteModal(true);
                      }}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm cursor-pointer',
                      )}
                    >
                      <TrashIcon
                        className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <span>
                        <FormattedMessage id="delete" />
                      </span>
                    </div>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};
