import { selectLanguages } from 'appConstants';
import { zoneInfo } from 'appConstants';
import { Auth } from 'aws-amplify';
import {
  AvaliblePassword,
  Languages,
  SelectedLanguage,
  SetStateType,
  ZoneInfo,
} from 'commonTypes';

export const isAuthenticated = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return !!user;
  } catch (e) {
    return false;
  }
};

interface CompareCountryCodeWithLanguageProps {
  lastCountry: ZoneInfo | null | undefined;
  country: ZoneInfo | undefined;
  transactionLanguages: SelectedLanguage[];
  setTransactionLanguages: SetStateType<SelectedLanguage[]>;
}

export const compareCountryCodeWithLanguage = ({
  lastCountry,
  country,
  transactionLanguages,
  setTransactionLanguages,
}: CompareCountryCodeWithLanguageProps) => {
  if (!lastCountry) {
    const countryCode = zoneInfo.find((item) => item.label === country?.label)?.lang;
    if (!transactionLanguages.find((item) => item.value === countryCode)) {
      const newLang = selectLanguages.find((item) => item.value === countryCode);
      if (newLang) {
        setTransactionLanguages([newLang]);
      } else {
        setTransactionLanguages([]);
      }
    }
  } else {
    const countryCode = zoneInfo.find((item) => item.label === country?.label)?.lang;
    const lastCountryCode = zoneInfo.find(
      (item) => item.label === lastCountry.label,
    )?.lang;
    if (transactionLanguages.find((item) => item.value === countryCode)) {
      return;
    }
    if (
      countryCode !== lastCountryCode &&
      (transactionLanguages.length < 5 ||
        transactionLanguages.find((item) => item.value === lastCountryCode))
    ) {
      const newTransactionLanguages = transactionLanguages.filter(
        (item) => item.value !== lastCountryCode,
      );
      const newLang = selectLanguages.find((item) => item.value === countryCode);
      if (newLang) {
        newTransactionLanguages.push(newLang);
      }
      setTransactionLanguages(newTransactionLanguages);
    }
  }
};

interface CompareMainLangWithTranslatedLanguageProps {
  lastMainLanguage: Languages | null;
  mainLanguage: Languages | undefined;
  transactionLanguages: SelectedLanguage[];
  setTransactionLanguages: SetStateType<SelectedLanguage[]>;
}

export const compareMainLangWithTranslatedLanguage = ({
  lastMainLanguage,
  mainLanguage,
  transactionLanguages,
  setTransactionLanguages,
}: CompareMainLangWithTranslatedLanguageProps) => {
  if (!lastMainLanguage) {
    const langCode = selectLanguages.find((item) => item.value === mainLanguage)?.value;
    if (!transactionLanguages.find((item) => item.value === langCode)) {
      const newLang = selectLanguages.find((item) => item.value === langCode);
      if (newLang) {
        setTransactionLanguages([newLang]);
      } else {
        setTransactionLanguages([]);
      }
    }
  } else {
    const langCode = selectLanguages.find((item) => item.value === mainLanguage)?.value;
    const lastLangCode = selectLanguages.find(
      (item) => item.value === lastMainLanguage,
    )?.value;
    if (transactionLanguages.find((item) => item.value === langCode)) {
      return;
    }
    if (
      langCode !== lastLangCode &&
      (transactionLanguages.length < 5 ||
        transactionLanguages.find((item) => item.value === lastLangCode))
    ) {
      const newTransactionLanguages = transactionLanguages.filter(
        (item) => item.value !== lastLangCode,
      );
      const newLang = selectLanguages.find((item) => item.value === langCode);
      if (newLang) {
        newTransactionLanguages.push(newLang);
      }
      setTransactionLanguages(newTransactionLanguages);
    }
  }
};

export const checkPassword = ({
  password,
  setAvaliblePassword,
  avaliblePassword,
}: {
  password: string;
  setAvaliblePassword: SetStateType<AvaliblePassword>;
  avaliblePassword: AvaliblePassword;
}) => {
  const newAvaliblePassword = { ...avaliblePassword };
  if (password.length >= 8) {
    newAvaliblePassword.length = true;
  } else {
    newAvaliblePassword.length = false;
  }

  if (password.match(/[A-Z]/)) {
    newAvaliblePassword.upperCase = true;
  } else {
    newAvaliblePassword.upperCase = false;
  }

  if (password.match(/[a-z]/)) {
    newAvaliblePassword.lowerCase = true;
  } else {
    newAvaliblePassword.lowerCase = false;
  }

  if (password.match(/[0-9]/)) {
    newAvaliblePassword.number = true;
  } else {
    newAvaliblePassword.number = false;
  }

  if (password.match(/[!@#$%^&*(),_.?":{}|<>-]/)) {
    newAvaliblePassword.special = true;
  } else {
    newAvaliblePassword.special = false;
  }

  setAvaliblePassword(newAvaliblePassword);
};
