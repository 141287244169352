import { Switch } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { classNames, positiveNumberInput } from 'helpers/validation';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-tooltip';

interface ToppingsOptionsProps {
  enabled: boolean;
  setEnabled: SetStateType<boolean>;
  setMultiChoice: SetStateType<boolean>;
  multi: boolean;
  setSelectionCount: SetStateType<number>;
  selectionCount: number;
}

export const ToppingsOptions = ({
  enabled,
  setEnabled,
  multi,
  setSelectionCount,
  selectionCount,
  setMultiChoice,
}: ToppingsOptionsProps) => {
  return (
    <div>
      <Switch.Group as="div" className="flex items-center justify-between flex-wrap mb-3">
        <div className="font-medium text-gray-700 ltr:mr-2 rtl:ml-2 text-sm flex items-center">
          <Switch.Label>
            <FormattedMessage id="topingsOption" />
          </Switch.Label>

          <InformationCircleIcon
            data-tooltip-id="topingsOption"
            className="h-5 w-5 text-gray-500 ml-2 cursor-pointer hover:text-gray-400"
            aria-hidden="true"
          />
          <Tooltip place="top" id="topingsOption">
            <div className="max-w-[300px]">
              <FormattedMessage id="toppingsHintText" />
            </div>
          </Tooltip>
        </div>

        <Switch
          id="selection-free"
          checked={enabled}
          onChange={(v: boolean) => {
            if (v) {
              setMultiChoice(true);
            }
            setEnabled(v);
          }}
          className={classNames(
            enabled ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
      </Switch.Group>

      <div className={classNames(!enabled && 'opacity-80')}>
        <label
          htmlFor="numberOfSelectionForFree"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          <FormattedMessage id="numberOfSelectionForFree" />
        </label>
        <div className="mt-2">
          <input
            value={selectionCount}
            onChange={(e) => {
              setSelectionCount(+positiveNumberInput(e, true));
            }}
            type="text"
            name="numberOfSelectionForFree"
            id="numberOfSelectionForFree"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="1"
            disabled={!multi || !enabled}
          />
        </div>
      </div>
    </div>
  );
};
