import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { oldLanguages } from 'appConstants';
import { ReactComponent as NoPhoto } from 'assets/static/no_photo.svg';
import { SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { CategoryDropdown } from 'components/Common/CategoryDropdown';
import { Spinner } from 'components/Common/Spinner';
import { PreloadComponent } from 'components/ImagePreload/PreloadComponent/PreloadComponent';
import { classNames, returnNumberPrice } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { AccountState } from 'store/account/types';
import { SET_IMAGES_ITEM } from 'store/images/actionTypes';
import { editSuggestions } from 'store/item/actions';
import { Items } from 'store/menu/types';

interface SuggestionModalProps {
  suggestion: Items[];
  open: boolean;
  setOpen: SetStateType<boolean>;
  item: Items;
  account: AccountState;
  activeGlobalMenu: string;
}

export const SuggestionModal = ({
  suggestion,
  open,
  setOpen,
  item,
  account,
  activeGlobalMenu,
}: SuggestionModalProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.item.loading);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const defaultPhoto = useAppSelector((state) => state.account.account?.default_photo);
  const [activeCategory, setActiveCategory] = useState(item.category_id || '');
  const [suggestions, setSuggestions] = useState(suggestion?.map((item) => item.id));
  const locale = useAppSelector((state) => state.locale.lang);
  const [discardClick, setDiscardClick] = useState(0);
  const menu = useAppSelector((state) => state.menu.menu);
  const categoryListFull = useAppSelector(
    (state) => state.category.activeCategory.categoryList,
  );
  const categoryListObjFull = useAppSelector(
    (state) => state.category.activeCategory.categoryListObj,
  );
  const withGlobalMenu = useAppSelector(
    (state) => state.account.account?.with_global_menus,
  );
  const [activeGlobalMenuLocal, setActiveGlobalMenuLocal] = useState(activeGlobalMenu);

  useEffect(() => {
    if (categoryListObjFull[activeGlobalMenuLocal][activeCategory]) {
      setActiveGlobalMenuLocal(activeGlobalMenu);
      setActiveCategory(item.category_id || '');
    }
  }, [item]);

  useEffect(() => {
    if (
      categoryListObjFull[activeGlobalMenuLocal][activeCategory]?.status === 4 &&
      categoryListFull[activeGlobalMenuLocal].length > 1
    ) {
      const filteredList = categoryListFull[activeGlobalMenuLocal];

      setActiveCategory(filteredList[0].id);
    }
  }, [activeGlobalMenuLocal]);

  const returnItemPhoto = (product: Items) => {
    if (product?.photo?.medium) {
      return (
        <PreloadComponent
          baseSrc={product?.photo?.medium}
          minimizedSrc={product?.photo?.preloader}
          className="h-full w-full object-cover object-center"
        />
      );
    } else if (defaultPhoto?.links?.medium) {
      return (
        <PreloadComponent
          baseSrc={defaultPhoto?.links?.medium}
          minimizedSrc={defaultPhoto?.links?.preloader}
          className="h-full w-full object-cover object-center"
        />
      );
    } else {
      return <NoPhoto className="h-full w-full object-cover object-center p-9" />;
    }
  };

  // @ts-ignore
  if (categoryListObjFull[activeGlobalMenuLocal][activeCategory]) {
    return (
      <AppModal open={open} size="sm:max-w-3xl">
        <div className="flex flex-col">
          <div className="flex w-full items-center justify-between mb-3">
            <h1>
              <FormattedMessage id="suggestions" />
            </h1>
            <XMarkIcon
              onClick={() => {
                if (discardClick === 0) {
                  setDiscardClick(1);
                } else if (discardClick === 1) {
                  dispatch({
                    type: SET_IMAGES_ITEM,
                    payload: [],
                  });
                  setOpen(false);
                  setDiscardClick(0);
                }
              }}
              className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
            />
          </div>

          <div className="w-full flex items-center justify-between flex-wrap">
            <div className="flex items-center space-x-2 overflow-auto pb-1">
              {withGlobalMenu &&
                menu.map((globalMenu) => {
                  return (
                    <button
                      onClick={() => {
                        if (activeGlobalMenuLocal !== globalMenu.id) {
                          setActiveGlobalMenuLocal(globalMenu.id);
                          setActiveCategory(categoryListFull[globalMenu.id][0].id);
                        }
                      }}
                      key={'suggestion-global-menu-' + globalMenu.id}
                      className={classNames(
                        globalMenu.id === activeGlobalMenuLocal
                          ? 'bg-indigo-100 text-indigo-700'
                          : 'text-gray-500 hover:text-gray-700',
                        'rounded-md px-3 py-2 text-sm font-medium cursor-pointer whitespace-nowrap',
                      )}
                    >
                      {globalMenu.name.translations
                        ? globalMenu.name.translations[oldLanguages[locale]]
                        : ''}
                    </button>
                  );
                })}
            </div>

            <div className="flex items-center justify-self-end ml-auto">
              {suggestions.length > 0 && (
                <span className="inline-flex ltr:mr-3 rtl:ml-3 items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
                  x{suggestions.length}
                </span>
              )}
              <CategoryDropdown
                categoryList={categoryListFull[activeGlobalMenuLocal]}
                setChangeCategyValue={setActiveCategory}
                changeCategyValue={activeCategory}
                className="w-52"
              />
            </div>
          </div>

          {withGlobalMenu &&
            (categoryListObjFull[activeGlobalMenuLocal][activeCategory]?.status === 4 ||
              menu.find((globalMenu) => globalMenu.id === activeGlobalMenuLocal)
                ?.status === 3) && (
              <div className="mt-2">
                <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationTriangleIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-yellow-700">
                        <FormattedMessage id="defaultSuggestions" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

          <div className="max-h-96">
            {/* @ts-ignore */}
            {categoryListObjFull[activeGlobalMenuLocal][activeCategory].items.filter(
              (elem) => item.id !== elem.id,
            ).length > 0 && (
              <div className="my-3 grid grid-cols-2 gap-y-6 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 max-h-96 overflow-auto scrollbar">
                {/* @ts-ignore */}
                {categoryListObjFull[activeGlobalMenuLocal][activeCategory].items.map(
                  (product: any) =>
                    item.id !== product.id && (
                      <div
                        role="button"
                        data-testId="cypress-item-suggestion-modal"
                        tabIndex={0}
                        onClick={() => {
                          setSuggestions((prev) => {
                            if (prev.includes(product.id)) {
                              return prev.filter((item) => item !== product.id);
                            } else {
                              return [...prev, product.id];
                            }
                          });
                        }}
                        key={'item-card-' + product.id}
                        className={`relative group cursor-pointer rounded-lg p-1 md:hover:bg-gray-300 ${
                          suggestions.includes(product.id) ? 'bg-gray-200' : ''
                        } `}
                      >
                        <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-w-7 xl:aspect-h-8">
                          {returnItemPhoto(product)}
                        </div>

                        <div className="mt-4 flex w-full items-center justify-between">
                          <h3 className="text-sm text-gray-700 truncate ltr:mr-3 rtl:ml-3">
                            {product?.name?.translations[oldLanguages[locale]] ||
                              product?.name?.translations[locale]}
                          </h3>
                          {product?.status === 1 && (
                            <span className="inline-flex whitespace-nowrap items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                              <FormattedMessage id="active" />
                            </span>
                          )}
                          {product?.status === 2 && (
                            <span className="inline-flex whitespace-nowrap items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                              <FormattedMessage id="soldout" />
                            </span>
                          )}
                          {product?.status === 3 && (
                            <span className="inline-flex whitespace-nowrap items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                              <FormattedMessage id="archived" />
                            </span>
                          )}
                        </div>

                        <p className="mt-1 text-base font-medium text-gray-900 truncate">
                          {returnNumberPrice(product?.price) === -1 ? (
                            <FormattedMessage id="free" />
                          ) : (
                            <FormattedNumber
                              // eslint-disable-next-line react/style-prop-object
                              style="currency"
                              currency={account?.currency}
                              value={returnNumberPrice(product.price)}
                              maximumFractionDigits={intCurrency ? 0 : 2}
                            />
                          )}
                        </p>
                      </div>
                    ),
                )}
              </div>
            )}
            {/* @ts-ignore */}
            {categoryListObjFull[activeGlobalMenuLocal][activeCategory].items.filter(
              (elem) => item.id !== elem.id,
            ).length === 0 && (
              <div className="w-full h-[265px] flex items-center justify-center">
                <h1>
                  <FormattedMessage id="noItemsInCategory" />
                </h1>
              </div>
            )}
          </div>
          <div className="flex w-full justify-end pt-3 bg-white">
            <div className="flex items-center space-x-3 rtl:space-x-reverse mt-3">
              <div className="text-sm text-red-500">
                {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
              </div>
              <button
                onClick={() => {
                  if (discardClick === 0) {
                    setDiscardClick(1);
                  } else if (discardClick === 1) {
                    dispatch({
                      type: SET_IMAGES_ITEM,
                      payload: [],
                    });
                    setOpen(false);
                    setDiscardClick(0);
                  }
                }}
                type="button"
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="discardChanges" />
              </button>
              <button
                onClick={() => {
                  setDiscardClick(0);
                  dispatch(
                    editSuggestions({
                      suggestions: suggestions,
                      item: item,
                      setOpen: setOpen,
                    }),
                  );
                }}
                disabled={loading}
                type="button"
                data-testId="cypress-add-suggestion-save"
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                {loading ? <Spinner color="bg-white" /> : <FormattedMessage id="save" />}
              </button>
            </div>
          </div>
        </div>
      </AppModal>
    );
  } else {
    return null;
  }
};
