import { SetStateType } from 'commonTypes';
import { Spinner } from 'components/Common/Spinner';
import { getItemStyle, reorder } from 'helpers/menu';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { changeOrderEntity } from 'store/account/actions';
import { SUCCESS_FETCH_CATEGORY } from 'store/category/actionTypes';
import { getNewValueByCategoryList } from 'store/menu/actions';
import { Categories } from 'store/menu/types';

import { AddCategoryModal } from '../Modals';
import { CategoryButton } from '../UI';

interface MenuSideBarProps {
  setActiveCategory: SetStateType<string>;
  activeCategory: string;
  isOpen: boolean;
  setIsOpen: SetStateType<boolean>;
  activeGlobalMenu: string;
}

export const MenuSideBar = ({
  setActiveCategory,
  activeCategory,
  isOpen,
  setIsOpen,
  activeGlobalMenu,
}: MenuSideBarProps) => {
  const dispatch = useAppDispatch();
  const categoryList = useAppSelector(
    (state) => state.category.activeCategory.categoryList,
  );
  const loadingCategory = useAppSelector((state) => state.category.loading);

  const [items, setItems] = useState<Categories[]>([]);

  useEffect(() => {
    if (activeGlobalMenu) {
      setItems(categoryList[activeGlobalMenu]);
    }
  }, [categoryList, activeGlobalMenu]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const itemsNew = reorder<Categories>(
      items,
      result.source.index,
      result.destination.index,
    );

    const newOrder: Record<number, string> = {};
    itemsNew.forEach((item, index) => {
      newOrder[index] = item.id;
    });

    dispatch(
      changeOrderEntity({
        newOrder,
        newCategoryList: itemsNew,
      }),
    );

    setItems(itemsNew);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="">
        <form className="w-full mb-2">
          <div className="block text-sm font-medium text-gray-700">
            <FormattedMessage id="categories" />
          </div>
        </form>
        <div className="text-gray-400 w-full ltr:text-left rtl:text-right text-sm mb-4">
          <FormattedMessage id="changeCategoriesOrder" />
        </div>

        {loadingCategory ? (
          <div className="py-10">
            <Spinner color="bg-primary" />
          </div>
        ) : (
          <>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  className="w-full category-list"
                  ref={provided.innerRef}
                >
                  {items?.map((category, i) => {
                    if (category.status === 4) return null;
                    return (
                      <Draggable
                        key={'category-button-menu-' + category.id}
                        draggableId={category.id}
                        isDragDisabled={i === 0}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                            className="mb-3"
                          >
                            <CategoryButton
                              active={activeCategory === category?.id}
                              category={category}
                              setActiveCategory={setActiveCategory}
                              activeGlobalMenu={activeGlobalMenu}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <div className="w-full mt-3 mb-3">
              <CategoryButton
                category={items?.find((item) => item?.status === 4)}
                active={activeCategory === items?.find((item) => item?.status === 4)?.id}
                setActiveCategory={setActiveCategory}
                activeGlobalMenu={activeGlobalMenu}
              />
            </div>

            <button
              type="button"
              id="add-category-button"
              data-testId="cypress-add-category-btn"
              className="w-full py-2 mt-2 text-sm bg-white text-gray-700 md:hover:bg-gray-100 rounded"
              onClick={() => setIsOpen(true)}
            >
              + <FormattedMessage id="addCategory" />
            </button>
          </>
        )}
      </div>
      <AddCategoryModal
        open={isOpen}
        setOpen={setIsOpen}
        activeGlobalMenu={activeGlobalMenu}
        globalMenuId={activeGlobalMenu}
      />
    </DragDropContext>
  );
};
