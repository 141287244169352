import { Switch } from '@headlessui/react';
import { ClipboardDocumentCheckIcon, PlusIcon } from '@heroicons/react/24/outline';
import { defaultToastProps, oldLanguages } from 'appConstants';
import { ReactComponent as NoPhoto } from 'assets/static/no_photo.svg';
import { Crumbs } from 'commonTypes';
import { Loading } from 'components/Common/Loading';
import { Spinner } from 'components/Common/Spinner';
import { ChangeServiceFeeModal } from 'components/QRCodeComponents/ChangeServiceFeeModal';
import { ChangeTakeAwayModal } from 'components/QRCodeComponents/ChangeTakeAwayModal';
import { QRCodeModal } from 'components/QRCodeComponents/QRCodeModal';
import { MENU_PANEL_URL } from 'configure';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { toast } from 'react-toastify';
import { changeFlagTakeAway, changeFlagWithOrder } from 'store/account/actions';
import { getQRCode } from 'store/qrcode/action';

import { Layout } from '../Layout/Layout';

const crumbs: Crumbs[] = [{ name: 'QRCode', href: '/qr-code', current: true }];

export const QRCode = () => {
  const dispatch = useAppDispatch();

  const account = useAppSelector((state) => state.account.account);
  const loading = useAppSelector((state) => state.qr.loading);
  const loadingAccount = useAppSelector((state) => state.account.loading);
  const subLoading = useAppSelector((state) => state.account.subLoading);
  const QRCode = useAppSelector((state) => state.qr.data);
  const locale = useAppSelector((state) => state.locale.lang);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);

  const [openFeeModal, setOpenFeeModal] = useState(false);
  const [enabledMenuNoOrder, setEnabledMenuNoOrder] = useState(false);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [openLinkModalEx, setOpenLinkModalEx] = useState(false);
  const [openTakeAwayModal, setOpenTakeAwayModal] = useState(false);

  const notification = useAppSelector((state) => state.account.notification);

  useEffect(() => {
    if (notification === 'servicesFee') {
      setOpenFeeModal(true);
    }
  }, [notification]);

  useEffect(() => {
    if (!QRCode) {
      dispatch(getQRCode());
    }
  }, []);

  useEffect(() => {
    if (account) {
      setEnabledMenuNoOrder(!account?.with_order);
    }
  }, [account]);

  const serviceFeeContent = () => {
    const serviceFee = account?.service_fee_warning?.translations;
    if (
      account?.service_fee_warning?.source_lang &&
      serviceFee &&
      account?.service_fee_percentage
    ) {
      return (
        serviceFee[oldLanguages[locale]] + ' / ' + account?.service_fee_percentage + '%'
      );
    } else if (
      account?.service_fee_warning?.source_lang &&
      serviceFee &&
      !account?.service_fee_percentage
    ) {
      return serviceFee[oldLanguages[locale]];
    } else if (
      !account?.service_fee_warning?.source_lang &&
      account?.service_fee_percentage
    ) {
      return account?.service_fee_percentage + '%';
    } else {
      return <FormattedMessage id="no" />;
    }
  };

  return (
    <Layout crumbs={crumbs}>
      {loading || loadingAccount ? (
        <div className="w-full flex flex-col h-full bg-white p-4 py-48">
          <Spinner color="bg-primary" />
        </div>
      ) : QRCode?.link ? (
        <>
          <QRCodeModal
            open={openLinkModalEx}
            setOpen={setOpenLinkModalEx}
            linkEx={QRCode?.link}
            id={QRCode?.id}
          />
          <ChangeTakeAwayModal open={openTakeAwayModal} setOpen={setOpenTakeAwayModal} />
          <ChangeServiceFeeModal open={openFeeModal} setOpen={setOpenFeeModal} />
          <div className="w-full flex flex-col bg-white p-4">
            <h1 className="mb-2 text-xl">
              <FormattedMessage id="qrcode" />
            </h1>
            <div className="flex w-full flex-wrap ipad:flex-nowrap items-start space-x-0 ipad:space-x-7 bg-white rounded-lg ">
              <div className="w-full ipad:max-w-[500px] ipad:m-5/12 flex flex-col space-y-4 mb-3">
                {QRCode?.qr_code ? (
                  <img
                    src={QRCode?.qr_code}
                    alt="Dish"
                    className="w-full h-[300px] ipad:h-[460px] object-contain ipad:object-cover object-center ipad:group-hover:opacity-75 rounded-lg"
                  />
                ) : (
                  <NoPhoto className="w-full h-[300px] ipad:h-[460px] object-contain ipad:object-cover object-center ipad:group-hover:opacity-75 rounded-lg p-9" />
                )}

                <a
                  href={QRCode?.qr_code}
                  download="qr_code.png"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-6 py-3 text-base font-medium text-indigo-700 md:hover:bg-indigo-200 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2 text-center justify-center"
                >
                  <FormattedMessage id="download" />
                </a>
              </div>

              <div className="w-full ipad:w-7/12 flex flex-grow flex-col space-y-4">
                <h1 className="font-medium text-xl text-gray-600">
                  <FormattedMessage id="mainInfo" />
                </h1>
                <dl className="divide-y divide-gray-200 mb-8">
                  {/* Status */}
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      <FormattedMessage id="status" />
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <div className="flex-grow">
                        <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
                          <FormattedMessage id="active" />
                        </span>
                      </div>
                      <span className="ltr:ml-4 rtl:mr-4 flex-shrink-0">
                        <button
                          onClick={() => {}}
                          className="rounded-md bg-white font-medium text-indigo-600 md:hover:text-indigo-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                        >
                          {/* <FormattedMessage id="edit" /> */}
                        </button>
                      </span>
                    </dd>
                  </div>

                  {/* Domain */}
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 flex">
                      <FormattedMessage id="menuLink" />
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <button
                        className="mr-2"
                        onClick={() => {
                          const text = MENU_PANEL_URL + '/' + QRCode?.link;
                          navigator.clipboard.writeText(text).then(
                            () => {
                              toast('The link was successfully copied to the clipboard', {
                                ...defaultToastProps,
                                type: 'success',
                              });
                            },
                            () => {
                              toast('An error occurred while copying', {
                                ...defaultToastProps,
                                type: 'error',
                              });
                            },
                          );
                        }}
                      >
                        <ClipboardDocumentCheckIcon className="h-6 w-6 text-gray-500 hover:text-gray-400 transition-all" />
                      </button>

                      <a
                        href={MENU_PANEL_URL + '/' + QRCode?.link}
                        target="blank"
                        className="flex-grow truncate text-primary md:hover:text-indigo-400"
                      >
                        {MENU_PANEL_URL.split('://')[1]}/{QRCode?.link}
                      </a>
                      <span className="ltr:ml-4 rtl:mr-4 flex-shrink-0">
                        <button
                          onClick={() => {
                            setOpenLinkModalEx(true);
                          }}
                          data-testId="cypress-change-domain"
                          className="rounded-md bg-white font-medium text-indigo-600 md:hover:text-indigo-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                        >
                          <FormattedMessage id="edit" />
                        </button>
                      </span>
                    </dd>
                  </div>

                  {/* Services fee */}
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 flex">
                      <FormattedMessage id="servicesFee" />
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <div className="text-sm text-gray-600 flex-grow truncate">
                        {serviceFeeContent()}
                      </div>
                      <span className="ltr:ml-4 rtl:mr-4 flex-shrink-0">
                        <button
                          onClick={() => {
                            setOpenFeeModal(true);
                          }}
                          data-testId="cypress-change-fee"
                          className="rounded-md bg-white font-medium text-indigo-600 md:hover:text-indigo-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                        >
                          <FormattedMessage id="edit" />
                        </button>
                      </span>
                    </dd>
                  </div>

                  {/* Menu without cart */}
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 flex">
                      <FormattedMessage id="menuWithoutOrder" />
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <div className="text-sm text-gray-600 flex-grow truncate">
                        <Switch
                          data-testId="cypress-withoutcart-switch"
                          disabled={subLoading}
                          checked={enabledMenuNoOrder}
                          onChange={(v: boolean) => {
                            dispatch(
                              changeFlagWithOrder({
                                setEnabledMenuNoOrder,
                                value: !v,
                              }),
                            );
                          }}
                          className={classNames(
                            enabledMenuNoOrder ? 'bg-indigo-600' : 'bg-gray-200',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              enabledMenuNoOrder ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none flex items-center justify-center h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                            )}
                          >
                            {subLoading && <Loading />}
                          </span>
                        </Switch>
                      </div>
                    </dd>
                  </div>

                  {/* Take away option */}
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 flex">
                      <FormattedMessage id="takeAwayOption" />
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <div className="text-sm text-gray-600 flex-grow truncate flex items-center">
                        <Switch
                          data-testId="cypress-take-away-switch"
                          disabled={subLoading}
                          checked={!!account?.take_away?.show}
                          onChange={(v: boolean) => {
                            if (!account?.take_away) {
                              setOpenTakeAwayModal(true);
                            } else {
                              dispatch(
                                changeFlagTakeAway({
                                  show: v,
                                }),
                              );
                            }
                          }}
                          className={classNames(
                            account?.take_away?.show ? 'bg-indigo-600' : 'bg-gray-200',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              account?.take_away?.show
                                ? 'translate-x-5'
                                : 'translate-x-0',
                              'pointer-events-none flex items-center justify-center h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                            )}
                          >
                            {subLoading && <Loading />}
                          </span>
                        </Switch>
                        <div className="flex items-center">
                          <div className="mx-2">/</div>
                          {account?.take_away?.price === '-1' ? (
                            <FormattedMessage id="free" />
                          ) : (
                            <FormattedNumber
                              style="currency"
                              currency={account?.currency}
                              value={+(account?.take_away?.price || 0)}
                              maximumFractionDigits={intCurrency ? 0 : 2}
                            />
                          )}
                        </div>
                      </div>
                      <span className="ltr:ml-4 rtl:mr-4 flex-shrink-0">
                        <button
                          onClick={() => {
                            setOpenTakeAwayModal(true);
                          }}
                          data-testId="cypress-take-away"
                          className="rounded-md bg-white font-medium text-indigo-600 md:hover:text-indigo-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                        >
                          <FormattedMessage id="edit" />
                        </button>
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <QRCodeModal open={openLinkModal} setOpen={setOpenLinkModal} />
          <div className="w-full flex flex-col h-full bg-white p-4">
            <h1 className="mb-2 text-xl">
              <FormattedMessage id="qrcode" />
            </h1>
            <div className="text-center py-48">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                <FormattedMessage id="noQrCode" />
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                <FormattedMessage id="getStartedQrCode" />
              </p>
              <div className="mt-6">
                <button
                  type="button"
                  onClick={() => setOpenLinkModal(true)}
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  <PlusIcon
                    className="ltr:-ml-1 rtl:-mr-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  <FormattedMessage id="newQrCode" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};
