import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ChangePasswordModal } from '../ChangeModals';

export const PasswordField = () => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
      <ChangePasswordModal
        open={showChangePasswordModal}
        setOpen={setShowChangePasswordModal}
      />
      <dt className="text-sm font-medium text-gray-500">
        <FormattedMessage id="password" />
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow">*********</div>
        <span className="ltr:ml-4 rtl:mr-4 flex-shrink-0">
          <button
            onClick={() => {
              setShowChangePasswordModal(true);
            }}
            className="rounded-md bg-white font-medium text-indigo-600 md:hover:text-indigo-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="edit" />
          </button>
        </span>
      </dd>
    </div>
  );
};
