import { XMarkIcon } from '@heroicons/react/24/outline';
import { oldLanguages } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { CategoryDropdown } from 'components/Common/CategoryDropdown';
import { Spinner } from 'components/Common/Spinner';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { editItemField } from 'store/item/actions';

interface ChangeCategoryContentProps {
  id: string;
  setOpenCategoryChange: SetStateType<boolean>;
  itemId: string;
  globalCategoryId: string;
  menuPage?: boolean;
}

export const ChangeCategoryContent = ({
  id,
  setOpenCategoryChange,
  itemId,
  globalCategoryId,
  menuPage = false,
}: ChangeCategoryContentProps) => {
  const dispatch = useAppDispatch();
  const [changeCategyValue, setChangeCategyValue] = useState(id);
  const loading = useAppSelector((state) => state.item.subLoading);
  const locale = useAppSelector((state) => state.locale.lang);
  const [discardClick, setDiscardClick] = useState(0);
  const menu = useAppSelector((state) => state.menu.menu);
  const withGlobalMenu = useAppSelector(
    (state) => state.account.account?.with_global_menus,
  );
  const categoryListFull = useAppSelector(
    (state) => state.category.activeCategory.categoryList,
  );
  const [activeGlobalMenuLocal, setActiveGlobalMenuLocal] = useState(globalCategoryId);

  return (
    <div className="flex flex-col">
      <div className="flex w-full items-center justify-between mb-3">
        <h1>
          <FormattedMessage id="category" />
        </h1>
        <XMarkIcon
          onClick={() => {
            if (changeCategyValue === id) {
              setDiscardClick(0);
              setOpenCategoryChange(false);
            } else {
              if (discardClick === 0) {
                setDiscardClick(1);
              } else if (discardClick === 1) {
                setDiscardClick(0);
                setOpenCategoryChange(false);
                setChangeCategyValue(id);
              }
            }
          }}
          className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
        />
      </div>

      <div className="flex items-center space-x-2 mb-2">
        {withGlobalMenu &&
          menu.map((globalMenu) => {
            return (
              <button
                onClick={() => {
                  setActiveGlobalMenuLocal(globalMenu.id);
                  setChangeCategyValue(categoryListFull[globalMenu.id][0].id);
                }}
                key={'suggestion-global-menu-' + globalMenu.id}
                className={classNames(
                  globalMenu.id === activeGlobalMenuLocal
                    ? 'bg-indigo-100 text-indigo-700'
                    : 'text-gray-500 hover:text-gray-700',
                  'rounded-md px-3 py-2 text-sm font-medium cursor-pointer',
                )}
              >
                {globalMenu.name.translations
                  ? globalMenu.name.translations[oldLanguages[locale]]
                  : ''}
              </button>
            );
          })}
      </div>

      <div>
        <CategoryDropdown
          categoryList={categoryListFull[activeGlobalMenuLocal]}
          setChangeCategyValue={setChangeCategyValue}
          changeCategyValue={changeCategyValue}
        />
      </div>
      <div className="flex w-full justify-end mt-3">
        <div className="flex items-center space-x-3">
          {changeCategyValue === id ? (
            <button
              onClick={() => {
                setDiscardClick(0);
                setOpenCategoryChange(false);
              }}
              type="button"
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
            >
              <FormattedMessage id="cancel" />
            </button>
          ) : (
            <div className="flex items-center">
              <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
                {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
              </div>
              <div className="flex items-center justify-end w-full sm:w-auto">
                <button
                  onClick={() => {
                    if (discardClick === 0) {
                      setDiscardClick(1);
                    } else if (discardClick === 1) {
                      setDiscardClick(0);
                      setOpenCategoryChange(false);
                      setChangeCategyValue(id);
                    }
                  }}
                  type="button"
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  <FormattedMessage id="discardChanges" />
                </button>
                <button
                  onClick={() => {
                    setDiscardClick(0);
                    dispatch(
                      editItemField({
                        id: itemId,
                        value: changeCategyValue,
                        field: 'category_id',
                        setOpen: setOpenCategoryChange,
                        globalCategoryId: activeGlobalMenuLocal,
                        oldGlobalCategoryId: globalCategoryId,
                        oldCategoryId: id,
                        menuPage,
                      }),
                    );
                  }}
                  type="button"
                  className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  {loading ? (
                    <Spinner color="bg-white" />
                  ) : (
                    <FormattedMessage id="save" />
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
