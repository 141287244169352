import { XMarkIcon } from '@heroicons/react/24/outline';
import { ISelections, SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { getItemStyleOrder, reorder } from 'helpers/menu';
import { useAppDispatch } from 'hooks/store';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { changeOrderEntity } from 'store/account/actions';

import { SelectionRowBlock } from '../UI';

interface ChangeSelectionsOrderModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  selectionsList: ISelections[];
  setSelectionsList: SetStateType<ISelections[]>;
  optionId: string;
}

export const ChangeSelectionsOrderModal = ({
  open,
  setOpen,
  selectionsList,
  setSelectionsList,
  optionId,
}: ChangeSelectionsOrderModalProps) => {
  const dispatch = useAppDispatch();
  const [selections, setSelections] = useState(selectionsList);

  useEffect(() => {
    setSelections(selectionsList);
  }, [selectionsList]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const itemsNew = reorder(selections, result.source.index, result.destination.index);

    const newOrder: Record<number, string> = {};
    itemsNew.forEach((item, index) => {
      newOrder[index] = item.id;
    });

    dispatch(
      changeOrderEntity({
        newOrder,
        entity: 'selection',
        entityId: optionId,
      }),
    );

    setSelections(itemsNew);
    setSelectionsList(itemsNew);
  };
  return (
    <AppModal open={open} size="sm:max-w-xl">
      <DragDropContext onDragEnd={onDragEnd}>
        <div>
          <form className="w-full mb-2 flex items-center justify-between">
            <h1>
              <FormattedMessage id="selections" />
            </h1>

            <XMarkIcon
              onClick={() => {
                setOpen(false);
              }}
              className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
            />
          </form>
          <div className="text-gray-400 w-full ltr:text-left rtl:text-right text-sm mb-4 sub-text-dnd">
            <FormattedMessage id="changeItemsOrderText" />
          </div>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                className="w-full relative item-list"
                ref={provided.innerRef}
              >
                {selections.map((item, i) => {
                  return (
                    <Draggable
                      key={'selections-order-button-' + item?.id}
                      draggableId={item?.id}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyleOrder(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                          className="mb-3"
                        >
                          <SelectionRowBlock key={item?.id} selection={item} />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <div className="w-full flex justify-end">
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
        >
          <FormattedMessage id="close" />
        </button>
      </div>
    </AppModal>
  );
};
