import { AxiosInstance } from 'axios';
import { CreateCategoryBody } from 'store/category/types';
import { Categories } from 'store/menu/types';

export const category = (ApiClient: () => Promise<AxiosInstance>) => {
  return {
    changeCategoryField: async (id: string, body: any) =>
      (await ApiClient()).put<Categories>(`/category/${id}`, body),
    createCategory: async (body: CreateCategoryBody) =>
      (await ApiClient()).post<Categories>('category', body),
    deleteCategory: async (id: string, moveItems: boolean) =>
      (await ApiClient()).delete<Categories[]>(`/category/${id}`, {
        params: {
          move_items: moveItems,
        },
      }),
  };
};
