import { selectLanguages } from 'appConstants';
import { Languages } from 'commonTypes';
import { AccountState } from 'store/account/types';
import { CategoryList } from 'store/category/types';
import { Categories, MenuItemState } from 'store/menu/types';

export const reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
  const containerDesktop: HTMLElement | null = document.querySelector('.category-list');
  const containerMobile: HTMLElement | null = document.querySelector(
    '.category-list-mobile',
  );
  const widthContainer = containerDesktop?.clientWidth || containerMobile?.clientWidth;
  const subTextHeight = document.querySelector('.sub-text-dnd')?.clientHeight;

  if (widthContainer && subTextHeight && containerMobile) {
    return {
      ...draggableStyle,
      width: isDragging ? widthContainer + 'px' : '100%',
      top:
        widthContainer > 0
          ? draggableStyle.top -
            containerMobile?.getBoundingClientRect()?.top +
            subTextHeight +
            60 +
            'px'
          : draggableStyle.top,
    };
  } else {
    return {
      ...draggableStyle,
      width: isDragging ? widthContainer + 'px' : '100%',
      top: draggableStyle.top,
    };
  }
};

export const getItemStyleOrder = (isDragging: boolean, draggableStyle: any) => {
  const container: HTMLElement | null = document.querySelector('.item-list');
  const widthContainer = container?.clientWidth;
  const subTextHeight = document.querySelector('.sub-text-dnd')?.clientHeight;

  if (widthContainer && subTextHeight) {
    return {
      ...draggableStyle,
      width: isDragging ? widthContainer + 'px' : '100%',
      top:
        widthContainer > 0
          ? draggableStyle.top -
            container?.getBoundingClientRect()?.top +
            subTextHeight +
            60 +
            'px'
          : draggableStyle.top,
      left: 'auto !important',
    };
  } else {
    return {
      ...draggableStyle,
      width: isDragging ? widthContainer + 'px' : '100%',
      top: draggableStyle.top,
      left: 'auto !important',
    };
  }
};

export const categoryNameList = (fullMenu: Categories[] = [], cateObj = false) => {
  if (cateObj) {
    const categoryList:
      | {
          [key: string]: Categories;
        }
      | Record<string, never> = {};
    fullMenu.map((cat) => {
      categoryList[cat.id] = cat;
      return true;
    });
    return categoryList;
  } else {
    return fullMenu;
  }
};

export const createNameWithoutTransalte = (name: string) => {
  // @ts-ignore
  const nameWithoutTranslate: Record<Languages, string> = {};
  selectLanguages.forEach((lang) => (nameWithoutTranslate[lang.value] = name));
  return nameWithoutTranslate;
};

export const checkLocalActiveMenu = ({
  menu,
  account,
}: {
  menu: MenuItemState[];
  account: AccountState;
}) => {
  let localeGlobalMenu = localStorage.getItem('activeGlobalMenu-' + account?.id);
  if (localeGlobalMenu) {
    const serverElement = menu.find((menu) => menu?.id === localeGlobalMenu);
    if (!serverElement) {
      localeGlobalMenu = menu[0]?.id;
    }
  } else {
    localeGlobalMenu = menu[0]?.id;
  }
  return localeGlobalMenu;
};

export const checkLocalActiveCategory = ({
  activeGlobalMenu,
  account,
  categoryList,
}: {
  activeGlobalMenu: string;
  account: AccountState;
  categoryList: CategoryList;
}) => {
  let localeActiveCategory = localStorage.getItem(
    'activeCategory-' + activeGlobalMenu + '-' + account?.id,
  );
  if (localeActiveCategory) {
    const serverElement = categoryList[activeGlobalMenu].find(
      (category) => category?.id === localeActiveCategory,
    );
    if (!serverElement) {
      if (categoryList[activeGlobalMenu]?.length > 1) {
        localeActiveCategory = categoryList[activeGlobalMenu][1]?.id;
      } else {
        localeActiveCategory = categoryList[activeGlobalMenu][0]?.id;
      }
    }
  } else {
    if (categoryList[activeGlobalMenu]?.length > 1) {
      localeActiveCategory = categoryList[activeGlobalMenu][1]?.id;
    } else {
      localeActiveCategory = categoryList[activeGlobalMenu][0]?.id;
    }
  }
  return localeActiveCategory;
};
