//eslint-ignore

export const NoCelery = ({ width = 32, height = 32, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 248 248"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_417_251)">
      <path
        d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
        fill="#F8E997"
      />
      <path
        d="M97.3772 195.505C97.2039 195.332 113.884 212.018 130.778 228.917C186.824 221.227 230 173.159 230 115C230 110.003 229.68 105.081 229.062 100.252L179.589 50.7786L150.04 61.1817L115.225 26.188L96.4802 66.3925L70.5874 98.5543C70.6653 98.6321 95.72 123.687 95.7929 123.76C95.801 123.768 97.7265 195.855 97.3772 195.505Z"
        fill="#F2D049"
      />
      <path
        d="M131.299 85.983H115.225H99.1512L91.122 177.034C90.5238 183.775 92.8049 190.507 97.377 195.505C101.949 200.498 108.453 203.362 115.225 203.362C121.997 203.362 128.5 200.498 133.072 195.505C137.645 190.507 139.926 183.776 139.327 177.034L131.299 85.983Z"
        fill="#BBEB7A"
      />
      <path
        d="M139.328 177.034L131.299 85.983H115.225V203.362C121.997 203.362 128.501 200.498 133.073 195.505C137.645 190.507 139.926 183.775 139.328 177.034Z"
        fill="#A1D45D"
      />
      <path
        d="M85.2675 104.627C79.955 104.627 74.6379 102.604 70.5875 98.5543C68.3874 96.3542 66.7504 93.7639 65.7112 90.8495L50.8611 49.6183L92.2525 64.3131C95.1521 65.347 97.7424 66.9844 99.9474 69.1845C108.042 77.2849 108.042 90.4588 99.9524 98.5543C95.9019 102.604 90.5849 104.627 85.2675 104.627Z"
        fill="#71A52C"
      />
      <path
        d="M144.828 105.788C139.511 105.788 134.193 103.765 130.143 99.715C122.053 91.62 122.053 78.4457 130.143 70.3502C132.353 68.1452 134.944 66.5077 137.848 65.4738L179.589 50.7791L164.384 92.0152C163.345 94.9247 161.708 97.515 159.508 99.715C155.458 103.765 150.14 105.788 144.828 105.788Z"
        fill="#558A66"
      />
      <path
        d="M115.225 26.188L96.4595 66.4371C95.1312 69.2299 94.4624 72.2208 94.4624 75.3279C94.4624 86.7788 103.774 96.0904 115.225 96.0904V26.188Z"
        fill="#A1D45D"
      />
      <path
        d="M135.988 75.3279C135.988 72.2204 135.318 69.2299 134 66.4519L115.225 26.188V96.0909C126.676 96.0904 135.988 86.7788 135.988 75.3279Z"
        fill="#71A52C"
      />
      <circle cx="115" cy="115" r="110" stroke="black" strokeWidth="10" />
      <line
        x1="35.7109"
        y1="32.649"
        x2="184.711"
        y2="197.649"
        stroke="black"
        strokeWidth="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_251">
        <rect width="230" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
