import { AxiosInstance } from 'axios';
import { IMG_SERVICE_BASE_URL } from 'configure';

export const images = (
  ApiClient: (publicRequest: boolean, baseUrl: string) => Promise<AxiosInstance>,
) => {
  return {
    fetchImage: async (body: any) =>
      (await ApiClient(false, IMG_SERVICE_BASE_URL)).put<any>(`/convert`, body),
    convertImage: async (body: any) =>
      (await ApiClient(false, IMG_SERVICE_BASE_URL)).post<File>(`/convert`, body, {
        responseType: 'blob',
        headers: {
          Accept: '*/*',
        },
      }),
  };
};
