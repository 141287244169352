import {
  AddItemProgress,
  AddItemSteps,
  CreateItemSteper,
} from 'components/AddItemComponents';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Layout } from 'pages/Layout/Layout';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Prompt } from 'react-router-dom';
import { RESET_ITEM } from 'store/item/actionsTypes';
import { getRestaurantMenu } from 'store/menu/actions';

const crumbs = [
  { name: 'Menu', href: '/menu', current: false },
  { name: 'AddItem', href: '/add-item', current: true },
];

export const AddItem = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.account);
  const menu = useAppSelector((state) => state.menu.menu);
  const loadingMenu = useAppSelector((state) => state.menu.loading);
  const [activeStep, setActiveStep] = useState(0);
  const [itemCreated, setItemCreated] = useState(false);

  const unloadHandler = (ev: any) => {
    if (!itemCreated) {
      ev.preventDefault();
      return (ev.returnValue = 'Are you sure you want to close?');
    }
  };

  useEffect(() => {
    if (menu.length === 0) {
      dispatch(getRestaurantMenu());
    }
    return () => {
      dispatch({ type: RESET_ITEM });
    };
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', unloadHandler);
    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [itemCreated]);

  return (
    <Layout crumbs={crumbs}>
      <Prompt
        when={!itemCreated}
        message={(location, action) => {
          if (action === 'POP') {
            console.log('Backing up...');
          }

          return location.pathname.startsWith('/add-item')
            ? true
            : `Are you sure you want to leave page? The changes will not be saved`;
        }}
      />

      {!loadingMenu && !!account ? (
        <div className="flex flex-col ipad:flex-row items-stretch w-full">
          <div className="hidden ipad:flex flex-col w-5/12 max-w-md px-6 py-4 bg-white ltr:mr-4 rtl:ml-4">
            <h1 className="mb-4 text-lg text-gray-500">
              <FormattedMessage id="createItem" />
            </h1>
            <CreateItemSteper activeStep={activeStep} />
          </div>
          <AddItemProgress activeStep={activeStep} />
          <AddItemSteps
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            itemCreated={itemCreated}
            setItemCreated={setItemCreated}
          />
        </div>
      ) : (
        <Spinner color="bg-primary" />
      )}
    </Layout>
  );
};
