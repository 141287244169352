import { oldLanguages } from 'appConstants';
import { IOptions } from 'commonTypes';
import { useAppSelector } from 'hooks/store';

import { ChoosenSelection } from './ChoosenSelection';

interface ChoosenOptionProps {
  option: IOptions;
}

export const ChoosenOption = ({ option }: ChoosenOptionProps) => {
  const locale = useAppSelector((state) => state.locale.lang);

  return (
    <div className="flex flex-col p-2 w-full xl:w-[48%] 2xl:w-[30%]">
      <p className="text-base text-gray-500 font-medium mb-2">
        {option?.name?.translations
          ? option?.name?.translations[oldLanguages[locale]]
          : ''}
      </p>
      <div className="max-w-[100%]">
        {option?.selections?.map((selection) => {
          return (
            <ChoosenSelection
              key={'option-selection' + selection?.id}
              selection={selection}
              multi={option?.multi_select}
            />
          );
        })}
      </div>
    </div>
  );
};
