import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import { oldLanguages } from 'appConstants';
import { ReactComponent as NoPhoto } from 'assets/static/no_photo.svg';
import { CategoryDropdown } from 'components/Common/CategoryDropdown';
import { PreloadComponent } from 'components/ImagePreload/PreloadComponent/PreloadComponent';
import { classNames, returnNumberPrice } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { EDIT_ITEM_FIELD } from 'store/item/actionsTypes';
import { Items } from 'store/menu/types';

interface SuggestionFieldProps {
  itemCreated: boolean;
  activeGlobalMenu: string;
}

export const SuggestionField = ({
  itemCreated,
  activeGlobalMenu,
}: SuggestionFieldProps) => {
  const dispatch = useAppDispatch();
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const account = useAppSelector((state) => state.account.account);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const defaultPhoto = useAppSelector((state) => state.account.account?.default_photo);
  const itemFields = useAppSelector((state) => state.item.addedItem);
  const [activeCategory, setActiveCategory] = useState('');
  const locale = useAppSelector((state) => state.locale.lang);
  const menu = useAppSelector((state) => state.menu.menu);
  const categoryListFull = useAppSelector(
    (state) => state.category.activeCategory.categoryList,
  );
  const categoryListObjFull = useAppSelector(
    (state) => state.category.activeCategory.categoryListObj,
  );
  const withGlobalMenu = useAppSelector(
    (state) => state.account.account?.with_global_menus,
  );
  const [activeGlobalMenuLocal, setActiveGlobalMenuLocal] = useState(activeGlobalMenu);

  useEffect(() => {
    if (itemFields.suggestions) {
      setSuggestions(itemFields.suggestions);
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: EDIT_ITEM_FIELD,
      payload: {
        field: 'suggestions',
        value: suggestions,
      },
    });
  }, [suggestions]);

  useEffect(() => {
    if (
      itemFields?.category &&
      categoryListObjFull[activeGlobalMenuLocal][itemFields.category].status !== 4
    ) {
      setActiveCategory(itemFields.category);
    } else if (categoryListFull[activeGlobalMenuLocal].length > 1) {
      const filteredList = categoryListFull[activeGlobalMenuLocal];

      setActiveCategory(filteredList[0].id);
    }
  }, []);

  const returnItemPhoto = (product: Items) => {
    if (product?.photo?.medium) {
      return (
        <PreloadComponent
          baseSrc={product?.photo?.medium}
          minimizedSrc={product?.photo?.preloader}
          className="h-full w-full object-cover object-center"
        />
      );
    } else if (defaultPhoto?.links?.medium) {
      return (
        <PreloadComponent
          baseSrc={defaultPhoto?.links?.medium}
          minimizedSrc={defaultPhoto?.links?.preloader}
          className="h-full w-full object-cover object-center"
        />
      );
    } else {
      return <NoPhoto className="h-full w-full object-cover object-center p-9" />;
    }
  };

  return (
    <div>
      <div className="rounded-md bg-blue-50 p-4 mb-2">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              <FormattedMessage id="suggestionText" />
            </p>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-between flex-wrap">
        <div className="flex items-center space-x-2 overflow-auto pb-1">
          {withGlobalMenu &&
            menu.map((globalMenu) => {
              return (
                <button
                  onClick={() => {
                    if (activeGlobalMenuLocal !== globalMenu.id) {
                      setActiveGlobalMenuLocal(globalMenu.id);
                      setActiveCategory(categoryListFull[globalMenu.id][0].id);
                    }
                  }}
                  key={'suggestion-global-menu-' + globalMenu.id}
                  className={classNames(
                    globalMenu.id === activeGlobalMenuLocal
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'text-gray-500 hover:text-gray-700',
                    'rounded-md px-3 py-2 text-sm font-medium cursor-pointer',
                  )}
                >
                  {globalMenu.name.translations
                    ? globalMenu.name.translations[oldLanguages[locale]]
                    : ''}
                </button>
              );
            })}
        </div>

        <div className="flex items-center justify-self-end ml-auto">
          {suggestions.length > 0 && (
            <span className="inline-flex rtl:ml-3 ltr:mr-3 items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
              x{suggestions.length}
            </span>
          )}
          <CategoryDropdown
            categoryList={categoryListFull[activeGlobalMenuLocal]}
            setChangeCategyValue={setActiveCategory}
            changeCategyValue={activeCategory}
            className="w-52"
          />
        </div>
      </div>

      {withGlobalMenu &&
        (categoryListObjFull[activeGlobalMenuLocal][activeCategory]?.status === 4 ||
          menu.find((globalMenu) => globalMenu.id === activeGlobalMenuLocal)?.status ===
            3) && (
          <div className="mt-2">
            <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    <FormattedMessage id="defaultSuggestions" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

      <div className="max-h-80">
        {(categoryListObjFull[activeGlobalMenuLocal][activeCategory]?.items?.length ||
          0) > 0 && (
          <div className="my-3 grid grid-cols-2 gap-y-10 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-8 max-h-80 overflow-auto scrollbar">
            {categoryListObjFull[activeGlobalMenuLocal][activeCategory]?.items?.map(
              (product) => (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    if (itemCreated) return;
                    setSuggestions((prev) => {
                      if (prev.includes(product.id)) {
                        return prev.filter((item) => item !== product.id);
                      } else {
                        return [...prev, product.id];
                      }
                    });
                  }}
                  key={'item-card-' + product.id}
                  className={`relative group cursor-pointer rounded-lg p-1 md:hover:bg-gray-300 ${
                    suggestions.includes(product.id) ? 'bg-gray-200' : ''
                  } `}
                >
                  <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-w-7 xl:aspect-h-8">
                    {returnItemPhoto(product)}
                  </div>

                  <div className="mt-4 flex w-full items-center justify-between">
                    <h3 className="text-sm text-gray-700 truncate rtl:ml-3 ltr:mr-3">
                      {product?.name?.translations
                        ? product?.name?.translations[oldLanguages[locale]]
                        : ''}
                    </h3>
                    {product?.status === 1 && (
                      <span className="inline-flex whitespace-nowrap items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                        <FormattedMessage id="active" />
                      </span>
                    )}
                    {product?.status === 2 && (
                      <span className="inline-flex whitespace-nowrap items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
                        <FormattedMessage id="soldout" />
                      </span>
                    )}
                    {product?.status === 3 && (
                      <span className="inline-flex whitespace-nowrap items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                        <FormattedMessage id="archived" />
                      </span>
                    )}
                  </div>

                  <p className="mt-1 text-base font-medium text-gray-900 truncate">
                    {returnNumberPrice(product.price) === -1 ? (
                      <FormattedMessage id="free" />
                    ) : (
                      <FormattedNumber
                        style="currency"
                        currency={account?.currency}
                        value={returnNumberPrice(product.price)}
                        maximumFractionDigits={intCurrency ? 0 : 2}
                      />
                    )}
                  </p>
                </div>
              ),
            )}
          </div>
        )}
        {categoryListObjFull[activeGlobalMenuLocal][activeCategory]?.items?.length ===
          0 && (
          <div className="w-full h-64 flex items-center justify-center">
            <h1>
              <FormattedMessage id="noItemsInCategory" />
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};
