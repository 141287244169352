import { CognitoUser } from '@aws-amplify/auth';
import { API } from 'api';
import { intCurrency } from 'appConstants';
import { defaultToastProps } from 'appConstants';
import { Auth } from 'aws-amplify';
import {
  ITheme,
  Languages,
  RoundedState,
  SelectedLanguage,
  SetStateType,
  TranslateFiled,
} from 'commonTypes';
import { BASE_URL } from 'configure';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { logout } from 'store/auth/actions';
import { SUCCESS_FETCH_CATEGORY } from 'store/category/actionTypes';
import { AppThunk } from 'store/globalTypes';
import { getNewValueByCategoryList } from 'store/menu/actions';
import { SUCCESS_UPDATE_MENU } from 'store/menu/actionsTypes';
import { Categories, MenuItemState } from 'store/menu/types';

import { store } from '..';
import { getOptionsApi } from '../options/actions';
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  SET_ACCOUNT_INFO,
  START_CHANGE_PASSWORD,
  START_SET_ACCOUNT_INFO,
  START_SUB_LOADING,
  STOP_SET_ACCOUNT_INFO,
  STOP_SUB_LOADING,
  UPDATE_COGNITO_USER,
} from './actionsTypes';
import {
  AccountState,
  ChangeAccountAtributesProps,
  ChangeColorBody,
  ChangeCurrencyBody,
  ChangeDeliveryBody,
  ChangeFlagTakeAwayBody,
  ChangeFlagWithOrderBody,
  ChangeFlagWithOrderProps,
  ChangeMainLangBody,
  ChangeMediaLinksBody,
  ChangeNameBody,
  ChangePasswordAction,
  ChangeReviewsLinksBody,
  ChangeServicesFeeBody,
  ChangeThemeBody,
  ChangeThemePreviewBody,
  ChangeTranslatedLangBody,
  CustomCognitoUser,
  LoadDefaultPhotoAccountBody,
  LoadLogoAccountBody,
  RequestBodyAccountResponse,
  SetAccountAction,
  SetAccountInfoAction,
  SubLoadingAction,
  UpdateCognitoUserAction,
} from './types';

export const changeOrderEntity: AppThunk =
  ({
    newOrder,
    entity = 'category',
    entityId = null,
    newCategoryList = [],
    newMenuList = [],
  }: {
    newOrder: Record<number, string>;
    entity?: string;
    entityId?: string | null;
    newCategoryList?: Categories[];
    newMenuList?: MenuItemState[];
  }) =>
  async (dispatch) => {
    if (entity === 'category' || entity === 'menu') {
      delete newOrder[0]; //Удаление id дефолтной категории или меню
    }
    const postFix = entity === 'category' || entity === 'menu' ? '' : '/' + entityId;
    try {
      const userToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
      const res = await fetch(BASE_URL + '/' + entity + '/order' + postFix, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + userToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newOrder),
      });

      if (res.ok) {
        if (entity === 'selection') {
          dispatch(getOptionsApi(false));
        } else if (entity === 'item') {
          const newItemList = await res.json();
          const account = store.getState().account.account?.id;
          const activeMenuId = localStorage.getItem('activeGlobalMenu-' + account) || '';
          const oldCategoryList =
            store.getState().category?.activeCategory.categoryList[activeMenuId] || [];

          const newCategoryList = oldCategoryList.map((category) => {
            if (category.id === entityId) {
              return {
                ...category,
                items: newItemList,
              };
            } else {
              return category;
            }
          });
          const [newCategoryListObj, beutifyMenu] = getNewValueByCategoryList({
            newCategoryList,
          });

          dispatch({
            type: SUCCESS_FETCH_CATEGORY,
            payload: {
              categoryList: {
                [activeMenuId]: newCategoryList,
              },
              categoryListObj: {
                [activeMenuId]: newCategoryListObj,
              },
              beutifyMenu,
            },
          });
        } else if (entity === 'category') {
          if (newCategoryList.length > 0) {
            const [newCategoryListObj, beutifyMenu] = getNewValueByCategoryList({
              newCategoryList: newCategoryList,
            });

            dispatch({
              type: SUCCESS_FETCH_CATEGORY,
              payload: {
                categoryList: {
                  [newCategoryList[0].menu_id]: newCategoryList,
                },
                categoryListObj: {
                  [newCategoryList[0].menu_id]: newCategoryListObj,
                },
                beutifyMenu,
              },
            });
          }
        } else if (entity === 'menu') {
          dispatch({
            type: SUCCESS_UPDATE_MENU,
            payload: newMenuList,
          });
        }
        toast('Order changed successfully', {
          ...defaultToastProps,
          type: 'success',
        });
      } else {
        throw new Error('Something went wrong');
      }
    } catch (e) {
      if (typeof e === 'string') {
        toast(e, {
          ...defaultToastProps,
          type: 'error',
        });
      } else if (e instanceof Error) {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      }
    }
  };

export const checkAvailabilityEmail =
  ({
    email,
    setAvalibleEmail,
  }: {
    email: string;
    setAvalibleEmail: SetStateType<boolean | string | null>;
  }) =>
  async () => {
    try {
      const userToken = (await Auth.currentSession()).getAccessToken().getJwtToken();

      const response = await fetch(BASE_URL + '/account/availability/' + email, {
        headers: {
          Authorization: 'Bearer ' + userToken,
        },
        method: 'GET',
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.detail[0].msg);
      }

      setAvalibleEmail(data);
    } catch (e) {
      console.log(e);
    }
  };

export const updateAccountInfo =
  (data: RequestBodyAccountResponse, cognitoUser: CustomCognitoUser) =>
  (dispatch: Dispatch<SetAccountInfoAction>) => {
    let newData: AccountState = data;
    if (!newData?.languages?.includes(newData?.locale)) {
      newData = {
        ...newData,
        translatedLanguages: newData?.languages,
        languages: [...newData.languages, newData?.locale],
        intCurrency: intCurrency.includes(newData.currency),
      };
    } else {
      newData = {
        ...newData,
        translatedLanguages: newData?.languages,
        languages: newData?.languages,
        intCurrency: intCurrency.includes(newData.currency),
      };
    }

    dispatch({
      type: SET_ACCOUNT_INFO,
      payload: {
        account: newData,
        cognitoUser,
      },
    });
  };

export const updateAccount =
  (body: ChangeAccountAtributesProps, handleSuccess: () => void) =>
  async (dispatch: Dispatch<SubLoadingAction>) => {
    dispatch({
      type: START_SUB_LOADING,
    });

    const cognitoUser: CustomCognitoUser = await Auth.currentAuthenticatedUser();

    API.v1.account
      .changeAccountAttribute(body)
      .then(({ data }) => {
        handleSuccess();
        // @ts-ignore
        dispatch(updateAccountInfo(data, cognitoUser));
        toast('Account successfully updated', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({ type: STOP_SUB_LOADING });
      });
  };

export const changeFlagWithOrder: AppThunk =
  ({ value, setEnabledMenuNoOrder }: ChangeFlagWithOrderProps) =>
  async (dispatch) => {
    const body: ChangeFlagWithOrderBody = {
      with_order: value,
    };

    dispatch(updateAccount(body, () => setEnabledMenuNoOrder(!value)));
  };

export const changeFlagTakeAway: AppThunk =
  ({ show, price, close }: { show: boolean; price?: string; close?: () => void }) =>
  async (dispatch) => {
    const takeAwayPrice = store.getState().account.account?.take_away?.price;
    const body: ChangeFlagTakeAwayBody = {
      take_away: {
        show,
        price: price || takeAwayPrice || '-1',
      },
    };

    dispatch(
      updateAccount(body, () => {
        if (close) close();
      }),
    );
  };

export const removeLogo: AppThunk =
  ({ close }: { close: () => void }) =>
  async (dispatch) => {
    const body: LoadLogoAccountBody = {
      logo: {
        name: '',
        size: '',
        links: {},
      },
    };

    dispatch(
      updateAccount(body, () => {
        close();
      }),
    );
  };

export const removeDefaultPhoto: AppThunk =
  ({ close }: { close: () => void }) =>
  async (dispatch) => {
    const body: LoadDefaultPhotoAccountBody = {
      default_photo: {
        name: '',
        size: '',
        links: {},
      },
    };

    dispatch(
      updateAccount(body, () => {
        close();
      }),
    );
  };

export const setAccountInfo: AppThunk =
  () => async (dispatch: Dispatch<SetAccountAction>) => {
    dispatch({
      type: START_SET_ACCOUNT_INFO,
    });

    const cognitoUser: CustomCognitoUser = await Auth.currentAuthenticatedUser();
    API.v1.account
      .setAccountInfo()
      .then(({ data }) => {
        // @ts-ignore
        dispatch(updateAccountInfo(data, cognitoUser));
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_SET_ACCOUNT_INFO,
        });
      });
  };

export const changeReviewsLinks: AppThunk =
  ({
    setOpen,
    data,
    resetFields,
  }: {
    setOpen: SetStateType<boolean>;
    data: {
      newGoogleMaps: string;
      newTripAdvisor: string;
    };
    resetFields: () => void;
  }) =>
  async (dispatch) => {
    const body: ChangeMediaLinksBody = {
      google_maps: data.newGoogleMaps,
      tripadvisor: data.newTripAdvisor,
    };

    dispatch(
      updateAccount(body, () => {
        resetFields();
        setOpen(false);
      }),
    );
  };

export const changeDeliveryLinks: AppThunk =
  ({
    setOpen,
    data,
    resetFields,
  }: {
    setOpen: SetStateType<boolean>;
    data: ChangeDeliveryBody;
    resetFields: () => void;
  }) =>
  async (dispatch) => {
    dispatch(
      updateAccount(data, () => {
        resetFields();
        setOpen(false);
      }),
    );
  };

export const changeMediaLinks: AppThunk =
  ({ setOpen, body }: { setOpen: SetStateType<boolean>; body: ChangeReviewsLinksBody }) =>
  async (dispatch) => {
    dispatch(updateAccount(body, () => setOpen(false)));
  };

export const changePassword: AppThunk =
  ({
    oldPassword,
    newPassword,
    setOpen,
    history,
  }: {
    oldPassword: string;
    newPassword: string;
    setOpen: SetStateType<boolean>;
    history: any;
  }) =>
  async (dispatch: Dispatch<ChangePasswordAction>) => {
    dispatch({
      type: START_CHANGE_PASSWORD,
    });
    try {
      const user = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(user, oldPassword, newPassword);

      // @ts-ignore
      dispatch(logout(history, true));

      setOpen(false);
      toast(
        'Password changed successfully. Please login to the admin panel with a new password',
        {
          ...defaultToastProps,
          type: 'success',
        },
      );
      dispatch({ type: CHANGE_PASSWORD });
    } catch (e) {
      if (typeof e === 'string') {
        toast(e, {
          ...defaultToastProps,
          type: 'error',
        });
        dispatch({ type: CHANGE_PASSWORD_ERROR, payload: e });
      } else if (e instanceof Error) {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
        dispatch({ type: CHANGE_PASSWORD_ERROR, payload: e.message });
      }
    }
  };

export const changeName: AppThunk =
  ({
    typeName,
    newName,
    setOpen,
  }: {
    typeName: string;
    newName: string;
    setOpen: SetStateType<boolean>;
  }) =>
  async (dispatch: Dispatch<SubLoadingAction | UpdateCognitoUserAction>) => {
    dispatch({
      type: START_SUB_LOADING,
    });
    try {
      const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();

      if (typeName === 'restName') {
        const body: ChangeNameBody = { name: newName };
        API.v1.account
          .changeAccountAttribute(body)
          .then(({ data }) => {
            setOpen(false);
            // @ts-ignore
            dispatch(updateAccountInfo(data, cognitoUser));
            dispatch({
              type: STOP_SUB_LOADING,
            });
            toast('Account successfully updated', {
              ...defaultToastProps,
              type: 'success',
            });
          })
          .catch((e) => {
            dispatch({
              type: STOP_SUB_LOADING,
            });
            toast(e.message, {
              ...defaultToastProps,
              type: 'error',
            });
          });
      }

      if (typeName === 'firstName' || typeName === 'lastName') {
        const attribute = typeName === 'firstName' ? 'given_name' : 'family_name';
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
          [attribute]: newName,
        });
        const newCognitoUser = await Auth.currentAuthenticatedUser();
        dispatch({
          type: UPDATE_COGNITO_USER,
          payload: newCognitoUser,
        });
        toast('Name changed successfully', {
          ...defaultToastProps,
          type: 'success',
        });
        setOpen(false);
        dispatch({
          type: STOP_SUB_LOADING,
        });
      }
    } catch (e) {
      dispatch({
        type: STOP_SUB_LOADING,
      });
      if (typeof e === 'string') {
        toast(e, {
          ...defaultToastProps,
          type: 'error',
        });
      } else if (e instanceof Error) {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      }
    }
  };

export const resetEmailAws: AppThunk =
  ({ setLoading }: { setLoading: SetStateType<boolean> }) =>
  async (dispatch: Dispatch<UpdateCognitoUserAction | ChangePasswordAction>) => {
    try {
      const oldEmail = store?.getState()?.account?.account?.email;
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        email: oldEmail,
      });
      const newCognitoUser: CustomCognitoUser = await Auth.currentAuthenticatedUser();
      setLoading(false);
      dispatch({
        type: UPDATE_COGNITO_USER,
        payload: newCognitoUser,
      });
    } catch (e) {
      if (typeof e === 'string') {
        toast(e, {
          ...defaultToastProps,
          type: 'error',
        });
        dispatch({ type: CHANGE_PASSWORD_ERROR, payload: e });
      } else if (e instanceof Error) {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
        dispatch({ type: CHANGE_PASSWORD_ERROR, payload: e.message });
      }
    }
  };

export const changeCurrency: AppThunk =
  ({ newCurrency, setOpen }: { newCurrency: string; setOpen: SetStateType<boolean> }) =>
  async (dispatch) => {
    const body: ChangeCurrencyBody = { currency: newCurrency };

    dispatch(updateAccount(body, () => setOpen(false)));
  };

export const changeServicesFee: AppThunk =
  ({
    newText,
    setOpen,
    resetFields,
    newPercent,
    rounded,
  }: {
    newText: TranslateFiled | object;
    setOpen: SetStateType<boolean>;
    resetFields: () => void;
    newPercent: number;
    rounded: RoundedState;
  }) =>
  async (dispatch) => {
    const body: ChangeServicesFeeBody = {
      service_fee_warning: newText,
      service_fee_percentage: +newPercent || 0,
      service_fee_round_options: {
        type: rounded.type,
        amount: rounded.amount,
      },
    };

    const handleSuccess = () => {
      setOpen(false);
      resetFields();
    };

    dispatch(updateAccount(body, handleSuccess));
  };

export const successChangeMainLang =
  (newMainLang: Languages, setOpen: SetStateType<boolean>) => async () => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      locale: newMainLang,
    });
    setOpen(false);
  };

export const changeMainLang: AppThunk =
  ({
    newMainLang,
    setOpen,
  }: {
    newMainLang: Languages;
    setOpen: SetStateType<boolean>;
  }) =>
  async (dispatch) => {
    const body: ChangeMainLangBody = { locale: newMainLang };

    // FIXME: Make an asynchronous colback or rewrite it
    dispatch(
      updateAccount(body, () => dispatch(successChangeMainLang(newMainLang, setOpen))),
    );
  };

export const changeTranslatedLang: AppThunk =
  ({
    newTranslatedLang,
    setOpen,
  }: {
    newTranslatedLang: SelectedLanguage[];
    setOpen: SetStateType<boolean>;
  }) =>
  async (dispatch) => {
    const body: ChangeTranslatedLangBody = {
      languages: newTranslatedLang.map((item) => item.value),
    };

    dispatch(updateAccount(body, () => setOpen(false)));
  };

export const changeColor: AppThunk =
  ({
    data,
    setOpen,
    resetFields,
  }: {
    data: {
      newBgColor: string;
      newTextColor: string;
      newTheme?: number;
    };
    setOpen: SetStateType<boolean>;
    resetFields: () => void;
  }) =>
  async (dispatch) => {
    const body: ChangeColorBody = {
      color: {
        bgColor: data.newBgColor || '#525ce5',
        textColor: data.newTextColor || '#fff',
        theme: data.newTheme,
      },
    };

    dispatch(
      updateAccount(body, () => {
        resetFields();
        setOpen(false);
      }),
    );
  };

export const changeTheme: AppThunk =
  ({ theme }: { theme: ITheme }) =>
  async (dispatch) => {
    const body: ChangeThemeBody = {
      color: theme,
    };

    dispatch(updateAccount(body, () => {}));
  };

export const changeThemePreview: AppThunk =
  ({ preview, setLoading }: { preview: ITheme; setLoading: SetStateType<boolean> }) =>
  async (dispatch) => {
    const body: ChangeThemePreviewBody = {
      theme_preview: preview,
    };

    dispatch(
      updateAccount(body, () => {
        setLoading(false);
      }),
    );
  };
