import { ReactComponent as Logo } from 'assets/logos/logo-dark.svg';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SET_ERROR } from 'store/auth/actionsTypes';

interface RegisterHeaderProps {
  step: number;
}

export const RegisterHeader = ({ step }: RegisterHeaderProps) => {
  const dispatch = useDispatch();
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <Logo className="mx-auto h-12 w-auto" />
      <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
        Create your free account
      </h2>
      {step !== 4 && (
        <p className="mt-2 text-center text-sm text-gray-600">
          Or {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <Link
            to="/login"
            onClick={() => dispatch({ type: SET_ERROR, payload: null })}
            className="font-medium text-indigo-600 md:hover:text-indigo-500"
          >
            sign in to your account
          </Link>
        </p>
      )}
    </div>
  );
};
