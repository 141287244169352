export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const SUCCESS_FETCH_CATEGORY = 'SUCCESS_FETCH_CATEGORY';
export const STOP_FETCH_CATEGORY = 'STOP_FETCH_CATEGORY';

export const START_MOVE_CATEGORY = 'START_MOVE_CATEGORY';
export const STOP_MOVE_CATEGORY = 'STOP_MOVE_CATEGORY';

export const START_SWITCH_SIMPLE_CATEGORY = 'START_SWITCH_SIMPLE_CATEGORY';
export const STOP_SWITCH_SIMPLE_CATEGORY = 'STOP_SWITCH_SIMPLE_CATEGORY';

export const START_CATEGORY_SUB_LOADING = 'START_CATEGORY_SUB_LOADING';
export const STOP_CATEGORY_SUB_LOADING = 'STOP_CATEGORY_SUB_LOADING';
