import { TrashIcon } from '@heroicons/react/24/outline';
import { defaultToastProps } from 'appConstants';
import { PriceElement, SetStateType } from 'commonTypes';
import { positiveNumberInput } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { toast } from 'react-toastify';

interface MultiPriceRowProps {
  multiplePriceValue: PriceElement[];
  setMultiplePriceValue: SetStateType<PriceElement[]>;
  disabled: boolean;
}

export const MultiPriceRow = ({
  multiplePriceValue,
  setMultiplePriceValue,
  disabled,
}: MultiPriceRowProps) => {
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const account = useAppSelector((state) => state.account.account);

  return (
    <>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Value
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Default
            </th>
            <th scope="col" className="relative py-3.5">
              <span className="sr-only">Delete</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {multiplePriceValue.map((priceValue, index) => (
            <tr key={'multiple-price-' + priceValue.id}>
              <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-900">
                <div className="relative rounded-md shadow-sm">
                  <input
                    disabled={disabled}
                    type="text"
                    id={'price-multi-name-' + priceValue.id}
                    className="block w-full rounded-md border-gray-300 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm"
                    placeholder="Price name"
                    value={priceValue.name || ''}
                    onChange={(e) => {
                      if (!disabled) {
                        setMultiplePriceValue((prev) => {
                          const newArr = prev.map((oldPrice, i) => {
                            if (i === index) {
                              return {
                                ...oldPrice,
                                name: e.target.value,
                              };
                            } else {
                              return oldPrice;
                            }
                          });
                          return newArr;
                        });
                      }
                    }}
                    aria-describedby="price-currency"
                  />
                </div>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="relative rounded-md shadow-sm">
                  <input
                    type="text"
                    id={'price-multi-value-' + priceValue.id}
                    className="block w-full rounded-md border-gray-300 rtl:pl-12 ltr:pr-12 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm"
                    placeholder={intCurrency ? '0' : '0.00'}
                    aria-describedby="price-currency"
                    value={priceValue.value}
                    disabled={disabled}
                    onChange={(e) => {
                      if (!disabled) {
                        setMultiplePriceValue((prev) => {
                          const newArr = prev.map((oldPrice, i) => {
                            if (i === index) {
                              return {
                                ...oldPrice,
                                value: positiveNumberInput(e, intCurrency),
                              };
                            } else {
                              return oldPrice;
                            }
                          });
                          return newArr;
                        });
                      }
                    }}
                  />
                  <div className="pointer-events-none absolute inset-y-0 rtl:left-0 ltr:right-0 flex items-center rtl:pl-3 ltr:pr-3">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      {account?.currency}
                    </span>
                  </div>
                </div>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="w-full flex items-center justify-center">
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      if (!disabled) {
                        setMultiplePriceValue((prev) => {
                          const newArr = prev.map((oldPrice, i) => {
                            if (i === index) {
                              return {
                                ...oldPrice,
                                is_default: true,
                              };
                            } else {
                              return {
                                ...oldPrice,
                                is_default: false,
                              };
                            }
                          });
                          return newArr;
                        });
                      }
                    }}
                    className={`w-4 focus:ring-indigo-500 h-4 rounded-full cursor-pointer flex items-center justify-center ${
                      priceValue.is_default ? 'bg-primary' : 'border border-gray-300'
                    }`}
                  >
                    <div className="w-1.5 h-1.5 rounded-full bg-white" />
                  </div>
                </div>
              </td>
              <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium">
                <TrashIcon
                  className={`w-5 h-5 text-red-500 cursor-pointer md:hover:opacity-60 ${
                    multiplePriceValue.length <= 1 ? 'hidden' : 'block'
                  }`}
                  onClick={() => {
                    if (!disabled) {
                      setMultiplePriceValue((prev) => {
                        if (priceValue.is_default) {
                          toast('Default value is mandatory', {
                            ...defaultToastProps,
                            type: 'error',
                          });
                          return prev;
                        } else {
                          const newArr = [...prev];
                          return newArr.filter((val) => val.id !== priceValue.id);
                        }
                      });
                    }
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            setMultiplePriceValue((prev) => {
              return [
                ...prev,
                {
                  name: '',
                  value: '',
                  is_default: false,
                  id: +prev[prev.length - 1].id + 1,
                },
              ];
            });
          }
        }}
        className="text-primary text-sm w-full text-start"
      >
        + Add new price
      </button>
    </>
  );
};
