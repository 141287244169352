import { ReactComponent as Facebook } from 'assets/static/facebookIcon.svg';
import { ReactComponent as Instagram } from 'assets/static/instagramIcon.svg';
import { ReactComponent as Telegram } from 'assets/static/telegram.svg';
import { ReactComponent as TikTok } from 'assets/static/tiktok.svg';
import { ReactComponent as Web } from 'assets/static/webIcon.svg';
import { ReactComponent as WhatsApp } from 'assets/static/whatsapp.svg';
import { useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ChangeMediaLinksModal } from '../ChangeModals';

export const MediaLinksField = () => {
  const account = useAppSelector((state) => state.account.account);
  const [showChangeMediaLinks, setShowChangeMediaLinks] = useState(false);

  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 reviews-link items-center">
      <ChangeMediaLinksModal
        open={showChangeMediaLinks}
        setOpen={setShowChangeMediaLinks}
      />
      <dt className="text-sm font-medium text-gray-500">
        <FormattedMessage id="mediaLinks" />
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow truncate">
          <div className="flex items-center">
            {account?.facebook && (
              <a
                href={account?.facebook}
                target="_blank"
                className="cursor-pointer p-1 rounded-lg border border-gray-300 mr-3 md:hover:bg-gray-100"
                rel="noreferrer"
              >
                <Facebook className="h-6 w-6" />
              </a>
            )}
            {account?.instagram && (
              <a
                href={account?.instagram}
                target="_blank"
                className="cursor-pointer p-1 rounded-lg border border-gray-300 mr-3 md:hover:bg-gray-100"
                rel="noreferrer"
              >
                <Instagram className="h-6 w-6" />
              </a>
            )}
            {account?.web && (
              <a
                href={account?.web}
                target="_blank"
                className="cursor-pointer p-1 rounded-lg border border-gray-300 mr-3 md:hover:bg-gray-100"
                rel="noreferrer"
              >
                <Web className="h-6 w-6" />
              </a>
            )}
            {account?.tiktok && (
              <a
                href={account?.tiktok}
                target="_blank"
                className="cursor-pointer p-1 rounded-lg border border-gray-300 mr-3 md:hover:bg-gray-100"
                rel="noreferrer"
              >
                <TikTok className="h-6 w-6" />
              </a>
            )}
            {account?.telegram && (
              <a
                href={account?.telegram}
                target="_blank"
                className="cursor-pointer p-1 rounded-lg border border-gray-300 mr-3 md:hover:bg-gray-100"
                rel="noreferrer"
              >
                <Telegram className="h-6 w-6" />
              </a>
            )}
            {account?.whatsapp && (
              <a
                href={'https://wa.me/' + account.whatsapp}
                target="_blank"
                className="cursor-pointer p-1 rounded-lg border border-gray-300 md:hover:bg-gray-100"
                rel="noreferrer"
              >
                <WhatsApp className="h-6 w-6" />
              </a>
            )}

            {!account?.facebook &&
              !account?.instagram &&
              !account?.web &&
              !account?.whatsapp &&
              !account?.tiktok &&
              !account?.telegram &&
              'No links'}
          </div>
        </div>
        <span className="ltr:ml-4 rtl:mr-4 flex-shrink-0">
          <button
            onClick={() => {
              setShowChangeMediaLinks(true);
            }}
            data-testId="cypress-change-media"
            className="rounded-md bg-white font-medium text-indigo-600 md:hover:text-indigo-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="edit" />
          </button>
        </span>
      </dd>
    </div>
  );
};
