import { SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { FormattedMessage } from 'react-intl';
import { editCategory } from 'store/category/actions';

interface ChangeCategoryStatusModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  status: number;
  id: string;
  globalMenuId: string;
}

export const ChangeCategoryStatusModal = ({
  open,
  setOpen,
  status,
  id,
  globalMenuId,
}: ChangeCategoryStatusModalProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.menu.subLoading);

  return (
    <AppModal open={open} size="sm:max-w-md">
      <h1 className="mb-2 rtl:text-right">
        <FormattedMessage id="status" />
      </h1>

      <div className="mb-2 w-full text-sm text-gray-500 rtl:text-right">
        {status === 1 ? (
          <FormattedMessage id="changeCategoryStatusArchived" />
        ) : status === 3 ? (
          <FormattedMessage id="changeCategoryStatusActive" />
        ) : (
          <></>
        )}
      </div>

      <div className="w-full flex justify-end">
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="ltr:mr-2 rtl:ml-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <FormattedMessage id="cancel" />
        </button>
        <button
          type="button"
          onClick={() => {
            dispatch(
              editCategory({
                categoryId: id,
                field: 'status',
                value: status === 3 ? 1 : 3,
                setOpen,
                globalCategoryId: globalMenuId,
              }),
            );
          }}
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {loading ? <Spinner color="bg-white" /> : <FormattedMessage id="save" />}
        </button>
      </div>
    </AppModal>
  );
};
