import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { translateGpt } from 'appConstants';
import { SelectedLanguage } from 'commonTypes';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import TextareaAutosize from 'react-textarea-autosize';
import { changeResult, getGptResponse } from 'store/gpt/actions';

interface SecondStepGPTProps {
  selected: SelectedLanguage | undefined;
  maxLength: number;
}

export const SecondStepGPT = ({ selected, maxLength }: SecondStepGPTProps) => {
  const dispatch = useAppDispatch();
  const result = useAppSelector((state) => state.gpt.chatGPT.gptResult);
  const loading = useAppSelector((state) => state.gpt.chatGPT.loading);
  const listPrompts = useAppSelector((state) => state.gpt.chatGPT.listPrompts);
  const itemFields = useAppSelector((state) => state.item.addedItem);
  const activeItem = useAppSelector((state) => state.item.activeItem);

  return (
    <>
      <div className="rounded-md bg-blue-50 p-4 mb-2">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              {
                translateGpt.finalStepText[
                  selected?.value as keyof typeof translateGpt.finalStepText
                ]
              }
            </p>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="gpt-result" className="sr-only">
          Result
        </label>
        <div>
          <TextareaAutosize
            minRows={2}
            disabled={loading}
            name="gpt-result"
            id="gpt-result"
            value={result}
            onChange={(e) => {
              dispatch(
                changeResult({
                  value: e.target.value,
                }),
              );
            }}
            className="w-full scrollbar rounded-md border-gray-300 sm:text-sm"
            placeholder={
              translateGpt.textResult[
                selected?.value as keyof typeof translateGpt.textResult
              ]
            }
          />
        </div>
        <div className="mt-2 flex justify-end">
          <button
            type="submit"
            disabled={loading}
            onClick={() => {
              let finalPrompt =
                translateGpt.finalPrompt[
                  selected?.value as keyof typeof translateGpt.finalPrompt
                ] +
                ' ' +
                (itemFields.name?.translations
                  ? itemFields.name?.translations[
                      selected?.value as keyof typeof itemFields.name.translations
                    ]
                  : // @ts-ignore
                    activeItem.name?.translations[selected?.value]) +
                '. ';
              listPrompts.forEach((prompt) => {
                if (!prompt.defaultId) {
                  finalPrompt += prompt.text + '. ';
                } else {
                  finalPrompt +=
                    // @ts-ignore
                    translateGpt.prompts[prompt.defaultId][selected.value] + '. ';
                }
              });
              finalPrompt +=
                translateGpt.inShort1[
                  selected?.value as keyof typeof translateGpt.inShort1
                ] +
                maxLength +
                translateGpt.inShort2[
                  selected?.value as keyof typeof translateGpt.inShort2
                ];
              dispatch(
                getGptResponse({
                  prompt: finalPrompt,
                  setActiveStep: () => {},
                }),
              );
            }}
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {loading ? (
              <Spinner color="bg-white" />
            ) : (
              translateGpt.regenerate[
                selected?.value as keyof typeof translateGpt.regenerate
              ]
            )}
          </button>
        </div>
      </div>
    </>
  );
};
