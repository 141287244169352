import { AxiosInstance } from 'axios';
import { CreatedMenuBody, MenuItemState, UpdateMenuBody } from 'store/menu/types';

export const menu = (ApiClient: () => Promise<AxiosInstance>) => {
  return {
    getRestaurantMenu: async () => (await ApiClient()).get<MenuItemState[]>(`/menu/list`),
    getMenuById: async (id: string) =>
      (await ApiClient()).get<MenuItemState>(`/menu/${id}`),
    createMenu: async (body: CreatedMenuBody) =>
      (await ApiClient()).post<MenuItemState>('/menu', body),
    deleteMenu: async (id: string, moveCategories: boolean) =>
      (await ApiClient()).delete<MenuItemState[]>(`/menu/${id}`, {
        params: {
          move_categories: moveCategories,
        },
      }),
    changeMenu: async (id: string, body: UpdateMenuBody) =>
      (await ApiClient()).put<MenuItemState>(`/menu/${id}`, body),
  };
};
