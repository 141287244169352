//eslint-ignore

export const Crab = ({ width = 33, height = 32, className = '' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 231 231"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_417_297)">
        <path
          d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
          fill="#F8E997"
        />
        <path
          d="M192.217 44.5804L154.633 46.8979C154.787 46.9002 162.47 54.5618 162.563 54.5667C162.83 56.986 162.181 59.7235 159.67 61.5896C153.431 66.217 149.707 73.6158 149.707 81.3754C149.707 88.2124 152.509 94.4377 157.061 98.9198C157.208 99.0728 169.337 111.198 169.398 111.261C169.299 115.727 167.211 119.704 163.984 122.351L75.8172 37.0413C75.8172 37.0413 39.2306 99.1989 39.0802 99.049C38.9304 98.8991 51.0537 111.027 51.1924 111.162C51.2554 117.952 54.0802 124.09 58.5951 128.511C58.7428 128.664 68.5265 138.445 68.5414 138.46L49.4361 145.736L52.9383 154.943L61.812 163.608L58.3066 165.945L63.7713 174.142L119.532 229.903C180.943 227.522 230 176.994 230 115C230 101.06 227.519 87.7011 222.975 75.3369C206.101 58.4623 192.276 44.6376 192.217 44.5804Z"
          fill="#F2D049"
        />
        <path d="M100.447 96.1535H110.299V111.576H100.447V96.1535Z" fill="#674D55" />
        <path d="M120.151 96.1535H130.003V111.576H120.151V96.1535Z" fill="#52374C" />
        <path
          d="M75.8175 135.561C62.2372 135.561 51.1873 124.511 51.1873 110.931V101.079H61.0395V110.931C61.0395 119.08 67.6685 125.709 75.8175 125.709V135.561Z"
          fill="#DD455B"
        />
        <path
          d="M49.5195 145.751L75.4038 135.896L78.9107 145.107L53.0264 154.962L49.5195 145.751Z"
          fill="#DD455B"
        />
        <path
          d="M58.3511 165.919L81.3394 150.584L86.8071 158.781L63.8188 174.116L58.3511 165.919Z"
          fill="#DD455B"
        />
        <path
          d="M81.6096 187.078L96.794 164.667L104.952 170.194L89.7672 192.605L81.6096 187.078Z"
          fill="#DD455B"
        />
        <path
          d="M154.633 135.561V125.709C162.782 125.709 169.411 119.08 169.411 110.931V101.079H179.263V110.931C179.263 124.511 168.213 135.561 154.633 135.561Z"
          fill="#B2004F"
        />
        <path
          d="M151.72 145.031L155.227 135.82L181.111 145.676L177.604 154.886L151.72 145.031Z"
          fill="#B2004F"
        />
        <path
          d="M143.728 158.868L149.195 150.671L172.184 166.006L166.716 174.202L143.728 158.868Z"
          fill="#B2004F"
        />
        <path
          d="M125.551 170.114L133.709 164.586L148.893 186.998L140.736 192.525L125.551 170.114Z"
          fill="#B2004F"
        />
        <path
          d="M157.034 116.483C156.269 116.055 138.008 106.005 115.225 106.005C92.442 106.005 74.1815 116.055 73.4164 116.483L70.8909 117.892V130.636C70.8909 155.083 90.7775 174.97 115.225 174.97C139.673 174.97 159.559 155.083 159.559 130.636V117.892L157.034 116.483Z"
          fill="#E37A4E"
        />
        <path
          d="M159.559 130.636V117.892L157.034 116.483C156.269 116.055 138.008 106.005 115.225 106.005V174.969C139.672 174.969 159.559 155.083 159.559 130.636Z"
          fill="#DD455B"
        />
        <path
          d="M56.0648 106C41.7372 106 31.1545 93.759 31.1545 80.3218V61.6711C31.1545 48.0907 42.5326 37.0408 56.1129 37.0408H75.8169V46.893C71.5549 46.9605 69.1734 49.9429 68.2786 52.7092C67.3982 55.4512 67.5954 59.2225 70.7803 61.5896C77.0195 66.2175 80.7427 73.6158 80.7427 81.3754C80.7432 94.8797 69.8332 106 56.0648 106Z"
          fill="#E37A4E"
        />
        <path
          d="M174.385 106C160.611 106 149.708 94.8721 149.708 81.375C149.708 73.6158 153.431 66.217 159.67 61.5891C162.854 59.2225 163.052 55.4507 162.172 52.7087C161.277 49.9429 158.651 46.9601 154.633 46.8975V37.0413H174.337C187.918 37.0413 199.296 48.0912 199.296 61.6715V80.3222C199.296 93.7351 188.737 106 174.385 106Z"
          fill="#DD455B"
        />
        <path
          d="M115.225 132.517C111.611 128.38 106.36 125.709 100.447 125.709V135.561C105.878 135.561 110.299 139.982 110.299 145.414H115.225H120.151C120.151 139.982 124.572 135.561 130.003 135.561V125.709C124.09 125.709 118.839 128.38 115.225 132.517Z"
          fill="#DD455B"
        />
        <path
          d="M130.003 135.561V125.709C124.09 125.709 118.839 128.38 115.225 132.517V145.414H120.151C120.151 139.982 124.572 135.561 130.003 135.561Z"
          fill="#B2004F"
        />
      </g>
      <defs>
        <clipPath id="clip0_417_297">
          <rect width="230" height="230" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
