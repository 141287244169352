import { Menu, Transition } from '@headlessui/react';
import { QrCodeIcon } from '@heroicons/react/24/outline';
import { ReactComponent as NoPhoto } from 'assets/static/no_photo.svg';
import { Fragment, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { ApiThemeState } from 'store/themes/types';

interface PreviewButtonProps {
  disabled?: boolean;
  activeChooseTheme: ApiThemeState | undefined;
}

export const PreviewButton = ({
  disabled = false,
  activeChooseTheme,
}: PreviewButtonProps) => {
  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const closeWhenClickOutside = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.preview-button-list')) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', closeWhenClickOutside);

    return () => {
      window.removeEventListener('click', closeWhenClickOutside);
    };
  }, []);

  return (
    <>
      <Tooltip
        isOpen={openTooltip}
        style={{
          backgroundColor: 'rgb(255, 255, 255)',
          color: '#000',
          border: '1px solid #d1d5db',
          opacity: 1,
        }}
        place="top"
        id="preview-theme"
        classNameArrow="border border-gray-300 border-t-0 border-l-0"
      >
        Theme Preview
      </Tooltip>
      <Menu as="div" className="relative inline-block text-left preview-button-list">
        <div>
          {/* @ts-ignore */}
          <Menu.Button
            onMouseOver={() => {
              if (!open) {
                setOpenTooltip(true);
              }
            }}
            onMouseOut={() => {
              setOpenTooltip(false);
            }}
            data-tooltip-id="preview-theme"
            onClick={() => {
              setOpenTooltip(false);
              setOpen((prev) => !prev);
            }}
            disabled={disabled}
            className="p-2 border border-gray-300 rounded-md bg-white"
          >
            <QrCodeIcon className="h-6 w-6 text-gray-500" />
          </Menu.Button>
        </div>
        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            static
            className="absolute right-0 z-30 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          >
            <div className="px-1 py-1 flex items-center justify-center">
              <Menu.Item>
                {activeChooseTheme?.qr_code_link ? (
                  <img
                    src={activeChooseTheme?.qr_code_link}
                    alt="Dish"
                    className="w-[200px] h-[200px] object-contain ipad:object-cover object-center ipad:group-hover:opacity-75 rounded-lg"
                  />
                ) : (
                  <NoPhoto className="w-[200px] h-[200px] object-contain ipad:object-cover object-center ipad:group-hover:opacity-75 rounded-lg p-9" />
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
