import { defaultToastProps } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { Spinner } from 'components/Common/Spinner';
import { checkFieldNotEmpty, validatePrice } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { loadImagesItem } from 'store/images/actions';
import { addItem } from 'store/item/actions';

interface SaveButtonsProps {
  activeStep: number;
  setActiveStep: SetStateType<number>;
  setItemCreated: SetStateType<boolean>;
  activeGlobalMenu: string;
  itemCreated: boolean;
}

export const SaveButtons = ({
  activeStep,
  setActiveStep,
  setItemCreated,
  activeGlobalMenu,
  itemCreated,
}: SaveButtonsProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.menu.loading);
  const loadingImage = useAppSelector((state) => state.image.loading);
  const itemLoading = useAppSelector((state) => state.item.loading);
  const itemFields = useAppSelector((state) => state.item.addedItem);
  const itemImages = useAppSelector((state) => state.image.itemImages);
  const account = useAppSelector((state) => state.account.account);

  const nextButtonContent = () => {
    if (loading || loadingImage || itemLoading) {
      return <Spinner color="bg-white" />;
    } else if (activeStep !== 6) {
      return <FormattedMessage id="next" />;
    } else {
      return <FormattedMessage id="save" />;
    }
  };

  const handleNextButton = () => {
    if (+activeStep === 0) {
      if (itemFields.name) {
        if (
          checkFieldNotEmpty(itemFields.name.translations) &&
          Object.keys(itemFields.name.translations).length === account?.languages.length
        ) {
          setActiveStep((prev) => prev + 1);
        } else {
          toast('Translation is required for all languages', {
            ...defaultToastProps,
            type: 'error',
          });
        }
      } else {
        toast('Name is required', {
          ...defaultToastProps,
          type: 'error',
        });
      }
    } else if (+activeStep === 2) {
      if (validatePrice(itemFields.price)) {
        setActiveStep((prev) => prev + 1);
      } else {
        toast('Price is required', {
          ...defaultToastProps,
          type: 'error',
        });
      }
    } else if (+activeStep === 4) {
      setActiveStep((prev) => prev + 1);
    } else if (+activeStep === 6) {
      setItemCreated(true);

      if (itemImages.length > 0) {
        dispatch(
          loadImagesItem({
            id: itemFields.id,
            photos: itemImages,
            history: history,
            setItemCreated,
            globalCategoryId: activeGlobalMenu,
          }),
        );
      } else {
        // @ts-ignore
        history.push('/menu');
      }
    } else {
      setActiveStep((prev) => {
        if (+prev + 1 === 6 && !itemCreated) {
          dispatch(
            addItem({
              setActiveStep,
              setItemCreated,
              globalMenuId: activeGlobalMenu,
            }),
          );
          return prev;
        } else {
          return +activeStep !== 6 ? prev + 1 : prev;
        }
      });
    }
  };

  return (
    <div className="w-full flex justify-end justify-self-end mt-2 md:mt-auto">
      <div className="flex items-center space-x-4 rtl:space-x-reverse">
        {+activeStep !== 0 && (
          <button
            disabled={loading || loadingImage}
            onClick={() => {
              setActiveStep((prev) => prev - 1);
            }}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="previous" />
          </button>
        )}

        <button
          data-testId="cypress-add-item-next"
          onClick={() => handleNextButton()}
          disabled={loading || loadingImage}
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
        >
          {nextButtonContent()}
        </button>
      </div>
    </div>
  );
};
