import { API } from 'api';
import { defaultToastProps } from 'appConstants';
import { Languages, SetStateType } from 'commonTypes';
import { toast } from 'react-toastify';
import { store } from 'store';

export interface TranslateProps {
  source_lang: Languages;
  text: string;
  setLoading: SetStateType<boolean>;
  setData: SetStateType<Record<Languages, string> | object | Record<string, never>>;
  setTranslateActiveTab?: SetStateType<number> | null;
  setActiveStep?: SetStateType<number> | null;
}

export const fetchLambdaTranslate =
  ({
    source_lang,
    text,
    setLoading,
    setData,
    setTranslateActiveTab = null,
    setActiveStep = null,
  }: TranslateProps) =>
  async () => {
    setLoading(true);
    setData((prev) => {
      if (Object.keys(prev).length > 0) {
        // @ts-ignore
        prev[source_lang] = text;
        return prev;
      } else {
        return {};
      }
    });
    const accLang = store.getState().account.account?.languages;
    const body = {
      source_language: source_lang,
      text: text,
    };
    API.v1.translate
      .fetchTranslate(body)
      .then(({ data: answer }) => {
        const bufAnswer: typeof answer = JSON.parse(JSON.stringify(answer));

        accLang?.forEach((lang) => {
          setData((prev) => {
            if (lang === source_lang) {
              // @ts-ignore
              prev[lang] = text;
            } else {
              // @ts-ignore
              prev[lang] = answer[lang];
            }
            return prev;
          });

          // @ts-ignore
          delete bufAnswer[lang];
        });
        Object.keys(bufAnswer).forEach((key) => {
          setData((prev) => {
            // @ts-ignore
            prev[key] = bufAnswer[key];
            return prev;
          });
        });
        if (setTranslateActiveTab) {
          setLoading(false);
          setTranslateActiveTab(1);
        }
        if (setActiveStep) {
          setLoading(false);
          setActiveStep((prev) => prev + 1);
        }
      })
      .catch((e) => {
        setData({});
        setLoading(false);
        if (typeof e === 'string') {
          toast(e, {
            ...defaultToastProps,
            type: 'error',
          });
        } else if (e instanceof Error) {
          toast(e.message, {
            ...defaultToastProps,
            type: 'error',
          });
        }
      });
  };

export interface SelectionTranslateProps {
  source_lang: Languages;
  setLoading: SetStateType<boolean>;
  setTranslateActiveTab: SetStateType<number>;
  selections: any;
  setSelections: SetStateType<any>;
}

export const fetchLambdaTranslateSelection =
  ({
    source_lang,
    selections,
    setLoading,
    setTranslateActiveTab,
    setSelections,
  }: SelectionTranslateProps) =>
  async () => {
    setLoading(true);
    const accLang = store.getState().account.account?.languages;
    const promises = [];

    for (let i = 0; i < Object.keys(selections).length; i++) {
      const selectionId = Object.keys(selections)[i];

      if (
        selections[selectionId].name !==
        selections[selectionId].nameTranslate[source_lang]
      ) {
        setSelections((prev: any) => {
          return {
            ...prev,
            [selectionId]: {
              ...prev[selectionId],
              nameTranslate: {
                ...prev[selectionId].nameTranslate,
                [source_lang]: selections[selectionId].name,
              },
            },
          };
        });

        const body = {
          source_language: source_lang,
          text: selections[selectionId].name,
        };

        const promise = API.v1.translate
          .fetchTranslate(body)
          .then(({ data: answer }) => {
            accLang?.forEach((lang) => {
              setSelections((prev: any) => {
                if (lang === source_lang) {
                  return {
                    ...prev,
                    [selectionId]: {
                      ...prev[selectionId],
                      nameTranslate: {
                        ...prev[selectionId]?.nameTranslate,
                        [lang]: selections[selectionId]?.name,
                      },
                    },
                  };
                } else {
                  return {
                    ...prev,
                    [selectionId]: {
                      ...prev[selectionId],
                      nameTranslate: {
                        ...prev[selectionId]?.nameTranslate,
                        [lang]: answer[lang],
                      },
                    },
                  };
                }
              });
            });

            Object.keys(answer).forEach((key) => {
              setSelections((prev: any) => {
                return {
                  ...prev,
                  [selectionId]: {
                    ...prev[selectionId],
                    nameTranslate: {
                      ...prev[selectionId].nameTranslate,
                      // @ts-ignore
                      [key]: answer[key],
                    },
                  },
                };
              });
            });
          })
          .catch((e) => {
            if (typeof e === 'string') {
              toast(e, {
                ...defaultToastProps,
                type: 'error',
              });
            } else if (e instanceof Error) {
              toast(e.message, {
                ...defaultToastProps,
                type: 'error',
              });
            }
          });

        promises.push(promise);
      }
    }

    Promise.all(promises).then(() => {
      setLoading(false);
      setTranslateActiveTab(3);
    });
  };
