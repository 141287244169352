import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { SuggestionModal } from '../ChangeModals/SuggestionModal';
import { EmptyTab, SuggestionComponent } from '../UI';

interface SuggestionTabProps {
  activeGlobalMenu: string;
  setActiveGlobalMenu: SetStateType<string>;
}

export const SuggestionTab = ({
  activeGlobalMenu,
  setActiveGlobalMenu,
}: SuggestionTabProps) => {
  const activeItem = useAppSelector((state) => state.item.activeItem);
  const account = useAppSelector((state) => state.account.account);
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);

  if (activeItem?.suggestions && activeItem?.suggestions?.length > 0) {
    return (
      <div>
        {account && (
          <SuggestionModal
            open={showSuggestionModal}
            setOpen={setShowSuggestionModal}
            suggestion={activeItem?.suggestions}
            item={activeItem}
            account={account}
            activeGlobalMenu={activeGlobalMenu}
          />
        )}

        <div className="w-full flex items-center justify-end">
          <Menu
            as="div"
            className="relative ltr:ml-3 rtl:mr-3 inline-block ltr:text-left rtl:text-right"
          >
            <div>
              <Menu.Button className="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 md:hover:text-gray-600 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute ltr:right-0 rtl:left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none">
                <div className="py-1">
                  {/* {activeItem?.suggestions?.length > 1 && (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => setShowChangeOrderSuggestion(true)}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm cursor-pointer',
                          )}
                        >
                          <Bars4Icon
                            className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          <span className="truncate">
                            <FormattedMessage id="changeSuggestionsOrder" />
                          </span>
                        </div>
                      )}
                    </Menu.Item>
                  )} */}
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => setShowSuggestionModal(true)}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm cursor-pointer',
                        )}
                      >
                        <PencilSquareIcon
                          className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        <span className="truncate">
                          <FormattedMessage id="edit" />
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className="mt-5 grid grid-cols-2 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 xl:gap-x-8">
          {activeItem.suggestions.map((product) => (
            <SuggestionComponent
              product={product}
              key={'item-card-' + product}
              setActiveGlobalMenu={setActiveGlobalMenu}
            />
          ))}
        </div>
      </div>
    );
  } else if (activeItem && account) {
    return (
      <>
        <EmptyTab setOpen={setShowSuggestionModal} />
        <SuggestionModal
          open={showSuggestionModal}
          setOpen={setShowSuggestionModal}
          suggestion={activeItem?.suggestions || []}
          item={activeItem}
          account={account}
          activeGlobalMenu={activeGlobalMenu}
        />
      </>
    );
  } else {
    return null;
  }
};
