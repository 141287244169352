import { ReactComponent as GoogleMapsIcon } from 'assets/static/googleMapsIcon.svg';
import { ReactComponent as TripAdvisorIcon } from 'assets/static/tripAdvisorIcon.svg';
import { useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { ChangeReviewsModal } from '../ChangeModals';

export const ReviewsFields = () => {
  const account = useAppSelector((state) => state.account.account);
  const [showChangeReviewsModal, setShowChangeReviewsModal] = useState(false);
  const notification = useAppSelector((state) => state.account.notification);

  useEffect(() => {
    if (notification === 'reviews') {
      setShowChangeReviewsModal(true);
    }
  }, [notification]);

  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 reviews-link items-center">
      <ChangeReviewsModal
        open={showChangeReviewsModal}
        setOpen={setShowChangeReviewsModal}
      />
      <dt className="text-sm font-medium text-gray-500">
        <FormattedMessage id="linksReview" />
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow truncate">
          <div className="flex items-center">
            {account?.google_maps && (
              <a
                href={account?.google_maps}
                target="_blank"
                className="cursor-pointer p-1 rounded-lg border border-gray-300 mr-3 md:hover:bg-gray-100"
                rel="noreferrer"
              >
                <GoogleMapsIcon className="h-6 w-6" />
              </a>
            )}
            {account?.tripadvisor && (
              <a
                href={account?.tripadvisor}
                target="_blank"
                className="cursor-pointer p-1 rounded-lg border border-gray-300 md:hover:bg-gray-100"
                rel="noreferrer"
              >
                <TripAdvisorIcon className="h-6 w-6" />
              </a>
            )}
            {!account?.google_maps && !account?.tripadvisor && 'No links'}
          </div>
        </div>
        <span className="ltr:ml-4 rtl:mr-4 flex-shrink-0">
          <button
            onClick={() => {
              setShowChangeReviewsModal(true);
            }}
            data-testId="cypress-change-reviews"
            className="rounded-md bg-white font-medium text-indigo-600 md:hover:text-indigo-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="edit" />
          </button>
        </span>
      </dd>
    </div>
  );
};
