import { InformationCircleIcon, PhoneIcon, WifiIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ChangeMainInfoModal } from '../ChangeModals/ChangeMainInfoModal';

export const MainInfoField = () => {
  const account = useAppSelector((state) => state.account.account);
  const [showChangeMainInfo, setShowChangeMainInfo] = useState(false);

  const noMainInfo = () => {
    if (
      !account?.info_description?.source_lang &&
      !account?.info_phone?.number &&
      !account?.info_wifi?.name
    ) {
      return true;
    }
  };

  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 reviews-link items-center">
      <ChangeMainInfoModal open={showChangeMainInfo} setOpen={setShowChangeMainInfo} />
      <dt className="text-sm font-medium text-gray-500">
        <FormattedMessage id="mainInfo" />
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow truncate">
          <div className="flex items-center">
            {account?.info_description?.source_lang && (
              <div className="p-1 rounded-lg border border-gray-300 mr-3">
                <InformationCircleIcon className="h-6 w-6" />
              </div>
            )}
            {account?.info_phone?.number && (
              <div className="p-1 rounded-lg border border-gray-300 mr-3">
                <PhoneIcon className="h-6 w-6" />
              </div>
            )}
            {account?.info_wifi?.name && (
              <div className="p-1 rounded-lg border border-gray-300 mr-3">
                <WifiIcon className="h-6 w-6" />
              </div>
            )}
            {noMainInfo() && 'No'}
          </div>
        </div>
        <span className="ltr:ml-4 rtl:mr-4 flex-shrink-0">
          <button
            onClick={() => {
              setShowChangeMainInfo(true);
            }}
            data-testId="cypress-change-media"
            className="rounded-md bg-white font-medium text-indigo-600 md:hover:text-indigo-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="edit" />
          </button>
        </span>
      </dd>
    </div>
  );
};
