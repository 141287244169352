import { AxiosInstance } from 'axios';
import { Items } from 'store/menu/types';

export const item = (ApiClient: () => Promise<AxiosInstance>) => {
  return {
    changeItemAttribute: async (id: string, body: any) =>
      (await ApiClient()).put<Items>(`/item/${id}`, body),
    getItemById: async (id: string) => (await ApiClient()).get<Items>(`/item/${id}`),
    createItem: async (body: any) => (await ApiClient()).post<Items>('/item', body),
    deleteItem: async (id: string) => (await ApiClient()).delete(`/item/${id}`),
  };
};
