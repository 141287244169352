import { oldLanguages } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { Categories } from 'store/menu/types';

interface MenuTabsProps {
  activeCategory: string;
  setActiveCategory: SetStateType<string>;
  activeGlobalMenu: string;
  tabs: Categories[];
}

export const MenuTabs = ({
  activeCategory,
  setActiveCategory,
  activeGlobalMenu,
  tabs,
}: MenuTabsProps) => {
  const account = useAppSelector((state) => state.account.account);
  const locale = useAppSelector((state) => state.locale.lang);

  const defaultTab = () => {
    const defaultElement = tabs.find((tab) => tab.status === 4);
    if (defaultElement && defaultElement.name.translations) {
      return (
        <div
          role="button"
          tabIndex={0}
          key={'tab-category-item' + defaultElement.id}
          onClick={() => {
            setActiveCategory(defaultElement.id);
            localStorage.setItem(
              'activeCategory-' + activeGlobalMenu + '-' + account?.id,
              defaultElement?.id,
            );
          }}
          className={classNames(
            defaultElement.id === activeCategory
              ? 'border-indigo-500 text-indigo-600'
              : 'border-transparent text-gray-500 md:hover:text-gray-700 md:hover:border-gray-300',
            'w-auto py-4 px-4 text-center border-b-2 font-medium text-sm whitespace-nowrap cursor-pointer',
          )}
        >
          {defaultElement.name.translations[oldLanguages[locale]]}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="w-full pb-1">
      <div className="block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex w-full" aria-label="Tabs">
            {tabs.map((tab) => {
              if (tab.status !== 4) {
                return (
                  <div
                    role="button"
                    tabIndex={0}
                    key={'tab-category-item' + tab.id}
                    onClick={() => {
                      setActiveCategory(tab.id);
                      localStorage.setItem(
                        'activeCategory-' + activeGlobalMenu + '-' + account?.id,
                        tab?.id,
                      );
                    }}
                    className={classNames(
                      tab.id === activeCategory
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 md:hover:text-gray-700 md:hover:border-gray-300',
                      'w-auto py-4 px-4 text-center border-b-2 font-medium text-sm whitespace-nowrap cursor-pointer',
                    )}
                  >
                    {tab?.name?.translations &&
                      tab?.name?.translations[oldLanguages[locale]]}
                  </div>
                );
              } else {
                return null;
              }
            })}
            {defaultTab()}
          </nav>
        </div>
      </div>
    </div>
  );
};
