import { XMarkIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { CategoryDropdown } from 'components/Common/CategoryDropdown';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { moveCategory } from 'store/category/actions';
import { Categories } from 'store/menu/types';

interface ChangeCategoryMenuModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  activeGlobalMenu: string;
  activeCategoryId: string;
  categoryList: Categories[];
  setActiveCategory: SetStateType<string>;
}

export const ChangeCategoryMenuModal = ({
  open,
  setOpen,
  activeGlobalMenu,
  activeCategoryId,
  categoryList,
  setActiveCategory,
}: ChangeCategoryMenuModalProps) => {
  const dispatch = useAppDispatch();
  const [changeCategyValue, setChangeCategyValue] = useState(activeGlobalMenu);
  const loading = useAppSelector((state) => state.category.subLoading);
  const categoryListMenu = useAppSelector((state) => state.menu.menu);
  const [discardClick, setDiscardClick] = useState(0);

  const resetFields = () => {
    setOpen(false);
  };

  useEffect(() => {
    setChangeCategyValue(activeGlobalMenu);
  }, [activeGlobalMenu]);

  return (
    <AppModal open={open} size="sm:max-w-md">
      <div className="flex flex-col">
        <div className="flex w-full items-center justify-between mb-3">
          <h1>
            <FormattedMessage id="category" />
          </h1>
          <XMarkIcon
            onClick={() => {
              setOpen(false);
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div>
          <CategoryDropdown
            categoryList={categoryListMenu}
            setChangeCategyValue={setChangeCategyValue}
            changeCategyValue={changeCategyValue}
            globalMode={true}
          />
        </div>
        <div className="flex w-full justify-end mt-3">
          <div className="flex items-center space-x-3">
            {changeCategyValue === activeGlobalMenu ? (
              <button
                onClick={() => {
                  setDiscardClick(0);
                  setOpen(false);
                }}
                type="button"
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="cancel" />
              </button>
            ) : (
              <div className="flex items-center">
                <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
                  {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
                </div>
                <div className="flex items-center justify-end w-full sm:w-auto">
                  <button
                    onClick={() => {
                      if (discardClick === 0) {
                        setDiscardClick(1);
                      } else if (discardClick === 1) {
                        setDiscardClick(0);
                        setOpen(false);
                        setChangeCategyValue(activeGlobalMenu);
                      }
                    }}
                    type="button"
                    className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                  >
                    <FormattedMessage id="discardChanges" />
                  </button>
                  <button
                    disabled={loading}
                    onClick={() => {
                      dispatch(
                        moveCategory({
                          categoryId: activeCategoryId,
                          globalMenuId: activeGlobalMenu,
                          newGlobalMenuId: changeCategyValue,
                          resetFields,
                          categoryList,
                          setActiveCategory,
                        }),
                      );
                    }}
                    type="button"
                    className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                  >
                    {loading ? (
                      <Spinner color="bg-white" />
                    ) : (
                      <FormattedMessage id="save" />
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppModal>
  );
};
