import { ISelections } from 'commonTypes';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { OptionSelectionModal } from '../Modals';
import { OptionSelection } from '../UI';

interface OptionSelectionsBlockProps {
  activeSelections: ISelections[];
  id: string;
  isMulti: boolean;
}

export const OptionSelectionsBlock = ({
  activeSelections,
  id,
  isMulti,
}: OptionSelectionsBlockProps) => {
  const [showAddSelectionModal, setShowAddSelectionModal] = useState(false);

  return (
    <div className="flex flex-col">
      <h1 className="text-lg text-gray-500 mb-3">
        <FormattedMessage id="selections" />
      </h1>
      <div className="-my-0 -mx-0 md:-mx-6 lg:-mx-8 overflow-x-auto scrollbar">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 ltr:pl-4 rtl:pr-4 ltr:pr-3 rtl:pl-3 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900 ltr:sm:pl-6 rtl:sm:pr-6"
                  >
                    <FormattedMessage id="name" />
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900"
                  >
                    <FormattedMessage id="price" />
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900"
                  >
                    <FormattedMessage id="active" />
                  </th>
                  <th
                    scope="col"
                    className={`px-3 py-3.5 text-center text-sm font-semibold text-gray-900 ${
                      isMulti ? 'opacity-50' : ''
                    }`}
                  >
                    <FormattedMessage id="default" />
                  </th>
                  <th
                    scope="col"
                    className="relative py-3.5 ltr:pl-3 rtl:pr-3 ltr:pr-4 rtl:pl-4 sm:pr-6"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {activeSelections?.map((selection) => {
                  return (
                    <OptionSelection
                      isMulti={isMulti}
                      key={'active-option-selection-' + selection?.id}
                      defaultValue={selection?.default_value}
                      id={id}
                      selection={selection}
                      activeSelections={activeSelections}
                      selectionId={selection?.id}
                      selectionsLength={activeSelections?.length}
                    />
                  );
                })}
                <tr>
                  <td className="whitespace-nowrap py-3 ltr:pl-4 rtl:pr-4 rtl:pl-3 ltr:pr-3 text-sm font-medium text-gray-900 rtl:sm:pr-6 ltr:sm:pl-6">
                    <button
                      onClick={() => setShowAddSelectionModal(true)}
                      className="text-indigo-600 hover:text-indigo-900 whitespace-nowrap"
                    >
                      + <FormattedMessage id="addSelection" />
                    </button>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                  <td className="relative whitespace-nowrap py-4 ltr:pl-3 rtl:pr-3 rtl:pl-4 ltr:pr-4 flex items-center justify-between text-right text-sm font-medium rtl:sm:pr-6 ltr:sm:pl-6"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <OptionSelectionModal
        open={showAddSelectionModal}
        setOpen={setShowAddSelectionModal}
        id={id}
        addModal={true}
      />
    </div>
  );
};
