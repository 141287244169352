import { AppModal } from '@Common/AppModal';
import { Spinner } from '@Common/Spinner';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { FormattedMessage } from 'react-intl';
import { deleteTheme } from 'store/themes/actions';
import { ApiThemeState } from 'store/themes/types';

interface DeleteThemeModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  id: string;
  activeChooseTheme: ApiThemeState | undefined;
  setThemeType: SetStateType<string>;
}

export const DeleteThemeModal = ({
  open,
  setOpen,
  id,
  activeChooseTheme,
  setThemeType,
}: DeleteThemeModalProps) => {
  const dispatch = useAppDispatch();
  const themeSubLoading = useAppSelector((state) => state.themes.subLoading);

  const close = () => {
    setOpen(false);
    setTimeout(() => {}, 300);
  };

  return (
    <AppModal open={open}>
      <div className="flex w-full items-center justify-between mb-3">
        <h1>
          <FormattedMessage id="deleteTheme" />
        </h1>
        <XMarkIcon
          onClick={close}
          className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
        />
      </div>

      <div className="mt-2">
        <p className="text-base text-gray-500 flex items-center flex-wrap">
          <FormattedMessage
            id="deleteThemeMessage"
            values={{
              name: <strong>&nbsp;{activeChooseTheme?.name.toLocaleUpperCase()}</strong>,
            }}
          />
        </p>
      </div>

      <div className="flex w-full justify-end items-center mt-4">
        <button
          type="button"
          onClick={close}
          className="rounded-md mr-2 bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <FormattedMessage id="cancel" />
        </button>

        <button
          type="button"
          onClick={() => {
            dispatch(deleteTheme(id, setOpen, setThemeType));
          }}
          className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          {themeSubLoading ? (
            <Spinner color="bg-white" />
          ) : (
            <FormattedMessage id="delete" />
          )}
        </button>
      </div>
    </AppModal>
  );
};
