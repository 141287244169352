import { Menu } from '@headlessui/react';
import { useAppDispatch } from 'hooks/store';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { SET_NOTIFICATION } from 'store/account/actionsTypes';

interface NotificationBlockProps {
  textId: string;
  to: string;
  state: {
    [key: string]: boolean;
  };
}

export const NotificationBlock = ({ textId, to, state }: NotificationBlockProps) => {
  const dispatch = useAppDispatch();

  const notificationId = {
    incompleteColor: 'color',
    incompleteServicesFee: 'servicesFee',
    incompleteReviews: 'reviews',
  };

  return (
    <Menu.Item>
      <div className="pointer-events-auto flex w-full max-w-md divide-x divide-gray-200 rounded-lg rounded-t-none bg-white">
        <div className="flex w-0 flex-1 items-center p-4">
          <div className="w-full">
            <p className="text-sm font-medium text-gray-900">
              <FormattedMessage id="incompleteField" />
            </p>
            <p className="mt-1 text-sm text-gray-500">
              <FormattedMessage id={textId} />
            </p>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            type="button"
            to={{
              pathname: to,
              state,
            }}
            onClick={() => {
              dispatch({
                type: SET_NOTIFICATION,
                payload: notificationId[textId as keyof typeof notificationId],
              });
            }}
            className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            <FormattedMessage id="complete" />
          </Link>
        </div>
      </div>
    </Menu.Item>
  );
};
