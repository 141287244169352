import { ModalChangeLanguage } from '@Common/ModalChangeLanguage';
import {
  InformationCircleIcon,
  PhoneIcon,
  WifiIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { phoneCodes, selectLanguages } from 'appConstants';
import { Languages, SetStateType } from 'commonTypes';
import { AppInput } from 'components/Common/AppInput';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { RegisterPhoneCodeSelect } from 'components/RegisterComponent';
import { createNameWithoutTransalte } from 'helpers/menu';
import { fetchLambdaTranslate } from 'helpers/translate';
import { classNames, phoneMask, sortedTranslateObj } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';
import { changeMediaLinks, updateAccount } from 'store/account/actions';

interface ChangeMainInfoModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
}

export const ChangeMainInfoModal = ({ open, setOpen }: ChangeMainInfoModalProps) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.account);
  const loading = useAppSelector((state) => state.account.subLoading);
  const [discardClick, setDiscardClick] = useState(0);

  const [newWifiLogin, setNewWifiLogin] = useState('');
  const [newWifiPass, setNewWifiPass] = useState('');

  const [newDescription, setNewDescription] = useState('');
  const [withoutTranslations, setWithoutTranslations] = useState(false);
  const [translationNewDescription, setTranslationNewDescription] = useState<
    Record<Languages, string> | Record<string, never> | object
  >({});
  const [selected, setSelected] = useState(
    selectLanguages.find((elem) => elem.value === account?.locale),
  );
  const [activeStep, setActiveStep] = useState(0);
  const [translateLoading, setTranslateLoading] = useState(false);

  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [newPhoneCode, setNewPhoneCode] = useState<
    | {
        code: string;
        name: string;
      }
    | undefined
  >(phoneCodes.find((phone) => phone.name === 'HU'));

  const resetFields = () => {
    setTimeout(() => {
      setActiveStep(0);
      setDiscardClick(0);
      setNewWifiLogin('');
      setNewWifiPass('');
      setNewPhoneNumber('');
      setNewPhoneCode(phoneCodes.find((phone) => phone.name === 'HU'));
    }, 300);
    setOpen(false);
  };

  const close = () => {
    setOpen(false);
    resetFields();
  };

  useEffect(() => {
    if (account?.info_wifi) {
      setNewWifiLogin(account.info_wifi.name || '');
      setNewWifiPass(account.info_wifi.password || '');
    }
    if (account?.info_phone) {
      setNewPhoneNumber(account.info_phone.number || '');
      if (account.info_phone?.code) {
        setNewPhoneCode(
          phoneCodes.find((code) => code.code === account.info_phone?.code),
        );
      } else {
        setNewPhoneCode(phoneCodes.find((code) => code.name === 'HU'));
      }
    }
    if (
      account?.info_description &&
      account.info_description.source_lang &&
      account.info_description.translations
    ) {
      setNewDescription(
        account.info_description.translations[account.info_description.source_lang],
      );
      setSelected(
        selectLanguages.find(
          (lang) => lang.value === account?.info_description?.source_lang,
        ),
      );
      setTranslationNewDescription(account.info_description.translations);
      setWithoutTranslations(account.info_description.withoutTranslate || false);
    }
  }, [open]);

  const onHandleClick = () => {
    setDiscardClick(0);
    if (activeStep === 0 && !withoutTranslations && newDescription.trim()) {
      // @ts-ignore
      if (newDescription.trim() === translationNewDescription[selected?.value]) {
        setActiveStep(1);
      } else {
        dispatch(
          fetchLambdaTranslate({
            setLoading: setTranslateLoading,
            setData: setTranslationNewDescription,
            text: newDescription.trim(),
            // @ts-ignore
            source_lang: selected?.value,
            setTranslateActiveTab: setActiveStep,
          }),
        );
      }
    } else if (
      activeStep === 1 ||
      (activeStep === 0 && (withoutTranslations || !newDescription.trim()))
    ) {
      dispatch(
        updateAccount(
          {
            info_wifi: {
              name: newWifiLogin.trim() || '',
              password: newWifiPass.trim() || '',
            },
            info_phone: {
              code: newPhoneCode?.code || '',
              number: newPhoneNumber.trim() || '',
              full: newPhoneNumber.trim()
                ? newPhoneCode?.code + newPhoneNumber.trim()
                : '',
            },
            // @ts-ignore
            info_description: newDescription.trim()
              ? {
                  source_lang: selected?.value,
                  translations: translationNewDescription,
                  withoutTranslate: withoutTranslations,
                }
              : {},
          },
          () => resetFields(),
        ),
      );
    }
  };

  const returnSubmitButtonId = () => {
    if (activeStep === 0 && (withoutTranslations || !newDescription.trim())) {
      return 'save';
    } else if (activeStep === 0 && !withoutTranslations) {
      return 'next';
    } else if (activeStep === 1) {
      return 'save';
    }
  };

  const returnCanvelButtonId = () => {
    if (activeStep === 0) {
      return 'discardChanges';
    } else {
      return 'previous';
    }
  };

  return (
    <AppModal open={open} size="sm:max-w-md">
      <div className="flex flex-col">
        <div className="flex items-center justify-between w-full mb-3">
          <h1>
            <FormattedMessage id="changeMainInfo" />
          </h1>
          <XMarkIcon
            onClick={close}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        {activeStep === 0 && (
          <div className="flex flex-col space-y-4 mb-3">
            <div className="flex items-center w-full">
              <WifiIcon className="h-6 w-6 mr-3" />
              <div className="flex flex-col flex-1">
                <input
                  type="text"
                  name="wifi-login"
                  value={newWifiLogin}
                  onChange={(e) => {
                    setNewWifiLogin(e.target.value);
                  }}
                  className={classNames(
                    'block w-full rounded-md border-gray-300 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm',
                  )}
                  placeholder="Your wi-fi login"
                />
                <input
                  type="text"
                  name="wifi-password"
                  value={newWifiPass}
                  onChange={(e) => {
                    setNewWifiPass(e.target.value);
                  }}
                  className={classNames(
                    'block w-full rounded-md border-gray-300 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm mt-2',
                  )}
                  placeholder="Your wi-fi password"
                />
              </div>
            </div>

            <div className="flex items-center w-full">
              <PhoneIcon className="h-6 w-6 mr-3" />
              <div className="relative mt-1 rounded-md shadow-sm flex-1">
                <div className="absolute inset-y-0 left-0 flex items-center">
                  <RegisterPhoneCodeSelect
                    disabled={loading}
                    phoneCode={newPhoneCode}
                    setPhoneCode={setNewPhoneCode}
                  />
                </div>
                <input
                  type="text"
                  value={newPhoneNumber}
                  disabled={loading}
                  onInput={(e) => {
                    phoneMask(e, setNewPhoneNumber);
                  }}
                  required
                  name="phone-number"
                  id="phone-number"
                  autoComplete="tel-national"
                  className="block w-full rounded-md border-gray-300 pl-28 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm"
                  placeholder="Phone Number"
                />
              </div>
            </div>

            <div className="flex items-center w-full">
              <InformationCircleIcon className="h-6 w-6 mr-3" />
              <div className="flex flex-1 flex-col">
                <div className="flex items-center justify-between w-full mb-2">
                  <ModalChangeLanguage
                    selected={selected}
                    setSelected={setSelected}
                    disabled={loading || translateLoading}
                  />
                </div>
                <TextareaAutosize
                  minRows={2}
                  disabled={loading}
                  value={newDescription}
                  onChange={(e) => {
                    if (!loading && !translateLoading) {
                      setNewDescription(e.target.value);
                      if (withoutTranslations) {
                        setTranslationNewDescription(
                          createNameWithoutTransalte(e.target.value),
                        );
                      } else {
                        setTranslationNewDescription({});
                      }
                    }
                  }}
                  name="restaurant-description"
                  className={classNames(
                    'w-full resize-none scrollbar rounded-md border-gray-300 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm',
                  )}
                  placeholder="Your restaurant description"
                />
                <div className="relative flex items-start justify-end mt-1">
                  <div className="flex h-6 items-center">
                    <input
                      id="without-translate"
                      aria-describedby="without-translate"
                      name="without-translate"
                      type="checkbox"
                      checked={withoutTranslations}
                      onChange={(e) => {
                        setWithoutTranslations(e.target.checked);
                        if (e.target.checked) {
                          if (newDescription.trim()) {
                            setTranslationNewDescription(
                              createNameWithoutTransalte(newDescription),
                            );
                          }
                        } else {
                          setTranslationNewDescription({});
                        }
                      }}
                      disabled={loading}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="without-translate"
                      className="font-medium text-gray-900 select-none"
                    >
                      <FormattedMessage id="withoutTranslate" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeStep === 1 && (
          <div className="mt-4 flex w-full flex-col space-y-4 max-h-96 overflow-auto scrollbar">
            {Object.keys(
              // @ts-ignore
              sortedTranslateObj({
                // @ts-ignore
                translate: translationNewDescription,
                langs: account?.languages || [],
              }),
            ).map((item) => {
              return (
                <div
                  key={'edit-translate-' + item}
                  className="grid grid-cols-[1fr_4fr] first:mt-1 items-center space-x-3 rtl:space-x-reverse w-full ltr:pr-2 rtl:pl-2"
                >
                  <h2 className="rtl:text-right">{item.toUpperCase()}</h2>
                  <div>
                    <TextareaAutosize
                      minRows={2}
                      className="w-full scrollbar rounded-md border-gray-300 sm:text-sm"
                      placeholder="Services fee text"
                      // @ts-ignore
                      value={translationNewDescription[item]}
                      disabled={loading || translateLoading}
                      onChange={(e) => {
                        if (!loading && !translateLoading) {
                          setTranslationNewDescription((prev) => {
                            return {
                              ...prev,
                              [item]: e.target.value,
                            };
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <div className="w-full flex items-center justify-end">
          <div className="flex items-center">
            <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
              {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
            </div>
            <div className="flex items-center justify-end w-full sm:w-auto">
              <button
                type="button"
                onClick={() => {
                  if (activeStep === 0) {
                    if (discardClick === 0) {
                      setDiscardClick(1);
                    } else {
                      close();
                    }
                  } else {
                    setActiveStep(0);
                  }
                }}
                className="ltr:mr-3 rtl:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id={returnCanvelButtonId()} />
              </button>
              <button
                type="button"
                onClick={onHandleClick}
                data-testId="cypress-media-save"
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                {loading || translateLoading ? (
                  <Spinner />
                ) : (
                  <FormattedMessage id={returnSubmitButtonId()} />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </AppModal>
  );
};
