import {
  RESET_MENU,
  START_CHANGE_MENU,
  START_CREATE_MENU,
  START_DELETE_MENU,
  START_ENABLE_MENU,
  START_FETCH_MENU,
  STOP_CHANGE_MENU,
  STOP_CREATE_MENU,
  STOP_DELETE_MENU,
  STOP_ENABLE_MENU,
  STOP_FETCH_MENU,
  SUCCESS_CREATE_MENU,
  SUCCESS_DELETE_MENU,
  SUCCESS_FETCH_MENU,
  SUCCESS_UPDATE_MENU,
} from './actionsTypes';
import { MenuAction, MenuStoreState } from './types';

const initialState: MenuStoreState = {
  loading: false,
  error: null,
  menu: [],
  subLoading: false,
};

export const menuReducer = (state: MenuStoreState = initialState, action: MenuAction) => {
  switch (action.type) {
    case START_FETCH_MENU:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_FETCH_MENU:
      return {
        ...state,
        loading: false,
        menu: action.payload.menu,
      };
    case STOP_FETCH_MENU:
      return {
        ...state,
        loading: false,
      };
    case START_CREATE_MENU:
    case START_DELETE_MENU:
    case START_ENABLE_MENU:
    case START_CHANGE_MENU:
      return {
        ...state,
        subLoading: true,
        error: null,
      };
    case STOP_CREATE_MENU:
    case STOP_DELETE_MENU:
    case STOP_ENABLE_MENU:
    case STOP_CHANGE_MENU:
      return {
        ...state,
        subLoading: false,
        error: null,
      };
    case SUCCESS_CREATE_MENU:
      return {
        ...state,
        subLoading: false,
        menu: [...state.menu, action.payload],
      };
    case SUCCESS_DELETE_MENU:
    case SUCCESS_UPDATE_MENU:
      return {
        ...state,
        subLoading: false,
        menu: action.payload,
      };
    case RESET_MENU:
      return initialState;
    default:
      return state;
  }
};
