import { AppModal } from '@Common/AppModal';
import { FreeCheckBox } from '@Common/FreeCheckBox';
import { Spinner } from '@Common/Spinner';
import { Switch } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { defaultToastProps } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { classNames, positiveNumberInput } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { changeFlagTakeAway } from 'store/account/actions';

interface ChangeTakeAwayModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
}
export const ChangeTakeAwayModal = ({ open, setOpen }: ChangeTakeAwayModalProps) => {
  const dispatch = useAppDispatch();
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const subLoading = useAppSelector((state) => state.account.subLoading);
  const account = useAppSelector((state) => state.account.account);
  const [takeAway, setTakeAway] = useState(false);
  const [price, setPrice] = useState('');
  const [free, setFree] = useState(false);
  const [discardClick, setDiscardClick] = useState(0);

  useEffect(() => {
    if (account?.take_away) {
      setTakeAway(account.take_away.show);
      if (account.take_away.price === '-1') {
        setFree(true);
      } else {
        setPrice(account.take_away.price);
      }
    } else {
      setPrice('');
      setFree(false);
      setTakeAway(false);
    }
  }, [account, open]);

  useEffect(() => {
    if (free) {
      setPrice('-1');
    } else {
      setPrice('');
    }
  }, [free]);

  const close = () => {
    setDiscardClick(0);
    setOpen(false);
  };

  return (
    <AppModal size="sm:max-w-lg" open={open}>
      <div className="flex items-center w-full justify-between">
        <h1>
          <FormattedMessage id="changeTakeAwayOption" />
        </h1>
        <XMarkIcon
          onClick={close}
          className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
        />
      </div>

      <div className="w-full grid grid-cols-2 mt-4 pb-2 border-b mb-2">
        <div>
          <FormattedMessage id="takeAwayOption" />
        </div>

        <div className=" justify-self-end">
          <Switch
            disabled={subLoading}
            data-testId="cypress-take-away-switch"
            checked={takeAway}
            onChange={(v: boolean) => setTakeAway(v)}
            className={classNames(
              takeAway ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                takeAway ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none flex items-center justify-center h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              )}
            />
          </Switch>
        </div>
      </div>

      <div className="flex-grow">
        <div className="w-full flex items-center justify-between">
          <div>
            <FormattedMessage id="costOfPackaging" />
          </div>
          <div className="flex items-center space-x-4">
            <FreeCheckBox disabled={subLoading} free={free} setFree={setFree} />
          </div>
        </div>

        <div className="relative mt-2 rounded-md shadow-sm">
          <input
            type="text"
            name="price"
            id="price"
            disabled={free || subLoading}
            className={classNames(
              'block w-full rounded-md border-gray-300 rtl:pl-12 ltr:pr-12 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm',
              free && 'opacity-70',
            )}
            placeholder={intCurrency ? '0' : '0.00'}
            aria-describedby="price-currency"
            value={price === '-1' ? '0' : price}
            onChange={(e) => {
              setPrice(() => {
                const newVal = positiveNumberInput(e, intCurrency);
                return newVal;
              });
            }}
          />
          <div className="pointer-events-none absolute inset-y-0 rtl:left-0 ltr:right-0 flex items-center rtl:pl-3 ltr:pr-3">
            <span className="text-gray-500 sm:text-sm" id="price-currency">
              {account?.currency}
            </span>
          </div>
        </div>
      </div>

      <div className="w-full flex items-center justify-end mt-5">
        {account?.take_away?.show === takeAway && account.take_away.price === price ? (
          <button
            type="button"
            onClick={close}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="cancel" />
          </button>
        ) : (
          <div className="flex items-center flex-nowrap">
            <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2 hidden md:block">
              {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
            </div>
            <div className="flex items-center justify-end w-full sm:w-auto">
              <button
                type="button"
                onClick={() => {
                  if (discardClick === 0) {
                    setDiscardClick(1);
                  } else {
                    close();
                  }
                }}
                className="ltr:mr-3 rtl:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="discardChanges" />
              </button>
              <button
                type="button"
                onClick={() => {
                  if (!price) {
                    toast('Price is required', {
                      ...defaultToastProps,
                      type: 'error',
                    });
                  } else {
                    dispatch(
                      changeFlagTakeAway({
                        show: takeAway,
                        price: price,
                        close: close,
                      }),
                    );
                  }
                }}
                data-testId="cypress-domain-save"
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                {subLoading ? (
                  <Spinner color="bg-white" />
                ) : (
                  <FormattedMessage id="save" />
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </AppModal>
  );
};
