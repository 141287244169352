import { API } from 'api';
import { defaultToastProps } from 'appConstants';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { store } from 'store';
import { AppThunk } from 'store/globalTypes';

import {
  START_GPT_REQUEST,
  STOP_GPT_REQUEST,
  UPDATE_CHAT_GPT_CUSTOM_PROMPT,
  UPDATE_CHAT_GPT_PROMPT,
  UPDATE_CHAT_GPT_RESULT,
} from './actionTypes';
import {
  FetchResponseBody,
  GetGptResponseProps,
  GPTAction,
  UpdateChatGPTCustomPromptAction,
  UpdateChatGPTPromptAction,
  UpdateChatGPTResultAction,
} from './types';

export const removePrompt =
  ({ id }: { id: number }) =>
  (dispatch: Dispatch<UpdateChatGPTPromptAction>) => {
    const oldListPrompts = store.getState().gpt.chatGPT.listPrompts;

    const newListPrompts = oldListPrompts.filter((prompt) => prompt.id !== id);

    dispatch({
      type: UPDATE_CHAT_GPT_PROMPT,
      payload: newListPrompts,
    });
  };

export const addPrompt =
  ({ text }: { text: string }) =>
  (dispatch: Dispatch<UpdateChatGPTPromptAction>) => {
    const oldListPrompts = store.getState().gpt.chatGPT.listPrompts;

    const newListPrompts = [
      ...oldListPrompts,
      {
        text: text,
        id:
          oldListPrompts.length > 0
            ? +oldListPrompts[oldListPrompts.length - 1].id + 1
            : 1,
      },
    ];
    dispatch({
      type: UPDATE_CHAT_GPT_PROMPT,
      payload: newListPrompts,
    });
  };

export const changeCustomPrompt =
  ({ value }: { value: string }) =>
  (dispatch: Dispatch<UpdateChatGPTCustomPromptAction>) => {
    dispatch({
      type: UPDATE_CHAT_GPT_CUSTOM_PROMPT,
      payload: value,
    });
  };

export const changeResult =
  ({ value }: { value: string }) =>
  (dispatch: Dispatch<UpdateChatGPTResultAction>) => {
    dispatch({
      type: UPDATE_CHAT_GPT_RESULT,
      payload: value,
    });
  };

export const getGptResponse: AppThunk =
  ({ prompt, setActiveStep }: GetGptResponseProps) =>
  async (dispatch: Dispatch<GPTAction>) => {
    dispatch({
      type: START_GPT_REQUEST,
    });

    const body: FetchResponseBody = {
      prompt,
    };

    API.v1.gpt
      .fetchResponse(body)
      .then(({ data }) => {
        dispatch({
          type: UPDATE_CHAT_GPT_RESULT,
          payload: data.body,
        });
        dispatch({
          type: STOP_GPT_REQUEST,
        });
        setActiveStep(2);
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_GPT_REQUEST,
        });
      });
  };
