//eslint-ignore

export const NoMustard = ({ width = 32, height = 32, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 248 248"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_417_432)">
      <path
        d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
        fill="#6BA7FF"
      />
      <path
        d="M219.159 163.788L97.8279 42.4564L34.6841 105.6L152.74 223.656C182.115 213.454 206.071 191.68 219.159 163.788Z"
        fill="#2681FF"
      />
      <path
        d="M199.668 191.896H174.202C160.525 191.896 149.396 180.768 149.396 167.091V162.13H174.863C188.54 162.13 199.668 173.258 199.668 186.935V191.896Z"
        fill="#DD455B"
      />
      <path
        d="M136.416 137.172L132.908 140.68L129.4 144.188V158.22L143.432 172.252L153.956 161.728L164.48 151.204L150.448 137.172H136.416Z"
        fill="#F8E997"
      />
      <path
        d="M150.448 137.172H136.416L132.908 140.68L153.956 161.728L164.48 151.204L150.448 137.172Z"
        fill="#EAAD44"
      />
      <path
        d="M73.6967 81.4691L49.1682 105.998L102.24 147.497C109.652 153.259 120.033 157.748 129.4 158.22L139.923 147.696L150.447 137.172C149.975 127.805 145.486 117.423 139.714 110.002L98.2248 56.9406L73.6967 81.4691Z"
        fill="#F2D049"
      />
      <path
        d="M139.714 110.002L98.2248 56.9406L73.6963 81.4691L139.923 147.696L150.447 137.172C149.975 127.805 145.486 117.423 139.714 110.002Z"
        fill="#E58E40"
      />
      <path
        d="M76.78 42.4564L55.732 63.5043L34.6841 84.5522C28.881 90.3553 28.881 99.797 34.6841 105.6L45.2079 116.124L76.7795 84.5527L108.351 52.981L97.8274 42.4573C92.0243 36.6528 82.5831 36.6528 76.78 42.4564Z"
        fill="#F8E997"
      />
      <path
        d="M97.8278 42.4564C92.0247 36.6533 82.583 36.6533 76.7799 42.4564L55.7319 63.5043L76.7799 84.5522L108.352 52.9806L97.8278 42.4564Z"
        fill="#F2D049"
      />
      <path
        d="M101.336 123.991C109.556 123.991 116.219 117.328 116.219 109.108C116.219 100.889 109.556 94.2253 101.336 94.2253C93.1164 94.2253 86.4531 100.889 86.4531 109.108C86.4531 117.328 93.1164 123.991 101.336 123.991Z"
        fill="#E37A4E"
      />
      <path
        d="M111.86 98.584C106.048 92.7719 96.6239 92.7719 90.8118 98.584L111.86 119.632C117.672 113.82 117.672 104.396 111.86 98.584Z"
        fill="#DD455B"
      />
      <circle cx="115" cy="115" r="110" stroke="black" strokeWidth="10" />
      <line
        x1="35.7109"
        y1="32.649"
        x2="184.711"
        y2="197.649"
        stroke="black"
        strokeWidth="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_432">
        <rect width="230" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
