import { XMarkIcon } from '@heroicons/react/24/outline';
import { defaultLabels, labelsList } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { SwitchLabel } from 'components/Common/SwitchLabel';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { editItemField } from 'store/item/actions';

interface ChangeLabelsModalProps {
  openLabelsChange: boolean;
  setOpenLabelsChange: SetStateType<boolean>;
  globalCategoryId: string;
}

export const ChangeLabelsModal = ({
  openLabelsChange,
  setOpenLabelsChange,
  globalCategoryId,
}: ChangeLabelsModalProps) => {
  const activeItem = useAppSelector((state) => state.item.activeItem);
  const [labels, setLabels] = useState(activeItem?.labels || defaultLabels);
  const loading = useAppSelector((state) => state.item.subLoading);
  const dispatch = useAppDispatch();
  const [discardClick, setDiscardClick] = useState(0);

  useEffect(() => {
    if (activeItem?.labels) {
      setLabels(activeItem?.labels || defaultLabels);
    }
  }, [activeItem, openLabelsChange]);

  return (
    <AppModal open={openLabelsChange} size="sm:max-w-lg">
      <div className="flex flex-col">
        <div className="flex w-full items-center justify-between mb-3">
          <h1>
            <FormattedMessage id="labels" />
          </h1>
          <XMarkIcon
            onClick={() => {
              setOpenLabelsChange(false);
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="w-full flex flex-col space-y-2 max-h-96 overflow-auto pr-1 scrollbar">
          {labelsList.map((label) => (
            <SwitchLabel
              key={'label-' + label.id}
              label={label.label}
              labels={labels}
              setLabels={setLabels}
            />
          ))}
        </div>

        <div className="flex w-full justify-end mt-3">
          <div className="flex items-center space-x-3 rtl:space-x-reverse">
            {JSON.stringify(labels) === JSON.stringify(activeItem?.labels) ? (
              <button
                onClick={() => {
                  setDiscardClick(0);
                  setOpenLabelsChange(false);
                }}
                type="button"
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="cancel" />
              </button>
            ) : (
              <div className="flex items-center">
                <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
                  {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
                </div>
                <div className="flex items-center justify-end w-full sm:w-auto">
                  <button
                    onClick={() => {
                      if (discardClick === 0) {
                        setDiscardClick(1);
                      } else if (discardClick === 1) {
                        setDiscardClick(0);
                        setOpenLabelsChange(false);
                        setLabels(activeItem?.labels || defaultLabels);
                      }
                    }}
                    type="button"
                    className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                  >
                    <FormattedMessage id="discardChanges" />
                  </button>
                  <button
                    onClick={() => {
                      setDiscardClick(0);
                      dispatch(
                        editItemField({
                          id: activeItem?.id,
                          value: labels,
                          field: 'labels',
                          setOpen: setOpenLabelsChange,
                          globalCategoryId,
                        }),
                      );
                    }}
                    type="button"
                    className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                  >
                    {loading ? (
                      <Spinner color="bg-white" />
                    ) : (
                      <FormattedMessage id="save" />
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppModal>
  );
};
