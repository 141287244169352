import { AxiosInstance } from 'axios';
import { ITheme } from 'commonTypes';
import { ApiThemeState, ThemeUpdateRequestBody } from 'store/themes/types';

export const theme = (ApiClient: () => Promise<AxiosInstance>) => {
  return {
    getThemes: async () => (await ApiClient()).get<ApiThemeState[]>(`/theme`),
    cretaeTheme: async (body: { name: string; colors: ITheme }) =>
      (await ApiClient()).post<ApiThemeState>(`/theme`, body),
    deleteTheme: async (id: string) => (await ApiClient()).delete(`/theme/${id}`),
    updateTheme: async (id: string, body: ThemeUpdateRequestBody) =>
      (await ApiClient()).put<ApiThemeState>(`/theme/${id}`, body),
    updateActiveTheme: async (id: string) =>
      (await ApiClient()).put<ApiThemeState[]>(`/theme/status/${id}`),
  };
};
