import { AxiosInstance } from 'axios';
import { IOptions, ISelections } from 'commonTypes';
import {
  AddOptionBody,
  AddSelectionBody,
  ChangeStatusOptionBody,
} from 'store/options/types';

export const options = (ApiClient: () => Promise<AxiosInstance>) => {
  return {
    getOptions: async () => (await ApiClient()).get<IOptions[]>('/option/list'),
    deleteOptions: async (id: string) => (await ApiClient()).delete(`/option/${id}`),
    createOption: async (body: AddOptionBody) =>
      (await ApiClient()).post<IOptions>('/option', body),
    editOption: async (id: string, body: any) =>
      (await ApiClient()).put<Omit<IOptions, 'selections'>>(`/option/${id}`, body),
    createSelection: async (body: AddSelectionBody) =>
      (await ApiClient()).post<ISelections>('/selection', body),
    editSelection: async (id: string, body: any) =>
      (await ApiClient()).put<ISelections>(`/selection/${id}`, body),
    editDefaultSelection: async (body: any) =>
      (await ApiClient()).put(`/selection/default`, body),
    deleteSelection: async (id: string) => (await ApiClient()).delete(`/selection/${id}`),
    updateItemOptionsRelations: async (id: string, body: any) =>
      (await ApiClient()).put(`/item_option/${id}`, body),
    changeStatusOption: async (
      mode: 'selection' | 'option',
      id: string,
      body: ChangeStatusOptionBody,
    ) => (await ApiClient()).put<IOptions | ISelections>(`/${mode}/${id}`, body),
  };
};
