import { Popover, Transition } from '@headlessui/react';
import { ITheme, SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppDispatch } from 'hooks/store';
import { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-tooltip';
import { updateTheme } from 'store/themes/actions';
import { ApiThemeState } from 'store/themes/types';

import { ConfirmChangeColorModal } from '../Modals';

const MiniColorPlate = ({
  color,
  active = false,
  onClick,
}: {
  color: string;
  active?: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      className={classNames(
        'w-7 h-7 rounded-full',
        active ? 'ring ring-primary' : 'border border-gray-300',
      )}
      data-testId={'cypress-color-btn-' + color.toLowerCase()}
      onClick={onClick}
      style={{ background: color }}
    />
  );
};

interface ChangeColorButtonProps {
  setPickColorOpen: SetStateType<boolean>;
  theme: ITheme | undefined;
  id: string | undefined;
  choosen: string;
  setTheme: SetStateType<ApiThemeState | undefined>;
  colorName: string;
  disabled?: boolean;
  active: boolean;
}

export const ChangeColorButton = ({
  setPickColorOpen,
  theme,
  choosen,
  setTheme,
  colorName,
  id,
  active,
  disabled = false,
}: ChangeColorButtonProps) => {
  const dispatch = useAppDispatch();
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openColorPlate, setOpenColorPlate] = useState(false);

  const closeColorPlate = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest('.change-color-popover')) setOpenColorPlate(false);
  };

  useEffect(() => {
    window.addEventListener('click', closeColorPlate);
    return () => {
      window.removeEventListener('click', closeColorPlate);
    };
  }, []);

  return (
    <>
      <ConfirmChangeColorModal
        open={openModalConfirm}
        setOpen={setOpenModalConfirm}
        setOpenColorPlate={setOpenColorPlate}
      />
      {disabled && (
        <Tooltip
          anchorSelect={'.change-color-btn-' + colorName}
          // isOpen={openTooltip}
          style={{
            backgroundColor: 'rgb(255, 255, 255)',
            color: '#000',
            border: '1px solid #d1d5db',
            opacity: 1,
          }}
          place="top"
          id="cannot-edit-theme"
          classNameArrow="border border-gray-300 border-t-0 border-l-0"
        >
          <FormattedMessage id="cannotEditTheme" />
        </Tooltip>
      )}
      <Popover className="relative change-color-popover">
        <Popover.Button
          disabled={disabled}
          data-testId="cypress-change-color-btn"
          className={classNames(
            'inline-flex disabled:cursor-not-allowed items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500',
            disabled ? 'opacity-80' : 'hover:bg-indigo-500',
            'change-color-btn-' + colorName,
          )}
          onClick={() => {
            if (active) {
              setOpenModalConfirm(true);
            } else {
              setOpenColorPlate((prev) => !prev);
            }
          }}
        >
          <FormattedMessage id="changeColor" />
        </Popover.Button>

        <Transition
          show={openColorPlate}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            className={classNames(
              'absolute left-1/2 z-10 mt-1 -translate-x-2/3 transform px-4 sm:px-0 w-40',
            )}
          >
            <div className="grid grid-cols-4 gap-2 bg-white p-3 rounded-lg border border-gray-300">
              {theme?.colors.map((color) => (
                <MiniColorPlate
                  key={'mini-color-plate-' + color.id}
                  color={color.value}
                  active={choosen?.toLowerCase() === color?.value?.toLowerCase()}
                  onClick={() => {
                    dispatch(
                      updateTheme(id, {
                        colors: {
                          ...theme,
                          [colorName]: color.value,
                        },
                      }),
                    );
                    setTheme((prev) => {
                      if (prev) {
                        const newTheme: ApiThemeState = {
                          ...prev,
                          colors: {
                            ...prev.colors,
                            [colorName]: color.value,
                          },
                        };
                        return newTheme;
                      } else {
                        return undefined;
                      }
                    });
                  }}
                />
              ))}

              <button
                onClick={() => setPickColorOpen(true)}
                className="w-7 h-7 rounded-full border flex items-center justify-center border-gray-300 text-gray-500 text-2xl cursor-pointer hover:opacity-80"
              >
                +
              </button>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
};
