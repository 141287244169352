import {
  ADD_ITEM_REQUEST,
  ADD_ITEM_SUCCESS,
  EDIT_ITEM_FIELD,
  RESET_ACTIVE_ITEM,
  RESET_ITEM,
  SET_ACTIVE_ITEM,
  START_ACTIVE_REQUEST,
  START_SUB_ITEM_LOADING,
  STOP_ACTIVE_REQUEST,
  STOP_ADD_ITEM,
  STOP_SUB_ITEM_LOADING,
} from './actionsTypes';
import { ItemAction, ItemStore } from './types';

const initState: ItemStore = {
  addedItem: {},
  activeItem: null,
  loading: false,
  subLoading: false,
  error: null,
};

export const addItemReducer = (state: ItemStore = initState, action: ItemAction) => {
  switch (action.type) {
    case START_SUB_ITEM_LOADING: {
      return {
        ...state,
        subLoading: true,
      };
    }
    case STOP_SUB_ITEM_LOADING: {
      return {
        ...state,
        subLoading: false,
      };
    }
    case START_ACTIVE_REQUEST:
    case ADD_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SET_ACTIVE_ITEM:
      return {
        ...state,
        loading: false,
        error: null,
        activeItem: action.payload,
      };
    case RESET_ACTIVE_ITEM:
      return {
        ...state,
        loading: false,
        error: null,
        activeItem: null,
      };
    case STOP_ACTIVE_REQUEST:
    case STOP_ADD_ITEM:
      return {
        ...state,
        loading: false,
      };
    case EDIT_ITEM_FIELD: {
      return {
        ...state,
        addedItem: {
          ...state.addedItem,
          [action.payload.field]:
            action.payload.field === 'availability'
              ? {
                  time: action.payload.value?.time
                    ? action.payload.value?.time
                    : state.addedItem.availability?.time,
                  days: action.payload.value?.days
                    ? action.payload.value?.days
                    : state.addedItem.availability?.days,
                }
              : action.payload.value,
        },
      };
    }
    case RESET_ITEM: {
      return {
        ...state,
        addedItem: {},
      };
    }
    default:
      return state;
  }
};
