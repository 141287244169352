import { PlusIcon } from '@heroicons/react/20/solid';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

export const EmptyItemsBlock = () => {
  const history = useHistory();
  return (
    <div className="text-center py-3 md:py-24">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-bold text-gray-900">
        <FormattedMessage id="noItems" />
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        <FormattedMessage id="getStarted" />
      </p>
      <div className="mt-6">
        <button
          type="button"
          onClick={() => {
            history.push('/add-item');
          }}
          className="inline-flex cursor-pointer items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
        >
          <PlusIcon
            className="ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2 h-5 w-5"
            aria-hidden="true"
          />
          <FormattedMessage id="newItem" />
        </button>
      </div>
    </div>
  );
};
