import { classNames } from 'helpers/validation';
import { MouseEventHandler, SVGProps } from 'react';
import { FormattedMessage } from 'react-intl';

interface ColorsTypeButtonProps {
  colorType: {
    title: string;
    icon: any;
    id: number;
    action: string[];
  };
  colorTypeChoosen: number;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const ColorsTypeButton = ({
  colorType,
  colorTypeChoosen,
  onClick,
}: ColorsTypeButtonProps) => {
  return (
    <button
      className={classNames(
        colorType.id === colorTypeChoosen
          ? 'bg-primary text-white ipad:hover:bg-white ipad:hover:text-primary'
          : 'text-primary bg-white ipad:hover:bg-primary ipad:hover:text-white',
        'cursor-pointer w-full relative rounded text-sm text-center py-1 transition border border-primary flex items-center justify-center',
      )}
      onClick={onClick}
    >
      <colorType.icon
        className="h-full w-6 absolute top-0 ltr:left-1 rtl:right-1"
        aria-hidden="true"
      />
      <div className="max-w-[70%]">
        <FormattedMessage id={colorType.title} />
      </div>
    </button>
  );
};
