import { selectLanguages } from 'appConstants';
import { SetStateType } from 'commonTypes';
import TextareaAutosize from 'react-textarea-autosize';

interface AddOptionSecondStepProps {
  optionNameTranslate: any;
  loadingTranslate: boolean;
  setOptionNameTranslate: SetStateType<any>;
}

export const AddOptionSecondStep = ({
  optionNameTranslate,
  loadingTranslate,
  setOptionNameTranslate,
}: AddOptionSecondStepProps) => {
  return (
    <div className="mt-4 flex w-full flex-col space-y-4 mb-3">
      {Object.keys(optionNameTranslate).length > 0 &&
        !loadingTranslate &&
        Object.keys(optionNameTranslate).map((item, index) => {
          return (
            <div
              key={'edit-translate-option-name-' + index}
              className="grid grid-cols-[1fr_4fr] items-center space-x-3 rtl:space-x-reverse w-full"
            >
              <h2 className="rtl:text-right">
                {selectLanguages
                  ?.find((elem) => {
                    return elem.value === item;
                  })
                  ?.value.toUpperCase()}
              </h2>
              <div>
                <TextareaAutosize
                  minRows={2}
                  className="mb-4 last:mb-0 w-full scrollbar rounded-md border-gray-300 sm:text-sm"
                  placeholder={'Option name'}
                  // @ts-ignore
                  value={optionNameTranslate[item]}
                  disabled={loadingTranslate}
                  onChange={(e) => {
                    setOptionNameTranslate((prev: any) => {
                      return {
                        ...prev,
                        [item]: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};
