import { oldLanguages } from 'appConstants';
import { ReactComponent as NoPhoto } from 'assets/static/no_photo.svg';
import { SetStateType } from 'commonTypes';
import { PreloadComponent } from 'components/ImagePreload/PreloadComponent/PreloadComponent';
import { returnNumberPrice } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Items } from 'store/menu/types';

interface SuggestionComponentProps {
  product: Items;
  setActiveGlobalMenu: SetStateType<string>;
}

export const SuggestionComponent = ({
  product,
  setActiveGlobalMenu,
}: SuggestionComponentProps) => {
  const history = useHistory();
  const account = useAppSelector((state) => state.account.account);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const defaultPhoto = useAppSelector((state) => state.account.account?.default_photo);
  const locale = useAppSelector((state) => state.locale.lang);
  const categoryListObj = useAppSelector(
    (state) => state.category.activeCategory.categoryListObj,
  );

  const returnItemPhoto = (product: Items) => {
    if (product?.photo?.medium) {
      return (
        <PreloadComponent
          baseSrc={product?.photo?.medium}
          minimizedSrc={product?.photo?.preloader}
          className="h-full w-full object-cover object-center"
        />
      );
    } else if (defaultPhoto?.links?.medium) {
      return (
        <PreloadComponent
          baseSrc={defaultPhoto?.links?.medium}
          minimizedSrc={defaultPhoto?.links?.preloader}
          className="h-full w-full object-cover object-center"
        />
      );
    } else {
      return <NoPhoto className="h-full w-full object-cover object-center p-9" />;
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        const categoryObjArray = Object.values(categoryListObj);
        const productMenuObj = categoryObjArray.find(
          (category) => (product.category_id || '') in category,
        );
        let productCategory;
        if (productMenuObj) {
          productCategory = productMenuObj[product.category_id || ''];
        }

        if (productCategory) {
          setActiveGlobalMenu(productCategory.menu_id);
        }

        if (!target.closest('.item-action-menu')) {
          history.push(`/item/${product.id}`);
        }
      }}
      className="relative group cursor-pointer p-1 rounded-lg"
    >
      <div className="aspect-w-1 md:group-hover:opacity-75 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-w-7 xl:aspect-h-6">
        {returnItemPhoto(product)}
      </div>
      <div className="mt-4 flex w-full items-center justify-between">
        <h3 className="text-sm text-gray-700 truncate ltr:mr-3 rtl:ml-3">
          {product?.name?.translations
            ? product?.name?.translations[oldLanguages[locale]]
            : ''}
        </h3>
        {product?.status === 1 && (
          <span className="inline-flex whitespace-nowrap items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
            <FormattedMessage id="active" />
          </span>
        )}
        {product?.status === 2 && (
          <span className="inline-flex whitespace-nowrap items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
            <FormattedMessage id="soldout" />
          </span>
        )}
        {product?.status === 3 && (
          <span className="inline-flex whitespace-nowrap items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
            <FormattedMessage id="archived" />
          </span>
        )}
      </div>

      <p className="mt-1 text-base font-medium text-gray-900 truncate">
        {returnNumberPrice(product?.price) === -1 ? (
          <FormattedMessage id="free" />
        ) : (
          <FormattedNumber
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency={account?.currency}
            value={returnNumberPrice(product?.price)}
            maximumFractionDigits={intCurrency ? 0 : 2}
          />
        )}
      </p>
    </div>
  );
};
