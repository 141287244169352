//eslint-ignore

export const NoSoy = ({ width = 32, height = 32, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 248 248"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_417_280)">
      <path
        d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
        fill="#F8E997"
      />
      <path
        d="M230 115C230 111.745 229.857 108.524 229.592 105.338L177.127 52.873L174.451 50.1976L50.6472 174.002L106.321 229.676C109.186 229.89 112.08 230 115 230C178.513 230 230 178.513 230 115Z"
        fill="#F2D049"
      />
      <path
        d="M116.594 148.695C125.206 148.382 133.272 144.888 139.305 138.855C145.338 132.822 148.832 124.756 149.145 116.144C149.206 114.532 150.238 113.176 151.907 112.515C155.236 111.203 158.196 109.262 160.71 106.748C169.753 97.705 168.697 86.9351 167.929 79.07C167.731 77.032 167.532 75.1663 167.587 73.1936C167.386 69.9457 168.588 66.7635 170.89 64.4612L179.803 55.5484L177.127 52.873L174.452 50.1976L165.539 59.1105C163.237 61.4123 160.055 62.6145 156.757 62.4106C154.957 62.3242 153.196 62.1887 151.474 62.055C141.898 61.3105 132 60.5422 123.253 69.2899C120.739 71.8037 118.798 74.764 117.483 78.0951C116.825 79.7623 115.469 80.7944 113.856 80.8542C105.244 81.1679 97.1784 84.6612 91.1453 90.6943C85.1122 96.7274 81.619 104.793 81.3053 113.405C81.2449 115.018 80.2129 116.374 78.5435 117.034C75.2145 118.346 72.2542 120.287 69.7409 122.801C60.6926 131.849 61.7562 142.325 62.5294 149.975C62.7513 152.193 62.9656 154.289 62.8639 156.355C63.065 159.603 61.8633 162.786 59.561 165.088L50.6482 174.001L53.3236 176.676L55.999 179.351L64.9118 170.439C67.2136 168.137 70.3962 166.935 73.6964 167.136C75.5044 167.224 77.276 167.366 79.0134 167.504C88.5505 168.262 98.4139 169.043 107.198 160.259C109.712 157.745 111.654 154.785 112.968 151.453C113.626 149.788 114.982 148.756 116.594 148.695Z"
        fill="#BBEB7A"
      />
      <path
        d="M64.9119 170.439C67.2137 168.138 70.3963 166.935 73.6965 167.137C75.5045 167.225 77.2761 167.367 79.0135 167.505C88.5506 168.263 98.414 169.044 107.199 160.26C109.712 157.746 111.654 154.786 112.968 151.454C113.626 149.787 114.983 148.755 116.594 148.695C125.207 148.382 133.272 144.888 139.305 138.855C145.339 132.822 148.832 124.756 149.145 116.144C149.206 114.532 150.238 113.176 151.907 112.515C155.236 111.203 158.196 109.262 160.71 106.748C169.753 97.705 168.697 86.9351 167.929 79.07C167.731 77.032 167.532 75.1663 167.587 73.1936C167.386 69.9457 168.588 66.7635 170.89 64.4612L179.803 55.5484L177.128 52.873L53.3232 176.677L55.9986 179.352L64.9119 170.439Z"
        fill="#A1D45D"
      />
      <path
        d="M141.981 99.3708C148.25 99.3708 153.332 94.2886 153.332 88.0193C153.332 81.7501 148.25 76.6679 141.981 76.6679C135.712 76.6679 130.629 81.7501 130.629 88.0193C130.629 94.2886 135.712 99.3708 141.981 99.3708Z"
        fill="#A1D45D"
      />
      <path
        d="M88.4696 152.882C94.7389 152.882 99.8211 147.8 99.8211 141.531C99.8211 135.261 94.7389 130.179 88.4696 130.179C82.2004 130.179 77.1182 135.261 77.1182 141.531C77.1182 147.8 82.2004 152.882 88.4696 152.882Z"
        fill="#A1D45D"
      />
      <path
        d="M115.225 133.694C125.674 133.694 134.144 125.224 134.144 114.775C134.144 104.326 125.674 95.8555 115.225 95.8555C104.776 95.8555 96.3057 104.326 96.3057 114.775C96.3057 125.224 104.776 133.694 115.225 133.694Z"
        fill="#A1D45D"
      />
      <path
        d="M150.008 96.0459C154.434 91.6197 154.434 84.4186 150.008 79.9923L133.954 96.0459C138.38 100.472 145.581 100.472 150.008 96.0459Z"
        fill="#71A52C"
      />
      <path
        d="M96.496 149.558C100.922 145.131 100.922 137.93 96.496 133.504L80.4424 149.558C84.8686 153.984 92.0698 153.984 96.496 149.558Z"
        fill="#71A52C"
      />
      <path
        d="M128.603 128.153C135.979 120.777 135.979 108.773 128.603 101.397L101.847 128.153C109.223 135.529 121.227 135.529 128.603 128.153Z"
        fill="#71A52C"
      />
      <circle cx="115" cy="115" r="110" stroke="black" strokeWidth="10" />
      <line
        x1="35.7109"
        y1="32.649"
        x2="184.711"
        y2="197.649"
        stroke="black"
        strokeWidth="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_280">
        <rect width="230" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
