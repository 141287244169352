import { Bars4Icon } from '@heroicons/react/24/outline';
import { oldLanguages } from 'appConstants';
import { ReactComponent as NoPhoto } from 'assets/static/no_photo.svg';
import { PreloadComponent } from 'components/ImagePreload/PreloadComponent/PreloadComponent';
import { returnNumberPrice } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Items } from 'store/menu/types';

interface ItemRowBlockProps {
  item: Items;
}

export const ItemRowBlock = ({ item }: ItemRowBlockProps) => {
  const account = useAppSelector((state) => state.account.account);
  const locale = useAppSelector((state) => state.locale.lang);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const defaultPhoto = useAppSelector((state) => state.account.account?.default_photo);

  const returnItemPhoto = (product: Items) => {
    if (product?.photo?.medium) {
      return (
        <PreloadComponent
          baseSrc={product?.photo?.medium}
          minimizedSrc={product?.photo?.preloader}
          className="h-full w-full object-cover object-center rounded-full"
        />
      );
    } else if (defaultPhoto?.links?.medium) {
      return (
        <PreloadComponent
          baseSrc={defaultPhoto?.links?.medium}
          minimizedSrc={defaultPhoto?.links?.preloader}
          className="h-full w-full object-cover object-center rounded-full"
        />
      );
    } else {
      return <NoPhoto className="h-full w-full object-cover object-center p-2" />;
    }
  };

  return (
    <div className="flex items-center justify-between py-2 px-3 cursor-pointer bg-white md:hover:bg-gray-200 rounded-lg border">
      <div className="flex">
        <div className="h-10 w-10 object-cover rounded-full select-none">
          {returnItemPhoto(item)}
        </div>

        <div className="ltr:ml-3 rtl:mr-3">
          <div className="flex items-center">
            <p className="text-sm font-medium text-gray-900 select-none ">
              {item?.name?.translations
                ? item?.name?.translations[oldLanguages[locale]]
                : ''}
            </p>
            <div
              className={`h-2 w-2 ltr:ml-3 rtl:mr-3 rounded-full ${
                item?.status === 1
                  ? 'bg-green-500'
                  : item?.status === 2
                  ? 'bg-yellow-500'
                  : 'bg-gray-500'
              }`}
            />
          </div>
          <p className="text-sm text-gray-500 select-none rtl:text-right">
            {returnNumberPrice(item?.price) === -1 ? (
              <FormattedMessage id="free" />
            ) : (
              <FormattedNumber
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency={account?.currency}
                value={returnNumberPrice(item?.price)}
                maximumFractionDigits={intCurrency ? 0 : 2}
              />
            )}
          </p>
        </div>
      </div>

      <Bars4Icon
        className="h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
        aria-hidden="true"
      />
    </div>
  );
};
