//eslint-ignore

export const Nuts = ({ width = 32, height = 32, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 231 231"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_417_344)">
      <path
        d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
        fill="#DD455B"
      />
      <path
        d="M220.948 159.796C187.319 125.987 111.839 50.1094 111.722 49.9933C104.442 42.559 94.5724 37.9581 83.7183 37.9581C83.7183 37.9581 55.0155 118.023 51.706 122.502C46.1455 130.01 43.2095 139.012 43.2095 148.532C43.2095 160.887 48.2006 172.115 56.1646 180.007C56.2637 180.108 85.9512 209.796 105.789 229.633C108.828 229.874 111.899 230 115 230C162.622 230 203.483 201.054 220.948 159.796Z"
        fill="#B2004F"
      />
      <path
        d="M115.731 107.048C121.291 99.536 124.227 90.5341 124.227 81.0176C124.227 57.4402 106.056 37.9581 83.7183 37.9581C61.3804 37.9581 43.2095 57.4402 43.2095 81.0176C43.2095 90.5341 46.1455 99.536 51.706 107.048C55.016 111.527 55.016 118.023 51.706 122.502C46.1455 130.01 43.2095 139.012 43.2095 148.532C43.2095 172.11 61.3804 191.592 83.7183 191.592C103.973 191.592 121.238 175.332 123.88 154.15C125.278 142.722 122.381 131.483 115.731 122.503C112.421 118.023 112.421 111.527 115.731 107.048Z"
        fill="#F8E997"
      />
      <path
        d="M123.88 154.15C125.278 142.721 122.381 131.482 115.731 122.502C112.421 118.023 112.421 111.527 115.731 107.048C121.291 99.536 124.227 90.5341 124.227 81.0176C124.227 57.4402 106.056 37.9581 83.7183 37.9581V191.591C103.973 191.592 121.238 175.332 123.88 154.15Z"
        fill="#F2D049"
      />
      <path
        d="M108.496 101.694C112.9 95.7426 115.225 88.591 115.225 81.0176C115.225 62.4029 101.089 46.9601 83.7183 46.9601C66.3472 46.9601 52.2114 62.4029 52.2114 81.0176C52.2114 88.591 54.5366 95.7426 58.9408 101.694C64.6373 109.395 64.6373 120.155 58.9408 127.856C54.5366 133.803 52.2114 140.954 52.2114 148.532C52.2114 167.147 66.3472 182.59 83.7183 182.59C99.4411 182.59 112.865 169.719 114.948 153.047C116.061 143.931 113.77 134.977 108.496 127.856C102.799 120.155 102.799 109.395 108.496 101.694Z"
        fill="#F2D049"
      />
      <path
        d="M114.948 153.046C116.06 143.93 113.77 134.977 108.496 127.856C102.799 120.155 102.799 109.395 108.496 101.694C112.9 95.7426 115.225 88.591 115.225 81.0176C115.225 62.4029 101.089 46.9601 83.7183 46.9601V182.589C99.4411 182.59 112.865 169.718 114.948 153.046Z"
        fill="#EAAD44"
      />
      <path
        d="M83.7183 42.4591H79.2173V187.091H83.7183H88.2192V42.4591H83.7183Z"
        fill="#F8E997"
      />
      <path d="M83.7183 42.4591H88.2192V187.09H83.7183V42.4591Z" fill="#F2D049" />
      <path
        d="M83.7183 83.2681C95.9511 83.2681 107.41 87.5274 115.168 94.9558L121.392 88.4591C111.977 79.4396 98.2452 74.2662 83.7183 74.2662C69.1914 74.2662 55.4598 79.4396 46.0447 88.4591L52.2686 94.9558C60.0265 87.5274 71.4856 83.2681 83.7183 83.2681Z"
        fill="#F8E997"
      />
      <path
        d="M58.6992 110.274V119.276H83.7184H108.737V110.274H83.7184H58.6992Z"
        fill="#F8E997"
      />
      <path
        d="M83.7183 146.282C71.4856 146.282 60.0265 142.023 52.2686 134.594L46.0447 141.091C55.4598 150.11 69.1914 155.284 83.7183 155.284C98.2452 155.284 111.977 150.11 121.392 141.091L115.168 134.594C107.41 142.023 95.9511 146.282 83.7183 146.282Z"
        fill="#F8E997"
      />
      <path
        d="M115.168 134.594C107.41 142.023 95.951 146.282 83.7183 146.282V155.284C98.2452 155.284 111.977 150.11 121.392 141.091L115.168 134.594Z"
        fill="#F2D049"
      />
      <path
        d="M121.392 88.4591C111.977 79.4396 98.2452 74.2662 83.7183 74.2662V83.2681C95.951 83.2681 107.41 87.5274 115.168 94.9558L121.392 88.4591Z"
        fill="#F2D049"
      />
      <path d="M83.7183 110.274H108.737V119.276H83.7183V110.274Z" fill="#F2D049" />
      <path
        d="M187.241 168.787C187.241 181.216 175.15 191.592 160.235 191.592C145.32 191.592 133.229 181.216 133.229 168.787C133.229 156.358 145.32 146.282 160.235 146.282C175.15 146.282 187.241 156.358 187.241 168.787Z"
        fill="#E09380"
      />
      <path
        d="M128.728 137.28C116.305 137.28 106.223 149.433 106.223 164.286C106.223 179.139 116.305 191.592 128.728 191.592C141.151 191.592 151.233 179.139 151.233 164.286C151.233 149.433 141.151 137.28 128.728 137.28Z"
        fill="#F6DFB9"
      />
      <path
        d="M151.233 164.286C151.233 179.139 141.151 191.592 128.728 191.592V137.28C141.151 137.28 151.233 149.433 151.233 164.286Z"
        fill="#EEBDA9"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_344">
        <rect width="230" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
