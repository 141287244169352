import { AppModal } from '@Common/AppModal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { rgbaNormalize } from 'helpers/validation';
import { useAppDispatch } from 'hooks/store';
import { useState } from 'react';
import { RGBColor, SketchPicker } from 'react-color';
import { FormattedMessage } from 'react-intl';
import { updateTheme } from 'store/themes/actions';
import { ApiThemeState } from 'store/themes/types';

interface PickColorModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  setTheme: SetStateType<ApiThemeState | undefined>;
  theme: ApiThemeState | undefined;
}

const sketchPickerStyles = {
  default: {
    picker: {
      boxShadow: 'none',
      border: '1px solid #e4e4e4',
      paddingBottom: '10px',
    },
  },
};

export const PickColorModal = ({
  open,
  setOpen,
  setTheme,
  theme,
}: PickColorModalProps) => {
  const [color, setColor] = useState<RGBColor>({
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  });
  const dispatch = useAppDispatch();

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      setColor({
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      });
    }, 300);
  };

  return (
    <AppModal open={open}>
      <div className="flex w-full items-center justify-between mb-3">
        <h1>
          <FormattedMessage id="pickNewColor" />
        </h1>
        <XMarkIcon
          onClick={close}
          className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
        />
      </div>

      <div className="w-full flex items-center space-x-4">
        <SketchPicker
          color={color}
          onChange={(color) => {
            setColor(color.rgb);
          }}
          presetColors={[]}
          styles={sketchPickerStyles}
        />

        <div
          className="h-10 rounded-md border border-gray-300 flex-1"
          style={{
            background: rgbaNormalize(color),
          }}
        />
      </div>

      <div className="flex w-full justify-end items-center mt-4">
        <button
          type="button"
          onClick={close}
          className="rounded-md mr-2 bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <FormattedMessage id="cancel" />
        </button>

        <button
          type="button"
          onClick={() => {
            if (theme) {
              dispatch(
                updateTheme(theme.id, {
                  colors: {
                    ...theme.colors,
                    colors: [
                      ...theme.colors.colors,
                      {
                        id: theme.colors.colors[theme.colors.colors.length - 1].id,
                        value: color,
                      },
                    ],
                  },
                }),
              );
            }
            setTheme((prev) => {
              if (prev) {
                const newTheme: ApiThemeState = {
                  ...prev,
                  colors: {
                    ...prev.colors,
                    colors: [
                      ...prev.colors.colors,
                      {
                        id: prev.colors.colors[prev.colors.colors.length - 1].id,
                        value: rgbaNormalize(color),
                      },
                    ],
                  },
                };
                return newTheme;
              } else {
                return undefined;
              }
            });
            close();
          }}
          data-testId="cypress-add-color-save"
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <FormattedMessage id="save" />
        </button>
      </div>
    </AppModal>
  );
};
