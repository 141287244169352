import { API } from 'api';
import { defaultToastProps } from 'appConstants';
import { categoryNameList } from 'helpers/menu';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { store } from 'store';
import { AppThunk } from 'store/globalTypes';
import { getNewValueByCategoryList } from 'store/menu/actions';
import { BeutifyMenu, Categories, Items } from 'store/menu/types';

import {
  FETCH_CATEGORY,
  START_CATEGORY_SUB_LOADING,
  STOP_CATEGORY_SUB_LOADING,
  STOP_FETCH_CATEGORY,
  SUCCESS_FETCH_CATEGORY,
} from './actionTypes';
import {
  CreateCategoryBody,
  CreateCategoryProps,
  DeleteCategoryProps,
  EditCategoryProps,
  FetchCategoryAction,
  MoveCategoryAction,
  MoveCategoryProps,
  SetActiveCategoryReduxProps,
  SuccessCategorySubOperation,
  SwitchSimpleCategoryAction,
  SwitchSimpleCategoryProps,
} from './types';

export const createCategory: AppThunk =
  ({ name, description, menu_id, setOpen, resetFields }: CreateCategoryProps) =>
  async (dispatch: Dispatch<SuccessCategorySubOperation>) => {
    dispatch({ type: START_CATEGORY_SUB_LOADING });

    const newCategory: CreateCategoryBody = {
      name,
      description,
      menu_id,
      status: 1,
    };

    API.v1.category
      .createCategory(newCategory)
      .then(({ data: createdCategory }) => {
        const oldCategoryList =
          store.getState().category?.activeCategory.categoryList[
            createdCategory?.menu_id
          ] || [];
        const newCategoryList = [
          ...oldCategoryList,
          {
            ...createdCategory,
            items: [],
          },
        ];

        const [newCategoryListObj, beutifyMenu] = getNewValueByCategoryList({
          newCategoryList,
        });

        dispatch({
          type: SUCCESS_FETCH_CATEGORY,
          payload: {
            categoryList: {
              [createdCategory?.menu_id]: newCategoryList,
            },
            categoryListObj: {
              [createdCategory?.menu_id]: newCategoryListObj,
            },
            beutifyMenu,
          },
        });

        toast('Category successfully created', {
          ...defaultToastProps,
          type: 'success',
        });

        if (setOpen) {
          setOpen(false);
          if (resetFields) resetFields();
        }
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_CATEGORY_SUB_LOADING,
        });
      });
  };

export const editCategory: AppThunk =
  ({ categoryId, field, value, resetFields, setOpen }: EditCategoryProps) =>
  async (dispatch: Dispatch<SuccessCategorySubOperation>) => {
    dispatch({ type: START_CATEGORY_SUB_LOADING });
    const body: any = {
      [field]: value,
    };

    API.v1.category
      .changeCategoryField(categoryId, body)
      .then(({ data: newCategory }) => {
        const oldCategoryList =
          store.getState().category?.activeCategory.categoryList[newCategory?.menu_id] ||
          [];
        const newCategoryList = oldCategoryList.map((category) => {
          if (category?.id === newCategory?.id) {
            return {
              ...newCategory,
              items: category?.items,
            };
          } else {
            return category;
          }
        });
        const [newCategoryListObj, beutifyMenu] = getNewValueByCategoryList({
          newCategoryList,
        });
        dispatch({
          type: SUCCESS_FETCH_CATEGORY,
          payload: {
            categoryList: {
              [newCategory?.menu_id]: newCategoryList,
            },
            categoryListObj: {
              [newCategory?.menu_id]: newCategoryListObj,
            },
            beutifyMenu,
          },
        });
        toast('Category successfully updated', {
          ...defaultToastProps,
          type: 'success',
        });
        if (resetFields) {
          resetFields();
        }

        if (setOpen) {
          setOpen(false);
        }
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
          toastId: 'editCategoryToast',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_CATEGORY_SUB_LOADING,
        });
      });
  };

export const moveCategory: AppThunk =
  ({
    categoryId,
    globalMenuId,
    newGlobalMenuId,
    resetFields,
    categoryList,
    setActiveCategory,
  }: MoveCategoryProps) =>
  async (dispatch: Dispatch<MoveCategoryAction>) => {
    dispatch({
      type: START_CATEGORY_SUB_LOADING,
    });

    const body = {
      menu_id: newGlobalMenuId,
    };

    API.v1.category
      .changeCategoryField(categoryId, body)
      .then(({ data: newCategory }) => {
        const account = store.getState().account.account;
        let categoryItems: Items[] = [];
        const olgCategoryListNewCategory =
          store.getState().category?.activeCategory.categoryList[newCategory.menu_id] ||
          [];

        const olgCategoryListOldCategory =
          store.getState().category?.activeCategory.categoryList[globalMenuId] || [];

        const newCategoryListOldCategory = olgCategoryListOldCategory.filter(
          (category) => {
            if (category?.id === newCategory?.id) {
              categoryItems = category?.items || [];
              return false;
            } else {
              return true;
            }
          },
        );

        //FIXME: need?
        if (olgCategoryListNewCategory) {
          const newCategoryListNewCategory = [
            ...olgCategoryListNewCategory,
            {
              ...newCategory,
              items: categoryItems,
            },
          ];
          const [newCategoryListObjNewCategory, beutifyMenuNew] =
            getNewValueByCategoryList({
              newCategoryList: newCategoryListNewCategory,
            });

          dispatch({
            type: SUCCESS_FETCH_CATEGORY,
            payload: {
              categoryList: {
                [newGlobalMenuId]: newCategoryListNewCategory,
              },
              categoryListObj: {
                [newGlobalMenuId]: newCategoryListObjNewCategory,
              },
              beutifyMenu: {
                ...beutifyMenuNew,
              },
            },
          });
        }

        const [newCategoryListObjOldCategory, beutifyMenuOld] = getNewValueByCategoryList(
          { newCategoryList: newCategoryListOldCategory },
        );

        dispatch({
          type: SUCCESS_FETCH_CATEGORY,
          payload: {
            categoryList: {
              [globalMenuId]: newCategoryListOldCategory,
            },
            categoryListObj: {
              [globalMenuId]: newCategoryListObjOldCategory,
            },
            beutifyMenu: {
              ...beutifyMenuOld,
            },
          },
        });
        localStorage.setItem(
          'activeCategory-' + globalMenuId + '-' + account?.id,
          categoryList[0]?.id,
        );
        setActiveCategory(categoryList[0]?.id);
        resetFields();
        toast('The category has been successfully changed', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_CATEGORY_SUB_LOADING,
        });
      });
  };

export const switchSimpleCategory: AppThunk =
  ({ categoryId, value, resetFields }: SwitchSimpleCategoryProps) =>
  async (dispatch: Dispatch<SwitchSimpleCategoryAction>) => {
    dispatch({
      type: START_CATEGORY_SUB_LOADING,
    });

    const body = {
      is_simple: value,
    };
    API.v1.category
      .changeCategoryField(categoryId, body)
      .then(({ data: newCategory }) => {
        const oldCategoryList =
          store.getState().category?.activeCategory.categoryList[newCategory?.menu_id] ||
          [];
        const newCategoryList = oldCategoryList.map((category) => {
          if (category?.id === newCategory?.id) {
            return {
              ...newCategory,
              items: category?.items,
            };
          } else {
            return category;
          }
        });
        const [newCategoryListObj, beutifyMenu] = getNewValueByCategoryList({
          newCategoryList,
        });
        dispatch({
          type: SUCCESS_FETCH_CATEGORY,
          payload: {
            categoryList: {
              [newCategory?.menu_id]: newCategoryList,
            },
            categoryListObj: {
              [newCategory?.menu_id]: newCategoryListObj,
            },
            beutifyMenu,
          },
        });
        resetFields();
        toast('The category has been successfully changed', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_CATEGORY_SUB_LOADING,
        });
      });
  };

export const setActiveCategoryRedux: AppThunk =
  ({
    globalCategoryId,
    force = false,
    needBack = false,
    backGround = false,
  }: SetActiveCategoryReduxProps) =>
  async (dispatch: Dispatch<FetchCategoryAction>) => {
    try {
      if (!needBack) {
        dispatch({
          type: FETCH_CATEGORY,
        });
      }

      const oldCategoryList =
        store.getState().category?.activeCategory.categoryList || [];

      let needFetchFlag = false;

      // @ts-ignore
      if (
        !(oldCategoryList[globalCategoryId]?.length > 0) ||
        !oldCategoryList[globalCategoryId].find((menu) => menu.status === 4)
      ) {
        needFetchFlag = true;
      }

      if (needFetchFlag || force) {
        const { data } = await API.v1.menu.getMenuById(globalCategoryId);

        const categoryList = data?.categories || [];
        // @ts-ignore
        const categoryListObj: {
          [key: string]: Categories;
        } = categoryNameList(categoryList, true);

        const beutifyMenu: BeutifyMenu = {};

        categoryList.forEach((category) => {
          category.items?.forEach((item) => {
            beutifyMenu[item.id] = {
              ...item,
              categoryName: category.name,
              categoryId: category.id,
              menuId: category.menu_id,
            };
          });
        });

        dispatch({
          type: SUCCESS_FETCH_CATEGORY,
          payload: {
            categoryList: {
              [globalCategoryId]: categoryList,
            },
            categoryListObj: {
              [globalCategoryId]: categoryListObj,
            },
            beutifyMenu,
          },
        });
      }
      if (backGround) {
        const menu = store.getState().menu.menu;
        menu.forEach((globalMenu) => {
          if (globalMenu.id in oldCategoryList === false) {
            dispatch(
              // @ts-ignore
              setActiveCategoryRedux({ globalCategoryId: globalMenu.id, needBack: true }),
            );
          }
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch({
        type: STOP_FETCH_CATEGORY,
      });
    }
  };

export const deleteCategory: AppThunk =
  ({
    id,
    setOpen,
    mode,
    setActiveCategory,
    categoryList,
    globalMenuId,
  }: DeleteCategoryProps) =>
  async (dispatch: Dispatch<SuccessCategorySubOperation>) => {
    dispatch({ type: START_CATEGORY_SUB_LOADING });
    API.v1.category
      .deleteCategory(id, mode === 'move')
      .then(({ data: newCategoryList }) => {
        const account = store.getState().account.account;
        const [newCategoryListObj, beutifyMenu] = getNewValueByCategoryList({
          newCategoryList,
        });

        dispatch({
          type: SUCCESS_FETCH_CATEGORY,
          payload: {
            categoryList: {
              [categoryList[0]?.menu_id]: newCategoryList,
            },
            categoryListObj: {
              [categoryList[0]?.menu_id]: newCategoryListObj,
            },
            beutifyMenu,
          },
        });
        setOpen(false);
        toast('Category successfully deleted', {
          ...defaultToastProps,
          type: 'success',
        });
        localStorage.setItem(
          'activeCategory-' + globalMenuId + '-' + account?.id,
          categoryList[0].id,
        );
        setActiveCategory(categoryList[0].id);
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_CATEGORY_SUB_LOADING,
        });
      });
  };
