import { XMarkIcon } from '@heroicons/react/24/outline';
import { defaultToastProps } from 'appConstants';
import { selectLanguages } from 'appConstants';
import { Languages, SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { ModalChangeLanguage } from 'components/Common/ModalChangeLanguage';
import { Spinner } from 'components/Common/Spinner';
import { createNameWithoutTransalte } from 'helpers/menu';
import { fetchLambdaTranslate } from 'helpers/translate';
import { checkFieldNotEmpty, sortedTranslateObj } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { createMenu, editMenu } from 'store/menu/actions';
import { MenuItemState } from 'store/menu/types';

const steps = [
  { name: <FormattedMessage id="firstCategoryStep" />, id: 1 },
  { name: <FormattedMessage id="secondCategoryStep" />, id: 2 },
];

interface AddGlobalMenuModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  edit?: boolean;
  globalMenu?: MenuItemState | undefined;
}

export const AddGlobalMenuModal = ({
  open,
  setOpen,
  edit = false,
  globalMenu,
}: AddGlobalMenuModalProps) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.account);
  const loading = useAppSelector((state) => state.menu.subLoading);
  const [globalMenuName, setGlobalMenuName] = useState('');
  const [selected, setSelected] = useState(
    selectLanguages.find((lang) => lang.value === account?.locale),
  );
  const [globalMenuTranslate, setGlobalMenuTranslate] = useState<
    object | Record<string, never> | Record<Languages, string>
  >({});
  const [loadingTranslateLabmda, setLoadingTranslateLabmda] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [withoutTranslations, setWithoutTranslations] = useState(false);

  const resetFields = () => {
    setTimeout(() => {
      setGlobalMenuName('');
      setSelected(selectLanguages.find((lang) => lang.value === account?.locale));
      setActiveStep(1);
      setGlobalMenuTranslate({});
      setWithoutTranslations(false);
    }, 250);
    setOpen(false);
  };

  useEffect(() => {
    if (account) {
      setSelected(selectLanguages.find((lang) => lang.value === account?.locale));
    }
  }, [open]);

  useEffect(() => {
    if (edit) {
      if (globalMenu?.name?.translations && globalMenu?.name?.source_lang) {
        setGlobalMenuName(globalMenu?.name?.translations[globalMenu?.name?.source_lang]);
      }
      setSelected(
        selectLanguages.find((lang) => lang?.value === globalMenu?.name?.source_lang),
      );
      if (globalMenu?.name?.translations) {
        setGlobalMenuTranslate(globalMenu?.name?.translations);
      }
      if (typeof globalMenu?.name.withoutTranslate !== undefined) {
        setWithoutTranslations(globalMenu?.name.withoutTranslate || false);
      }
      setActiveStep(1);
    }
  }, [edit, open]);

  const handleCreateCategory = () => {
    if (!globalMenuName.trim()) {
      toast('Global menu name required', {
        ...defaultToastProps,
        type: 'error',
      });
      return;
    } else if (
      !Object.keys(globalMenuTranslate)?.length ||
      !checkFieldNotEmpty(globalMenuTranslate)
    ) {
      toast('Global menu name translation required', {
        ...defaultToastProps,
        type: 'error',
      });
      return;
    } else {
      if (edit) {
        if (
          JSON.stringify(globalMenu?.name?.translations) !==
          JSON.stringify(globalMenuTranslate)
        ) {
          dispatch(
            editMenu({
              globalMenuId: globalMenu?.id,
              updateValue: {
                name: {
                  source_lang: selected?.value,
                  translations: globalMenuTranslate,
                  withoutTranslate: withoutTranslations,
                },
              },
              resetField: resetFields,
            }),
          );
        } else {
          resetFields();
        }
      } else {
        dispatch(
          createMenu({
            newMenu: {
              name: {
                source_lang: selected?.value,
                translations: globalMenuTranslate,
                withoutTranslate: withoutTranslations,
              },
            },
            resetFields,
          }),
        );
      }
    }
  };

  const saveButtonContent = () => {
    if (loadingTranslateLabmda || loading) {
      return <Spinner color="bg-white" />;
    }
    if (activeStep === 2 || withoutTranslations) {
      return <FormattedMessage id="save" />;
    } else {
      return <FormattedMessage id="next" />;
    }
  };

  return (
    <AppModal open={open} size="sm:max-w-3xl">
      <div className="flex w-full items-center justify-between mb-3">
        <h1>
          {edit ? (
            <FormattedMessage id="editGlobalMenu" />
          ) : (
            <FormattedMessage id="createGlobalMenu" />
          )}
        </h1>
        <XMarkIcon
          onClick={() => {
            resetFields();
          }}
          className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
        />
      </div>
      <div className="w-full">
        <div className="mt-2 mb-3" aria-hidden="true">
          <div className="overflow-hidden rounded-full bg-gray-200">
            <div
              className="h-2 rounded-full bg-indigo-600"
              style={{
                width: (+activeStep / steps.length) * 100 + '%',
              }}
            />
          </div>
          <div className="mt-6 hidden grid-cols-3 text-sm font-medium text-gray-600 sm:grid">
            {steps.map((item) => (
              <div
                className={`${
                  activeStep === item.id ? 'text-indigo-600 block' : 'hidden'
                } ltr:text-left rtl:text-right`}
                key={'create-global-menu-step-' + item.id}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between w-full mt-3 md:mt-0">
        {activeStep === 1 && (
          <ModalChangeLanguage selected={selected} setSelected={setSelected} />
        )}
      </div>
      {activeStep === 1 && (
        <>
          <div className="mt-4 flex w-full justify-between items-end">
            <div className="w-full">
              <div className="mt-0">
                <TextareaAutosize
                  minRows={2}
                  name="name"
                  id="global-menu-name"
                  data-testId="cypress-global-menu-name-input"
                  value={globalMenuName}
                  disabled={loadingTranslateLabmda}
                  onChange={(e) => {
                    setGlobalMenuName(e.target.value);
                    if (withoutTranslations) {
                      setGlobalMenuTranslate(
                        createNameWithoutTransalte(e.target.value || ''),
                      );
                    } else {
                      setGlobalMenuTranslate({});
                    }
                  }}
                  className="w-full scrollbar rounded-md border-gray-300 sm:text-sm"
                  placeholder="Write global menu name..."
                />
              </div>
            </div>
          </div>
          <div className="relative flex items-start justify-end mb-4">
            <div className="flex h-6 items-center">
              <input
                id="without-translate"
                aria-describedby="without-translate"
                name="without-translate"
                type="checkbox"
                data-testId="cypress-global-menu-translate-input"
                checked={withoutTranslations}
                onChange={(e) => {
                  if (setWithoutTranslations) {
                    setWithoutTranslations(e.target.checked);
                  }
                  if (e.target.checked) {
                    if (globalMenuName.trim()) {
                      setGlobalMenuTranslate(createNameWithoutTransalte(globalMenuName));
                    }
                  } else {
                    setGlobalMenuTranslate({});
                  }
                }}
                disabled={loadingTranslateLabmda || loading}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label
                htmlFor="without-translate"
                className="font-medium text-gray-900 select-none"
              >
                <FormattedMessage id="withoutTranslate" />
              </label>
            </div>
          </div>
        </>
      )}
      {activeStep === 2 && (
        <div className="mt-4 flex w-full flex-col space-y-4 max-h-96 overflow-auto scrollbar">
          {Object.keys(globalMenuTranslate).length > 0 &&
            !loadingTranslateLabmda &&
            Object.keys(
              sortedTranslateObj({
                // @ts-ignore
                translate: globalMenuTranslate,
                langs: account?.languages || [],
              }),
            ).map((item, index) => {
              return (
                <div
                  key={'edit-translate-global-menu-' + index}
                  className="grid grid-cols-[1fr_4fr] items-center space-x-3 rtl:space-x-reverse w-full ltr:pr-2 rtl:pl-2"
                >
                  <h2 className="rtl:text-right">{item.toUpperCase()}</h2>
                  <div>
                    <TextareaAutosize
                      minRows={2}
                      className="w-full scrollbar rounded-md border-gray-300 sm:text-sm"
                      placeholder="Category name..."
                      disabled={loadingTranslateLabmda}
                      // @ts-ignore
                      value={globalMenuTranslate[item]}
                      onChange={(e) => {
                        setGlobalMenuTranslate((prev) => {
                          return {
                            ...prev,
                            [item]: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      )}

      <div className="w-full flex justify-end mt-4">
        <div className="flex items-center space-x-4 rtl:space-x-reverse">
          {activeStep !== 1 && (
            <button
              disabled={loadingTranslateLabmda}
              onClick={() => {
                setActiveStep((prev) => prev - 1);
              }}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
            >
              <FormattedMessage id="previous" />
            </button>
          )}

          <button
            data-testId="cypress-add-global-menu-next"
            disabled={loadingTranslateLabmda}
            onClick={() => {
              if (activeStep === 1) {
                if (globalMenuName.trim() && selected?.value) {
                  if (withoutTranslations) {
                    if (checkFieldNotEmpty(globalMenuTranslate)) {
                      handleCreateCategory();
                    } else {
                      toast('Translations global menu name required', {
                        ...defaultToastProps,
                        type: 'error',
                      });
                    }
                  } else {
                    // @ts-ignore
                    if (globalMenuTranslate[selected?.value] !== globalMenuName) {
                      setGlobalMenuTranslate({});
                      dispatch(
                        fetchLambdaTranslate({
                          setLoading: setLoadingTranslateLabmda,
                          setData: setGlobalMenuTranslate,
                          text: globalMenuName?.trim(),
                          source_lang: selected?.value,
                          setActiveStep,
                        }),
                      );
                    } else {
                      setActiveStep(2);
                    }
                  }
                } else {
                  toast('Global menu name required', {
                    ...defaultToastProps,
                    type: 'error',
                  });
                }
              } else if (activeStep === 2) {
                if (checkFieldNotEmpty(globalMenuTranslate)) {
                  handleCreateCategory();
                } else {
                  toast('Translations global menu name required', {
                    ...defaultToastProps,
                    type: 'error',
                  });
                }
              }
            }}
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            {saveButtonContent()}
          </button>
        </div>
      </div>
    </AppModal>
  );
};
