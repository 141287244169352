import { Switch } from '@headlessui/react';
import { ISelections, SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { changeStatusOption } from 'store/options/actions';

import { AddDefaultSelection } from '../Modals';

interface StatusSwitchProps {
  defaultValue: number;
  id: string;
  optionId: string;
  selection: ISelections;
  disable: boolean;
  activeSelections: ISelections[];
  setOpenToppingsModal: SetStateType<boolean>;
}

export const StatusSwitch = ({
  defaultValue,
  id,
  optionId,
  selection,
  disable,
  setOpenToppingsModal,
  activeSelections,
}: StatusSwitchProps) => {
  const dispatch = useAppDispatch();
  const [enabled, setEnabled] = useState(defaultValue === 1 ? true : false);
  const structOptions = useAppSelector((state) => state.options.structOptionsApi);
  const [addDefaultValue, setAddDefaultValue] = useState(false);

  return (
    <>
      <Switch
        checked={enabled}
        disabled={disable}
        onChange={(v: boolean) => {
          const sortedSelections = activeSelections.filter(
            (selection) => selection.status === 1,
          );
          if (
            // @ts-ignore
            !!structOptions[optionId]?.free_option_data?.free_options &&
            sortedSelections.length - 1 <
              // @ts-ignore
              (structOptions[optionId]?.free_option_data?.amount || 2) &&
            !v
          ) {
            setOpenToppingsModal(true);
            return;
          }
          if (
            // @ts-ignore
            !structOptions[optionId]?.multi_select &&
            selection?.default_value &&
            // @ts-ignore
            structOptions[optionId]?.selections?.length > 0
          ) {
            setAddDefaultValue(true);
          } else {
            dispatch(
              changeStatusOption({
                newStatus: v ? 1 : 2,
                id: id,
                optionId: optionId,
                setEnabled,
                mode: 'selection',
              }),
            );
          }
        }}
        className={classNames(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          className={classNames(
            enabled ? 'ltr:translate-x-5 rtl:-translate-x-5' : 'translate-x-0',
            'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        >
          <span
            className={classNames(
              enabled
                ? 'opacity-0 duration-100 ease-out'
                : 'opacity-100 duration-200 ease-in',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
            )}
            aria-hidden="true"
          >
            <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={classNames(
              enabled
                ? 'opacity-100 duration-200 ease-in'
                : 'opacity-0 duration-100 ease-out',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
            )}
            aria-hidden="true"
          >
            <svg
              className="h-3 w-3 text-indigo-600"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>

      <AddDefaultSelection
        setOpen={setAddDefaultValue}
        open={addDefaultValue}
        // @ts-ignore
        activeSelections={structOptions[optionId]?.selections}
        id={optionId}
        selectionId={id}
        setEnabled={setEnabled}
      />
    </>
  );
};
