export const Loading = () => {
  return (
    <div
      className="animate-spin inline-block w-4 h-4 border-[3px] border-primary border-t-transparent text-primary rounded-full"
      role="status"
      aria-label="loading"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};
