import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { defaultToastProps, selectLanguages } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { ModalChangeLanguage } from 'components/Common/ModalChangeLanguage';
import { Spinner } from 'components/Common/Spinner';
import { createNameWithoutTransalte } from 'helpers/menu';
import { fetchLambdaTranslate } from 'helpers/translate';
import { checkFieldNotEmpty } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { AccountState } from 'store/account/types';
import { EDIT_ITEM_FIELD } from 'store/item/actionsTypes';

import { ChatGPTModal } from '../ChatGPT/ChatGPTModal';

interface CreateTextFieldItemProps {
  description?: boolean;
  itemCreated: boolean;
  account: AccountState;
  setParentActiveStep: SetStateType<number>;
  activeStep: number;
  withoutTranslate?: boolean;
  setWithoutTranslate?: SetStateType<boolean>;
}

export const CreateTextFieldItem = ({
  description = false,
  itemCreated,
  account,
  setParentActiveStep,
  activeStep,
  withoutTranslate,
  setWithoutTranslate,
}: CreateTextFieldItemProps) => {
  const dispatch = useAppDispatch();
  const [translateActiveTab, setTranslateActiveTab] = useState(0);
  const itemFields = useAppSelector((state) => state.item.addedItem);
  const [selected, setSelected] = useState(
    selectLanguages.find((elem) => {
      if (itemFields[description ? 'description' : 'name']?.source_lang) {
        return (
          elem.value === itemFields[description ? 'description' : 'name'].source_lang
        );
      } else {
        return elem.value === account.locale;
      }
    }),
  );
  const [openChatGPTModal, setOpenChatGPTModal] = useState(false);

  const [translate, setTranslate] = useState<any>(
    itemFields[description ? 'description' : 'name']?.translations || {},
  );
  const [itemName, setItemName] = useState<string>(
    itemFields[description ? 'description' : 'name']?.translations[
      itemFields[description ? 'description' : 'name']?.source_lang
    ] || '',
  );
  const [loadingTranslate, setLoadingTranslate] = useState(false);

  useEffect(() => {
    if (Object.keys(translate).length > 0 && !withoutTranslate) {
      setTranslateActiveTab(1);
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: EDIT_ITEM_FIELD,
      payload: {
        field: description ? 'description' : 'name',
        value: {
          source_lang: selected?.value,
          translations: translate,
        },
      },
    });
  }, [translate]);

  useEffect(() => {
    if (description) {
      if (itemName.trim() === '') {
        dispatch({
          type: EDIT_ITEM_FIELD,
          payload: {
            field: 'description',
            value: {
              source_lang: selected?.value,
              translations: {},
            },
          },
        });
      }
    }
  }, [itemName]);

  useEffect(() => {
    if (itemFields[description ? 'description' : 'name']?.source_lang) {
      setSelected(
        selectLanguages.find(
          (elem) =>
            elem.value === itemFields[description ? 'description' : 'name'].source_lang,
        ),
      );
    }
  }, [itemFields]);

  return (
    <div className="w-full flex flex-col h-full">
      {description && (
        <ChatGPTModal
          setParentSelected={setSelected}
          setParentName={setItemName}
          open={openChatGPTModal}
          setOpen={setOpenChatGPTModal}
        />
      )}
      <div className="flex flex-col justify-center self-center w-full md:w-5/12 max-w-2xl md:min-w-[500px] ipad:max-h-[36rem] mb-7">
        <div className="w-full max-h-full">
          <>
            <div className="flex items-center justify-between w-full">
              {translateActiveTab === 0 && (
                <ModalChangeLanguage
                  disabled={itemCreated}
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
            </div>
            {translateActiveTab === 0 && (
              <div className="mt-4 flex flex-col w-full justify-between items-end">
                <div className="w-full">
                  <div className="mt-1 ">
                    <TextareaAutosize
                      minRows={2}
                      disabled={itemCreated}
                      name="item-name"
                      id="item-name"
                      data-testId="cypress-add-item-name-input"
                      className="mb-1 w-full scrollbar rounded-md border-gray-300 sm:text-sm"
                      placeholder={`Item ${description ? 'description' : 'name'}`}
                      value={itemName}
                      onChange={(e) => {
                        setItemName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="relative flex items-start mb-4">
                  <div className="flex h-6 items-center">
                    <input
                      id="without-translate"
                      aria-describedby="without-translate"
                      name="without-translate"
                      data-testId="cypress-add-item-translate-input"
                      type="checkbox"
                      checked={withoutTranslate}
                      onChange={(e) => {
                        if (setWithoutTranslate) {
                          setWithoutTranslate(e.target.checked);
                        }
                        if (e.target.checked) {
                          if (itemName.trim()) {
                            dispatch({
                              type: EDIT_ITEM_FIELD,
                              payload: {
                                field: description ? 'description' : 'name',
                                value: {
                                  source_lang: selected?.value,
                                  translations: createNameWithoutTransalte(itemName),
                                  withoutTranslate: true,
                                },
                              },
                            });
                          }
                        } else {
                          dispatch({
                            type: EDIT_ITEM_FIELD,
                            payload: {
                              field: description ? 'description' : 'name',
                              value: {
                                source_lang: selected?.value,
                                translations: {},
                              },
                            },
                          });
                        }
                      }}
                      disabled={itemCreated}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="without-translate"
                      className="font-medium text-gray-900 select-none"
                    >
                      <FormattedMessage id="withoutTranslate" />
                    </label>
                  </div>
                </div>

                {description && (
                  <button
                    onClick={() => setOpenChatGPTModal(true)}
                    className="w-full py-3 flex items-center justify-center rounded-lg bg-[#75ac9d] md:hover:bg-[#67998b] transition-all duration-300"
                  >
                    <span className="text-base text-white font-medium mr-2">
                      Or Ask ChatGPT
                    </span>

                    <svg width="24px" height="24px" viewBox="140 140 520 520">
                      <path
                        d="m617.24 354a126.36 126.36 0 0 0 -10.86-103.79 127.8 127.8 0 0 0 -137.65-61.32 126.36 126.36 0 0 0 -95.31-42.49 127.81 127.81 0 0 0 -121.92 88.49 126.4 126.4 0 0 0 -84.5 61.3 127.82 127.82 0 0 0 15.72 149.86 126.36 126.36 0 0 0 10.86 103.79 127.81 127.81 0 0 0 137.65 61.32 126.36 126.36 0 0 0 95.31 42.49 127.81 127.81 0 0 0 121.96-88.54 126.4 126.4 0 0 0 84.5-61.3 127.82 127.82 0 0 0 -15.76-149.81zm-190.66 266.49a94.79 94.79 0 0 1 -60.85-22c.77-.42 2.12-1.16 3-1.7l101-58.34a16.42 16.42 0 0 0 8.3-14.37v-142.39l42.69 24.65a1.52 1.52 0 0 1 .83 1.17v117.92a95.18 95.18 0 0 1 -94.97 95.06zm-204.24-87.23a94.74 94.74 0 0 1 -11.34-63.7c.75.45 2.06 1.25 3 1.79l101 58.34a16.44 16.44 0 0 0 16.59 0l123.31-71.2v49.3a1.53 1.53 0 0 1 -.61 1.31l-102.1 58.95a95.16 95.16 0 0 1 -129.85-34.79zm-26.57-220.49a94.71 94.71 0 0 1 49.48-41.68c0 .87-.05 2.41-.05 3.48v116.68a16.41 16.41 0 0 0 8.29 14.36l123.31 71.19-42.69 24.65a1.53 1.53 0 0 1 -1.44.13l-102.11-59a95.16 95.16 0 0 1 -34.79-129.81zm350.74 81.62-123.31-71.2 42.69-24.64a1.53 1.53 0 0 1 1.44-.13l102.11 58.95a95.08 95.08 0 0 1 -14.69 171.55c0-.88 0-2.42 0-3.49v-116.68a16.4 16.4 0 0 0 -8.24-14.36zm42.49-63.95c-.75-.46-2.06-1.25-3-1.79l-101-58.34a16.46 16.46 0 0 0 -16.59 0l-123.31 71.2v-49.3a1.53 1.53 0 0 1 .61-1.31l102.1-58.9a95.07 95.07 0 0 1 141.19 98.44zm-267.11 87.87-42.7-24.65a1.52 1.52 0 0 1 -.83-1.17v-117.92a95.07 95.07 0 0 1 155.9-73c-.77.42-2.11 1.16-3 1.7l-101 58.34a16.41 16.41 0 0 0 -8.3 14.36zm23.19-50 54.92-31.72 54.92 31.7v63.42l-54.92 31.7-54.92-31.7z"
                        fill="#fff"
                      ></path>
                    </svg>
                  </button>
                )}
              </div>
            )}
            {translateActiveTab === 1 && (
              <div className="mt-4 flex w-full flex-col space-y-4 ipad:max-h-[100%] max-h-[36rem] overflow-auto scrollbar rtl:pl-1 ltr:pr-1 mb-3">
                {Object.keys(translate).length > 0 &&
                  !loadingTranslate &&
                  Object.keys(translate).map((item, index) => {
                    return (
                      <div
                        key={'edit-translate-' + index}
                        className="grid grid-cols-[1fr_4fr] items-center space-x-3 rtl:space-x-reverse w-full"
                      >
                        <h2>
                          {selectLanguages
                            .find((elem) => {
                              return elem.value === item;
                            })
                            ?.value.toUpperCase()}
                        </h2>
                        <div>
                          <TextareaAutosize
                            minRows={2}
                            disabled={itemCreated}
                            className="mb-4 last:mb-0 w-full scrollbar rounded-md border-gray-300 sm:text-sm"
                            placeholder={'Item ' + (description ? 'description' : 'name')}
                            value={translate[item]}
                            onChange={(e) => {
                              setTranslate((prev: any) => {
                                return {
                                  ...prev,
                                  [item]: e.target.value,
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </>
        </div>
      </div>
      <div className="flex w-full items-center justify-end mt-auto justify-self-end">
        {(activeStep !== 0 || translateActiveTab === 1) && (
          <button
            onClick={() => {
              if (translateActiveTab === 1) {
                setTranslateActiveTab(0);
              } else {
                setParentActiveStep((prev) => prev - 1);
              }
            }}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="previous" />
          </button>
        )}

        <button
          onClick={() => {
            if (withoutTranslate) {
              if (translateActiveTab === 0) {
                if (itemName.trim()) {
                  if (
                    itemFields[description ? 'description' : 'name']?.translations[
                      itemFields[description ? 'description' : 'name']?.source_lang
                    ] === itemName
                  ) {
                    setParentActiveStep((prev) => prev + 1);
                  } else {
                    dispatch({
                      type: EDIT_ITEM_FIELD,
                      payload: {
                        field: description ? 'description' : 'name',
                        value: {
                          source_lang: selected?.value,
                          translations: createNameWithoutTransalte(itemName),
                          withoutTranslate: true,
                        },
                      },
                    });
                    setParentActiveStep((prev) => prev + 1);
                  }
                } else {
                  toast('Item name/description required', {
                    ...defaultToastProps,
                    type: 'error',
                  });
                }
              }
            } else if (translateActiveTab === 0) {
              if (itemName.trim()) {
                if (
                  itemFields[description ? 'description' : 'name'].translations[
                    itemFields[description ? 'description' : 'name'].source_lang
                  ] === itemName &&
                  selected?.value ===
                    itemFields[description ? 'description' : 'name'].source_lang
                ) {
                  setTranslateActiveTab((prev) => prev + 1);
                } else {
                  setTranslate({});
                  dispatch(
                    fetchLambdaTranslate({
                      setLoading: setLoadingTranslate,
                      setData: setTranslate,
                      text: itemName.trim(),
                      // @ts-ignore
                      source_lang: selected?.value,
                      setTranslateActiveTab,
                    }),
                  );
                }
              } else {
                if (description) {
                  setParentActiveStep((prev) => prev + 1);
                } else {
                  toast('Item name and transtation required', {
                    ...defaultToastProps,
                    type: 'error',
                  });
                }
              }
            } else if (!description && checkFieldNotEmpty(itemFields.name.translations)) {
              setParentActiveStep((prev) => prev + 1);
            } else if (
              !description &&
              !checkFieldNotEmpty(itemFields.name.translations)
            ) {
              toast('Transtations required', {
                ...defaultToastProps,
                type: 'error',
              });
            } else if (
              description &&
              itemName.trim() !== '' &&
              checkFieldNotEmpty(itemFields.description.translations)
            ) {
              setParentActiveStep((prev) => prev + 1);
            } else if (description && itemName.trim() === '') {
              setParentActiveStep((prev) => prev + 1);
            } else if (
              description &&
              itemName.trim() !== '' &&
              !checkFieldNotEmpty(itemFields.description.translations)
            ) {
              toast('Transtations required', {
                ...defaultToastProps,
                type: 'error',
              });
            }
          }}
          disabled={loadingTranslate}
          data-testId="cypress-transalate-item-next"
          className="inline-flex items-center rtl:mr-3 ltr:ml-3 rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
        >
          {loadingTranslate ? (
            <Spinner color="bg-white" />
          ) : (
            <FormattedMessage id="next" />
          )}
        </button>
      </div>
    </div>
  );
};
