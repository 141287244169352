import { Spinner } from 'components/Common/Spinner';
import {
  FirstItemTab,
  ItemHeader,
  ItemImage,
  ItemTabs,
  OptionsTab,
  SuggestionTab,
} from 'components/ItemComponents';
import { Toggle } from 'components/OptionsComponents';
import { checkLocalActiveMenu } from 'helpers/menu';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Layout } from 'pages/Layout/Layout';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { setActiveCategoryRedux } from 'store/category/actions';
import { setActiveItem } from 'store/item/actions';
import { RESET_ACTIVE_ITEM } from 'store/item/actionsTypes';
import { getRestaurantMenu } from 'store/menu/actions';

interface RouteParams {
  id: string;
}

export const Item = () => {
  const { id } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const menu = useAppSelector((state) => state.menu.menu);
  const beutifyMenu = useAppSelector((state) => state.category.beutifyMenu);
  const account = useAppSelector((state) => state.account.account);
  const loadingMenu = useAppSelector((state) => state.menu.loading);
  const activeItem = useAppSelector((state) => state.item.activeItem);
  const activeItemLoading = useAppSelector((state) => state.item.loading);
  const loadingAccount = useAppSelector((state) => state.account.loading);

  const [activeTab, setActiveTab] = useState(0);
  const [activeGlobalMenu, setActiveGlobalMenu] = useState('');

  useEffect(() => {
    if (menu.length === 0) {
      dispatch(getRestaurantMenu());
    }

    return () => {
      dispatch({
        type: RESET_ACTIVE_ITEM,
      });
    };
  }, []);

  useEffect(() => {
    dispatch(
      setActiveItem({
        id: id,
      }),
    );
  }, [id]);

  useEffect(() => {
    if (!activeGlobalMenu && !!account?.id && !!menu[0]?.id) {
      const localeGlobalMenu = checkLocalActiveMenu({ menu, account });
      setActiveGlobalMenu(localeGlobalMenu);
      localStorage.setItem('activeGlobalMenu-' + account?.id, localeGlobalMenu);
    }
  }, [menu, account, id]);

  useEffect(() => {
    if (activeGlobalMenu) {
      dispatch(
        setActiveCategoryRedux({ globalCategoryId: activeGlobalMenu, backGround: true }),
      );
    }
  }, [activeGlobalMenu]);

  const crumbs = [
    { name: 'Menu', href: '/menu', current: false },
    { name: 'Item', href: '/item/' + id, current: true },
  ];

  return (
    <Layout crumbs={crumbs}>
      {loadingMenu ||
      loadingAccount ||
      !beutifyMenu ||
      activeItemLoading ||
      !activeItem ||
      !activeGlobalMenu ? (
        <div className="flex justify-center items-start h-screen">
          <Spinner color="bg-primary" />
        </div>
      ) : (
        <div className="flex w-full ju flex-wrap ipad:flex-nowrap items-start space-x-0 ipad:space-x-7 rtl:ipad:space-x-reverse p-4 bg-white rounded-lg ">
          <ItemImage activeGlobalMenu={activeGlobalMenu} />
          <div className="w-full ipad:w-7/12 flex flex-grow flex-col space-y-4 mt-3 ipad:mt-0">
            <ItemHeader activeGlobalMenu={activeGlobalMenu} />
            <div className="flex sm:items-center mt-3 flex-wrap flex-col sm:flex-row">
              <Toggle
                mode="popular"
                defaultCheck={activeItem?.popular}
                id={activeItem?.id}
              />
              <Toggle
                mode="chefAdvice"
                className="ltr:sm:ml-5 rtl:sm:mr-5 sm:mt-0 mt-3"
                defaultCheck={activeItem?.chef_advice}
                id={activeItem?.id}
              />
              <Toggle
                mode="new"
                className="ltr:sm:ml-5 rtl:sm:mr-5 sm:mt-0 mt-3"
                defaultCheck={activeItem?.new}
                id={activeItem?.id}
              />
            </div>
            <ItemTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            {+activeTab === 0 && <FirstItemTab activeGlobalMenu={activeGlobalMenu} />}
            {+activeTab === 1 && <OptionsTab optionsItem={activeItem?.options || []} />}
            {+activeTab === 2 && (
              <SuggestionTab
                activeGlobalMenu={activeGlobalMenu}
                setActiveGlobalMenu={setActiveGlobalMenu}
              />
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};
