export const CHECK_AVAILABILITY_LINK = 'CHECK_AVAILABILITY_LINK';

export const START_REQUEST_QR_CODE = 'START_REQUEST_QR_CODE';
export const SUCCESS_REQUEST_QR_CODE = 'SUCCESS_REQUEST_QR_CODE';
export const ERROR_REQUEST_QR_CODE = 'ERROR_REQUEST_QR_CODE';

export const START_REQUEST_QR_CODE_LOCAL = 'START_REQUEST_QR_CODE_LOCAL';
export const SUCCESS_REQUEST_QR_CODE_LOCAL = 'SUCCESS_REQUEST_QR_CODE_LOCAL';
export const ERROR_REQUEST_QR_CODE_LOCAL = 'ERROR_REQUEST_QR_CODE_LOCAL';

export const RESET_QR_CODE = 'RESET_QR_CODE';
