import { Menu, Transition } from '@headlessui/react';
import {
  AdjustmentsHorizontalIcon,
  Bars4Icon,
  BellIcon,
  BuildingStorefrontIcon,
  PaintBrushIcon,
  QrCodeIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Crumbs } from 'commonTypes';
import { BreadCrumbs } from 'components/Common/BreadCrumbs';
import { LanguageDropdown } from 'components/Common/LanguageDropdown';
import { MobileSidebar } from 'components/Common/MobileSidebar';
import { NotificationBlock } from 'components/Common/NotificationBlock';
import { Sidebar } from 'components/Common/Sidebar';
import { Spinner } from 'components/Common/Spinner';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { ReactNode, useEffect } from 'react';
import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { setAccountInfo } from 'store/account/actions';
import { logout } from 'store/auth/actions';

export interface UserNavigationProp {
  name: string;
  href: string;
  id: string;
}

const userNavigation: UserNavigationProp[] = [
  { name: 'Your Profile', href: '/profile', id: 'profile' },
  { name: 'Sign out', href: '#', id: 'signout' },
];

export interface NavigationProp {
  name: string;
  icon: any;
  href: string;
  routes: string[];
}

const navigation: NavigationProp[] = [
  //{
  //  name: 'Dashboard',
  //  icon: ChartPieIcon,
  //  href: '/dashboard',
  //  routes: ['dashboard'],
  //},
  {
    name: 'Menu',
    icon: BuildingStorefrontIcon,
    href: '/menu',
    routes: ['menu', 'add-item', 'item'],
  },
  {
    name: 'Options',
    icon: AdjustmentsHorizontalIcon,
    href: '/options',
    routes: ['options'],
  },
  {
    name: 'Profile',
    icon: UserIcon,
    href: '/profile',
    routes: ['profile'],
  },
  {
    name: 'QRCode',
    icon: QrCodeIcon,
    href: '/qr-code',
    routes: ['qr-code'],
  },
  {
    name: 'Customization',
    icon: PaintBrushIcon,
    href: '/customization',
    routes: ['customization'],
  },
];

interface LayoutProps {
  crumbs: Crumbs[];
  children: ReactNode;
}

export const Layout = ({ crumbs, children }: LayoutProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [desktopSidebarOpen, setDesktopSidebarOpen] = useState(() => {
    return localStorage.getItem('desktopSidebarOpen') === 'true';
  });

  const loading = useAppSelector((state) => state.auth.loading);
  const cognitoUser = useAppSelector((state) => state.account.cognitoUser);
  const account = useAppSelector((state) => state.account.account);

  const incompletedFields = () => {
    return (
      (account?.tripadvisor === null && account?.google_maps === null) ||
      account?.color === null ||
      account?.service_fee_warning === null
    );
  };

  useEffect(() => {
    if (!account) {
      dispatch(setAccountInfo());
    }
  }, [account]);

  return (
    <div className="h-full relative">
      <MobileSidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        navigation={navigation}
        userNavigation={userNavigation}
      />
      {/* Static sidebar for desktop */}
      <div
        className={`hidden ipad:fixed ipad:inset-y-0 ipad:flex ${
          desktopSidebarOpen ? 'ipad:w-64' : 'ipad:w-16'
        } ipad:flex-col transition-[width] delay-150`}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <Sidebar navigation={navigation} desktopSidebarOpen={desktopSidebarOpen} />
      </div>

      <div
        className={`flex flex-1 flex-col h-full ${
          desktopSidebarOpen
            ? 'rtl:ipad:pr-64 ltr:ipad:pl-64'
            : 'rtl:ipad:pr-16 ltr:ipad:pl-16'
        } transition-all delay-150`}
      >
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white">
          <button
            type="button"
            className="px-4 text-gray-500 ipad:focus:outline-none ipad:focus:ring-2 ipad:focus:ring-inset ipad:focus:ring-indigo-500 ipad:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars4Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between px-4">
            <div className="flex flex-1 items-center">
              {desktopSidebarOpen ? (
                <XMarkIcon
                  className="h-8 w-8 text-primary cursor-pointer ipad:block hidden ipad:hover:text-gray-500"
                  onClick={() =>
                    setDesktopSidebarOpen((prev) => {
                      localStorage.setItem('desktopSidebarOpen', !prev + '');
                      return !prev;
                    })
                  }
                />
              ) : (
                <Bars4Icon
                  className="h-8 w-8 text-primary cursor-pointer ipad:block hidden ipad:hover:text-gray-500"
                  onClick={() =>
                    setDesktopSidebarOpen((prev) => {
                      localStorage.setItem('desktopSidebarOpen', !prev + '');
                      return !prev;
                    })
                  }
                />
              )}
            </div>
            <div
              className={`rtl:mr-4 rtl:ipad:mr-6 ltr:ml-4 ltr:ipad:ml-6 flex items-center`}
            >
              <div className={`rtl:ml-4 ltr:mr-4 hidden ipad:block`}>
                <LanguageDropdown />
              </div>

              <Menu as="div" className={`relative rtl:mr-3 ltr:ml-3`}>
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2">
                    <button
                      type="button"
                      className="rounded-full relative bg-white p-1 text-gray-400 md:hover:text-gray-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                      {incompletedFields() && (
                        <div className="h-2 w-2 rounded-full bg-red-500 absolute top-1.5 right-1.5" />
                      )}
                    </button>
                  </Menu.Button>
                </div>
                {incompletedFields() && (
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className={`absolute rtl:-left-10 ltr:-right-10 sm:rtl:left-0 sm:ltr:right-0 z-10 mt-2 w-80 sm:w-[420px] origin-top-right divide-y divide-gray-200 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none`}
                    >
                      {account?.tripadvisor === null && account?.google_maps === null && (
                        <NotificationBlock
                          textId="incompleteReviews"
                          to="/profile"
                          state={{
                            fromNotification: true,
                          }}
                        />
                      )}
                      {account?.color === null && (
                        <NotificationBlock
                          textId="incompleteColor"
                          to="/qr-code"
                          state={{
                            fromNotification: true,
                            servicesFee: false,
                            color: true,
                          }}
                        />
                      )}
                      {account?.service_fee_warning === null && (
                        <NotificationBlock
                          textId="incompleteServicesFee"
                          to="/qr-code"
                          state={{
                            fromNotification: true,
                            servicesFee: true,
                            color: false,
                          }}
                        />
                      )}
                    </Menu.Items>
                  </Transition>
                )}
              </Menu>

              {/* Profile dropdown */}
              <Menu as="div" className={`relative rtl:mr-3 ltr:ml-3`}>
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2">
                    <span className="sr-only">Open user menu</span>
                    <div className="flex-shrink-0 h-8 w-8 rounded-full bg-primary text-white flex items-center justify-center text-base font-medium">
                      {cognitoUser?.attributes?.given_name[0]?.toUpperCase()}
                      {cognitoUser?.attributes?.family_name[0]?.toUpperCase()}
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className={`absolute rtl:left-0 ltr:right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none`}
                  >
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => {
                          if (item.name === 'Sign out') {
                            return (
                              <div
                                role="button"
                                tabIndex={0}
                                onClick={() => {
                                  dispatch(logout(history));
                                  localStorage.removeItem(
                                    'activeCategory-' + account?.id,
                                  );
                                }}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700 cursor-pointer',
                                )}
                              >
                                {loading ? (
                                  <Spinner color="bg-primary" />
                                ) : (
                                  <FormattedMessage id={item.id.toLowerCase()} />
                                )}
                              </div>
                            );
                          } else {
                            return (
                              <Link
                                to={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700',
                                )}
                              >
                                <FormattedMessage id={item.id.toLowerCase()} />
                              </Link>
                            );
                          }
                        }}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="flex-1">
          <div className="py-6 h-full">
            <div className="mx-auto px-4 sm:px-6 ipad:px-8">
              <h1 className="text-2xl font-semibold text-gray-900">
                <BreadCrumbs crumbs={crumbs} />
              </h1>
            </div>
            <div className="mx-auto px-4 sm:px-6 ipad:px-8 h-full">
              {/* Replace with your content */}
              <div className="py-4 h-full flex flex-col">
                {children}
                <div className="flex items-center mt-auto justify-self-end w-full justify-end">
                  <a
                    href="https://www.digital-menu.ai/privacy-policy"
                    target="_blank"
                    className={
                      'border-transparent text-gray-600 ipad:hover:text-gray-900 group flex items-center px-0 py-2 text-sm font-medium rtl:border-r-4 ltr:border-l-4'
                    }
                    rel="noreferrer"
                  >
                    <div>Privacy & Cookie</div>
                  </a>
                  <a
                    href="https://www.digital-menu.ai/terms"
                    target="_blank"
                    className={
                      'border-transparent text-gray-600 ipad:hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rtl:border-r-4 ltr:border-l-4'
                    }
                    rel="noreferrer"
                  >
                    <div>Tems & Conditions</div>
                  </a>
                </div>
              </div>
              {/* /End replace */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
