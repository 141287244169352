import { useEffect, useState } from 'react';

import { DefaultImage } from '../Default/DefaultImage';

interface PreloadComponentProps {
  minimizedSrc: string;
  baseSrc: string;
  style?: object;
  className?: string;
}

export const PreloadComponent = ({
  minimizedSrc,
  baseSrc,
  style,
  className = '',
}: PreloadComponentProps) => {
  const [loadingMin, setLoadingMin] = useState(true);
  const [loadingBase, setLoadingBase] = useState(false);

  useEffect(() => {
    const imageMin = new Image();
    imageMin.src = minimizedSrc;
    imageMin.onload = () => {
      setLoadingMin(false);
    };
  }, [minimizedSrc]);

  useEffect(() => {
    if (!loadingMin) {
      setLoadingBase(true);
      const imageBase = new Image();
      imageBase.src = baseSrc;
      imageBase.onload = () => {
        setLoadingBase(false);
      };
    }
  }, [loadingMin]);

  if (loadingMin) {
    return <DefaultImage style={style} className={className} />;
  } else if (loadingBase) {
    return <DefaultImage src={minimizedSrc} style={style} className={className} />;
  } else {
    return <DefaultImage src={baseSrc} style={style} className={className} />;
  }
};
