import { XMarkIcon } from '@heroicons/react/24/outline';
import { ISelections, SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  changeStatusOption,
  deleteSelection,
  editOption,
  getOptionsApi,
} from 'store/options/actions';

import { OptionSelection } from '../UI';

interface AddDefaultSelectionProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  activeSelections: ISelections[];
  id: string;
  setIsMulti?: SetStateType<boolean>;
  setEnabled?: SetStateType<boolean>;
  selectionId?: string;
  deleteMode?: boolean;
}

export const AddDefaultSelection = ({
  open,
  setOpen,
  activeSelections,
  id,
  setIsMulti,
  setEnabled,
  selectionId,
  deleteMode,
}: AddDefaultSelectionProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.options.subLoading);
  const [discardClick, setDiscardClick] = useState(0);
  const [wasClicked, setWasClicked] = useState(false);
  const structOptions = useAppSelector((state) => state.options.structOptionsApi);

  return (
    <AppModal size="sm:max-w-xl" open={open}>
      <div className="w-full flex flex-col">
        <div className="flex w-full items-center justify-between mb-3">
          <h1>Select default selection</h1>
          <XMarkIcon
            onClick={() => {
              if (discardClick === 0) {
                setDiscardClick(1);
              } else {
                setOpen(false);
                dispatch(getOptionsApi());
                setDiscardClick(0);
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="mb-3">
          <div className="rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-yellow-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  <FormattedMessage id="attentionNeeded" />
                </h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>The non-multiple choice option should have a default value.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <div className="-my-0 -mx-0 md:-mx-6 lg:-mx-8 overflow-x-auto scrollbar">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        <FormattedMessage id="name" />
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <FormattedMessage id="price" />
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <FormattedMessage id="active" />
                      </th>
                      <th
                        scope="col"
                        className={`px-3 py-3.5 text-center text-sm font-semibold text-gray-900`}
                      >
                        <FormattedMessage id="default" />
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {activeSelections?.map((selection) => {
                      return (
                        <OptionSelection
                          isMulti={false}
                          key={'active-option-selection-default-value-' + selection?.id}
                          defaultValue={selection?.default_value}
                          activeSelections={activeSelections}
                          setWasClicked={setWasClicked}
                          selectionId={selection.id}
                          id={id}
                          selection={selection}
                          selectionsLength={activeSelections?.length}
                          mini={true}
                          disableActive={true}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end w-full">
          <div className="text-xs text-red-500 mr-auto justify-self-start">
            {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
          </div>
          <button
            type="button"
            onClick={() => {
              if (discardClick === 0) {
                setDiscardClick(1);
              } else {
                setOpen(false);
                dispatch(getOptionsApi());
                setDiscardClick(0);
              }
            }}
            className="ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <FormattedMessage id="cancel" />
          </button>
          {wasClicked && (
            <button
              onClick={() => {
                if (setIsMulti) {
                  dispatch(
                    editOption({
                      data: {
                        multi_select: false,
                        free_option_data: {
                          free_options: false,
                          // @ts-ignore
                          amount: structOptions[id]?.free_option_data?.amount || 2,
                        },
                      },
                      id,
                      setIsMulti,
                      setOpen,
                      reloadNeeded: true,
                    }),
                  );
                  if (setEnabled) setEnabled(false);
                } else if (deleteMode) {
                  dispatch(
                    deleteSelection({
                      id: selectionId,
                      setOpen,
                      optionId: id,
                    }),
                  );
                } else {
                  dispatch(
                    changeStatusOption({
                      newStatus: 2,
                      id: selectionId,
                      optionId: id,
                      setEnabled,
                      mode: 'selection',
                      setOpen,
                    }),
                  );
                }
              }}
              type="button"
              className="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {loading ? <Spinner color="bg-white" /> : <FormattedMessage id="save" />}
            </button>
          )}
        </div>
      </div>
    </AppModal>
  );
};
