import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const tabs = [
  { name: <FormattedMessage id="account" />, id: 0, current: false },
  { name: <FormattedMessage id="profile" />, id: 1, current: true },
];

interface ProfileTabsProps {
  activeTab: number;
  setActiveTab: SetStateType<number>;
}

export const ProfileTabs = ({ activeTab, setActiveTab }: ProfileTabsProps) => {
  return (
    <div>
      <div className="block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                role="button"
                tabIndex={0}
                key={'tab-item-desctop-' + tab.id}
                className={classNames(
                  +tab.id === +activeTab
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 md:hover:text-gray-700 md:hover:border-gray-300',
                  'ltr:mr-6 rtl:ml-6 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
                )}
                aria-current={tab.id === activeTab ? 'page' : undefined}
                onClick={() => {
                  setActiveTab(tab.id);
                  localStorage.setItem('activeTabAccount', tab.id.toString());
                }}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
