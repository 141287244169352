export const START_FETCH_MENU = 'START_FETCH_MENU';
export const SUCCESS_FETCH_MENU = 'SUCCESS_FETCH_MENU';
export const STOP_FETCH_MENU = 'STOP_FETCH_MENU';

export const RESET_MENU = 'RESET_MENU';
export const START_CREATE_MENU = 'START_CREATE_MENU';
export const STOP_CREATE_MENU = 'STOP_CREATE_MENU';
export const SUCCESS_CREATE_MENU = 'SUCCESS_CREATE_MENU';

export const START_DELETE_MENU = 'START_DELETE_MENU';
export const STOP_DELETE_MENU = 'STOP_DELETE_MENU';
export const SUCCESS_DELETE_MENU = 'SUCCESS_DELETE_MENU';

export const START_ENABLE_MENU = 'START_ENABLE_MENU';
export const STOP_ENABLE_MENU = 'STOP_ENABLE_MENU';

export const START_CHANGE_MENU = 'START_CHANGE_MENU';
export const STOP_CHANGE_MENU = 'STOP_CHANGE_MENU';

export const SUCCESS_UPDATE_MENU = 'SUCCESS_UPDATE_MENU';
