import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';

import { RegisterPasswordInput, RegisterTextInput } from '../FormElement';

interface RegisterFirstStepProps {
  email: string;
  setEmail: SetStateType<string>;
  password: string;
  setPassword: SetStateType<string>;
  confirmPrivacy: boolean;
  setConfirmPrivacy: SetStateType<boolean>;
}

export const RegisterFirstStep = ({
  email,
  setEmail,
  password,
  setPassword,
  confirmPrivacy,
  setConfirmPrivacy,
}: RegisterFirstStepProps) => {
  return (
    <>
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">Enter the password sent to your email</p>
          </div>
        </div>
      </div>
      <RegisterTextInput
        label={'Email address'}
        type={'email'}
        value={email}
        setValue={setEmail}
        required={true}
        id={'register-email'}
        name={'email'}
        autoComplete={'email'}
        placeholder={'Enter your email address'}
      />

      <div>
        <RegisterPasswordInput
          label={'Password'}
          value={password}
          setValue={setPassword}
          id={'register-password'}
          name={'password'}
        />
      </div>

      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            checked={confirmPrivacy}
            onChange={(e) => setConfirmPrivacy(e.target.checked)}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
        </div>
        <label
          htmlFor="comments"
          className="ml-3 text-sm leading-6 flex items-center flex-wrap"
        >
          <div className="text-gray-500">I accept the&nbsp;</div>
          <a
            href="https://www.digital-menu.ai/privacy-policy"
            target="_blank"
            rel="noreferrer"
            id="comments-description"
            className="text-primary"
          >
            Privacy & Cookie
          </a>
          <div className="text-gray-500">&nbsp;and&nbsp;</div>
          <a
            href="https://www.digital-menu.ai/terms"
            target="_blank"
            rel="noreferrer"
            id="comments-description"
            className="text-primary"
          >
            Tems & Conditions
          </a>
        </label>
      </div>
    </>
  );
};
