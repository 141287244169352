import { API } from 'api';
import { defaultToastProps } from 'appConstants';
import { Auth, Storage } from 'aws-amplify';
import { IPriview } from 'commonTypes';
import { CLOUDFRONT_BASE_URL } from 'configure';
import { formatBytes } from 'helpers/validation';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { store } from 'store';
import { updateAccountInfo } from 'store/account/actions';
import {
  CustomCognitoUser,
  LoadDefaultPhotoAccountBody,
  LoadImagesAccountBody,
  LoadLogoAccountBody,
} from 'store/account/types';
import { STOP_LOADING, SUCCESS_LOGIN } from 'store/auth/actionsTypes';
import { SUCCESS_FETCH_CATEGORY } from 'store/category/actionTypes';
import { AppThunk } from 'store/globalTypes';
import { setActiveItem } from 'store/item/actions';
import { getNewValueByCategoryList } from 'store/menu/actions';

import { SET_IMAGES_ITEM, START_FETCH_IMAGES, STOP_FETCH_IMAGES } from './actionTypes';
import {
  ConvertImageProps,
  FetchImageProps,
  FetchImagesAction,
  ItemImages,
  LoadImageAccountProps,
  LoadImagesItemProps,
  LoadLogoAccountProps,
} from './types';

export const fetchImage: AppThunk =
  ({ photo, id }: FetchImageProps) =>
  async (dispatch: Dispatch<FetchImagesAction>) => {
    dispatch({ type: START_FETCH_IMAGES });

    try {
      let actPhoto = photo;

      if (actPhoto.type !== 'image/webp') {
        const body = new FormData();
        body.append('img', actPhoto, actPhoto.name);

        const { data } = await API.v1.images.convertImage(body);
        actPhoto = data;
      }

      const res = await Storage.put(id + '_' + Date.now() + '.webp', actPhoto, {
        level: 'protected',
        customPrefix: {
          protected: '',
        },
      });

      const cred = await Auth.currentCredentials();

      const data: ItemImages[] = [
        {
          type: 'full',
          link: `${CLOUDFRONT_BASE_URL}/fit-in/1200x800/${cred.identityId}/${res.key}`,
        },
        {
          type: 'medium',
          link: `${CLOUDFRONT_BASE_URL}/fit-in/450x320/${cred.identityId}/${res.key}`,
        },
        {
          type: 'original',
          link: `${CLOUDFRONT_BASE_URL}/${cred.identityId}/${res.key}`,
        },
        {
          type: 'preloader',
          link: `${CLOUDFRONT_BASE_URL}/fit-in/450x320/filters:blur(10)/${cred.identityId}/${res.key}`,
        },
      ];

      dispatch({
        type: SET_IMAGES_ITEM,
        payload: data,
      });
    } catch (error) {
      if (typeof error === 'string') {
        toast(error, {
          ...defaultToastProps,
          type: 'error',
        });
      } else if (error instanceof Error) {
        toast(error.message, {
          ...defaultToastProps,
          type: 'error',
        });
      }
    } finally {
      dispatch({
        type: STOP_FETCH_IMAGES,
      });
    }
  };

export const convertImage: AppThunk =
  ({ photo, setPhoto, needToLoad = true }: ConvertImageProps) =>
  async (dispatch: Dispatch<FetchImagesAction>) => {
    dispatch({ type: START_FETCH_IMAGES });
    const body = new FormData();
    body.append('img', photo, photo.name);

    API.v1.images
      .convertImage(body)
      .then(({ data }) => {
        if (setPhoto) setPhoto(data);
        if (needToLoad) {
          dispatch({
            type: STOP_FETCH_IMAGES,
          });
        }
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_FETCH_IMAGES,
        });
      });
  };

export const loadImagesAccount: AppThunk =
  ({ photos, setOpen, registerMode, history = null }: LoadImageAccountProps) =>
  async (dispatch) => {
    dispatch({ type: START_FETCH_IMAGES });
    const photo: {
      links: IPriview;
    } = {
      links: {
        full: '',
        medium: '',
        original: '',
        preloader: '',
      },
    };
    photos.forEach((item) => {
      photo.links[item.type as keyof IPriview] = item.link;
    });
    const body: LoadImagesAccountBody = {
      preview: photo,
      photo_version: +photo?.links?.full?.split('_')[1]?.split('.')[0],
    };
    const cognitoUser: CustomCognitoUser = await Auth.currentAuthenticatedUser();

    API.v1.account
      .changeAccountAttribute(body)
      .then(({ data }) => {
        if (!registerMode) {
          setOpen(false);
        } else {
          dispatch({ type: STOP_LOADING });
          dispatch({ type: SUCCESS_LOGIN, payload: true });
          localStorage.removeItem('user');
          history.push('/menu');
        }
        dispatch(updateAccountInfo(data, cognitoUser));
        dispatch({
          type: SET_IMAGES_ITEM,
          payload: [],
        });
        toast('Image updated successfully', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_FETCH_IMAGES,
        });
      });
  };

export const loadLogoAccount: AppThunk =
  ({ photos, setOpen, name = 'logo.webp', size }: LoadLogoAccountProps) =>
  async (dispatch) => {
    dispatch({ type: START_FETCH_IMAGES });
    const photo: {
      links: IPriview;
    } = {
      links: {
        full: '',
        medium: '',
        original: '',
        preloader: '',
      },
    };
    photos.forEach((item) => {
      photo.links[item.type as keyof IPriview] = item.link;
    });
    const body: LoadLogoAccountBody = {
      logo: {
        name,
        size: formatBytes(size),
        links: photo.links,
      },
    };

    const cognitoUser: CustomCognitoUser = await Auth.currentAuthenticatedUser();

    API.v1.account
      .changeAccountAttribute(body)
      .then(({ data }) => {
        setOpen(false);
        dispatch(updateAccountInfo(data, cognitoUser));
        dispatch({
          type: SET_IMAGES_ITEM,
          payload: [],
        });
        toast('Image updated successfully', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_FETCH_IMAGES,
        });
      });
  };

export const loadDefaultPhotoAccount: AppThunk =
  ({ photos, setOpen, name = 'default.webp', size }: LoadLogoAccountProps) =>
  async (dispatch) => {
    dispatch({ type: START_FETCH_IMAGES });
    const photo: {
      links: IPriview;
    } = {
      links: {
        full: '',
        medium: '',
        original: '',
        preloader: '',
      },
    };
    photos.forEach((item) => {
      photo.links[item.type as keyof IPriview] = item.link;
    });
    const body: LoadDefaultPhotoAccountBody = {
      default_photo: {
        name,
        size: formatBytes(size),
        links: photo.links,
      },
    };

    const cognitoUser: CustomCognitoUser = await Auth.currentAuthenticatedUser();

    API.v1.account
      .changeAccountAttribute(body)
      .then(({ data }) => {
        setOpen(false);
        dispatch(updateAccountInfo(data, cognitoUser));
        dispatch({
          type: SET_IMAGES_ITEM,
          payload: [],
        });
        toast('Image updated successfully', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_FETCH_IMAGES,
        });
      });
  };

export const loadImagesItem: AppThunk =
  ({
    id,
    photos,
    setOpen,
    history,
    setItemCreated,
    updateFromItem = false,
    globalCategoryId,
  }: LoadImagesItemProps) =>
  async (dispatch) => {
    dispatch({ type: START_FETCH_IMAGES });
    const photo: IPriview = {
      full: '',
      medium: '',
      original: '',
      preloader: '',
    };
    photos.forEach((item) => {
      photo[item.type as keyof IPriview] = item.link;
    });
    const body = {
      photo: photo,
      photo_version: +photo?.full?.split('_')[1]?.split('.')[0],
    };

    API.v1.item
      .changeItemAttribute(id, body)
      .then(({ data: newItem }) => {
        if (setOpen) setOpen(false);
        const oldCategoryList =
          store.getState().category?.activeCategory.categoryList[globalCategoryId] || [];

        const newCategoryList = oldCategoryList.map((category) => {
          if (category?.id === newItem?.category_id) {
            return {
              ...category,
              items:
                category.items?.map((item) => {
                  if (item?.id === newItem?.id) {
                    return newItem;
                  } else {
                    return item;
                  }
                }) || [],
            };
          } else {
            return category;
          }
        });

        const [newCategoryListObj, beutifyMenu] = getNewValueByCategoryList({
          newCategoryList,
        });

        dispatch({
          type: SUCCESS_FETCH_CATEGORY,
          payload: {
            categoryList: {
              [globalCategoryId]: newCategoryList,
            },
            categoryListObj: {
              [globalCategoryId]: newCategoryListObj,
            },
            beutifyMenu,
          },
        });

        dispatch({
          type: SET_IMAGES_ITEM,
          payload: [],
        });
        if (updateFromItem) {
          dispatch(
            setActiveItem({
              id: id,
            }),
          );
        }
        toast('Image updated successfully', {
          ...defaultToastProps,
          type: 'success',
        });
        if (setItemCreated) setItemCreated(true);
        if (history) history.push('/menu');
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_FETCH_IMAGES,
        });
      });
  };
