import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, TrashIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { createNameWithoutTransalte } from 'helpers/menu';
import { positiveNumberInput, positiveNumberValue } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface AddSelectionFieldProps {
  id: string;
  selection: any;
  setSelections: SetStateType<any>;
  multiChoice: boolean;
  disabled?: boolean;
  testId?: string;
  open: boolean;
  selections: any;
  selectionCount: number;
  toppins: boolean;
}

export const AddSelectionField = ({
  id,
  selection,
  setSelections,
  multiChoice,
  disabled = false,
  testId = '',
  open,
  selections,
  selectionCount,
  toppins,
}: AddSelectionFieldProps) => {
  const account = useAppSelector((state) => state.account.account);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const [withoutTranslation, setWithoutTranslation] = useState(
    !!selection.withoutTranslate,
  );

  useEffect(() => {
    setSelections((prev: any) => {
      const newSelections = { ...prev };
      newSelections[id].withoutTranslate = withoutTranslation;
      return newSelections;
    });
  }, [withoutTranslation]);

  return (
    <div className="mt-3">
      <Disclosure.Button className="flex w-full items-center justify-between py-3 px-2 rounded-lg bg-gray-100 md:hover:bg-gray-50">
        <p className="text-gray-400 text-base whitespace-nowrap pr-2 truncate">
          {selection.name || <FormattedMessage id="name" />}
        </p>

        <div className="flex items-center space-x-3">
          {Object.keys(selections).length > (toppins ? selectionCount : 1) && (
            <button
              onClick={() => {
                setSelections((prev: any) => {
                  const newSelectionsArr = Object.values(prev).filter(
                    (fillteresSelection: any) => selection.id !== fillteresSelection.id,
                  );
                  const newSelectionObj: any = {};
                  newSelectionsArr.forEach((select: any) => {
                    newSelectionObj[select.id] = select;
                  });
                  return newSelectionObj;
                });
              }}
            >
              <TrashIcon className="h-6 w-6 text-red-400" />
            </button>
          )}

          <span className="ltr:ml-2 rtl:mr-2 flex h-7 items-center">
            <ChevronDownIcon
              className={`h-6 w-6 text-gray-400 transition-all duration-500 ease-in-out ${
                open ? 'rotate-180' : ''
              }`}
              aria-hidden="true"
            />
          </span>
        </div>
      </Disclosure.Button>
      <Disclosure.Panel className="mx-3 px-3 py-2 bg-gray-100 rounded-lg mt-2">
        <div className="mb-2">
          <label
            htmlFor={'selection-' + id}
            className="block rtl:text-right text-sm font-medium text-gray-700"
          >
            <FormattedMessage id="selectionName" />
          </label>
          <div className="mt-1">
            <input
              disabled={disabled}
              type="text"
              name={'selection'}
              value={selection.name}
              onChange={(e) =>
                setSelections((prev: any) => {
                  const newSelections = { ...prev };
                  newSelections[id].name = e.target.value;
                  if (withoutTranslation) {
                    newSelections[id].nameTranslate = createNameWithoutTransalte(
                      e.target.value,
                    );
                  } else {
                    newSelections[id].nameTranslate = {};
                  }
                  return newSelections;
                })
              }
              id={'selection-' + id}
              data-testId={'cypress-add-option-name-' + testId}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Selection Name"
            />
          </div>
        </div>

        <div className="relative flex items-start justify-end mb-4">
          <div className="flex h-6 items-center">
            <input
              id={'without-translate-' + id}
              aria-describedby="without-translate"
              name="without-translate"
              type="checkbox"
              data-testId={'cypress-add-option-translate-' + testId}
              checked={withoutTranslation}
              onChange={(e) => {
                if (setWithoutTranslation) {
                  setWithoutTranslation(e.target.checked);
                }
                if (e.target.checked) {
                  if (selection.name.trim()) {
                    setSelections((prev: any) => {
                      const newSelections = { ...prev };
                      newSelections[id].nameTranslate = createNameWithoutTransalte(
                        selection.name,
                      );
                      return newSelections;
                    });
                  }
                } else {
                  setSelections((prev: any) => {
                    const newSelections = { ...prev };
                    newSelections[id].nameTranslate = {};
                    return newSelections;
                  });
                }
              }}
              disabled={disabled}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label
              htmlFor={'without-translate-' + id}
              className="font-medium text-gray-900 select-none"
            >
              <FormattedMessage id="withoutTranslate" />
            </label>
          </div>
        </div>

        <div className="flex items-end justify-between w-full">
          <div className="w-[49%]">
            <label
              htmlFor={'price-' + id}
              className="block text-sm font-medium text-gray-700"
            >
              <FormattedMessage id="price" />
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="price"
                data-testId={'cypress-add-option-price-' + testId}
                disabled={disabled}
                value={positiveNumberValue('' + selection.price, intCurrency)}
                onChange={(e) =>
                  setSelections((prev: any) => {
                    const newSelections = { ...prev };
                    newSelections[id].price = positiveNumberInput(e, intCurrency);
                    return newSelections;
                  })
                }
                id={'price-' + id}
                className="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder={intCurrency ? '0' : '0.00'}
                aria-describedby="price-currency"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  {account?.currency}
                </span>
              </div>
            </div>
          </div>

          {!multiChoice && (
            <div className="flex items-center justify-end w-[49%] mb-[10px]">
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id={'selection-default-' + id}
                    name="comments"
                    type="radio"
                    disabled={disabled}
                    checked={selection.default_value}
                    onClick={(e) =>
                      setSelections((prev: any) => {
                        const newSelections = { ...prev };
                        const defaultPrevId = Object.keys(newSelections).find(
                          (id) => newSelections[id].default_value,
                        );
                        if (defaultPrevId) {
                          newSelections[defaultPrevId].default_value = false;
                        }
                        const target = e.target as HTMLInputElement;
                        newSelections[id].default_value = target.checked;
                        return newSelections;
                      })
                    }
                    className="h-4 w-4 rounded-full border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
                <div className="ltr:ml-2 rtl:mr-2 text-sm">
                  <label
                    htmlFor={'selection-default-' + id}
                    className="font-medium text-gray-700"
                  >
                    <FormattedMessage id="default" />
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </Disclosure.Panel>
    </div>
  );
};
