import {
  RESET_THEME,
  START_THEME_LOADING,
  START_THEME_SUB_LOADING,
  STOP_THEME_LOADING,
  STOP_THEME_SUB_LOADING,
  SUCCESS_GET_THEMES_LIST,
} from './actionsTypes';
import { ThemesActions, ThemesReducerState } from './types';

const initState: ThemesReducerState = {
  loading: false,
  subLoading: false,
  themeList: [],
};

export const themesReducer = (
  state: ThemesReducerState = initState,
  action: ThemesActions,
) => {
  switch (action.type) {
    case START_THEME_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_THEME_LOADING:
      return {
        ...state,
        loading: false,
      };
    case START_THEME_SUB_LOADING:
      return {
        ...state,
        subLoading: true,
      };
    case STOP_THEME_SUB_LOADING:
      return {
        ...state,
        subLoading: false,
      };
    case SUCCESS_GET_THEMES_LIST:
      return {
        ...state,
        themeList: action.payload,
      };
    case RESET_THEME:
      return initState;
    default:
      return state;
  }
};
