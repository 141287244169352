import { defaultToastProps } from 'appConstants';
import { Languages, SelectedLanguage, SetStateType, TranslateFiled } from 'commonTypes';
import { Spinner } from 'components/Common/Spinner';
import { fetchLambdaTranslate } from 'helpers/translate';
import { checkFieldNotEmpty } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

interface AddCategoryButtonProps {
  edit: boolean;
  activeStep: number;
  categoryName: string;
  addCategoryTranslate: object | Record<string, never> | Record<Languages, string>;
  selected: SelectedLanguage | undefined;
  setAddCategoryTranslate: SetStateType<
    object | Record<string, never> | Record<Languages, string>
  >;
  setLoadingTranslateLabmda: SetStateType<boolean>;
  setActiveStep: SetStateType<number>;
  description: string;
  descriptionTranslate: object | Record<string, never> | Record<Languages, string>;
  setDescriptionTranslate: SetStateType<
    object | Record<string, never> | Record<Languages, string>
  >;
  handleCreateCategory: () => void;
  loadingTranslateLabmda: boolean;
  selectedDescription: SelectedLanguage | undefined;
  descriptionOld: Record<string, never> | TranslateFiled | undefined;
  setOpen: SetStateType<boolean>;
  resetFields: () => void;
  name: TranslateFiled | undefined;
  withoutTranslationsName: boolean;
  withoutTranslationsDescription: boolean;
}

export const AddCategoryButton = ({
  edit,
  activeStep,
  categoryName,
  addCategoryTranslate,
  selected,
  setAddCategoryTranslate,
  setLoadingTranslateLabmda,
  setActiveStep,
  description,
  descriptionTranslate,
  setDescriptionTranslate,
  handleCreateCategory,
  loadingTranslateLabmda,
  selectedDescription,
  descriptionOld,
  setOpen,
  resetFields,
  name,
  withoutTranslationsName,
  withoutTranslationsDescription,
}: AddCategoryButtonProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.category.subLoading);

  const handleSaveClick = () => {
    if (activeStep === 1) {
      if (categoryName.trim() && selected?.value) {
        if (
          addCategoryTranslate[selected?.value as keyof typeof addCategoryTranslate] !==
            categoryName &&
          !withoutTranslationsName
        ) {
          setAddCategoryTranslate({});
          dispatch(
            fetchLambdaTranslate({
              setLoading: setLoadingTranslateLabmda,
              setData: setAddCategoryTranslate,
              text: categoryName.trim(),
              source_lang: selected.value,
              setActiveStep,
            }),
          );
        } else if (withoutTranslationsName) {
          setActiveStep(3);
        }
      } else {
        toast('Category name required', {
          ...defaultToastProps,
          type: 'error',
        });
      }
    } else if (activeStep === 2) {
      if (checkFieldNotEmpty(addCategoryTranslate)) {
        setActiveStep(3);
      } else {
        toast('Translations category name required', {
          ...defaultToastProps,
          type: 'error',
        });
      }
    } else if (activeStep === 3) {
      if (description.trim() && selectedDescription?.value) {
        if (
          descriptionTranslate[
            selectedDescription?.value as keyof typeof descriptionTranslate
          ] !== description &&
          !withoutTranslationsDescription
        ) {
          setDescriptionTranslate({});
          dispatch(
            fetchLambdaTranslate({
              setLoading: setLoadingTranslateLabmda,
              setData: setDescriptionTranslate,
              text: description.trim(),
              source_lang: selectedDescription.value,
              setActiveStep,
            }),
          );
        } else {
          if (
            Object.keys(descriptionTranslate).length > 0 &&
            !withoutTranslationsDescription
          ) {
            setActiveStep(4);
          } else if (
            Object.keys(descriptionTranslate).length === 0 ||
            withoutTranslationsDescription
          ) {
            handleCreateCategory();
          }
        }
      } else {
        handleCreateCategory();
      }
    } else if (activeStep === 4) {
      handleCreateCategory();
    }
  };

  if (edit) {
    return (
      <div>
        {activeStep === 1 ? (
          // @ts-ignore
          name.translations[name.source_lang] === categoryName ? (
            <button
              disabled={loadingTranslateLabmda}
              onClick={() => {
                if (withoutTranslationsName) {
                  setActiveStep(3);
                } else {
                  setActiveStep(2);
                }
              }}
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
            >
              <FormattedMessage id="next" />
            </button>
          ) : (
            <button
              disabled={loadingTranslateLabmda}
              onClick={() => {
                if (categoryName.trim()) {
                  if (withoutTranslationsName) {
                    setActiveStep(3);
                  } else {
                    setAddCategoryTranslate({});
                    dispatch(
                      fetchLambdaTranslate({
                        setLoading: setLoadingTranslateLabmda,
                        setData: setAddCategoryTranslate,
                        text: categoryName.trim(),
                        // @ts-ignore
                        source_lang: selected?.value,
                        setActiveStep,
                      }),
                    );
                  }
                } else {
                  toast('Category name required', {
                    ...defaultToastProps,
                    type: 'error',
                  });
                }
              }}
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
            >
              {loading || loadingTranslateLabmda ? (
                <Spinner color="bg-white" />
              ) : (
                <FormattedMessage id="savengonext" />
              )}
            </button>
          )
        ) : activeStep === 2 ? (
          <button
            disabled={loadingTranslateLabmda}
            onClick={() => {
              if (checkFieldNotEmpty(addCategoryTranslate)) {
                setActiveStep(3);
              } else {
                toast('Translations category name required', {
                  ...defaultToastProps,
                  type: 'error',
                });
              }
            }}
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="next" />
          </button>
        ) : activeStep === 3 ? (
          descriptionOld?.source_lang &&
          descriptionOld?.translations &&
          (descriptionOld?.translations[descriptionOld?.source_lang] || '') ===
            description ? (
            <button
              disabled={loadingTranslateLabmda}
              onClick={() => {
                if (description.trim() && !withoutTranslationsDescription) {
                  setActiveStep(4);
                } else {
                  handleCreateCategory();
                }
              }}
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
            >
              {description.trim() && !withoutTranslationsDescription ? (
                <FormattedMessage id="next" />
              ) : (
                <FormattedMessage id="save" />
              )}
            </button>
          ) : (
            <button
              disabled={loadingTranslateLabmda}
              onClick={() => {
                if (description) {
                  if (withoutTranslationsDescription) {
                    handleCreateCategory();
                  } else {
                    setDescriptionTranslate({});
                    dispatch(
                      fetchLambdaTranslate({
                        setLoading: setLoadingTranslateLabmda,
                        setData: setDescriptionTranslate,
                        text: description.trim(),
                        // @ts-ignore
                        source_lang: selectedDescription?.value,
                        setActiveStep,
                      }),
                    );
                  }
                } else {
                  handleCreateCategory();
                }
              }}
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
            >
              {loading || loadingTranslateLabmda ? (
                <Spinner color="bg-white" />
              ) : (
                <FormattedMessage id="savengonext" />
              )}
            </button>
          )
        ) : JSON.stringify(name?.translations) !== JSON.stringify(addCategoryTranslate) ||
          JSON.stringify(descriptionOld?.translations || {}) !==
            JSON.stringify(descriptionTranslate) ? (
          <button
            disabled={loadingTranslateLabmda}
            onClick={() => handleCreateCategory()}
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            {loading ? (
              <Spinner color="bg-white" />
            ) : edit ? (
              <FormattedMessage id="edit" />
            ) : (
              <FormattedMessage id="next" />
            )}
          </button>
        ) : (
          <button
            disabled={loadingTranslateLabmda}
            onClick={() => {
              setOpen(false);
              setTimeout(() => {
                resetFields();
              }, 250);
            }}
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="save" />
          </button>
        )}
      </div>
    );
  } else {
    return (
      <button
        data-testId="cypress-add-category-next"
        disabled={loadingTranslateLabmda}
        onClick={() => handleSaveClick()}
        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
      >
        {loading || loadingTranslateLabmda ? (
          <Spinner color="bg-white" />
        ) : (activeStep === 3 && description.trim() === '') ||
          activeStep === 4 ||
          (activeStep === 3 && withoutTranslationsDescription) ? (
          <FormattedMessage id="save" />
        ) : (
          <FormattedMessage id="next" />
        )}
      </button>
    );
  }
};
