import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { useAppSelector } from 'hooks/store';
import { FormattedMessage } from 'react-intl';

interface EmptyTabProps {
  setOpen: SetStateType<boolean>;
  setExistOpen?: SetStateType<boolean>;
  mode?: string;
}

export const EmptyTab = ({
  setOpen,
  setExistOpen,
  mode = 'suggestions',
}: EmptyTabProps) => {
  const options = useAppSelector((state) => state.options.optionsApi);

  return (
    <div>
      {mode === 'suggestions' && (
        <div className="rounded-md bg-blue-50 p-4 mb-2">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                <FormattedMessage id="suggestionText" />
              </p>
            </div>
          </div>
        </div>
      )}
      <div
        className={`text-center py-3 ${
          mode === 'suggestions' ? 'ipad:py-10' : 'ipad:py-24'
        }`}
      >
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-bold text-gray-900">
          <FormattedMessage id={mode === 'suggestions' ? 'noSuggestions' : 'noOptions'} />
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          <FormattedMessage
            id={mode === 'suggestions' ? 'getStartedSuggestions' : 'getStartedOptions'}
          />
        </p>
        <div className="mt-6 flex items-center justify-center flex-wrap sm:space-x-4 rtl:space-x-reverse space-y-3 sm:space-y-0">
          <button
            onClick={() => {
              setOpen(true);
            }}
            type="button"
            data-testId="cypress-add-suggestion"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <PlusIcon
              className="ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2 h-5 w-5"
              aria-hidden="true"
            />
            <FormattedMessage
              id={mode === 'suggestions' ? 'createSuggestion' : 'addOption'}
            />
          </button>

          {mode === 'options' && options?.length !== 0 && (
            <button
              data-testId="cypress-add-from-exist-option"
              onClick={() => {
                if (setExistOpen) {
                  setExistOpen(true);
                }
              }}
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
            >
              <PlusIcon
                className="ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2 h-5 w-5"
                aria-hidden="true"
              />
              <FormattedMessage id="chooseFromExisting" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
