// @ts-nocheck
import { Disclosure } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { fetchLambdaTranslate, fetchLambdaTranslateSelection } from 'helpers/translate';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { defaultToastProps, selectLanguages } from '../../appConstants';
import { checkFieldNotEmpty } from '../../helpers/validation';
import { addOption } from '../../store/options/actions';
import { AddOptionFirstStep } from './AddOption/AddOptionFirstStep';
import { AddOptionLastStep } from './AddOption/AddOptionLastStep';
import { AddOptionSecondStep } from './AddOption/AddOptionSecondStep';
import { AddOptionSteper } from './AddOption/AddOptionSteper';
import { AddOptionThirdStep } from './AddOption/AddOptionThirdStep';
import { SelectionTranslateSection } from './AddOption/SelectionTranslateSection';
import { AppModal } from './AppModal';
import { Spinner } from './Spinner';

interface AddOptionModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  setLocalChoose?: SetStateType<string[]>;
  idItem?: string;
  localChoose?: string[];
}

export const AddOptionModal = ({
  open,
  setOpen,
  setLocalChoose,
  idItem,
  localChoose,
}: AddOptionModalProps) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.account);
  const [selected, setSelected] = useState(
    selectLanguages.find((elem) => {
      return elem.value === 'en';
    }),
  );
  const [selectedSelectionsLang, setSelectedSelectionLang] = useState(
    selectLanguages.find((elem) => {
      return elem.value === 'en';
    }),
  );

  const [optionName, setOptionName] = useState('');
  const [optionNameTranslate, setOptionNameTranslate] = useState<any>({});
  const [loadingTranslate, setLoadingTranslate] = useState(false);
  const [multiChoice, setMultiChoice] = useState(false);
  const [toppings, setToppings] = useState(false);
  const [selectionCount, setSelectionCount] = useState(1);
  const [selections, setSelections] = useState<any>({});

  const [withoutTransalteOption, setWithoutTransalteOption] = useState(false);

  const loading = useAppSelector((state) => state.options.subLoading);

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (account?.locale) {
      setSelected(selectLanguages.find((elem) => elem.value === account?.locale));
      setSelectedSelectionLang(
        selectLanguages.find((elem) => elem.value === account?.locale),
      );
    }
  }, [account]);

  const resetState = () => {
    setStep(0);
    setSelectionCount(1);
    setMultiChoice(false);
    setLoadingTranslate(false);
    setOptionNameTranslate({});
    setOptionName('');
    setSelected(selectLanguages.find((elem) => elem.value === account?.locale));
    setSelectedSelectionLang(
      selectLanguages.find((elem) => elem.value === account?.locale),
    );
    setSelections({});
    setWithoutTransalteOption(false);
    setToppings(false);
  };

  const saveHandler = () => {
    if (step === 0) {
      if (optionName.trim()) {
        if (withoutTransalteOption) {
          if (Object.keys(selections).length > 0) {
            setStep(2);
          } else if (+selectionCount > 0) {
            const bufselectionArray = new Array(+selectionCount).fill({
              name: '',
              nameTranslate: {},
              withoutTranslate: false,
              price: 0,
              default_value: false,
            });
            const bufferSelections = {};
            bufselectionArray.forEach((item, index) => {
              bufferSelections[index + 1] = { ...item, id: index + 1 };
            });
            setSelections(bufferSelections);
            setStep(2);
          } else {
            toast('Selections count must be greater than 0', {
              ...defaultToastProps,
              type: 'error',
            });
          }
        } else {
          if (optionNameTranslate[selected.value] === optionName) {
            setStep((prev) => prev + 1);
          } else {
            setOptionNameTranslate({});
            dispatch(
              fetchLambdaTranslate({
                setLoading: setLoadingTranslate,
                setData: setOptionNameTranslate,
                text: optionName.trim(),
                source_lang: selected.value,
                setTranslateActiveTab: setStep,
              }),
            );
          }
        }
      } else {
        toast('Option name and transtation required', {
          ...defaultToastProps,
          type: 'error',
        });
      }
    } else if (step === 1) {
      if (checkFieldNotEmpty(optionNameTranslate)) {
        setStep((prev) => prev + 1);
      } else {
        toast('Option transtation required', {
          ...defaultToastProps,
          type: 'error',
        });
      }
    } else if (step === 2) {
      let checkName = false;
      let checkDefault = false;
      Object.keys(selections).forEach((item) => {
        if (selections[item].name.trim() === '') {
          checkName = true;
        }
        if (selections[item].default_value) {
          checkDefault = true;
        }
      });
      if (Object.keys(selections).length < selectionCount && toppings) {
        toast(
          'When selecting the topping options, the number of selections cannot be less than number of selections for free',
          {
            ...defaultToastProps,
            type: 'error',
          },
        );
        return;
      }
      if (checkName) {
        toast('Selection name and transtation required', {
          ...defaultToastProps,
          type: 'error',
        });
      } else if (!checkDefault && !multiChoice) {
        toast('The non-multiple choice option should have a default value.', {
          ...defaultToastProps,
          type: 'error',
        });
      } else {
        dispatch(
          fetchLambdaTranslateSelection({
            setLoading: setLoadingTranslate,
            selections: selections,
            source_lang: selectedSelectionsLang.value,
            setSelections: setSelections,
            setTranslateActiveTab: setStep,
          }),
        );
      }
    } else if (step === 3) {
      const resultArr = Object.values(selections).filter((selection) => {
        return checkFieldNotEmpty(selection?.nameTranslate);
      });
      if (resultArr.length === Object.keys(selections).length) {
        setStep((prev) => prev + 1);
      } else {
        toast('Selections transtation required', {
          ...defaultToastProps,
          type: 'error',
        });
      }
    } else if (step === 4) {
      const option = {
        name: {
          source_lang: selected?.value,
          translations: optionNameTranslate,
          withoutTranslate: withoutTransalteOption,
        },
        multi_select: multiChoice,
        max_count: 99,
        selections: Object.values(selections)?.map((selection) => ({
          default_value: selection?.default_value,
          price: +selection?.price,
          name: {
            source_lang: selectedSelectionsLang?.value,
            translations: selection?.nameTranslate,
            withoutTranslate: selection?.withoutTranslate,
          },
        })),
        status: 1,
        free_option_data: {
          free_options: toppings,
          amount: selectionCount,
        },
      };
      dispatch(
        addOption({
          option,
          setOpen,
          resetFields: resetState,
          setLocalChoose,
          idItem,
          localChoose,
        }),
      );
    } else {
      setStep((prev) => prev + 1);
    }
  };

  return (
    <AppModal size="sm:max-w-2xl" open={open}>
      <div className="flex w-full items-center justify-between mb-3">
        <h1>
          <FormattedMessage id="addOption" />
        </h1>
        <XMarkIcon
          onClick={() => {
            if (!loadingTranslate && !loading) {
              setOpen(false);
              setTimeout(() => {
                resetState();
              }, 200);
            }
          }}
          className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
        />
      </div>

      <AddOptionSteper step={step} />

      <div className="h-96 overflow-auto scrollbar ltr:pr-1 rtl:pl-1 p-1">
        {step === 0 && (
          <AddOptionFirstStep
            loadingTranslate={loadingTranslate}
            selected={selected}
            setSelected={setSelected}
            optionName={optionName}
            setOptionName={setOptionName}
            withoutTransalteOption={withoutTransalteOption}
            setOptionNameTranslate={setOptionNameTranslate}
            setWithoutTransalteOption={setWithoutTransalteOption}
            multiChoice={multiChoice}
            setMultiChoice={setMultiChoice}
            toppings={toppings}
            setToppings={setToppings}
            selectionCount={selectionCount}
            setSelectionCount={setSelectionCount}
          />
        )}

        {step === 1 && (
          <AddOptionSecondStep
            optionNameTranslate={optionNameTranslate}
            loadingTranslate={loadingTranslate}
            setOptionNameTranslate={setOptionNameTranslate}
          />
        )}

        {step === 2 && (
          <AddOptionThirdStep
            loadingTranslate={loadingTranslate}
            selectedSelectionsLang={selectedSelectionsLang}
            setSelectedSelectionLang={setSelectedSelectionLang}
            selections={selections}
            multiChoice={multiChoice}
            setSelections={setSelections}
            selectionCount={selectionCount}
            toppins={toppings}
          />
        )}

        {step === 3 && !loadingTranslate && (
          <div className="mb-3">
            {Object.keys(selections).map((item) => {
              return (
                <Disclosure as="div" key={'selection-translate-' + item}>
                  {({ open }) => (
                    <SelectionTranslateSection
                      open={open}
                      selections={selections}
                      setSelections={setSelections}
                      item={item}
                    />
                  )}
                </Disclosure>
              );
            })}
          </div>
        )}

        {step === 4 && (
          <AddOptionLastStep
            optionName={optionName}
            selections={selections}
            multiChoice={multiChoice}
          />
        )}
      </div>

      <div className="w-full flex items-center justify-end mt-3">
        {step !== 0 && (
          <button
            type="button"
            disabled={loadingTranslate}
            onClick={() => {
              if (step == 2 && withoutTransalteOption) {
                setStep(0);
                return;
              }
              setStep((prev) => prev - 1);
            }}
            className="ltr:mr-3 rtl:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <FormattedMessage id="previous" />
          </button>
        )}
        <button
          type="button"
          onClick={saveHandler}
          disabled={loadingTranslate || loading}
          data-testId="cypress-add-option-next"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {loadingTranslate || loading ? (
            <Spinner color="bg-white" />
          ) : step === 4 ? (
            <FormattedMessage id="save" />
          ) : (
            <FormattedMessage id="next" />
          )}
        </button>
      </div>
    </AppModal>
  );
};
