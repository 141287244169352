import { LOCALES } from './locale';
import af from './tr/af.json';
import am from './tr/am.json';
import ar from './tr/ar.json';
import az from './tr/az.json';
import bg from './tr/bg.json';
import bn from './tr/bn.json';
import bs from './tr/bs.json';
import ca from './tr/ca.json';
import cs from './tr/cs.json';
import cy from './tr/cy.json';
import da from './tr/da.json';
import de from './tr/de.json';
import el from './tr/el.json';
import en from './tr/en.json';
import es from './tr/es.json';
import et from './tr/et.json';
import fa from './tr/fa.json';
import fi from './tr/fi.json';
import fr from './tr/fr.json';
import ga from './tr/ga.json';
import gu from './tr/gu.json';
import ha from './tr/ha.json';
import he from './tr/he.json';
import hi from './tr/hi.json';
import hr from './tr/hr.json';
import ht from './tr/ht.json';
import hu from './tr/hu.json';
import hy from './tr/hy.json';
import id from './tr/id.json';
import is from './tr/is.json';
import it from './tr/it.json';
import ja from './tr/ja.json';
import ka from './tr/ka.json';
import kk from './tr/kk.json';
import kn from './tr/kn.json';
import ko from './tr/ko.json';
import lt from './tr/lt.json';
import lv from './tr/lv.json';
import mk from './tr/mk.json';
import ml from './tr/ml.json';
import mn from './tr/mn.json';
import mr from './tr/mr.json';
import ms from './tr/ms.json';
import mt from './tr/mt.json';
import nl from './tr/nl.json';
import no from './tr/no.json';
import pa from './tr/pa.json';
import pl from './tr/pl.json';
import ps from './tr/ps.json';
import pt from './tr/pt.json';
import ro from './tr/ro.json';
import ru from './tr/ru.json';
import si from './tr/si.json';
import sk from './tr/sk.json';
import sl from './tr/sl.json';
import so from './tr/so.json';
import sq from './tr/sq.json';
import sr from './tr/sr.json';
import sv from './tr/sv.json';
import sw from './tr/sw.json';
import ta from './tr/ta.json';
import te from './tr/te.json';
import th from './tr/th.json';
import tl from './tr/tl.json';
import tr from './tr/tr.json';
import uk from './tr/uk.json';
import ur from './tr/ur.json';
import uz from './tr/uz.json';
import vi from './tr/vi.json';
import zh from './tr/zh.json';

export const messages = {
  [LOCALES.ENGLISH]: en,
  [LOCALES.RUSSIAN]: ru,
  [LOCALES.HUNGARY]: hu,
  [LOCALES.ALBANIAN]: sq,
  [LOCALES.AFRIKAANS]: af,
  [LOCALES.ARABIC]: ar,
  [LOCALES.AMHARIC]: am,
  [LOCALES.ARMENIAN]: hy,
  [LOCALES.AZERBAIJANI]: az,
  [LOCALES.BENGALI]: bn,
  [LOCALES.BOSNIAN]: bs,
  [LOCALES.BULGARIAN]: bg,
  [LOCALES.CATALAN]: ca,
  [LOCALES.CHINESE]: zh,
  [LOCALES.CROATIAN]: hr,
  [LOCALES.CZECH]: cs,
  [LOCALES.DANISH]: da,
  [LOCALES.DUTCH]: nl,
  [LOCALES.ESTONIAN]: et,
  [LOCALES.FARSI]: fa,
  [LOCALES.FILIPINO]: tl,
  [LOCALES.FINNISH]: fi,
  [LOCALES.FRENCH]: fr,
  [LOCALES.GEORGIAN]: ka,
  [LOCALES.GERMAN]: de,
  [LOCALES.GREEK]: el,
  [LOCALES.GUJARATI]: gu,
  [LOCALES.HAITIAN]: ht,
  [LOCALES.HAUSA]: ha,
  [LOCALES.HEBREW]: he,
  [LOCALES.HINDI]: hi,
  [LOCALES.ICELANDIC]: is,
  [LOCALES.INDONESIAN]: id,
  [LOCALES.IRISH]: ga,
  [LOCALES.ITALIAN]: it,
  [LOCALES.JAPANESE]: ja,
  [LOCALES.KANNADA]: kn,
  [LOCALES.KAZAKH]: kk,
  [LOCALES.KOREAN]: ko,
  [LOCALES.LATVIAN]: lv,
  [LOCALES.LITHUANIAN]: lt,
  [LOCALES.MACEDONIAN]: mk,
  [LOCALES.MALAY]: ms,
  [LOCALES.MALAYALAM]: ml,
  [LOCALES.MALTESE]: mt,
  [LOCALES.MARATHI]: mr,
  [LOCALES.MONGOLIAN]: mn,
  [LOCALES.NORWEGIAN]: no,
  [LOCALES.PASHTO]: ps,
  [LOCALES.POLISH]: pl,
  [LOCALES.PORTUGUESE]: pt,
  [LOCALES.PUNJABI]: pa,
  [LOCALES.ROMANIAN]: ro,
  [LOCALES.SERBIAN]: sr,
  [LOCALES.SINHALA]: si,
  [LOCALES.SLOVAK]: sk,
  [LOCALES.SLOVENIAN]: sl,
  [LOCALES.SOMALI]: so,
  [LOCALES.SPANISH]: es,
  [LOCALES.SWAHILI]: sw,
  [LOCALES.SWEDISH]: sv,
  [LOCALES.TAMIL]: ta,
  [LOCALES.TELUGU]: te,
  [LOCALES.THAI]: th,
  [LOCALES.TURKISH]: tr,
  [LOCALES.UKRANIAN]: uk,
  [LOCALES.URDU]: ur,
  [LOCALES.UZBEK]: uz,
  [LOCALES.VIETNAMESE]: vi,
  [LOCALES.WELSH]: cy,
};
