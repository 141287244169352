import { XMarkIcon } from '@heroicons/react/24/outline';
import { defaultToastProps } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { AppInput } from 'components/Common/AppInput';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { ChangeEvent, useEffect } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { changeName } from 'store/account/actions';

interface ChangeNameModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  typeName: 'restName' | 'firstName' | 'lastName';
  oldName: string;
}

export const ChangeNameModal = ({
  open,
  setOpen,
  typeName,
  oldName,
}: ChangeNameModalProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.account.subLoading);
  const [name, setName] = useState(oldName);
  const [discardClick, setDiscardClick] = useState(0);

  useEffect(() => {
    setName(oldName);
  }, [oldName]);

  const handleOldPasswordChange = () => {
    setDiscardClick(0);
    if (name.trim() === '') {
      toast('Name cannot be empty', {
        ...defaultToastProps,
        type: 'error',
      });
    } else {
      dispatch(
        changeName({
          setOpen,
          typeName,
          newName: name.trim(),
        }),
      );
    }
  };

  const resetFields = () => {
    setName(oldName);
    setDiscardClick(0);
    setOpen(false);
  };

  return (
    <AppModal open={open} size="sm:max-w-md">
      <div className="flex flex-col">
        <div className="flex items-center justify-between w-full mb-3">
          <h1>
            <FormattedMessage id={typeName} />{' '}
          </h1>
          <XMarkIcon
            onClick={() => {
              if (name === oldName) {
                resetFields();
              } else {
                if (discardClick === 0) {
                  setDiscardClick(1);
                } else {
                  resetFields();
                }
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="mb-4">
          <AppInput
            label="name"
            id="name"
            name={typeName}
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            testId="cypress-name-input"
          />
        </div>

        <div className="w-full flex items-center justify-end">
          {name === oldName ? (
            <>
              <button
                type="button"
                disabled={loading}
                onClick={() => {
                  resetFields();
                }}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="cancel" />
              </button>
            </>
          ) : (
            <div className="flex items-center">
              <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
                {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
              </div>
              <div className="flex items-center justify-end w-full sm:w-auto">
                <button
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    if (discardClick === 0) {
                      setDiscardClick(1);
                    } else {
                      resetFields();
                    }
                  }}
                  className="inline-flex ltr:mr-3 rtl:ml-3 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  <FormattedMessage id="discardChanges" />
                </button>
                <button
                  disabled={loading}
                  onClick={handleOldPasswordChange}
                  type="button"
                  data-testId="cypress-save-name"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  {loading ? <Spinner /> : <FormattedMessage id="save" />}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </AppModal>
  );
};
