import { Bars4Icon } from '@heroicons/react/24/outline';
import { oldLanguages } from 'appConstants';
import { ISelections } from 'commonTypes';
import { useAppSelector } from 'hooks/store';

interface SelectionRowBlockProps {
  selection: ISelections;
}

export const SelectionRowBlock = ({ selection }: SelectionRowBlockProps) => {
  const locale = useAppSelector((state) => state.locale.lang);
  const account = useAppSelector((state) => state.account.account);

  return (
    <div className="flex items-center justify-between py-2 px-3 cursor-pointer bg-white md:hover:bg-gray-200 rounded-lg border">
      <div className="flex">
        <div className="ltr:ml-0 rtl:mr-0">
          <div className="flex items-center">
            <p className="text-sm font-medium text-gray-900 select-none ">
              {selection?.name?.translations
                ? selection?.name?.translations[oldLanguages[locale]]
                : ''}
            </p>
            <div
              className={`h-2 w-2 ltr:ml-3 rtl:mr-3 rounded-full ${
                selection?.status === 1 ? 'bg-green-500' : 'bg-gray-500'
              }`}
            />
          </div>
          <p className="text-sm text-gray-500 select-none rtl:text-right">
            {selection?.price || 0} {account?.currency}
          </p>
        </div>
      </div>

      <Bars4Icon
        className="h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
        aria-hidden="true"
      />
    </div>
  );
};
