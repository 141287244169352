import { API } from 'api';
import { defaultToastProps } from 'appConstants';
import { Auth } from 'aws-amplify';
import { SetStateType } from 'commonTypes';
import { categoryNameList } from 'helpers/menu';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { store } from 'store';
import { EnableMenuBody } from 'store/account/types';
import { setActiveCategoryRedux } from 'store/category/actions';
import { SUCCESS_FETCH_CATEGORY } from 'store/category/actionTypes';
import { FetchCategoryAction } from 'store/category/types';
import { AppThunk } from 'store/globalTypes';

import { updateAccountInfo } from '../account/actions';
import {
  START_CHANGE_MENU,
  START_CREATE_MENU,
  START_DELETE_MENU,
  START_ENABLE_MENU,
  START_FETCH_MENU,
  STOP_CHANGE_MENU,
  STOP_CREATE_MENU,
  STOP_DELETE_MENU,
  STOP_ENABLE_MENU,
  STOP_FETCH_MENU,
  SUCCESS_CREATE_MENU,
  SUCCESS_DELETE_MENU,
  SUCCESS_FETCH_MENU,
  SUCCESS_UPDATE_MENU,
} from './actionsTypes';
import {
  BeutifyMenu,
  Categories,
  CreatedMenuBody,
  CreateMenuAction,
  DeleteMenuProps,
  EditMenuAction,
  EditMenuProps,
  EnableMenuProps,
  FetchMenuAction,
} from './types';

export const getRestaurantMenu: AppThunk =
  () => async (dispatch: Dispatch<FetchMenuAction>) => {
    dispatch({ type: START_FETCH_MENU });

    API.v1.menu
      .getRestaurantMenu()
      .then(({ data }) => {
        dispatch({
          type: SUCCESS_FETCH_MENU,
          payload: { menu: data },
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => dispatch({ type: STOP_FETCH_MENU }));
  };

const setActiveDefautlMenu: AppThunk =
  ({ setActiveGlobalMenu }: { setActiveGlobalMenu: SetStateType<string> }) =>
  (dispatch) => {
    const menu = store.getState().menu.menu;
    const defaultMenu = menu?.find((menu) => menu?.status === 3);
    if (defaultMenu) {
      const account = store.getState().account.account;
      setActiveGlobalMenu(defaultMenu?.id);
      localStorage.setItem('activeGlobalMenu-' + account?.id, defaultMenu?.id);
      dispatch(
        setActiveCategoryRedux({
          globalCategoryId: defaultMenu?.id,
        }),
      );
    }
  };

export const enableMenu: AppThunk =
  ({ mode, resetFields, setActiveGlobalMenu }: EnableMenuProps) =>
  async (dispatch) => {
    dispatch({
      type: START_ENABLE_MENU,
    });

    const body: EnableMenuBody = {
      with_global_menus: mode === 1,
    };

    const cognitoUser = await Auth.currentAuthenticatedUser();

    API.v1.account
      .changeAccountAttribute(body)
      .then(({ data }) => {
        dispatch(updateAccountInfo(data, cognitoUser));
        resetFields();
        if (mode === 2) {
          dispatch(setActiveDefautlMenu({ setActiveGlobalMenu }));
          const menu = store.getState().menu.menu;
          const defaultMenu = menu?.find((menu) => menu?.status === 3);
          dispatch(
            setActiveCategoryRedux({
              globalCategoryId: defaultMenu?.id,
              force: true,
            }),
          );
          dispatch(getRestaurantMenu());
          toast('Global menus have been successfully disabled', {
            ...defaultToastProps,
            type: 'success',
          });
        } else {
          toast('Global menus have been successfully enabled', {
            ...defaultToastProps,
            type: 'success',
          });
        }
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_ENABLE_MENU,
        });
      });
  };

export const createMenu: AppThunk =
  ({ newMenu, resetFields }: { newMenu: CreatedMenuBody; resetFields: () => void }) =>
  async (dispatch: Dispatch<CreateMenuAction | FetchCategoryAction>) => {
    dispatch({
      type: START_CREATE_MENU,
    });

    const updateMenusList = async (id: string) => {
      const { data } = await API.v1.menu.getMenuById(id);

      const categoryList = data?.categories || [];
      // @ts-ignore
      const categoryListObj: {
        [key: string]: Categories;
      } = categoryNameList(categoryList, true);

      const beutifyMenu: BeutifyMenu = {};

      categoryList.forEach((category) => {
        category.items?.forEach((item) => {
          beutifyMenu[item.id] = {
            ...item,
            categoryName: category.name,
            categoryId: category.id,
            menuId: category.menu_id,
          };
        });
      });

      dispatch({
        type: SUCCESS_FETCH_CATEGORY,
        payload: {
          categoryList: {
            [id]: categoryList,
          },
          categoryListObj: {
            [id]: categoryListObj,
          },
          beutifyMenu,
        },
      });
    };

    API.v1.menu
      .createMenu(newMenu)
      .then(({ data }) => {
        dispatch({
          type: SUCCESS_CREATE_MENU,
          payload: data,
        });
        updateMenusList(data.id);
        resetFields();
        toast('Menu successfully created', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_CREATE_MENU,
        });
      });
  };

export const deleteMenu: AppThunk =
  ({ globalMenuId, mode, setActiveGlobalMenu, resetField }: DeleteMenuProps) =>
  async (dispatch) => {
    dispatch({
      type: START_DELETE_MENU,
    });

    API.v1.menu
      .deleteMenu(globalMenuId, mode === 'move')
      .then(({ data: newMenu }) => {
        dispatch({
          type: SUCCESS_DELETE_MENU,
          payload: newMenu,
        });

        const menu = store.getState().menu.menu;
        const defaultMenu = menu?.find((menu) => menu?.status === 3);
        dispatch(
          setActiveCategoryRedux({
            globalCategoryId: defaultMenu?.id,
            force: true,
          }),
        );
        dispatch(setActiveDefautlMenu({ setActiveGlobalMenu }));
        resetField();
        toast('Menu successfully deleted', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_DELETE_MENU,
        });
      });
  };

export const editMenu: AppThunk =
  ({ globalMenuId, updateValue, resetField }: EditMenuProps) =>
  async (dispatch: Dispatch<EditMenuAction>) => {
    dispatch({
      type: START_CHANGE_MENU,
    });

    API.v1.menu
      .changeMenu(globalMenuId, updateValue)
      .then(({ data: newMenu }) => {
        const menu = store.getState().menu.menu;
        const newMenuList = menu.map((menu) => {
          if (menu?.id === newMenu?.id) {
            return newMenu;
          } else {
            return menu;
          }
        });
        dispatch({
          type: SUCCESS_UPDATE_MENU,
          payload: newMenuList,
        });
        resetField();
        toast('Menu successfully updated', {
          ...defaultToastProps,
          type: 'success',
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_CHANGE_MENU,
        });
      });
  };

export const getNewValueByCategoryList = ({
  newCategoryList,
}: {
  newCategoryList: Categories[];
}): [
  {
    [key: string]: Categories;
  },
  Record<string, never> | BeutifyMenu,
] => {
  // @ts-ignore
  const newCategoryListObj: {
    [key: string]: Categories;
  } = categoryNameList(newCategoryList, true);
  const beutifyMenu: BeutifyMenu | Record<string, never> = {};

  newCategoryList.forEach((category) => {
    category.items?.forEach((item) => {
      beutifyMenu[item.id] = {
        ...item,
        categoryName: category.name,
        categoryId: category.id,
        menuId: category.menu_id,
      };
    });
  });

  return [newCategoryListObj, beutifyMenu];
};
