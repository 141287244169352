import { Menu, Transition } from '@headlessui/react';
import {
  ArchiveBoxIcon,
  Bars4Icon,
  EllipsisVerticalIcon,
  HeartIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { ISelections, SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppDispatch } from 'hooks/store';
import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { changeStatusOption } from 'store/options/actions';

import { ChangeSelectionsOrderModal, OptionDeleteModal } from '../Modals';

interface OptionMenuProps {
  status: number;
  id: string;
  selections: ISelections[];
  localSelectionsList: ISelections[];
  setLocalSelectionsList: SetStateType<ISelections[]>;
}

export const OptionMenu = ({
  status,
  id,
  selections,
  localSelectionsList,
  setLocalSelectionsList,
}: OptionMenuProps) => {
  const dispatch = useAppDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showChangeSelectionsOrder, setShowChangeSelectionsOrder] = useState(false);

  return (
    <Menu
      as="div"
      className="relative ltr:ml-3 rtl:mr-3 ltr:text-left rtl:text-right inline-block"
    >
      <OptionDeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        mode="option"
        id={id}
      />

      <ChangeSelectionsOrderModal
        open={showChangeSelectionsOrder}
        setOpen={setShowChangeSelectionsOrder}
        selectionsList={localSelectionsList}
        setSelectionsList={setLocalSelectionsList}
        optionId={id}
      />
      <div>
        <Menu.Button className="-my-2 flex items-center rounded-full bg-white text-gray-400 md:hover:text-gray-600 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute ltr:right-0 rtl:left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  role="button"
                  tabIndex={0}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm cursor-pointer',
                  )}
                  onClick={() => {
                    dispatch(
                      changeStatusOption({
                        newStatus: status === 1 ? 2 : 1,
                        id: id,
                        mode: 'option',
                      }),
                    );
                  }}
                >
                  {status === 2 && (
                    <HeartIcon
                      className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  )}
                  {status === 1 && (
                    <ArchiveBoxIcon
                      className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  )}
                  {status === 2 && <FormattedMessage id="active" />}
                  {status === 1 && <FormattedMessage id="archived" />}
                </div>
              )}
            </Menu.Item>
            {selections?.length > 1 && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => setShowChangeSelectionsOrder(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm cursor-pointer',
                    )}
                  >
                    <Bars4Icon
                      className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="truncate">
                      <FormattedMessage id="changeSelectionsOrder" />
                    </span>
                  </div>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <div
                  role="button"
                  tabIndex={0}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm cursor-pointer',
                  )}
                  onClick={() => {
                    setOpenDeleteModal(true);
                  }}
                >
                  <TrashIcon
                    className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span>
                    <FormattedMessage id="delete" />
                  </span>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
