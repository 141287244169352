import { EyeIcon } from '@heroicons/react/20/solid';
import { EyeSlashIcon } from '@heroicons/react/20/solid';
import { defaultToastProps } from 'appConstants';
import { ReactComponent as LoginImg } from 'assets/static/login.svg';
import { Spinner } from 'components/Common/Spinner';
import {
  LoginHeaderForm,
  LoginInput,
  LoginSubActionsBlock,
} from 'components/LoginComponents';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { login } from 'store/auth/actions';
import { SET_ERROR } from 'store/auth/actionsTypes';

export const Login = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const loading = useAppSelector((state) => state.auth.loading);
  const error = useAppSelector((state) => state.auth.error);

  useEffect(() => {
    dispatch({
      type: SET_ERROR,
      payload: null,
    });
    return () => {
      dispatch({
        type: SET_ERROR,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (error) {
      toast(error, {
        ...defaultToastProps,
        type: 'error',
        toastId: 'login-error',
      });
    }
  }, [error]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(login(email, password, history));
  };

  return (
    <div className="min-h-screen w-full flex">
      <div className="min-h-full lg:w-1/3 w-0 bg-primary flex items-center justify-center">
        <LoginImg className="w-4/5" />
      </div>
      <div className="min-h-full lg:w-2/3 w-full bg-gray-50">
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <LoginHeaderForm
            label="Sign in to your account"
            linkLabel="request your 14-day free trial"
            to="/request-demo"
            onClick={() => dispatch({ type: SET_ERROR, payload: null })}
          />

          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="mx-4 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form className="space-y-6" onSubmit={handleSubmit}>
                <LoginInput
                  label="Email address"
                  id="login-email"
                  name="email"
                  type="email"
                  value={email}
                  setValue={setEmail}
                  autoComplete="email"
                />

                <LoginInput
                  label="Password"
                  id="login-password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  setValue={setPassword}
                  autoComplete="current-password"
                  iconLeft={
                    <div
                      role="button"
                      tabIndex={0}
                      className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                      onClick={() => {
                        setShowPassword((prev) => !prev);
                      }}
                    >
                      {showPassword ? (
                        <EyeSlashIcon className="h-5 w-5 text-gray-400" />
                      ) : (
                        <EyeIcon className="h-5 w-5 text-gray-400" />
                      )}
                    </div>
                  }
                />

                <LoginSubActionsBlock />

                <div>
                  <button
                    disabled={loading}
                    type="submit"
                    className="whitespace-nowrap break-normal flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                  >
                    {loading ? <Spinner /> : 'Sign in'}
                  </button>
                </div>
              </form>

              {/*<SocialSignIn />*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
