import { AxiosInstance } from 'axios';

export const publicRequest = (
  ApiClient: (publicRequest: boolean) => Promise<AxiosInstance>,
) => {
  return {
    checkAvailabilityLink: async (link: string) =>
      (await ApiClient(true)).get<boolean>(`/qrcode/availability/${link}`),
  };
};
