import { AxiosInstance } from 'axios';
import {
  ChangeAccountAtributesProps,
  RequestBodyAccountResponse,
} from 'store/account/types';
import { CreateUserBody } from 'store/auth/types';

export const account = (ApiClient: () => Promise<AxiosInstance>) => {
  return {
    setAccountInfo: async () =>
      (await ApiClient()).get<RequestBodyAccountResponse>('/account'),
    changeAccountAttribute: async (body: ChangeAccountAtributesProps) =>
      (await ApiClient()).put<RequestBodyAccountResponse>('/account', body),
    createAccount: async (body: CreateUserBody) =>
      (await ApiClient()).post<any>('/account', body),
  };
};
