import { Bars4Icon } from '@heroicons/react/24/outline';
import { oldLanguages } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { setActiveCategoryRedux } from 'store/category/actions';
import { MenuItemState } from 'store/menu/types';

interface GlobalMenuButtonProps {
  menu: MenuItemState | undefined;
  setActiveGlobalMenu: SetStateType<string>;
  activeGlobalMenu: string;
}

export const GlobalMenuButton = ({
  menu,
  setActiveGlobalMenu,
  activeGlobalMenu,
}: GlobalMenuButtonProps) => {
  const account = useAppSelector((state) => state.account.account);
  const dispatch = useAppDispatch();
  const locale = useAppSelector((state) => state.locale.lang);

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => {
        if (menu?.id) {
          setActiveGlobalMenu(menu?.id);
          localStorage.setItem('activeGlobalMenu-' + account?.id, menu?.id);
          dispatch(setActiveCategoryRedux({ globalCategoryId: menu?.id }));
        }
      }}
      className={`h-10 flex items-center space-x-2 px-3 ${
        activeGlobalMenu === menu?.id
          ? 'bg-primary text-white md:hover:bg-white md:hover:text-primary'
          : 'text-primary bg-white md:hover:bg-primary md:hover:text-white'
      } rounded-lg transition-all cursor-pointer relative text-sm text-center border border-primary`}
    >
      <div>
        {menu?.status !== 3 ? (
          <div className="">
            <Bars4Icon className="h-full w-6" />
          </div>
        ) : null}
      </div>

      <div className="whitespace-nowrap">
        {menu?.name?.translations && menu?.name?.translations[oldLanguages[locale]]}
      </div>

      <div>
        {menu?.status === 1 && <div className="h-2.5 w-2.5 rounded-full bg-green-500" />}
        {menu?.status === 2 && <div className="h-2.5 w-2.5 rounded-full bg-gray-500" />}
      </div>
    </div>
  );
};
