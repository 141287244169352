import { CropDropdown } from 'components/Common/CropDropdown';
import { useAppDispatch } from 'hooks/store';
import { useEffect } from 'react';
import { setAccountInfo } from 'store/account/actions';

export const RegisterThirdStep = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAccountInfo());
  }, []);

  return <CropDropdown profileMode={true} registerMode={true} />;
};
