import { ReactNode } from 'react';

interface RegisterSelectInputProps {
  label: string;
  child?: ReactNode | null;
  fromGoogle?: boolean;
}

export const RegisterSelectInput = ({
  label,
  child = null,
  fromGoogle = false,
}: RegisterSelectInputProps) => {
  return (
    <div className={fromGoogle ? 'w-full' : 'w-full sm:w-1/3'}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1">{child}</div>
    </div>
  );
};
