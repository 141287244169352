import { oldLanguages } from 'appConstants';
import { ISelections } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { FormattedNumber } from 'react-intl';

interface ChoosenSelectionProps {
  selection: ISelections;
  multi: boolean;
}

export const ChoosenSelection = ({ selection, multi }: ChoosenSelectionProps) => {
  const account = useAppSelector((state) => state.account.account);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const locale = useAppSelector((state) => state.locale.lang);

  return (
    <div className="flex w-full max-w-full items-center justify-between mb-1">
      <div className="flex h-4 items-start max-w-[70%]">
        {multi ? (
          <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            disabled
            type={multi ? 'checkbox' : 'radio'}
            defaultChecked={selection?.default_value}
            className={`h-4 w-4 ${
              multi ? 'rounded' : 'rounded-full'
            } border-gray-300 text-indigo-600 edit-option-button self-center`}
            style={{
              outline: 'none',
              // @ts-ignore
              '--tw-ring-offset-width': '0px',
              '--tw-ring-shadow': 'none',
            }}
          />
        ) : (
          <div
            className={classNames(
              'min-w-4 min-h-4 rounded-full border relative',
              selection?.default_value ? 'bg-primary border-primary' : 'border-gray-300',
            )}
          >
            <div className="bg-white w-2 h-2 rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          </div>
        )}
        <div className="text-base -mt-1 ltr:pl-3 ltr:pr-2 rtl:pr-3 rtl:pl-2 truncate">
          {selection?.name?.translations
            ? selection?.name?.translations[oldLanguages[locale]]
            : ''}
        </div>
      </div>
      <div>
        <p className="text-base text-gray-500 font-medium">
          +{/* eslint-disable-next-line react/style-prop-object*/}
          <FormattedNumber
            currency={account?.currency}
            style="currency"
            value={selection?.price}
            maximumFractionDigits={intCurrency ? 0 : 2}
          />
        </p>
      </div>
    </div>
  );
};
