import {
  RESET_AUTH,
  SET_ERROR,
  SET_REGISTER_USER,
  START_LOADING,
  STOP_LOADING,
  SUCCESS_LOGIN,
} from './actionsTypes';
import { AuthAction, AuthStore } from './types';

const initialState: AuthStore = {
  isAuth: false,
  user: null,
  loading: false,
  error: null,
};

export const authReducer = (state: AuthStore = initialState, action: AuthAction) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, loading: true, error: null };
    case STOP_LOADING:
      return { ...state, loading: false };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SUCCESS_LOGIN:
      return { ...state, isAuth: action.payload, error: null };
    case SET_REGISTER_USER:
      return { ...state, user: action.payload, error: null };
    case RESET_AUTH:
      return initialState;
    default:
      return state;
  }
};
