import { RoundedState } from 'commonTypes';
import { useAppSelector } from 'hooks/store';
import { FormattedMessage } from 'react-intl';

const ExampleInstans = ({
  numberExample,
  rounded,
}: {
  numberExample: number;
  rounded: RoundedState;
}) => {
  const currency = useAppSelector((state) => state.account.account?.currency);

  const returnDeforeDigit = () => {
    if (numberExample === 1) {
      return '88888888888';
    } else if (numberExample === 2) {
      return '33333333333';
    } else {
      return '55555555555';
    }
  };

  const percentFromValue = ({ roundedOption }: { roundedOption: RoundedState }) => {
    const finalValue = +('47.' + returnDeforeDigit());
    if (roundedOption?.type) {
      const multiplier = Math.pow(10, roundedOption.amount);
      if (roundedOption.type === 'up') {
        return Math.ceil(finalValue * multiplier) / multiplier;
      } else if (roundedOption.type === 'down') {
        return Math.floor(finalValue * multiplier) / multiplier;
      } else {
        return Math.round(finalValue * multiplier) / multiplier;
      }
    } else {
      const multiplier = Math.pow(10, 2);
      return Math.round(finalValue * multiplier) / multiplier;
    }
  };

  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="text-sm font-medium mb-2">
        <FormattedMessage id="example" /> {numberExample}:
      </div>
      <div className="text-xs mb-1">
        <FormattedMessage id="feeBefore" />: 47.{returnDeforeDigit()} {currency}
      </div>
      <div className="text-xs">
        <FormattedMessage id="feeAfter" />: {percentFromValue({ roundedOption: rounded })}{' '}
        {currency}
      </div>
    </div>
  );
};

export const ExampleRound = ({ type, amount }: RoundedState) => {
  return (
    <div className="flex flex-col space-y-3">
      <ExampleInstans numberExample={1} rounded={{ type, amount }} />
      <ExampleInstans numberExample={2} rounded={{ type, amount }} />
      <ExampleInstans numberExample={3} rounded={{ type, amount }} />
    </div>
  );
};
