import { XMarkIcon } from '@heroicons/react/24/outline';
import { dataAllergensIcons } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { editItemField } from 'store/item/actions';

import { AllergensChooseCover } from '../UI/AllergensChooseCover';

interface ChangeAllergensModalProps {
  openAllergensChange: boolean;
  setOpenAllergensChange: SetStateType<boolean>;
  globalCategoryId: string;
}

interface RouteParams {
  id: string;
}

export const ChangeAllergensModal = ({
  openAllergensChange,
  setOpenAllergensChange,
  globalCategoryId,
}: ChangeAllergensModalProps) => {
  const { id } = useParams<RouteParams>();
  const dispatch = useAppDispatch();
  const beutifyMenu = useAppSelector((state) => state.category.beutifyMenu);
  const loadingImages = useAppSelector((state) => state.item.subLoading);
  const [discardClick, setDiscardClick] = useState(0);
  const menu = useAppSelector((state) => state.menu.menu);
  const activeItem = useAppSelector((state) => state.item.activeItem);
  const [allergens, setAllergens] = useState<{
    [key: string]: number;
  }>();

  const [isMobile, setIsMobile] = useState(document.body.clientWidth < 640);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(document.body.clientWidth < 640);
    });
  }, []);

  useEffect(() => {
    if (menu.length > 0) {
      setAllergens(activeItem?.allergens);
    }
  }, [menu, activeItem]);

  if (!beutifyMenu) {
    return null;
  } else {
    return (
      <AppModal open={openAllergensChange} size="sm:max-w-lg">
        <div className="flex flex-col">
          <div className="flex w-full items-center justify-between mb-3">
            <h1>
              <FormattedMessage id="allergens" />
            </h1>
            <XMarkIcon
              onClick={() => {
                if (
                  JSON.stringify(allergens) === JSON.stringify(beutifyMenu[id].allergens)
                ) {
                  setOpenAllergensChange(false);
                  setDiscardClick(0);
                } else {
                  if (discardClick === 0) {
                    setDiscardClick(1);
                  } else if (discardClick === 1) {
                    setDiscardClick(0);
                    setAllergens(beutifyMenu[id].allergens);
                    setOpenAllergensChange(false);
                  }
                }
              }}
              className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
            />
          </div>

          <div className="flex items-center space-x-3 rtl:space-x-reverse overflow-x-auto mt-3 scrollbar pb-2">
            {dataAllergensIcons.map((item, index) => {
              let allergen;
              if (beutifyMenu[id]?.allergens[item.name.toLocaleLowerCase()] === 1) {
                allergen = item.name.toLocaleLowerCase();
              } else if (
                beutifyMenu[id]?.allergens[item.name.toLocaleLowerCase()] === 2
              ) {
                allergen = 'no' + item.name.toLocaleLowerCase();
              } else {
                allergen = '';
              }
              return (
                <AllergensChooseCover
                  key={'allergens-choose-' + index}
                  name={item?.name}
                  iconNoAllergen={item?.iconNoAllergen}
                  iconNoVis={item?.iconNoVis}
                  iconVis={item?.iconVis}
                  allergen={allergen}
                  allergens={allergens}
                  setAllergens={setAllergens}
                  isMobile={isMobile}
                />
              );
            })}
          </div>
          <div className="flex w-full justify-end mt-3">
            <div className="flex items-center rtl:space-x-reverse space-x-3">
              {JSON.stringify(allergens) ===
              JSON.stringify(beutifyMenu[id]?.allergens) ? (
                <button
                  onClick={() => {
                    setOpenAllergensChange(false);
                    setDiscardClick(0);
                  }}
                  type="button"
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  <FormattedMessage id="cancel" />
                </button>
              ) : (
                <div className="flex items-center">
                  <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
                    {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
                  </div>
                  <div className="flex items-center justify-end w-full sm:w-auto">
                    <button
                      onClick={() => {
                        if (discardClick === 0) {
                          setDiscardClick(1);
                        } else if (discardClick === 1) {
                          setDiscardClick(0);
                          setAllergens(beutifyMenu[id].allergens);
                          setOpenAllergensChange(false);
                        }
                      }}
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                    >
                      <FormattedMessage id="discardChanges" />
                    </button>
                    <button
                      onClick={() => {
                        setDiscardClick(0);
                        dispatch(
                          editItemField({
                            id: id,
                            value: allergens,
                            field: 'allergens',
                            setOpen: setOpenAllergensChange,
                            globalCategoryId,
                          }),
                        );
                      }}
                      type="button"
                      className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                    >
                      {loadingImages ? (
                        <Spinner color="bg-white" />
                      ) : (
                        <FormattedMessage id="save" />
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </AppModal>
    );
  }
};
