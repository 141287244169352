import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { FormattedMessage } from 'react-intl';

export const NoneDesktopVersion = () => {
  return (
    <div
      style={{
        height: 'calc(100vh - 64px - 36px - 44px - 56px)',
      }}
      className="flex w-full flex-wrap ipad:flex-nowrap items-center justify-center p-4 bg-white rounded-lg space-x-10"
    >
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              <FormattedMessage id="attentionNeeded" />
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                <FormattedMessage id="noMobileVersion" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
