import { HomeIcon } from '@heroicons/react/20/solid';
import { Crumbs } from 'commonTypes';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface BreadCrumbsProps {
  crumbs: Crumbs[];
}

export const BreadCrumbs = ({ crumbs }: BreadCrumbsProps) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <div className="text-gray-400">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </div>
          </div>
        </li>
        {crumbs.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <Link
                to={page.href}
                className={`rtl:mr-4 ltr:ml-4 text-sm font-medium text-gray-500 md:hover:text-gray-700`}
                aria-current={page.current ? 'page' : undefined}
              >
                <FormattedMessage id={page.name.toLowerCase()} />
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
