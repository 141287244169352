import { FormattedMessage } from 'react-intl';

import {
  CurrencyField,
  DefaultItemPhotoField,
  DeliveryServicesField,
  LogoField,
  MainInfoField,
  MainLanguageField,
  MediaLinksField,
  RestaurantNameField,
  ReviewsFields,
  TranslateLanguagesField,
} from '../Fields';

export const AccountTab = () => {
  return (
    <div className="w-full flex flex-col">
      <h1 className="font-medium text-xl text-gray-600">
        <FormattedMessage id="account" />
      </h1>
      <dl className="divide-y divide-gray-200">
        <RestaurantNameField />

        <CurrencyField />

        <MainLanguageField />

        <TranslateLanguagesField />

        <ReviewsFields />

        <MediaLinksField />

        <MainInfoField />

        <DeliveryServicesField />

        <LogoField />

        <DefaultItemPhotoField />
      </dl>
    </div>
  );
};
