import { colorsSections, colorTypes } from 'appConstants';
import { Crumbs, ITheme } from 'commonTypes';
import { Spinner } from 'components/Common/Spinner';
import {
  ActiveThemeSelect,
  ColorPlate,
  ColorSection,
  ColorsTypeButton,
  DeleteThemeButton,
  IframeSection,
  NoneDesktopVersion,
  PickColorModal,
  PreviewButton,
  ThemeTypeTabs,
} from 'components/CustomizationComponents';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Layout } from 'pages/Layout/Layout';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-tooltip';
import { getQRCode } from 'store/qrcode/action';
import { getThemesByAccount } from 'store/themes/actions';
import { ApiThemeState } from 'store/themes/types';

export const Customization = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.account);
  const themesList = useAppSelector((state) => state.themes.themeList);
  const themesLoading = useAppSelector((state) => state.themes.loading);
  const loadingAccount = useAppSelector((state) => state.account.loading);
  const QRCode = useAppSelector((state) => state.qr.data);

  const [activeTheme, setActiveTheme] = useState<ApiThemeState | undefined>();
  const [activeChooseTheme, setActiveChooseTheme] = useState<ApiThemeState | undefined>();
  const [themeType, setThemeType] = useState<string>('');
  const [colorTypeChoosen, setColorTypeChoosen] = useState(1);
  const [size, setSize] = useState(document.body.clientWidth);
  const [pickColorOpen, setPickColorOpen] = useState(false);

  const crumbs: Crumbs[] = [
    { name: 'Customization', href: '/customization', current: true },
  ];

  const updateSize = () => {
    setSize(document.body.clientWidth);
  };

  useEffect(() => {
    if (activeChooseTheme?.colors) {
      sendMessageToIframe(activeChooseTheme?.colors);
    }
  }, [activeChooseTheme]);

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    if (!QRCode) {
      dispatch(getQRCode());
    }
    if (themesList.length === 0) {
      dispatch(getThemesByAccount());
    }

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  const sendMessageToIframe = (message: ITheme | string): void => {
    const iframe = document.getElementById('menu-preview-frame') as HTMLIFrameElement;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(message, '*');
    }
  };

  useEffect(() => {
    setActiveChooseTheme(themesList.find((theme) => theme.id === themeType));
  }, [themeType]);

  const actionFrame = (actions: string[]) => {
    actions.forEach((action) => sendMessageToIframe(action));
  };

  if (size < 640) {
    return (
      <Layout crumbs={crumbs}>
        <NoneDesktopVersion />
      </Layout>
    );
  }

  if (size >= 640 && size < 840) {
    return (
      <Layout crumbs={crumbs}>
        {loadingAccount || !account || !QRCode || themesLoading ? (
          <div className="flex justify-center items-start h-screen">
            <Spinner color="bg-primary" />
          </div>
        ) : (
          <>
            <PickColorModal
              open={pickColorOpen}
              setOpen={setPickColorOpen}
              setTheme={setActiveChooseTheme}
              theme={activeChooseTheme}
            />
            <div className="flex w-full flex-col space-y-3 relative">
              <div className="absolute -top-10 right-0">
                <PreviewButton activeChooseTheme={activeChooseTheme} />
              </div>
              <div className="w-full flex items-stretch space-x-3">
                <div className="w-[36%] max-w-[425px] min-w-[330px] min-h-full max-h-full flex items-center justify-center p-2 scrollbar overflow-auto bg-white">
                  <IframeSection
                    className="shadow"
                    onLoad={() => {
                      setTimeout(() => {
                        if (activeChooseTheme?.colors) {
                          sendMessageToIframe(activeChooseTheme?.colors);
                        }
                      }, 500);
                    }}
                  />
                </div>

                <div className="flex flex-col space-y-3 flex-1">
                  <div className="w-full bg-white p-4 flex items-center">
                    <div className="w-full">
                      <div className="text-gray-700 text-lg mb-1">
                        <FormattedMessage id="currentTheme" />
                      </div>
                      <ActiveThemeSelect
                        activeTheme={activeTheme}
                        setActiveTheme={setActiveTheme}
                        changeActive={true}
                        setActiveChooseTheme={setActiveChooseTheme}
                        activeChooseTheme={activeChooseTheme}
                        testId="cypress-active-theme-list-btn-laptop"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: 'calc(100vw - 407px)',
                    }}
                    className="bg-white p-4 relative"
                  >
                    <div className="flex items-center w-full justify-between">
                      <ThemeTypeTabs themeType={themeType} setThemeType={setThemeType} />

                      {activeChooseTheme?.type !== 2 &&
                        activeChooseTheme?.status === 2 && (
                          <div className="flex items-center space-x-3 ml-3">
                            <DeleteThemeButton
                              id={themeType}
                              activeChooseTheme={activeChooseTheme}
                              setThemeType={setThemeType}
                            />
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="flex flex-col bg-white w-full p-4 space-y-3 flex-1">
                    {colorTypes.map((colorType) => (
                      <ColorsTypeButton
                        key={'color-type-' + colorType.id}
                        colorType={colorType}
                        colorTypeChoosen={colorTypeChoosen}
                        onClick={() => {
                          actionFrame(colorType.action);
                          setColorTypeChoosen(colorType.id);
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="p-4 flex flex-col overflow-auto scrollbar space-y-4 bg-white flex-1">
                {colorsSections[colorTypeChoosen].map((colorName) => {
                  const colors = activeChooseTheme?.colors;
                  if (colors) {
                    return (
                      <ColorSection
                        key={'color-section-' + colorName}
                        setPickColorOpen={setPickColorOpen}
                        colorValue={
                          colors[
                            colorName as Exclude<keyof ITheme, 'colors' | 'fontSize'>
                          ]
                        }
                        colorName={colorName}
                        setTheme={setActiveChooseTheme}
                        activeChooseTheme={activeChooseTheme}
                      />
                    );
                  } else return null;
                })}
              </div>
            </div>
          </>
        )}
      </Layout>
    );
  }

  return (
    <Layout crumbs={crumbs}>
      {loadingAccount || !account || !QRCode || themesLoading ? (
        <div className="flex justify-center items-start h-screen">
          <Spinner color="bg-primary" />
        </div>
      ) : (
        <>
          <PickColorModal
            open={pickColorOpen}
            setOpen={setPickColorOpen}
            setTheme={setActiveChooseTheme}
            theme={activeChooseTheme}
          />
          <div className="flex w-full flex-wrap ipad:flex-nowrap items-stretch p-4 rounded-lg space-x-5 relative">
            <div
              style={{
                height: 'calc(100vh - 64px - 36px - 44px - 56px - 32px)',
                minHeight: '702px',
              }}
              className="flex flex-1 flex-col max-w-[74%] h-full space-y-5 overflow-auto scrollbar"
            >
              <div className="w-full lg:hidden bg-white p-4 flex items-center">
                <div className="w-full">
                  <div className="text-gray-700 text-lg mb-1">
                    <FormattedMessage id="currentTheme" />
                  </div>
                  <ActiveThemeSelect
                    activeTheme={activeTheme}
                    setActiveTheme={setActiveTheme}
                    changeActive={true}
                    setActiveChooseTheme={setActiveChooseTheme}
                    activeChooseTheme={activeChooseTheme}
                    testId="cypress-active-theme-list-btn-laptop"
                  />
                </div>
              </div>

              <div className="w-full flex items-stretch justify-between lg:!mt-0">
                <div className="lg:w-[25%] hidden bg-white p-4 lg:flex items-center">
                  <div className="w-full">
                    <div className="text-gray-700 text-lg mb-1">
                      <FormattedMessage id="currentTheme" />
                    </div>
                    <ActiveThemeSelect
                      activeTheme={activeTheme}
                      setActiveTheme={setActiveTheme}
                      changeActive={true}
                      setActiveChooseTheme={setActiveChooseTheme}
                      activeChooseTheme={activeChooseTheme}
                      testId="cypress-active-theme-list-btn-desktop"
                    />
                  </div>
                </div>
                <div className="bg-white p-4 lg:w-[73%] w-full">
                  <div className="flex items-center w-full justify-between">
                    <ThemeTypeTabs themeType={themeType} setThemeType={setThemeType} />

                    <div className="flex items-center space-x-3 ml-3">
                      <PreviewButton activeChooseTheme={activeChooseTheme} />
                      {activeChooseTheme?.type !== 2 &&
                        activeChooseTheme?.status === 2 && (
                          <DeleteThemeButton
                            id={themeType}
                            activeChooseTheme={activeChooseTheme}
                            setThemeType={setThemeType}
                          />
                        )}
                    </div>
                  </div>
                  {activeChooseTheme?.type === 2 && (
                    <Tooltip
                      anchorSelect={'.add-color-btn'}
                      style={{
                        backgroundColor: 'rgb(255, 255, 255)',
                        color: '#000',
                        border: '1px solid #d1d5db',
                        opacity: 1,
                      }}
                      place="top"
                      id="cannot-edit-theme"
                      classNameArrow="border border-gray-300 border-t-0 border-l-0"
                    >
                      <FormattedMessage id="cannotEditTheme" />
                    </Tooltip>
                  )}
                  <div className="flex items-center space-x-3 mt-3 overflow-auto scrollbar">
                    <button
                      onClick={() => {
                        if (activeChooseTheme?.type !== 2) {
                          setPickColorOpen(true);
                        }
                      }}
                      disabled={activeChooseTheme?.type === 2}
                      data-testId="cypress-add-color-btn"
                      className="w-10 h-10 shrink-0 add-color-btn rounded-md border border-gray-300 text-gray-500 flex items-center justify-center text-3xl disabled:cursor-not-allowed disabled:opacity-80 enabled:cursor-pointer enabled:hover:opacity-80"
                    >
                      +
                    </button>
                    {activeChooseTheme?.colors.colors.map((color) => (
                      <ColorPlate key={'color-plate-' + color.id} color={color.value} />
                    ))}
                  </div>
                </div>
              </div>

              <div className="xl:hidden w-full bg-white flex space-x-4 p-4 overflow-auto scrollbar">
                {colorTypes.map((colorType) => (
                  <button
                    key={'color-type-' + colorType.id}
                    className={classNames(
                      colorType.id === colorTypeChoosen
                        ? 'bg-indigo-100 text-indigo-700'
                        : 'text-gray-500 hover:text-gray-700',
                      'rounded-md px-3 py-2 text-sm font-medium flex items-center whitespace-nowrap',
                    )}
                    onClick={() => {
                      actionFrame(colorType.action);
                      setColorTypeChoosen(colorType.id);
                    }}
                  >
                    <colorType.icon
                      className={classNames(
                        colorType.id === colorTypeChoosen
                          ? 'text-indigo-600'
                          : 'text-gray-400 group-hover:text-indigo-600',
                        'h-6 w-6 shrink-0 mr-2',
                      )}
                      aria-hidden="true"
                    />
                    <FormattedMessage id={colorType.title} />
                  </button>
                ))}
              </div>

              <div
                className="w-full flex flex-1 justify-between"
                style={{
                  height:
                    'calc(100vh - 64px - 36px - 44px - 56px - 128px - 72px - 40px - 32px - 100px - 20px)',
                }}
              >
                <div className="xl:flex flex-col bg-white w-[25%] p-4 space-y-3 hidden overflow-auto scrollbar">
                  {colorTypes.map((colorType) => (
                    <ColorsTypeButton
                      key={'color-type-' + colorType.id}
                      colorType={colorType}
                      colorTypeChoosen={colorTypeChoosen}
                      onClick={() => {
                        actionFrame(colorType.action);
                        setColorTypeChoosen(colorType.id);
                      }}
                    />
                  ))}
                </div>

                <div className="xl:w-[73%] w-full flex flex-col space-y-0">
                  <div className="p-4 flex flex-col overflow-auto scrollbar space-y-4 bg-white flex-1">
                    {colorsSections[colorTypeChoosen].map((colorName) => {
                      const colors = activeChooseTheme?.colors;
                      if (colors) {
                        return (
                          <ColorSection
                            key={'color-section-' + colorName}
                            setPickColorOpen={setPickColorOpen}
                            colorValue={
                              colors[
                                colorName as Exclude<keyof ITheme, 'colors' | 'fontSize'>
                              ]
                            }
                            colorName={colorName}
                            setTheme={setActiveChooseTheme}
                            activeChooseTheme={activeChooseTheme}
                          />
                        );
                      } else return null;
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[26%] min-w-[330px] min-h-full max-h-full flex items-center justify-center overflow-auto">
              <IframeSection
                className="shadow"
                onLoad={() => {
                  setTimeout(() => {
                    if (activeChooseTheme?.colors) {
                      sendMessageToIframe(activeChooseTheme?.colors);
                    }
                  }, 500);
                }}
              />
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};
