import { Loading } from '@Common/Loading';
import { Switch } from '@headlessui/react';
import { IOptions, SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppDispatch } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { editSwithItemField } from 'store/item/actions';
import { editOption } from 'store/options/actions';

import { AddDefaultSelection } from '../Modals';

interface ToggleProps {
  mode: 'multi' | 'popular' | 'chefAdvice' | 'new';
  id: string;
  active?: IOptions;
  className?: string;
  defaultCheck?: boolean;
  setIsMulti?: SetStateType<boolean>;
  hasDefault?: boolean;
}

export const Toggle = ({
  mode,
  id,
  active,
  className = '',
  defaultCheck = false,
  setIsMulti,
  hasDefault,
}: ToggleProps) => {
  const dispatch = useAppDispatch();
  const [enabled, setEnabled] = useState(defaultCheck);
  const [openDefaultSelectionModal, setOpendefaultSelectionModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEnabled(defaultCheck);
  }, [defaultCheck]);

  return (
    <Switch.Group as="div" className={className}>
      <div className={'flex items-center '}>
        <Switch.Label
          htmlFor={'toggle-' + mode}
          className="flex items-center cursor-pointer ltr:mr-2 rtl:ml-2 text-gray-500 select-none"
        >
          <FormattedMessage id={mode} />
        </Switch.Label>
        <Switch
          checked={enabled}
          onChange={(v: boolean) => {
            if (mode === 'multi') {
              if (!hasDefault && mode === 'multi' && v === false) {
                setOpendefaultSelectionModal(true);
              } else {
                if (active?.free_option_data?.free_options && !v) {
                  dispatch(
                    editOption({
                      data: {
                        [mode === 'multi' ? 'multi_select' : mode]: v,
                        free_option_data: {
                          free_options: false,
                          amount: active?.free_option_data?.amount || 2,
                        },
                      },
                      id,
                      setIsMulti,
                    }),
                  );
                  setEnabled(v);
                } else {
                  dispatch(
                    editOption({
                      data: {
                        [mode === 'multi' ? 'multi_select' : mode]: v,
                      },
                      id,
                      setIsMulti,
                    }),
                  );
                  setEnabled(v);
                }
              }
            } else if (mode === 'popular' || mode === 'chefAdvice' || mode === 'new') {
              dispatch(
                editSwithItemField({
                  setEnabled,
                  data: {
                    [mode === 'chefAdvice' ? 'chef_advice' : mode]: v,
                  },
                  id,
                  setLoading,
                }),
              );
            }
          }}
          id={'toggle-' + mode}
          className={classNames(
            enabled ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? 'ltr:translate-x-5 rtl:-translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          >
            {loading && <Loading />}
          </span>
        </Switch>
      </div>

      <AddDefaultSelection
        open={openDefaultSelectionModal}
        setOpen={setOpendefaultSelectionModal}
        activeSelections={active?.selections || []}
        setIsMulti={setIsMulti}
        setEnabled={setEnabled}
        id={id}
      />
    </Switch.Group>
  );
};
