export const ColorPlate = ({ color }: { color: string }) => {
  return (
    <div
      className="w-10 h-10 shrink-0 rounded-md border border-gray-300"
      style={{
        background: color,
      }}
    />
  );
};
