import { LOCALES } from 'i18n/locale';

import { RESET_LOCALE, SET_LOCALE } from './actionsTypes';
import { LocaleAction, LocaleState } from './types';

const initState: LocaleState = {
  lang: LOCALES.ENGLISH,
};

export const localesReducer = (store: LocaleState = initState, action: LocaleAction) => {
  switch (action.type) {
    case SET_LOCALE:
      return {
        ...store,
        lang: action.payload,
      };
    case RESET_LOCALE:
      return initState;
    default:
      return store;
  }
};
