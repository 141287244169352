import { Listbox, Transition } from '@headlessui/react';
import {
  ArchiveBoxIcon,
  CheckIcon,
  ChevronUpDownIcon,
  ExclamationTriangleIcon,
  HeartIcon,
} from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { editItemField } from 'store/item/actions';
import { Items } from 'store/menu/types';

const statusData = [
  {
    id: 1,
    name: <FormattedMessage id="active" />,
    icon: (
      <HeartIcon
        className="h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
        aria-hidden="true"
      />
    ),
  },
  {
    id: 2,
    name: <FormattedMessage id="soldout" />,
    icon: (
      <ExclamationTriangleIcon
        className="h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
        aria-hidden="true"
      />
    ),
  },
  {
    id: 3,
    name: <FormattedMessage id="archived" />,
    icon: (
      <ArchiveBoxIcon
        className="h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
        aria-hidden="true"
      />
    ),
  },
];

interface ChangeStatusItemModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  itemId: string;
  item: Items;
  globalCategoryId: string;
  menuPage?: boolean;
}

export const ChangeStatusItemModal = ({
  open,
  setOpen,
  itemId,
  item,
  globalCategoryId,
  menuPage = false,
}: ChangeStatusItemModalProps) => {
  const dispatch = useAppDispatch();
  const [changeStatus, setChangeStatus] = useState(item?.status);
  const loading = useAppSelector((state) => state.item.subLoading);
  const [discardClick, setDiscardClick] = useState(0);

  useEffect(() => {
    if (item?.status) {
      setChangeStatus(item.status);
    }
  }, [item]);

  return (
    <AppModal size="sm:max-w-md" open={open}>
      <div className="flex flex-col">
        <div className="flex w-full items-center justify-between mb-3">
          <h1>
            <FormattedMessage id="changeStatus" />
          </h1>
          <XMarkIcon
            onClick={() => {
              if (changeStatus === item?.status) {
                setDiscardClick(0);
                setOpen(false);
              } else {
                if (discardClick === 0) {
                  setDiscardClick(1);
                } else if (discardClick === 1) {
                  setDiscardClick(0);
                  setOpen(false);
                  setChangeStatus(item?.status);
                }
              }
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <Listbox value={changeStatus} onChange={setChangeStatus}>
          {({ open }) => (
            <>
              <div className="relative mt-1">
                <Listbox.Button
                  as="div"
                  data-testId="cypress-list-status-btn"
                  className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm md:focus:border-indigo-500 md:focus:outline-none md:focus:ring-1 md:focus:ring-indigo-500 sm:text-sm"
                >
                  <span className="flex items-center">
                    <span>
                      {statusData.find((item) => +item.id === +changeStatus)?.icon}
                    </span>
                    <span className="ltr:ml-3 rtl:mr-3 block truncate">
                      {statusData.find((item) => +item.id === +changeStatus)?.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="scrollbar absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none sm:text-sm">
                    {statusData.map((item) => (
                      <Listbox.Option
                        key={'choose-category-' + item.id}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          )
                        }
                        data-testId={'cypress-item-status-' + item.id}
                        value={item.id}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center">
                              <span>{item.icon}</span>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'ltr:ml-3 rtl:mr-3 block truncate',
                                )}
                              >
                                {item.name}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>

        <div className="flex w-full justify-end mt-3">
          <div className="flex items-center space-x-3 rtl:space-x-reverse">
            {changeStatus === item?.status ? (
              <button
                onClick={() => {
                  setDiscardClick(0);
                  setOpen(false);
                }}
                type="button"
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="cancel" />
              </button>
            ) : (
              <div className="flex items-center">
                <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
                  {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
                </div>
                <div className="flex items-center justify-end w-full sm:w-auto">
                  <button
                    onClick={() => {
                      if (discardClick === 0) {
                        setDiscardClick(1);
                      } else if (discardClick === 1) {
                        setDiscardClick(0);
                        setOpen(false);
                        setChangeStatus(item?.status);
                      }
                    }}
                    type="button"
                    className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                  >
                    <FormattedMessage id="discardChanges" />
                  </button>
                  <button
                    onClick={() => {
                      setDiscardClick(0);
                      dispatch(
                        editItemField({
                          id: itemId,
                          value: changeStatus,
                          field: 'status',
                          setOpen: setOpen,
                          globalCategoryId,
                          menuPage,
                        }),
                      );
                    }}
                    type="button"
                    data-testId="cypress-save-status-item"
                    className="ltr:ml-2 rtl:mr-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                  >
                    {loading ? (
                      <Spinner color="bg-white" />
                    ) : (
                      <FormattedMessage id="save" />
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppModal>
  );
};
