import { XMarkIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { getOptionsApi, updateItemOptionsRelations } from '../../store/options/actions';
import { OptionItem } from '../OptionsComponents/RootPageModule/OptionItem';
import { AppModal } from './AppModal';
import { Spinner } from './Spinner';

interface ChangeOptionsModalProps {
  open: boolean;
  itemId?: string;
  setOpen: SetStateType<boolean>;
  itemOptions?: string[];
  addToItem?: boolean;
  addOptions?: string[];
  setAddOptions?: SetStateType<string[]>;
  localChoose: string[];
  setLocalChoose: SetStateType<string[]>;
}

export const ChangeOptionsModal = ({
  open,
  itemId,
  setOpen,
  itemOptions = [],
  addToItem = true,
  addOptions,
  setAddOptions,
  localChoose,
  setLocalChoose,
}: ChangeOptionsModalProps) => {
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.options.optionsApi) || [];
  const loading = useAppSelector((state) => state.options.subLoading);

  useEffect(() => {
    if (!options) {
      dispatch(getOptionsApi());
    }
  }, []);

  return (
    <AppModal open={open} size="sm:max-w-lg">
      <div className="flex w-full items-center justify-between mb-3">
        <h1>
          <FormattedMessage id="changeOptions" />
        </h1>
        <XMarkIcon
          onClick={() => {
            if (!loading) {
              setOpen(false);
              setLocalChoose(itemOptions);
            }
          }}
          className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
        />
      </div>

      {options?.length > 0 ? (
        <ul className="w-full space-y-3 mb-3">
          {options?.map((option) => {
            return (
              <OptionItem
                option={option}
                key={'option-in-item-' + option?.id}
                name={option?.name}
                localChoose={localChoose}
                setLocalChoose={setLocalChoose}
                id={option?.id}
                itemPage
              />
            );
          })}
        </ul>
      ) : (
        <div className="mb-5">You don&apos;t have any options yet</div>
      )}

      <div className="w-full flex items-center justify-end">
        <button
          type="button"
          disabled={loading}
          onClick={() => {
            setOpen(false);
            setLocalChoose(itemOptions);
          }}
          className="ltr:mr-3 rtl:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <FormattedMessage id="discardChanges" />
        </button>
        <button
          type="button"
          onClick={() => {
            if (addToItem) {
              dispatch(
                updateItemOptionsRelations({
                  entityId: itemId,
                  data: localChoose,
                  setOpen,
                }),
              );
            } else if (addOptions && setAddOptions) {
              setAddOptions(localChoose);
              setOpen(false);
            }
          }}
          data-testId="cypress-add-option-save"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {loading ? <Spinner color="bg-white" /> : <FormattedMessage id="save" />}
        </button>
      </div>
    </AppModal>
  );
};
