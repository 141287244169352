import { TrashIcon } from '@heroicons/react/24/outline';
import { oldLanguages } from 'appConstants';
import { ISelections, SetStateType } from 'commonTypes';
import { Loading } from 'components/Common/Loading';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { editDefaultSelection } from 'store/options/actions';

import { AddDefaultSelection, OptionDeleteModal, OptionSelectionModal } from '../Modals';
import { ChangeToppingsModal } from '../Modals/ChangeToppingsModal';
import { StatusSwitch } from './StatusSwitch';

interface OptionSelectionProps {
  defaultValue?: boolean;
  selection: ISelections;
  isMulti: boolean;
  id: string;
  selectionId: string;
  selectionsLength: number;
  activeSelections: ISelections[];
  mini?: boolean;
  setWasClicked?: SetStateType<boolean>;
  disableActive?: boolean;
}

export const OptionSelection = ({
  defaultValue = false,
  selection,
  isMulti,
  id,
  selectionId,
  selectionsLength,
  activeSelections,
  mini = false,
  setWasClicked,
  disableActive = false,
}: OptionSelectionProps) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.account);
  const intCurrency = useAppSelector((state) => state.account.account?.intCurrency);
  const structOptions = useAppSelector((state) => state.options.structOptionsApi);
  const loading = useAppSelector((state) => state.options.subLoading);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openToppingsModal, setOpenToppingsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [addDefaultValue, setAddDefaultValue] = useState(false);
  const locale = useAppSelector((state) => state.locale.lang);

  return (
    <tr>
      <td className="whitespace-nowrap py-4 ltr:pl-4 rtl:pr-4 ltr:pr-3 rtl:pl-3 text-sm font-medium text-gray-900 rtl:sm:pr-6 ltr:sm:pl-6">
        {selection?.name?.translations
          ? selection?.name?.translations[oldLanguages[locale]]
          : ''}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
        <FormattedNumber
          style="currency"
          currency={account?.currency}
          value={selection?.price}
          maximumFractionDigits={intCurrency ? 0 : 2}
        />
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
        <StatusSwitch
          disable={disableActive}
          defaultValue={selection?.status}
          id={selection?.id}
          selection={selection}
          optionId={id}
          activeSelections={activeSelections}
          setOpenToppingsModal={setOpenToppingsModal}
        />
      </td>
      <td
        className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center ${
          isMulti ? 'opacity-50' : ''
        }`}
      >
        <div className="w-full flex items-center justify-center">
          {loading ? (
            <Loading />
          ) : (
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                if (isMulti || selection?.status !== 1 || loading) return false;
                const newSelections = [...activeSelections];
                const newSelectionsObj: any = {};
                newSelections.forEach((item) => {
                  const newValue = item.id === selectionId ? true : false;
                  newSelectionsObj[item.id] = newValue;
                });
                dispatch(
                  editDefaultSelection({
                    data: newSelectionsObj,
                    optionId: id,
                    selectionId: selectionId,
                    setWasClicked: setWasClicked,
                  }),
                );
              }}
              className={`w-4 focus:ring-indigo-500 h-4 rounded-full cursor-pointer flex items-center justify-center ${
                defaultValue ? 'bg-primary' : 'border border-gray-300'
              }`}
            >
              <div className="w-1.5 h-1.5 rounded-full bg-white" />
            </div>
          )}
        </div>
      </td>
      {!mini && (
        <td className="relative whitespace-nowrap py-4 ltr:pl-3 rtl:pr-3 ltr:pr-4 rtl:pl-4 flex items-center justify-end ltr:text-right rtl:text-left text-sm font-medium rtl:sm:pl-6 ltr:sm:pr-6">
          <button
            className="text-indigo-600 hover:text-indigo-900 ltr:mr-3 rtl:ml-3"
            onClick={() => {
              setOpenEditModal(true);
            }}
          >
            <FormattedMessage id="edit" />
          </button>
          <TrashIcon
            className={`w-5 h-5 text-red-500 cursor-pointer md:hover:opacity-60 ${
              selectionsLength <= 1 ? 'hidden' : 'block'
            }`}
            onClick={() => {
              const sortedSelections = activeSelections.filter(
                (selection) => selection.status === 1,
              );
              if (
                // @ts-ignore
                !!structOptions[id]?.free_option_data?.free_options &&
                sortedSelections.length - 1 <
                  // @ts-ignore
                  (structOptions[id]?.free_option_data?.amount || 2)
              ) {
                setOpenToppingsModal(true);
                return;
              }
              // @ts-ignore
              if (selection.default_value && !structOptions[id]?.multi_select) {
                setAddDefaultValue(true);
              } else {
                setOpenDeleteModal(true);
              }
            }}
          />
        </td>
      )}

      <AddDefaultSelection
        setOpen={setAddDefaultValue}
        open={addDefaultValue}
        activeSelections={activeSelections}
        id={id}
        selectionId={selection?.id}
        deleteMode={true}
      />

      <OptionDeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        mode="selection"
        id={id}
        activeSelection={selection}
      />
      <OptionSelectionModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        active={selection}
      />
      <ChangeToppingsModal
        // @ts-ignore
        active={structOptions[id]}
        open={openToppingsModal}
        setOpen={setOpenToppingsModal}
        id={id}
      />
    </tr>
  );
};
