import { Languages, SelectedLanguage, SetStateType, ZoneInfo } from 'commonTypes';

import {
  RegisterCountrySelect,
  RegisterCurrencySelect,
  RegisterMainLanguageSelect,
  RegisterPhoneInput,
  RegisterSelectInput,
  RegisterTextInput,
  RegisterTranslationSelect,
} from '../FormElement';

interface RegisterSecondStepProps {
  firstName: string;
  setFirstName: SetStateType<string>;

  lastName: string;
  setLastName: SetStateType<string>;

  restaurantName: string;
  setRestaurantName: SetStateType<string>;

  phoneCode:
    | {
        code: string;
        name: string;
      }
    | undefined;
  setPhoneCode: SetStateType<
    | {
        code: string;
        name: string;
      }
    | undefined
  >;
  phoneNumber: string;
  setPhoneNumber: SetStateType<string>;

  country: ZoneInfo | undefined;
  setCountry: SetStateType<ZoneInfo | undefined>;
  setLastCountry: SetStateType<ZoneInfo | null | undefined>;

  currency: string;
  setCurrency: SetStateType<string>;

  mainLanguage: Languages | undefined;
  setMainLanguage: SetStateType<Languages | undefined>;
  setLastMainLanguage: SetStateType<Languages | null>;

  setTransactionLanguages: SetStateType<SelectedLanguage[]>;
  transactionLanguages: SelectedLanguage[];

  link: string;
  setLink: SetStateType<string>;
  avalibleLink: boolean | null;
  setAvalibleLink: SetStateType<boolean | null>;
}

export const RegisterSecondStep = ({
  firstName,
  setFirstName,

  lastName,
  setLastName,

  restaurantName,
  setRestaurantName,

  phoneCode,
  setPhoneCode,
  phoneNumber,
  setPhoneNumber,

  country,
  setCountry,
  setLastCountry,

  currency,
  setCurrency,

  mainLanguage,
  setMainLanguage,
  setLastMainLanguage,

  setTransactionLanguages,
  transactionLanguages,

  link,
  setLink,
  avalibleLink,
  setAvalibleLink,
}: RegisterSecondStepProps) => {
  return (
    <>
      <div className="flex items-center justify-between w-full sm:space-x-8 flex-wrap sm:flex-nowrap space-y-6 sm:space-y-0">
        <RegisterTextInput
          label={'First name'}
          value={firstName}
          setValue={setFirstName}
          id={'register-first-name'}
          name={'register-first-name'}
          required={true}
          autoComplete={'first-name'}
          placeholder={'First name'}
          className="w-full sm:w-1/2"
        />

        <RegisterTextInput
          label={'Last name'}
          value={lastName}
          setValue={setLastName}
          id={'register-last-name'}
          name={'register-last-name'}
          required={true}
          autoComplete={'family-name'}
          placeholder={'Last name'}
          className="w-full sm:w-1/2"
        />
      </div>

      <div>
        <RegisterTextInput
          label={'Restaurant name'}
          value={restaurantName}
          setValue={setRestaurantName}
          id={'register-restaurant-name'}
          name={'register-restaurant-name'}
          required={true}
          placeholder={'Restaurant name'}
          className="w-full"
        />
      </div>

      <div className="flex items-start justify-between w-full sm:space-x-8 flex-wrap sm:flex-nowrap space-y-6 sm:space-y-0">
        <RegisterPhoneInput
          phoneCode={phoneCode}
          setPhoneCode={setPhoneCode}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          link={link}
          setLink={setLink}
          avalibleLink={avalibleLink}
          setAvalibleLink={setAvalibleLink}
        />
      </div>

      <div className="flex items-center justify-between w-full sm:space-x-8 flex-wrap sm:flex-nowrap space-y-6 sm:space-y-0">
        <RegisterSelectInput
          label={'Country'}
          child={
            <RegisterCountrySelect
              country={country}
              setCountry={setCountry}
              setLastCountry={setLastCountry}
            />
          }
        />

        <RegisterSelectInput
          label={'Currency'}
          child={<RegisterCurrencySelect currency={currency} setCurrency={setCurrency} />}
        />

        <RegisterSelectInput
          label={'Main language'}
          child={
            <RegisterMainLanguageSelect
              mainLanguage={mainLanguage}
              setLastMainLanguage={setLastMainLanguage}
              setMainLanguage={setMainLanguage}
            />
          }
        />
      </div>

      <div>
        <RegisterTranslationSelect
          setTransactionLanguages={setTransactionLanguages}
          transactionLanguages={transactionLanguages}
        />
      </div>
    </>
  );
};
