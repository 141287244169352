import { useState } from 'react';

import { AddGlobalMenuModal } from '../Modals';

export const AddGlobalMenusButton = () => {
  const [openAddGlobalMenu, setOpenAddGlobalMenu] = useState(false);
  return (
    <>
      <button
        type="button"
        onClick={() => {
          setOpenAddGlobalMenu(true);
        }}
        data-testId="cypress-add-global-menu-btn"
        className="h-10 px-8 flex items-center justify-center border border-primary bg-white rounded-lg text-primary text-2xl  transition-all md:hover:bg-primary md:hover:text-white md:hover:text-700"
      >
        +
        <AddGlobalMenuModal open={openAddGlobalMenu} setOpen={setOpenAddGlobalMenu} />
      </button>
    </>
  );
};
