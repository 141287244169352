//eslint-ignore

export const Gluten = ({ width = 32, height = 32, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 231 232"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_417_152)">
      <path
        d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
        fill="#DD455B"
      />
      <path
        d="M229.095 129.483L135.325 35.7126L43.6046 127.433L50.8755 172.573L36.1627 187.286L42.7143 193.837L69.5312 220.654C83.4779 226.664 98.8488 230 115 230C173.607 230 221.973 186.159 229.095 129.483Z"
        fill="#B2004F"
      />
      <path
        d="M158.254 71.7456L154.979 68.4698L36.1627 187.286L39.4385 190.562L42.7143 193.837L161.53 75.0214L158.254 71.7456Z"
        fill="#EAAD44"
      />
      <path
        d="M39.4227 190.549L158.221 71.7512L161.496 75.0263L42.6978 193.825L39.4227 190.549Z"
        fill="#E58E40"
      />
      <path
        d="M92.7405 137.26L63.259 107.778L43.6046 127.433C36.0745 134.963 35.0442 146.198 40.5273 155.327L53.3326 176.667L74.6726 189.473C83.8024 194.956 95.0369 193.926 102.567 186.395L122.222 166.741L92.7405 137.26Z"
        fill="#F2D049"
      />
      <path
        d="M102.567 186.395L122.222 166.741L92.7405 137.26L53.3326 176.667L74.6727 189.473C83.8025 194.956 95.0374 193.926 102.567 186.395Z"
        fill="#EAAD44"
      />
      <path
        d="M112.395 117.606L82.9131 88.1242L63.2587 107.779C55.7285 115.309 54.6983 126.544 60.1814 135.673L72.9866 157.013L94.3267 169.819C103.456 175.302 114.691 174.272 122.221 166.741L141.876 147.087L112.395 117.606Z"
        fill="#F8E997"
      />
      <path
        d="M122.222 166.741L141.876 147.087L112.395 117.605L72.9871 157.013L94.3271 169.818C103.457 175.301 114.691 174.272 122.222 166.741Z"
        fill="#F2D049"
      />
      <path
        d="M132.049 97.9512L102.567 68.4698L82.913 88.1242C75.3828 95.6544 74.3526 106.889 79.8356 116.019L92.6409 137.359L113.981 150.164C123.111 155.647 134.345 154.617 141.876 147.087L161.53 127.433L132.049 97.9512Z"
        fill="#F2D049"
      />
      <path
        d="M141.876 147.087L161.53 127.433L132.049 97.9512L92.641 137.359L113.981 150.164C123.111 155.647 134.346 154.617 141.876 147.087Z"
        fill="#EAAD44"
      />
      <path
        d="M187.736 88.1242L177.909 97.9512C171.7 104.16 162.103 105.344 154.573 100.821L150.117 98.1492C145.421 95.3276 140.056 94.3391 134.931 95.0688C135.66 89.9439 134.672 84.5792 131.854 79.8865L129.18 75.4206C124.656 67.8968 125.84 58.2998 132.049 52.0907L141.876 42.2642L135.324 35.7126L102.567 68.4698C95.0369 75.9999 94.0066 87.2348 99.4897 96.3646L112.295 117.705L133.635 130.51C142.765 135.993 153.999 134.963 161.53 127.433L194.287 94.6754L187.736 88.1242Z"
        fill="#F8E997"
      />
      <path
        d="M161.53 127.433L194.287 94.6754L187.736 88.1242L177.909 97.9512C171.7 104.16 162.103 105.344 154.573 100.821L150.117 98.1492C145.421 95.3276 140.056 94.3392 134.931 95.0688L112.295 117.705L133.635 130.51C142.765 135.993 154 134.963 161.53 127.433Z"
        fill="#F2D049"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_152">
        <rect width="230" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
