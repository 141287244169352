import { defaultToastProps, phoneCodes, selectLanguages, zoneInfo } from 'appConstants';
import { ReactComponent as Logo } from 'assets/logos/logo-light.svg';
import { ReactComponent as LoginImg } from 'assets/static/login.svg';
import { Languages, ZoneInfo } from 'commonTypes';
import { Spinner } from 'components/Common/Spinner';
import {
  RegisterFirstStep,
  RegisterHeader,
  RegisterInformationBanner,
  RegisterSecondStep,
  RegisterSteper,
  RegisterThirdStep,
} from 'components/RegisterComponent';
import {
  checkPassword,
  compareCountryCodeWithLanguage,
  compareMainLangWithTranslatedLanguage,
} from 'helpers/auth';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { newRegister, setExistingUser } from 'store/auth/actions';
import { SET_ERROR } from 'store/auth/actionsTypes';

const steps = [
  { name: 'Email & Password', status: 'current' },
  { name: 'Main Information', status: 'upcoming' },
  { name: 'Upload Image', status: 'upcoming' },
];

export const PrivateRegister = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const loading = useAppSelector((state) => state.auth.loading);
  const error = useAppSelector((state) => state.auth.error);
  const registerUser = useAppSelector((state) => state.auth.user);

  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [restaurantName, setRestaurantName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneCode, setPhoneCode] = useState(
    phoneCodes.find((phone) => phone.code === '+36'),
  );
  const [country, setCountry] = useState(
    zoneInfo.find((country) => country.country === 'Hungary'),
  );
  const [lastCountry, setLastCountry] = useState<ZoneInfo | undefined | null>(null);
  const [currency, setCurrency] = useState('HUF');
  const [mainLanguage, setMainLanguage] = useState<Languages | undefined>(
    selectLanguages.find(
      (item) =>
        item.value === zoneInfo.find((country) => country.country === 'Hungary')?.lang,
    )?.value,
  );
  const [lastMainLanguage, setLastMainLanguage] = useState<Languages | null>(null);
  const [transactionLanguages, setTransactionLanguages] = useState([selectLanguages[0]]);

  const [registerStep, setRegisterStep] = useState(1);

  const [confirmPrivacy, setConfirmPrivacy] = useState(false);

  const [stepData, setStepData] = useState(steps);

  const [avaliblePassword, setAvaliblePassword] = useState({
    length: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    special: false,
  });

  const [link, setLink] = useState('');
  const [avalibleLink, setAvalibleLink] = useState<boolean | null>(null);

  const resetFields = () => {
    localStorage.removeItem('user');
    setEmail('');
    setPassword('');
    setOldPassword('');
    setFirstName('');
    setRestaurantName('');
    setLastName('');
    setPhoneNumber('');
    setCountry(zoneInfo.find((country) => country.country === 'Hungary'));
    setLastCountry(null);
    setPhoneCode(phoneCodes.find((phone) => phone.code === '+36'));
    setCurrency('HUF');
    setMainLanguage(
      selectLanguages.find(
        (item) =>
          item.value === zoneInfo.find((country) => country.country === 'Hungary')?.lang,
      )?.value,
    );
    setLastMainLanguage(null);
    setTransactionLanguages([selectLanguages[0]]);
  };

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem('user') + '');
    if (localUser) {
      dispatch(setExistingUser({ ...localUser, setRegisterStep }));
      setRegisterStep(localUser.activeStep);

      if (localUser.activeStep === 2) {
        setOldPassword(localUser.oldPassword);
        setEmail(localUser.email);
        setPassword(localUser.password);
      }
    }
    dispatch({
      type: SET_ERROR,
      payload: null,
    });
    return () => {
      resetFields();
      dispatch({
        type: SET_ERROR,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    compareCountryCodeWithLanguage({
      lastCountry,
      country,
      transactionLanguages,
      setTransactionLanguages,
    });
  }, [country]);

  useEffect(() => {
    compareMainLangWithTranslatedLanguage({
      lastMainLanguage,
      mainLanguage,
      transactionLanguages,
      setTransactionLanguages,
    });
  }, [mainLanguage]);

  useEffect(() => {
    if (error) {
      toast(error, {
        ...defaultToastProps,
        type: 'error',
        toastId: 'private-register-error',
      });
    }
  }, [error]);

  useEffect(() => {
    const newStepData = stepData.map((step, index) => {
      if (index === registerStep - 1) {
        return { ...step, status: 'current' };
      } else if (index < registerStep - 1) {
        return { ...step, status: 'complete' };
      } else {
        return { ...step, status: 'upcoming' };
      }
    });
    setStepData(newStepData);
  }, [registerStep]);

  useEffect(() => {
    checkPassword({
      password,
      avaliblePassword,
      setAvaliblePassword,
    });
  }, [password]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (registerStep === 1) {
      if (!confirmPrivacy) {
        toast('Please accept the Privacy & Cookie and Tems & Conditions', {
          ...defaultToastProps,
          type: 'warning',
        });
        return;
      } else {
        dispatch(
          newRegister({ email, oldPassword, setRegisterStep }, registerStep, history),
        );
      }
    } else if (registerStep === 2) {
      if (transactionLanguages.length === 0 || transactionLanguages.length === 1) {
        toast('Please select at least two transaction language', {
          ...defaultToastProps,
          type: 'error',
        });
        return;
      } else if (!link) {
        toast('Please enter your link', {
          ...defaultToastProps,
          type: 'error',
        });
        return;
      } else if (!avalibleLink) {
        toast('Link is not avalible', {
          ...defaultToastProps,
          type: 'error',
        });
        return;
      } else {
        dispatch(
          newRegister(
            {
              ...registerUser,
              firstName,
              lastName,
              restaurantName,
              phoneNumber,
              phoneCode,
              country: country?.label,
              currency,
              mainLanguage,
              transactionLanguages,
              link,
            },
            registerStep,
            history,
          ),
        );
      }
    } else if (registerStep === 3) {
      dispatch(
        newRegister(
          {
            ...registerUser,
          },
          registerStep,
          history,
        ),
      );
    }
  };

  return (
    <div className="min-h-screen flex">
      <div className="min-h-full lg:w-1/3 w-0 bg-primary flex items-center justify-center">
        {registerStep !== 4 && <LoginImg className="w-4/5" />}
        {registerStep === 4 && (
          <div>
            <div className="flex items-center mb-3">
              <div className="w-[55px] h-[55px] flex items-center justify-center p-1 rounded-full bg-white">
                <Logo className="w-[80%]" />
              </div>
              <div className="text-xl text-white font-semibold ml-2">DigitalMenu.ai</div>
            </div>

            <div className="text-base text-indigo-300 font-semibold">Try Basic</div>
            <div className="text-4xl font-bold text-white my-1">18 days free</div>
            <div className="text-base text-indigo-300 font-semibold">
              Then €45.00 per month
            </div>
            <LoginImg className="w-full mt-[70px]" />
          </div>
        )}
      </div>
      <div className="min-h-full lg:w-2/3 w-full bg-gray-50 scrollbar overflow-y-auto">
        <div className="flex min-h-full  flex-col justify-center py-12 sm:px-6 lg:px-8">
          <RegisterHeader step={registerStep} />
          <div
            className={`mt-8 sm:mx-auto sm:w-full mx-4 bg-white shadow sm:rounded-lg py-8 px-4 sm:px-10 ${
              registerStep === 1 || registerStep === 4
                ? 'sm:max-w-[500px]'
                : 'sm:max-w-3xl'
            }`}
          >
            {registerStep === 3 && (
              <div className=" text-gray-500 mb-2">Restaurant cover photo</div>
            )}
            {registerStep !== 1 && registerStep !== 4 && <RegisterInformationBanner />}

            <div className="mt-0">
              <form className="space-y-6" onSubmit={handleSubmit}>
                {registerStep === 1 && (
                  <RegisterFirstStep
                    email={email}
                    setEmail={setEmail}
                    password={oldPassword}
                    setPassword={setOldPassword}
                    setConfirmPrivacy={setConfirmPrivacy}
                    confirmPrivacy={confirmPrivacy}
                  />
                )}

                {registerStep === 2 && (
                  <RegisterSecondStep
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    restaurantName={restaurantName}
                    setRestaurantName={setRestaurantName}
                    phoneCode={phoneCode}
                    setPhoneCode={setPhoneCode}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    country={country}
                    setCountry={setCountry}
                    setLastCountry={setLastCountry}
                    currency={currency}
                    setCurrency={setCurrency}
                    mainLanguage={mainLanguage}
                    setMainLanguage={setMainLanguage}
                    setLastMainLanguage={setLastMainLanguage}
                    setTransactionLanguages={setTransactionLanguages}
                    transactionLanguages={transactionLanguages}
                    link={link}
                    setLink={setLink}
                    avalibleLink={avalibleLink}
                    setAvalibleLink={setAvalibleLink}
                  />
                )}

                {registerStep === 3 && <RegisterThirdStep />}

                {registerStep !== 3 && registerStep !== 4 && (
                  <div className={registerStep !== 1 ? 'flex w-full justify-end' : ''}>
                    {registerStep === 2 && (
                      <button
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          setRegisterStep((prev) => prev - 1);
                        }}
                        className={`flex mr-2 items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2`}
                      >
                        {registerStep === 2 && !loading && 'Previous'}
                        {loading && <Spinner color="bg-primary" />}
                      </button>
                    )}
                    <button
                      type="submit"
                      disabled={loading}
                      className={`flex ${
                        registerStep === 1 ? 'w-full' : 'w-auto'
                      } break-normal justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2`}
                    >
                      {registerStep === 3 && !loading && 'Sign Up'}
                      {(registerStep === 1 || registerStep === 2) && !loading && 'Next'}
                      {loading && <Spinner />}
                    </button>
                  </div>
                )}
              </form>
              <RegisterSteper stepData={stepData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
