//eslint-ignore

export const NoShellfish = ({ width = 32, height = 32, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 248 248"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_417_421)">
      <path
        d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
        fill="#F8E997"
      />
      <path
        d="M119.585 229.901C180.971 227.493 230 176.977 230 115C230 113.71 229.971 112.428 229.929 111.149L193.992 75.2955L191.55 73.7827C193.192 73.7827 64.2664 145.18 64.2664 145.18C64.4162 145.33 70.3526 151.271 70.471 151.388C66.5691 156.829 64.4572 174.773 64.4572 174.773L119.585 229.901Z"
        fill="#F2D049"
      />
      <path
        d="M156.834 147.623L115.225 147.446L73.9629 147.271C67.8335 153.378 64.4573 161.505 64.4573 170.158V174.773H115.225H165.993V170.158C165.993 161.689 162.739 153.685 156.834 147.623Z"
        fill="#DCD2CD"
      />
      <path
        d="M165.993 170.158C165.993 161.689 162.739 153.685 156.834 147.623L115.225 147.446V174.773H165.993V170.158Z"
        fill="#C9BBB4"
      />
      <path
        d="M73.7689 60.4292C61.7922 63.5268 49.8502 67.9427 38.9007 73.7832L36.458 75.0853V77.8525C36.458 144.358 95.843 168.421 110.677 173.412L73.7689 60.4292Z"
        fill="#EBE1DC"
      />
      <path
        d="M191.55 73.7828C180.6 67.9423 168.658 63.5264 156.681 60.4288L119.718 173.43C134.438 168.49 193.992 144.461 193.992 77.853V75.0858L191.55 73.7828Z"
        fill="#DCD2CD"
      />
      <path
        d="M115.225 54.7765C100.921 54.7765 86.8923 57.0346 73.7688 60.4288V64.1106C74.1532 108.676 93.7221 164.436 110.677 173.413C113.441 174.344 114.275 174.518 115.225 174.773C116.165 174.52 116.942 174.362 119.718 173.43C136.698 164.508 156.296 108.728 156.681 64.0701V60.4288C143.553 57.0337 129.532 54.7765 115.225 54.7765Z"
        fill="#FCF5F5"
      />
      <path
        d="M156.681 64.0701V60.4288C143.553 57.0333 129.532 54.7765 115.225 54.7765V174.773C116.165 174.52 116.942 174.362 119.718 173.43C136.698 164.507 156.297 108.728 156.681 64.0701Z"
        fill="#EBE1DC"
      />
      <circle cx="115" cy="115" r="110" stroke="black" strokeWidth="10" />
      <line
        x1="35.7109"
        y1="32.649"
        x2="184.711"
        y2="197.649"
        stroke="black"
        strokeWidth="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_421">
        <rect width="230" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
