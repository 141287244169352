//eslint-ignore

export const Sesame = ({ width = 32, height = 32, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 231 237"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_417_214)">
      <path
        d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
        fill="#DD455B"
      />
      <path
        d="M230 115C230 112.779 229.93 110.575 229.806 108.385C207.631 86.2104 186.291 64.8717 186.218 64.8006L157.524 99.8389L115.225 57.5193L96.2418 81.118C96.181 81.0572 80.0054 64.882 79.8528 64.7344C79.0611 63.9107 58.8769 108.991 58.8769 108.991L62.0802 112.385L94.6552 144.943C96.2206 148.918 101.226 156.337 101.226 156.337L62.0802 117.165L44.6754 165.188C44.7681 165.285 82.111 202.628 109.345 229.86C111.219 229.951 113.104 230 115 230C178.513 230 230 178.513 230 115Z"
        fill="#B2004F"
      />
      <path
        d="M115.225 110.333C119.38 110.605 137.54 107.076 137.54 92.7535C137.54 79.2312 120.356 62.9557 118.429 60.9127L115.225 57.5189L112.021 60.9127C110.094 62.9557 92.9102 79.2307 92.9102 92.7535C92.9102 105.527 107.133 109.815 115.225 110.333Z"
        fill="#F8E997"
      />
      <path
        d="M115.225 119.217C111.071 118.945 92.9102 122.473 92.9102 136.796C92.9102 150.319 110.094 166.594 112.021 168.637L115.225 172.031L118.429 168.637C120.356 166.594 137.54 150.319 137.54 136.796C137.54 124.023 123.317 119.735 115.225 119.217Z"
        fill="#91776F"
      />
      <path
        d="M137.54 92.7535C137.54 79.2312 120.356 62.9557 118.429 60.9127L115.225 57.5189V110.333C119.38 110.605 137.54 107.076 137.54 92.7535Z"
        fill="#EAAD44"
      />
      <path
        d="M137.54 136.796C137.54 124.023 123.317 119.735 115.225 119.217V172.031L118.429 168.637C120.356 166.594 137.54 150.319 137.54 136.796Z"
        fill="#674D55"
      />
      <path
        d="M171.573 108.991L168.37 112.385L165.167 108.991C163.237 106.947 146.348 88.6576 146.348 75.1367C146.348 62.2324 160.844 57.9749 168.37 57.5284C178.971 58.0924 190.14 63.9724 190.382 74.6141C190.757 85.6464 179.07 101.05 171.573 108.991Z"
        fill="#91776F"
      />
      <path
        d="M190.391 154.413C190.391 154.816 190.377 155.212 190.35 155.599C189.493 167.579 175.661 171.59 168.37 172.022C157.891 171.464 146.349 165.659 146.349 154.413C146.349 140.892 163.238 122.603 165.167 120.559L168.37 117.165L171.574 120.559C173.502 122.603 190.391 140.892 190.391 154.413Z"
        fill="#F8E997"
      />
      <path
        d="M171.573 108.991L168.37 112.385V57.5284C178.971 58.0924 190.14 63.9724 190.382 74.6141C190.757 85.6464 179.07 101.05 171.573 108.991Z"
        fill="#674D55"
      />
      <path
        d="M190.391 154.413C190.391 154.816 190.377 155.212 190.35 155.599C189.493 167.579 175.661 171.59 168.37 172.022V117.165L171.574 120.559C173.502 122.603 190.391 140.892 190.391 154.413Z"
        fill="#EAAD44"
      />
      <path
        d="M84.1017 75.1367C84.1017 88.6576 67.2127 106.947 65.2835 108.991L62.0802 112.385L58.8769 108.991C56.9477 106.947 40.0587 88.6576 40.0587 75.1367C40.0587 62.2324 54.5546 57.9749 62.0802 57.5284C72.9343 58.1059 84.1017 64.1232 84.1017 75.1367Z"
        fill="#91776F"
      />
      <path
        d="M84.1017 154.413C84.1017 154.816 84.0868 155.212 84.0607 155.599C83.2033 167.579 69.3709 171.59 62.0807 172.022C51.601 171.464 40.0587 165.659 40.0587 154.413C40.0587 140.892 56.9477 122.603 58.8769 120.559L62.0802 117.165L65.2835 120.559C67.2127 122.603 84.1017 140.892 84.1017 154.413Z"
        fill="#F8E997"
      />
      <path
        d="M84.1017 75.1367C84.1017 88.6576 67.2127 106.947 65.2835 108.991L62.0802 112.385V57.5284C72.9658 58.1077 84.1017 64.1448 84.1017 75.1367Z"
        fill="#674D55"
      />
      <path
        d="M84.1017 154.413C84.1017 154.816 84.0869 155.212 84.0608 155.599C83.2033 167.579 69.3709 171.59 62.0807 172.022V117.165L65.284 120.559C67.2127 122.603 84.1017 140.892 84.1017 154.413Z"
        fill="#EAAD44"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_214">
        <rect width="230" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
