import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { selectLanguages } from 'appConstants';
import { SetStateType } from 'commonTypes';
import TextareaAutosize from 'react-textarea-autosize';

interface SelectionTranslateSectionProps {
  item: string;
  selections: any;
  setSelections: SetStateType<any>;
  open: boolean;
}

export const SelectionTranslateSection = ({
  item,
  selections,
  setSelections,
  open,
}: SelectionTranslateSectionProps) => {
  return (
    <div className="h-auto  mb-3">
      <Disclosure.Button className="flex w-full items-center justify-between py-3 px-2 rounded-lg bg-gray-100 md:hover:bg-gray-50">
        <p className="text-gray-400 text-base whitespace-nowrap pr-2 truncate">
          {selections[item].name}
        </p>

        <span className="ltr:ml-2 rtl:mr-2 flex h-7 items-center">
          <ChevronDownIcon
            className={`h-6 w-6 text-gray-400 transition-all duration-500 ease-in-out ${
              open ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
        </span>
      </Disclosure.Button>
      <Disclosure.Panel className="mx-3 px-3 py-2 bg-gray-100 rounded-lg mt-2">
        {Object.keys(selections[item].nameTranslate).map((item2) => {
          return (
            <div
              key={'selection-translate-' + item + '-' + item2}
              className="grid grid-cols-[1fr_4fr] items-center space-x-3 rtl:space-x-reverse w-full mb-2 transition-all duration-300 ease-in-out overflow-hidden"
            >
              <h2 className="rtl:text-right">
                {selectLanguages
                  .find((elem) => {
                    return elem.value === item2;
                  })
                  ?.value.toUpperCase()}
              </h2>
              <div>
                <TextareaAutosize
                  minRows={2}
                  className="mb-4 last:mb-0 w-full scrollbar rounded-md border-gray-300 sm:text-sm"
                  placeholder={'Selection name'}
                  value={selections[item].nameTranslate[item2]}
                  onChange={(e) => {
                    setSelections((prev: any) => {
                      return {
                        ...prev,
                        [item]: {
                          ...prev[item],
                          nameTranslate: {
                            ...prev[item].nameTranslate,
                            [item2]: e.target.value,
                          },
                        },
                      };
                    });
                  }}
                />
              </div>
            </div>
          );
        })}
      </Disclosure.Panel>
    </div>
  );
};
