import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { FormattedMessage } from 'react-intl';

const tabs = [
  { name: <FormattedMessage id="mainInfo" />, id: 0, current: true },
  { name: <FormattedMessage id="options" />, id: 1, current: false },
  { name: <FormattedMessage id="suggestions" />, id: 2, current: false },
];

interface ItemTabsProps {
  activeTab: number;
  setActiveTab: SetStateType<number>;
}

export const ItemTabs = ({ activeTab, setActiveTab }: ItemTabsProps) => {
  return (
    <div>
      <div className="block overflow-auto scrollbar pb-1">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8 rtl:space-x-reverse" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                role="button"
                tabIndex={0}
                data-testId={'cypress-item-tab-' + tab.id}
                key={'tab-item-desctop-' + tab.id}
                className={classNames(
                  +tab.id === +activeTab
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 md:hover:text-gray-700 md:hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
                )}
                aria-current={tab.id === activeTab ? 'page' : undefined}
                onClick={() => {
                  setActiveTab(tab.id);
                }}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
