import { XMarkIcon } from '@heroicons/react/24/outline';
import { phoneCodes } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { resetEmailAws } from 'store/account/actions';
import { changeMainAttr } from 'store/auth/actions';

import { AddNewMainAttrValue, ConfirmOldAttr } from '../UI';

interface ChangeMainAttrModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  mode?: 'changeEmail' | 'changePhone';
}

export const ChangeMainAttrModal = ({
  open,
  setOpen,
  mode = 'changePhone',
}: ChangeMainAttrModalProps) => {
  const dispatch = useAppDispatch();
  const [confirmMethodChoose, setConfirmMethodChoose] = useState(1);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [codeSended, setCodeSended] = useState(false);
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState<
    | {
        code: string;
        name: string;
      }
    | undefined
  >(phoneCodes[0]);

  const resetFields = () => {
    setConfirmMethodChoose(1);
    setActiveStep(1);
    setLoading(false);
    setCodeSended(false);
    setCode('');
    setEmail('');
    setPhone('');
    setPhoneCode(phoneCodes[0]);
  };

  return (
    <AppModal size="sm:max-w-md" open={open}>
      <div className="flex flex-col">
        <div className="flex items-center justify-between w-full mb-3">
          <h1>
            <FormattedMessage id={mode} />
          </h1>
          {activeStep !== 4 && (
            <XMarkIcon
              onClick={() => {
                if (!loading) {
                  setOpen(false);
                  setTimeout(() => {
                    resetFields();
                  }, 300);
                }
              }}
              className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
            />
          )}
        </div>

        {(activeStep === 1 || activeStep === 2) && (
          <ConfirmOldAttr
            codeSended={codeSended}
            loading={loading}
            setLoading={setLoading}
            confirmMethodChoose={confirmMethodChoose}
            setConfirmMethodChoose={setConfirmMethodChoose}
            code={code}
            setCode={setCode}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            setCodeSended={setCodeSended}
          />
        )}

        {(activeStep === 3 || activeStep === 4) && (
          <AddNewMainAttrValue
            mode={mode}
            email={email}
            setEmail={setEmail}
            phoneCode={phoneCode}
            setPhoneCode={setPhoneCode}
            phone={phone}
            code={code}
            setCode={setCode}
            setPhone={setPhone}
            codeSended={codeSended}
            loading={loading}
            setLoading={setLoading}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setCodeSended={setCodeSended}
          />
        )}

        {(activeStep === 2 || activeStep === 4) && (
          <div className="flex items-center justify-end w-full">
            {activeStep === 4 && (
              <button
                type="button"
                disabled={!codeSended || loading}
                onClick={() => {
                  dispatch(resetEmailAws({ setLoading }));
                }}
                className="mr-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                {loading ? (
                  <Spinner color="bg-primary" />
                ) : (
                  <FormattedMessage id="cancel" />
                )}
              </button>
            )}
            <button
              type="button"
              disabled={!codeSended || loading || !code.trim()}
              onClick={() => {
                dispatch(
                  changeMainAttr({
                    step: activeStep,
                    setStep: setActiveStep,
                    code,
                    setLoading,
                    setCode,
                    codeSended,
                    setCodeSended,
                    mode: confirmMethodChoose === 1 ? 'email' : 'phone_number',
                    setOpen,
                    email,
                    // @ts-ignore
                    phone: phoneCode?.code + phone?.match(/\d+/g)?.join(''),
                    resetFields,
                  }),
                );
              }}
              className="rounded-md disabled:opacity-50 disabled:hover:bg-indigo-600 bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {activeStep === 4 ? (
                <FormattedMessage id="confirm" />
              ) : (
                <FormattedMessage id="next" />
              )}
            </button>
          </div>
        )}
      </div>
    </AppModal>
  );
};
