import { Spinner } from '@Common/Spinner';
import { defaultToastProps, phoneCodes, zoneInfo } from 'appConstants';
import { ReactComponent as Logo } from 'assets/logos/logo-dark.svg';
import { ReactComponent as LoginImg } from 'assets/static/login.svg';
import { SetStateType } from 'commonTypes';
import {
  RegisterCountrySelect,
  RegisterPhoneCodeSelect,
} from 'components/RegisterComponent';
import { phoneMask } from 'helpers/validation';
import { useAppDispatch } from 'hooks/store';
import { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SET_ERROR } from 'store/auth/actionsTypes';

interface RequestBody {
  name: string;
  restaurant_name: string;
  phone: string;
  country: string;
  email: string;
}

const requestFeedback = async ({
  setLoading,
  body,
}: {
  setLoading: SetStateType<boolean>;
  body: RequestBody;
}) => {
  try {
    setLoading(true);
    const req = await fetch(
      'https://fu6jvxm51k.execute-api.eu-central-1.amazonaws.com/default/web-form-free-trial',
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
    );
    if (req.ok) {
      toast('Request sent successfully', { ...defaultToastProps, type: 'success' });
    } else {
      throw new Error('Something went wrong');
    }
  } catch (e) {
    toast('Failed to send request. Please try again later', {
      ...defaultToastProps,
      type: 'error',
    });
  } finally {
    setLoading(false);
  }
};

export const RequestDemo = () => {
  const [countrySelected, setCountrySelected] = useState(
    zoneInfo.find((zone) => zone.country === 'Hungary'),
  );
  const [phoneCode, setPhoneCode] = useState(
    phoneCodes.find((phone) => phone.code === '+36'),
  );
  const [phoneNumber, setPhoneNumber] = useState('');
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [restaurantName, setRestaurantName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!loading) {
      if (!name.trim()) {
        toast('Please enter your name', {
          ...defaultToastProps,
          type: 'error',
        });
      } else if (!restaurantName.trim()) {
        toast('Please enter the name of the restaurant', {
          ...defaultToastProps,
          type: 'error',
        });
      } else if (!phoneNumber.trim()) {
        toast('Please enter your phone number', {
          ...defaultToastProps,
          type: 'error',
        });
      } else if (!email.trim()) {
        toast('Please enter your e-mail address', {
          ...defaultToastProps,
          type: 'error',
        });
      } else if (!countrySelected?.country) {
        toast('Please select your country', {
          ...defaultToastProps,
          type: 'error',
        });
      } else {
        const body: RequestBody = {
          name: name,
          restaurant_name: restaurantName,
          phone: phoneCode?.code + phoneNumber,
          country: countrySelected?.country,
          email: email,
        };
        requestFeedback({
          setLoading,
          body,
        });
      }
    }
  };

  return (
    <div className="min-h-screen flex">
      <div className="min-h-full lg:w-1/3 w-0 bg-primary flex items-center justify-center">
        <LoginImg className="w-4/5" />
      </div>
      <div className="min-h-full lg:w-2/3 w-full bg-gray-50 scrollbar overflow-y-auto">
        <div className="flex min-h-full  flex-col items-center justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md mb-8">
            <Logo className="mx-auto h-12 w-auto" />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Request your 14-day free trial
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Or {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
              <Link
                to="/login"
                onClick={() => dispatch({ type: SET_ERROR, payload: null })}
                className="font-medium text-indigo-600 md:hover:text-indigo-500"
              >
                sign in to your account
              </Link>
            </p>
          </div>
          <div className="p-4 bg-white w-full sm:w-[47%] rounded-lg sm:rounded-r-lg">
            <form
              onSubmit={handleSubmit}
              className="flex w-full flex-col justify-between h-auto sm:h-[90%]"
            >
              <div className="mb-5">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    id="name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="restaurant-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Restaurant name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="restaurant-name"
                    value={restaurantName}
                    onChange={(e) => setRestaurantName(e.target.value)}
                    id="restaurant-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="feedback-email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="feedback-email"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor="country" className="sr-only">
                      Country
                    </label>
                    <RegisterPhoneCodeSelect
                      phoneCode={phoneCode}
                      setPhoneCode={setPhoneCode}
                    />
                  </div>
                  <input
                    type="text"
                    value={phoneNumber}
                    //   onChange={(e) => setPhoneNumber(e.target.value)}
                    onInput={(e) => {
                      phoneMask(e, setPhoneNumber);
                    }}
                    required
                    name="phone-number"
                    id="phone-number"
                    autoComplete="tel-national"
                    className="block w-full rounded-md border-gray-300 pl-28 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm"
                    placeholder="Phone Number"
                  />
                </div>
              </div>

              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                Country
              </label>
              <RegisterCountrySelect
                country={countrySelected}
                setCountry={setCountrySelected}
                setLastCountry={() => {}}
              />

              <button
                type="submit"
                className="mt-6 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {loading ? <Spinner color="bg-primary" /> : 'Send'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
