import { AppModal } from '@Common/AppModal';
import { Spinner } from '@Common/Spinner';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { colorPresets, defaultToastProps } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { createTheme } from 'store/themes/actions';
import { ApiThemeState } from 'store/themes/types';

import { ActiveThemeSelect } from '../UI';

interface AddThemeModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
}

export const AddThemeModal = ({ open, setOpen }: AddThemeModalProps) => {
  const dispatch = useAppDispatch();
  const themeSubLoading = useAppSelector((state) => state.themes.subLoading);
  const [activeTheme, setActiveTheme] = useState<ApiThemeState | undefined>();
  const [nameTheme, setNameTheme] = useState('');

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      setActiveTheme(undefined);
      setNameTheme('');
    }, 300);
  };

  return (
    <AppModal open={open}>
      <div className="flex w-full items-center justify-between mb-3">
        <h1>
          <FormattedMessage id="createTheme" />
        </h1>
        <XMarkIcon
          onClick={close}
          className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="new-theme-name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          <FormattedMessage id="name" />
        </label>
        <div className="mt-1">
          <input
            data-testId="cypress-add-theme-input"
            type="text"
            name="new-theme-name"
            id="new-theme-name"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Theme name"
            value={nameTheme}
            onChange={(e) => {
              setNameTheme(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="block text-sm font-medium leading-6 text-gray-900 mb-1">
        <FormattedMessage id="themeToCreateFrom" />
      </div>
      <ActiveThemeSelect activeTheme={activeTheme} setActiveTheme={setActiveTheme} />

      <div className="flex w-full justify-end items-center mt-4">
        <button
          type="button"
          onClick={close}
          className="rounded-md mr-2 bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <FormattedMessage id="cancel" />
        </button>

        <button
          data-testId="cypress-add-theme-save"
          type="button"
          onClick={() => {
            if (!nameTheme) {
              toast('The name of the theme is mandatory', {
                ...defaultToastProps,
                type: 'error',
              });
            } else {
              let newColors = activeTheme?.colors;
              if (activeTheme?.type === 2) {
                newColors =
                  colorPresets[
                    activeTheme?.name.toLowerCase() as keyof typeof colorPresets
                  ];
              }
              dispatch(createTheme(nameTheme, newColors, close));
            }
          }}
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {themeSubLoading ? (
            <Spinner color="bg-white" />
          ) : (
            <FormattedMessage id="save" />
          )}
        </button>
      </div>
    </AppModal>
  );
};
