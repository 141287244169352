import { AppModal } from '@Common/AppModal';
import { Spinner } from '@Common/Spinner';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { IOptions, SetStateType } from 'commonTypes';
import { positiveNumberInput } from 'helpers/validation';
import { useAppDispatch } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { editOption } from 'store/options/actions';

interface ChangeToppingsModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  active: IOptions;
  id: string;
}

export const ChangeToppingsModal = ({
  open,
  setOpen,
  active,
  id,
}: ChangeToppingsModalProps) => {
  const dispatch = useAppDispatch();
  const [selectionCount, setSelectionCount] = useState(
    active.free_option_data?.amount || 2,
  );
  const [discardClick, setDiscardClick] = useState(0);
  const [loading, setLoading] = useState(false);

  const close = () => {
    setDiscardClick(0);
    setSelectionCount(active.free_option_data?.amount || 2);
    setOpen(false);
  };

  return (
    <AppModal size="sm:max-w-lg" open={open}>
      <div className="w-full flex flex-col">
        <div className="flex w-full items-center justify-between mb-3">
          <h1>
            <FormattedMessage id="changeToppings" />
          </h1>
          <XMarkIcon
            onClick={close}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="rounded-md bg-blue-50 p-4 my-3">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ltr:ml-3 rtl:mr-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                <FormattedMessage id="numberOfSelectionText" />
              </p>
            </div>
          </div>
        </div>

        <div>
          <label
            htmlFor="numberOfSelectionForFree"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            <FormattedMessage id="numberOfSelectionForFree" />
          </label>
          <div className="mt-2">
            <input
              value={selectionCount}
              onChange={(e) => {
                setSelectionCount(+positiveNumberInput(e, true));
              }}
              type="text"
              name="numberOfSelectionForFree"
              id="numberOfSelectionForFree"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="1"
            />
          </div>
        </div>

        <div className="md:hidden text-sm text-red-500 mt-2">
          {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
        </div>
        <div className="w-full flex justify-end mt-3">
          {selectionCount === (active.free_option_data?.amount || 2) ? (
            <button
              type="button"
              onClick={close}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
            >
              <FormattedMessage id="cancel" />
            </button>
          ) : (
            <div className="flex items-center flex-nowrap">
              <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2 hidden md:block">
                {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
              </div>
              <div className="flex items-center justify-end w-full sm:w-auto">
                <button
                  type="button"
                  onClick={() => {
                    if (discardClick === 0) {
                      setDiscardClick(1);
                    } else {
                      close();
                    }
                  }}
                  className="ltr:mr-3 rtl:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  <FormattedMessage id="discardChanges" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    const sortedSelections = active.selections.filter(
                      (selection) => selection.status === 1,
                    );
                    if (sortedSelections.length >= selectionCount) {
                      dispatch(
                        editOption({
                          data: {
                            multi_select: true,
                            free_option_data: {
                              free_options: true,
                              amount: selectionCount,
                            },
                          },
                          id,
                          setLoading,
                          setOpen,
                        }),
                      );
                    }
                  }}
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  {loading ? (
                    <Spinner color="bg-white" />
                  ) : (
                    <FormattedMessage id="save" />
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </AppModal>
  );
};
