import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { PaperClipIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-tooltip';

import { ChangeDefaultPhotoModal, ConfirmRemoveDefaultPhoto } from '../ChangeModals';

export const DefaultItemPhotoField = () => {
  const notification = useAppSelector((state) => state.account.notification);
  const defaultPhoto = useAppSelector((state) => state.account.account?.default_photo);
  const [openEdit, setOpenEdit] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);

  useEffect(() => {
    if (notification === 'changeDefaultPhoto') {
      setOpenEdit(true);
    }
  }, [notification]);

  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 reviews-link items-center">
      <ConfirmRemoveDefaultPhoto open={openRemove} setOpen={setOpenRemove} />
      <ChangeDefaultPhotoModal open={openEdit} setOpen={setOpenEdit} />
      <dt className="text-sm font-medium text-gray-500 flex">
        <FormattedMessage id="defaultItemPhoto" />
        <>
          <ExclamationCircleIcon
            data-tooltip-id="default-photo-tooltip"
            className="h-5 w-5 text-gray-500 ml-2 cursor-pointer md:hover:text-gray-400"
            aria-hidden="true"
          />
          <Tooltip place="top" id="default-photo-tooltip">
            <div className="max-w-[100px] md:max-w-[300px]">
              <FormattedMessage id="defaultPhotoTooltipText" />
            </div>
          </Tooltip>
        </>
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow truncate">
          {defaultPhoto?.links?.preloader ? (
            <div className="divide-y divide-gray-100 rounded-md border border-gray-200">
              <div className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                <div className="flex w-0 flex-1 items-center">
                  <PaperClipIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <div className="ml-4 flex min-w-0 flex-1 gap-2">
                    <span className="truncate font-medium">{defaultPhoto.name}</span>
                    <span className="flex-shrink-0 text-gray-400">
                      {defaultPhoto.size}
                    </span>
                  </div>
                </div>
                <div className="ml-4 flex flex-shrink-0 space-x-4">
                  <button
                    type="button"
                    onClick={() => {
                      setOpenEdit(true);
                    }}
                    className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    <FormattedMessage id="update" />
                  </button>
                  <span className="text-gray-200" aria-hidden="true">
                    |
                  </span>
                  <button
                    type="button"
                    onClick={() => {
                      setOpenRemove(true);
                    }}
                    className="rounded-md bg-white font-medium text-gray-900 hover:text-gray-800"
                  >
                    <FormattedMessage id="remove" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            'No'
          )}
        </div>
        {!defaultPhoto?.links?.preloader && (
          <span className="ltr:ml-4 rtl:mr-4 flex-shrink-0">
            <button
              onClick={() => {
                setOpenEdit(true);
              }}
              data-testId="cypress-change-media"
              className="rounded-md bg-white font-medium text-indigo-600 md:hover:text-indigo-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
            >
              <FormattedMessage id="edit" />
            </button>
          </span>
        )}
      </dd>
    </div>
  );
};
