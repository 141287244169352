import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { FormattedMessage } from 'react-intl';

interface FreeCheckBoxProps {
  free: boolean;
  setFree: SetStateType<boolean>;
  disabled?: boolean;
}

export const FreeCheckBox = ({ free, setFree, disabled }: FreeCheckBoxProps) => {
  return (
    <div className={classNames('relative flex items-start', disabled && 'opacity-70')}>
      <div className="flex h-6 items-center">
        <input
          disabled={disabled}
          id="free-price"
          checked={free}
          onChange={(e) => {
            if (!disabled) {
              setFree(e.target.checked);
            }
          }}
          aria-describedby="free-price"
          name="free-price"
          type="checkbox"
          data-testId="cypress-add-item-free-price"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
      </div>
      <div className="ml-1.5 text-sm leading-6">
        <label htmlFor="free-price" className="font-medium text-gray-900 select-none">
          <FormattedMessage id="free" />
        </label>
      </div>
    </div>
  );
};
