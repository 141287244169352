import { TrashIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { useState } from 'react';
import { ApiThemeState } from 'store/themes/types';

import { DeleteThemeModal } from '../Modals';

interface DeleteThemeButtonProps {
  id: string;
  activeChooseTheme: ApiThemeState | undefined;
  setThemeType: SetStateType<string>;
}

export const DeleteThemeButton = ({
  id,
  activeChooseTheme,
  setThemeType,
}: DeleteThemeButtonProps) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <>
      <DeleteThemeModal
        id={id}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        activeChooseTheme={activeChooseTheme}
        setThemeType={setThemeType}
      />
      <button
        onClick={() => setOpenDeleteModal(true)}
        className="p-2 border border-gray-300 rounded-md bg-white"
      >
        <TrashIcon className="h-6 w-6 text-gray-500" />
      </button>
    </>
  );
};
