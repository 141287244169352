import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ReactComponent as Logo } from 'assets/logos/digital-menu.logo-03.svg';
import { SetStateType } from 'commonTypes';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { NavigationProp, UserNavigationProp } from 'pages/Layout/Layout';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { logout } from 'store/auth/actions';

import { LanguageDropdown } from './LanguageDropdown';
import { Spinner } from './Spinner';

interface MobileSidebarProps {
  setSidebarOpen: SetStateType<boolean>;
  sidebarOpen: boolean;
  navigation: NavigationProp[];
  userNavigation: UserNavigationProp[];
}

export const MobileSidebar = ({
  setSidebarOpen,
  sidebarOpen,
  navigation,
  userNavigation,
}: MobileSidebarProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const loading = useAppSelector((state) => state.auth.loading);
  const account = useAppSelector((state) => state.account.account);
  const cognitoUser = useAppSelector((state) => state.account.cognitoUser);

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40 ipad:hidden" onClose={setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-40">
          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
            enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
            enterTo="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
            leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
            leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
            leaveTo="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
          >
            <Dialog.Panel
              className={`fixed inset-0 z-40 h-full w-full bg-white sm:inset-y-0 rtl:sm:right-auto rtl:sm:left-0 ltr:sm:left-auto ltr:sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg`}
              aria-label="Global"
            >
              <div className="flex h-16 items-center justify-between px-4 sm:px-6">
                <Link to="/menu">
                  <Logo className="block h-8 w-auto" />
                </Link>
                <button
                  type="button"
                  className={`rtl:-ml-2 ltr:-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 md:hover:bg-gray-100 md:hover:text-gray-500 md:focus:outline-none md:focus:ring-2 md:focus:ring-inset md:focus:ring-indigo-600`}
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close main menu</span>
                  <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="max-w-8xl mx-auto py-3 px-2 sm:px-4">
                {navigation.map((item) => (
                  <Fragment key={item.name}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                    <Link
                      to={item.href}
                      onClick={() => setSidebarOpen(false)}
                      className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 md:hover:bg-gray-100"
                    >
                      <FormattedMessage id={item.name.toLowerCase()} />
                    </Link>
                  </Fragment>
                ))}
              </div>
              <div className="border-t border-gray-200 pt-4 pb-3">
                <div className="max-w-8xl mx-auto flex items-center px-4 sm:px-6">
                  <div className="flex-shrink-0 h-10 w-10 rounded-full bg-primary text-white flex items-center justify-center text-lg font-medium">
                    {cognitoUser?.attributes?.given_name[0]?.toUpperCase()}
                    {cognitoUser?.attributes?.family_name[0]?.toUpperCase()}
                  </div>
                  <div className={`rtl:mr-3 ltr:ml-3 min-w-0 flex-1`}>
                    <div className="truncate text-base font-medium text-gray-800">
                      {cognitoUser?.attributes?.given_name}{' '}
                      {cognitoUser?.attributes?.family_name}
                    </div>
                    <div className="truncate text-sm font-medium text-gray-500">
                      {account?.email}
                    </div>
                  </div>
                  {/*<a href='/' className='ml-auto flex-shrink-0 bg-white p-2 text-gray-400 md:hover:text-gray-500'>
                    <span className='sr-only'>View notifications</span>
                    <BellIcon className='h-6 w-6' aria-hidden='true' />
                  </a>*/}
                </div>
                <div className="max-w-8xl mx-auto mt-3 space-y-1 px-2 sm:px-4">
                  {userNavigation.map((item) => {
                    if (item.name === 'Sign out') {
                      return (
                        <div
                          role="button"
                          tabIndex={0}
                          key={item.name}
                          onClick={() => {
                            dispatch(logout(history));
                          }}
                          className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 md:hover:bg-gray-50 cursor-pointer"
                        >
                          {loading ? (
                            <Spinner color="bg-primary" />
                          ) : (
                            <FormattedMessage id={item.id.toLowerCase()} />
                          )}
                        </div>
                      );
                    } else {
                      return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                          key={item.name}
                          to={item.href}
                          onClick={() => setSidebarOpen(false)}
                          className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 md:hover:bg-gray-50"
                        >
                          <FormattedMessage id={item.id.toLowerCase()} />
                        </Link>
                      );
                    }
                  })}
                  <div className="block md:hidden px-3 pt-3">
                    <LanguageDropdown />
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
