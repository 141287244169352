import { XMarkIcon } from '@heroicons/react/24/outline';
import { defaultToastProps, translateGpt } from 'appConstants';
import { selectLanguagesGPT } from 'appConstants';
import { SelectedLanguage, SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getGptResponse } from 'store/gpt/actions';
import { SET_GPT_ERROR } from 'store/gpt/actionTypes';

import { FirstStepGPT } from './FirstStepGPT';
import { SecondStepGPT } from './SecondStepGPT';

interface ChatGPTModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  setParentSelected: SetStateType<SelectedLanguage | undefined>;
  setParentName: SetStateType<string>;
}

export const ChatGPTModal = ({
  open,
  setOpen,
  setParentSelected,
  setParentName,
}: ChatGPTModalProps) => {
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = useState(1);
  const listPrompts = useAppSelector((state) => state.gpt.chatGPT.listPrompts);
  const loading = useAppSelector((state) => state.gpt.chatGPT.loading);
  const [selected, setSelected] = useState<SelectedLanguage | undefined>(
    selectLanguagesGPT.find((lang) => lang.value === 'en'),
  );
  const result = useAppSelector((state) => state.gpt.chatGPT.gptResult);
  const itemFields = useAppSelector((state) => state.item.addedItem);
  const activeItem = useAppSelector((state) => state.item.activeItem);

  const [avalibleLength, setAvalibleLength] = useState(350);
  const [currentLength, setCurrentLength] = useState(250);
  const [maxLength, setMaxLength] = useState(150);

  const getFinalPromptLength = () => {
    let finalPrompt =
      translateGpt.finalPrompt[selected?.value as keyof typeof translateGpt.finalPrompt] +
      ' ' +
      (itemFields.name?.translations
        ? itemFields.name?.translations[
            selected?.value as keyof typeof itemFields.name.translations
          ]
        : // @ts-ignore
          activeItem.name?.translations[
            // @ts-ignore
            selected?.value as keyof typeof activeItem.name.translations
          ]) +
      '. ';
    listPrompts.forEach((prompt) => {
      if (!prompt.defaultId) {
        finalPrompt += prompt.text + '. ';
      } else {
        finalPrompt +=
          // @ts-ignore
          translateGpt.prompts[prompt.defaultId][selected?.value] + '. ';
      }
    });
    finalPrompt +=
      translateGpt.inShort1[selected?.value as keyof typeof translateGpt.inShort1] +
      maxLength +
      translateGpt.inShort2[selected?.value as keyof typeof translateGpt.inShort2];

    return finalPrompt.length;
  };

  useEffect(() => {
    setCurrentLength(getFinalPromptLength());
    dispatch({
      type: SET_GPT_ERROR,
      payload: null,
    });
    return () => {
      dispatch({
        type: SET_GPT_ERROR,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    setCurrentLength(getFinalPromptLength());
  }, [
    translateGpt,
    itemFields,
    activeItem,
    listPrompts,
    maxLength,
    selectLanguagesGPT,
    selected,
  ]);

  return (
    <AppModal open={open} size="sm:max-w-5xl">
      <div className="flex w-full items-center justify-between mb-3">
        <h1>{translateGpt.title[selected?.value as keyof typeof translateGpt.title]}</h1>
        <XMarkIcon
          onClick={() => {
            if (!loading) {
              setTimeout(() => {
                setActiveStep(1);
              }, 300);
              setOpen(false);
            }
          }}
          className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
        />
      </div>

      {activeStep === 1 && (
        <FirstStepGPT
          maxLength={maxLength}
          setMaxLength={setMaxLength}
          selected={selected}
          setSelected={setSelected}
          avalibleLength={avalibleLength}
          currentLength={currentLength}
        />
      )}

      {activeStep === 2 && <SecondStepGPT maxLength={maxLength} selected={selected} />}

      <div className="flex w-full justify-end items-center">
        {activeStep === 2 && (
          <button
            type="button"
            onClick={() => {
              if (activeStep === 2) {
                setActiveStep(1);
              }
            }}
            disabled={loading}
            className="rounded-md mr-2 bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            {translateGpt.previous[selected?.value as keyof typeof translateGpt.previous]}
          </button>
        )}

        <button
          type="button"
          onClick={() => {
            if (activeStep === 1) {
              let finalPrompt =
                translateGpt.finalPrompt[
                  selected?.value as keyof typeof translateGpt.finalPrompt
                ] +
                ' ' +
                (itemFields.name?.translations
                  ? itemFields.name?.translations[
                      selected?.value as keyof typeof itemFields.name.translations
                    ]
                  : // @ts-ignore
                    activeItem.name?.translations[
                      // @ts-ignore
                      selected?.value as keyof typeof activeItem.name.translations
                    ]) +
                '. ';
              listPrompts.forEach((prompt) => {
                if (!prompt.defaultId) {
                  finalPrompt += prompt.text + '. ';
                } else {
                  finalPrompt +=
                    // @ts-ignore
                    translateGpt.prompts[prompt.defaultId][selected?.value] + '. ';
                }
              });
              finalPrompt +=
                translateGpt.inShort1[
                  selected?.value as keyof typeof translateGpt.inShort1
                ] +
                maxLength +
                translateGpt.inShort2[
                  selected?.value as keyof typeof translateGpt.inShort2
                ];

              if (finalPrompt.length > avalibleLength) {
                toast('The maximum request length has been exceeded', {
                  ...defaultToastProps,
                  type: 'error',
                });
              } else {
                dispatch(
                  getGptResponse({
                    prompt: finalPrompt,
                    setActiveStep,
                  }),
                );
              }
            } else {
              setParentName(result);
              setParentSelected(selected);
              setOpen(false);
              setTimeout(() => {
                setActiveStep(1);
              }, 300);
            }
          }}
          disabled={loading}
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {activeStep === 1 &&
            (loading ? (
              <Spinner color="bg-white" />
            ) : (
              translateGpt.generate[selected?.value as keyof typeof translateGpt.generate]
            ))}
          {activeStep === 2 &&
            translateGpt.confirm[selected?.value as keyof typeof translateGpt.confirm]}
        </button>
      </div>
    </AppModal>
  );
};
