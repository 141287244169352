//eslint-ignore

export const Fish = ({ width = 32, height = 32, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 231 231"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_417_185)">
      <path
        d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
        fill="#6BA7FF"
      />
      <path
        d="M230 115C230 113.82 229.977 112.646 229.941 111.474L202.544 84.0769C202.544 84.0769 174.8 85.0712 171.66 86.8504L158.714 73.8444C158.714 73.8444 69.2943 76.5743 69.5041 77.8944C67.4782 78.6776 65.5212 79.5503 63.6366 80.4987C63.6366 80.4987 42.8434 133.773 42.6945 133.624C42.5455 133.475 69.5162 160.446 69.6495 160.58C70.659 165.79 73.2529 170.438 76.91 174.006C77.018 174.117 112.366 209.466 131.692 228.791C187.293 220.704 230 172.843 230 115Z"
        fill="#2681FF"
      />
      <path
        d="M104.988 50.3907C101.76 48.9765 98.3171 48.2622 94.76 48.2622C80.6553 48.2622 69.1782 59.7388 69.1782 73.844C69.1782 75.5278 69.343 77.1765 69.6531 78.7703L131.189 85.8602L158.714 73.844L104.988 50.3907Z"
        fill="#EEF4FF"
      />
      <path
        d="M131.189 143.689L69.6526 150.779C69.3429 152.373 69.1777 154.022 69.1777 155.705C69.1777 169.81 80.6543 181.287 94.7595 181.287C98.3171 181.287 101.759 180.573 104.987 179.159L158.714 155.705L131.189 143.689Z"
        fill="#D9E6FC"
      />
      <path
        d="M192.311 99.4258C192.311 96.5604 194.562 94.3095 197.428 94.3095H202.544V84.077H182.078C173.893 84.077 166.081 88.9884 162.909 96.5604C138.184 81.7374 108.383 73.844 90.9733 73.844C80.6305 73.844 71.5133 76.5329 63.6361 80.4978V114.775V149.052C71.5137 153.017 80.6309 155.706 90.9733 155.706C108.383 155.706 138.184 147.813 162.909 132.989C166.081 140.561 173.892 145.473 182.078 145.473H202.544V135.24H197.428C194.562 135.24 192.311 132.989 192.311 130.124C192.311 121.391 184.822 114.775 176.621 114.775C184.822 114.775 192.311 108.159 192.311 99.4258Z"
        fill="#D9E6FC"
      />
      <path
        d="M90.9737 155.706C108.383 155.706 138.184 147.813 162.909 132.99C166.082 140.562 173.893 145.473 182.079 145.473H202.544V135.24H197.428C194.562 135.24 192.311 132.99 192.311 130.124C192.311 121.392 184.822 114.775 176.621 114.775H63.6361V149.052C71.5137 153.017 80.6309 155.706 90.9737 155.706Z"
        fill="#BED9FD"
      />
      <path
        d="M63.6361 80.4982C45.4049 89.6748 33.5754 105.796 29.6452 112.012L27.906 114.775L29.6452 117.538C33.5754 123.754 45.4045 139.875 63.6361 149.052C78.5965 144.754 89.6432 131.098 89.6432 114.775C89.6432 98.4522 78.5969 84.7958 63.6361 80.4982Z"
        fill="#EEF4FF"
      />
      <path
        d="M63.6361 149.052C78.5965 144.754 89.6432 131.098 89.6432 114.775H27.906L29.6452 117.538C33.5759 123.754 45.4049 139.875 63.6361 149.052Z"
        fill="#D9E6FC"
      />
      <path
        d="M115.225 99.4257V109.658C118.048 109.658 120.341 111.951 120.341 114.775V125.007C120.341 127.83 118.048 130.123 115.225 130.123C112.402 130.123 110.109 127.83 110.109 125.007H99.8762C99.8762 133.471 106.761 140.356 115.225 140.356C123.69 140.356 130.575 133.471 130.575 125.007V114.775C130.574 106.311 123.689 99.4257 115.225 99.4257Z"
        fill="#EEF4FF"
      />
      <path
        d="M115.225 130.124C112.402 130.124 110.109 127.831 110.109 125.008H99.8762C99.8762 133.472 106.761 140.357 115.225 140.357C123.69 140.357 130.575 133.472 130.575 125.008V114.775H120.342V125.007C120.341 127.831 118.048 130.124 115.225 130.124Z"
        fill="#D9E6FC"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_185">
        <rect width="230" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
