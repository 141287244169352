//eslint-ignore

export const NoSesame = ({ width = 32, height = 32, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 248 248"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_417_231)">
      <path
        d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
        fill="#DD455B"
      />
      <path
        d="M230 115C230 112.779 229.93 110.575 229.806 108.385C207.631 86.2104 186.291 64.8717 186.218 64.8006L157.524 99.8389L115.225 57.5193L96.2417 81.118C96.1809 81.0572 80.0053 64.882 79.8527 64.7344C79.061 63.9107 58.8768 108.991 58.8768 108.991L62.0801 112.385L94.6551 144.943C96.2205 148.918 101.226 156.337 101.226 156.337L62.0801 117.165L44.6753 165.188C44.768 165.285 82.1108 202.628 109.344 229.86C111.219 229.951 113.104 230 115 230C178.513 230 230 178.513 230 115Z"
        fill="#B2004F"
      />
      <path
        d="M115.225 110.333C119.379 110.605 137.54 107.076 137.54 92.7535C137.54 79.2312 120.356 62.9557 118.429 60.9127L115.225 57.5189L112.021 60.9127C110.094 62.9557 92.9099 79.2307 92.9099 92.7535C92.9099 105.527 107.133 109.815 115.225 110.333Z"
        fill="#F8E997"
      />
      <path
        d="M115.225 119.217C111.07 118.945 92.9099 122.473 92.9099 136.796C92.9099 150.319 110.094 166.594 112.021 168.637L115.225 172.031L118.429 168.637C120.356 166.594 137.54 150.319 137.54 136.796C137.54 124.023 123.317 119.735 115.225 119.217Z"
        fill="#91776F"
      />
      <path
        d="M137.54 92.7535C137.54 79.2312 120.356 62.9557 118.429 60.9127L115.225 57.5189V110.333C119.379 110.605 137.54 107.076 137.54 92.7535Z"
        fill="#EAAD44"
      />
      <path
        d="M137.54 136.796C137.54 124.023 123.317 119.735 115.225 119.217V172.031L118.429 168.637C120.356 166.594 137.54 150.319 137.54 136.796Z"
        fill="#674D55"
      />
      <path
        d="M171.573 108.991L168.37 112.385L165.167 108.991C163.237 106.947 146.348 88.6576 146.348 75.1367C146.348 62.2324 160.844 57.9749 168.37 57.5284C178.971 58.0924 190.14 63.9724 190.382 74.6141C190.757 85.6464 179.07 101.05 171.573 108.991Z"
        fill="#91776F"
      />
      <path
        d="M190.391 154.413C190.391 154.816 190.376 155.212 190.35 155.599C189.493 167.579 175.66 171.59 168.37 172.022C157.891 171.464 146.349 165.659 146.349 154.413C146.349 140.892 163.238 122.603 165.167 120.559L168.37 117.165L171.573 120.559C173.502 122.603 190.391 140.892 190.391 154.413Z"
        fill="#F8E997"
      />
      <path
        d="M171.573 108.991L168.37 112.385V57.5284C178.971 58.0924 190.14 63.9724 190.382 74.6141C190.757 85.6464 179.07 101.05 171.573 108.991Z"
        fill="#674D55"
      />
      <path
        d="M190.391 154.413C190.391 154.816 190.377 155.212 190.35 155.599C189.493 167.579 175.661 171.59 168.37 172.022V117.165L171.574 120.559C173.502 122.603 190.391 140.892 190.391 154.413Z"
        fill="#EAAD44"
      />
      <path
        d="M84.1016 75.1367C84.1016 88.6576 67.2125 106.947 65.2834 108.991L62.0801 112.385L58.8767 108.991C56.9476 106.947 40.0586 88.6576 40.0586 75.1367C40.0586 62.2324 54.5544 57.9749 62.0801 57.5284C72.9342 58.1059 84.1016 64.1232 84.1016 75.1367Z"
        fill="#91776F"
      />
      <path
        d="M84.1016 154.413C84.1016 154.816 84.0867 155.212 84.0606 155.599C83.2032 167.579 69.3708 171.59 62.0805 172.022C51.6009 171.464 40.0586 165.659 40.0586 154.413C40.0586 140.892 56.9476 122.603 58.8767 120.559L62.0801 117.165L65.2834 120.559C67.2125 122.603 84.1016 140.892 84.1016 154.413Z"
        fill="#F8E997"
      />
      <path
        d="M84.1016 75.1367C84.1016 88.6576 67.2125 106.947 65.2834 108.991L62.0801 112.385V57.5284C72.9657 58.1077 84.1016 64.1448 84.1016 75.1367Z"
        fill="#674D55"
      />
      <path
        d="M84.1016 154.413C84.1016 154.816 84.0868 155.212 84.0606 155.599C83.2032 167.579 69.3708 171.59 62.0806 172.022V117.165L65.2839 120.559C67.2126 122.603 84.1016 140.892 84.1016 154.413Z"
        fill="#EAAD44"
      />
      <circle cx="115" cy="115" r="110" stroke="black" strokeWidth="10" />
      <line
        x1="35.7109"
        y1="32.649"
        x2="184.711"
        y2="197.649"
        stroke="black"
        strokeWidth="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_231">
        <rect width="230" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
