import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ITheme, SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppDispatch } from 'hooks/store';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-tooltip';
import { updateTheme } from 'store/themes/actions';
import { ApiThemeState } from 'store/themes/types';

import { ChangeColorButton, ColorPlate } from '../UI';

type ColorName = Exclude<keyof ITheme, 'colors' | 'fontSize'>;

interface ColorSectionProps {
  setPickColorOpen: SetStateType<boolean>;
  colorValue: ITheme[ColorName];
  colorName: string;
  setTheme: SetStateType<ApiThemeState | undefined>;
  activeChooseTheme: ApiThemeState | undefined;
}

export const ColorSection = ({
  setPickColorOpen,
  colorValue,
  colorName,
  setTheme,
  activeChooseTheme,
}: ColorSectionProps) => {
  const dispatch = useAppDispatch();

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div
          className={classNames(
            colorName === 'mainElementsEstablishmentNameColor' && 'border-b pb-4 mb-3',
          )}
        >
          <div className="flex items-center space-x-3">
            <ColorPlate color={colorValue} />
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              <FormattedMessage id={colorName} />
            </h3>
          </div>

          <div className="mt-2 flex-col xl:flex-row sm:flex sm:items-start sm:justify-between">
            <div className="max-w-xl text-sm text-gray-500">
              <p>
                <FormattedMessage id={colorName + 'Description'} />
              </p>
            </div>
            <div className="mt-3 w-full xl:w-auto justify-end xl:justify-start xl:ml-6 xl:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
              <ChangeColorButton
                setPickColorOpen={setPickColorOpen}
                theme={activeChooseTheme?.colors}
                id={activeChooseTheme?.id}
                active={activeChooseTheme?.status === 1}
                choosen={colorValue}
                setTheme={setTheme}
                colorName={colorName}
                disabled={activeChooseTheme?.type === 2}
              />
            </div>
          </div>
        </div>
        {colorName === 'mainElementsEstablishmentNameColor' && (
          <div className="w-auto flex justify-between items-center">
            <div>
              <FormattedMessage id="fontSize" />
            </div>
            <div className="flex items-stretch border rounded-lg">
              {activeChooseTheme?.type === 2 && (
                <Tooltip
                  anchorSelect={'.change-color-btn-' + colorName + '-font-minus'}
                  style={{
                    backgroundColor: 'rgb(255, 255, 255)',
                    color: '#000',
                    border: '1px solid #d1d5db',
                    opacity: 1,
                  }}
                  place="top"
                  id="cannot-edit-theme"
                  classNameArrow="border border-gray-300 border-t-0 border-l-0"
                >
                  <FormattedMessage id="cannotEditTheme" />
                </Tooltip>
              )}
              <button
                disabled={activeChooseTheme?.type === 2}
                className={classNames(
                  'p-2 disabled:cursor-not-allowed',
                  'change-color-btn-' + colorName + '-font-minus',
                )}
                onClick={() => {
                  if ((activeChooseTheme?.colors.fontSize || 36) > 1) {
                    dispatch(
                      updateTheme(activeChooseTheme?.id, {
                        colors: {
                          ...activeChooseTheme?.colors,
                          fontSize: (activeChooseTheme?.colors.fontSize || 36) - 1,
                        },
                      }),
                    );
                    setTheme((prev) => {
                      if (prev) {
                        const newTheme: ApiThemeState = {
                          ...prev,
                          colors: {
                            ...prev.colors,
                            fontSize: (activeChooseTheme?.colors.fontSize || 36) - 1,
                          },
                        };
                        return newTheme;
                      } else {
                        return undefined;
                      }
                    });
                  }
                }}
              >
                <MinusIcon className="h-4 w-4" />
              </button>
              <input
                type="text"
                disabled={activeChooseTheme?.type === 2}
                className="!py-2 !px-0 !text-base !border-r !border-l !border-t-0 !border-b-0 !border-gray-200 !w-10 !text-center"
                value={activeChooseTheme?.colors.fontSize || 36}
                onChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  const newFont = +target.value.replace(/\D/g, '');
                  if (newFont >= 1) {
                    dispatch(
                      updateTheme(activeChooseTheme?.id, {
                        colors: {
                          ...activeChooseTheme?.colors,
                          fontSize: newFont,
                        },
                      }),
                    );
                    setTheme((prev) => {
                      if (prev) {
                        const newTheme: ApiThemeState = {
                          ...prev,
                          colors: {
                            ...prev.colors,
                            fontSize: newFont,
                          },
                        };
                        return newTheme;
                      } else {
                        return undefined;
                      }
                    });
                  }
                }}
              />
              <button
                disabled={activeChooseTheme?.type === 2}
                className={classNames(
                  'p-2 disabled:cursor-not-allowed',
                  'change-color-btn-' + colorName + '-font-plus',
                )}
                onClick={() => {
                  dispatch(
                    updateTheme(activeChooseTheme?.id, {
                      colors: {
                        ...activeChooseTheme?.colors,
                        fontSize: (activeChooseTheme?.colors.fontSize || 36) + 1,
                      },
                    }),
                  );
                  setTheme((prev) => {
                    if (prev) {
                      const newTheme: ApiThemeState = {
                        ...prev,
                        colors: {
                          ...prev.colors,
                          fontSize: (activeChooseTheme?.colors.fontSize || 36) + 1,
                        },
                      };
                      return newTheme;
                    } else {
                      return undefined;
                    }
                  });
                }}
              >
                <PlusIcon className="h-4 w-4" />
              </button>
              {activeChooseTheme?.type === 2 && (
                <Tooltip
                  anchorSelect={'.change-color-btn-' + colorName + '-font-plus'}
                  style={{
                    backgroundColor: 'rgb(255, 255, 255)',
                    color: '#000',
                    border: '1px solid #d1d5db',
                    opacity: 1,
                  }}
                  place="top"
                  id="cannot-edit-theme"
                  classNameArrow="border border-gray-300 border-t-0 border-l-0"
                >
                  <FormattedMessage id="cannotEditTheme" />
                </Tooltip>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
