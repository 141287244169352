import { Switch } from '@headlessui/react';
import { labelsIcons } from 'appConstants';
import { Labels, SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { FormattedMessage } from 'react-intl';

interface SwitchLabelProps {
  label: Labels;
  labels: Record<string, boolean>;
  setLabels: SetStateType<Record<string, boolean>>;
}

export const SwitchLabel = ({ label, labels, setLabels }: SwitchLabelProps) => {
  return (
    <div className="w-full flex items-center justify-between px-4 py-2 bg-gray-100 rounded-lg">
      <div className="flex items-center">
        {labelsIcons[label].large}
        <FormattedMessage id={label} />
      </div>

      <Switch
        data-testId={'cypress-label-' + label}
        checked={labels[label]}
        onChange={() => {
          setLabels({ ...labels, [label]: !labels[label] });
        }}
        className={classNames(
          labels[label] ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            labels[label] ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
    </div>
  );
};
