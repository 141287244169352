import { FormattedMessage } from 'react-intl';

interface AddItemProgressProps {
  activeStep: number;
}

export const AddItemProgress = ({ activeStep }: AddItemProgressProps) => {
  const steps = [
    {
      name: <FormattedMessage id="name" />,
      description: <FormattedMessage id="nameSub" />,
      id: 0,
    },
    {
      name: <FormattedMessage id="description" />,
      description: <FormattedMessage id="descriptionSub" />,
      id: 1,
    },
    {
      name: <FormattedMessage id="mainInfo" />,
      description: <FormattedMessage id="mainInfoSub" />,
      id: 2,
    },
    {
      name: <FormattedMessage id="availability" />,
      description: <FormattedMessage id="availabilitySub" />,
      id: 3,
    },
    {
      name: <FormattedMessage id="options" />,
      description: <FormattedMessage id="optionsSub" />,
      id: 4,
    },
    {
      name: <FormattedMessage id="suggestions" />,
      description: <FormattedMessage id="suggestionsSub" />,
      id: 5,
    },
    {
      name: <FormattedMessage id="photo" />,
      description: <FormattedMessage id="photoSub" />,
      id: 6,
    },
  ];

  return (
    <div className="block ipad:hidden bg-white p-4 mb-4">
      <p className="text-sm font-medium text-gray-900">{steps[activeStep].name}</p>
      <div className="mt-6" aria-hidden="true">
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div
            className="h-2 rounded-full bg-indigo-600"
            style={{
              width: (activeStep / steps.length) * 100 + '%',
            }}
          />
        </div>
      </div>
    </div>
  );
};
