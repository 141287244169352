import { ReactComponent as Logo } from 'assets/logos/digital-menu.logo-03.svg';
import { classNames } from 'helpers/validation';
import { NavigationProp } from 'pages/Layout/Layout';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

interface SidebarProps {
  navigation: NavigationProp[];
  desktopSidebarOpen: boolean;
}

export const Sidebar = ({ navigation, desktopSidebarOpen }: SidebarProps) => {
  const location = useLocation();

  return (
    <div className="flex flex-grow flex-col border-gray-200 bg-white pt-5 pb-4">
      <div className="flex flex-shrink-0 items-center space-y-5 px-[12px]">
        <Logo className="h-[32px] w-[40px]" />
      </div>
      <div className="mt-3 flex flex-grow flex-col">
        <nav
          id="navigation-usetiful-sidebar"
          className="flex-1 space-y-1 bg-white"
          aria-label="Sidebar"
        >
          {navigation.map((item) => (
            <Link
              key={item.name}
              id={item.name + '-usetiful'}
              to={item.href}
              className={classNames(
                item.routes.includes(location.pathname.split('/')[1])
                  ? 'bg-indigo-50 border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-600 md:hover:bg-gray-50 md:hover:text-gray-900',
                'group flex items-center px-3 py-2 text-sm font-medium rtl:border-r-4 ltr:border-l-4',
              )}
            >
              <item.icon
                className={classNames(
                  item.routes.includes(location.pathname.split('/')[1])
                    ? 'text-indigo-500'
                    : 'text-gray-400 md:group-hover:text-gray-500',
                  'rtl:ml-3 ltr:mr-3 flex-shrink-0 h-8 w-8',
                )}
                aria-hidden="true"
              />
              <div
                className={
                  desktopSidebarOpen
                    ? 'truncate transition-[opacity] delay-150 opacity-1'
                    : 'truncate transition-[opacity] delay-150 opacity-0'
                }
              >
                <FormattedMessage id={item.name.toLowerCase()} />
              </div>
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};
