import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

export const AddItemBanner = () => {
  return (
    <div className="rounded-md bg-blue-50 p-4 mb-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div className="ltr:ml-3 rtl:mr-3 flex-1 ipad:flex ipad:justify-between">
          <p className="text-sm text-blue-700">
            <FormattedMessage id="changeDataLater" />
          </p>
        </div>
      </div>
    </div>
  );
};
