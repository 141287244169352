export const Seeds = ({ width = 32, height = 32, className = '' }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 231 231"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="230" height="230" rx="115" fill="#A1D45D" />
      <path
        d="M58 189L109.5 230C154.16 229.872 175.348 219.936 206.5 185C226.461 152.698 233.15 133.914 229 98L118 20L100.5 118L145 149.5L39 98L58 189Z"
        fill="#71A52C"
      />
      <path
        d="M193.139 78.7252C187.604 82.5496 181.401 87.1425 175.571 91.7175C165.619 99.5629 154.843 109.338 148.169 116.576C138.178 127.442 132.073 137.378 129.698 146.689C129.208 148.637 129.168 149.155 129.149 152.318C129.149 155.392 129.208 155.982 129.6 157.322C131.367 163.327 136.352 168.063 145.068 172.03C155.098 176.587 163.166 177.231 169.938 173.996C174.119 171.994 178.299 168.045 181.676 162.898C189.841 150.442 194.356 132.446 196.103 105.496C196.437 100.045 196.672 81.9956 196.437 78.7431L196.28 76.545L193.139 78.7252ZM183.56 98.5443C184.129 98.7945 184.581 99.1698 184.895 99.6702C185.366 100.403 185.385 100.564 185.385 103.369C185.385 104.978 185.287 107.623 185.189 109.249C183.835 129.676 179.124 149.781 173.274 160.217C172.116 162.308 171.527 162.809 170.095 162.987C168.406 163.202 166.699 162.022 166.444 160.468C166.306 159.735 166.404 159.467 167.582 157.179C169.054 154.32 170.507 150.853 171.547 147.761C175.551 135.788 178.241 119.704 178.692 105.317C178.849 100.171 178.928 99.7595 179.831 99.0268C180.36 98.5979 181.46 98.1869 182.127 98.169C182.441 98.169 183.089 98.3298 183.56 98.5443Z"
        fill="#3E2E2E"
      />
      <path
        d="M38.0705 100.796C37.8938 105.621 38.0705 120.222 38.3649 125.154C39.896 151.014 44.0769 168.813 51.5163 181.27C56.6983 189.937 62.7048 194.798 69.7319 196.031C71.6359 196.353 75.5617 196.353 77.7405 196.013C81.4896 195.423 85.3761 194.155 89.9693 192.046C98.2527 188.239 102.787 183.932 104.632 178.142C105.339 175.944 105.515 171.744 105.005 168.867C103.768 161.683 99.4108 153.015 92.7762 144.544C87.4764 137.789 81.5485 131.606 72.5781 123.528C64.4517 116.201 56.8553 110.071 47.4924 103.244C44.3517 100.957 38.3845 96.7393 38.2668 96.7393C38.2471 96.7393 38.149 98.5621 38.0705 100.796ZM52.1052 119.739C54.0484 120.883 60.5063 126.173 65.8061 130.945C79.5267 143.293 90.0282 155.553 94.8765 164.864C96.2309 167.473 96.3487 167.83 96.0739 168.778C95.4654 170.815 93.051 171.673 91.0685 170.547C90.4993 170.225 90.1656 169.761 89.2823 168.063C84.4928 158.984 75.228 148.011 62.5085 136.395C57.9349 132.231 53.8325 128.782 49.9852 125.887C48.7682 124.976 47.6494 123.993 47.4924 123.725C47.3353 123.439 47.1979 122.777 47.1979 122.241C47.1979 121.455 47.2961 121.133 47.7868 120.544C48.1205 120.133 48.3953 119.793 48.4345 119.793C48.4542 119.793 48.8271 119.668 49.259 119.525C50.1619 119.221 51.3789 119.31 52.1052 119.739Z"
        fill="#3E2E2E"
      />
      <path
        d="M114.663 26.2736C100.863 51.4897 92.9333 72.4346 90.6368 89.7695C90.2049 93.0221 90.1657 101.082 90.5386 103.888C91.7949 112.93 95.171 118.524 101.374 121.866C106.202 124.457 113.701 125.673 121.69 125.172C126.872 124.833 130.719 123.993 133.977 122.438C135.43 121.759 135.528 121.669 137.55 119.203C138.688 117.809 140.514 115.683 141.593 114.467C143.792 112.037 143.949 111.769 144.754 108.981C149.151 93.6654 144.145 70.7368 129.679 39.9986C126.479 33.1897 119.099 19 118.745 19C118.706 19 116.861 22.2704 114.663 26.2736ZM119.158 44.2876C119.903 44.6986 120.139 44.9846 120.983 46.593C125.831 55.8145 131.386 70.8798 134.252 82.5139C136.274 90.7346 136.981 95.667 136.981 101.582L137 105.961L136.431 106.64C134.939 108.445 132.014 108.159 130.856 106.104C130.739 105.889 130.601 104.156 130.542 102.083C130.503 100.081 130.307 97.3111 130.13 95.9351C128.403 82.2816 122.809 65.0181 114.839 48.7554C114.034 47.0934 113.976 46.5751 114.545 45.5028C115.369 43.9302 117.469 43.3941 119.158 44.2876Z"
        fill="#3E2E2E"
      />
      <path
        d="M193.139 78.7252C187.604 82.5496 181.401 87.1425 175.571 91.7175C165.619 99.5629 154.843 109.338 148.169 116.576C138.178 127.442 132.073 137.378 129.698 146.689C129.208 148.637 129.168 149.155 129.149 152.318C129.149 155.392 129.208 155.982 129.6 157.322C131.367 163.327 136.352 168.063 145.068 172.03C155.098 176.587 163.166 177.231 169.938 173.996C174.119 171.994 178.299 168.045 181.676 162.898C189.841 150.442 194.356 132.446 196.103 105.496C196.437 100.045 196.672 81.9956 196.437 78.7431L196.28 76.545L193.139 78.7252ZM183.56 98.5443C184.129 98.7945 184.581 99.1698 184.895 99.6701C185.366 100.403 185.385 100.564 185.385 103.369C185.385 104.978 185.287 107.623 185.189 109.249C183.835 129.676 179.124 149.781 173.274 160.217C172.116 162.308 171.527 162.809 170.095 162.987C168.406 163.202 166.699 162.022 166.444 160.468C166.306 159.735 166.404 159.467 167.582 157.179C169.054 154.32 170.507 150.853 171.547 147.761C175.551 135.788 178.241 119.704 178.692 105.317C178.849 100.171 178.928 99.7595 179.831 99.0268C180.36 98.5979 181.46 98.1868 182.127 98.169C182.441 98.169 183.089 98.3298 183.56 98.5443Z"
        fill="#3E2E2E"
      />
      <path
        d="M38.0705 100.796C37.8938 105.621 38.0705 120.222 38.3649 125.154C39.896 151.014 44.0769 168.813 51.5163 181.27C56.6983 189.937 62.7048 194.798 69.7319 196.031C71.6359 196.353 75.5617 196.353 77.7405 196.013C81.4896 195.423 85.3761 194.155 89.9693 192.046C98.2527 188.239 102.787 183.932 104.632 178.142C105.339 175.944 105.515 171.744 105.005 168.867C103.768 161.683 99.4108 153.015 92.7762 144.544C87.4764 137.789 81.5485 131.606 72.5781 123.528C64.4517 116.201 56.8553 110.071 47.4924 103.244C44.3517 100.957 38.3845 96.7393 38.2668 96.7393C38.2471 96.7393 38.149 98.5621 38.0705 100.796ZM52.1052 119.739C54.0484 120.883 60.5063 126.173 65.8061 130.945C79.5267 143.293 90.0282 155.553 94.8765 164.864C96.2309 167.473 96.3487 167.83 96.0739 168.778C95.4654 170.815 93.051 171.673 91.0685 170.547C90.4993 170.225 90.1656 169.761 89.2823 168.063C84.4928 158.984 75.228 148.011 62.5085 136.395C57.9349 132.231 53.8325 128.782 49.9852 125.887C48.7682 124.976 47.6494 123.993 47.4924 123.725C47.3353 123.439 47.1979 122.777 47.1979 122.241C47.1979 121.455 47.2961 121.133 47.7868 120.544C48.1205 120.133 48.3953 119.793 48.4345 119.793C48.4542 119.793 48.8271 119.668 49.259 119.525C50.1619 119.221 51.3789 119.31 52.1052 119.739Z"
        fill="#3E2E2E"
      />
      <path
        d="M114.663 26.2736C100.863 51.4897 92.9333 72.4346 90.6368 89.7695C90.2049 93.0221 90.1657 101.082 90.5386 103.888C91.7949 112.93 95.171 118.524 101.374 121.866C106.202 124.457 113.701 125.673 121.69 125.172C126.872 124.833 130.719 123.993 133.977 122.438C135.43 121.759 135.528 121.669 137.55 119.203C138.688 117.809 140.514 115.683 141.593 114.467C143.792 112.037 143.949 111.769 144.754 108.981C149.151 93.6654 144.145 70.7368 129.679 39.9986C126.479 33.1897 119.099 19 118.745 19C118.706 19 116.861 22.2704 114.663 26.2736ZM119.158 44.2876C119.903 44.6986 120.139 44.9846 120.983 46.593C125.831 55.8145 131.386 70.8798 134.252 82.5139C136.274 90.7346 136.981 95.667 136.981 101.582L137 105.961L136.431 106.64C134.939 108.445 132.014 108.159 130.856 106.104C130.739 105.889 130.601 104.156 130.542 102.083C130.503 100.081 130.307 97.3111 130.13 95.9351C128.403 82.2816 122.809 65.0181 114.839 48.7554C114.034 47.0934 113.976 46.5751 114.545 45.5028C115.369 43.9302 117.469 43.3941 119.158 44.2876Z"
        fill="#3E2E2E"
      />
      <path
        d="M193.139 78.7252C187.604 82.5496 181.401 87.1425 175.571 91.7175C165.619 99.5629 154.843 109.338 148.169 116.576C138.178 127.442 132.073 137.378 129.698 146.689C129.208 148.637 129.168 149.155 129.149 152.318C129.149 155.392 129.208 155.982 129.6 157.322C131.367 163.327 136.352 168.063 145.068 172.03C155.098 176.587 163.166 177.231 169.938 173.996C174.119 171.994 178.299 168.045 181.676 162.898C189.841 150.442 194.356 132.446 196.103 105.496C196.437 100.045 196.672 81.9956 196.437 78.7431L196.28 76.545L193.139 78.7252ZM183.56 98.5443C184.129 98.7945 184.581 99.1698 184.895 99.6701C185.366 100.403 185.385 100.564 185.385 103.369C185.385 104.978 185.287 107.623 185.189 109.249C183.835 129.676 179.124 149.781 173.274 160.217C172.116 162.308 171.527 162.809 170.095 162.987C168.406 163.202 166.699 162.022 166.444 160.468C166.306 159.735 166.404 159.467 167.582 157.179C169.054 154.32 170.507 150.853 171.547 147.761C175.551 135.788 178.241 119.704 178.692 105.317C178.849 100.171 178.928 99.7595 179.831 99.0268C180.36 98.5979 181.46 98.1868 182.127 98.169C182.441 98.169 183.089 98.3298 183.56 98.5443Z"
        fill="#3E2E2E"
      />
      <path
        d="M38.0705 100.796C37.8938 105.621 38.0705 120.222 38.3649 125.154C39.896 151.014 44.0769 168.813 51.5163 181.27C56.6983 189.937 62.7048 194.798 69.7319 196.031C71.6359 196.353 75.5617 196.353 77.7405 196.013C81.4896 195.423 85.3761 194.155 89.9693 192.046C98.2527 188.239 102.787 183.932 104.632 178.142C105.339 175.944 105.515 171.744 105.005 168.867C103.768 161.683 99.4108 153.015 92.7762 144.544C87.4764 137.789 81.5485 131.606 72.5781 123.528C64.4517 116.201 56.8553 110.071 47.4924 103.244C44.3517 100.957 38.3845 96.7393 38.2668 96.7393C38.2471 96.7393 38.149 98.5621 38.0705 100.796ZM52.1052 119.739C54.0484 120.883 60.5063 126.173 65.8061 130.945C79.5267 143.293 90.0282 155.553 94.8765 164.864C96.2309 167.473 96.3487 167.83 96.0739 168.778C95.4654 170.815 93.051 171.673 91.0685 170.547C90.4993 170.225 90.1656 169.761 89.2823 168.063C84.4928 158.984 75.228 148.011 62.5085 136.395C57.9349 132.231 53.8325 128.782 49.9852 125.887C48.7682 124.976 47.6494 123.993 47.4924 123.725C47.3353 123.439 47.1979 122.777 47.1979 122.241C47.1979 121.455 47.2961 121.133 47.7868 120.544C48.1205 120.133 48.3953 119.793 48.4345 119.793C48.4542 119.793 48.8271 119.668 49.259 119.525C50.1619 119.221 51.3789 119.31 52.1052 119.739Z"
        fill="#3E2E2E"
      />
      <path
        d="M114.663 26.2736C100.863 51.4897 92.9333 72.4346 90.6368 89.7695C90.2049 93.0221 90.1657 101.082 90.5386 103.888C91.7949 112.93 95.171 118.524 101.374 121.866C106.202 124.457 113.701 125.673 121.69 125.172C126.872 124.833 130.719 123.993 133.977 122.438C135.43 121.759 135.528 121.669 137.55 119.203C138.688 117.809 140.514 115.683 141.593 114.467C143.792 112.037 143.949 111.769 144.754 108.981C149.151 93.6654 144.145 70.7368 129.679 39.9986C126.479 33.1897 119.099 19 118.745 19C118.706 19 116.861 22.2704 114.663 26.2736ZM119.158 44.2876C119.903 44.6986 120.139 44.9846 120.983 46.593C125.831 55.8145 131.386 70.8798 134.252 82.5139C136.274 90.7346 136.981 95.667 136.981 101.582L137 105.961L136.431 106.64C134.939 108.445 132.014 108.159 130.856 106.104C130.739 105.889 130.601 104.156 130.542 102.083C130.503 100.081 130.307 97.3111 130.13 95.9351C128.403 82.2816 122.809 65.0181 114.839 48.7554C114.034 47.0934 113.976 46.5751 114.545 45.5028C115.369 43.9302 117.469 43.3941 119.158 44.2876Z"
        fill="#3E2E2E"
      />
    </svg>
  );
};
