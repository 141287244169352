import { SetStateType } from 'commonTypes';
import { AddOptionModal } from 'components/Common/AddOptionModal';
import { ChangeOptionsModal } from 'components/Common/ChangeOptionsModal';
import { ChoosenOption } from 'components/Common/ChoosenOption';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EDIT_ITEM_FIELD } from 'store/item/actionsTypes';
import { getOptionsApi } from 'store/options/actions';

interface OptionsFieldNewProps {
  itemCreated: boolean;
  options: string[];
  setOptions: SetStateType<string[]>;
}

export const OptionsFieldNew = ({
  itemCreated,
  options,
  setOptions,
}: OptionsFieldNewProps) => {
  const dispatch = useAppDispatch();

  const [showChooseExisting, setShowChooseExisting] = useState(false);
  const [showAddOptionModal, setShowAddOptionModal] = useState(false);
  const structOptions = useAppSelector((state) => state.options.structOptionsApi);
  const optionsApi = useAppSelector((state) => state.options.optionsApi);

  useEffect(() => {
    if (!optionsApi) {
      dispatch(getOptionsApi());
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: EDIT_ITEM_FIELD,
      payload: {
        field: 'options',
        value: options,
      },
    });
  }, [options]);

  return (
    <div className="flex flex-col w-full mb-5">
      {options?.length > 0 && (
        <div className="flex items-start flex-wrap w-full gap-x-3 gap-y-3 xl:gap-x-4 mb-3">
          {options?.map((option) => {
            return (
              <ChoosenOption
                // @ts-ignore
                key={'add-item-option-' + structOptions[option]?.id}
                // @ts-ignore
                option={structOptions[option]}
              />
            );
          })}
        </div>
      )}

      <div className="flex items-center overflow-auto scrollbar p-1">
        <button
          type="button"
          disabled={itemCreated}
          onClick={() => {
            if (!itemCreated) {
              setShowAddOptionModal(true);
            }
          }}
          className="ltr:mr-3 rtl:ml-3 whitespace-nowrap inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <FormattedMessage id="addOption" />
        </button>

        <button
          type="button"
          disabled={itemCreated}
          onClick={() => {
            if (!itemCreated) {
              setShowChooseExisting(true);
            }
          }}
          className="inline-flex items-center whitespace-nowrap rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <FormattedMessage id="chooseFromExisting" />
        </button>
        <ChangeOptionsModal
          open={showChooseExisting}
          localChoose={options}
          setLocalChoose={setOptions}
          setOpen={setShowChooseExisting}
          addOptions={options}
          setAddOptions={setOptions}
          addToItem={false}
          itemOptions={options}
        />
        <AddOptionModal
          open={showAddOptionModal}
          setOpen={setShowAddOptionModal}
          localChoose={options}
          setLocalChoose={setOptions}
        />
      </div>
    </div>
  );
};
