import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { dataAllergensIcons, defaultLabels, labelsList } from 'appConstants';
import { PriceElement, QuantityState, SetStateType } from 'commonTypes';
import { CategoryDropdown } from 'components/Common/CategoryDropdown';
import { SwitchLabel } from 'components/Common/SwitchLabel';
import { AllergensChooseCover } from 'components/ItemComponents';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EDIT_ITEM_FIELD } from 'store/item/actionsTypes';
import { Categories } from 'store/menu/types';

import { QuantityField } from '../UI';
import { AddPriceBlock } from './AddPriceBlock';

interface SetMainInfoProps {
  changeCategyValue: string;
  setChangeCategyValue: SetStateType<string>;
  categoryList: Categories[];
  itemCreated: boolean;
}

export const SetMainInfo = ({
  changeCategyValue,
  setChangeCategyValue,
  categoryList,
  itemCreated,
}: SetMainInfoProps) => {
  const dispatch = useAppDispatch();
  const [priceValue, setPriceValue] = useState<PriceElement>({
    name: null,
    value: '',
    is_default: null,
    id: 0,
  });
  const [multiplePriceValue, setMultiplePriceValue] = useState<PriceElement[]>([
    {
      name: '',
      value: '',
      is_default: true,
      id: 0,
    },
    {
      name: '',
      value: '',
      is_default: false,
      id: 1,
    },
  ]);
  const [allergens, setAllergens] = useState<any>(null);
  const itemFields = useAppSelector((state) => state.item.addedItem);
  const [multiple, setMultiple] = useState(false);
  const [free, setFree] = useState(false);
  const [labels, setLabels] = useState<Record<string, boolean>>(defaultLabels);
  const [quantity, setQuantity] = useState<QuantityState>({
    value: '',
    amount: '',
  });

  const [isMobile, setIsMobile] = useState(document.body.clientWidth < 640);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(document.body.clientWidth < 640);
    });
  }, []);

  useEffect(() => {
    if (itemFields.price) {
      if (itemFields.price.type === 'single') {
        setPriceValue(itemFields.price.prices[0]);
        setMultiple(false);
        if (itemFields.price.prices[0].value === '-1') {
          setFree(true);
        } else {
          setFree(false);
        }
      } else {
        setMultiplePriceValue(itemFields.price.prices);
        setMultiple(true);
        setFree(false);
      }
    }
    if (itemFields.allergens) {
      setAllergens(itemFields.allergens);
    }
    if (itemFields.labels) {
      setLabels(itemFields.labels);
    }
    if (itemFields.quantity) {
      setQuantity(itemFields.quantity);
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: EDIT_ITEM_FIELD,
      payload: {
        field: 'price',
        value: {
          type: multiple ? 'multiple' : 'single',
          prices: multiple ? multiplePriceValue : [priceValue],
        },
      },
    });
    dispatch({
      type: EDIT_ITEM_FIELD,
      payload: {
        field: 'allergens',
        value: allergens,
      },
    });
    dispatch({
      type: EDIT_ITEM_FIELD,
      payload: {
        field: 'labels',
        value: labels,
      },
    });
    dispatch({
      type: EDIT_ITEM_FIELD,
      payload: {
        field: 'quantity',
        value: quantity,
      },
    });
  }, [priceValue, allergens, multiple, multiplePriceValue, labels, quantity]);

  return (
    <div className="w-full flex justify-center">
      <div className="w-full flex flex-col max-w-2xl">
        <dl className="divide-y divide-gray-200">
          <div className="py-2 sm:grid sm:grid-cols-[150px_1fr] sm:gap-4 sm:py-3 items-center">
            <dt className="text-sm font-medium text-gray-500 truncate">
              <FormattedMessage id="category" />
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-1 sm:mt-0">
              <div className="flex-grow">
                <CategoryDropdown
                  categoryList={categoryList}
                  changeCategyValue={changeCategyValue}
                  setChangeCategyValue={setChangeCategyValue}
                  disabled={itemCreated}
                />
              </div>
            </dd>
          </div>
        </dl>
        <AddPriceBlock
          itemCreated={itemCreated}
          priceValue={priceValue}
          setPriceValue={setPriceValue}
          multiple={multiple}
          setMultiple={setMultiple}
          multiplePriceValue={multiplePriceValue}
          setMultiplePriceValue={setMultiplePriceValue}
          free={free}
          setFree={setFree}
        />
        <QuantityField setQuantity={setQuantity} quantity={quantity} />
        <dl className="divide-y divide-gray-200">
          <div className="py-2 sm:grid sm:grid-cols-[150px_1fr] sm:gap-4 sm:py-3 items-center">
            {isMobile && (
              <div className="rounded-md bg-blue-50 p-4 mb-3">
                <div className="flex mb-2">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      className="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700 mb-3n">
                      Choose the allergens of your dish
                    </p>
                  </div>
                </div>
                <div className="w-full felx flex-col space-y-2">
                  <div className="flex items-center truncate">
                    <div>{dataAllergensIcons[0].iconNoVis}</div>
                    <div className="text-sm text-gray-600 ml-1 overflow-auto scrollbar pb-1">
                      {' '}
                      - Allergen not selected
                    </div>
                  </div>
                  <div className="flex items-center truncate">
                    <div>{dataAllergensIcons[0].iconVis}</div>
                    <div className="text-sm text-gray-600 ml-1 overflow-auto scrollbar pb-1">
                      {' '}
                      - Is there an allergen in your dish
                    </div>
                  </div>
                  <div className="flex items-center truncate">
                    <div>{dataAllergensIcons[0].iconNoAllergen}</div>
                    <div className="text-sm text-gray-600 ml-1 overflow-auto scrollbar pb-1">
                      {' '}
                      - There is no allergen in your dish
                    </div>
                  </div>
                </div>
              </div>
            )}
            <dt className="text-sm font-medium text-gray-500 truncate">
              <FormattedMessage id="allergens" />
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-1 sm:mt-0 overflow-auto scrollbar">
              <div className="flex-grow">
                <div className="flex items-center space-x-3 rtl:space-x-reverse overflow-x-auto mt-3 scrollbar pb-2">
                  {dataAllergensIcons.map((item, index) => {
                    let allergen = '';
                    if (allergens) {
                      if (+allergens[item.name.toLowerCase()] === 0) {
                        allergen = '';
                      } else if (+allergens[item.name.toLowerCase()] === 1) {
                        allergen = item.name.toLowerCase();
                      } else if (+allergens[item.name.toLowerCase()] === 2) {
                        allergen = 'no' + item.name.toLowerCase();
                      }
                    }
                    return (
                      <AllergensChooseCover
                        key={'allergens-choose-' + index}
                        name={item?.name}
                        iconNoAllergen={item?.iconNoAllergen}
                        iconNoVis={item?.iconNoVis}
                        iconVis={item?.iconVis}
                        allergen={allergen}
                        allergens={allergens}
                        setAllergens={setAllergens}
                        disabled={itemCreated}
                        isMobile={isMobile}
                      />
                    );
                  })}
                </div>
              </div>
            </dd>
          </div>
        </dl>
        <dl className="divide-y divide-gray-200">
          <div className="py-2 sm:grid sm:grid-cols-[150px_1fr] sm:gap-4 sm:py-3 items-center">
            <dt className="text-sm font-medium text-gray-500 truncate">
              <FormattedMessage id="labels" />
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-1 sm:mt-0">
              <div className="flex flex-col space-y-2 w-full max-h-96 overflow-auto pr-1 scrollbar">
                {labelsList.map((label) => (
                  <SwitchLabel
                    key={'label-' + label.id}
                    label={label.label}
                    labels={labels}
                    setLabels={setLabels}
                  />
                ))}
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
