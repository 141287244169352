import { AxiosInstance } from 'axios';
import { GPT_LAMBDA_LINK } from 'configure';
import { FetchResponseBody } from 'store/gpt/types';

export const gpt = (
  ApiClient: (
    publicRequest: boolean,
    baseUrl: string,
    idToken: boolean,
  ) => Promise<AxiosInstance>,
) => {
  return {
    fetchResponse: async (body: FetchResponseBody) =>
      (await ApiClient(false, GPT_LAMBDA_LINK, true)).post<any>('', body),
  };
};
