import { defaultToastProps } from 'appConstants';
import { ReactComponent as Logo } from 'assets/logos/digital-menu.logo-03.svg';
import { ReactComponent as LoginImg } from 'assets/static/login.svg';
import { Spinner } from 'components/Common/Spinner';
import {
  ForgotPasswordCode,
  ForgotPasswordInput,
} from 'components/ForgotPasswordComponents';
import {
  RegisterAvailablePassword,
  RegisterPasswordInput,
} from 'components/RegisterComponent';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logout, resetPassword } from 'store/auth/actions';
import { SET_ERROR } from 'store/auth/actionsTypes';

export const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const loading = useAppSelector((state) => state.auth.loading);
  const error = useAppSelector((state) => state.auth.error);

  const [codeSended, setCodeSended] = useState(false);

  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  const [startTimer, setStartTimer] = useState(false);
  const [timer, setTimer] = useState<any>(null);
  const [counter, setCounter] = useState(60);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [avaliblePassword, setAvaliblePassword] = useState({
    length: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    special: false,
  });

  useEffect(() => {
    const newAvaliblePassword = { ...avaliblePassword };
    if (password.length >= 8) {
      newAvaliblePassword.length = true;
    } else {
      newAvaliblePassword.length = false;
    }

    if (password.match(/[A-Z]/)) {
      newAvaliblePassword.upperCase = true;
    } else {
      newAvaliblePassword.upperCase = false;
    }

    if (password.match(/[a-z]/)) {
      newAvaliblePassword.lowerCase = true;
    } else {
      newAvaliblePassword.lowerCase = false;
    }

    if (password.match(/[0-9]/)) {
      newAvaliblePassword.number = true;
    } else {
      newAvaliblePassword.number = false;
    }

    if (password.match(/[!@#$%^&*(),_.?":{}|<>-]/)) {
      newAvaliblePassword.special = true;
    } else {
      newAvaliblePassword.special = false;
    }

    setAvaliblePassword(newAvaliblePassword);
  }, [password]);

  useEffect(() => {
    dispatch({
      type: SET_ERROR,
      payload: null,
    });
    return () => {
      dispatch({
        type: SET_ERROR,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (error) {
      toast(error, {
        ...defaultToastProps,
        type: 'error',
        toastId: 'forgot-password-error',
      });
    }
  }, [error]);

  useEffect(() => {
    if (startTimer && !timer) {
      setTimer(
        setInterval(() => {
          setCounter((time) => time - 1);
        }, 1000),
      );
    } else {
      clearInterval(timer);
      setTimer(null);
      setCounter(60);
    }
  }, [startTimer]);

  useEffect(() => {
    if (counter <= 0) {
      setStartTimer(false);
    }
  }, [counter]);

  const handleSubmitPassword = () => {
    if (!code.trim()) {
      toast('The code cannot be empty', {
        ...defaultToastProps,
        type: 'error',
      });
    } else if (!password.trim()) {
      toast('The password cannot be empty', {
        ...defaultToastProps,
        type: 'error',
      });
    } else if (!email.trim()) {
      toast('The email cannot be empty', {
        ...defaultToastProps,
        type: 'error',
      });
    } else if (password !== confirmPassword) {
      toast("Passwords don't match", {
        ...defaultToastProps,
        type: 'error',
      });
    } else if (Object.values(avaliblePassword).includes(false)) {
      toast('The password is not strong enough', {
        ...defaultToastProps,
        type: 'error',
      });
    } else {
      dispatch(
        resetPassword({
          email,
          setStartTimer,
          setCodeSended,
          codeSended,
          password,
          code,
          history,
        }),
      );
    }
  };

  return (
    <div className="min-h-screen w-full flex">
      <div className="min-h-full lg:w-1/3 w-0 bg-primary flex items-center justify-center">
        <LoginImg className="w-4/5" />
      </div>
      <div className="min-h-full lg:w-2/3 w-full bg-gray-50">
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Logo className="mx-auto h-12 w-auto" />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Recover password
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Or {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
              <Link
                to="/login"
                onClick={() => dispatch(logout(history))}
                className="font-medium text-indigo-600 md:hover:text-indigo-500"
              >
                sign in to your account
              </Link>
            </p>
          </div>

          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="mx-4 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form className="space-y-6" onSubmit={() => {}}>
                <ForgotPasswordInput email={email} setEmail={setEmail} />
                <div className="mt-3">
                  <ForgotPasswordCode
                    codeSended={codeSended}
                    code={code}
                    setCode={setCode}
                    startTimer={startTimer}
                    email={email}
                    setCodeSended={setCodeSended}
                    setStartTimer={setStartTimer}
                    counter={counter}
                  />
                </div>

                {codeSended && (
                  <>
                    <div>
                      <RegisterPasswordInput
                        label={'Password'}
                        value={password}
                        setValue={setPassword}
                        id={'register-password'}
                        name={'password'}
                      />
                      <RegisterAvailablePassword avaliblePassword={avaliblePassword} />
                    </div>

                    <div className="space-y-1">
                      <RegisterPasswordInput
                        label={'Confirmed Password'}
                        value={confirmPassword}
                        setValue={setConfirmPassword}
                        id={'register-confirm-password'}
                        name={'confirm-password'}
                      />
                    </div>
                    <button
                      disabled={loading}
                      type="button"
                      onClick={handleSubmitPassword}
                      className="rounded-md w-full bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {loading ? <Spinner color="bg-white" /> : 'Confirm'}
                    </button>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
