import { API } from 'api';
import { defaultToastProps } from 'appConstants';
import { SetStateType } from 'commonTypes';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { store } from 'store';
import { AppThunk } from 'store/globalTypes';

import {
  ERROR_REQUEST_QR_CODE,
  ERROR_REQUEST_QR_CODE_LOCAL,
  START_REQUEST_QR_CODE,
  START_REQUEST_QR_CODE_LOCAL,
  SUCCESS_REQUEST_QR_CODE,
  SUCCESS_REQUEST_QR_CODE_LOCAL,
} from './actionsTypes';
import {
  CreateUpdateQRCodeAction,
  GetQRCodeAction,
  QRCodeState,
  UpdateQRCodeProps,
} from './types';

export const checkAvailabilityLink =
  ({
    link,
    setAvalibleLink,
  }: {
    link: string;
    setAvalibleLink: SetStateType<boolean | null>;
  }) =>
  async () => {
    API.v1.publicRequest
      .checkAvailabilityLink(link)
      .then(({ data }) => {
        setAvalibleLink(data);
      })
      .catch((error) => {
        toast(error.message, {
          ...defaultToastProps,
          type: 'error',
        });
      });
  };

export const getQRCode: AppThunk = () => async (dispatch: Dispatch<GetQRCodeAction>) => {
  dispatch({ type: START_REQUEST_QR_CODE });
  API.v1.qrcode
    .getQRCode()
    .then(({ data }) => {
      dispatch({ type: SUCCESS_REQUEST_QR_CODE, payload: data });
    })
    .catch((error) => {
      toast(error.message, {
        ...defaultToastProps,
        type: 'error',
      });
      dispatch({ type: ERROR_REQUEST_QR_CODE, payload: error.message });
    });
};

export const updateQRCode: AppThunk =
  ({ link, id, setOpen, setDiscardClick }: UpdateQRCodeProps) =>
  async (dispatch: Dispatch<CreateUpdateQRCodeAction>) => {
    dispatch({ type: START_REQUEST_QR_CODE_LOCAL });
    API.v1.qrcode
      .updateQRCode(id, { link })
      .then(({ data }) => {
        setOpen(false);
        setDiscardClick(0);
        toast('QR-code link successfully saved', {
          ...defaultToastProps,
          type: 'success',
        });
        const QRCodeOld = store.getState().qr.data;
        if (!QRCodeOld) {
          // @ts-ignore
          dispatch(getQRCode());
        } else {
          dispatch({ type: SUCCESS_REQUEST_QR_CODE_LOCAL, payload: data });
        }
      })
      .catch((error) => {
        toast(error.message, {
          ...defaultToastProps,
          type: 'error',
        });
        dispatch({
          type: ERROR_REQUEST_QR_CODE_LOCAL,
          payload: error.message,
        });
      });
  };
