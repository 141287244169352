//eslint-ignore

export const NoPea = ({ width = 32, height = 32, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 248 248"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_417_468)">
      <path
        d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
        fill="#A1D45D"
      />
      <path
        d="M228.252 135.064C207.14 113.951 184.842 91.6548 184.921 91.734C185.071 91.8843 142.916 77.8557 143.228 77.8557C143.207 77.8345 129.06 63.6897 129.21 63.8401C129.36 63.9904 47.6686 112.458 45.8799 110.669C46.0208 110.81 50.4398 115.23 50.4677 115.259C50.4677 115.259 46.0208 166.195 45.9145 166.088C45.8083 165.982 86.2339 206.41 109.698 229.874C111.456 229.954 113.223 230 115 230C171.668 230 218.76 189.011 228.252 135.064Z"
        fill="#71A52C"
      />
      <path
        d="M110.61 53.2906L101.379 59.3917H87.5333H73.6874L64.4568 53.2906C46.8535 58.5473 36.458 70.3188 36.458 87.0835C36.458 110.205 55.7452 124.006 87.5338 124.006C119.322 124.006 138.302 110.205 138.302 87.0835C138.302 70.3188 128.214 58.5473 110.61 53.2906Z"
        fill="#EAAD44"
      />
      <path
        d="M138.302 87.0836C138.302 70.3188 128.214 58.5474 110.61 53.2911L101.38 59.3922H87.5337V124.006C119.322 124.006 138.302 110.205 138.302 87.0836Z"
        fill="#E58E40"
      />
      <path
        d="M87.5338 50.1611C78.7938 50.1611 71.1327 51.2976 64.4573 53.2907V68.6219H87.5338H110.61V53.2907C103.935 51.2976 96.2733 50.1611 87.5338 50.1611Z"
        fill="#F2D049"
      />
      <path
        d="M110.61 53.2907C103.935 51.2972 96.2732 50.1611 87.5332 50.1611V68.6223H110.61V53.2907Z"
        fill="#EAAD44"
      />
      <path
        d="M165.993 80.9824L156.762 87.0835H142.917H129.071L119.84 80.9824C102.237 86.2387 92.1487 98.0101 92.1487 114.775C92.1487 137.896 111.128 151.697 142.917 151.697C174.705 151.697 193.992 137.896 193.992 114.775C193.992 98.0101 183.597 86.2387 165.993 80.9824Z"
        fill="#F2D049"
      />
      <path
        d="M193.992 114.775C193.992 98.0101 183.597 86.2387 165.993 80.9824L156.762 87.0835H142.917V151.697C174.705 151.697 193.992 137.896 193.992 114.775Z"
        fill="#EAAD44"
      />
      <path
        d="M142.917 77.8529C134.177 77.8529 126.515 78.9894 119.84 80.9824V96.3137H142.917H165.993V80.9824C159.318 78.989 151.657 77.8529 142.917 77.8529Z"
        fill="#F8E997"
      />
      <path
        d="M165.993 80.9824C159.318 78.989 151.657 77.8529 142.917 77.8529V96.3141H165.993V80.9824Z"
        fill="#F2D049"
      />
      <path
        d="M110.61 108.674L101.379 114.775H87.5333H73.6874L64.4568 108.674C46.8535 113.931 36.458 125.702 36.458 142.466C36.458 165.588 55.7452 179.388 87.5338 179.388C119.322 179.388 138.302 165.587 138.302 142.466C138.302 125.702 128.214 113.931 110.61 108.674Z"
        fill="#EAAD44"
      />
      <path
        d="M138.302 142.466C138.302 125.701 128.213 113.93 110.61 108.674L101.379 114.775H87.5332V179.389C119.322 179.389 138.302 165.588 138.302 142.466Z"
        fill="#E58E40"
      />
      <path
        d="M87.5338 105.544C78.7938 105.544 71.1327 106.681 64.4573 108.674V124.005H87.5338H110.61V108.674C103.935 106.681 96.2733 105.544 87.5338 105.544Z"
        fill="#F2D049"
      />
      <path
        d="M110.61 108.674C103.935 106.68 96.2732 105.544 87.5332 105.544V124.006H110.61V108.674Z"
        fill="#EAAD44"
      />
      <circle cx="115" cy="115" r="110" stroke="black" strokeWidth="10" />
      <line
        x1="35.7109"
        y1="32.649"
        x2="184.711"
        y2="197.649"
        stroke="black"
        strokeWidth="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_468">
        <rect width="230" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
