interface RegisterSteperProps {
  stepData: {
    name: string;
    status: string;
  }[];
}

export const RegisterSteper = ({ stepData }: RegisterSteperProps) => {
  return (
    <nav className="flex items-center justify-center mt-6" aria-label="Progress">
      <p className="text-sm font-medium">
        Step {stepData.findIndex((step) => step.status === 'current') + 1} of{' '}
        {stepData.length}
      </p>
      <ol className="ml-8 flex items-center space-x-5">
        {stepData.map((step) => (
          <li key={step.name}>
            {step.status === 'complete' ? (
              <div className="block h-2.5 w-2.5 rounded-full bg-indigo-600 md:hover:bg-indigo-900">
                <span className="sr-only">{step.name}</span>
              </div>
            ) : step.status === 'current' ? (
              <div
                className="relative flex items-center justify-center"
                aria-current="step"
              >
                <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                  <span className="h-full w-full rounded-full bg-indigo-200" />
                </span>
                <span
                  className="relative block h-2.5 w-2.5 rounded-full bg-indigo-600"
                  aria-hidden="true"
                />
                <span className="sr-only">{step.name}</span>
              </div>
            ) : (
              <div className="block h-2.5 w-2.5 rounded-full bg-gray-200 md:hover:bg-gray-400">
                <span className="sr-only">{step.name}</span>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};
