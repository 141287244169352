import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { ISelections } from 'commonTypes';
import { AddOptionModal } from 'components/Common/AddOptionModal';
import { Spinner } from 'components/Common/Spinner';
import {
  EmptyOptions,
  ItemsInOption,
  OptionAddButton,
  OptionHeader,
  OptionItem,
  OptionMenu,
  OptionSelectionsBlock,
} from 'components/OptionsComponents';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Layout } from 'pages/Layout/Layout';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { getRestaurantMenu } from 'store/menu/actions';
import { getOptionsApi, resetOptionsApi } from 'store/options/actions';

interface RouteParams {
  id: string;
}

export const Options = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<RouteParams>();
  const crumbs = [{ name: 'Options', href: '/options', current: true }];
  const menu = useAppSelector((state) => state.menu.menu);
  const loadingMenu = useAppSelector((state) => state.menu.loading);
  const loadingAccount = useAppSelector((state) => state.account.loading);

  const options = useAppSelector((state) => state.options.optionsApi) || [];
  const structOptions = useAppSelector((state) => state.options.structOptionsApi);
  const optionsLoading = useAppSelector((state) => state.options.loading);

  const [showAddOptionModal, setShowAddOptionModal] = useState(false);

  const [isMulti, setIsMulti] = useState(false);

  const [localSelectionsList, setLocalSelectionsList] = useState<ISelections[]>([]);

  useEffect(() => {
    if (options) {
      // @ts-ignore
      setLocalSelectionsList(structOptions[id || options[0]?.id]?.selections);
    }
  }, [id, options, structOptions]);

  useEffect(() => {
    if (menu.length === 0) {
      dispatch(getRestaurantMenu());
    }
    if (!options.length) {
      dispatch(getOptionsApi());
    }
    return () => {
      dispatch(resetOptionsApi());
    };
  }, []);

  useEffect(() => {
    if (options?.length > 0) {
      setIsMulti(
        id
          ? // @ts-ignore
            structOptions[id]?.multi_select
          : // @ts-ignore
            structOptions[options[0]?.id]?.multi_select,
      );
    }
  }, [id, options, structOptions]);

  return (
    <Layout crumbs={crumbs}>
      <AddOptionModal open={showAddOptionModal} setOpen={setShowAddOptionModal} />
      {loadingMenu || loadingAccount || optionsLoading ? (
        <div className="flex justify-center items-start h-screen">
          <Spinner color="bg-primary" />
        </div>
      ) : options?.length ? (
        <div className="w-full h-full flex items-stretch justify-between flex-wrap">
          <div
            className={`w-full lg:w-[39%] xl:w-[49%] 2xl:w-[39%] mb-4 lg:mb-0 h-auto bg-white p-4 rounded-lg overflow-auto scrollbar ${
              id ? 'hidden lg:block' : ''
            }`}
          >
            <h1 className="text-lg font-medium mb-3 text-gray-500">
              <FormattedMessage id="optionList" />
            </h1>
            <ul className="divide-y divide-gray-200 ltr:pr-2 rtl:pl-2">
              {options.map((option) => {
                return (
                  <OptionItem
                    name={option.name}
                    key={'options-list-item-' + option.id}
                    id={option.id}
                    option={option}
                    activeOption={id ? option?.id === id : options[0]?.id === option?.id}
                  />
                );
              })}
            </ul>
            <div className="w-full items-center flex mt-3">
              <div className="w-1/2 h-px bg-gray-300" />
              <OptionAddButton
                onClick={() => {
                  setShowAddOptionModal(true);
                }}
              />
              <div className="w-1/2 h-px bg-gray-300" />
            </div>
          </div>
          <div
            className={`w-full overflow-y-auto overflow-x-hidden scrollbar lg:w-[59%] max-h-full xl:w-[49%] 2xl:w-[59%] bg-white p-4 rounded-lg ${
              !id ? 'hidden lg:block' : ''
            }`}
          >
            <div className="flex w-full items-center justify-between mb-3">
              <div className="flex items-center">
                <ChevronLeftIcon
                  onClick={() => history.push('/options')}
                  className="h-5 w-5 text-gray-400 rtl:rotate-180 md:hover:text-gray-500 transition-all duration-200 ease-in-out cursor-pointer block lg:hidden ltr:mr-3 rtl:ml-3"
                />
                <h1 className="text-lg font-medium text-gray-500">
                  <FormattedMessage id="optionInformation" />
                </h1>
              </div>

              <div className="flex items-center">
                {/* @ts-ignore */}
                {structOptions[id || options[0]?.id]?.status === 1 && (
                  <span className="items-center hidden sm:inline-flex rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800">
                    <FormattedMessage id="active" />
                  </span>
                )}
                {/* @ts-ignore */}
                {structOptions[id || options[0]?.id]?.status === 2 && (
                  <span className="items-center hidden sm:inline-flex rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800">
                    <FormattedMessage id="archived" />
                  </span>
                )}
                <OptionMenu
                  // @ts-ignore
                  status={structOptions[id || options[0]?.id]?.status}
                  // @ts-ignore
                  selections={structOptions[id || options[0]?.id]?.selections}
                  id={id || options[0]?.id}
                  localSelectionsList={localSelectionsList}
                  setLocalSelectionsList={setLocalSelectionsList}
                />
              </div>
            </div>

            <div className="mb-5 w-full">
              <OptionHeader
                id={id || options[0]?.id}
                setIsMulti={setIsMulti}
                // @ts-ignore
                active={structOptions[id] || structOptions[options[0]?.id]}
              />

              <div className="w-full h-px bg-gray-300 mb-3" />

              <OptionSelectionsBlock
                id={id || options[0]?.id}
                isMulti={isMulti}
                activeSelections={localSelectionsList}
              />
            </div>

            <div>
              <h1 className="text-base font-medium mb-3 text-gray-500">
                <FormattedMessage id="items" />
              </h1>
              <div className="grid grid-cols-2 gap-y-10 gap-x-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 xl:gap-x-8">
                {/* @ts-ignore */}
                {structOptions[id || options[0]?.id]?.items?.length > 0 ? (
                  // @ts-ignore
                  structOptions[id || options[0]?.id]?.items?.map((item) => {
                    return <ItemsInOption item={item} key={'item-in-option' + item.id} />;
                  })
                ) : (
                  <h1 className="text-base">
                    <FormattedMessage id="noItems" />
                  </h1>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyOptions setOpen={setShowAddOptionModal} />
      )}
    </Layout>
  );
};
