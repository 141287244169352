import { SetStateType } from 'commonTypes';
import { checkLocalActiveCategory, checkLocalActiveMenu } from 'helpers/menu';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { setActiveCategoryRedux } from 'store/category/actions';
import { EDIT_ITEM_FIELD } from 'store/item/actionsTypes';

import {
  CheckAvailabilityField,
  CreateTextFieldItem,
  OptionsFieldNew,
  PhotoField,
  SaveButtons,
  SetMainInfo,
  SuggestionField,
} from '../Fields';
import { AddItemBanner, AddItemTitle } from '../UI';

interface AddItemStepsProps {
  activeStep: number;
  setActiveStep: SetStateType<number>;
  itemCreated: boolean;
  setItemCreated: SetStateType<boolean>;
}

export const AddItemSteps = ({
  activeStep,
  setActiveStep,
  itemCreated,
  setItemCreated,
}: AddItemStepsProps) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.account);
  const menu = useAppSelector((state) => state.menu.menu);
  const categoryList = useAppSelector(
    (state) => state.category.activeCategory.categoryList,
  );

  const [availabilityDays, setAvailabilityDays] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [availabilityTime, setAvailabilityTime] = useState([
    { timeStart: '00:00', timeEnd: '00:00', id: 0 },
  ]);
  const [everyTime, setEverytime] = useState(true);
  const [everyDay, setEveryday] = useState(true);
  const [optionsLocal, setOptionsLocal] = useState<string[]>([]);
  const [withoutTranslateName, setWithoutTranslateName] = useState(false);
  const [withoutTranslateDescription, setWithoutTranslateDescription] = useState(false);
  const [activeGlobalMenu, setActiveGlobalMenu] = useState('');
  const [changeCategyValue, setChangeCategyValue] = useState('');

  useEffect(() => {
    if (!activeGlobalMenu && !!account?.id) {
      const localeGlobalMenu = checkLocalActiveMenu({ menu, account });
      setActiveGlobalMenu(localeGlobalMenu);
      localStorage.setItem('activeGlobalMenu-' + account?.id, localeGlobalMenu);
    }
  }, [menu, account]);

  useEffect(() => {
    if (activeGlobalMenu) {
      dispatch(
        setActiveCategoryRedux({ globalCategoryId: activeGlobalMenu, backGround: true }),
      );
    }
  }, [activeGlobalMenu]);

  useEffect(() => {
    if (activeGlobalMenu && !!categoryList[activeGlobalMenu]?.length && !!account?.id) {
      const localeActiveCategory = checkLocalActiveCategory({
        activeGlobalMenu,
        account,
        categoryList,
      });
      setChangeCategyValue(localeActiveCategory);
      localStorage.setItem(
        'activeCategory-' + activeGlobalMenu + '-' + account?.id,
        localeActiveCategory,
      );
    }
  }, [activeGlobalMenu, categoryList, account]);

  useEffect(() => {
    if (changeCategyValue) {
      dispatch({
        type: EDIT_ITEM_FIELD,
        payload: {
          field: 'category',
          value: changeCategyValue,
        },
      });
    }
  }, [changeCategyValue]);

  return (
    <div className="flex w-full flex-col justify-self-stretch self-stretch p-4 bg-white">
      <AddItemTitle activeStep={activeStep} />
      <div className="h-full flex flex-col">
        {+activeStep !== 6 && itemCreated && <AddItemBanner />}

        {+activeStep === 0 && account && (
          <CreateTextFieldItem
            itemCreated={itemCreated}
            setParentActiveStep={setActiveStep}
            account={account}
            activeStep={activeStep}
            withoutTranslate={withoutTranslateName}
            setWithoutTranslate={setWithoutTranslateName}
          />
        )}
        {+activeStep === 1 && account && (
          <CreateTextFieldItem
            itemCreated={itemCreated}
            setParentActiveStep={setActiveStep}
            account={account}
            description
            activeStep={activeStep}
            withoutTranslate={withoutTranslateDescription}
            setWithoutTranslate={setWithoutTranslateDescription}
          />
        )}
        {+activeStep === 2 && (
          <SetMainInfo
            changeCategyValue={changeCategyValue}
            setChangeCategyValue={setChangeCategyValue}
            itemCreated={itemCreated}
            categoryList={categoryList[activeGlobalMenu]}
          />
        )}
        {+activeStep === 3 && (
          <CheckAvailabilityField
            itemCreated={itemCreated}
            days={availabilityDays}
            setDays={setAvailabilityDays}
            time={availabilityTime}
            setTime={setAvailabilityTime}
            everyDay={everyDay}
            everyTime={everyTime}
            setEveryday={setEveryday}
            setEverytime={setEverytime}
          />
        )}
        {+activeStep === 4 && (
          <OptionsFieldNew
            options={optionsLocal}
            setOptions={setOptionsLocal}
            itemCreated={itemCreated}
          />
        )}
        {+activeStep === 5 && (
          <SuggestionField
            itemCreated={itemCreated}
            activeGlobalMenu={activeGlobalMenu}
          />
        )}
        {+activeStep === 6 && (
          <PhotoField setActiveStep={setActiveStep} globalCategoryId={activeGlobalMenu} />
        )}

        {activeStep !== 0 && activeStep !== 1 && activeStep !== 6 && (
          <SaveButtons
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setItemCreated={setItemCreated}
            activeGlobalMenu={activeGlobalMenu}
            itemCreated={itemCreated}
          />
        )}
      </div>
    </div>
  );
};
