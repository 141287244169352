import 'react-tooltip/dist/react-tooltip.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-toastify/dist/ReactToastify.css';

import { Auth } from 'aws-amplify';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { selectLanguages } from './appConstants';
import { Spinner } from './components/Common/Spinner';
import { configureAws } from './configure';
import { LOCALES } from './i18n/locale';
import { messages } from './i18n/messages';
import { allPrivetRoutes, authRoutes } from './routes/allRoutes';
import { ProtectedRoute } from './routes/privateRout';

function App() {
  const dispatch = useAppDispatch();
  const [isAuthing, setIsAuthing] = useState(true);
  const authFlag = useAppSelector((state) => state.auth.isAuth);
  const [loading, setLoading] = useState(true);

  const locale = useAppSelector((state) => state.locale.lang);

  useEffect(() => {
    dispatch(configureAws({ setLoading }));
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      setIsAuthing(true);
      try {
        await Auth.currentSession();
      } catch (error) {
        console.log(error);
      }
      setIsAuthing(false);
    };
    checkAuth();
  }, [authFlag]);

  useEffect(() => {
    const rtl = selectLanguages.find((item) => item.oldCode === locale)?.dir === 'rtl';
    document.body.dir = rtl ? 'rtl' : 'ltr';
  }, [locale]);

  if (loading) {
    return <Spinner />;
  }

  return !isAuthing ? (
    <IntlProvider
      messages={messages[locale]}
      locale={locale}
      defaultLocale={LOCALES.ENGLISH}
    >
      <ToastContainer />
      <Router>
        <Switch>
          {authRoutes.map((route, index) => (
            <Route
              path={route.path}
              exact={route.exact}
              key={'auth-route-' + index}
              // @ts-ignore
              render={(props) => <route.component {...props} />}
            />
          ))}

          {allPrivetRoutes.map((route, index) => {
            return (
              <ProtectedRoute
                path={route.path}
                exact={route.exact}
                key={'private-route-' + index}
                // @ts-ignore
                render={(props) => <route.component {...props} />}
              />
            );
          })}
        </Switch>
      </Router>
    </IntlProvider>
  ) : null;
}

export default App;
