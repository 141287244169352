import {
  RESET_API_OPTIONS,
  SET_API_OPTIONS,
  START_OPTIONS_REQUEST,
  START_SUB_OPTIONS_REQUEST,
  STOP_OPTIONS_REQUEST,
  STOP_SUB_OPTIONS_REQUEST,
} from './actionsTypes';
import { OptionsAction, OptionStore } from './types';

const initState: OptionStore = {
  optionsApi: null,
  structOptionsApi: {},
  error: null,
  loading: false,
  subLoading: false,
};

export const optionsReducer = (state: OptionStore = initState, action: OptionsAction) => {
  switch (action.type) {
    case START_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case STOP_OPTIONS_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case START_SUB_OPTIONS_REQUEST:
      return {
        ...state,
        subLoading: true,
      };
    case STOP_SUB_OPTIONS_REQUEST:
      return {
        ...state,
        subLoading: false,
      };
    case SET_API_OPTIONS:
      return {
        ...state,
        optionsApi: action.payload.options,
        structOptionsApi: action.payload.struct,
      };
    case RESET_API_OPTIONS: {
      return {
        ...state,
        optionsApi: null,
        structOptionsApi: {},
      };
    }
    default:
      return state;
  }
};
