import { defaultLabels, labelsIcons } from 'appConstants';
import { FormattedMessage } from 'react-intl';
import { Items } from 'store/menu/types';

interface LabelsPreviewProps {
  dish: Items;
}

export const LabelsPreview = ({ dish }: LabelsPreviewProps) => {
  const labels = dish?.labels || defaultLabels;
  const activeLabels = Object.keys(labels)?.filter(
    (label) => labels[label as keyof typeof defaultLabels],
  );
  if (activeLabels.length > 0) {
    return (
      <div className="flex items-center space-x-1">
        {activeLabels.map((label) => (
          <div key={'label-' + label + '-dish-' + dish?.id}>
            {labelsIcons[label as keyof typeof labelsIcons]?.small}
          </div>
        ))}
      </div>
    );
  } else {
    return <FormattedMessage id="noLabels" />;
  }
};
