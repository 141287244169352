import { Dialog } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { deleteItem } from 'store/item/actions';

import { AppModal } from '../../Common/AppModal';
import { Spinner } from '../../Common/Spinner';

interface DeleteItemModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  item: string;
  globalMenuId: string;
  categoryId: string;
}

export const DeleteItemModal = ({
  open,
  setOpen,
  item,
  globalMenuId,
  categoryId,
}: DeleteItemModalProps) => {
  const cancelButtonRef = useRef(null);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const loading = useAppSelector((state) => state.item.subLoading);

  return (
    <AppModal open={open} size="sm:max-w-3xl">
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 ltr:sm:ml-4 rtl:sm:mr-4 sm:text-left rtl:sm:text-right">
          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
            <FormattedMessage id="deleteItem" />?
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              <FormattedMessage id="deleteItemText" />
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          disabled={loading}
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-red-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-red-500 md:focus:ring-offset-2 sm:w-auto sm:text-sm"
          onClick={() => {
            dispatch(
              deleteItem({
                itemId: item,
                setOpen,
                history,
                globalMenuId,
                categoryId,
              }),
            );
          }}
        >
          {loading ? <Spinner /> : <FormattedMessage id="delete" />}
        </button>
        <button
          disabled={loading}
          type="button"
          className="ltr:mr-3 rtl:ml-3 mt-3 sm:mt-0 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
          ref={cancelButtonRef}
        >
          <FormattedMessage id="cancel" />
        </button>
      </div>
    </AppModal>
  );
};
