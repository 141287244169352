import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { selectLanguages } from 'appConstants';
import { SelectedLanguage, SetStateType } from 'commonTypes';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';

interface RegisterTranslationSelectProps {
  setTransactionLanguages: SetStateType<SelectedLanguage[]>;
  transactionLanguages: SelectedLanguage[];
}

export const RegisterTranslationSelect = ({
  setTransactionLanguages,
  transactionLanguages,
}: RegisterTranslationSelectProps) => {
  return (
    <div className="w-full">
      <label
        htmlFor="transaction-languages"
        className="flex text-sm font-medium text-gray-700 mb-1"
      >
        <div>Translation Languages</div>
        <InformationCircleIcon
          data-tooltip-id="translate-info"
          className="h-5 w-5 text-gray-500 ml-2 cursor-pointer md:hover:text-gray-400"
          aria-hidden="true"
        />
        <Tooltip place="top" id="translate-info">
          <div className="max-w-[100px] md:max-w-[300px]">
            All 70 languages will be translated, but you need to select five main ones
          </div>
        </Tooltip>
      </label>
      <Select
        options={selectLanguages}
        isMulti
        value={transactionLanguages}
        // @ts-ignore
        onChange={(v: SelectedLanguage[]) => {
          if (v.length !== 0 && v.length <= 5) {
            setTransactionLanguages(v);
          }
        }}
        styles={{
          input: (provided, state) => ({
            ...provided,
            border: 'none',
            // @ts-ignore
            '--tw-ring-color': state.isFocused
              ? 'transparent !important'
              : 'transparent !important',
            // @ts-ignore
            '--tw-ring-offset-color': state.isFocused
              ? 'transparent !important'
              : 'transparent !important',
            // @ts-ignore
            '--tw-ring-offset-width': state.isFocused ? '0 !important' : '0 !important',
            // @ts-ignore
            '--tw-ring-offset-shadow': state.isFocused
              ? '0 0 #0000 !important'
              : '0 0 #0000 !important',
          }),
        }}
      />
    </div>
  );
};
