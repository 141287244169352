import { Menu, Transition } from '@headlessui/react';
import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  EllipsisVerticalIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Items } from 'store/menu/types';

interface CategoryDropdownMenuProps {
  setActiveItem: SetStateType<string | null>;
  product: Items;
  setOpenCategoryChange: SetStateType<boolean>;
  setShowChangeStatusModal: SetStateType<boolean>;
  setOpenDeleteModal: SetStateType<boolean>;
}

export const CategoryDropdownMenu = ({
  setActiveItem,
  product,
  setOpenCategoryChange,
  setShowChangeStatusModal,
  setOpenDeleteModal,
}: CategoryDropdownMenuProps) => {
  const history = useHistory();
  return (
    <div className="absolute top-1.5 ltr:right-1.5 rtl:left-1.5 item-action-menu">
      <Menu as="div" className="relative ltr:ml-3 rtl:mr-3 inline-block text-left">
        <div>
          <Menu.Button
            onClick={() => {
              setActiveItem(product.id);
            }}
            className="flex items-center rounded-full bg-white p-2 text-gray-400 md:hover:text-gray-600 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500"
          >
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute ltr:right-0 rtl:left-0 z-10 mt-2 w-36 tiny:w-44 md:w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 md:focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      history.push(`/item/${product.id}`);
                    }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm cursor-pointer',
                    )}
                  >
                    <PencilSquareIcon
                      className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="truncate">
                      <FormattedMessage id="edit" />
                    </span>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setOpenCategoryChange(true);
                    }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm cursor-pointer',
                    )}
                  >
                    <ArrowRightCircleIcon
                      className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="truncate">
                      <FormattedMessage id="move" />
                    </span>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setShowChangeStatusModal(true);
                    }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm cursor-pointer',
                    )}
                  >
                    <ArchiveBoxIcon
                      className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="truncate">
                      <FormattedMessage id="changeStatus" />
                    </span>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setOpenDeleteModal(true);
                    }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm cursor-pointer',
                    )}
                  >
                    <TrashIcon
                      className="ltr:mr-3 rtl:ml-3 h-5 w-5 text-gray-400 md:group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="truncate">
                      <FormattedMessage id="delete" />
                    </span>
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
