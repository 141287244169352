import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { defaultToastProps, roundedOption } from 'appConstants';
import { selectLanguages } from 'appConstants';
import { Languages, SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { ModalChangeLanguage } from 'components/Common/ModalChangeLanguage';
import { Spinner } from 'components/Common/Spinner';
import { fetchLambdaTranslate } from 'helpers/translate';
import { checkFieldNotEmpty, classNames, sortedTranslateObj } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { changeServicesFee } from 'store/account/actions';
import { RESET_NOTIFICATION } from 'store/account/actionsTypes';

import { ExampleRound } from './ExampleRound';
import { RoundedOption, RoundedSelect } from './RoundedSelect';

interface ChangeServiceFeeModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
}

export const ChangeServiceFeeModal = ({ open, setOpen }: ChangeServiceFeeModalProps) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.account);
  const accountLoading = useAppSelector((state) => state.account.subLoading);

  const [selected, setSelected] = useState(
    selectLanguages.find((elem) => elem.value === account?.locale),
  );
  const [servicesFee, setServicesFee] = useState('');
  const [translate, setTranslate] = useState<
    Record<Languages, string> | Record<string, never> | object
  >({});
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [newPercent, setNewPercent] = useState<number | string>(0);

  const [validPercent, setValidPercent] = useState(true);
  const [roundType, setRoundType] = useState<RoundedOption>(roundedOption[0]);
  const [roundDigit, setRoundDigit] = useState<number>(2);

  useEffect(() => {
    if (account?.service_fee_percentage) {
      setNewPercent(account.service_fee_percentage);
    } else {
      setNewPercent(0);
    }
  }, [account, open]);

  useEffect(() => {
    const serviceFee = account?.service_fee_warning?.translations;
    if (account && !account?.service_fee_warning?.source_lang) {
      setSelected(selectLanguages.find((lang) => lang.value === account.locale));
    } else if (account && !!account?.service_fee_warning?.source_lang && serviceFee) {
      setSelected(
        selectLanguages.find(
          (lang) => lang.value === account?.service_fee_warning?.source_lang,
        ),
      );
      setTranslate(serviceFee);
      setServicesFee(serviceFee[account?.service_fee_warning?.source_lang]);
    }
    if (account?.service_fee_round_options?.type) {
      setRoundDigit(account.service_fee_round_options.amount);
      setRoundType(
        roundedOption.find(
          (option) => option.value === account.service_fee_round_options?.type,
        ) || roundedOption[0],
      );
    }
  }, [account, open]);

  const buttonContent = () => {
    if (loading || accountLoading) {
      return <Spinner color="bg-white" />;
    }
    if (activeStep === 0 || activeStep === 1) {
      return <FormattedMessage id="next" />;
    } else if (activeStep === 2) {
      return <FormattedMessage id="save" />;
    }
  };

  const buttonHandler = () => {
    if (activeStep === 0) {
      if (!validPercent) {
        toast('The percentage cannot be more than 100', {
          ...defaultToastProps,
          type: 'error',
        });
        return;
      }
      setActiveStep(1);
    } else if (activeStep === 1) {
      // @ts-ignore
      if (servicesFee.trim() === translate[selected?.value]) {
        setActiveStep(2);
      } else {
        if (servicesFee.trim()) {
          dispatch(
            fetchLambdaTranslate({
              setLoading,
              setData: setTranslate,
              text: servicesFee.trim(),
              // @ts-ignore
              source_lang: selected?.value,
              setActiveStep: setActiveStep,
            }),
          );
        } else {
          dispatch(
            changeServicesFee({
              newText: {},
              setOpen,
              resetFields,
              newPercent,
              rounded: {
                type: roundType.value,
                amount: roundDigit,
              },
            }),
          );
        }
      }
    } else if (activeStep === 2) {
      if (checkFieldNotEmpty(translate)) {
        dispatch(
          changeServicesFee({
            newText: {
              source_lang: selected?.value,
              translations: translate,
            },
            setOpen,
            newPercent,
            resetFields,
            rounded: {
              type: roundType.value,
              amount: roundDigit,
            },
          }),
        );
      } else {
        toast('Translation is required', {
          ...defaultToastProps,
          type: 'error',
        });
      }
    }
  };

  const resetFields = () => {
    setTimeout(() => {
      setActiveStep(0);
      setValidPercent(true);
      dispatch({
        type: RESET_NOTIFICATION,
      });
    }, 250);
  };

  return (
    <AppModal size="sm:max-w-xl" open={open}>
      <div className="flex items-center w-full justify-between mb-3">
        <h1>
          <FormattedMessage id="servicesFee" />
        </h1>
        <XMarkIcon
          onClick={() => {
            if (!loading && !accountLoading) {
              setOpen(false);
              resetFields();
            }
          }}
          className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
        />
      </div>
      {activeStep === 0 && (
        <div>
          <div className="flex items-center justify-between w-full mb-2">
            <ModalChangeLanguage
              selected={selected}
              setSelected={setSelected}
              disabled={loading || accountLoading}
            />
          </div>
          <div className="w-full">
            <div className="mt-0">
              <textarea
                name="name"
                id="item-name"
                disabled={loading || accountLoading}
                value={servicesFee}
                onChange={(e) => {
                  if (!loading && !accountLoading) {
                    setServicesFee(e.target.value);
                    setTranslate({});
                  }
                }}
                data-testId="cypress-fee-text-input"
                className="block overflow-hidden resize-none  w-full box-border scrollbar rounded-md border-gray-300 sm:text-sm"
                placeholder="Services fee text"
              />
            </div>
          </div>
          <div className="rounded-md bg-blue-50 p-4 mt-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">
                  <FormattedMessage id="addPercent" />
                </p>
              </div>
            </div>
          </div>
          <div className="mt-2 flex items-center relative">
            <input
              id="link"
              name="dm-percent"
              value={newPercent}
              onInput={(e) => {
                const target = e.target as HTMLInputElement;
                const numberValue = target.value.replace(/[^0-9.]/g, '');
                if (+numberValue > 100) {
                  setValidPercent(false);
                  setNewPercent(numberValue);
                } else if (+numberValue >= 0 && +numberValue <= 100) {
                  setValidPercent(true);
                  setNewPercent(numberValue);
                }
              }}
              data-testId="cypress-percent-input"
              type="text"
              required
              placeholder="0"
              className={classNames(
                validPercent
                  ? 'focus:ring-indigo-600 ring-gray-300'
                  : 'ring-red-600 focus:ring-red-600',
                'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6',
              )}
            />
          </div>
        </div>
      )}

      {activeStep === 1 && (
        <div className="">
          <div className="text-lg font-medium">
            <FormattedMessage id="roundingTitle" />
          </div>
          <div className="text-sm mt-1">
            <FormattedMessage id="roundingText" />
          </div>
          <div className="flex items-center justify-between mt-3 mb-2 flex-wrap">
            <RoundedSelect selected={roundType} setSelected={setRoundType} />

            <div className="flex items-stretch border rounded-lg mt-3 tiny:mt-0">
              <button
                className="p-2"
                onClick={() => {
                  if (roundDigit >= 1) {
                    setRoundDigit((prev) => prev - 1);
                  }
                }}
              >
                <MinusIcon className="h-4 w-4" />
              </button>
              <input
                type="text"
                className="!py-2 !px-0 !text-base !border-r !border-l !border-t-0 !border-b-0 !border-gray-200 !w-10 !text-center"
                value={roundDigit}
                onChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  const newFont = +target.value.replace(/\D/g, '');
                  if (newFont >= 0 && newFont <= 10) {
                    setRoundDigit(newFont);
                  }
                }}
              />
              <button
                className="p-2"
                onClick={() => {
                  if (roundDigit <= 9) {
                    setRoundDigit((prev) => prev + 1);
                  }
                }}
              >
                <PlusIcon className="h-4 w-4" />
              </button>
            </div>
          </div>

          <ExampleRound type={roundType.value} amount={roundDigit} />
        </div>
      )}

      {activeStep === 2 && (
        <div className="mt-4 flex w-full flex-col space-y-4 max-h-96 overflow-auto scrollbar">
          {Object.keys(
            // @ts-ignore
            sortedTranslateObj({ translate, langs: account?.languages || [] }),
          ).map((item) => {
            return (
              <div
                key={'edit-translate-' + item}
                className="grid grid-cols-[1fr_4fr] first:mt-1 items-center space-x-3 rtl:space-x-reverse w-full ltr:pr-2 rtl:pl-2"
              >
                <h2 className="rtl:text-right">{item.toUpperCase()}</h2>
                <div>
                  <TextareaAutosize
                    minRows={2}
                    className="w-full scrollbar rounded-md border-gray-300 sm:text-sm"
                    placeholder="Services fee text"
                    // @ts-ignore
                    value={translate[item]}
                    disabled={loading || accountLoading}
                    onChange={(e) => {
                      if (!loading && !accountLoading) {
                        setTranslate((prev) => {
                          return {
                            ...prev,
                            [item]: e.target.value,
                          };
                        });
                      }
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="w-full flex justify-end mt-4 first-line:flex items-center rtl:space-x-reverse space-x-4">
        {activeStep !== 0 && (
          <button
            onClick={() => {
              setActiveStep((prev) => +prev - 1);
            }}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="previous" />
          </button>
        )}
        <button
          disabled={loading || accountLoading}
          onClick={() => {
            buttonHandler();
          }}
          data-testId="cypress-fee-save"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
        >
          {buttonContent()}
        </button>
      </div>
    </AppModal>
  );
};
