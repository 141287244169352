import { ReactComponent as NoPhoto } from 'assets/static/no_photo.svg';
import { PreloadComponent } from 'components/ImagePreload/PreloadComponent/PreloadComponent';
import { classNames } from 'helpers/validation';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { SET_NOTIFICATION } from 'store/account/actionsTypes';
import { Items } from 'store/menu/types';

import { ChangePhotoItemCrop, ConfirmDeleteItemPhoto } from '../ChangeModals';

interface ItemImageProps {
  activeGlobalMenu: string;
}

export const ItemImage = ({ activeGlobalMenu }: ItemImageProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [openImage, setOpenImage] = useState(false);
  const [openDeleteImage, setOpenDeleteImage] = useState(false);
  const activeItem = useAppSelector((state) => state.item.activeItem);
  const defaultPhoto = useAppSelector((state) => state.account.account?.default_photo);

  const returnItemPhoto = (product: Items | null) => {
    if (product?.photo?.full) {
      return (
        <PreloadComponent
          baseSrc={product?.photo?.full}
          minimizedSrc={product?.photo?.preloader}
          className="w-full h-[260px] md:h-[400px] overflow-hidden object-cover object-center ipad:group-hover:opacity-75 rounded-lg"
        />
      );
    } else if (defaultPhoto?.links?.full) {
      return (
        <PreloadComponent
          baseSrc={defaultPhoto?.links?.full}
          minimizedSrc={defaultPhoto?.links?.preloader}
          className="w-full h-[260px] md:h-[400px] overflow-hidden object-cover object-center ipad:group-hover:opacity-75 rounded-lg"
        />
      );
    } else {
      return (
        <NoPhoto className="w-full h-[260px] md:h-[400px] overflow-hidden object-cover object-center ipad:group-hover:opacity-75 rounded-lg p-14" />
      );
    }
  };

  return (
    <div
      className={classNames(
        'w-full  overflow-hidden ipad:max-w-[500px] ipad:max-h-[500px] ipad:m-5/12 flex flex-col space-y-4',
      )}
    >
      {returnItemPhoto(activeItem)}

      <button
        onClick={() => setOpenImage(true)}
        className="inline-flex m-1 items-center rounded-md border border-transparent bg-indigo-100 px-6 py-3 text-base font-medium text-indigo-700 md:hover:bg-indigo-200 text-center justify-center"
      >
        <FormattedMessage id="updatePhoto" />
      </button>
      {!activeItem?.photo?.full && (
        <button
          onClick={() => {
            dispatch({
              type: SET_NOTIFICATION,
              payload: 'changeDefaultPhoto',
            });
            history.push('/profile');
          }}
          className="inline-flex m-1 items-center rounded-md border border-transparent bg-indigo-100 px-6 py-3 text-base font-medium text-indigo-700 md:hover:bg-indigo-200 text-center justify-center"
        >
          <FormattedMessage id="updateDefaultPhoto" />
        </button>
      )}
      {activeItem?.photo?.full && (
        <button
          onClick={() => setOpenDeleteImage(true)}
          className="inline-flex m-1 items-center rounded-md border border-transparent bg-red-100 px-6 py-3 text-base font-medium text-red-700 md:hover:bg-red-200 text-center justify-center"
        >
          <FormattedMessage id="deletePhoto" />
        </button>
      )}

      <ConfirmDeleteItemPhoto
        globalCategoryId={activeGlobalMenu}
        open={openDeleteImage}
        setOpen={setOpenDeleteImage}
      />
      <ChangePhotoItemCrop
        globalCategoryId={activeGlobalMenu}
        open={openImage}
        setOpen={setOpenImage}
      />
    </div>
  );
};
