import { AppModal } from '@Common/AppModal';
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { SetStateType } from 'commonTypes';
import { FormattedMessage } from 'react-intl';

interface ConfirmChangeColorModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
  setOpenColorPlate: SetStateType<boolean>;
}

export const ConfirmChangeColorModal = ({
  open,
  setOpen,
  setOpenColorPlate,
}: ConfirmChangeColorModalProps) => {
  const close = () => {
    setOpen(false);
    setTimeout(() => {}, 300);
  };

  return (
    <AppModal open={open}>
      <div className="flex w-full items-center justify-between mb-3">
        <h1>
          <FormattedMessage id="changingColorCurrentTheme" />
        </h1>
        <XMarkIcon
          onClick={close}
          className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
        />
      </div>

      <div className="rounded-md bg-yellow-50 p-4 mb-3">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              <FormattedMessage id="attentionNeeded" />
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                <FormattedMessage id="chnagingColorCurrentThemeWarning" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm text-blue-700">
              <FormattedMessage id="chnagingColorCurrentThemeSuggestion" />
            </p>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-end items-center mt-4">
        <button
          type="button"
          onClick={close}
          className="rounded-md mr-2 bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <FormattedMessage id="cancel" />
        </button>

        <button
          type="button"
          onClick={() => {
            setOpen(false);
            setOpenColorPlate(true);
          }}
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <FormattedMessage id="confirm" />
        </button>
      </div>
    </AppModal>
  );
};
