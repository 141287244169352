import { IPrice, Languages, SetStateType } from 'commonTypes';
import { ChangeEvent, FormEvent } from 'react';
import { RGBColor } from 'react-color';

export const phoneMask = (
  e: InputEvent | FormEvent<HTMLInputElement>,
  setPhone: SetStateType<string>,
) => {
  const target = e.target as HTMLInputElement;
  const x = target.value.replace(/\D/g, '');
  // .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  // const phone = !x[2]
  //   ? x[1]
  //   : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  const phone = x;
  target.value = phone;
  setPhone(phone);
};

export function classNames(...classes: (string | undefined | false | null)[]) {
  return classes.filter(Boolean).join(' ');
}

export const checkFieldNotEmpty = (obj: object) => {
  const filterArr = Object.values(obj).filter((val) => !val.trim());
  return filterArr.length === 0;
};

export const validatePrice = (priceField: IPrice) => {
  let valid = true;
  priceField?.prices?.forEach((price) => {
    if (priceField.type === 'single') {
      if (!+price.value) {
        valid = false;
      }
    } else {
      if (!(price.name?.trim() && +price.value)) {
        valid = false;
      }
    }
  });
  return valid;
};

export const returnNumberPrice = (price: IPrice) => {
  if (price.type === 'multiple') {
    return +(price?.prices.find((price) => price.is_default)?.value || 0);
  } else if (price.type === 'single') {
    return +(price?.prices[0].value || 0);
  } else {
    return 0;
  }
};

export const sortedTranslateObj = ({
  translate,
  langs,
}: {
  translate: Record<Languages, string>;
  langs: Languages[];
}) => {
  // @ts-ignore
  let sortedObj: Record<Languages, string> = {};
  const bufTranslate = { ...translate };
  if (langs?.length > 0) {
    langs.forEach((lang) => {
      sortedObj[lang] = translate[lang];
      delete bufTranslate[lang];
    });
    sortedObj = {
      ...sortedObj,
      ...bufTranslate,
    };
    return sortedObj;
  } else {
    return bufTranslate;
  }
};

export function isEqual<T>(object1: T, object2: T) {
  const props1 = Object.getOwnPropertyNames(object1) as Array<keyof T>;
  const props2 = Object.getOwnPropertyNames(object2) as Array<keyof T>;

  if (props1.length !== props2.length) {
    return false;
  }

  for (let i = 0; i < props1.length; i += 1) {
    const prop = props1[i];
    const bothAreObjects =
      typeof object1[prop] === 'object' && typeof object2[prop] === 'object';

    if (
      (!bothAreObjects && object1[prop] !== object2[prop]) ||
      (bothAreObjects && !isEqual(object1[prop], object2[prop]))
    ) {
      return false;
    }
  }

  return true;
}

export const positiveNumberInput = (e: InputEvent | ChangeEvent, easy = false) => {
  const traget = e.target as HTMLInputElement;
  const value = traget.value;
  if (easy) {
    return value.replace(/[^0-9]/g, '');
  } else {
    return value.replace(/^\.|[^\d.]|\.(?=.*\.)|^0+(?=\d)/g, '');
  }
};

export const positiveNumberValue = (value: string, easy = false) => {
  if (easy) {
    // @ts-ignore
    return Math.trunc(value);
  } else {
    return value.replace(/^\.|[^\d.]|\.(?=.*\.)|^0+(?=\d)/g, '');
  }
};

export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 B';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const rgbaNormalize = (color: RGBColor) => {
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color?.a || 1})`;
};
