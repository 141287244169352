import { FormattedMessage } from 'react-intl';

import {
  EmailField,
  FirstNameField,
  LastNameField,
  PasswordField,
  PhoneField,
} from '../Fields';

export const FirstProfileTab = () => {
  return (
    <div className="w-full flex flex-col">
      <h1 className="font-medium text-xl text-gray-600">
        <FormattedMessage id="profile" />
      </h1>
      <dl className="divide-y divide-gray-200 mb-8">
        <FirstNameField />

        <LastNameField />

        <PhoneField />

        <EmailField />

        <PasswordField />
      </dl>
    </div>
  );
};
