import { OldLanguages } from 'commonTypes';

export const LOCALES: Record<string, OldLanguages> = {
  ENGLISH: 'en-EN',
  RUSSIAN: 'ru-RU',
  HUNGARY: 'hu-HU',
  ALBANIAN: 'sq-SQ',
  AFRIKAANS: 'af-AF',
  ARABIC: 'ar-AR',
  AMHARIC: 'am-AM',
  ARMENIAN: 'hy-HY',
  AZERBAIJANI: 'az-AZ',
  BENGALI: 'bn-BN',
  BOSNIAN: 'bs-BS',
  BULGARIAN: 'bg-BG',
  CATALAN: 'ca-CA',
  CHINESE: 'zh-ZH',
  CROATIAN: 'hr-HR',
  CZECH: 'cs-CS',
  DANISH: 'da-DA',
  DUTCH: 'nl-NL',
  ESTONIAN: 'et-ET',
  FARSI: 'fa-FA',
  FILIPINO: 'tl-TL',
  FINNISH: 'fi-FI',
  FRENCH: 'fr-FR',
  GEORGIAN: 'ka-KA',
  GERMAN: 'de-DE',
  GREEK: 'el-EL',
  GUJARATI: 'gu-GU',
  HAITIAN: 'ht-HT',
  HAUSA: 'ha-HA',
  HEBREW: 'he-HE',
  HINDI: 'hi-HI',
  ICELANDIC: 'is-IS',
  INDONESIAN: 'id-ID',
  IRISH: 'ga-GA',
  ITALIAN: 'it-IT',
  JAPANESE: 'ja-JA',
  KANNADA: 'kn-KN',
  KAZAKH: 'kk-KK',
  KOREAN: 'ko-KO',
  LATVIAN: 'lv-LV',
  LITHUANIAN: 'lt-LT',
  MACEDONIAN: 'mk-MK',
  MALAY: 'ms-MS',
  MALAYALAM: 'ml-ML',
  MALTESE: 'mt-MT',
  MARATHI: 'mr-MR',
  MONGOLIAN: 'mn-MN',
  NORWEGIAN: 'no-NO',
  PASHTO: 'ps-PS',
  POLISH: 'pl-PL',
  PORTUGUESE: 'pt-PT',
  PUNJABI: 'pa-PA',
  ROMANIAN: 'ro-RO',
  SERBIAN: 'sr-SR',
  SINHALA: 'si-SI',
  SLOVAK: 'sk-SK',
  SLOVENIAN: 'sl-SL',
  SOMALI: 'so-SO',
  SPANISH: 'es-ES',
  SWAHILI: 'sw-SW',
  SWEDISH: 'sv-SV',
  TAMIL: 'ta-TA',
  TELUGU: 'te-TE',
  THAI: 'th-TH',
  TURKISH: 'tr-TR',
  UKRANIAN: 'uk-UK',
  URDU: 'ur-UR',
  UZBEK: 'uz-UZ',
  VIETNAMESE: 'vi-VI',
  WELSH: 'cy-CY',
};
