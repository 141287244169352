import { API } from 'api';
import { colorPresets, defaultToastProps } from 'appConstants';
import { ITheme, SetStateType } from 'commonTypes';
import { toast } from 'react-toastify';
import { store } from 'store';
import { AppThunk } from 'store/globalTypes';

import {
  START_THEME_LOADING,
  START_THEME_SUB_LOADING,
  STOP_THEME_LOADING,
  STOP_THEME_SUB_LOADING,
  SUCCESS_GET_THEMES_LIST,
} from './actionsTypes';
import { ApiThemeState, ThemeUpdateRequestBody } from './types';

export const getThemesByAccount: AppThunk = () => async (dispatch) => {
  dispatch({
    type: START_THEME_LOADING,
  });
  API.v1.theme
    .getThemes()
    .then(({ data }) => {
      const complitedData = data.map((theme) => {
        if (theme.type === 1) {
          return theme;
        } else {
          const defaultThemeKey = theme.name.toLocaleLowerCase();
          return {
            ...theme,
            colors: { ...colorPresets[defaultThemeKey as keyof typeof colorPresets] },
          };
        }
      });
      dispatch({
        type: SUCCESS_GET_THEMES_LIST,
        payload: complitedData,
      });
    })
    .catch((e) => {
      toast(e.message, {
        ...defaultToastProps,
        type: 'error',
      });
    })
    .finally(() => {
      dispatch({
        type: STOP_THEME_LOADING,
      });
    });
};

export const createTheme: AppThunk =
  (name: string, colors: ITheme, close: () => void) => async (dispatch) => {
    dispatch({
      type: START_THEME_SUB_LOADING,
    });
    API.v1.theme
      .cretaeTheme({
        name,
        colors,
      })
      .then(({ data }) => {
        const oldThemes = store.getState().themes.themeList;

        dispatch({
          type: SUCCESS_GET_THEMES_LIST,
          payload: [...oldThemes, data],
        });
        close();
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_THEME_SUB_LOADING,
        });
      });
  };

export const deleteTheme: AppThunk =
  (id: string, setOpen: SetStateType<boolean>, setThemeType: SetStateType<string>) =>
  async (dispatch) => {
    dispatch({
      type: START_THEME_SUB_LOADING,
    });
    API.v1.theme
      .deleteTheme(id)
      .then(() => {
        const oldThemeList = store.getState().themes.themeList;
        const newThemeList = oldThemeList.filter((theme) => theme.id !== id);
        dispatch({
          type: SUCCESS_GET_THEMES_LIST,
          payload: newThemeList,
        });
        setOpen(false);
        const activeTheme = newThemeList.find((theme) => theme.status === 1);
        if (activeTheme) {
          setThemeType(activeTheme?.id);
        } else {
          setThemeType(newThemeList[0].id);
        }
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_THEME_SUB_LOADING,
        });
      });
  };

export const updateTheme: AppThunk =
  (id: string, newColor: ThemeUpdateRequestBody) => async (dispatch) => {
    API.v1.theme
      .updateTheme(id, newColor)
      .then(({ data }) => {
        const oldThemesList = store.getState().themes.themeList;
        const newThemesList = oldThemesList.map((theme) => {
          if (theme.id === id) {
            return data;
          } else {
            return theme;
          }
        });
        dispatch({
          type: SUCCESS_GET_THEMES_LIST,
          payload: newThemesList,
        });
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      });
  };

export const updateActiveTheme: AppThunk =
  (
    id: string,
    setOpen: SetStateType<boolean>,
    setActiveTheme: SetStateType<ApiThemeState | undefined>,
    setActiveChooseTheme?: SetStateType<ApiThemeState | undefined>,
    activeChooseTheme?: ApiThemeState | undefined,
  ) =>
  async (dispatch) => {
    dispatch({
      type: START_THEME_SUB_LOADING,
    });
    API.v1.theme
      .updateActiveTheme(id)
      .then(() => {
        const oldData = store.getState().themes.themeList;
        const complitedData: ApiThemeState[] = oldData.map((theme) => {
          if (theme.type === 1) {
            return {
              ...theme,
              status: theme.id === id ? 1 : 2,
            };
          } else {
            const defaultThemeKey = theme.name.toLocaleLowerCase();
            return {
              ...theme,
              colors: { ...colorPresets[defaultThemeKey as keyof typeof colorPresets] },
              status: theme.id === id ? 1 : 2,
            };
          }
        });
        dispatch({
          type: SUCCESS_GET_THEMES_LIST,
          payload: complitedData,
        });
        const newActiveTheme = complitedData.find((theme) => theme.id === id);
        setActiveTheme(newActiveTheme);
        const newActiveChooseTheme = complitedData.find(
          (theme) => theme.id === activeChooseTheme?.id,
        );
        if (setActiveChooseTheme) {
          setActiveChooseTheme(newActiveChooseTheme);
        }
        setOpen(false);
      })
      .catch((e) => {
        toast(e.message, {
          ...defaultToastProps,
          type: 'error',
        });
      })
      .finally(() => {
        dispatch({
          type: STOP_THEME_SUB_LOADING,
        });
      });
  };
