import { XMarkIcon } from '@heroicons/react/24/outline';
import { ReactComponent as GoogleMapsIcon } from 'assets/static/googleMapsIcon.svg';
import { ReactComponent as TripAdvisorIcon } from 'assets/static/tripAdvisorIcon.svg';
import { SetStateType } from 'commonTypes';
import { AppInput } from 'components/Common/AppInput';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { changeReviewsLinks } from 'store/account/actions';
import { RESET_NOTIFICATION } from 'store/account/actionsTypes';

interface ChangeReviewsModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
}

export const ChangeReviewsModal = ({ open, setOpen }: ChangeReviewsModalProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.account.subLoading);
  const account = useAppSelector((state) => state.account.account);

  const [discardClick, setDiscardClick] = useState(0);

  const [newGoogleMaps, setNewGoogleMaps] = useState('');
  const [newTripAdvisor, setNewTripAdvisor] = useState('');

  useEffect(() => {
    if (account) {
      if (account.google_maps) {
        setNewGoogleMaps(account.google_maps);
      } else {
        setNewGoogleMaps('');
      }
      if (account.tripadvisor) {
        setNewTripAdvisor(account.tripadvisor);
      } else {
        setNewTripAdvisor('');
      }
    }
  }, [open]);

  const resetFields = () => {
    setTimeout(() => {
      setDiscardClick(0);
      setNewGoogleMaps('');
      setNewTripAdvisor('');
      dispatch({
        type: RESET_NOTIFICATION,
      });
    }, 300);
  };

  const onHandleClick = () => {
    setDiscardClick(0);
    //TODO Валидация ссылки

    dispatch(
      changeReviewsLinks({
        setOpen,
        data: {
          newGoogleMaps,
          newTripAdvisor,
        },
        resetFields,
      }),
    );
  };

  return (
    <AppModal open={open} size="sm:max-w-md">
      <div className="flex flex-col">
        <div className="flex items-center justify-between w-full mb-3">
          <h1>
            <FormattedMessage id="changeReviewLinkTitle" />
          </h1>
          <XMarkIcon
            onClick={() => {
              setOpen(false);
              resetFields();
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="flex items-center justify-between mb-3">
          <GoogleMapsIcon className="h-7 w-7 mr-3" />
          <AppInput
            label="googleMaps"
            withLabel={false}
            id="googleMaps"
            name="googleMapsLinks"
            type="text"
            placeholder="Your Google maps link"
            className="w-full"
            testId="cypress-googlemaps-input"
            value={newGoogleMaps}
            onChange={(e) => {
              setNewGoogleMaps(e.target.value);
            }}
          />
        </div>

        <div className="flex items-center justify-between mb-3">
          <TripAdvisorIcon className="h-7 w-7 mr-3" />
          <AppInput
            label="tripAdvisor"
            withLabel={false}
            id="tripAdvisor"
            name="tripAdvisorLinks"
            type="text"
            placeholder="Your Tripadvisor link"
            className="w-full"
            value={newTripAdvisor}
            onChange={(e) => {
              setNewTripAdvisor(e.target.value);
            }}
            testId="cypress-tripadvisor-input"
          />
        </div>

        <div className="w-full flex items-center justify-end">
          {(!account?.google_maps &&
            !account?.tripadvisor &&
            newGoogleMaps === '' &&
            newTripAdvisor === '') ||
          (newTripAdvisor === account?.tripadvisor &&
            newGoogleMaps === account?.google_maps) ? (
            <>
              <button
                type="button"
                disabled={loading}
                onClick={() => {
                  setOpen(false);
                  resetFields();
                }}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="cancel" />
              </button>
            </>
          ) : (
            <div className="flex items-center">
              <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
                {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
              </div>
              <div className="flex items-center justify-end w-full sm:w-auto">
                <button
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    if (discardClick === 0) {
                      setDiscardClick(1);
                    } else {
                      setOpen(false);
                      resetFields();
                    }
                  }}
                  className="ltr:mr-3 rtl:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  <FormattedMessage id="discardChanges" />
                </button>
                <button
                  disabled={loading}
                  onClick={onHandleClick}
                  type="button"
                  data-testId="cypress-reviews-save"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
                >
                  {loading ? <Spinner /> : <FormattedMessage id="save" />}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </AppModal>
  );
};
