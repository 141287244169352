import { AddItem } from 'pages/AddItem/AddItem';
import { Customization } from 'pages/Customization/Customization';
import { ForgotPassword } from 'pages/ForgotPassword/ForgotPassword';
import { Item } from 'pages/Item/Item';
import { Login } from 'pages/Login/Login';
import { Menu } from 'pages/Menu/Menu';
import { NotFound } from 'pages/NotFount/NotFound';
import { Options } from 'pages/Option/Options';
import { PrivateRegister } from 'pages/PrivateRegister/PrivateRegister';
import { Profile } from 'pages/Profile/Profile';
import { QRCode } from 'pages/QRCode/QRCode';
import { RequestDemo } from 'pages/RequestDemo/RequestDemo';
import { Redirect } from 'react-router-dom';

export const allPrivetRoutes = [
  //{ path: '/dashboard', exact: true, component: Dashboard },

  { path: '/menu', exact: true, component: Menu },
  { path: '/item/:id', exact: true, component: Item },
  { path: '/add-item', exact: true, component: AddItem },
  { path: '/profile', exact: true, component: Profile },
  { path: '/options', exact: true, component: Options },
  { path: '/qr-code', exact: true, component: QRCode },
  { path: '/customization', exact: true, component: Customization },
  { path: '/options/:id', exact: true, component: Options },

  { path: '/', exact: true, component: () => <Redirect to="/menu" /> },
  { path: '*', exact: false, component: () => <Redirect to="/not-found" /> },
];

export const authRoutes = [
  { path: '/login', exact: true, component: Login },
  { path: '/register', exact: true, component: PrivateRegister },
  { path: '/request-demo', exact: true, component: RequestDemo },
  { path: '/forgot-password', exact: true, component: ForgotPassword },
  { path: '/not-found', exact: true, component: NotFound },
];
