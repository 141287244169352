import './DefaultImage.css';

interface DefaultImageProps {
  src?: string;
  style?: object;
  className?: string;
}

export const DefaultImage = ({ src = '', style, className = '' }: DefaultImageProps) => {
  if (src) {
    return (
      <img
        alt="dish"
        src={src}
        style={{
          objectFit: 'cover',
          backgroundColor: '#f3f4f6',
          backgroundRepeat: 'no-repeat',
          ...style,
        }}
        className={className}
      />
    );
  } else {
    return (
      <div
        className={'default_image-placeholder ' + className}
        style={{
          backgroundSize: '70%',
          backgroundColor: '#f3f4f6',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          ...style,
        }}
      />
    );
  }
};
