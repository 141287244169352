import { configureAddAws, TRANSLATOR_BASE_URL } from 'configure';
import { useAppDispatch } from 'hooks/store';
import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Spinner } from '../components/Common/Spinner';
import { isAuthenticated } from '../helpers/auth';

export const ProtectedRoute = ({ ...propsRoute }) => {
  const dispatch = useAppDispatch();
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    isAuthenticated().then((res) => {
      res ? setIsAuth(true) : setIsAuth(false);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (isAuth) {
      if (!TRANSLATOR_BASE_URL) {
        dispatch(configureAddAws({ setLoading: setIsLoading }));
      }
    }
  }, [isAuth]);

  if (isLoading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Spinner color="bg-primary" />
      </div>
    );
  }

  return isAuth ? <Route {...propsRoute} /> : <Redirect to="/login" />;
};
