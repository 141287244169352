import { XMarkIcon } from '@heroicons/react/24/outline';
import { ReactComponent as Facebook } from 'assets/static/facebookIcon.svg';
import { ReactComponent as Instagram } from 'assets/static/instagramIcon.svg';
import { ReactComponent as Telegram } from 'assets/static/telegram.svg';
import { ReactComponent as TikTok } from 'assets/static/tiktok.svg';
import { ReactComponent as Web } from 'assets/static/webIcon.svg';
import { ReactComponent as WhatsApp } from 'assets/static/whatsapp.svg';
import { SetStateType } from 'commonTypes';
import { AppInput } from 'components/Common/AppInput';
import { AppModal } from 'components/Common/AppModal';
import { Spinner } from 'components/Common/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { changeMediaLinks } from 'store/account/actions';

interface ChangeMediaLinksModalProps {
  open: boolean;
  setOpen: SetStateType<boolean>;
}

export const ChangeMediaLinksModal = ({ open, setOpen }: ChangeMediaLinksModalProps) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.account);
  const loading = useAppSelector((state) => state.account.subLoading);
  const [discardClick, setDiscardClick] = useState(0);

  const [newFacebook, setNewFacebook] = useState('');
  const [newInstagram, setNewInstagram] = useState('');
  const [newWeb, setNewWeb] = useState('');
  const [newTiktok, setNewTiktok] = useState('');
  const [newWhatsappNumber, setNewWhatsappNumber] = useState('');
  const [newTelegram, setNewTelegram] = useState('');

  const resetFields = () => {
    setTimeout(() => {
      setDiscardClick(0);
      setNewFacebook('');
      setNewInstagram('');
      setNewWeb('');
      setNewWhatsappNumber('');
      setNewTiktok('');
      setNewTelegram('');
    }, 300);
  };

  useEffect(() => {
    if (account) {
      if (account?.facebook) {
        setNewFacebook(account.facebook);
      } else {
        setNewFacebook('');
      }
      if (account?.instagram) {
        setNewInstagram(account.instagram);
      } else {
        setNewInstagram('');
      }
      if (account?.web) {
        setNewWeb(account.web);
      } else {
        setNewWeb('');
      }
      if (account?.tiktok) {
        setNewTiktok(account?.tiktok);
      } else {
        setNewTiktok('');
      }
      if (account?.whatsapp) {
        setNewWhatsappNumber(account.whatsapp);
      } else {
        setNewWhatsappNumber('');
      }
      if (account?.telegram) {
        setNewTelegram(account.telegram);
      } else {
        setNewTelegram('');
      }
    }
  }, [open]);

  const onHandleClick = () => {
    setDiscardClick(0);
    //TODO Валидация ссылки

    dispatch(
      changeMediaLinks({
        setOpen,
        body: {
          facebook: newFacebook,
          instagram: newInstagram,
          web: newWeb,
          whatsapp: newWhatsappNumber,
          tiktok: newTiktok,
          telegram: newTelegram,
        },
      }),
    );
  };

  return (
    <AppModal open={open} size="sm:max-w-md">
      <div className="flex flex-col">
        <div className="flex items-center justify-between w-full mb-3">
          <h1>
            <FormattedMessage id="changeMediaLinks" />
          </h1>
          <XMarkIcon
            onClick={() => {
              setOpen(false);
              resetFields();
            }}
            className="h-6 w-6 cursor-pointer text-gray-500 md:hover:text-gray-700"
          />
        </div>

        <div className="flex items-center justify-between mb-3">
          <Facebook className="h-7 w-7 mr-3" />
          <AppInput
            label="facebook"
            withLabel={false}
            id="facebook"
            testId="cypress-facebook-input"
            name="facebiikLinks"
            type="text"
            placeholder="Your Facebook link"
            className="w-full"
            value={newFacebook}
            onChange={(e) => {
              setNewFacebook(e.target.value);
            }}
          />
        </div>

        <div className="flex items-center justify-between mb-3">
          <Instagram className="h-7 w-7 mr-3" />
          <AppInput
            label="instagram"
            withLabel={false}
            testId="cypress-instagram-input"
            id="instagram"
            name="instagramLinks"
            type="text"
            placeholder="Your Instagram link"
            className="w-full"
            value={newInstagram}
            onChange={(e) => {
              setNewInstagram(e.target.value);
            }}
          />
        </div>

        <div className="flex items-center justify-between mb-3">
          <Web className="h-7 w-7 mr-3" />
          <AppInput
            label="web"
            testId="cypress-web-input"
            withLabel={false}
            id="web"
            name="webLink"
            type="text"
            placeholder="Your web link"
            className="w-full"
            value={newWeb}
            onChange={(e) => {
              setNewWeb(e.target.value);
            }}
          />
        </div>

        <div className="flex items-center justify-between mb-3">
          <TikTok className="h-7 w-7 mr-3" />
          <AppInput
            label="tiktok"
            testId="cypress-tiktok-input"
            withLabel={false}
            id="tiktok"
            name="tiktokLink"
            type="text"
            placeholder="Your tiktok link"
            className="w-full"
            value={newTiktok}
            onChange={(e) => {
              setNewTiktok(e.target.value);
            }}
          />
        </div>

        <div className="flex items-center justify-between mb-3">
          <Telegram className="h-7 w-7 mr-3" />
          <AppInput
            label="telegram"
            testId="cypress-telegram-input"
            withLabel={false}
            id="telegram"
            name="telegramLink"
            type="text"
            placeholder="Your telegram link"
            className="w-full"
            value={newTelegram}
            onChange={(e) => {
              setNewTelegram(e.target.value);
            }}
          />
        </div>

        <div className="mb-3">
          <div className="flex items-center justify-between">
            <WhatsApp className="h-7 w-7 mr-3" />
            <div className="w-full">
              <AppInput
                label="web"
                testId="cypress-whatsappNumber-input"
                withLabel={false}
                id="whatsappNumber"
                name="whatsappNumber"
                type="text"
                placeholder="Your Whatsapp number"
                className="w-full"
                value={newWhatsappNumber}
                onChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  const newVal = target.value.replace(/\D/g, '');
                  setNewWhatsappNumber(newVal);
                }}
              />
            </div>
          </div>
          <div className="mt-1 ml-10 text-xs text-primary underline">
            https://wa.me/{newWhatsappNumber || 'phone_number'}
          </div>
          <div className="mt-1 ml-10 flex items-center">
            <div className="mr-1 text-xs font-medium">Example:</div>
            <div className="text-xs text-primary underline">https://wa.me/123456789</div>
          </div>
        </div>

        <div className="w-full flex items-center justify-end">
          <div className="flex items-center">
            <div className="text-xs text-red-500 ltr:mr-2 rtl:ml-2">
              {discardClick === 1 && <FormattedMessage id="approveDiscard" />}
            </div>
            <div className="flex items-center justify-end w-full sm:w-auto">
              <button
                type="button"
                onClick={() => {
                  if (discardClick === 0) {
                    setDiscardClick(1);
                  } else {
                    setOpen(false);
                    resetFields();
                  }
                }}
                className="ltr:mr-3 rtl:ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                <FormattedMessage id="discardChanges" />
              </button>
              <button
                type="button"
                onClick={onHandleClick}
                data-testId="cypress-media-save"
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
              >
                {loading ? <Spinner /> : <FormattedMessage id="save" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </AppModal>
  );
};
