import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { SetStateType } from 'commonTypes';
import { Spinner } from 'components/Common/Spinner';
import { RegisterPhoneCodeSelect } from 'components/RegisterComponent/FormElement/RegisterPhoneCodeSelect';
import { useDebounce } from 'helpers/hooks';
import { phoneMask } from 'helpers/validation';
import { useAppDispatch } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { checkAvailabilityEmail } from 'store/account/actions';
import { changeMainAttr, resendCodeForMainAttr } from 'store/auth/actions';

interface AddNewMainAttrValueProps {
  mode: 'changeEmail' | 'changePhone';
  email: string;
  setEmail: SetStateType<string>;
  phoneCode:
    | {
        code: string;
        name: string;
      }
    | undefined;
  setPhoneCode: SetStateType<
    | {
        code: string;
        name: string;
      }
    | undefined
  >;
  phone: string;
  code: string;
  setCode: SetStateType<string>;
  setPhone: SetStateType<string>;
  codeSended: boolean;
  loading: boolean;
  setLoading: SetStateType<boolean>;
  activeStep: number;
  setActiveStep: SetStateType<number>;
  setCodeSended: SetStateType<boolean>;
}

export const AddNewMainAttrValue = ({
  mode,
  email,
  setEmail,
  phoneCode,
  setPhoneCode,
  phone,
  code,
  setCode,
  setPhone,
  codeSended,
  loading,
  setLoading,
  activeStep,
  setActiveStep,
  setCodeSended,
}: AddNewMainAttrValueProps) => {
  const dispatch = useAppDispatch();
  const [startTimer, setStartTimer] = useState(false);
  const [timer, setTimer] = useState<undefined | NodeJS.Timeout>(undefined);

  const debouncedSearchTerm = useDebounce(email, 300);
  const [avalibleEmail, setAvalibleEmail] = useState<boolean | string | null>(null);

  const [counter, setCounter] = useState(60);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(
        checkAvailabilityEmail({
          email: debouncedSearchTerm,
          setAvalibleEmail,
        }),
      );
    } else {
      setAvalibleEmail(null);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (startTimer && !timer) {
      setTimer(
        setInterval(() => {
          setCounter((time) => time - 1);
        }, 1000),
      );
    } else {
      clearInterval(timer);
      setTimer(undefined);
      setCounter(60);
    }
  }, [startTimer]);

  useEffect(() => {
    if (counter <= 0) {
      setStartTimer(false);
    }
  }, [counter]);

  return (
    <div className="flex flex-col mb-3">
      {mode === 'changeEmail' && (
        <div>
          <label
            htmlFor="new-email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email
          </label>
          <div className="mt-2 flex items-center relative">
            <input
              type="email"
              name="email"
              disabled={loading || codeSended}
              id="new-email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className={`block w-full ${
                avalibleEmail === null
                  ? 'text-gray-900 border-gray-300 placeholder-gray-300 md:focus:border-primary md:focus:ring-primary'
                  : avalibleEmail === true
                  ? 'text-green-900 border-green-300 placeholder-green-300 md:focus:border-green-500 md:focus:ring-green-500'
                  : 'text-red-900 border-red-300 placeholder-red-300 md:focus:border-red-500 md:focus:ring-red-500'
              }  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
              placeholder="you@example.com"
            />
            <div className="pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-3 rtl:pl-3">
              {avalibleEmail === null ? (
                <></>
              ) : avalibleEmail === true ? (
                <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
              ) : (
                <XCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              )}
            </div>
          </div>
          {avalibleEmail === false && avalibleEmail !== null && (
            <p className="mt-2 text-xs text-red-500 truncate">
              Account with this link already exist
            </p>
          )}
          {avalibleEmail !== true &&
            avalibleEmail !== false &&
            avalibleEmail !== null && (
              <p className="mt-2 text-xs text-red-500 truncate">{avalibleEmail}</p>
            )}
          {avalibleEmail === true && avalibleEmail !== null && (
            <p className="mt-2 text-xs text-green-500 truncate">Email available</p>
          )}
        </div>
      )}

      {mode === 'changePhone' && (
        <div className="relative mt-1 rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 flex items-center">
            <label htmlFor="country" className="sr-only">
              <FormattedMessage id="phone" />
            </label>
            <RegisterPhoneCodeSelect
              disabled={loading || codeSended}
              phoneCode={phoneCode}
              setPhoneCode={setPhoneCode}
            />
          </div>
          <input
            type="text"
            value={phone}
            disabled={loading || codeSended}
            //   onChange={(e) => setPhoneNumber(e.target.value)}
            onInput={(e) => {
              phoneMask(e, setPhone);
            }}
            required
            name="phone-number"
            id="phone-number"
            autoComplete="tel-national"
            className="block w-full rounded-md border-gray-300 pl-28 md:focus:border-indigo-500 md:focus:ring-indigo-500 sm:text-sm"
            placeholder="Phone Number"
          />
        </div>
      )}

      <div className="flex items-center w-full mt-3">
        <div className="flex flex-grow items-stretch">
          <label htmlFor="code" className="sr-only">
            <FormattedMessage id="code" />
          </label>
          <input
            type="text"
            name="code"
            value={code}
            disabled={loading}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            id="code"
            className={`${
              codeSended ? 'w-full' : 'w-0 px-0'
            } block transition-all duration-300 ease-in-out rounded-l-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
            placeholder="Your conformation code"
          />
        </div>
        <button
          type="button"
          disabled={
            loading ||
            (mode === 'changeEmail' ? !email.trim() : !phone) ||
            (codeSended && startTimer)
          }
          onClick={() => {
            if (!codeSended && mode === 'changeEmail' && avalibleEmail === true) {
              dispatch(
                changeMainAttr({
                  step: activeStep,
                  setStep: setActiveStep,
                  code,
                  setLoading,
                  setCode,
                  codeSended,
                  setCodeSended,
                  mode: mode === 'changeEmail' ? 'email' : 'phone_number',
                  setStartTimer,
                  email,
                  // @ts-ignore
                  phone: phoneCode?.code + phone?.match(/\d+/g)?.join(''),
                }),
              );
            } else if (!startTimer) {
              dispatch(
                resendCodeForMainAttr({
                  step: activeStep,
                  setLoading,
                  mode: mode === 'changeEmail' ? 'email' : 'phone_number',
                  setStartTimer,
                  email,
                  // @ts-ignore
                  phone: phoneCode?.code + phone?.match(/\d+/g)?.join(''),
                }),
              );
            }
          }}
          className={`${
            codeSended ? 'w-32 rounded-r-md' : 'w-full rounded-md'
          } transition-all disabled:opacity-50 disabled:hover:bg-indigo-600 duration-300 truncate whitespace-nowrap flex items-center justify-center text-center ease-in-out bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
        >
          {loading ? (
            <Spinner color="bg-white" />
          ) : codeSended ? (
            startTimer ? (
              counter + ' s'
            ) : (
              <FormattedMessage id="resendCode" />
            )
          ) : (
            <FormattedMessage id="sendCode" />
          )}
        </button>
      </div>
    </div>
  );
};
