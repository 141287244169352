export const NoSeeds = ({ width = 32, height = 32, className = '' }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 248 248"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" width="230" height="230" rx="115" fill="#A1D45D" />
      <path
        d="M59 189L110.5 230C155.16 229.872 176.348 219.936 207.5 185C227.461 152.698 234.15 133.914 230 98L119 20L101.5 118L146 149.5L40 98L59 189Z"
        fill="#71A52C"
      />
      <path
        d="M194.139 78.7252C188.604 82.5496 182.401 87.1425 176.571 91.7175C166.619 99.5629 155.843 109.338 149.169 116.576C139.178 127.442 133.073 137.378 130.698 146.689C130.208 148.637 130.168 149.155 130.149 152.318C130.149 155.392 130.208 155.982 130.6 157.322C132.367 163.327 137.352 168.063 146.068 172.03C156.098 176.587 164.166 177.231 170.938 173.996C175.119 171.994 179.299 168.045 182.676 162.898C190.841 150.442 195.356 132.446 197.103 105.496C197.437 100.045 197.672 81.9956 197.437 78.7431L197.28 76.545L194.139 78.7252ZM184.56 98.5443C185.129 98.7945 185.581 99.1698 185.895 99.6701C186.366 100.403 186.385 100.564 186.385 103.369C186.385 104.978 186.287 107.623 186.189 109.249C184.835 129.676 180.124 149.781 174.274 160.217C173.116 162.308 172.527 162.809 171.095 162.987C169.406 163.202 167.699 162.022 167.444 160.468C167.306 159.735 167.404 159.467 168.582 157.179C170.054 154.32 171.507 150.853 172.547 147.761C176.551 135.788 179.241 119.704 179.692 105.317C179.849 100.171 179.928 99.7595 180.831 99.0268C181.36 98.5979 182.46 98.1868 183.127 98.169C183.441 98.169 184.089 98.3298 184.56 98.5443Z"
        fill="#3E2E2E"
      />
      <path
        d="M39.0705 100.796C38.8938 105.621 39.0705 120.222 39.3649 125.154C40.896 151.014 45.0769 168.813 52.5163 181.27C57.6983 189.937 63.7048 194.798 70.7319 196.031C72.6359 196.353 76.5617 196.353 78.7405 196.013C82.4896 195.423 86.3761 194.155 90.9693 192.046C99.2527 188.239 103.787 183.932 105.632 178.142C106.339 175.944 106.515 171.744 106.005 168.867C104.768 161.683 100.411 153.015 93.7762 144.544C88.4764 137.789 82.5485 131.606 73.5781 123.528C65.4517 116.201 57.8553 110.071 48.4924 103.244C45.3517 100.957 39.3845 96.7393 39.2668 96.7393C39.2471 96.7393 39.149 98.5621 39.0705 100.796ZM53.1052 119.739C55.0484 120.883 61.5063 126.173 66.8061 130.945C80.5267 143.293 91.0282 155.553 95.8765 164.864C97.2309 167.473 97.3487 167.83 97.0739 168.778C96.4654 170.815 94.051 171.673 92.0685 170.547C91.4993 170.225 91.1656 169.761 90.2823 168.063C85.4928 158.984 76.228 148.011 63.5085 136.395C58.9349 132.231 54.8325 128.782 50.9852 125.887C49.7682 124.976 48.6494 123.993 48.4924 123.725C48.3353 123.439 48.1979 122.777 48.1979 122.241C48.1979 121.455 48.2961 121.133 48.7868 120.544C49.1205 120.133 49.3953 119.793 49.4345 119.793C49.4542 119.793 49.8271 119.668 50.259 119.525C51.1619 119.221 52.3789 119.31 53.1052 119.739Z"
        fill="#3E2E2E"
      />
      <path
        d="M115.663 26.2736C101.863 51.4897 93.9333 72.4346 91.6368 89.7695C91.2049 93.0221 91.1657 101.082 91.5386 103.888C92.7949 112.93 96.171 118.524 102.374 121.866C107.202 124.457 114.701 125.673 122.69 125.172C127.872 124.833 131.719 123.993 134.977 122.438C136.43 121.759 136.528 121.669 138.55 119.203C139.688 117.809 141.514 115.683 142.593 114.467C144.792 112.037 144.949 111.769 145.754 108.981C150.151 93.6654 145.145 70.7368 130.679 39.9986C127.479 33.1897 120.099 19 119.745 19C119.706 19 117.861 22.2704 115.663 26.2736ZM120.158 44.2876C120.903 44.6986 121.139 44.9846 121.983 46.593C126.831 55.8145 132.386 70.8798 135.252 82.5139C137.274 90.7346 137.981 95.667 137.981 101.582L138 105.961L137.431 106.64C135.939 108.445 133.014 108.159 131.856 106.104C131.739 105.889 131.601 104.156 131.542 102.083C131.503 100.081 131.307 97.3111 131.13 95.9351C129.403 82.2816 123.809 65.0181 115.839 48.7554C115.034 47.0934 114.976 46.5751 115.545 45.5028C116.369 43.9302 118.469 43.3941 120.158 44.2876Z"
        fill="#3E2E2E"
      />
      <path
        d="M194.139 78.7252C188.604 82.5496 182.401 87.1425 176.571 91.7175C166.619 99.5629 155.843 109.338 149.169 116.576C139.178 127.442 133.073 137.378 130.698 146.689C130.208 148.637 130.168 149.155 130.149 152.318C130.149 155.392 130.208 155.982 130.6 157.322C132.367 163.327 137.352 168.063 146.068 172.03C156.098 176.587 164.166 177.231 170.938 173.996C175.119 171.994 179.299 168.045 182.676 162.898C190.841 150.442 195.356 132.446 197.103 105.496C197.437 100.045 197.672 81.9956 197.437 78.7431L197.28 76.545L194.139 78.7252ZM184.56 98.5443C185.129 98.7945 185.581 99.1698 185.895 99.6701C186.366 100.403 186.385 100.564 186.385 103.369C186.385 104.978 186.287 107.623 186.189 109.249C184.835 129.676 180.124 149.781 174.274 160.217C173.116 162.308 172.527 162.809 171.095 162.987C169.406 163.202 167.699 162.022 167.444 160.468C167.306 159.735 167.404 159.467 168.582 157.179C170.054 154.32 171.507 150.853 172.547 147.761C176.551 135.788 179.241 119.704 179.692 105.317C179.849 100.171 179.928 99.7595 180.831 99.0268C181.36 98.5979 182.46 98.1868 183.127 98.169C183.441 98.169 184.089 98.3298 184.56 98.5443Z"
        fill="#3E2E2E"
      />
      <path
        d="M39.0705 100.796C38.8938 105.621 39.0705 120.222 39.3649 125.154C40.896 151.014 45.0769 168.813 52.5163 181.27C57.6983 189.937 63.7048 194.798 70.7319 196.031C72.6359 196.353 76.5617 196.353 78.7405 196.013C82.4896 195.423 86.3761 194.155 90.9693 192.046C99.2527 188.239 103.787 183.932 105.632 178.142C106.339 175.944 106.515 171.744 106.005 168.867C104.768 161.683 100.411 153.015 93.7762 144.544C88.4764 137.789 82.5485 131.606 73.5781 123.528C65.4517 116.201 57.8553 110.071 48.4924 103.244C45.3517 100.957 39.3845 96.7393 39.2668 96.7393C39.2471 96.7393 39.149 98.5621 39.0705 100.796ZM53.1052 119.739C55.0484 120.883 61.5063 126.173 66.8061 130.945C80.5267 143.293 91.0282 155.553 95.8765 164.864C97.2309 167.473 97.3487 167.83 97.0739 168.778C96.4654 170.815 94.051 171.673 92.0685 170.547C91.4993 170.225 91.1656 169.761 90.2823 168.063C85.4928 158.984 76.228 148.011 63.5085 136.395C58.9349 132.231 54.8325 128.782 50.9852 125.887C49.7682 124.976 48.6494 123.993 48.4924 123.725C48.3353 123.439 48.1979 122.777 48.1979 122.241C48.1979 121.455 48.2961 121.133 48.7868 120.544C49.1205 120.133 49.3953 119.793 49.4345 119.793C49.4542 119.793 49.8271 119.668 50.259 119.525C51.1619 119.221 52.3789 119.31 53.1052 119.739Z"
        fill="#3E2E2E"
      />
      <path
        d="M115.663 26.2736C101.863 51.4897 93.9333 72.4346 91.6368 89.7695C91.2049 93.0221 91.1657 101.082 91.5386 103.888C92.7949 112.93 96.171 118.524 102.374 121.866C107.202 124.457 114.701 125.673 122.69 125.172C127.872 124.833 131.719 123.993 134.977 122.438C136.43 121.759 136.528 121.669 138.55 119.203C139.688 117.809 141.514 115.683 142.593 114.467C144.792 112.037 144.949 111.769 145.754 108.981C150.151 93.6654 145.145 70.7368 130.679 39.9986C127.479 33.1897 120.099 19 119.745 19C119.706 19 117.861 22.2704 115.663 26.2736ZM120.158 44.2876C120.903 44.6986 121.139 44.9846 121.983 46.593C126.831 55.8145 132.386 70.8798 135.252 82.5139C137.274 90.7346 137.981 95.667 137.981 101.582L138 105.961L137.431 106.64C135.939 108.445 133.014 108.159 131.856 106.104C131.739 105.889 131.601 104.156 131.542 102.083C131.503 100.081 131.307 97.3111 131.13 95.9351C129.403 82.2816 123.809 65.0181 115.839 48.7554C115.034 47.0934 114.976 46.5751 115.545 45.5028C116.369 43.9302 118.469 43.3941 120.158 44.2876Z"
        fill="#3E2E2E"
      />
      <path
        d="M194.139 78.7252C188.604 82.5496 182.401 87.1425 176.571 91.7175C166.619 99.5629 155.843 109.338 149.169 116.576C139.178 127.442 133.073 137.378 130.698 146.689C130.208 148.637 130.168 149.155 130.149 152.318C130.149 155.392 130.208 155.982 130.6 157.322C132.367 163.327 137.352 168.063 146.068 172.03C156.098 176.587 164.166 177.231 170.938 173.996C175.119 171.994 179.299 168.045 182.676 162.898C190.841 150.442 195.356 132.446 197.103 105.496C197.437 100.045 197.672 81.9956 197.437 78.7431L197.28 76.545L194.139 78.7252ZM184.56 98.5443C185.129 98.7945 185.581 99.1698 185.895 99.6701C186.366 100.403 186.385 100.564 186.385 103.369C186.385 104.978 186.287 107.623 186.189 109.249C184.835 129.676 180.124 149.781 174.274 160.217C173.116 162.308 172.527 162.809 171.095 162.987C169.406 163.202 167.699 162.022 167.444 160.468C167.306 159.735 167.404 159.467 168.582 157.179C170.054 154.32 171.507 150.853 172.547 147.761C176.551 135.788 179.241 119.704 179.692 105.317C179.849 100.171 179.928 99.7595 180.831 99.0268C181.36 98.5979 182.46 98.1868 183.127 98.169C183.441 98.169 184.089 98.3298 184.56 98.5443Z"
        fill="#3E2E2E"
      />
      <path
        d="M39.0705 100.796C38.8938 105.621 39.0705 120.222 39.3649 125.154C40.896 151.014 45.0769 168.813 52.5163 181.27C57.6983 189.937 63.7048 194.798 70.7319 196.031C72.6359 196.353 76.5617 196.353 78.7405 196.013C82.4896 195.423 86.3761 194.155 90.9693 192.046C99.2527 188.239 103.787 183.932 105.632 178.142C106.339 175.944 106.515 171.744 106.005 168.867C104.768 161.683 100.411 153.015 93.7762 144.544C88.4764 137.789 82.5485 131.606 73.5781 123.528C65.4517 116.201 57.8553 110.071 48.4924 103.244C45.3517 100.957 39.3845 96.7393 39.2668 96.7393C39.2471 96.7393 39.149 98.5621 39.0705 100.796ZM53.1052 119.739C55.0484 120.883 61.5063 126.173 66.8061 130.945C80.5267 143.293 91.0282 155.553 95.8765 164.864C97.2309 167.473 97.3487 167.83 97.0739 168.778C96.4654 170.815 94.051 171.673 92.0685 170.547C91.4993 170.225 91.1656 169.761 90.2823 168.063C85.4928 158.984 76.228 148.011 63.5085 136.395C58.9349 132.231 54.8325 128.782 50.9852 125.887C49.7682 124.976 48.6494 123.993 48.4924 123.725C48.3353 123.439 48.1979 122.777 48.1979 122.241C48.1979 121.455 48.2961 121.133 48.7868 120.544C49.1205 120.133 49.3953 119.793 49.4345 119.793C49.4542 119.793 49.8271 119.668 50.259 119.525C51.1619 119.221 52.3789 119.31 53.1052 119.739Z"
        fill="#3E2E2E"
      />
      <path
        d="M115.663 26.2736C101.863 51.4897 93.9333 72.4346 91.6368 89.7695C91.2049 93.0221 91.1657 101.082 91.5386 103.888C92.7949 112.93 96.171 118.524 102.374 121.866C107.202 124.457 114.701 125.673 122.69 125.172C127.872 124.833 131.719 123.993 134.977 122.438C136.43 121.759 136.528 121.669 138.55 119.203C139.688 117.809 141.514 115.683 142.593 114.467C144.792 112.037 144.949 111.769 145.754 108.981C150.151 93.6654 145.145 70.7368 130.679 39.9986C127.479 33.1897 120.099 19 119.745 19C119.706 19 117.861 22.2704 115.663 26.2736ZM120.158 44.2876C120.903 44.6986 121.139 44.9846 121.983 46.593C126.831 55.8145 132.386 70.8798 135.252 82.5139C137.274 90.7346 137.981 95.667 137.981 101.582L138 105.961L137.431 106.64C135.939 108.445 133.014 108.159 131.856 106.104C131.739 105.889 131.601 104.156 131.542 102.083C131.503 100.081 131.307 97.3111 131.13 95.9351C129.403 82.2816 123.809 65.0181 115.839 48.7554C115.034 47.0934 114.976 46.5751 115.545 45.5028C116.369 43.9302 118.469 43.3941 120.158 44.2876Z"
        fill="#3E2E2E"
      />
      <circle cx="115" cy="115" r="110" stroke="black" strokeWidth="10" />
      <line
        x1="35.7109"
        y1="32.649"
        x2="184.711"
        y2="197.649"
        stroke="black"
        strokeWidth="10"
      />
    </svg>
  );
};
