import { SetStateType } from 'commonTypes';
import { PreloadComponent } from 'components/ImagePreload/PreloadComponent/PreloadComponent';
import { DropezoneItem } from 'components/ItemComponents';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import {
  fetchImage,
  loadDefaultPhotoAccount,
  loadImagesAccount,
  loadImagesItem,
  loadLogoAccount,
} from 'store/images/actions';
import {
  SET_IMAGES_ITEM,
  START_FETCH_IMAGES,
  STOP_FETCH_IMAGES,
} from 'store/images/actionTypes';

import { CropImage } from './CropImage';
import { Spinner } from './Spinner';

interface CropDropdownProps {
  setOpen?: SetStateType<boolean>;
  profileMode?: boolean;
  addItemMode?: boolean;
  setActiveStep?: SetStateType<number>;
  registerMode?: boolean;
  globalCategoryId?: string;
  logoMode?: boolean;
  defaultPhotoMode?: boolean;
}

interface RouteParams {
  id: string;
}

export const CropDropdown = ({
  setOpen,
  profileMode = false,
  addItemMode = false,
  setActiveStep,
  registerMode = false,
  logoMode = false,
  defaultPhotoMode = false,
  globalCategoryId,
}: CropDropdownProps) => {
  const { id } = useParams<RouteParams>();
  const [imgSrc, setImgSrc] = useState('');
  const [photo, setPhoto] = useState<any>(null);
  const dispatch = useAppDispatch();
  const images = useAppSelector((state) => state.image.itemImages);
  const loadingImages = useAppSelector((state) => state.image.loading);
  const account = useAppSelector((state) => state.account.account);
  const itemFields = useAppSelector((state) => state.item.addedItem);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);
  const [crop, setCrop] = useState<any>(null);
  const [completedCrop, setCompletedCrop] = useState({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });

  const [name, setName] = useState('');
  const [size, setSize] = useState(0);

  const resetCrop = () => {
    setCompletedCrop({
      unit: '%',
      width: 50,
      height: 50,
      x: 25,
      y: 25,
    });
    setCrop(null);
    setRotate(0);
    setScale(1);
  };

  const history = useHistory();

  useEffect(() => {
    if (photo) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '');
      });
      reader.readAsDataURL(photo);
    } else {
      setImgSrc('');
    }
  }, [photo]);

  useEffect(() => {
    if (!addItemMode) {
      return () => {
        setPhoto(null);
        setImgSrc('');
        setRotate(0);
        setCrop(null);
        dispatch({
          type: SET_IMAGES_ITEM,
          payload: [],
        });
      };
    }
  }, []);

  return (
    <>
      {!imgSrc && !images.length && (
        <DropezoneItem
          resetCrop={resetCrop}
          setPhoto={setPhoto}
          setName={setName}
          setSize={setSize}
        />
      )}

      {!!imgSrc && !images.length && (
        <CropImage
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          rotate={rotate}
          setRotate={setRotate}
          scale={scale}
          setScale={setScale}
          crop={crop}
          setCrop={setCrop}
          imgSrc={imgSrc}
          logoMode={logoMode}
        />
      )}

      {!!images.length && (
        <>
          {profileMode || registerMode ? (
            <div className="flex my-2 items-center justify-center space-y-3 md:space-y-0 flex-wrap w-full h-auto md:h-[380px]">
              <div
                className={`max-w-full md:max-w-[425px] max-h-[380px] rounded-lg bg-gray-300`}
              >
                <PreloadComponent
                  baseSrc={images.find((image) => image.type === 'full')?.link || ''}
                  minimizedSrc={
                    images.find((image) => image.type === 'preloader')?.link || ''
                  }
                  className="relative bg-gray-300 w-full max-w-full md:max-w-[425px] max-h-[380px] rounded-lg"
                />
              </div>
            </div>
          ) : (
            <div className="flex my-2 items-center justify-between space-y-3 md:space-y-0 flex-wrap w-full h-auto md:h-[380px]">
              <div
                className={`max-w-full md:max-w-[425px] max-h-[280px] rounded-lg bg-gray-300`}
              >
                <PreloadComponent
                  baseSrc={images.find((image) => image.type === 'full')?.link || ''}
                  minimizedSrc={
                    images.find((image) => image.type === 'preloader')?.link || ''
                  }
                  className="relative bg-gray-300 object-cover max-w-full w-[240px] h-[180px] md:max-w-[425px] md:w-[340px] md:h-[280px] rounded-lg"
                />
              </div>
              <div
                className={`max-w-full md:max-w-[425px] max-h-[320px] rounded-lg bg-gray-300`}
              >
                <PreloadComponent
                  baseSrc={images.find((image) => image.type === 'medium')?.link || ''}
                  minimizedSrc={
                    images.find((image) => image.type === 'preloader')?.link || ''
                  }
                  className="relative object-cover max-w-full bg-gray-300 w-[115px] h-[100px]  md:max-w-[175px] md:w-[150px] md:h-[120px] rounded-lg"
                />
              </div>
            </div>
          )}
        </>
      )}

      {!imgSrc && !images.length && addItemMode && (
        <div className="w-full flex items-center justify-end justify-self-end mt-auto">
          {!!setActiveStep && (
            <button
              type="button"
              disabled={loadingImages}
              onClick={() => setActiveStep((prev) => prev - 1)}
              className="mr-3 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <FormattedMessage id="previous" />
            </button>
          )}
          <button
            type="button"
            disabled={loadingImages}
            onClick={() => {
              history.push('/menu');
            }}
            data-testId="cypress-save-img"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm md:hover:bg-indigo-700 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
          >
            <FormattedMessage id="save" />
          </button>
        </div>
      )}

      {!!imgSrc && !images.length && (
        <div className="w-full flex items-center justify-end justify-self-end mt-auto">
          <button
            type="button"
            disabled={loadingImages}
            onClick={() => {
              setPhoto(null);
              setImgSrc('');
            }}
            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            {registerMode ? 'Previous' : <FormattedMessage id="previous" />}
          </button>
          <button
            type="button"
            disabled={loadingImages}
            onClick={() => {
              dispatch({ type: START_FETCH_IMAGES });
              // @ts-ignore
              document.getElementById('previewCanvasRef')?.toBlob(
                (blob: any) => {
                  if (blob) {
                    dispatch(
                      fetchImage({
                        photo: blob,
                        id:
                          profileMode || registerMode
                            ? account?.id
                            : addItemMode
                            ? itemFields.id
                            : id,
                        blobName: photo?.name,
                      }),
                    );
                  } else {
                    dispatch({ type: STOP_FETCH_IMAGES });
                  }
                },
                'image/webp',
                0.5,
              );
            }}
            className="ml-3 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {loadingImages ? (
              <Spinner color="bg-white" />
            ) : registerMode ? (
              'Next'
            ) : (
              <FormattedMessage id="next" />
            )}
          </button>
        </div>
      )}

      {!!imgSrc && !!images.length && (
        <div className="w-full flex items-center justify-end justify-self-end mt-auto">
          <button
            type="button"
            disabled={loadingImages}
            onClick={() => {
              dispatch({
                type: SET_IMAGES_ITEM,
                payload: [],
              });
            }}
            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            {registerMode ? 'Previous' : <FormattedMessage id="previous" />}
          </button>
          <button
            type="button"
            disabled={loadingImages}
            onClick={() => {
              if (defaultPhotoMode) {
                dispatch(
                  loadDefaultPhotoAccount({
                    photos: images,
                    setOpen: setOpen,
                    name,
                    size,
                  }),
                );
              } else if (logoMode) {
                dispatch(
                  loadLogoAccount({
                    photos: images,
                    setOpen: setOpen,
                    name,
                    size,
                  }),
                );
              } else if (profileMode || registerMode) {
                dispatch(
                  loadImagesAccount({
                    photos: images,
                    setOpen: setOpen,
                    registerMode,
                    history,
                  }),
                );
              } else {
                dispatch(
                  loadImagesItem({
                    id: id ? id : itemFields.id,
                    photos: images,
                    setOpen: setOpen,
                    updateFromItem: true,
                    history: addItemMode ? history : null,
                    globalCategoryId,
                  }),
                );
              }
            }}
            className="ml-3 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {loadingImages ? (
              <Spinner color="bg-white" />
            ) : registerMode ? (
              'Sign Up'
            ) : (
              <FormattedMessage id="save" />
            )}
          </button>
        </div>
      )}
    </>
  );
};
