import { RadioGroup } from '@headlessui/react';
import { SetStateType } from 'commonTypes';
import { classNames } from 'helpers/validation';
import { useAppSelector } from 'hooks/store';
import { useEffect, useState } from 'react';

import { AddThemeModal } from '../Modals';

interface ThemeTypeTabsProps {
  themeType: string;
  setThemeType: SetStateType<string>;
}

export const ThemeTypeTabs = ({ themeType, setThemeType }: ThemeTypeTabsProps) => {
  const subLoading = useAppSelector((state) => state.account.subLoading);
  const [addNewThemeOpen, setAddNewThemeOpen] = useState(false);
  const themeList = useAppSelector((state) => state.themes.themeList);

  useEffect(() => {
    if (!themeType && themeList.length > 0) {
      const activeTheme = themeList.find((theme) => theme.status === 1);
      if (activeTheme) {
        setThemeType(activeTheme.id);
      } else {
        setThemeType(themeList[0].id);
      }
    }
  }, []);

  return (
    <>
      <AddThemeModal open={addNewThemeOpen} setOpen={setAddNewThemeOpen} />
      <div className="flex items-center overflow-auto scrollbar">
        <button
          disabled={subLoading}
          onClick={() => setAddNewThemeOpen(true)}
          data-testId="cypress-add-theme-btn"
          className="w-10 h-10 shrink-0 grow mr-3 rounded-md border border-gray-300 text-gray-500 flex items-center justify-center text-3xl cursor-pointer hover:opacity-80"
        >
          +
        </button>
        <RadioGroup
          disabled={subLoading}
          value={themeType}
          onChange={(v: string) => {
            setThemeType(v);
          }}
        >
          <div className="flex items-center space-x-3">
            {themeList.map((type) => (
              <RadioGroup.Option
                data-testId={'cypress-theme-' + type.name}
                key={type.name + '-' + type.id}
                value={type.id}
                className={({ checked }) =>
                  classNames(
                    checked
                      ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                      : 'border border-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                    'flex shrink-0 items-center cursor-pointer justify-center whitespace-nowrap rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1',
                  )
                }
              >
                <RadioGroup.Label as="span">{type.name}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </>
  );
};
