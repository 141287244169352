import { SetStateType } from 'commonTypes';
import { AppModal } from 'components/Common/AppModal';
import { getItemStyle, reorder } from 'helpers/menu';
import { useAppDispatch } from 'hooks/store';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { changeOrderEntity } from 'store/account/actions';
import { Categories } from 'store/menu/types';

import { CategoryButton } from '../UI';

interface ChangeCategoryOrderProps {
  isOpen: boolean;
  setIsOpen: SetStateType<boolean>;
  activeCategory: string;
  setActiveCategory: SetStateType<string>;
  categoryList: Categories[];
  setTabs: SetStateType<Categories[]>;
}

export const ChangeCategoryOrder = ({
  isOpen,
  setIsOpen,
  activeCategory,
  setActiveCategory,
  categoryList,
  setTabs,
}: ChangeCategoryOrderProps) => {
  const dispatch = useAppDispatch();
  const [items, setItems] = useState(categoryList);

  useEffect(() => {
    setItems(categoryList);
  }, [categoryList]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const itemsNew = reorder(items, result.source.index, result.destination.index);

    const newOrder: Record<number, string> = {};
    itemsNew.forEach((item, index) => {
      newOrder[index] = item.id;
    });

    dispatch(
      changeOrderEntity({
        newOrder,
        newCategoryList: itemsNew,
      }),
    );

    setItems(itemsNew);
    setTabs(itemsNew);
  };

  return (
    <AppModal open={isOpen} size="sm:max-w-3xl">
      <DragDropContext onDragEnd={onDragEnd}>
        <div>
          <form className="w-full mb-2">
            <div className="block text-sm font-medium text-gray-700">
              <FormattedMessage id="categories" />
            </div>
          </form>
          <div className="text-gray-400 w-full text-left text-sm mb-4 sub-text-dnd">
            <FormattedMessage id="changeCategoriesOrder" />
          </div>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                className="w-full relative category-list-mobile"
                ref={provided.innerRef}
              >
                {items.map((category, i) => {
                  if (category.status === 4) return null;
                  return (
                    <Draggable
                      key={'category-button-menu-' + category.id}
                      draggableId={category.id}
                      isDragDisabled={i === 0}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                          className="mb-3"
                        >
                          <CategoryButton
                            active={activeCategory === category?.id}
                            category={category}
                            setActiveCategory={setActiveCategory}
                            activeGlobalMenu={category.menu_id}
                            noClick={true}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="w-full mt-3 mb-3">
            <CategoryButton
              category={items?.find((item) => item?.status === 4)}
              active={activeCategory === items?.find((item) => item?.status === 4)?.id}
              setActiveCategory={setActiveCategory}
              activeGlobalMenu={items?.find((item) => item?.status === 4)?.menu_id || ''}
              noClick={true}
            />
          </div>
        </div>
      </DragDropContext>
      <div className="w-full flex justify-end">
        <button
          type="button"
          onClick={() => setIsOpen(false)}
          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm md:hover:bg-gray-50 md:focus:outline-none md:focus:ring-2 md:focus:ring-indigo-500 md:focus:ring-offset-2"
        >
          <FormattedMessage id="close" />
        </button>
      </div>
    </AppModal>
  );
};
