//eslint-ignore

export const NoEggs = ({ width = 32, height = 32, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 248 248"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_417_140)">
      <path
        d="M115 230C178.513 230 230 178.513 230 115C230 51.4873 178.513 0 115 0C51.4873 0 0 51.4873 0 115C0 178.513 51.4873 230 115 230Z"
        fill="#DD455B"
      />
      <path
        d="M229.669 123.784C200.439 94.5539 147.791 41.9064 147.682 41.7988C138.061 32.0978 127.005 26.1403 115.225 26.1403C115.225 26.1403 65.5774 186.234 65.4271 186.084C65.3713 186.029 87.6075 208.265 109.197 229.855C111.12 229.951 113.054 230 115 230C175.557 230 225.181 183.193 229.669 123.784Z"
        fill="#B2004F"
      />
      <path
        d="M115.225 26.1403C77.8265 26.1403 47.7104 86.1501 47.7104 135.549C47.7104 179.084 71.6894 203.41 115.225 203.41C158.761 203.41 182.74 179.084 182.74 135.549C182.74 86.1501 152.624 26.1403 115.225 26.1403Z"
        fill="#FCF5F5"
      />
      <path
        d="M182.74 135.549C182.74 86.1501 152.624 26.1403 115.225 26.1403V203.41C158.761 203.41 182.74 179.084 182.74 135.549Z"
        fill="#EBE1DC"
      />
      <path
        d="M115.225 171.903C135.303 171.903 151.579 155.627 151.579 135.549C151.579 115.471 135.303 99.1948 115.225 99.1948C95.1473 99.1948 78.8711 115.471 78.8711 135.549C78.8711 155.627 95.1473 171.903 115.225 171.903Z"
        fill="#EAAD44"
      />
      <path
        d="M151.579 135.549C151.579 115.505 135.268 99.1948 115.225 99.1948V171.903C135.268 171.903 151.579 155.592 151.579 135.549Z"
        fill="#E58E40"
      />
      <circle cx="115" cy="115" r="110" stroke="black" strokeWidth="10" />
      <line
        x1="35.7109"
        y1="32.649"
        x2="184.711"
        y2="197.649"
        stroke="black"
        strokeWidth="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_140">
        <rect width="230" height="230" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
